import { useMemo, useRef, useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
/* Conflict Resolution Candidate
import { DateTimePicker, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { t, months, getCurrentPageName, formatDate } from "utils/constants";
import useAuth from "hooks/useAuth";
 */
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  t,
  months,
  getCurrentPageName,
  formatDate,
  checkIsReadOrWrite,
  checkIsRequired,
  PERMISSION_NAMES,
  isEmpty,
} from 'utils/constants';

import { styled } from '@mui/material/styles';
import Style from './GridDatefield.style';

export default function GridDatefield(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { control, getValues } = useFormContext();
  const {
    formSteps: { isEditField, activeStep },
    userProfileEdit,
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { user } = useSelector(({ auth }) => auth);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isInputFocused, setInputFocus] = useState(false);
  /* Conflict Resolution Candidate
  const { isSignedIn } = useAuth();
 */

  const { PrivAttributes } = user; // Here we get fields permission like hidden, read Only or write

  const {
    name = '',
    type = 'text',
    label = '',
    subLabel = '',
    defaultValue = null,
    rules,
    placeholder,
    InputProps,
    stepNumber,
    gridMd = 4,
    onClose,
    customClass = '',
    inputClass = '',
    customTrigger = () => {
    },
    multiline = false,
    disabled = false,
    validation,
    matchWith = 'passphrase',
    privId = '',
    isViewOnly = '',
    selectedRadio = '',
    customeStyle = {},
    isShowOld = false,
    RevisionDate = '',
    onKeyDown,
    gridStyles = '',
    refField = '',
    textAppend = '',
    customTextClassName = '',
    labelValue = '',
    onKeyUp = '',
    EnableFutureDate = false,
    componentName,
    fieldName,
    noValidate = false,
    boxLabelClass = '',
    tooltipIcon = false,
    tooltipTitle = '',
    tooltipPlacement = 'right',
    minDate,
    maxDate = new Date(),
    isRequiredMsg = '',
  } = props;
  const { fieldPermissions } = useSelector(({ entityApplication }) => entityApplication);
  const isReadOnlyisReadOnly = useMemo(() => {
    return checkIsReadOrWrite(fieldPermissions, componentName, fieldName) === PERMISSION_NAMES.READ;
  }, [fieldPermissions]);
  const isRequired = useMemo(() => {
    return checkIsRequired(fieldPermissions, componentName, fieldName) === PERMISSION_NAMES.MANDATORY;
  }, [fieldPermissions]);


  const d = new Date(RevisionDate);
  const revisionDate =
    RevisionDate && `${t(t1, 'REVISION_MADE_ON')} ${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;

  let readOnly = false;
  const pcomponentName = privId === '' ? name : privId;
  if (PrivAttributes?.[pcomponentName]?.PrivRole === 'NA') {
    return null;
  }
  readOnly = PrivAttributes?.[pcomponentName]?.PrivRole === 'RO';

  // This will set read only field

  if (rules && (label || name) && rules.required === true) {
    rules.required = label.length > 0 ? `${t(t1, isRequiredMsg)}` : `${t(t1, isRequiredMsg)}`;
  }

  const handleFocusEvent = (e, type) => {
    if (type === 'focus') {
      setInputFocus(true);
    }

    if (type === 'blur') {
      setInputFocus(false);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      padding: '1px 7px',
      border: '1px solid #000',
      borderRadius: '0px',
    },
  }));

  if (!isEmpty(componentName) && !isEmpty(fieldName)) {
    readOnly = isReadOnlyisReadOnly;
    if (!isRequired && !noValidate) {
      rules.required = false;
      delete rules.required;
    }
  }


  return (
    <Grid
      item
      md={gridMd}
      sx={{ minHeight: activeStep !== 4 || !isViewOnly ? '' : '100px', ...gridStyles }}
    >
      <Box
        component="div"
        className={`${boxLabelClass || 'label-wrapper'} date-picker`}
      >
        {label ? (
          <InputLabel
            className={customClass}
            htmlFor="customize-textfield"
            sx={[Style?.FieldLabel, customeStyle]}
          >
            {label}
            {rules?.required ? (
              <Typography
                variant="body2"
                sx={{
                  display: 'inline-block',
                  color: 'common.red',
                  ml: 0.3,
                  fontWeight: 700,
                }}
              >
                *
              </Typography>
            ) : null}
            {subLabel ? <Box component="span">{subLabel}</Box> : null}
            {tooltipIcon ? (
              <Tooltip
                title={tooltipTitle}
                placement={tooltipPlacement}
              >
                <ErrorIcon
                  sx={{
                    color: '#4d8da4',
                    fontSize: '20px',
                    ml: 1,
                  }}
                />
              </Tooltip>
            ) : null}
          </InputLabel>
        ) : null}
      </Box>
      {(!isEditField[stepNumber] && activeStep === 4) || isViewOnly ? (
        !textAppend && (
          <Typography
            className={customTextClassName}
            variant="body2"
            sx={Style.valuetext}
          >
            {formatDate(getValues(name)) || 'N/A'}
          </Typography>
        )
      ) : (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            name={name}
            defaultValue={defaultValue}
            rules={rules}
            control={control}
            render={({ field: { ref, onBlur, onChange, name, value, ...field }, fieldState }) => (
              <DatePicker
                onClose={onClose}
                {...field}
                value={value ? new Date(value) : value}
                inputRef={ref}
                sx={Style.formField}
                disableFuture={EnableFutureDate ? false : null}
                disabled={readOnly || disabled}
                name={name}
                id={name}
                onChange={(event) => {
                  if (props?.onChange) {
                    props.onChange(event);
                  } else {
                    onChange(event);
                  }
                  customTrigger();
                }}
                format="yyyy/MM/dd"
                slotProps={{
                  textField: {
                    'data-testid': name,
                    helperText: fieldState?.error?.message || ' ',
                    error: !!fieldState?.error,
                    onBlur,
                    sx: {
                      '.MuiOutlinedInput-input': {
                        textTransform: 'uppercase',
                        color: '#333333',
                        fontSize: '14px',
                        p: '4.8px',
                      },
                      '.MuiFormHelperText-root': {
                        mx: 0,
                        py: 0,
                      },
                      '.MuiOutlinedInput-root': {
                        '&.MuiInputBase-root.Mui-focused': {
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#333',
                            borderWidth: 1,
                          },
                        },
                      },
                      width: '100%',
                    },
                  },
                  desktopPaper: {
                    sx: {
                      '& .MuiPickersYear-yearButton': {
                        fontSize: '14px !important',
                      },
                    },
                  },
                }}
                minDate={minDate ?? new Date('1930-1-1')}
                maxDate={EnableFutureDate ? null : maxDate}
                renderInput={(inputProps) => (
                  <TextField
                    {...inputProps}
                    onBlur={onBlur}
                    sx={{
                      '.MuiFormHelperText-root': {
                        mx: 0,
                        py: 0,
                      },
                      '.MuiOutlinedInput-input': {
                        py: '3.5px',
                        textTransform: 'uppercase',
                      },
                      width: '100%',
                    }}
                    name={name}
                    value={defaultValue}
                    onFocus={(e) => handleFocusEvent(e, 'focus')}
                    onBlurCapture={(e) => handleFocusEvent(e, 'blur')}
                    onKeyUp={({ target: value }) => onKeyUp && onKeyUp(value)}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message || ' '}
                  />
                )}
              />
            )}
          />
        </LocalizationProvider>
      )}
      {isViewOnly && isShowOld && (
        <StyledTooltip
          title={
            <Typography
              color="black"
              sx={{ fontSize: '14px' }}
            >
              {revisionDate}
            </Typography>
          }
        >
          <Box
            mt={1 / 4}
            sx={Style.updatedFieldBox}
          >
            <ErrorIcon sx={{ color: '#4d8da4', fontSize: '20px', mx: 1 / 4 }} />
            <Box sx={Style.coloredBox}>
              <Typography
                sx={{
                  overflow: 'hidden',
                  wordWrap: 'break-word',
                  textOverflow: 'ellipsis',
                  /* Conflict Resolution Candidate
              }}>{isShowOld || "N/A"}</Typography>
*/
                }}
              >
                {isShowOld}
              </Typography>
            </Box>
          </Box>
        </StyledTooltip>
      )}
    </Grid>
  );
}
