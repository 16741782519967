import InfoIcon from '@mui/icons-material/Info';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { Loader } from 'components/Loader';

import PassphraseConfirmationDialog from 'pages/WiringInstructions/components/PassphraseConfirmationDialog';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { GridSelect, GridTextfield, CustomAutocomplete } from 'components';
import { CustomizeToolTip } from 'components/CustomizeToolTip';
import { FormActions } from 'components/EntityFormActions';
import { FormCard } from 'components/FormCard';
import { MUITableGrid, StyledCellContent } from 'components/MUITableGrid';
import { RHFCheckbox } from 'components/RHFCheckbox';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { FormProvider, useForm } from 'react-hook-form';
import { TextTruncate } from 'components/TextTruncate';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { setLoader, setModelMessage } from 'store/commonSlice.js';
import {
  activateRepresentative,
  addProposedRepresentatives,
  cancelRepresentative,
  deleteProposedRepresentative,
  deleteRepresentative,
  getAlternateContact,
  getRepresentative,
  getRepresentativeStatusHistory,
  getRoles,
  getSelfInformation,
  getValidateReferenceCode,
  retireRepresentative,
  saveAlternateContact,
  saveRepresentative,
  setEnableSaveBtnOnReview,
  setHandleRepReset,
  setNextStepSelect,
  setProposedRepresentativeRoleByIndex,
  suspendRepresentative,
  updateAlternateContact,
  updateProposedRepresentative,
  updateRepresentative,
  resetSteps,
  setEntityType,
  setEntityID,
  setSubmittedOfSelectedStep,
  getEntityContactById,
  setErrorOnAddRepresentativeForm,
  getProposedChangeRepresentatives,
  setChangeProposedRepresentatives,
  setRepresentative,
  setRepresentativeOnLocal,
} from 'store/entityApplicationSlice';
import {
  checkingEmptySpace,
  COMPONENT_MODES,
  ENTITY_APPLICTION_STATUS_NAMES,
  ENTITY_TYPE_NAMES,
  ERROR,
  formatDate,
  getCurrentPageName,
  isEmpty,
  isRolesAssigned,
  JURISDICTIONS_NAMES,
  onlyAlphaNumWithSpacePattern,
  onlyTextWithSpacePattern,
  onlySpaceNotAccept,
  REPRESENTATIVES_ROLES,
  REPRESENTATIVE_ACCOUNT_IDS,
  REPRESENTATIVE_RADIO_OPTIONS,
  REPRESENTATIVE_STATUS_NAMES,
  scrollToTop,
  STATUS_IDS,
  t, userReferenceCodeRegex,
  USER_ROLES,
  USER_ROLE_TYPES,
  REPRESENTATIVE_STATUS,
  SopfDetailView,
  COMPONENT_NAME_FOR_ROLE,
  getFilterForColumn,
  translateRows,
  getRepresentativeStatusDropdownforFilterDropdown,
  getRolesForActiveEntity,
  getRolesForInActiveEntity,
  validateCommentModule,
  handleRepresentativeError,
  COMMENT_MAX_CHARACTER_MODULE,
  removeTimeZone,
  formatDateUpdated,
  handleEntityStatusError,
} from 'utils/constants';
import { QuickLinksMenu } from 'components/QuickLinksMenu';
import logger from 'utils/logger.js';
import debounce from 'lodash/debounce';
import CustomeDialogMessage from 'components/CustomeDialogMessage/CustomeDialogMessage.js';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Pdf from '../../../../../assets/pdfs/rep_sample_pdf.pdf';
import { AlternateContactForm } from './AddAlternateContact.js';
import Style from './AddRepresentative.style.js';
import { RadioSelectionUser } from './RadioSelectionUser.js';
import RepresentativeStatusHistorySection from './RepresentativeStatusHistory';
import UnsavedPrompt from '../../../../../components/UnsavedDialog';
import '../../ComponentTextTruncate.css';


export default function AddRepresentative(props) {
  const {
    page,
    showUpdateByColumn = false,
    isProposed = false,
    displayHead = true,
    mode = COMPONENT_MODES.ADD,
    stepData,
    isRevisionRequested = false,
    hideAlternateContactForm = true,
    showRepTableTitle,
    checkFormIsValid = false,
    getAlternateValuesforEntityProfile = false,
    disabledOnReviewAndSubmit = false,
    verifyNewRepAdded = false,
    verifyRepEdited = false,
    setCancelRequestOption,
    displayRoleSwapPopUp = false,
    hideCard = false,
    isReviewAndSubmit,
    representativeAddFunc,
    setRepRemoved = () => {
    },
  } = props;

  const { user } = useSelector(({ auth }) => auth);
  const addRepresentativeRef = useRef();
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const {
    selectedEntity,
    EntitiesData,
    selectedJurisdiction,
    steps,
    Roles,
    Representative,
    RepresentativesByGET,
    RepresentativeOnLocal,
    AlternateContact,
    SelfInformation,
    EditAccess,
    RecheckDuplicateAR,
    fieldPermissions,
    handleRepReset,
  } = useSelector(({ entityApplication }) => entityApplication);
  const { representativeStatus } = useSelector(({ entityProfile }) => entityProfile);
  const { modalTitle, modalBodyMessage, showModal, modalConfirmButton, modalCloseButton, hideComment } = useSelector(
    ({ customModal }) => customModal,
  );
  const dispatch = useDispatch();
  const [isReadMore, setIsReadMore] = useState(false);
  const [enableAddMoreBtn, setEnableAddMoreBtn] = useState(true);
  const { id } = useParams();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showDownloadFormgModal, setShowDownloadFormgModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [representativeActionType, setRepresentativeActionType] = useState(null);
  const [modaDataObj, setModaDataObj] = useState([]);
  const [showInactiveUser, setShowInactiveUser] = useState(false);
  const [alternateContactDetails, setAlternateContactDetails] = useState('');
  const [validAlternateContactFormState, setValidAlternateContactFormState] = useState(false);
  const [checkNewRepAdded, setCheckNewRepAdded] = useState(false);
  const [checkRepEdited, setCheckRepEdited] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [showPassphraseDialogForAddRepresentative, setShowPassphraseDialogForAddRepresentative] = useState(false);
  const [showPopupOnRoleSwap, setShowPopupOnRoleSwap] = useState(false);
  const [continueBtnClicked, setContinueBtnClicked] = useState(false);
  const [newRole, setNewRole] = useState('');
  const [disabledAddRepresentative, setDisabledAddRepresentative] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isExitInProgress, setIsExitInProgress] = useState(false);

  const modalMethods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValuesForm: { comment: '' },
  });
  const userRefModalMethods = useForm({
    mode: 'onChange',
    reValidateMode: '',
    defaultValuesForm: { userRefCode: '', userRadio: false },
  });

  const resetData = () => {
    if (id) {
      dispatch(resetSteps());
      dispatch(setEntityType(''));
      dispatch(setEntityID(0));
      navigate('/start-entity-registration');
    }
  };

  const { control, reset, resetField, setValue, getValues } = modalMethods;

  // close account modal
  const handleSuspendAccountModal = (representativeObject) => {
    const modalData = {};
    modalData.title = t(t1, 'SUSPEND_ACCOUNT_REPRESENTATIVE_TITLE');
    modalData.message = t(t1, 'SUSPEND_ACCOUNT_REPRESENTATIVE_MESSAGE1');
    modalData.buttonMessage = t(t1, 'SUSPEND_ACCOUNT_REPRESENTATIVE_MESSAGE211');
    modalData.buttonText = t(t1, 'SUSPEND_ACCOUNT_REPRESENTATIVE_MODAL_BUTTONTEXT');
    modalData.showComentText = true;
    modalData.textBoxName = t(t1, 'Comment');
    modalData.rules = {
      required: t(t1, 'COMMENTS_ISREQUIRED'),
      // pattern: {
      //   value: onlySpaceNotAccept,
      //   message: `${t(t1, "VALIDATION_MESSAGE")} ${t(t1, "COMMENT")}`,
      // },

      validate: (v) => validateCommentModule(v, `${t(t1, 'COMMENTS')}`, t1),
      maxLength: {
        value: COMMENT_MAX_CHARACTER_MODULE,
        message: `${t(t1, 'ERR_COMMENTS_MAX_LENGTH_CHAR')}`,
      },
    };
    modalData.representativeObject = representativeObject;
    modalData.handleModalContinue = (data) => {
      setShowInactiveUser(false);
      setShowWarningModal(representativeObject);
    };
    setModaDataObj(modalData);
    setShowInactiveUser(true);
    setCheckRepEdited(true);
  };

  // close account modal
  const handleActivateAccountModal = (representativeObject) => {
    const modalData = {};
    modalData.title = 'ACTIVATE_ACCOUNT_REPRESENTATIVE_TITLE';
    modalData.message = 'ACTIVATE_ACCOUNT_REPRESENTATIVE_MESSAG1';
    modalData.buttonMessage = 'ACTIVATE_ACCOUNT_REPRESENTATIVE_MESSAGE2';
    modalData.buttonText = 'ACTIVATE_ACCOUNT_REPRESENTATIVE_BUTTONTEXT';
    modalData.showComentText = true;
    modalData.textBoxName = 'COMMENT';
    modalData.rules = {
      required: true,
      validate: (v) => validateCommentModule(v, `${t(t1, 'COMMENTS')}`, t1),
      maxLength: {
        value: COMMENT_MAX_CHARACTER_MODULE,
        message: `${t(t1, 'ERR_COMMENTS_MAX_LENGTH_CHAR')}`,
      },
    };
    modalData.representativeObject = representativeObject;
    modalData.handleModalContinue = (data) => {
      setShowInactiveUser(false);
      setShowWarningModal(representativeObject);
    };
    setModaDataObj(modalData);
    setShowInactiveUser(true);
    setCheckRepEdited(true);
  };

  const handleModalContinue = (data) => {
    if (representativeActionType === REPRESENTATIVE_STATUS_NAMES.activate) {
      handleActivateRepresentative(showWarningModal.id, getValues('comment'));
    } else if (representativeActionType === REPRESENTATIVE_STATUS_NAMES.cancel) {
      handleCanceRepresentative(showWarningModal.id);
    } else if (representativeActionType === REPRESENTATIVE_STATUS_NAMES.suspend) {
      handleSuspendRepresentative(showWarningModal.id, getValues('comment'));
    } else if (representativeActionType === REPRESENTATIVE_STATUS_NAMES.retire) {
      handleRetireRepresentative(showWarningModal.id);
    } else if (representativeActionType === REPRESENTATIVE_STATUS_NAMES.remove) {
      const representativeToBeRemove = RepresentativesByGET.filter((rep) => {
        return showWarningModal.userReferenceCode === rep.userReferenceCode;
      });
      setRepRemoved(true);
      if (representativeToBeRemove.length > 0) {
        handleCanceRepresentative(showWarningModal?.proposeChanges?.id || showWarningModal?.id);
      } else {
        dispatch(deleteProposedRepresentative(showWarningModal));
      }
      const values = methods.getValues();
      methods?.reset(values);
    }
    if (representativeAddFunc) {
      representativeAddFunc();
    }
    modalMethods?.reset();
    resetField('comment');
    setShowWarningModal(null);
    setRepresentativeActionType(null);
  };

  useEffect(() => {
    methods?.trigger('representative.userReferenceCode');
  }, [RecheckDuplicateAR]);

  useEffect(() => {
    if (handleRepReset) {
      dispatch(setHandleRepReset(false));
      handleReset(null, false);
    }
  }, [handleRepReset]);

  const getUpdateBy = (item) => {
    if (isEmpty(item.id) && isEmpty(item?.proposeChanges)) {
      return userDetails?.Name;
    }
    if (!isEmpty(item?.proposeChanges)) {
      return item?.proposeChanges?.updatedBy?.firstName.concat(' ', item?.proposeChanges?.updatedBy?.lastName);
    }
    return item?.updatedBy?.firstName.concat(' ', item?.updatedBy?.lastName);
  };

  const [tableRow, setTableRow] = useState(!isEmpty(Representative) ? setExcelData(Representative) : []);
  const [coveredEntity] = useState(selectedEntity === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY);
  const [caJurisdiction] = useState(selectedJurisdiction === JURISDICTIONS_NAMES.CALIFORNIA);
  const [roleOptions, setRoleOptions] = useState([]);
  const pendingStatusId = STATUS_IDS.PENDING_STATUS_ID;
  const propsedStatusID = STATUS_IDS.PROPOSED_STATUS_ID;
  const primaryAccountID = REPRESENTATIVE_ACCOUNT_IDS.PRIMARY_ACCOUNT_ID;
  const isGMPI = selectedEntity === ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_INDIVIDUAL;


  const { isIndividualUser, isJurisdictionRegistrarUser, isJurisdictionUser } = useMemo(
    () => ({
      isIndividualUser: isRolesAssigned(userDetails, [USER_ROLE_TYPES.INDIVIDUAL_USER]),
      isJurisdictionRegistrarUser: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.REGISTRAR],
      ),
      isJurisdictionUser: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
      ),
    }),
    [userDetails],
  );

  let representativesPageDetail = {
    componentName: COMPONENT_NAME_FOR_ROLE.REPRESENTATIVE,
    pageName: 'Page_EntityProfile',
  };
  if (isJurisdictionRegistrarUser) {
    representativesPageDetail = {
      componentName: COMPONENT_NAME_FOR_ROLE.REPRESENTATIVE,
      pageName: 'Page_EntityProfile',
    };
  }

  let alternateContactPersonPageDetail = {
    componentName: COMPONENT_NAME_FOR_ROLE.ALTERNATE_CONTACT_PERSON,
    pageName: 'Page_EntityProfile',
  };
  if (isJurisdictionRegistrarUser) {
    alternateContactPersonPageDetail = {
      componentName: COMPONENT_NAME_FOR_ROLE.ALTERNATE_CONTACT_PERSON,
      pageName: 'Page_EntityProfile',
    };
  }

  let representativeHistorypageDetails = {
    componentName: 'userRepresentativeAssignmentHistory',
    pageName: 'Page_EntityProfile',
  };
  if (isJurisdictionRegistrarUser) {
    representativeHistorypageDetails = { componentName: 'representativeStatusHistory', pageName: 'Page_EntityProfile' };
  }

  let tempUserReferenceCode = isGMPI ? SelfInformation[0]?.ReferenceCode : '';

  const representativeStatusAndJurisdictionValuesForColumn = getFilterForColumn(Representative, t, t1, ['status', 'jurisdiction']);

  let tableColoums = [
    {
      id: 1,
      field: 'name',
      headerName: 'Name',
      flex: 2,
      hideable: false,
      groupable: false,
      renderCell: (cellValues) => {

        const jurisdictionNameSubstring = (cellValues?.row?.jurisdictionName || '').toLowerCase().split(' ')[0];
        const orgName = (userDetails?.OrgDetails?.[0]?.OrgName || '').toLowerCase().split(' ')[0];
        const isSameJurisdiction = jurisdictionNameSubstring === orgName;
        const userId = cellValues?.row?.status === 'Proposed' && !isEmpty(cellValues.row?.proposeChanges) ? cellValues.row?.proposeChanges?.uId : cellValues?.row?.uId;
        return (
          isJurisdictionUser && isSameJurisdiction ?
            <Typography sx={Style.ClickLinkCenter}>
              <Link
                className="link-cell"
                href={`/jurisdiction-user-actions?id=${window.btoa(userId?.substring(2))}`}
                to={`/jurisdiction-user-actions?id=${window.btoa(userId?.substring(2))}`}
              >
                {cellValues?.row?.name}
              </Link>
            </Typography>
            :
            <StyledCellContent>{cellValues?.row?.name}</StyledCellContent>
        );
      },
    },
    {
      id: 1,
      field: 'role',
      headerName: 'Role',
      flex: 2,
      valueGetter: (params) => {
        return t(t1, params?.row?.role?.name || params?.row?.proposeChanges?.role?.name);
      },
      renderCell: (params) => {

        const isRetired = (params.row?.status?.toLowerCase() === 'retired' || params.row?.status?.toLowerCase() === 'retired-f');
        const isSuspended = (params.row?.status?.toLowerCase() === 'suspended' || params.row?.status?.toLowerCase() === 'suspended-f');
        const isProposedStatusWithActiveClosedEntity = (params.row?.status?.toLowerCase() === 'proposed' || params.row?.status?.toLowerCase() === 'proposed-f') && [ENTITY_APPLICTION_STATUS_NAMES.ACTIVE, ENTITY_APPLICTION_STATUS_NAMES.CLOSED].includes(EntitiesData?.data?.entityStatus?.name);

        const roleId = params?.row?.role?.id || params?.row?.proposeChanges?.role?.id;
        const roleEditAllowed = isJurisdictionRegistrarUser && (params?.row?.status?.toLowerCase() !== 'active' || params?.row?.status?.toLowerCase() !== 'active-f');
        const ifQCGMPISelf = params?.row?.role?.name === REPRESENTATIVES_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE &&
          isGMPI && EntitiesData?.data?.createdByUser?.userReferenceCodeData === params?.row?.userReferenceCode;
        if (
          ((isProposed || isRevisionRequested || isJurisdictionRegistrarUser || isIndividualUser) && !stepData?.editMode) && !isEditable) {
          const role = roleOptions?.find((item) => item.id === params?.row?.role?.id);
          const proposerRoles = roleOptions?.find((item) => item.id === params?.row?.proposeChanges?.role?.id);
          if (params?.row?.action === 'Replace') {
            return <span>{''}</span>;
          }
          return (<span>{t(t1, role?.name || proposerRoles?.name)}</span>);
        }

        return (
          <FormControl
            variant="standard"
            sx={{ margin: '0 -10px', width: 'calc(100% + 20px)' }}
          >
            <Select
              disabled={isSuspended || ifQCGMPISelf ||
                isJurisdictionRegistrarUser || (!stepData?.editMode && disabledOnReviewAndSubmit) ||
                roleEditAllowed ||
                ((isProposed || isRevisionRequested) && !stepData?.editMode) ||
                isRetired || isProposedStatusWithActiveClosedEntity
              }
              value={roleId}
              onChange={(e) => displayRoleSwapPopUp ? handleARSwapAction(params, e) : handleChangeRepFromTable(params, e)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {roleOptions.filter(role => {
                if (pathname.includes('entity-registration')) {
                  return [REPRESENTATIVES_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE, REPRESENTATIVES_ROLES.ALTERNATE_ACCOUNT_REPRESENTATIVE].includes(role.name);
                }
                return true;
              })?.map((each, index) => {
                return (
                  <MenuItem
                    key={`${index}-${each.id}`}
                    value={each.id}
                  >
                    {t(t1, each.name)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      },
      type: 'singleSelect',
      valueOptions: [ENTITY_APPLICTION_STATUS_NAMES.ACTIVE, ENTITY_APPLICTION_STATUS_NAMES.CLOSED].includes(EntitiesData?.data?.entityStatus?.name) ? getRolesForActiveEntity(t, t1) : getRolesForInActiveEntity(t, t1),
    },
    {
      id: 1,
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return <StyledCellContent>{params?.row?.status ? t(t1, params?.row?.status) : ''}</StyledCellContent>;
      },
      type: 'singleSelect',
      valueOptions: getRepresentativeStatusDropdownforFilterDropdown(t, t1),
    },
    {
      id: 1,
      field: 'jurisdictionName',
      headerName: t(t1, 'JURISDICTION'),
      flex: 1,
      valueFormatter: ({ value }) => {
        return t(t1, value);
      },
      renderCell: (params) => {
        return <StyledCellContent>{t(t1, params?.row?.jurisdictionName || params?.row?.proposeChanges?.jurisdiction)}  </StyledCellContent>;
      },
      type: 'singleSelect',
      valueOptions: representativeStatusAndJurisdictionValuesForColumn?.jurisdiction,
    },
    {
      id: 1,
      field: 'updateDate',
      headerName: `${t(t1, 'UPDATE_DATE_COLUMN_HEADER')}`,
      flex: 1,
      valueGetter: (params) => ` ${removeTimeZone(formatDateUpdated(params?.row?.updateDate ? params?.row?.updateDate : 'NA'))}`,
      renderCell: (params) => {
        return <StyledCellContent>{removeTimeZone(formatDateUpdated(params?.row?.updateDate))}</StyledCellContent>;
      },
    },
    {
      id: 1,
      field: 'updatedBy',
      headerName: t(t1, 'UPDATED_BY'),
      flex: 1,
      renderCell: (params) => {
        return <StyledCellContent>{params?.row?.updatedBy}</StyledCellContent>;
      },
    },
    {
      id: 1,
      field: 'isAllowedAccess',
      headerName: 'Edit Access',
      align: 'center',
      flex: 1,
      filterable: false,
      renderCell: (cellValues) => (
        cellValues.row.isAllowedAccess ? <CheckBoxIcon
            color="primary"
            sx={{

              '&:hover': {
                bgcolor: 'transparent',
              },
            }}

          /> :
          <CheckBoxOutlineBlankIcon color="disabled"
                                    sx={{

                                      '&:hover': {
                                        bgcolor: 'transparent',
                                      },
                                    }}
          />
      ),
    },
  ];

  if (
    ![
      ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_IN_PROGRESS,
      ENTITY_APPLICTION_STATUS_NAMES.REVISIONS_REQUESTED,
    ].includes(EntitiesData?.data?.entityStatus?.name)
  ) {
    tableColoums = tableColoums.filter((item) => item.field !== 'isAllowedAccess');
  }

  if (mode === COMPONENT_MODES.ADD || (stepData?.editMode && isIndividualUser)) {
    tableColoums.push({
      id: 1,
      field: 'QuickActions',
      headerName: 'Quick Actions',
      flex: 1,
      resizable: false,
      align: 'center',
      hideable: false,
      filterable: false,
      sortable: false,
      groupable: false,
      disableExport: true,
      renderCell: (cellValues) => {
        const index = cellValues.row?.id;
        let showQuickAction = false;
        if (
          (mode === COMPONENT_MODES.ADD || stepData?.editMode) &&
          isIndividualUser &&
          cellValues.row?.status?.toLowerCase() !== REPRESENTATIVE_STATUS_NAMES.retired &&
          cellValues.row?.status?.toLowerCase() !== REPRESENTATIVE_STATUS_NAMES.retired_f &&
          cellValues.row?.status?.toLowerCase() !== REPRESENTATIVE_STATUS_NAMES.propose &&
          cellValues.row?.status?.toLowerCase() !== REPRESENTATIVE_STATUS_NAMES.propose_f
        ) {
          showQuickAction = true;
        }
        if (
          cellValues?.row?.role?.name === REPRESENTATIVES_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE &&
          isGMPI && EntitiesData?.data?.createdByUser?.userReferenceCodeData === cellValues?.row?.userReferenceCode
        ) {
          showQuickAction = false;
        }
        if (
          cellValues?.row?.role?.name === REPRESENTATIVES_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE &&
          isGMPI &&
          caJurisdiction &&
          EntitiesData?.data?.createdByUser?.userReferenceCodeData === cellValues?.row?.userReferenceCode
        ) {
          showQuickAction = false;
        }
        if (cellValues?.row?.action === 'Replace') {
          showQuickAction = false;
        }
        const quickLinkList = [
          {
            name: t(t1, 'RETIRE_ACCOUNT_REPRESENTATIVE'),
            isHiden: !['active', 'suspended'].includes(cellValues.row?.status?.toLowerCase()),
            onLinkClick: () => {
              setShowWarningModal(cellValues.row);
              setRepresentativeActionType('retire');
            },
          },
          {
            name: t(
              t1,
              ENTITY_APPLICTION_STATUS_NAMES.ACTIVE === EntitiesData?.data?.entityStatus?.name
                ? 'CANCEL_REQUEST_UPPERCASE'
                : 'REMOVE_USER_FROM_ROLE',
            ),
            isHiden: cellValues.row?.status?.toLowerCase() !== 'proposed',
            onLinkClick: () => {
              setShowWarningModal(cellValues.row);
              setRepresentativeActionType('remove');
            },
          },
        ];
        const optionsPresent = quickLinkList.some((list) => !list.isHiden);

        return showQuickAction && optionsPresent && isEditable ? (
          <QuickLinksMenu
            iconClassName="dot-icon"
            popupClassName="quick-link-style"
            linksList={quickLinkList || []}
            label={t(t1, 'MORE ACTIONS')}
            isDotIcon
          />
        ) : null;
      },
    });
  }

  if (
    stepData?.editMode &&
    isJurisdictionRegistrarUser &&
    (EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.ACTIVE ||
      EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.CLOSED)
  ) {
    tableColoums.push({
      id: 1,
      field: 'QuickActions',
      headerName: 'Quick Actions',
      flex: 1,
      hideable: false,
      // minWidth: 120,
      align: 'center',
      sortable: false,
      groupable: false,
      disableExport: true,
      filterable: false,
      renderCell: (cellValues) => {
        const index = cellValues?.row?.id || cellValues?.row?.index;
        const hasProposed = tableRow?.find((item) => !isEmpty(item.proposeChanges));
        const showRemoveCanceltoRegistrar =
          (cellValues?.row?.status?.toLowerCase() === 'proposed' || cellValues?.row?.status?.toLowerCase() === 'proposed-f') &&
          isJurisdictionRegistrarUser &&
          userDetails?.UID === `U#${cellValues.row?.proposeChanges?.updatedBy}`;

        const quickLinkList = [
          {
            name: t(t1, 'REMOVE_USER_FROM_ROLE').toUpperCase(),
            isHiden: !showRemoveCanceltoRegistrar,
            onLinkClick: () => {
              setShowWarningModal(cellValues.row);
              setRepresentativeActionType('remove');
            },
          },
          {
            name: t(t1, 'CANCEL_REQUEST'),
            isHiden: isEmpty(cellValues.row?.proposeChanges) || !showRemoveCanceltoRegistrar,
            onLinkClick: () => {
              setShowWarningModal(cellValues.row?.proposeChanges);
              setRepresentativeActionType('cancel');
            },
          },
          {
            name: t(t1, 'SUSPEND_ACCOUNT_REPRESENTATIVE'),
            isHiden: cellValues?.row?.status?.toLowerCase() !== 'active',
            onLinkClick: () => {
              modalMethods?.reset();
              handleSuspendAccountModal(cellValues.row);
              setRepresentativeActionType('suspend');
            },
          },
          {
            name: t(t1, 'ACTIVATE_REPRESENTATIVE'),
            isHiden: cellValues.row?.status?.toLowerCase() !== 'suspended',
            onLinkClick: () => {
              modalMethods?.reset();
              handleActivateAccountModal(cellValues.row);
              setRepresentativeActionType('activate');
            },
          },
          {
            name: t(t1, 'RETIRE_ACCOUNT_REPRESENTATIVE'),
            isHiden:
              cellValues?.row?.status?.toLowerCase() !== 'active' ||
              isRolesAssigned(userDetails, [USER_ROLE_TYPES.JURISDICTION_USER]),
            onLinkClick: () => {
              setShowWarningModal(cellValues.row);
              setRepresentativeActionType('retire');
            },
          },
          {
            name: t(t1, 'CHANGE_PRIMARY_ACCOUNT_REPRESENTATIVE'),
            isHiden:
              !['active', 'suspended'].includes(cellValues.row?.status.toLowerCase()) ||
              cellValues?.row?.role?.name !== REPRESENTATIVES_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE ||
              hasProposed,
            onLinkClick: () => {
              setShowChangeModal(true);
              userRefModalMethods.setValue('selectedAR', cellValues?.row);
            },
          },
        ];

        const optionsPresent = quickLinkList.some((list) => !list.isHiden);

        return (
          ((isJurisdictionRegistrarUser && userDetails?.UID === `U#${cellValues.row?.proposeChanges?.updatedBy}`) ||
            ((cellValues.row?.status?.toLowerCase() !== 'retired' ||
                cellValues.row?.status?.toLowerCase() !== 'retired-f') &&
              (cellValues.row?.status?.toLowerCase() !== 'proposed' ||
                cellValues.row?.status?.toLowerCase() !== 'proposed-f'))) &&
          optionsPresent && (
            <QuickLinksMenu
              iconClassName="dot-icon"
              popupClassName="quick-link-style"
              linksList={quickLinkList || []}
              label={t(t1, 'MORE ACTIONS')}
              isDotIcon
            />
          )
        );
      },
    });
  }

  const hasProposed = tableRow?.find((item) => !isEmpty(item.proposeChanges));
  if (hasProposed && isProposed && ([ENTITY_APPLICTION_STATUS_NAMES.ACTIVE, ENTITY_APPLICTION_STATUS_NAMES.CLOSED].includes(
    EntitiesData?.data?.entityStatus?.name))) {
    tableColoums.splice(2, 0, {
      id: 10,
      field: 'proposedRole',
      headerName: t(t1, 'PROPOSED_ROLE'),
      flex: 2,
      // valueFormatter: ({ value }) => {
      //   return t(t1, value?.name);
      // },
      valueGetter: (params) => {
        return t(t1, params?.row?.updatedProposed?.role?.name);
      },
      valueOptions: getRolesForActiveEntity(t, t1),
      // minWidth: 120,
      renderCell: (params) => {
        const roleId = params?.row?.updatedProposed?.role?.id;
        const isReplaceRole = params?.row?.proposeChanges?.action === 'Replace';
        if ((isProposed || isRevisionRequested || isJurisdictionRegistrarUser) &&
          !stepData?.editMode) {
          const role = roleOptions?.find((item) => item.id === roleId);
          if (isReplaceRole) {
            return <span>{''}</span>;
          }
          if (params?.row?.action === 'Replace') {
            return <span>{params?.row?.role?.name ? t(t1, params?.row?.role?.name) : ''}</span>;
          }
          return <span>{role?.name ? t(t1, role?.name) : ''}</span>;
        }
        return (
          <FormControl
            variant="standard"
            sx={{ margin: '0 -10px', width: 'calc(100% + 20px)' }}
          >
            <Select
              disabled
              value={roleId}
              onChange={(e) => displayRoleSwapPopUp ? handleARSwapAction(params, e) : handleChangeRepFromTable(params, e)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {roleOptions?.map((each, index) => {
                return (
                  <MenuItem
                    key={`select-${index}-${each.id}`}
                    value={each.id}
                  >
                    {t(t1, each.name)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      },
    });
  }

  if (isRolesAssigned(userDetails, [USER_ROLE_TYPES.JURISDICTION_USER])) {
    tableColoums = tableColoums?.map((ele) => ele);
  } else if (isRolesAssigned(userDetails, [USER_ROLE_TYPES.INDIVIDUAL_USER]) || !showUpdateByColumn) {
    tableColoums = tableColoums?.filter((ele) => ele.field !== 'updatedBy');
  }

  function setExcelData() {
    const userRows = [];
    const rolesData = [];
    Roles?.map((item) => {
      rolesData.push({ name: item?.RoleName, id: item?.ID });
      return rolesData;
    });
    const representativeData = [];
    Representative.forEach(item => {
      representativeData.push(item);
      if (item?.proposeChanges?.action === 'Replace') {
        representativeData.push(item.proposeChanges);
      }
    });
    representativeData?.forEach((item, index) => {
      let JsName = '';
      if (!isEmpty(item?.jurisdiction) || !isEmpty(item?.proposeChanges?.jurisdiction)) {
        JsName = item?.proposeChanges?.jurisdiction ? item?.proposeChanges?.jurisdiction : item?.jurisdiction;
      } else {
        JsName = SelfInformation[0]?.jurisdictionType;
      }
      if (!JsName) {
        JsName = item?.proposeChanges?.jurisdiction ? item?.proposeChanges?.jurisdiction : item?.jurisdiction;
      }

      userRows.push({
        ...item,
        isAllowedAccess: item?.proposeChanges?.isAllowedAccess ? item?.proposeChanges?.isAllowedAccess : item?.isAllowedAccess,
        id: item?.id ? item?.id : index,
        index,
        name: item?.name ? item?.name : item?.proposeChanges?.name,
        role: typeof item?.role === 'object' ? item?.role : rolesData?.find((object) => (item?.role?.id) ? item?.role?.id === object.id : item?.role === object.id),
        status:
          item?.status?.split('')?.length < 12 ? t(t1, item?.status) || t(t1, item?.proposeChanges?.status) : 'Proposed',
        jurisdictionName: JsName,
        updateDate: item?.updateDate || new Date().toString(),
        updatedBy: getUpdateBy(item),
      });
    });

    if (isRolesAssigned(userDetails, [USER_ROLE_TYPES.INDIVIDUAL_USER], [USER_ROLES.INDIVIDUAL_USER])) {
      const newValue = userRows?.filter((elemt) => delete elemt?.updatedByUser);
    }
    return userRows;
  }

  useEffect(() => {
    setTableRow(setExcelData(Representative));
  }, [Representative]);

  useEffect(() => {
    handleReset(null, false);
    if (!isReviewAndSubmit) {
      dispatch(getEntityContactById(id));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(getRepresentative(id, representativesPageDetail));
      dispatch(getAlternateContact(id, alternateContactPersonPageDetail));
    }
  }, [id, isJurisdictionRegistrarUser]);

  const AddRepresentativeShowError = (error) => {
    handleRepresentativeError(error, dispatch, logger);
  };

  useEffectOnce(() => {
    if (mode !== COMPONENT_MODES.EDIT) scrollToTop();
    dispatch(getRoles());
    dispatch(getSelfInformation(() => setLoading(false)));
    if (t(t1, 'FOOTER_DISCLAIMER_CONTENT').length > 250) {
      setIsReadMore(true);
    } else {
      setIsReadMore(false);
    }
  }, []);

  useEffectOnce(() => {
    dispatch(getRoles());
  }, []);

  const reloadUserRoles = () => {
    if (Roles?.length > 0) {
      const rolesData = [];
      Roles?.map((item) => {
        if (
          EntitiesData?.data?.entityStatus?.name !== ENTITY_APPLICTION_STATUS_NAMES.CLOSED &&
          EntitiesData?.data?.entityStatus?.name !== ENTITY_APPLICTION_STATUS_NAMES.ACTIVE &&
          item?.RoleName === 'ACCOUNT_VIEWING_AGENT'
        ) {
          return item;
        }
        rolesData.push({ name: item?.RoleName, id: item?.ID });
        return rolesData;
      });
      setRoleOptions(rolesData);
    }
  };
  useEffect(() => {
    reloadUserRoles();
  }, []);
  useEffect(() => {
    reloadUserRoles();
  }, [Roles]);


  if (isGMPI && Representative.length > 0) {
    tempUserReferenceCode = '';
  }


  /* eslint-disable no-nested-ternary */
  const [INITIALDEFAULTVALUE, SETINITIALDEFAULTVALUE] = useState({
    representative: [
      {
        index: 0,
        userReferenceCode: '',
        role: isGMPI && EntitiesData?.data?.entityStatus?.name !== ENTITY_APPLICTION_STATUS_NAMES.ACTIVE ? primaryAccountID : '',
        isAllowedAccess: (Representative.length === 0 && isGMPI),
        entityId: id,
        status: pendingStatusId,
        name: `${SelfInformation[0]?.Name} ${SelfInformation[0]?.FamilyName}` || '',
        email: SelfInformation[0]?.Email || '',
        type: 'default',
        selectOrUserCode: isGMPI ? REPRESENTATIVE_RADIO_OPTIONS.self[0].value : '',
        isMandatoryCheckbox: false,
        disableReferenceField: true,
      },
    ],
  });

  const [defaultValuesForm, setDefaultValuesForm] = useState(INITIALDEFAULTVALUE);

  useEffect(() => {
    methods?.reset({ ...defaultValuesForm });
  }, [AlternateContact?.data]);

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: defaultValuesForm,
  });

  const { watch } = methods;
  const methodsAlternateData = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: defaultValuesForm,
  });

  const {
    formState: { isValid },
  } = methods;
  const setDefaultValues = (val) => {
    methods?.reset(val || defaultValuesForm);
  };

  useMemo(() => {
    if (methods?.formState?.errors?.representative) {
      dispatch(setErrorOnAddRepresentativeForm(true));
    } else {
      dispatch(setErrorOnAddRepresentativeForm(false));
    }
  }, [methods?.formState?.errors?.representative]);

  const checkDuplicateRefs = () => {
    const reps = methods?.getValues('representative');
    reps.forEach((rep, index) => {
      const existingRep = tableRow?.find((item) => (item.userReferenceCode?.toLowerCase() === rep.userReferenceCode?.toLowerCase() || item.proposeChanges?.userReferenceCode?.toLowerCase() === rep.userReferenceCode?.toLowerCase()) && (item.status?.toLowerCase() !== 'retired' || item.status?.toLowerCase() !== 'retired-f'));
      const isDuplicate = reps.find((item) => {
        /* eslint-disable */
        return rep.userReferenceCode?.toLowerCase() === item.userReferenceCode?.toLowerCase() && item.userReferenceCode && item.index < index;
      });
      if (
        existingRep &&
        [REPRESENTATIVE_STATUS.RETIRED, REPRESENTATIVE_STATUS.SUSPENDED].includes(existingRep.status.Name)
      ) {
        return true;
      }
      if ((isDuplicate || existingRep)) {
        return methods?.setError(`representative[${index}].userReferenceCode`, {
          type: 'custom',
          message: t(t1, 'DUPLICATE_USER_REFERENCE_CODE_IS_NOT_ALLOWED'),
        });
      }
    });
  };

  const SaveAlternateContact = () => {
    const temp = { ...alternateContactDetails };
    delete temp.createdBy;
    delete temp.deleted_at;
    delete temp.deleted_by;
    delete temp.updatedBy;

    const contact = {
      contact: {
        ...temp,
        entityId: id,
      },
    };
    if (AlternateContact?.data && AlternateContact?.data?.id) {
      contact.contact = {
        ...contact.contact,
        entityId: id,
      };
      return dispatch(updateAlternateContact(contact, id, {}, true));
    } else {

      return dispatch(saveAlternateContact(contact, id, {}, true));
    }
  };

  const onSubmit = () => {
    dispatch(setLoader(true));
    setContinueBtnClicked(true);
    const reps = Representative?.map((item) => {
      return {
        id: item.id,
        role: (item.role?.id) ? item.role?.id : item.role,
        userReferenceCode: item.userReferenceCode,
        isAllowedAccess: item.isAllowedAccess ? item.isAllowedAccess === true : false,
        name: item.name,
        entityId: id,
        email: item.email,
        status: propsedStatusID,
      };
    });
    const postData = {
      representative: reps,
    };
    if (id) {
      if (!isEmpty(RepresentativesByGET)) {
        updateRepresentative(postData, id)
          .then(async () => {
            await SaveAlternateContact();
            dispatch(
              setModelMessage({
                title: t(t1, 'SUCCESS'),
                message1: t(t1, 'REPRESENTATIVE_MODIFIED_SUCCESSFULLY'),
              }),
            );
            dispatch(setRepresentativeOnLocal([]));
            dispatch(setSubmittedOfSelectedStep(true));
            dispatch(getRepresentative(id, representativesPageDetail));
            dispatch(setNextStepSelect({ steps }));
            dispatch(getRepresentativeStatusHistory(id, representativeHistorypageDetails));
          })
          .catch((error) => {
            dispatch(setLoader(false));
            const hasNavigated = handleEntityStatusError(error, id, dispatch, navigate);
            if (hasNavigated) return;
            setContinueBtnClicked(false);
            if (error.message?.includes('ALTERNATE_CONTACT_ERROR')) {
              dispatch(setModelMessage({
                title: 'COMMON_ERROR',
                message1: '',
                error: true,
              }));
              dispatch(getRepresentative(id, representativesPageDetail));
            } else {
              AddRepresentativeShowError(error);
            }
            dispatch(setSubmittedOfSelectedStep(false));
          });
      } else {
        saveRepresentative(postData, id)
          .then(async () => {
            await SaveAlternateContact();
            dispatch(
              setModelMessage({
                title: t(t1, 'SUCCESS'),
                message1: t(t1, 'REPRESENTATIVE_ADDED_SUCCESSFULLY_AND_MAIL_SENT_TO_AR'),
              }),
            );
            dispatch(setSubmittedOfSelectedStep(true));
            dispatch(setRepresentativeOnLocal([]));
            dispatch(getRepresentative(id, representativesPageDetail));
            dispatch(setNextStepSelect({ steps }));
            dispatch(getRepresentativeStatusHistory(id, representativeHistorypageDetails));
          })
          .catch((error) => {
            dispatch(setLoader(false));
            const hasNavigated = handleEntityStatusError(error, id, dispatch, navigate);
            if (hasNavigated) return;
            setContinueBtnClicked(false);
            if (error.message?.includes('ALTERNATE_CONTACT_ERROR')) {
              dispatch(setModelMessage({
                title: 'COMMON_ERROR',
                message1: '',
                error: true,
              }));
              dispatch(setRepresentativeOnLocal([]));
              dispatch(getRepresentative(id, representativesPageDetail));
            } else {
              AddRepresentativeShowError(error);
            }
            dispatch(setSubmittedOfSelectedStep(false));
          });
      }
    }
  };

  const SubmitRepresentative = () => {
    let repsList = methods?.getValues('representative') || [];
    repsList = repsList.splice(0, defaultValuesForm.representative.length);
    const formReps = [...repsList];
    const reps = formReps.map((item) => {
      item.status = propsedStatusID;
      item.entityId = id;
      // eslint-disable-next-line
      item.role;
      // eslint-disable-next-line

      delete item.index;
      delete item.type;
      delete item.selectOrUserCode;
      delete item.isMandatoryCheckbox;
      delete item.disableReferenceField;
      item.isEdited = true;
      return item;
    });
    setCheckNewRepAdded(true);
    if (verifyNewRepAdded) {
      verifyNewRepAdded(true);
    }
    dispatch(setLoader(true));
    reps?.forEach((d) => {
      delete d.isEdited;
    });
    dispatch(addProposedRepresentatives(reps, id, RepresentativeOnLocal));
    dispatch(setEnableSaveBtnOnReview(true));
    tempUserReferenceCode = '';
    setTimeout(() => {
      handleReset('add', false);
      dispatch(setEnableSaveBtnOnReview(true));
      methods.clearErrors();
    }, 1000);
    tempUserReferenceCode = '';
    dispatch(setEnableSaveBtnOnReview(true));
  };

  const setRepFormValue = (base, key, value) => {
    const newV = { ...methods.getValues(base), [key]: value };
    methods.setValue(base, newV);
  };

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const handleAddMore = () => {
    const oldIndex = defaultValuesForm?.representative[defaultValuesForm.representative.length - 1].index;
    setDefaultValuesForm((defaultValuesForm) => {
      return {
        representative: [
          ...defaultValuesForm.representative,
          {
            index: oldIndex + 1,
            userReferenceCode: '',
            role: '',
            isAllowedAccess: false,
            entityId: id,
            status: pendingStatusId,
            name: '',
            email: '',
            type: coveredEntity ? 'default' : 'additional',
            selectOrUserCode: '',
            isMandatoryCheckbox: false,
          },
        ],
      };
    });

    methods?.setValue(`representative[${oldIndex + 1}].role`, '');
    methods?.setValue(`representative[${oldIndex + 1}].userReferenceCode`, '');
    methods?.setValue(`representative[${oldIndex + 1}].isAllowedAccess`, false);
    methods?.setValue(`representative[${oldIndex + 1}].index`, oldIndex + 1);

    if (isGMPI) {
      methods?.setValue(`representative[${oldIndex + 1}].disableReferenceField`, true);
      setEnableAddMoreBtn(false);
    }
  };

  const handleRereseQubicGMPI = async () => {

    const foundAdded = Representative?.find((item) => item.userReferenceCode === SelfInformation[0]?.ReferenceCode);

    if (isEmpty(foundAdded) && isGMPI) {
      setTimeout(() => {
        onSelfOrUserCodeTypeChange('Self', 0);
      }, 500);

    }
  };

  useEffect(() => {
    if (!isEmpty(SelfInformation)) {
      handleRereseQubicGMPI();
    }
  }, [stepData?.editMode, SelfInformation, Representative]);

  const handleReset = async (type = null, userAction = false) => {
    setEnableAddMoreBtn(true);
    setDisabledAddRepresentative(false);
    if (Representative.length === 0 && isGMPI && type !== 'add') {
      await setDefaultValuesForm(INITIALDEFAULTVALUE);
      if (userAction) {
        methods?.trigger('representative');
      }
      return false;
    }

    await setDefaultValuesForm(INITIALDEFAULTVALUE);

    if (isGMPI) {
      const INT_VALUE = {
        representative: INITIALDEFAULTVALUE.representative.map(item => {
          item.userReferenceCode = '';
          item.selectOrUserCode = '';
          item.isAllowedAccess = false;
          item.role = '';
          return item;
        }),
      };
      methods.reset(INT_VALUE);
      methods?.clearErrors('representative');
    } else {
      const INT_VALUE = {
        representative: INITIALDEFAULTVALUE.representative.map(item => {
          item.userReferenceCode = '';
          item.selectOrUserCode = '';
          item.isAllowedAccess = false;
          item.role = '';
          return item;
        }),
      };
      methods.reset(INT_VALUE, {
        keepErrors: true,
      });
      methods?.clearErrors('representative');
    }

    setTimeout(() => {
      methods?.clearErrors('representative');
    }, 1000);
    dispatch(setEnableSaveBtnOnReview(true));
  };

  const handleActivateRepresentative = (representativeId, comment) => {
    dispatch(setLoader(true));
    dispatch(activateRepresentative(representativeId, id, comment));
  };
  const handleCanceRepresentative = (representativeId) => {
    dispatch(setLoader(true));
    dispatch(cancelRepresentative(representativeId, id));
  };

  const handleSuspendRepresentative = (representativeId, comment) => {
    dispatch(setLoader(true));
    dispatch(suspendRepresentative(representativeId, id, comment));
  };

  const handleRetireRepresentative = (representativeId) => {
    dispatch(setLoader(true));
    dispatch(
      retireRepresentative(representativeId, id, () => {
        if (isIndividualUser) {
          navigate('/home');
        }
      }),
    );
  };

  const handleDeleteRepresentative = (representativeId) => {
    dispatch(setLoader(true));
    dispatch(deleteRepresentative(representativeId, id));
  };

  const handleReferenceCode = (data, repIndex) => {
    return new Promise((resolve) => {
      const value = methods?.getValues(`${data}.userReferenceCode`);
      if (value?.length === 12) {
        getValidateReferenceCode(value)
          .then((response) => {
            if (response?.data) {
              setDisabledAddRepresentative(false);
              methods?.setValue(`${data}.email`, response.data[0].Email);
              methods?.setValue(`${data}.name`, `${response.data[0].Name} ${response.data[0].FamilyName}`);
              methods?.setValue(`${data}.jurisdiction`, response.data[0].jurisdictionType);
              dispatch(setEnableSaveBtnOnReview(true));
              setEnableAddMoreBtn(true);
              addRepresentativeRef.current.focus();
              resolve(null);
            }
          })
          .catch((err) => {
            const currentTab = defaultValuesForm.representative.find(item => item.index === repIndex);
            if (currentTab?.selectOrUserCode !== 'Self') {
              methods?.setError(`${data}.userReferenceCode`, {
                type: 'custom',
                message: t(t1, 'INVALID_REFERENCE_CODE'),
              });
            }
            setEnableAddMoreBtn(false);
            dispatch(setEnableSaveBtnOnReview(false));
            resolve(t(t1, 'INVALID_REFERENCE_CODE'));

          });
      } else {
        setEnableAddMoreBtn(false);
        resolve(t(t1, 'INVALID_REFERENCE_CODE'));
      }
    });
  };

  const onSelfOrUserCodeTypeChange = (value, repIndex) => {
    const newValue = defaultValuesForm?.representative.map((item) => {
      if (item.index === repIndex) {
        // eslint-disable-next-line no-unneeded-ternary
        item.isAllowedAccess = value === 'Self' ? true : false;
        item.selectOrUserCode = value;
        item.userReferenceCode = value === 'Self' ? SelfInformation[0]?.ReferenceCode : '';
        item.name = value === 'Self' ? `${SelfInformation[0]?.Name} ${SelfInformation[0]?.FamilyName}` : '';
        item.email = value === 'Self' ? SelfInformation[0]?.Email : '';
      }
      return item;
    });

    if (value === 'Self') {
      setEnableAddMoreBtn(true);
      dispatch(setEnableSaveBtnOnReview(true));
      methods?.clearErrors(`representative[${repIndex}].userReferenceCode`);
      setRepFormValue(`representative[${repIndex}]`, 'userReferenceCode', SelfInformation[0]?.ReferenceCode);


      methods?.setValue(
        `representative[${repIndex}].name`,
        `${SelfInformation[0]?.Name} ${SelfInformation[0]?.FamilyName}`,
      );
      methods?.setValue(`representative[${repIndex}].email`, SelfInformation[0]?.Email);
      methods?.setValue(`representative[${repIndex}].disableReferenceField`, true);
      methods?.setValue(`representative[${repIndex}].isAllowedAccess`, true);
      methods?.clearErrors(`representative[${repIndex}].userReferenceCode`);
      methods.trigger(`representative[${repIndex}].userReferenceCode`);
      validateRefCode(SelfInformation[0]?.ReferenceCode, repIndex);
    } else {
      const codeVal = methods?.getValues(`representative[${repIndex}].userReferenceCode`);
      methods?.setValue(`representative[${repIndex}].userReferenceCode`, '');
      methods?.setValue(`representative[${repIndex}].name`, '');
      methods?.setValue(`representative[${repIndex}].email`, '');
      methods?.setValue(`representative[${repIndex}].disableReferenceField`, false);
      methods?.setValue(`representative[${repIndex}].isAllowedAccess`, false);
      if (!isEmpty(codeVal)) {
        methods.trigger(`representative[${repIndex}].userReferenceCode`);
      }
    }

    setDefaultValuesForm((value) => {
      return {
        ...value,
        representative: newValue,
      };
    });

    checkDuplicateRefs();
  };

  const handleAlloweAccessCheck = (e, repIndex) => {
    const newValue = defaultValuesForm?.representative?.map((item) => {
      if (item.index === repIndex) {
        item.isAllowedAccess = e.target?.checked;
      }
      return item;
    });
    methods?.setValue(`representative[${repIndex}].isAllowedAccess`, e.target.checked);
    setDefaultValuesForm((value) => {
      return {
        ...value,
        representative: newValue,
      };
    });
  };


  const [ARParams, setARParam] = useState(null);
  const [ARelement, setARelement] = useState(null);

  const handleChangeRepFromTableAfterContinue = () => {
    setCheckRepEdited(true);
    if (verifyRepEdited) {
      verifyRepEdited(true);
    }
    const getRoleObjectbyId = roleOptions?.find((item) => item?.id === ARelement?.target?.value);
    dispatch(
      setProposedRepresentativeRoleByIndex({
        role: getRoleObjectbyId,
        value: ARelement?.target?.value,
        id: ARParams?.row?.id,
      }),
    );
    setARParam(null);
    setARelement(null);
    setShowPopupOnRoleSwap(false);
  };

  const handleARSwapAction = (params, e) => {
    setARParam(params);
    setARelement(e);
    setShowPopupOnRoleSwap(true);
  };

  const handleChangeRepFromTable = (params, e) => {
    setCheckRepEdited(true);
    if (verifyRepEdited) {
      verifyRepEdited(true);
    }
    const getRoleObjectbyId = roleOptions?.find((item) => item?.id === e?.target?.value);
    dispatch(
      setProposedRepresentativeRoleByIndex({
        role: getRoleObjectbyId,
        value: e?.target?.value,
        index: params?.row?.index,
      }),
    );
  };

  const viewOnly = mode === COMPONENT_MODES.EDIT && !stepData?.editMode;
  const isEditable = EditAccess;
  const getRadioOption = (repIndex, representativeObject) => {
    let res;
    if (isJurisdictionRegistrarUser) {
      res = [REPRESENTATIVE_RADIO_OPTIONS.userRefCode];
      return res;
    }
    if (repIndex === 0 && isGMPI && Representative?.length === 0) {
      res = [REPRESENTATIVE_RADIO_OPTIONS.self];
    } else if (repIndex === 0 && isGMPI && Representative?.length > 0) {
      res = [REPRESENTATIVE_RADIO_OPTIONS.userRefCode];
      //      res = mode === COMPONENT_MODES.EDIT ? [REPRESENTATIVE_RADIO_OPTIONS.userRefCode, REPRESENTATIVE_RADIO_OPTIONS.self] : [REPRESENTATIVE_RADIO_OPTIONS.self]
    } else if (isGMPI && representativeObject.type === 'additional') {
      res = [REPRESENTATIVE_RADIO_OPTIONS.userRefCode];
    } else {
      res = [REPRESENTATIVE_RADIO_OPTIONS.userRefCode, REPRESENTATIVE_RADIO_OPTIONS.self];
    }
    return res;
  };

  const getRoleOptions = (repIndex) => {
    const foundAdded = tableRow.find((item) => item.userReferenceCode === SelfInformation[0]?.ReferenceCode);
    let list = roleOptions.filter(role => {
      if (pathname.includes('entity-registration')) {
        return [REPRESENTATIVES_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE, REPRESENTATIVES_ROLES.ALTERNATE_ACCOUNT_REPRESENTATIVE].includes(role.name);
      }
      return true;
    });
    if (isGMPI &&
      EntitiesData?.data?.entityStatus?.name !== ENTITY_APPLICTION_STATUS_NAMES.ACTIVE) {
      if (foundAdded || repIndex > 0) {
        list = roleOptions?.filter((each) => {
          return each;
        });
      } else if (!foundAdded) {
        list = roleOptions?.filter((each) => {
          return each.name === REPRESENTATIVES_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE;
        });
      }
      if (list?.length === 1 && list[0]?.id) {
        setTimeout(() => {
          setRepFormValue(`representative[${repIndex}]`, 'role', list[0].id);
        }, 500);
      }
      return list?.map((each) => ({ name: t(t1, each.name), value: each.id }));
    }
    if (isJurisdictionRegistrarUser) {
      list = roleOptions?.filter((each) => {
        return each.name === REPRESENTATIVES_ROLES.PRIMARY_ACCOUNT_REPRESENTATIVE;
      });
    }

    return list?.map((each) => ({ name: t(t1, each.name), value: each.id }));
  };

  const validateRefCode = debounce(async (value, repIndex) => {
    if (isEmpty(value)) {
      dispatch(setEnableSaveBtnOnReview(true));
    }
    const reps = methods?.getValues('representative');
    // tableRow contains proposed(change PAR) and active representative
    const existingRep = tableRow?.find((item) => (item.userReferenceCode?.toLowerCase() === value?.toLowerCase() || item.proposeChanges?.userReferenceCode?.toLowerCase() === value?.toLowerCase()) && (item.status?.toLowerCase() !== 'retired' || item.status?.toLowerCase() !== 'retired-f'));
    const isDuplicate = reps.find((item) => {
      /* eslint-disable */
      return value?.toLowerCase() === item.userReferenceCode?.toLowerCase() && item.index < repIndex;
    });
    if (
      existingRep &&
      [REPRESENTATIVE_STATUS.RETIRED, REPRESENTATIVE_STATUS.SUSPENDED].includes(existingRep.status.Name)
    ) {
      return true;
    }
    if ((isDuplicate || existingRep)) {
      return methods?.setError(`representative[${repIndex}].userReferenceCode`, {
        type: 'custom',
        message: t(t1, 'DUPLICATE_USER_REFERENCE_CODE_IS_NOT_ALLOWED'),
      });
    } else {
      methods?.clearErrors(`representative[${repIndex}].userReferenceCode`);
    }
    if (checkingEmptySpace(value, `${t(t1, 'USER_REFERENCE_CODE')}`, t1, `${t(t1, 'ERRR_INVALID_USER_REFERENCE_CODE')}`)) {
      return checkingEmptySpace(value, `${t(t1, 'USER_REFERENCE_CODE')}`, t1, `${t(t1, 'ERRR_INVALID_USER_REFERENCE_CODE')}`);
    }
    const message = await handleReferenceCode(`representative[${repIndex}]`, repIndex);
    return message;
  }, 100);

  const validateRef = debounce(async (value) => {

    if (value?.length > 12 || value?.length < 12 || userReferenceCodeRegex.test(value) === false) {
      return userRefModalMethods?.setError(`userRefCode`, {
        type: 'custom',
        message: t(t1, 'INVALID_REFERENCE_CODE'),
      });
    }

    dispatch(setLoader(true));
    const selectedAR = userRefModalMethods.getValues('selectedAR');
    const existingRep = selectedAR?.userReferenceCode === value || Representative?.find((item) => item.userReferenceCode === value);
    if (existingRep) {
      dispatch(setLoader(false));
      return userRefModalMethods?.setError(`userRefCode`, {
        type: 'custom',
        message: t(t1, 'DUPLICATE_USER_REFERENCE_CODE_IS_NOT_ALLOWED'),
      });
    }
    return new Promise((resolve) => {
      getValidateReferenceCode(value)
        .then((response) => {
          if (response?.data) {
            userRefModalMethods.setValue('userDetails', response?.data[0]);
            setDisabledAddRepresentative(false);
            setTimeout(() => {
              userRefModalMethods.clearErrors('userRefCode');
            }, 1000);

            dispatch(setLoader(false));
            resolve(null);
          }
        })
        .catch((err) => {
          if (err.response?.data?.messageKey === 'USER_BELONGS_TO_ANOTHER_JURISDICTION') {
            userRefModalMethods?.setError('userRefCode', {
              type: 'custom',
              message: t(t1, 'USER_BELONGS_TO_ANOTHER_JURISDICTION'),
            });
            resolve(t(t1, 'USER_BELONGS_TO_ANOTHER_JURISDICTION'));
            dispatch(setLoader(false));
            return;
          }
          if (err.response?.data?.messageKey === 'ERROR') {
            resolve(t(t1, 'INVALID_REFERENCE_CODE'));
          }
          userRefModalMethods?.setError('userRefCode', {
            type: 'custom',
            message: t(t1, 'INVALID_REFERENCE_CODE'),
          });
          resolve(t(t1, 'INVALID_REFERENCE_CODE'));
          dispatch(setLoader(false));
          return;
        });
    });
  }, 100);

  const changePARHandler = () => {
    dispatch(setLoader(true));
    const reps = Representative?.map((item) => {
      const rep = {
        role: item?.role?.id || item?.proposeChanges?.role?.id,
        entityId: id,
        status: item.status ? representativeStatus.find((object) => object.Name === item.status)?.ID : representativeStatus.find((object) => object.Name === item.proposeChanges.status)?.ID,
        action: item.id ? 'Edit' : 'Add',
        userReferenceCode: item?.userReferenceCode ? item?.userReferenceCode : item?.proposeChanges?.userReferenceCode || '',
      };
      if (item?.id) {
        rep.representativeId = item?.id ? item?.id : item?.proposeChanges?.id || null;
      }
      return rep;
    });
    const newAR = userRefModalMethods?.getValues('userDetails');
    const selectedAR = userRefModalMethods.getValues('selectedAR');
    reps.push({
      role: selectedAR?.role?.id,
      userReferenceCode: newAR?.ReferenceCode,
      isAllowedAccess: true,
      name: newAR?.Name,
      entityId: id,
      email: newAR?.Email,
      status: propsedStatusID,
      action: 'Replace',
      representativeId: selectedAR?.id,
    });
    userRefModalMethods?.reset();
    updateProposedRepresentative({ representative: reps }, id)
      .then((response) => {
        const data = {
          title: t(t1, 'SUCCESS'),
          message1: response?.messageKey,
          error: false,
        };
        dispatch(setModelMessage(data));
        dispatch(getRepresentative(id, representativesPageDetail));
        dispatch(setRepresentative(reps));
        dispatch(getRepresentativeStatusHistory(id, representativeHistorypageDetails));
        dispatch(getProposedChangeRepresentatives(id));
        dispatch(setLoader(false));
        setCancelRequestOption(true);
        dispatch(getEntityContactById(id));
      })
      .catch((error) => {
        dispatch(setLoader(false));
        AddRepresentativeShowError(error);
      });
  };

  const getRadioSelected = (index) => {
    const optionValue = defaultValuesForm.representative.find(item => item.index === index);
    return isEmpty(optionValue.selectOrUserCode);
  };
  const renderSingleRow = (representativeObject, repIndex) => {
    const foundAdded = tableRow?.find((item) => item.userReferenceCode === SelfInformation[0]?.ReferenceCode);
    if (repIndex === 0 && !isEmpty(foundAdded) && isGMPI) {
      representativeObject.userReferenceCode = '';
      representativeObject.role = '';
      representativeObject.name = '';
      representativeObject.email = '';
      representativeObject.disableReferenceField = false;
    }

    // to remove PAR when it is QUIBIC and GMPI and more than on rep added

    // add disabled by permissin
    const disableByPermission =
      (EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.CLOSED ||
        EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.ACTIVE) &&
      isIndividualUser &&
      isJurisdictionRegistrarUser &&
      EditAccess;
    return (
      <>
        {showChangeModal && (
          <CustomeDialogMessage
            title={t(t1, 'CHANGE_PRIMARY_ACCOUNT_REPRESENTATIVE_TO')}
            handleButtonClick={() => {
              setShowChangeModal(false);
            }}
            handleContinue={() => {
              setShowChangeModal(false);
              changePARHandler();
            }}
            handleModalCancel={() => {
              setShowChangeModal(false);
              userRefModalMethods?.reset();
            }}
            handleClose={() => {
              setShowChangeModal(false);
              userRefModalMethods?.reset();
            }}
            open={showChangeModal}
            message1={modalBodyMessage}
            borderColor={hideComment ? '#ca2c1c' : '#283459'}
            // disabled={userRefModalMethods.formState?.isValid}
            disableContinue={!userRefModalMethods.formState?.isValid || userRefModalMethods?.formState?.errors?.userRefCode?.type}
            continueBtn={t(t1, 'CHANGE')}
            closeBtnDisplay={t(t1, 'CANCEL')}
          >
            <FormProvider {...userRefModalMethods}>
              <RadioSelectionUser
                radioOptions={[[
                  {
                    name: 'User Reference Code',
                    value: 'UserReferenceCode',
                    privId: 'user_reference_code',
                    isDisplay: true,
                  },
                ]]}
                selectedRadio={'UserReferenceCode'}
                methods={userRefModalMethods}
                Style={Style}
                customeStyle={{ height: '5px' }}
              ></RadioSelectionUser>
              <GridTextfield
                gridMd={4}
                customeClass=""
                inputClass=""
                name={'userRefCode'}
                placeholder={representativeObject.selectOrUserCode !== 'Self' ? t(t1, 'ENTER_USER_REFERENCE_CODE') : ''}
                rules={{
                  required: true,
                  minLength: {
                    value: 11,
                    message: `${t(t1, 'ERR_USER_REFERENCE_CODE_MIN_LENGTH')}`,
                  },
                  maxLength: {
                    value: 12,
                    message: `${t(t1, 'ERR_USER_REFERENCE_CODE_MAX_LENGTH')}`,
                  },
                  // validate: (data) => validateRef(data),
                }}
                isRequiredMsg={'ERR_USER_REFERENCE_CODE_IS_REQUIRED'}
                onKeyUp={(data) => validateRef(data?.value)}
                InputProps={{
                  'aria-required': 'true',
                }}
              />
            </FormProvider>
          </CustomeDialogMessage>
        )}

        {defaultValuesForm?.representative && <RadioSelectionUser
          radioOptions={getRadioOption(repIndex, representativeObject)}
          onChangeRadio={(e) => onSelfOrUserCodeTypeChange(e.target.value, repIndex)}
          selectedRadio={representativeObject.selectOrUserCode}
          methods={methods}
          Style={Style}
          disabled={disableByPermission || !EditAccess}
          // disabled={!isEditable}
        />}
        <span style={{ display: 'none' }}>
          <GridTextfield
            type="hidden"
            name={`representative[${repIndex}].email`}
          />
          <GridTextfield
            type="hidden"
            name={`representative[${repIndex}].name`}
          />
          <GridTextfield
            type="hidden"
            name={`representative[${repIndex}].entityId`}
          />
        </span>
        <Grid
          container
          spacing={2.5}
          sx={Style.manageRoleLayoutGrid}
        >
          {SopfDetailView(<Grid
            item
            xs={EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.ACTIVE ? 3 : 4}
            sx={Style.customMarginforReferencebox}
            className="radio-group"
          >
            <GridTextfield
              componentName="addRepresentative"
              customTextFieldStyle={EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.ACTIVE ? { width: '339px' } : {}}
              fieldName="useReferenceCode"
              name={`representative[${repIndex}].userReferenceCode`}
              placeholder={representativeObject.selectOrUserCode !== 'Self' ? t(t1, 'ENTER_USER_REFERENCE_CODE') : ''}
              rules={{
                required: true,
                minLength: {
                  value: 12,
                  message: `${t(t1, 'ERR_USER_REFERENCE_CODE_MIN_LENGTH')}`,
                },
                maxLength: {
                  value: 12,
                  message: `${t(t1, 'ERR_USER_REFERENCE_CODE_MAX_LENGTH')}`,
                },
                onChange: async (e) => {
                  if (!e.target.value) {
                    dispatch(setEnableSaveBtnOnReview(true));
                  }
                  if (e.target.value.length === 12) {
                    setDisabledAddRepresentative(true);
                    const currentTab = defaultValuesForm.representative.find(item => item.index === repIndex);
                    const message = await validateRefCode(e.target.value, repIndex);
                    checkDuplicateRefs();
                    if (!(currentTab?.selectOrUserCode === 'Self' && message === t(t1, 'INVALID_REFERENCE_CODE'))) {
                      return message;
                    }
                    return '';
                  }
                },
              }}
              isRequiredMsg={'ERR_USER_REFERENCE_CODE_IS_REQUIRED'}
              stepNumber={2}
              gridMd={12}
              // disabled={methods?.getValues(`representative[${repIndex}].disableReferenceField`) === undefined || methods?.getValues(`representative[${repIndex}].disableReferenceField`) ||  isEditable}
              disabled={
                getRadioSelected(repIndex) ||
                disableByPermission ||
                methods?.getValues(`representative[${repIndex}].disableReferenceField`) === undefined ||
                methods?.getValues(`representative[${repIndex}].disableReferenceField`) ||
                !isEditable
              }
            />
          </Grid>, 'addRepresentative', 'userReferenceCode', fieldPermissions)}
          {SopfDetailView(<Grid
            item
            xs={4}
            style={{ paddingLeft: '31px' }}
            sx={{ mt: '-20px' }}
          >
            <CustomAutocomplete
              componentName="addRepresentative"
              fieldName="role"
              privId="priv_txt_role"
              forcePopupIcon={!(isGMPI && repIndex === 0 && !foundAdded)}
              onClose={() => methods.trigger(`representative[${repIndex}].role`)}
              name={`representative[${repIndex}].role`}
              noValueText={t(t1, 'SELECT_ROLE')}
              label={t(t1, 'ROLE')}
              options={getRoleOptions(repIndex)}
              rules={{ required: t(t1, 'ROLE_REQUIRED') }}
              isRequiredMsg={'ERR_ROLE_IS_REQUIRED'}
              InputProps={{
                'aria-required': 'true',
              }}
              className="input-group"
              customTextFieldStyle={EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.ACTIVE ? { width: '339px' } : {}}
              gridMd={12}
              disableByPermission
              disabled={
                (isGMPI && repIndex === 0 && !foundAdded) ||
                !isEditable
              }
              handleOnChange={(e) => {
                setNewRole(e?.value);
                addRepresentativeRef.current.focus();
                const newValue = defaultValuesForm?.representative?.map((item) => {
                  if (item.index === repIndex) {
                    item.role = e?.value;
                  }
                  return item;
                });
                setDefaultValuesForm((value) => {
                  return {
                    ...value,
                    representative: newValue,
                  };
                });
              }}
            />
          </Grid>, 'addRepresentative', 'role', fieldPermissions)}

          {EntitiesData?.data?.entityStatus?.name !== ENTITY_APPLICTION_STATUS_NAMES.CLOSED &&
            EntitiesData?.data?.entityStatus?.name !== ENTITY_APPLICTION_STATUS_NAMES.ACTIVE && (
              <Grid
                item
                xs={4}
                sx={{ mt: '-5px' }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: 700, ml: 0 }}>
                  {t(t1, 'EDIT_ACCESS')}{' '}
                  <span style={{ color: '#CA2C1C' }}>{representativeObject.isMandatoryCheckbox ? null : '*'}</span>
                  <CustomizeToolTip
                    title={
                      <Typography
                        color="inherit"
                        variant="p"
                      >
                        {t(t1, 'ALLOW_THIS_USER_TO_EDIT_ENTITY_ACCOUNT_APPLICATION')}
                      </Typography>
                    }
                    placement="right-start"
                    className="sm-tooltip"
                  >
                    <InfoIcon sx={{ color: '#4d8da4', width: '14px', height: '14px' }} />
                  </CustomizeToolTip>
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <RHFCheckbox
                    tabIndex
                    componentName="addRepresentative"
                    fieldName="editAccess"
                    value={representativeObject.isAllowedAccess}
                    onChange={(e) => handleAlloweAccessCheck(e, repIndex /*, representativeObject.type*/)}
                    name={`representative[${repIndex}].isAllowedAccess`}
                    label={t(t1, 'ALLOW_ACCESS')}
                    btnDisabled={representativeObject?.selectOrUserCode === 'Self' ? true : false || !isEditable}
                    sx={Style.checkBoxLabel}
                  />
                </Box>
              </Grid>
            )}
        </Grid>
      </>
    );
  };

  // if (!isEmpty(Representative) && !isEmpty(SelfInformation)) {
  //   const existingRep = Representative?.find(item => item.userReferenceCode === SelfInformation[0]?.ReferenceCode)
  //   if (existingRep) {
  //     defaultValuesForm.representative = defaultValuesForm?.representative.filter((item) => item.userReferenceCode !== existingRep.userReferenceCode)
  //   }
  // }

  const getMessage = () => {
    if (isEmpty(representativeActionType)) {
      return {};
    }

    if (representativeActionType == 'propsed') {
      return {
        message1: 'PROPOSE_USER_FROM_ROLE_MESSAGE1',
        message2: 'PROPOSE_USER_FROM_ROLE_MESSAGE2',
      };
    }
    if (representativeActionType == 'cancel') {
      return {
        message1: 'CANCEL_REPRESENATATIVE_PROPOSED_MESSAGE1',
        message2: 'CANCEL_REPRESENATATIVE_PROPOSED_MESSAGE2',
      };
    }
    if (representativeActionType == 'retire') {
      return {
        message1: 'RETIRE_REPRESENATATIVE_MESSAGE1',
        message2: 'RETIRE_REPRESENATATIVE_MESSAGE2',
      };
    }

    if (representativeActionType == 'activate') {
      return {
        message1: 'ACTIVATE_REPRESENTATIVE_MESSAGE1',
        message2: 'ACTIVATE_REPRESENTATIVE_MESSAGE2',
      };
    }
    if (representativeActionType == 'suspend') {
      return {
        message1: 'SUSPEND_REPRESENTATIVE_MESSAGE1',
        message2: '',
      };
    }

    return {
      message1: 'REMOVE_USER_FROM_ROLE_MESSAGE1',
      message2: 'REMOVE_USER_FROM_ROLE_MESSAGE2',
    };
  };

  const getAlternateValues = (values) => {
    setAlternateContactDetails(values);
    if (getAlternateValuesforEntityProfile) {
      getAlternateValuesforEntityProfile(values);
    }
  };

  const checkAlternateValidation = (value) => {
    setValidAlternateContactFormState(value);
    if (checkFormIsValid) {
      checkFormIsValid(value);
    }
  };

  let disableAddRepresentativeForRegister = '';
  if (isJurisdictionRegistrarUser) {
    disableAddRepresentativeForRegister = `hideForJurisdictionUser`;
  }
  const disableByPermission =
    (EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.CLOSED ||
      EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.ACTIVE) &&
    isIndividualUser &&
    isJurisdictionRegistrarUser &&
    EditAccess;

  const AddRepresentativeAfterPassPharseSuccess = () => {
    methods?.handleSubmit(SubmitRepresentative);
    setShowPassphraseDialogForAddRepresentative(false);
  };

  const representativeRows = translateRows([...tableRow], ['status', 'jurisdictionName'], t, t1);
  const proposedRepresentatives = representativeRows
    .map(({ proposeChanges }) => (Object.keys(proposeChanges || {}).length ? proposeChanges : null))
    .filter((row) => row);
  const rowsForGrid = representativeRows.map((row) => ({
    ...row,
    updatedProposed: proposedRepresentatives.find((status) => status.representativeId === row.id),
  }));

  return (
    <>
      {isLoading && <Loader loader={isLoading} />}
      <FormProvider {...methods}>
        {/* Read more section  */}

        {displayHead && (
          <Typography style={Style.contentTextCard}>
            <div className="contentTextCardTwo" style={Style.contentTextCard}>
              <TextTruncate
                content={t(t1, 'FOOTER_DISCLAIMER_CONTENT')}
                animate
              />
            </div>
          </Typography>
        )}

        {/* Add Representative */}
        {(stepData && stepData?.editMode) || mode === COMPONENT_MODES.ADD ? (
          <FormCard
            customClass={`customFormCard ${disableAddRepresentativeForRegister}`}
            customeStyle={{ ...Style.formCardPaddingLg, ...Style.formCardMarginLg }}
            title={
              mode === COMPONENT_MODES.ADD
                ? t(t1, 'ADD_REPRESENTATIVES')
                : stepData?.editMode && t(t1, 'ADD_REPRESENTATIVES')
            }
            indicateRequired
            description={isRevisionRequested ? false : t(t1, 'INDICATE_REQUIRED_FIELDS')}
            setDefaultValues={setDefaultValues}
            titleSize
            // style={{ paddingBottom: "20px" }}
          >
            {/* for Covered Entity */}
            {Roles && (
              <div>
                {defaultValuesForm?.representative?.map((representativeObject, index) => {
                  return (
                    <div key={`single-row-container-${index}`}>
                      {renderSingleRow(representativeObject, representativeObject.index)}
                    </div>
                  );
                })}
              </div>
            )}

            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sx={Style.btnContainer}
              >
                <Button
                  variant="outlined"
                  disableElevation
                  disabled={!isValid || !isEditable || !enableAddMoreBtn || disabledAddRepresentative || !!methods?.formState?.errors?.representative?.length}
                  size="small"
                  onClick={() => handleAddMore()}
                  // disabled={!enableAddMoreBtn}
                  sx={{ mr: 0, ...Style.btnOutline }}
                >
                  {t(t1, 'ADD_MORE')}
                </Button>
                <Box>
                  <Button
                    id="RepClearBtn"
                    variant="outlined"
                    disableElevation
                    size="small"
                    sx={{ mr: 1, ...Style.btnOutline }}
                    onClick={() => handleReset(null, true)}
                    customeStyle={Style.btnOutline}
                    disabled={disableByPermission || !EditAccess}
                  >
                    {t(t1, 'RESET_CLEAR')}
                  </Button>
                  <Button
                    ref={addRepresentativeRef}
                    disabled={(methods && (!methods?.formState?.isValid || !enableAddMoreBtn || disabledAddRepresentative || !!methods?.formState?.errors?.representative?.length))}
                    // disabled={!isValid || isEditable}
                    variant="contained"
                    type="submit"
                    disableElevation
                    size="small"
                    sx={{ mr: 0, ...Style.btnPrimary }}
                    onClick={(e) => {
                      if (
                        EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.CLOSED ||
                        EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.ACTIVE
                      ) {
                        setShowDownloadFormgModal(true);
                      } else {
                        methods?.handleSubmit(SubmitRepresentative)(e);
                      }
                      if (representativeAddFunc) {
                        representativeAddFunc();
                      }
                    }}
                  >
                    {t(t1, 'ADD')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </FormCard>
        ) : null}

        {/* Representative table and Alternate contact detail */}

        {/* Representative Table */}
        <div className={`${viewOnly ? 'representative-viewonly' : 'representative-editmode'}`}>
          <FormCard
            customClass={'customFormCard'}
            customeStyle={
              !isJurisdictionRegistrarUser &&
              [ENTITY_APPLICTION_STATUS_NAMES.PENDING, ENTITY_APPLICTION_STATUS_NAMES.REVISIONS_REQUESTED].includes(
                EntitiesData?.data?.entityStatus?.name,
              )
                ? Style.styleForPendingandRevision
                : Style.formCardPadding
            }
            title={mode === COMPONENT_MODES.ADD && (t(t1, 'REPRESENTATIVES') || 'Representatives')}
            indicateRequired={false}
            setDefaultValues={setDefaultValues}
            // stepNumber={3}
            hideCard={hideCard}
          >
            {showRepTableTitle ? (
              <Typography
                variant="h2"
                component="div"
                sx={{ fontSize: 24 }}
                className={hideCard ? 'represetativeText' : ''}
              >
                {' '}
                {t(t1, 'REPRESENTATIVES')}
              </Typography>
            ) : null}
            <Grid
              container
              spacing={2}
              sx={{
                mt: 0,
                mb:
                  EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.PENDING ||
                  ENTITY_APPLICTION_STATUS_NAMES.REVISIONS_REQUESTED
                    ? 0
                    : 2.5,
              }}
            >
              <Grid
                item
                sm={12}
              >
                <MUITableGrid
                  getRowClassName={(params) => {
                    if (EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.ACTIVE || EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.CLOSED) {
                      return params?.row?.status === 'Proposed' ? 'PROPOSED' : null;
                    }
                    // return !isEmpty(params?.row?.proposeChanges) && "PROPOSED";
                  }}
                  isExport
                  isSearch
                  isSettings
                  getRowId={(row) => row.id}
                  rows={rowsForGrid}
                  columns={tableColoums}
                  page={1}
                  customTableStyle={Style.tableStyle}
                  customLocaleText={{ noRowsLabel: t(t1, 'NO_REPRESENTATIVES_ADDED') }}
                  fileName={t(t1, 'REPRESENTATIVES')}
                />
              </Grid>
            </Grid>
          </FormCard>

          {showWarningModal && (
            <CustomeDialogMessage
              title={t(t1, 'WARNING')}
              continueBtn={t(t1, 'CONTINUE')}
              open={showWarningModal}
              handleModalCancel={() => {
                setShowWarningModal(null);
              }}
              handleClose={() => {
                setShowWarningModal(null);
                setValue('comment', '');
                resetField('comment');
              }}
              message1={t(t1, getMessage().message1)}
              message3={t(t1, getMessage().message2)}
              multipleErrorKeys={t(t1, getMessage().multipleErrorKeys)}
              handleContinue={handleModalContinue}
              borderColor="red"
            />
          )}

          <CustomeDialogMessage
            title={t(t1, 'ADD_NEW_ACCOUNT_REPRESENTATIVE')}
            message1={t(
              t1,
              'PLEASE_SUBMIT_THE_FOLLOWING_FORM_TO_COMPLETE_THE_REQUEST_TO_ADD_NEW_ACCOUNT_REPRESENTATIVE',
            )}
            message2={
              <NavLink
                target="_blank"
                to={Pdf}
                without
                rel="noopener noreferrer"
                className="form_links"
              >
                {t(t1, 'REQUEST_TO_CHANGE_THE_ACCOUNT_REPRESENTATIVE_FORM')}
              </NavLink>
            }
            message3={t(t1, 'THIS_FORM_CAN_ALSO_BE_FOUND_IN_THE_FORMS_SECTION_OF_THE_ENTITY_PROFILE')}
            continueBtn={t(t1, 'CONTINUE')}
            handleClose={() => {
              setShowDownloadFormgModal(false);
            }}
            open={showDownloadFormgModal}
            handleContinue={(e) => {
              setShowDownloadFormgModal(false);
              methods?.handleSubmit(SubmitRepresentative)(e);
            }}
            customStyling={'new-rep-dialog'}
            borderColor="#333"
          />
        </div>
        {/* Representative Table */}
        {/* Representative Status History Table Start */}
        {(EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.CLOSED ||
            EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.ACTIVE) &&
          (!stepData?.editMode || isJurisdictionRegistrarUser) && <RepresentativeStatusHistorySection />}
        {/* Representative Status History Table Ends */}
        {/* Alternate contact person */}
        {hideAlternateContactForm && (
          // condition commented because alternate contant needed on Active/Pending/Revision view only mode and Editable mode
          // ((stepData && stepData?.editMode) || mode === COMPONENT_MODES.ADD) &&
          <AlternateContactForm
            getAlternateValues={getAlternateValues}
            checkAlternateValidation={checkAlternateValidation}
            viewOnlyProps={!stepData?.editMode && (isRevisionRequested || isProposed)}
          />
        )}
      </FormProvider>
      <FormProvider {...methodsAlternateData}>
        {/* on submit */}
        {mode === COMPONENT_MODES.ADD && (
          <FormActions
            page={page}
            continueDisabled={!EditAccess || tableRow.length === 0 || continueBtnClicked || !validAlternateContactFormState}
            handleSubmit={(data) => {
              // onSubmit(data);
              onSubmit();
            }}
            stepNumber={4}
            onExitClick={() => setIsExitInProgress(true)}
            onCancelExit={() => setIsExitInProgress(false)}
          />
        )}
        <UnsavedPrompt id={id} when={!isExitInProgress && displayHead} title={t(t1, 'WARNING')}
                       message={t(t1, 'CONFIRM_LEAVE_PAGE')} onSuccess={resetData} />
      </FormProvider>

      {showInactiveUser && (
        <CustomeDialogMessage
          title={t(t1, modaDataObj?.title)} // {t(t1, "INACTIVE_USER")}
          message1={t(t1, modaDataObj?.message)}
          // buttonMessage={t(t1, modaDataObj?.buttonMessage)}
          continueBtn={modaDataObj?.buttonText}
          handleClose={() => {
            setValue('comment', '');
            resetField('comment');
            setShowInactiveUser(false);
          }}
          open={showInactiveUser}
          handleContinue={() => {
            modaDataObj?.handleModalContinue(modalMethods?.getValues('comment'));
          }}
          disableContinue={!modalMethods.formState.isValid}
        >
          <FormProvider {...modalMethods}>
            <Grid item>
              <GridTextfield
                gridMd={12}
                multiline
                customeClass=""
                className="input-group"
                rows={2}
                inputClass=""
                label={t(t1, modaDataObj?.textBoxName)}
                name={'comment'}
                rules={modaDataObj?.rules}
                InputProps={{
                  'aria-required': 'true',
                }}
              />
            </Grid>
          </FormProvider>
        </CustomeDialogMessage>
      )}

      {showPopupOnRoleSwap &&
        <CustomeDialogMessage
          title={t(t1, 'SWAP_ACCOUNT_REPRESENTATIVE')}
          message1={t(
            t1,
            'PLEASE_SUBMIT_THE_FOLLOWING_FORM_TO_COMPLETE_THE_REQUEST_TO_SWAP_ACCOUNT_REPRESENTATIVE',
          )}
          message2={
            <NavLink
              target="_blank"
              to={Pdf}
              without
              rel="noopener noreferrer"
              className="form_links"
            >
              {t(t1, 'REQUEST_TO_CHANGE_THE_ACCOUNT_REPRESENTATIVE_FORM')}
            </NavLink>
          }
          message3={t(t1, 'THIS_FORM_CAN_ALSO_BE_FOUND_IN_THE_FORMS_SECTION_OF_THE_ENTITY_PROFILE')}
          continueBtn={t(t1, 'CONTINUE')}
          handleClose={() => {
            setShowPopupOnRoleSwap(false);
          }}
          open={showPopupOnRoleSwap}
          handleContinue={(e) => {
            handleChangeRepFromTableAfterContinue();
          }}
          customStyling={'new-rep-dialog'}
          borderColor="#333"
        />
      }

      {showPassphraseDialogForAddRepresentative ? (
        <PassphraseConfirmationDialog
          title={t(t1, 'ENTER_PASSPHRASE_TO_SUBMIT')}
          cancelButtonLabel={t(t1, 'CANCEL')}
          confirmButtonLabel={t(t1, 'SUBMIT')}
          pageName={'Page_UserHome'}
          onPassphraseSuccess={AddRepresentativeAfterPassPharseSuccess}
          handleCloseDialog={() => setShowPassphraseDialogForAddRepresentative(false)}
          open={showPassphraseDialogForAddRepresentative}
        />
      ) : null}
    </>
  );
}
