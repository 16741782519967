const Style = {
  formCardPadding: { paddingBottom: '40px', minHeight: '181px' },
  otherCard: { paddingBottom: '20px' },
  onEditPage: {
    paddingBottom: '40px !important',
  },
  disabledLabelStyle: {
    color: '#333 !important',
    visibility: 'visible !important',
  },
  contentEntityContact: {
    'p.MuiFormHelperText-root': {
      display: 'contents!important',
    },
  },
};

export default Style;