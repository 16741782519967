import { Loadable } from 'components/Loadable';
import { lazy } from 'react';

const SetUpJurisdictionAccounts = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.SetUpJurisdictionAccounts,
    })),
  ),
);

const AddJurisdictionAccounts = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.AddJurisdictionAccounts,
    })),
  ),
);

const AddNewJurisdictionAccount = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.AddNewJurisdictionAccount,
    })),
  ),
);

export const setUpJurisdictionAccountsRoutes = {
  path: '/set-up-jurisdiction-accounts',
  children: [
    {
      index: true,
      path: ':jurisdictionId',
      element: <SetUpJurisdictionAccounts />,
    },
    {
      path: 'add/:jurisdictionId',
      element: <AddJurisdictionAccounts />,
    },
    {
      path: 'add-new/:jurisdictionId',
      element: <AddNewJurisdictionAccount />,
    },
  ],
};
