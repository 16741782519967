import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, Grid, Link, Menu, MenuItem, Typography, Tooltip } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { GridTextfield } from 'components';
import { CustomeDialogMessage } from 'components/CustomeDialogMessage';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { setDialogMessage, setLoader, setModelMessage } from 'store/commonSlice';
import { setHideModal, setShowModal } from 'store/customModalSlice';
import {
  getAccountApplications,
  getActionStatus,
  getMyAccounts,
  getMyRepresentativeAssignments,
  getRequestStatus,
  saveEntityStatus,
} from 'store/entityProfileSlice';
import {
  ENTITY_APPLICTION_STATUS_NAMES,
  ENTITY_TYPE_NAMES,
  SUCCESS,
  USER_ROLES,
  USER_ROLE_TYPES,
  formatDate,
  getRolesForActiveEntity,
  getCurrentPageName,
  getEntityAcoountTypeNamesForFilterDropdown,
  getEntityTypeNamesForFilterDropdown,
  isRolesAssigned,
  showOnlyPermittedFields,
  t,
  translateRows,
  getFilterForColumn,
  validateCommentModule,
  COMMENT_MAX_CHARACTER_MODULE,
  getRepresentativeStatusDropdownforFilterDropdown,
  isEmpty,
  formatDateUpdated,
  removeTimeZone,
} from 'utils/constants';
import { getSelfInformation } from 'store/entityApplicationSlice';
import StyledCellContent from 'components/MUITableGrid/StyledCellContent';
import MUITableGrid from '../../components/MUITableGrid/MUITableGrid';
import { ManageJurisdictionSection } from './ManageJurisdictions';
import PendingTransfers from './PendingTransfers';
import Style from './styles.js';

export default function UserApplication() {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const [anchorEl, setAnchorEl] = useState(null);
  const [cancelEntityId, setCancelEntityId] = useState(null);
  const [entityStatusName, setEntityStatusName] = useState('');
  const [entityTypeName, setEntityTypeName] = useState('');

  const { SelfInformation } = useSelector(({ entityApplication }) => entityApplication);

  const { userDetails } = useSelector(({ userHome }) => userHome);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const { dialogMessage } = useSelector(({ common }) => common);

  const dispatch = useDispatch();
  const commentRef = useRef();

  const { actionStatus, requestStatus, EntitiesData } = useSelector(({ entityProfile }) => entityProfile);
  let { entitiesInit, myAccounts } = useSelector(({ entityProfile }) => entityProfile);
  const { myRepresentativeAssignments } = useSelector(({ entityProfile }) => entityProfile);
  const myRepresentativeAssignmentsData = setRepresentativeAssiData(myRepresentativeAssignments.data);
  const { modalTitle, modalBodyMessage, showModal, modalConfirmButton, modalCloseButton, hideComment } = useSelector(
    ({ customModal }) => customModal,
  );

  const { isIndividualUser, isJurisdictionRegistrarUser, isJurisdictionAdminUser, isWCIAdminUser } = useMemo(
    () => ({
      isIndividualUser: isRolesAssigned(userDetails, [USER_ROLE_TYPES.INDIVIDUAL_USER]),

      isJurisdictionRegistrarUser: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.REGISTRAR],
      ),
      isJurisdictionAdminUser: isRolesAssigned(userDetails, [USER_ROLE_TYPES.JURISDICTION_USER], [USER_ROLES.ADMIN]),
      isWCIAdminUser: isRolesAssigned(userDetails, [USER_ROLE_TYPES.SYSTEM_USER], [USER_ROLES.WCI_ADMIN_DEFAULT_ORG]),
    }),
    [userDetails],
  );

  const coveredEntity =
    entityTypeName === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY
      ? t(t1, 'ASSOCIATE_FACILITY_RETIRED')
      : '';
  const commentBoxReq = entityStatusName !== t(t1, ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_IN_PROGRESS);
  const { accountApplications } = useSelector(({ entityProfile }) => entityProfile);
  const FilterForColumnData = getFilterForColumn(accountApplications.data, t, t1, [
    'entityType',
    'jurisdiction',
    'requestStatus',
    'entityStatus',
  ]);
  /**
   * My Representative Assignment Home Page Start */
  let myRepresentativeAssignmentsColumnsData = [
    {
      id: 1,
      field: 'respresentativeType',
      headerName: t(t1, 'REPRESENTATIVE_TYPE'),
      flex: 1,
      align: 'center',
      type: 'singleSelect',
      valueOptions: getRolesForActiveEntity(t, t1),
    },
    {
      id: 2,
      field: 'representativeStatus',
      headerName: t(t1, 'REPRESENTATIVE_STATUS'),
      flex: 1,
      align: 'center',
      type: 'singleSelect',
      valueOptions: getRepresentativeStatusDropdownforFilterDropdown(t, t1),
    },
    {
      id: 3,
      field: 'legalName',
      headerName: t(t1, 'LEGAL_NAME'),
      flex: 1,
      align: 'center',
    },
    {
      id: 4,
      field: 'operatingName',
      headerName: t(t1, 'OPERATING_NAME'),
      flex: 1,
      align: 'center',
    },
    {
      id: 5,
      field: 'entityAccountType',
      headerName: t(t1, 'ACCOUNT_TYPE'),
      flex: 1,
      align: 'center',
      type: 'singleSelect',
      valueOptions: getEntityAcoountTypeNamesForFilterDropdown(t, t1),
    },
    {
      id: 6,
      field: 'entityAccountNumber',
      headerName: t(t1, 'ACCOUNT_NUMBER'),
      flex: 1,
      align: 'center',
    },
    {
      id: 7,
      field: 'jurisdiction',
      headerName: t(t1, 'JURISDICTION'),
      flex: 1,
      align: 'center',
      type: 'singleSelect',
      valueOptions: FilterForColumnData?.jurisdiction,
      renderCell: (params) => <StyledCellContent>{t(t1, params?.row.jurisdiction)}</StyledCellContent>,
    },
    {
      id: 8,
      field: 'updateDate',
      headerName: `${t(t1, 'UPDATE_DATE')}`,
      flex: 1,
      align: 'center',
      valueGetter: ({ value }) => {
        return `${removeTimeZone(formatDateUpdated(value))}`;
      },
    },
    {
      id: 9,
      field: 'updatedBy',
      headerName: t(t1, 'UPDATE_BY'),
      flex: 1,
      align: 'center',
      renderCell: (params) => {
        return <StyledCellContent>{formatDate(params?.row?.updatedBy)}</StyledCellContent>;
      },
    },
  ];

  function setRepresentativeAssiData(entitiesInit) {
    const userRows = [];
    entitiesInit?.forEach((item, i) => {
      userRows.push({
        ...item,
        id: i + 1,
        representativeType: t(t1, item?.respresentativeType),
        representativeStatus: item?.representativeStatus,
        legalName: item?.legalName,
        operatingName: item?.operatingName,
        accountType: item?.entityAccountType,
        accountNumber: item?.entityAccountNumber,
        jurisdiction: item?.jurisdiction,
        updateDate: item?.updateDate,
      });
    });
    return userRows;
  }

  if (isRolesAssigned(userDetails, [USER_ROLE_TYPES.INDIVIDUAL_USER])) {
    myRepresentativeAssignmentsColumnsData = myRepresentativeAssignmentsColumnsData.filter(
      (ele) => ele.field !== 'updatedBy',
    );
  }

  /** My Representative Assignment Home Page Ends */
  const defaultValues = {
    comment: null,
  };
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const handleCancel = () => {
    dispatch(setDialogMessage(true));
    dispatch(
      setShowModal({
        modalTitle: t(t1, 'WARNING'),
        modalBodyMessage: [t(t1, 'CANCEL_WARNING_CONTENT5')],
        modalConfirmButton: t(t1, 'CANCEL_ENTITY_APPLICATION'),
        modalCloseButton: true,
      }),
      methods?.reset(defaultValues),
    );
  };
  useEffectOnce(() => {
    dispatch(getActionStatus());
    dispatch(getRequestStatus());
    dispatch(getSelfInformation());
    dispatch(getMyRepresentativeAssignments({ componentName: 'myRepresentativeAssignments' }));
  });
  const handleQuickAction = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleQuickActionClose = () => {
    setAnchorEl(null);
  };

  const handleCancelModal = () => {
    const value = methods?.getValues('comment')?.trim();
    methods?.reset(defaultValues);
    commentRef.current = value;
    dispatch(setHideModal());
    if (cancelEntityId) {
      const actionData = { comment: '' || value };
      actionData.requestStatusId = requestStatus.find(
        (item) => item.name === ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_CANCELLED_BY_USER,
      ).id;
      actionData.statusId = actionStatus.find((item) => item.Name === 'CANCELLED').ID;
      actionData.entityId = cancelEntityId;
      saveEntityStatus(actionData)
        .then((response) => {
          const data = {
            title: SUCCESS,
            message1: response.messageKey,
            error: false,
            isDefaultDisplay: false,
          };
          dispatch(getMyAccounts({ componentName: 'myAccounts' }));
          dispatch(getAccountApplications({ componentName: 'accountApplications' }));

          dispatch(setLoader(false));
          dispatch(setModelMessage(data));
        })
        .catch((error) => {
          let errorData = error?.message;
          if (error?.response?.data?.messageKey) {
            errorData = error?.response?.data?.messageKey;
          }
          const data = {
            title: t(t1, 'WARNING'),
            message1: t(t1, errorData),
            error: true,
            isDefaultDisplay: true,
          };
          dispatch(setLoader(false));
          dispatch(setModelMessage(data));
        });
    }

    navigate('/home');
  };

  const accountApplicationsColumns = [
    {
      id: 1,
      field: 'entityId',
      headerName: t(t1, 'ENTITY_ID'),
      resizable: false,
      align: 'center',
      width: 100,
      hideable: false,
      groupable: false,
      renderCell: (params) => {
        const EntityId = params?.row?.entityId;
        const entityStatus = t(t1, params?.row?.entityStatus);
        if (isRolesAssigned(userDetails, [USER_ROLE_TYPES.INDIVIDUAL_USER])) {
          if (entityStatus === t(t1, ENTITY_APPLICTION_STATUS_NAMES.REVISIONS_REQUESTED)) {
            return (
              <Link
                component={RouterLink}
                to={`/entity-revision-requested/${params?.row?.entityObjectId}`}
                className="linkHoverDesign"
                sx={{ color: '#4d8da4', cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/entity-revision-requested/${params?.row?.entityObjectId}`);
                }}
              >
                {EntityId}
              </Link>
            );
          }
          if (entityStatus === t(t1, ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_IN_PROGRESS)) {
            return (
              <Link
                component={RouterLink}
                to={`/entity-registration/${params?.row?.entityObjectId}`}
                className="linkHoverDesign"
                sx={{ color: '#4d8da4', cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/entity-registration/${params?.row?.entityObjectId}`);
                }}
              >
                {EntityId}
              </Link>
            );
          }
          if (
            entityStatus === t(t1, ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_CANCELLED_BY_USER) ||
            entityStatus === t(t1, ENTITY_APPLICTION_STATUS_NAMES.CANCELLED) ||
            entityStatus === t(t1, ENTITY_APPLICTION_STATUS_NAMES.PENDING) ||
            entityStatus === t(t1, ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_PENDING) ||
            entityStatus === t(t1, ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_APPROVED) ||
            entityStatus === t(t1, ENTITY_APPLICTION_STATUS_NAMES.ACTIVE)
          ) {
            return (
              <Link
                component={RouterLink}
                to={`/entity-profile/${params?.row?.entityObjectId}`}
                className="linkHoverDesign"
                sx={{ color: '#4d8da4', cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/entity-profile/${params?.row?.entityObjectId}`);
                  window.history.pushState({}, '', `/entity-profile/${params?.row?.entityObjectId}`);
                }}
              >
                {EntityId}
              </Link>
            );
          }
        } else {
          return (
            <Link
              component={RouterLink}
              to={`/entity-profile/${params?.row?.entityObjectId}`}
              className="linkHoverDesign"
              sx={{ color: '#4d8da4', cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/entity-profile/${params?.row?.entityObjectId}`);
              }}
            >
              {EntityId}
            </Link>
          );
        }
      },
    },
    {
      id: 2,
      field: 'entityReferenceCode',
      headerName: t(t1, 'ENTITY_REFERENCE_CODE'),
      width: 150,
      align: 'center',
      valueFormatter: ({ value }) => (value ? `\t${value}` : ''),
    },
    {
      id: 2,
      field: 'legalName',
      headerName: 'Entity Legal Name',
      width: 150,
      align: 'center',
    },
    {
      id: 3,
      field: 'entityType',
      headerName: 'Entity Type',
      width: 200,
      align: 'center',
      type: 'singleSelect',
      valueOptions: FilterForColumnData?.entityType,
      renderCell: (params) => <StyledCellContent>{t(t1, params?.row.entityType)}</StyledCellContent>,
    },
    {
      id: 4,
      field: 'operatingName',
      headerName: 'Operating Name',
      width: 150,
      align: 'center',
    },

    {
      id: 2,
      field: 'jurisdiction',
      headerName: 'Jurisdiction',
      width: 100,
      align: 'center',
      type: 'singleSelect',
      valueOptions: FilterForColumnData?.jurisdiction,
      renderCell: (params) => <StyledCellContent>{t(t1, params?.row.jurisdiction)}</StyledCellContent>,
    },
    {
      id: 3,
      field: 'requestStatus',
      headerName: t(t1, 'REQUEST_STATUS'),
      width: 200,
      align: 'center',
      valueOptions: FilterForColumnData?.requestStatus,
      renderCell: (params) => {
        const status = params?.row?.requestStatus;
        const statusToRender = typeof status === 'string' ? t(t1, status) : status;
        return <StyledCellContent>{statusToRender}</StyledCellContent>;
      },
    },
    {
      id: 4,
      field: 'requestStatusUpdateDate',
      headerName: `${t(t1, 'REQUEST_STATUS_UPDATE_DATE')}`,
      width: 250,
      align: 'center',
      valueGetter: ({ value }) => {
        return `${removeTimeZone(formatDateUpdated(value))}`;
      },
    },
    {
      id: 5,
      field: 'entityStatus',
      headerName: t(t1, 'ENTITY_STATUS'),
      width: 150,
      align: 'center',
      type: 'singleSelect',
      valueOptions: FilterForColumnData?.entityStatus,
      renderCell: (params) => (
        <StyledCellContent>{params?.row?.entityStatus ? t(t1, params?.row?.entityStatus) : params?.row?.entityStatus}</StyledCellContent>
      ),
    },
    {
      id: 6,
      field: 'updateDate',
      width: 100,
      headerName: `${t(t1, 'UPDATE_DATE')}`,
      align: 'center',
      valueGetter: ({ value }) => {
        return `${removeTimeZone(formatDateUpdated(value))}`;
      },
    },
    {
      id: 8,
      field: 'comment',
      headerName: t(t1, 'COMMENT'),
      width: 150,
      align: 'center',
      renderCell: (params) => (
        <Tooltip
          title={params?.row?.comment}
          placement="bottom"
          PopperProps={{
            sx: {
              maxHeight: '220px',
              overflow: 'auto',
            },
          }}
        >
          <StyledCellContent>
            {params?.row?.entityStatus === t(t1, ENTITY_APPLICTION_STATUS_NAMES.PENDING) ? '' : params?.row?.comment}
          </StyledCellContent>
        </Tooltip>
      ),
    },
    {
      id: 9,
      field: 'QuickActions',
      headerName: t(t1, 'QUICK_ACTIONS'),
      resizable: false,
      align: 'center',
      hideable: false,
      sortable: false,
      groupable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params) => {
        if (isEmpty(params.row.is_allowed_access) || params.row.is_allowed_access.find(rep => rep.reference_code === SelfInformation?.[0]?.ReferenceCode)?.is_allowed_access) {
          return (
            <div>
              <Button
                sx={{ cursor: 'pointer' }}
                onClick={(e) => {
                  handleQuickAction(e);
                  setCancelEntityId(params?.row.entityObjectId);
                  setEntityStatusName(params?.row.entityStatus);
                  setEntityTypeName(params?.row.EntityType);
                }}
              >
                <MoreHorizIcon sx={{ color: '#4D8DA4', fontSize: '30px' }} />
              </Button>
              <Menu
                id="basic-menu"
                open={open}
                anchorEl={anchorEl}
                onClose={handleQuickActionClose}
                sx={Style.QuickActionBox}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem
                  className="cancelBtn"
                  onClick={async () => {
                    handleCancel();

                    handleQuickActionClose();
                  }}
                >
                  {t(t1, 'CANCEL_ENTITY_APPLICATION_CAPTITAL')}
                </MenuItem>
              </Menu>
            </div>
          );
        }
        return null;
      },
    },
  ];

  const myAccountValuesForColumn = getFilterForColumn(myAccounts?.data, t, t1, [
    'EntityAccountType',
    'accountStatus',
    'Jurisdiction',
    'entityStatus',
  ]);

  const myAccountColumns = [
    {
      id: 1,
      field: 'entityId',
      headerName: t(t1, 'ENTITY_ID'),
      align: 'center',
      hideable: false,
      groupable: false,
      flex: 1,
      renderCell: (params) => {
        const EntityId = params?.row?.EntityID;
        if (params?.row?.Status === t(t1, ENTITY_APPLICTION_STATUS_NAMES.REVISIONS_REQUESTED)) {
          return (
            <Link
              component={RouterLink}
              to={`/entity-revision-requested/${params?.row?.entityObjectId}`}
              className="linkHoverDesign"
              sx={{ color: '#4d8da4', cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/entity-revision-requested/${params?.row?.entityObjectId}`);
              }}
            >
              {EntityId}
            </Link>
          );
        }
        if (params?.row?.Status === t(t1, ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_IN_PROGRESS)) {
          return (
            <Link
              component={RouterLink}
              to={`/entity-registration/${params?.row?.entityObjectId}`}
              className="linkHoverDesign"
              sx={{ color: '#4d8da4', cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/entity-registration/${params?.row?.entityObjectId}`);
              }}
            >
              {EntityId}
            </Link>
          );
        }
        if (
          params?.row?.Status === t(t1, ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_CANCELLED_BY_USER) ||
          params?.row?.Status === t(t1, ENTITY_APPLICTION_STATUS_NAMES.CANCELLED) ||
          params?.row?.Status === t(t1, ENTITY_APPLICTION_STATUS_NAMES.PENDING) ||
          params?.row?.Status === t(t1, ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_PENDING) ||
          params?.row?.Status === t(t1, ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_APPROVED) ||
          params?.row?.Status === t(t1, ENTITY_APPLICTION_STATUS_NAMES.ACTIVE) ||
          params?.row?.Status === t(t1, ENTITY_APPLICTION_STATUS_NAMES.CLOSED)
        ) {
          return (
            <Link
              component={RouterLink}
              to={`/entity-profile/${params?.row?.entityObjectId}`}
              className="linkHoverDesign"
              sx={{ color: '#4d8da4', cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/entity-profile/${params?.row?.entityObjectId}`);
              }}
            >
              {EntityId}
            </Link>
          );
        }
      },
    },
    {
      id: 7,
      field: 'entityAccountNumber',
      headerName: t(t1, 'ACCOUNT_NUMBER'),
      align: 'center',
      flex: 2,
      renderCell: (params) => {
        const entityId = params?.row?.entityObjectId;
        const accountId = params?.row?.accountId;
        return (
          <Link
            component={RouterLink}
            to={`/entity-profile/${entityId}/account/${accountId}`}
            className="linkHoverDesign"
            sx={{ color: 'primary.light', cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/entity-profile/${entityId}/account/${accountId}`);
            }}
          >
            {params?.row?.EntityAccountNumber}
          </Link>
        );
      },
    },
    {
      id: 3,
      field: 'legalName',
      headerName: t(t1, 'LEGAL_NAME'),
      flex: 2,
      align: 'center',
    },
    {
      id: 4,
      field: 'operatingName',
      headerName: t(t1, 'OPERATING_NAME'),
      flex: 2,
      align: 'center',
    },
    {
      id: 9,
      field: 'entityReferenceCode',
      headerName: t(t1, 'ENTITY_REFERENCE_CODE'),
      flex: 2,
      align: 'center',
    },
    {
      id: 8,
      field: 'entityType',
      headerName: 'Entity Type',
      flex: 1,
      align: 'center',
      type: 'singleSelect',
      valueOptions: getEntityTypeNamesForFilterDropdown(t, t1),
      renderCell: (params) => <StyledCellContent>{params?.row.entityType}</StyledCellContent>,
    },
    {
      id: 5,
      field: 'entityAccountType',
      headerName: t(t1, 'ENTITY_ACCOUNT_TYPE'),
      flex: 2,
      align: 'center',
      type: 'singleSelect',
      valueOptions: myAccountValuesForColumn?.EntityAccountType,
      renderCell: (params) => <StyledCellContent>{params?.row.EntityAccountType}</StyledCellContent>,
    },
    {
      id: 6,
      field: 'accountStatus',
      headerName: t(t1, 'ACCOUNT_STATUS'),
      flex: 1,
      align: 'center',
      type: 'singleSelect',
      valueOptions: myAccountValuesForColumn?.accountStatus,
      renderCell: (params) => (
        <StyledCellContent>{params?.row?.accountStatus ? t(t1, params?.row?.accountStatus) : params?.row?.accountStatus}</StyledCellContent>
      ),
    },
    {
      id: 2,
      field: 'jurisdiction',
      headerName: t(t1, 'JURISDICTION'),
      flex: 1,
      align: 'center',
      type: 'singleSelect',
      valueOptions: myAccountValuesForColumn?.Jurisdiction,
      renderCell: (params) => <StyledCellContent>{t(t1, params?.row.Jurisdiction)}</StyledCellContent>,
    },
    {
      id: 10,
      field: 'entityStatus',
      headerName: t(t1, 'ENTITY_STATUS'),
      flex: 1,
      align: 'center',
      type: 'singleSelect',
      valueOptions: myAccountValuesForColumn?.entityStatus,
      renderCell: (params) => (
        <StyledCellContent>{params?.row?.entityStatus ? t(t1, params?.row?.entityStatus) : params?.row?.entityStatus}</StyledCellContent>
      ),
    },
  ];

  useEffectOnce(() => {
    dispatch(getMyAccounts({ componentName: 'myAccounts' }));
    dispatch(getAccountApplications({ componentName: 'accountApplications' }));
  }, []);

  const { metaData, fieldAccess } = myAccounts;
  entitiesInit = setExcelData(accountApplications?.data);
  myAccounts = setExcelData(myAccounts?.data);
  let recordsOfmyAccounts = myAccounts.filter(
    (item) =>
      item.entityStatus === ENTITY_APPLICTION_STATUS_NAMES.ACTIVE ||
      item.entityStatus === ENTITY_APPLICTION_STATUS_NAMES.CLOSED,
  );
  recordsOfmyAccounts = recordsOfmyAccounts.map((record) => {
    return {
      ...record,
      accountStatus: t(t1, record?.accountStatus),
      EntityAccountType: t(t1, record.EntityAccountType),
      entityType: t(t1, record.entityType),
    };
  });

  function setExcelData(entitiesInit) {
    const userRows = [];
    entitiesInit?.forEach((item) => {
      userRows.push({
        ...item,
        EntityID: item?.EntityID,
        EntityType: item?.EntityType,
        Jurisdiction: item?.Jurisdiction,
        RequestStatus: t(t1, item?.RequestStatus),
        requestStatusUpdateDate: item?.requestStatusUpdateDate,
        Status: t(t1, item?.Status),
        updatedAt: item?.updatedAt ? formatDate(item?.updatedAt) : '',
        comment: item?.comment ? [item?.comment].filter((value) => value).join(', ') : '',
      });
    });
    return userRows;
  }

  const handleFinalCancel = () => {
    dispatch(setHideModal());
  };
  const finalColumns = showOnlyPermittedFields(
    accountApplicationsColumns,
    accountApplications.metaData,
    accountApplications.fieldAccess,
  );
  const translatedRows = translateRows(
    [...entitiesInit],
    ['entityAccountType', 'entityStatus', 'requestStatus', 'jurisdiction', 'entityType'],
    t,
    t1,
  );
  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      navigate('/cron-jobs');
    }
  };
  return (
    <>
      {isWCIAdminUser && <ManageJurisdictionSection />}
      {isIndividualUser && (
        <>
          <Typography
            sx={{
              fontWeight: 600,
              background: '#4d8da4',
              color: '#fff',
              textAlign: 'left',
              paddingLeft: '1.5rem',
              marginBottom: '0px',
              fontSize: '1.25rem',
              paddingTop: '0.75rem',
              paddingBottom: '0.75rem',
            }}
            variant="h4"
            gutterBottom
          >
            {t(t1, 'ENTITIES')}
          </Typography>
          <List className="commonlistul">
            <ListItem className="pending-accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{t(t1, 'MY_ACCOUNTS')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MUITableGrid
                    rows={translateRows(
                      [...recordsOfmyAccounts],
                      ['jurisdiction', 'entityAccountType', 'entityType', 'entityStatus'],
                      t,
                      t1,
                    )}
                    columns={showOnlyPermittedFields(myAccountColumns, metaData, fieldAccess)}
                    count={3}
                    isExport
                    isSearch
                    isSettings
                    fileName={t(t1, 'MY_ACCOUNTS')}
                  />
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <ListItem className="pending-accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{t(t1, 'ACCOUNT_APPLICATIONS')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {translatedRows && finalColumns && (
                    <MUITableGrid
                      isExport
                      isSearch
                      isSettings
                      rows={translatedRows}
                      columns={finalColumns}
                      count={3}
                      fileName={t(t1, 'ACCOUNT_APPLICATIONS', 'requestStatusUpdateDate', 'updateDate')}
                      customPinnedColumns={{
                        left: ['entityId'],
                        right: ['QuickActions'],
                      }}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
              {dialogMessage && showModal ? (
                <FormProvider {...methods}>
                  <CustomeDialogMessage
                    title={modalTitle}
                    handleButtonClick={handleCancelModal}
                    handleModalCancel={(_, reason) => {
                      if (reason !== 'backdropClick') {
                        dispatch(setHideModal());
                      }
                    }}
                    handleClose={handleFinalCancel}
                    open={showModal}
                    message1={modalBodyMessage}
                    message3={t(t1, 'CANCEL_WARNING_CONTENT2')}
                    message2={coveredEntity}
                    message4={commentBoxReq ? t(t1, 'PLEASE_ADD_COMMENT') : ''}
                    buttonDisplay={modalConfirmButton}
                    closeBtnDisplay={modalCloseButton}
                    borderColor={'#ca2c1c'}
                    disabled={commentBoxReq ? methods.formState?.isValid : true}
                  >
                    {commentBoxReq && (
                      <Grid
                        item
                        md={12}
                        sx={{ mt: 1 }}
                        className="no-lable-field"
                      >
                        <GridTextfield
                          gridMd={12}
                          name="comment"
                          multiline
                          className="input-group"
                          rows={2}
                          rules={{
                            required: t(t1, 'COMMENTS_ISREQUIRED'),
                            maxLength: {
                              value: COMMENT_MAX_CHARACTER_MODULE,
                              message: `${t(t1, 'ERR_COMMENT_MAX_LENGTH_CHAR',
                              )}`,
                            },
                            validate: (v) => validateCommentModule(v, `${t(t1, 'COMMENTS')}`, t1),
                          }}
                          isRequiredMsg={'COMMENTS_ISREQUIRED'}
                        />
                      </Grid>
                    )}
                  </CustomeDialogMessage>
                </FormProvider>
              ) : null}
            </ListItem>
            <ListItem className="pending-accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{t(t1, 'MY_REPRESENTATIVE_ASSIGNMENTS')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MUITableGrid
                    isExport
                    isSearch
                    isSettings
                    rows={translateRows(
                      [...myRepresentativeAssignmentsData],
                      ['respresentativeType', 'representativeStatus', 'entityAccountType', 'jurisdiction'],
                      t,
                      t1,
                    )}
                    columns={showOnlyPermittedFields(
                      myRepresentativeAssignmentsColumnsData,
                      myRepresentativeAssignments.metaData,
                      myRepresentativeAssignments.fieldAccess,
                    )}
                    count={3}
                    fileName={t(t1, 'MY_REPRESENTATIVE_ASSIGNMENTS')}
                  />
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <PendingTransfers />
          </List>
        </>
      )}
      {isWCIAdminUser && (
        <Typography
          sx={{
            fontWeight: 600,
            background: '#4d8da4',
            color: '#fff',
            textAlign: 'left',
            paddingLeft: '1.5rem',
            marginBottom: '0px',
            fontSize: '16px',
            paddingTop: '10px',
            paddingBottom: '10px',
            height: '44px',
            textTransform: 'uppercase',
            cursor: 'pointer',
            '&:focus': {
              fontWeight: 700,
              outline: 'none',
            },
          }}
          variant="h4"
          gutterBottom
          onClick={() => {
            navigate('/cron-jobs');
          }}
          tabIndex={0}
          onKeyDown={(e) => handleEnterKey(e)}
        >
          {t(t1, 'MANAGE_CRON_JOBS')}
        </Typography>
      )}
    </>
  );
}
