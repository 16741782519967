import { MUITableGrid } from 'components/MUITableGrid';
import { StyledCardTitle, StyledForm } from 'pages/ManageBatchTransfer/ManageBatchTransfer.style';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentPageName, t, getFilterForColumn } from 'utils/constants';
import { TransferHistorySchema } from './columnSchema';

function HistoryTable({ batchTransferHistory, flp }) {
  const { t: t1 } = useTranslation(getCurrentPageName());

  const valueOptions = useMemo(
    () => getFilterForColumn(batchTransferHistory, t, t1, ['Status.name']),
    [batchTransferHistory, t1],
  );
  const columns = useMemo(() => [...TransferHistorySchema(t1, valueOptions)], [t1, valueOptions]);
  return (
    <StyledForm>
      <StyledCardTitle variant="h2">{`${t(t1, 'BATCH_TRANSFER_HISTORY')}`}</StyledCardTitle>
      <MUITableGrid
        isExport
        isSearch
        isSettings
        rows={batchTransferHistory}
        fieldsMetaData={flp?.metaData}
        customTableStyle={{ marginTop: '1rem' }}
        columns={columns}
        page={1}
        initialSortedFields={[{ field: 'vintage', sort: 'asc' }]}
        fileName={t(t1, 'BATCH_TRANSFER_HISTORY')}
        enablePrintView
      />
    </StyledForm>
  );
}

export default HistoryTable;
