const Style = {
  formCardPadding: { paddingBottom: '40px', minHeight: '181px' },
  formCardPaddingOnAdd: { paddingBottom: '40px', minHeight: '181px' },
  otherCard: { paddingBottom: '20px' },
  FieldLabel: {
    fontSize: '0.875rem',
    color: 'common.textColor',
    fontWeight: 700,
    mb: 0.5,
  },
  formField: {
    width: 1,
    color: 'common.textColor',
    '.MuiOutlinedInput-input': {
      py: 0.6,
      fontSize: '14px',
      '& .MuiTypography-root': {
        fontSize: '14px',
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: '0',
      borderColor: 'common.GrayColor',
    },
    '&.MuiOutlinedInput-root': {
      '&.MuiInputBase-root.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: '#333',
          borderWidth: 1,
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '4px',
    },
    '& .Mui-error': {
      marginLeft: 0,
    },
  },
  inputStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  onEditPage: {
    paddingBottom: '40px !important',
  },
  abc: {
    paddingBottom: '40px !important',
  },
  gridLayoutColumnStyling: {
    mt: 1.5,
    alignItems: 'flex-end',
    display: 'inline-flex',
    rowGap: '1rem',
    '.label-wrapper': {
      display: 'contents',
    },
  },
};

export default Style;
  