const Style = {
  FieldLabel: {
    fontSize: '0.875rem',
    color: 'common.textColor',
    fontWeight: 700,
    mb: 0.5,
  },
  formField: {
    width: 1,
    color: 'common.textColor',
    '.MuiOutlinedInput-input': {
      p: 0.4,
      fontSize: '0.875rem',
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: '4px',
      borderColor: 'common.GrayColor',
    },
    '&.MuiOutlinedInput-root': {
      '&.MuiInputBase-root.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: '#333333',
          borderWidth: 1,
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '4px',
    },
  },
  valuetext: {
    maxWidth: '100px',
  },
};

export default Style;
