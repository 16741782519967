import LoginConfig from 'pages/auth/Login.js/LoginConfig';
import CreateUserProfileConfig from 'pages/CreateUserProfile/CreateUserProfileConfig';
import UserHomeConfig from 'pages/UserHome/UserHomeConfig';
import PageNotFoundConfig from 'pages/PageNotFound/PageNotFoundConfig';
import LandingPageKnownConfig from 'pages/LandingPageKnown/LandingPageKnownConfig';
import LandingPageUnknownConfig from 'pages/LandingPageUnknown/LandingPageUnknownConfig';
import ConfigurationSettingsConfig from 'pages/ConfigurationSettings/ConfigurationSettingsConfig';
import PrivilegeManagementConfig from 'pages/PrivilegeManagement/PrivilegeManagementConfig';
import SystemRolesConfig from 'pages/PrivilegeManagement/components/SystemRoles/SystemRolesConfig';
import RoleDetailsConfig from 'pages/PrivilegeManagement/components/RoleDetails/RoleDetailsConfig';
import UserWithinRoleConfig from 'pages/PrivilegeManagement/components/UserWithinRole/UserWithinRoleConfig';
import JurisdictionUserHome from 'pages/JurisdictionUserHome/JurisdictionUserHomeConfig';
import EntityRegistrationConfig from 'pages/EntityRegistration/EntityRegistrationConfig';
import ManageJurisdictionBudgetConfig from 'pages/ManageJurisdictionBudget/ManageJurisdictionBudgetConfig';
import AddJurisdictionBudgetConfig from 'pages/AddBudgetRecord/AddBudgetRecordConfig';
import ManageBatchTransferConfig from 'pages/ManageBatchTransfer/ManageBatchTransferConfig';

const layouts = [
  ManageJurisdictionBudgetConfig,
  LoginConfig,
  CreateUserProfileConfig,
  UserHomeConfig,
  PageNotFoundConfig,
  LandingPageKnownConfig,
  LandingPageKnownConfig,
  LandingPageUnknownConfig,
  ConfigurationSettingsConfig,
  PrivilegeManagementConfig,
  SystemRolesConfig,
  SystemRolesConfig,
  RoleDetailsConfig,
  UserWithinRoleConfig,
  JurisdictionUserHome,
  EntityRegistrationConfig,
  AddJurisdictionBudgetConfig,
  ManageBatchTransferConfig,
];

export default layouts;
