import { Box, Container, Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomeDialogMessage } from 'components/CustomeDialogMessage';
import { CustomizeButton } from 'components/CustomizeButton';
import { Loader } from 'components/Loader';
import { MUITableGrid } from 'components/MUITableGrid';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setDialogMessage, setLoader, setModelMessage } from 'store/commonSlice';
import {
  APIS,
  ERROR,
  USER_ROLES,
  USER_ROLE_TYPES,
  getCurrentPageName,
  isEmpty,
  isRolesAssigned,
  scrollToTop,
  t,
} from 'utils/constants';
import { fetcherGet, fetcherPost } from 'utils/fetcher';
import logger from 'utils/logger';
import { ErrorCardWithScroll } from 'components/ErrorCardWithScroll';
import BudgetDetailsCard from 'pages/ManageJurisdictionBudget/components/BudgetDetailsCard';
import { setFLP } from 'store/manageJurisdictionBudgetSlice';
import { JURISDICTION_BUDGET_COMPONENTS_FLP, getFLPHeaders } from 'pages/ManageJurisdictionBudget/constant';
import { DialogMessage, PageTitle } from '../../components';
import { StyledCard, StyledCardTitle, StyledTitleContainer } from '../AccountDetails/AccountDetails.style';
import { StyledRequiredAstrik } from '../NAICSCodeManagement/NAICSCodeManagement.style';
import { StyledErrorCard, StyledErrorCardTitle, StyledErrorListItems } from './AddBudgetRecord.style';
import { AddBudgetSchema } from './columnSchema';
import { get15UnProposedBudgetYears } from './utils';


function AddJurisdictionBudget() {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { dialogMessage, loader } = useSelector(({ common }) => common);
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const {
    flp,
  } = useSelector(({ ManageJurisdictionBudget }) => ManageJurisdictionBudget);

  const { isWCIAdminUser } = useMemo(
    () => ({
      isWCIAdminUser: isRolesAssigned(userDetails, [USER_ROLE_TYPES.SYSTEM_USER], [USER_ROLES.WCI_ADMIN_DEFAULT_ORG]),
    }),
    [userDetails],
  );

  const [rows, setRows] = React.useState([]);

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const {
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const onSubmit = () => {
    if (isValid) {
      setOpenConfirmDialog(true);
    }
  };

  const onChange = React.useCallback(
    (budgetYear, field, value) => {
      const updatedRows = rows.map((data) => (data.budgetYear === budgetYear ? { ...data, [field]: value } : data));
      setRows(updatedRows);
    },
    [rows],
  );

  const setWarningModal = () => {
    dispatch(setDialogMessage(true));
  };

  const handleCancelModalSubmit = () => {
    reset();
    const resetRows = rows.map((row) => ({
      ...row,
      annualAllowanceBudget: 0,
      reserveAdjustedAllowanceBudget: 0,
    }));
    setRows(resetRows);
    navigate(`/budgets/${id}`);
  };

  const filledRows = useMemo(() => rows.filter(
    (item) =>
      (item.annualAllowanceBudget !== null) &&
      (item.reserveAdjustedAllowanceBudget !== null),
  ), [rows]);

  const onPropose = () => {
    const body = filledRows
      .map(({ id, ...rest }) => ({ ...rest }));
    dispatch(setLoader(true));
    fetcherPost(`${APIS.BUDGETS}/jurisdictions/${id}/budget-record`, { jurisdictionBudget: body }, getFLPHeaders(JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET)).then(() => {
      dispatch(setLoader(false));
      setOpenConfirmDialog(false);
      const data = {
        title: 'PROPOSE_SUCCESS',
        message1: t(t1, 'PROPOSE_SUCCESS_MESSAGE'),
        error: false,
      };
      dispatch(setModelMessage(data));
      setTimeout(() => {
        navigate(`/budgets/${id}`);
      }, 3000);
    }).catch((error) => {
      dispatch(setLoader(false));
      const data = {
        title: ERROR,
        message1: error?.response?.data?.data,
        error: true,
        isDefaultDisplay: false,
      };
      dispatch(setModelMessage(data));
    });
  };

  useEffect(() => {
    if (!userDetails?.loading && !isWCIAdminUser) {
      navigate('/home');
    }
    const setGridData = async () => {
      dispatch(setLoader(true));
      fetcherGet(`${APIS.BUDGETS}/jurisdictions/${id}/budget-year`, {
          budgetStatus: 'PROPOSED,PROPOSED_CHANGES,PROPOSED_TO_AUTHORITY,REVISIONS_REQUESTED,APPROVED',
        },
        {
          pageName: 'Page_ManageJurisdictionBudget',
          componentName: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_YEARS,
        })
        .then((response) => {
          const budgetYears = response.data.map((budget) => budget.budgetYear);
          const { metaData, fieldAccess } = response;
          dispatch(
            setFLP({
              key: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_YEARS,
              value: { metaData, fieldAccess },
            }),
          );
          const rows = get15UnProposedBudgetYears(budgetYears);
          if (rows) setRows(rows);
          dispatch(setLoader(false));
        })
        .catch((error) => {
          dispatch(setLoader(false));
          logger({ error, name: 'getBudgetYear' });
        });
    };
    dispatch(setDialogMessage(false));
    setGridData();
  }, [dispatch, id, isWCIAdminUser, navigate, userDetails]);

  const columns = React.useMemo(() => [...AddBudgetSchema(t1, onChange)], [onChange, t1]);
  return (
    <Container maxWidth="xl">
      <FormProvider {...methods}>
        <StyledTitleContainer>
          <PageTitle
            customeStyle={{ my: 0, marginBottom: 2.5 }}
            title={t(t1, 'ADD_BUDGET_RECORDS')}
          />
        </StyledTitleContainer>
        <BudgetDetailsCard flp={flp[JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_DETAILS]} />
        {!isEmpty(errors) && (
          <StyledErrorCard>
            <StyledErrorCardTitle variant="h2">{t(t1, 'ERRORS')}</StyledErrorCardTitle>
            <Grid
              container
              spacing={2}
              sx={{ mt: 0, mb: 0 }}
            >
              <Grid item xs={12}>
                <ErrorCardWithScroll fileUploadErrors={Object.entries(errors).map(([key, { message }]) => ({
                  messageKey: message,
                }))}
                                     truncateLength={50}
                                     customeStyle={{
                                       paddingInlineStart: 0,
                                       paddingInlineEnd: 0,
                                       margin: 0,
                                       paddingLeft: '20px',
                                     }}
                />
              </Grid>
            </Grid>
          </StyledErrorCard>
        )}
        <form onSubmit={handleSubmit(onSubmit, scrollToTop)} noValidate>
          <StyledCard>
            <StyledCardTitle variant="h2">{t(t1, 'ADD_RECORDS')}</StyledCardTitle>
            <StyledRequiredAstrik variant="body2">*</StyledRequiredAstrik>
            {t(t1, 'INDICATE_REQUIRED_FIELDS')}
            <Grid
              container
              spacing={2}
              sx={{ mt: 0, mb: 2.5 }}
            >
              <Grid
                item
                sm={12}
                sx={{
                  '& .MuiButtonBase-root': {
                    textTransform: 'capitalize',
                  },
                  '& .MuiDataGrid-columnHeader': {
                    textTransform: 'none',
                  },
                }}
              >
                <MUITableGrid
                  rows={rows}
                  columns={columns}
                  showFooter={false}
                  pagination={false}
                  count={4}
                  initialSortedFields={[{ field: 'budgetYear', sort: 'asc' }]}
                  enablePrintView
                  disableColumnMenu
                  hideFooter
                />
              </Grid>
            </Grid>
          </StyledCard>
          {/* {isLoading && <Loader loader={isLoading} />} */}
          <Box sx={{ ml: 'auto' }}>
            <Grid
              item
              sm={12}
            >
              <div
                className="bottom-btn"
                style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}
              >
                <CustomizeButton
                  disableElevation
                  variant="outlined"
                  csName="rc"
                  name="add_budget_cancel"
                  privId="priv_btn_cancel"
                  label={t(t1, 'BTN_CANCEL')}
                  handleClick={() => setWarningModal()}
                />
                <CustomizeButton
                  disabled={!filledRows.length}
                  disableElevation
                  variant="contained"
                  customStyle={{ marginLeft: 1 }}
                  type="submit"
                  label={t(t1, 'BTN_PROPOSE')}
                  name="add_budget_save"
                  privId="priv_btn_Send"
                />
              </div>
            </Grid>
          </Box>
        </form>
      </FormProvider>
      {dialogMessage ? (
        <DialogMessage
          warning
          handleModalClick={handleCancelModalSubmit}
          buttonMessage={false}
          title={t(t1, 'WARNING')}
          message1={t(t1, 'DIALOG_CANCEL_BUDGET_MESSAGE')}
          message2={t(t1, 'DIALOG_RESET_BUDGET_MESSAGE')}
        />
      ) : null}
      <CustomeDialogMessage
        title={t(t1, 'CONFIRM_PROPOSAL')}
        continueBtn={t(t1, 'CONTINUE')}
        open={openConfirmDialog}
        disableContinue={loader}
        handleModalCancel={() => {
          setOpenConfirmDialog(false);
        }}
        handleClose={() => {
          setOpenConfirmDialog(false);
        }}
        message1={t(t1, 'CONFIRM_PROPOSAL_MESSAGE')}
        handleContinue={onPropose}
        borderColor="#283459"
      />
      {loader && <Loader loader={loader} />}
    </Container>
  );
}

export default AddJurisdictionBudget;
