import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { CustomAutocomplete, GridTextfield } from 'components';
import { FormActions } from 'components/EntityFormActions';
import { FormCard } from 'components/FormCard';
import { FormCardAddFacility } from 'components/FormCardAddFacility';
import { TextTruncate } from 'components/TextTruncate';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setIsFormEditable, setLoader, setModelMessage } from 'store/commonSlice';
import {
  getCountry,
  getEntityContactById,
  getNAICS,
  resetSteps,
  saveContactFormData,
  setEntityID,
  setEntityType,
  setMaillingBtnDisable,
  setNextStepSelect,
  setStateProvince,
  setStateProvinceforMailing,
  setSubmittedOfSelectedStep,
  updateEntity,
} from 'store/entityApplicationSlice';
import { isEqual, omit } from 'underscore';
import {
  COMPONENT_MODES,
  COMPONENT_NAMES,
  COUNTRIES_ID,
  COUNTRY_KEY_NAMES,
  ERROR,
  JURISDICTIONS_NAMES,
  SopfDetailView,
  emailPatternValidation,
  getCurrentPageName,
  handleEntityStatusError,
  isEmpty,
  scrollToTop,
  t,
  validatePhoneNumber,
  validatingPostalCode,
  validatePattern,
  extPattern,
  verifyIdentificationNumberWithoutSpecialCharacters,
  website,
  verifyCityOrRegion,
  verifyStreet1orStreet2,
} from 'utils/constants';
import logger from 'utils/logger';
import UnsavedPrompt from '../../../../../components/UnsavedDialog';
import '../../ComponentTextTruncate.css';
import Style from './ContactInfoStyle';


export default function ContactInformation({
                                             displayHead = true,
                                             mode = COMPONENT_MODES.ADD,
                                             isRevisionRequested = false,
                                             combineAddressUpdate = true,
                                             isReviewAndSubmit = false,
                                             updateCardEdit = () => {
                                             },
                                           }) {
  const { t: t1, i18n } = useTranslation(getCurrentPageName());
  const legalNameRef = useRef('');
  const operatingNameRef = useRef('');
  const [isReadMore, setIsReadMore] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [isExitInProgress, setIsExitInProgress] = useState(false);
  const [stepData, setStepData] = useState({ editMode: false });
  const [stepDataMailing, setStepDataMailing] = useState({ editMode: false });
  const [stepDataAdditional, setStepDataAdditional] = useState({ editMode: false });
  const { QUEBEC, NOVA_SCOTIA } = JURISDICTIONS_NAMES;
  const [physicalCountry, setPhysicalCountry] = useState(false);
  const [mailingCountry, setMailingCountry] = useState(false);

  const [hidePhysicalRegion, setPhysicalHideRegion] = useState(true);
  const [hideMailingRegion, setHideMailingRegion] = useState(true);

  const {
    steps,
    selectedJurisdiction,
    jurisdictions,
    Entities,
    Purpose,
    UserClassification,
    Country,
    StateProvince,
    StateProvinceforMailing,
    selectedEntity,
    Organization,
    EditAccess,
    EntitiesData,
    fieldPermissions,
  } = useSelector(({ entityApplication }) => entityApplication);
  const dispatch = useDispatch();


  useEffectOnce(() => {

    scrollToTop();
    dispatch(getCountry());
    if (t(t1, 'FOOTER_DISCLAIMER_CONTENT').length > 250) {
      setIsReadMore(true);
    } else {
      setIsReadMore(false);
    }
    setDefaultValues();

  }, []);

  const showContactSaveError = (error) => {
    const data = {
      title: ERROR,
      message1: error?.response.data?.messageKey || error?.response?.data?.data,
      error: true,
      isDefaultDisplay: false,
    };
    dispatch(setModelMessage(data));
  };

  useEffect(() => {
    if (Country && Country.length) {
      const defaultValues = steps.find((item) => item.key === COMPONENT_NAMES.CONTACT_INFORMATION).filledData;
      if (defaultValues?.mailingSameAsPhysical) {
        handleSameAddressCheck(defaultValues?.mailingSameAsPhysical, false);
      }
      if (defaultValues?.mailingAddress?.country?.id) {
        updateStateByCountryforMailing(defaultValues?.mailingAddress?.country?.id);
      }
      if (defaultValues?.physicalAddress?.country?.id) {
        updateStateByCountry(defaultValues?.physicalAddress?.country?.id);
      }
    }
    const countryChanged = methods?.getValues('physicalAddress.country')?.id || methods?.getValues('physicalAddress.country');
    setPhysicalHideRegion(countryChanged === COUNTRIES_ID.UNITED_STATES || countryChanged === COUNTRIES_ID.CANADA || countryChanged === '');
  }, [Country]);
  const stateProvinceData = [];
  const countryData = [];

  const [disable, setDisable] = useState(false);

  const defaultValues = {
    mailingSameAsPhysical: false,
    physicalAddress: {
      street1: '',
      street2: '',
      city: '',
      country: '',
      stateOrProvince: '',
      postalCode: '',
      region: '',
    },
    mailingAddress: {
      street1: '',
      street2: '',
      city: '',
      country: '',
      stateOrProvince: '',
      postalCode: '',
      region: '',
    },
    contactInfo: {
      teleNumber: '',
      ext: '',
      mobilePhone: '',
      facsimileNumber: '',
      email: '',
      website: '',
    },
  };

  // Sorting country list
  function countrySort(countryList) {
    // eslint-disable-next-line prefer-arrow-callback
    const data = countryList.slice(2).sort(function(a, b) {
      if (a.Name < b.Name) {
        return -1;
      }
      if (a.Name > b.Name) {
        return 1;
      }
      return 0;
    });
    return data;
  }

  const restCountryList = countrySort(Country);
  const initialCountryList = Country.slice(0, 2);
  const sortedCountryList = [...initialCountryList, ...restCountryList];

  if (sortedCountryList?.length > 0) {
    sortedCountryList?.map((item) => {
      if (item.Name !== '') {
        countryData.push({ ID: item.ID, Name: item.Name });
        countryData.sort();
      }
      return countryData;
    });
  }

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const [clearMailDirtyFields, setClearMailDirtyFields] = useState();
  const [clearPhysDirtyFields, setClearPhysDirtyFields] = useState();
  const [clearContactDirtyFields, setClearContactDirtyFields] = useState();


  useEffect(() => {
    setClearMailDirtyFields(
      (methods && methods?.formState?.errors?.mailingAddress) ||
      !Object.values(methods?.formState?.dirtyFields?.mailingAddress || {}).some(Boolean),
    );

    setClearPhysDirtyFields(
      (methods && methods?.formState?.errors?.physicalAddress) ||
      !Object.values(methods?.formState?.dirtyFields?.physicalAddress || {}).some(Boolean),
    );

    setClearContactDirtyFields(
      (methods && methods?.formState?.errors?.contactInfo) ||
      !Object.values(methods?.formState?.dirtyFields?.contactInfo || {}).some(Boolean),
    );
  }, [methods?.formState]);

  const onSubmit = async (data) => {
    const newStepsData = {};
    const latestStep = [];
    // preparing latest data here in steps
    steps.map((item) => {
      if (item.selected) {
        latestStep.push({ ...item, filledData: data });
      } else {
        latestStep.push(item);
      }
      return item;
    });

    latestStep.map((item) => {
      newStepsData[item.key] = item.filledData;
      return item;
    });
    dispatch(setLoader(true));

    saveContactFormData(
      {
        newStepsData,
        latestStep,
        selectedJurisdiction,
        selectedEntity,
        jurisdictions,
        Entities,
        Purpose,
        UserClassification,
        Country,
        StateProvince,
        StateProvinceforMailing,
        Organization,
      },
      navigate,
      id,
      fieldPermissions,
    )
      .then((response) => {
        if (!isEmpty(response)) {
          dispatch(setLoader(false));
          if (response && !id) {
            navigate(`/entity-registration/${response.data}`);
          }
          dispatch(setNextStepSelect({ steps }));
          dispatch(getNAICS(response?.data));
          dispatch(getEntityContactById(response?.data));
          dispatch(setSubmittedOfSelectedStep(true));
        }
        return response;
      })
      .catch((error) => {
        showContactSaveError(error);

        logger({ error, name: 'saveContactFormData' });
        dispatch(setLoader(false));
        if (error?.response?.data?.messageKey !== 'Type of Organization is required') {
          dispatch(setSubmittedOfSelectedStep(false));
        }
      });
  };

  const setDefaultValues = () => {
    const defaultValuesFilled = steps.find((item) => item.key === COMPONENT_NAMES.CONTACT_INFORMATION).filledData;
    if (!isEmpty(defaultValuesFilled)) {
      methods?.reset(defaultValuesFilled);
      methods?.setValue('physicalAddress.country', defaultValuesFilled?.physicalAddress?.country?.id);
      methods?.setValue('mailingAddress.country', defaultValuesFilled?.mailingAddress?.country?.id);
      if (defaultValuesFilled?.mailingAddress?.country?.id) {
        updateStateByCountryforMailing(defaultValuesFilled.mailingAddress.country?.id);
      }
      if (defaultValuesFilled?.physicalAddress?.country?.id) {
        updateStateByCountry(defaultValuesFilled.physicalAddress.country?.id);
      }
    } else {
      methods?.reset(defaultValues);
    }
  };

  const handleSameAddressCheck = (check, allowMailingAddressSave = true) => {
    dispatch(setMaillingBtnDisable(check));
    updateCardEdit({ cardName: 'MAILING_ADDRESS', isEditing: !check });
    if (check) {
      setDisable(true);
      const physicalAddress = methods.getValues('physicalAddress');
      const country = Country.find((item) => item?.ID === physicalAddress?.country);
      dispatch(setStateProvinceforMailing(country?.States));
      methods.setValue('mailingAddress', physicalAddress, {
        shouldDirty: true,
        shouldValidate: true,
      });

      methods.setValue('mailingSameAsPhysical', check);

      if (hidePhysicalRegion) {
        setHideMailingRegion(true);
      } else {
        setHideMailingRegion(false);
        methods.setValue('mailingAddress.stateOrProvince', physicalAddress.stateOrProvince);
      }

    } else {
      setDisable(false);
      methods.setValue(
        'mailingAddress',
        {
          ...defaultValues.mailingAddress,
        },
        { shouldDirty: true, shouldValidate: true },
      );
      dispatch(setStateProvinceforMailing([]));
    }
    methods.setValue('mailingSameAsPhysical', check);
    methods.trigger('mailingAddress');
    methods.trigger('physicalAddress');
  };
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const updateStateByCountry = (countryID, fromUserChange = false) => {
    if (fromUserChange) {
      methods.setValue('physicalAddress.stateOrProvince', '');
      methods.setValue('physicalAddress.region', '');
      methods.clearErrors('physicalAddress.region');
      if (methods.getValues('mailingSameAsPhysical')) {
        spreadSameAsPhysicalAddress();
      }
    }
    if (Country.length) {
      const country = Country.find((item) => item.ID === countryID);
      if (methods.getValues('mailingSameAsPhysical')) {
        dispatch(setStateProvinceforMailing(country?.States));
      }
      dispatch(setStateProvince(country?.States));

      if (country?.Name === COUNTRY_KEY_NAMES.UNITED_STATES || country?.Name === COUNTRY_KEY_NAMES.CANADA) {
        setPhysicalHideRegion(true);
        if (methods.getValues('mailingSameAsPhysical')) {
          setHideMailingRegion(true);
        }
      } else {
        setPhysicalHideRegion(false);
        if (methods.getValues('mailingSameAsPhysical')) {
          setHideMailingRegion(false);
        }
      }
    }
  };

  const updateStateByCountryforMailing = (countryID, fromUserChange = false) => {
    if (fromUserChange) {
      methods.setValue('mailingAddress.stateOrProvince', '');
      methods.setValue('mailingAddress.region', '');
      methods.clearErrors('mailingAddress.region');
    }
    if (Country.length) {
      const country = Country.find((item) => item.ID === countryID);
      dispatch(setStateProvinceforMailing(country?.States));
      if (country?.Name === COUNTRY_KEY_NAMES.UNITED_STATES || country?.Name === COUNTRY_KEY_NAMES.CANADA) {
        setHideMailingRegion(true);
      } else {
        setHideMailingRegion(false);
        methods.clearErrors('mailingAddress.stateOrProvince');
      }
    }
  };
  const handleSubmitUpdateAdditional = () => {
    const values = methods.getValues('contactInfo');
    dispatch(setLoader(true));
    updateEntity(
      {
        entityContact: {
          telephoneNumber: values?.teleNumber?.trim(),
          ext: values?.ext,
          mobilePhone: values?.mobilePhone ? values?.mobilePhone?.trim() : '',
          facsimileNumber: values?.facsimileNumber ? values?.facsimileNumber?.trim() : '',
          email: values?.email,
          websiteAddress: values?.website,
          consent: 'yes',
        },
      },
      id,
      false,
      {
        pageName: 'Page_EntityRegistration',
        componentName: 'entityContact',
      },
    )
      .then((response) => {
        dispatch(getEntityContactById(id));
        handleEnableEditAdditional(false);
        dispatch(setLoader(false));
        if (isReviewAndSubmit) {
          dispatch(setModelMessage({
            title: `${t(t1, 'COMMON_SUCCESS')}`,
            message1: t(t1, 'DATA_UPDATED_SUCCESSFULLY'),
            error: false,
            isDefaultDisplay: false,
          }));
        }
        dispatch(setIsFormEditable(false));
        updateCardEdit({ cardName: 'ADDITIONAL_INFORMATION', isEditing: false });
      })
      .catch((error) => {
        dispatch(setLoader(false));
        const hasNavigated = handleEntityStatusError(error, id, dispatch, navigate);
        if (hasNavigated) return;
        showContactSaveError(error);
      });

  };

  const handleEnableEditAdditional = (value) => {
    methods?.reset({}, { keepValues: true });
    setStepDataAdditional({ ...stepDataAdditional, editMode: value });
    updateCardEdit({ cardName: 'ADDITIONAL_INFORMATION', isEditing: value });
    dispatch(setIsFormEditable(true));
    methods?.trigger();
  };
  const handleSubmitUpdateMailing = () => {
    const values = methods.getValues('mailingAddress');
    const mailingSameAsPhysical = methods.getValues('mailingSameAsPhysical');
    dispatch(setLoader(true));
    updateEntity(
      {
        mailing: {
          street1: values?.street1,
          street2: values?.street2,
          city: values?.city,
          state: values?.stateOrProvince,
          region: values?.region,
          postalCode: values?.postalCode,
          country: values?.country,
          sameAsPhysicalAddress: mailingSameAsPhysical,
          type: 'Mailing',
        },
      },
      id,
      false,
      {
        pageName: 'Page_EntityRegistration',
        componentName: 'mailing',
      },
    )
      .then((response) => {
        dispatch(getEntityContactById(id));
        handleEnableEditMailing(false);
        dispatch(setLoader(false));
        if (isReviewAndSubmit) {
          dispatch(setModelMessage({
            title: `${t(t1, 'COMMON_SUCCESS')}`,
            message1: t(t1, 'DATA_UPDATED_SUCCESSFULLY'),
            error: false,
            isDefaultDisplay: false,
          }));
        }
        dispatch(setIsFormEditable(false));
        updateCardEdit({ cardName: 'MAILING_ADDRESS', isEditing: false });
      })
      .catch((error) => {
        dispatch(setLoader(false));
        const hasNavigated = handleEntityStatusError(error, id, dispatch, navigate);
        if (hasNavigated) return;
        showContactSaveError(error);
      });
  };

  const handleEnableEditMailing = (value) => {
    methods?.reset({}, { keepValues: true });
    setStepDataMailing({ ...stepDataMailing, editMode: value });
    dispatch(setIsFormEditable(true));
    updateCardEdit({ cardName: 'MAILING_ADDRESS', isEditing: value });
    methods?.trigger();
  };
  const handleSubmitUpdate = () => {
    const values = methods.getValues('physicalAddress');
    dispatch(setLoader(true));
    updateEntity(
      {
        physical: {
          street1: values?.street1,
          street2: values?.street2,
          city: values?.city,
          state: values?.stateOrProvince,
          region: values?.region,
          postalCode: values?.postalCode,
          country: values?.country,
          sameAsPhysicalAddress: values?.SameAsPhysicalAddress,
          type: 'Physical',
        },
      },
      id,
      false,
      {
        pageName: 'Page_EntityRegistration',
        componentName: 'physical',
      },
    )
      .then((response) => {
        dispatch(getEntityContactById(id));
        handleEnableEdit(false);
        dispatch(setLoader(false));
        if (isReviewAndSubmit) {
          dispatch(setModelMessage({
            title: `${t(t1, 'COMMON_SUCCESS')}`,
            message1: t(t1, 'DATA_UPDATED_SUCCESSFULLY'),
            error: false,
            isDefaultDisplay: false,
          }));
        }
        dispatch(setIsFormEditable(false));
        updateCardEdit({ cardName: 'PHYSICAL_ADDRESS', isEditing: false });
        // if (combineAddressUpdate && methods.getValues("mailingSameAsPhysical")) {
        //   handleSubmitUpdateMailing();
        // }
      })
      .catch((error) => {
        dispatch(setLoader(false));
        const hasNavigated = handleEntityStatusError(error, id, dispatch, navigate);
        if (hasNavigated) return;
        showContactSaveError(error);
      });
  };

  const handleEnableEdit = (value) => {
    methods?.reset({}, { keepValues: true });
    setStepData({ ...stepData, editMode: value });
    if (isRevisionRequested) {
      setStepDataAdditional({ ...stepDataAdditional, editMode: value });
      setStepDataMailing({ ...stepDataMailing, editMode: value });
    }
    dispatch(setIsFormEditable(true));
    updateCardEdit({ cardName: 'PHYSICAL_ADDRESS', isEditing: value });
    methods?.trigger();
  };

  const handleEditCancel = () => {
    setDefaultValues();
    dispatch(setIsFormEditable(false));
  };

  const handlePhysicalAddressCancel = () => {
    methods?.reset({}, { keepValues: true });
    const defaultValuesFilled = steps.find((item) => item.key === COMPONENT_NAMES.CONTACT_INFORMATION).filledData;

    if (!isEmpty(defaultValuesFilled)) {
      methods?.setValue('physicalAddress', defaultValuesFilled?.physicalAddress);
      if (methods.getValues('mailingSameAsPhysical')) {
        methods?.setValue('mailingAddress', defaultValuesFilled?.physicalAddress);
        methods?.setValue('mailingAddress.country', defaultValuesFilled?.mailingAddress.country?.id);
        updateStateByCountryforMailing(defaultValuesFilled?.mailingAddress.country?.id);
      }
      methods?.setValue('physicalAddress.country', defaultValuesFilled?.physicalAddress.country?.id);
      if (defaultValuesFilled?.physicalAddress.country?.id) {
        updateStateByCountry(defaultValuesFilled?.physicalAddress.country?.id);
      }
    }
    setPhysicalCountry(false);
    dispatch(setIsFormEditable(false));
    updateCardEdit({ cardName: 'PHYSICAL_ADDRESS', isEditing: false });
    methods?.trigger();
  };

  const handleMailingAddressCancel = () => {
    const defaultValuesFilled = steps.find((item) => item.key === COMPONENT_NAMES.CONTACT_INFORMATION).filledData;
    methods?.reset({}, { keepValues: true });
    if (!isEmpty(defaultValuesFilled)) {
      methods?.setValue('mailingAddress', defaultValuesFilled?.mailingAddress);
      methods?.setValue('mailingSameAsPhysical', defaultValuesFilled?.mailingSameAsPhysical);
      setDisable(defaultValuesFilled?.mailingSameAsPhysical);
      methods?.setValue('mailingAddress.country', defaultValuesFilled?.mailingAddress.country?.id);

      if (defaultValuesFilled?.mailingAddress?.sameAsPhysicalAddress) {
        methods?.setValue('mailingAddress', defaultValuesFilled?.physicalAddress);
        methods?.setValue('mailingAddress.country', defaultValuesFilled?.physicalAddress.country?.id);
        if (
          !isEqual(
            omit(defaultValuesFilled?.physicalAddress, ['updatedAt', 'id']),
            omit(defaultValuesFilled?.mailingAddress, ['updatedAt', 'id']),
          )
        ) {
          handleSubmitUpdateMailing();
        }
      }
      if (methods?.getValues('mailingAddress.country')) {
        updateStateByCountryforMailing(methods?.getValues('mailingAddress.country'));
      }
    }
    setMailingCountry(false);
    dispatch(setIsFormEditable(false));
    updateCardEdit({ cardName: 'MAILING_ADDRESS', isEditing: false });
    methods?.trigger();
  };

  const handleAdditionalcancel = () => {
    methods?.reset({}, { keepValues: true });
    const defaultValuesFilled = steps.find((item) => item.key === COMPONENT_NAMES.CONTACT_INFORMATION).filledData;
    updateCardEdit({ cardName: 'ADDITIONAL_INFORMATION', isEditing: false });
    if (!isEmpty(defaultValuesFilled)) {
      methods?.setValue('contactInfo', defaultValuesFilled?.contactInfo);
    }
    methods?.clearErrors();
    methods?.trigger();
  };

  const spreadSameAsPhysicalAddress = () => {
    if (methods.getValues('mailingSameAsPhysical')) {
      setTimeout(() => {
        const value = methods.getValues('physicalAddress');
        methods.setValue('mailingAddress', value);
        methods.trigger('mailingAddress');
        methods?.trigger('physicalAddress');
        methods?.trigger('physicalAddress.stateOrProvince');
        methods?.trigger('physicalAddress.region');
      }, 500);
    }
  };

  const AllValues = steps.find((item) => item.key === COMPONENT_NAMES.CONTACT_INFORMATION).filledData;
  useEffect(() => {
    if (isReviewAndSubmit) {
      methods?.setValue('contactInfo.teleNumber', AllValues?.contactInfo?.telephoneNumber);
      methods?.setValue('contactInfo.mobilePhone', AllValues?.contactInfo?.mobilePhone);
      methods?.setValue('contactInfo.facsimileNumber', AllValues?.contactInfo?.facsimileNumber);
      methods?.setValue('contactInfo.ext', AllValues?.contactInfo?.ext);
      methods?.setValue('contactInfo.email', AllValues?.contactInfo?.email);
      methods?.setValue('contactInfo.website', AllValues?.contactInfo?.websiteAddress);
    }
  }, [AllValues]);

  const viewOnly = mode === COMPONENT_MODES.EDIT && !stepData.editMode;
  const viewOnlyAdditional = mode === COMPONENT_MODES.EDIT && !stepDataAdditional.editMode;
  const viewOnlyMailing = mode === COMPONENT_MODES.EDIT && !stepDataMailing.editMode;
  const isEditable = EditAccess;

  const resetData = () => {
    dispatch(resetSteps());
    dispatch(setEntityType(''));
    dispatch(setEntityID(0));
    navigate('/start-entity-registration');
  };

  const isPhysicalAddressDirty = Object.values(methods?.formState?.dirtyFields?.physicalAddress || {}).some(e => e);
  const isMailingAddressDirty = Object.values(methods?.formState?.dirtyFields?.mailingAddress || {}).some(e => e);
  const isContactInfoDirty = Object.values(methods?.formState?.dirtyFields?.contactInfo || {}).some(e => e);

  return (
    <FormProvider {...methods}>
      {displayHead && (
        <div
          className={`STEP_CLASSNAME_${COMPONENT_NAMES.CONTACT_INFORMATION} MODE_${mode} ${viewOnly ? 'VIEW_ONLY' : ''
          }`}
        >
          <Typography style={Style.contentTextCard}>
            <div className="contentTextCardTwo" style={Style.contentTextCard}>
              <TextTruncate
                content={t(t1, 'FOOTER_DISCLAIMER_CONTENT')}
                animate
              />
            </div>
          </Typography>
        </div>
      )}
      <div
        className={`STEP_CLASSNAME_${COMPONENT_NAMES.CONTACT_INFORMATION} MODE_${mode} ${viewOnly ? 'physical-viewonly' : ''
        }`}
      >
        <FormCard
          customClass={'customFormCard physical-address'}
          title={isRevisionRequested ? t(t1, 'PHYSICAL_ADDRESS') : t(t1, 'CONTACT_INFORMATION_PHYSICAL_ADDRESS')}
          indicateRequired
          description={t(t1, 'INDICATE_REQUIRED_FIELDS')}
          setDefaultValues={setDefaultValues}
          stepNumber={3}
          mode={mode}
          handleEnableEdit={handleEnableEdit}
          handleSubmitUpdate={handleSubmitUpdate}
          cancelRegistraionContactEdit={handlePhysicalAddressCancel}
          saveDisabled={clearPhysDirtyFields}
          stepData={stepData}
          stepName={COMPONENT_NAMES.SELECT_ENTITY_TYPE}
          customeStyle={viewOnly ? Style.formCardPadding : Style.onEditPage}
          isEditAccess={isEditable}
        >
          <Grid
            container
            spacing={2.5}
            sx={{ mt: 1 }}
          >
            {SopfDetailView(<Grid
              item
              xs={4}
              sx={{ mt: '-35px !important' }}
            >
              <GridTextfield
                componentName="physical"
                fieldName="street1"
                isViewOnly={viewOnly}
                name="physicalAddress.street1"
                label={t(t1, 'STREET_1')}
                rules={{
                  required: true,
                  validate: (v) =>
                    methods?.getValues('physicalAddress.street1')
                      ? verifyStreet1orStreet2(v, ``, t1, t(t1, 'ERR_STREET_1_MIN_LENGTH_CHAR'), t(t1, 'ERR_STREET_1_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_STREET_1'), {
                        minLength: 1,
                        maxLength: 255,
                      })
                      : null,
                }}
                isRequiredMsg={'ERR_STREET_1_IS_REQUIRED'}
                trimLeadingSpaces
                stepNumber={2}
                gridMd={12}
                refField={legalNameRef}
                disabled={!isEditable}
                onKeyUp={spreadSameAsPhysicalAddress}
                customTextClassName={viewOnly ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'physical', 'street1', fieldPermissions)}

            {SopfDetailView(<Grid
              item
              xs={4}
              sx={{ mt: '-35px !important' }}
            >
              <GridTextfield
                componentName="physical"
                fieldName="street2"

                isViewOnly={viewOnly}
                name="physicalAddress.street2"
                label={t(t1, 'STREET_2')}
                rules={{
                  validate: (v) =>
                    methods?.getValues('physicalAddress.street2')
                      ? verifyStreet1orStreet2(v, ``, t1, t(t1, 'ERR_STREET_2_MIN_LENGTH_CHAR'), t(t1, 'ERR_STREET_2_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_STREET_2'), {
                        minLength: 1,
                        maxLength: 255,
                      })
                      : null,
                }}
                trimLeadingSpaces
                stepNumber={2}
                gridMd={12}
                refField={operatingNameRef}
                disabled={!isEditable}
                onKeyUp={spreadSameAsPhysicalAddress}
                customTextClassName={viewOnly ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'physical', 'street2', fieldPermissions)}
            {SopfDetailView(<Grid
              item
              xs={4}
              sx={{ mt: '-35px !important' }}
            >
              <GridTextfield
                componentName="physical"
                fieldName="city"
                isViewOnly={viewOnly}
                name="physicalAddress.city"
                label={t(t1, 'CITY')}
                rules={{
                  required: true,
                  validate: (v) =>
                    methods?.getValues('physicalAddress.city')
                      ? verifyCityOrRegion(v, ``, t1, t(t1, 'ERR_CITY_MIN_LENGTH_CHAR'), t(t1, 'ERR_CITY_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_CITY'), {
                        minLength: 1,
                        maxLength: 255,
                      })
                      : null,
                }}
                isRequiredMsg={'ERR_CITY_IS_REQUIRED'}
                trimLeadingSpaces
                stepNumber={2}
                gridMd={12}
                refField={operatingNameRef}
                disabled={!isEditable}
                onKeyUp={spreadSameAsPhysicalAddress}
                customTextClassName={viewOnly ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'physical', 'city', fieldPermissions)}
          </Grid>

          <Grid
            container
            spacing={2.5}
            sx={{ mt: 1.5 }}
          >
            {SopfDetailView(<Grid
              item
              xs={4}
              sx={{ mt: '-35px !important' }}
            >
              <CustomAutocomplete
                componentName="physical"
                fieldName="country"
                isViewOnly={viewOnly}
                viewName
                privId="priv_txt_select_ountry"
                name="physicalAddress.country"
                onClose={() => methods.trigger('physicalAddress.country')}
                label={t(t1, 'COUNTRY')}
                noValueText={t(t1, 'SELECT_COUNTRY')}
                options={countryData?.map((each) => ({ name: t(t1, each.Name), value: each.ID }))}
                rules={{ required: t(t1, 'COUNTRY_REQUIRED') }}
                isRequiredMsg={'ERR_COUNTRY_IS_REQUIRED'}
                InputProps={{
                  'aria-required': 'true',
                }}
                handleOnChange={(e) => {
                  setPhysicalCountry(true);
                  updateStateByCountry(e?.value, true);
                }}
                className="input-group"
                stepNumber={2}
                gridMd={12}
                disabled={!isEditable}
                customTextClassName={viewOnly ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'physical', 'country', fieldPermissions)}

            {hidePhysicalRegion ? (
                SopfDetailView(<Grid
                  item
                  xs={4}
                  sx={{ mt: '-35px !important' }}
                >
                  <CustomAutocomplete
                    componentName="physical"
                    fieldName="state"
                    noValidate
                    isViewOnly={viewOnly}
                    onClose={() => methods.trigger('physicalAddress.stateOrProvince')}
                    viewName
                    privId="priv_txt_state_province"
                    name="physicalAddress.stateOrProvince"
                    noValueText={t(t1, 'SELECT_STATE_OR_PROVINCE')}
                    label={t(t1, 'STATE_OR_PROVINCE')}
                    options={methods.getValues('physicalAddress.country') ?
                      StateProvince && StateProvince.map((each) => ({ name: t(t1, each.Name), value: each.ID })) : []
                    }
                    rules={{ required: true }}
                    isRequiredMsg={'ERR_STATE_OR_PROVINCE_IS_REQUIRED'}
                    InputProps={{
                      'aria-required': 'true',
                    }}
                    handleOnChange={(e) => {
                      setPhysicalCountry(false);
                      spreadSameAsPhysicalAddress();
                    }}
                    className="input-group"
                    stepNumber={2}
                    gridMd={12}
                    disabled={!isEditable}
                  />
                </Grid>, 'physical', 'state', fieldPermissions)
              )
              :
              (
                SopfDetailView(<Grid
                  item
                  xs={4}
                  sx={{ mt: '-35px !important' }}
                >
                  <GridTextfield
                    componentName="physical"
                    fieldName="region"
                    noValidate
                    isViewOnly={viewOnly}
                    name="physicalAddress.region"
                    label={t(t1, 'REGION')}
                    rules={{
                      required: true,
                      validate: (v) =>
                        methods?.getValues('physicalAddress.region')
                          ? verifyIdentificationNumberWithoutSpecialCharacters(v, ``, t1, t(t1, 'ERR_REGION_MIN_LENGTH_CHAR'), t(t1, 'ERR_REGION_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_REGION'), {
                            minLength: 1,
                            maxLength: 255,
                          })
                          : null,
                    }}
                    isRequiredMsg={'ERR_REGION_IS_REQUIRED'}
                    onKeyDown={(e) => {
                      setPhysicalCountry(false);
                    }}
                    trimLeadingSpaces
                    stepNumber={2}
                    gridMd={12}
                    disabled={!isEditable}
                    onKeyUp={spreadSameAsPhysicalAddress}
                    customTextClassName={viewOnly ? 'viewOnlyTextareaContent' : ''}
                  />
                </Grid>, 'physical', 'region', fieldPermissions)
              )}

            {SopfDetailView(<Grid
              item
              xs={4}
              sx={{ mt: '-35px !important' }}
            >
              <GridTextfield
                componentName="physical"
                fieldName="postalCode"
                isViewOnly={viewOnly}
                name="physicalAddress.postalCode"
                label={t(t1, 'POSTAL_CODE')}
                rules={{
                  required: true,
                  validate: (v) =>
                    methods?.getValues('physicalAddress.postalCode')
                      ? validatingPostalCode(v, ``, t1, { minLength: 3, maxLength: 20 })
                      : null,
                }}
                isRequiredMsg={'ERR_POSTAL_CODE_IS_REQUIRED'}
                trimLeadingSpaces
                stepNumber={2}
                gridMd={12}
                refField={operatingNameRef}
                disabled={!isEditable}
                onKeyUp={spreadSameAsPhysicalAddress}
              />
            </Grid>, 'physical', 'postalCode', fieldPermissions)}
          </Grid>
        </FormCard>
      </div>
      <div
        className={`STEP_CLASSNAME_${COMPONENT_NAMES.CONTACT_INFORMATION} MODE_${mode} ${viewOnlyMailing ? 'mailing-viewonly' : ''
        }`}
      >
        <FormCardAddFacility
          customClass={'customFormCard mailing-address'}
          title={isRevisionRequested ? t(t1, 'MAILING_ADDRESS') : t(t1, 'CONTACT_INFORMATION_MAILING_ADDRESS')}
          indicateRequired
          description={t(t1, 'INDICATE_REQUIRED_FIELDS')}
          formLabel={isRevisionRequested ? null : t(t1, 'CHECK_IF_SAME_AS_PHYSICAL_ADDRESS_CHECKBOX')}
          handleSameAddress={handleSameAddressCheck}
          setDefaultValues={setDefaultValues}
          stepNumber={3}
          defaultChecked={methods.getValues('mailingSameAsPhysical')}
          mode={mode}
          handleEnableEdit={handleEnableEditMailing}
          handleSubmitUpdate={handleSubmitUpdateMailing}
          cancelRegistraionContactEdit={handleMailingAddressCancel}
          saveDisabled={clearMailDirtyFields}
          stepData={stepDataMailing}
          stepName={COMPONENT_NAMES.SELECT_ENTITY_TYPE}
          showEditbtn={isRevisionRequested ? false : null}
          showSaveCancelbtn={isRevisionRequested ? false : null}
          customeStyle={!viewOnly && isEditable ? Style.onEditPage : mode === COMPONENT_MODES.ADD && Style.formCardPadding}
          disableCheckOnReview={viewOnlyMailing || !isEditable}
          isEditAccess={isEditable}
        >
          <Grid
            container
            spacing={2.5}
            sx={{ mt: 0.5 }}
          >
            {SopfDetailView(<Grid
              item
              xs={4}
              sx={{ mt: '-35px !important' }}
            >
              <GridTextfield
                componentName="mailing"
                fieldName="street1"
                inputClass={disable ? 'disabledField' : ''}
                isViewOnly={viewOnlyMailing}
                name="mailingAddress.street1"
                label={t(t1, 'STREET_1')}
                rules={{
                  required: true,
                  validate: (v) =>
                    methods?.getValues('mailingAddress.street1')
                      ? verifyStreet1orStreet2(v, ``, t1, t(t1, 'ERR_STREET_1_MIN_LENGTH_CHAR'), t(t1, 'ERR_STREET_1_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_STREET_1'), {
                        minLength: 1,
                        maxLength: 255,
                      })
                      : null,
                }}
                isRequiredMsg={'ERR_STREET_1_IS_REQUIRED'}
                trimLeadingSpaces
                stepNumber={2}
                gridMd={12}
                refField={legalNameRef}
                disabled={disable || !isEditable}
                customTextClassName={viewOnlyMailing ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'mailing', 'street1', fieldPermissions)}

            {SopfDetailView(<Grid
              item
              xs={4}
              sx={{ mt: '-35px !important' }}
            >
              <GridTextfield
                componentName="mailing"
                fieldName="street2"
                inputClass={disable ? 'disabledField' : ''}
                isViewOnly={viewOnlyMailing}
                name="mailingAddress.street2"
                label={t(t1, 'STREET_2')}
                rules={{
                  validate: (v) =>
                    methods?.getValues('mailingAddress.street2')
                      ? verifyStreet1orStreet2(v, ``, t1, t(t1, 'ERR_STREET_2_MIN_LENGTH_CHAR'), t(t1, 'ERR_STREET_2_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_STREET_2'), {
                        minLength: 1,
                        maxLength: 255,
                      })
                      : null,
                }}
                trimLeadingSpaces
                stepNumber={2}
                gridMd={12}
                refField={operatingNameRef}
                disabled={disable || !isEditable}
                customTextClassName={viewOnlyMailing ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'mailing', 'street2', fieldPermissions)}
            {SopfDetailView(<Grid
              item
              xs={4}
              sx={{ mt: '-35px !important' }}
            >
              <GridTextfield
                componentName="mailing"
                fieldName="city"
                inputClass={disable ? 'disabledField' : ''}
                isViewOnly={viewOnlyMailing}
                name="mailingAddress.city"
                label={t(t1, 'CITY')}
                rules={{
                  required: true,
                  validate: (v) =>
                    methods?.getValues('mailingAddress.city')
                      ? verifyCityOrRegion(v, ``, t1, t(t1, 'ERR_CITY_MIN_LENGTH_CHAR'), t(t1, 'ERR_CITY_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_CITY'), {
                        minLength: 1,
                        maxLength: 255,
                      })
                      : null,
                }}
                isRequiredMsg={'ERR_CITY_IS_REQUIRED'}
                trimLeadingSpaces
                stepNumber={2}
                gridMd={12}
                refField={operatingNameRef}
                disabled={disable || !isEditable}
                customTextClassName={viewOnlyMailing ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'mailing', 'city', fieldPermissions)}
          </Grid>

          <Grid
            container
            columnSpacing={2.5}
            sx={{ mt: 1.5 }}
          >
            {SopfDetailView(<Grid
              item
              xs={4}
              sx={{ mt: '-20px !important' }}
            >
              <CustomAutocomplete
                componentName="mailing"
                fieldName="country"
                onClose={() => methods.trigger('mailingAddress.country')}
                className={disable ? 'disabledField' : ''}
                isViewOnly={viewOnlyMailing}
                viewName
                privId="priv_txt_select_ountry"
                name="mailingAddress.country"
                label={t(t1, 'COUNTRY')}
                noValueText={t(t1, 'SELECT_COUNTRY')}
                options={countryData?.map((each, index) => ({ name: t(t1, each.Name), value: each.ID }))}
                handleOnChange={(e) => {
                  setMailingCountry(true);
                  updateStateByCountryforMailing(e?.value, true);
                }}
                rules={{ required: true }}
                isRequiredMsg={'ERR_COUNTRY_IS_REQUIRED'}
                InputProps={{
                  'aria-required': 'true',
                }}
                stepNumber={2}
                gridMd={12}
                disabled={disable || !isEditable}
                customeStyle={disable ? Style.disabledLabelStyle : ''}
              />
            </Grid>, 'mailing', 'country', fieldPermissions)}

            {hideMailingRegion ?
              (
                SopfDetailView(<Grid
                  item
                  xs={4}
                  sx={{ mt: '-20px !important' }}
                >
                  <CustomAutocomplete
                    componentName="mailing"
                    fieldName="state"
                    noValidate
                    className={disable ? 'disabledField' : ''}
                    isViewOnly={viewOnlyMailing}
                    onClose={() => methods.trigger('mailingAddress.stateOrProvince')}
                    viewName
                    privId="priv_txt_state_province"
                    name="mailingAddress.stateOrProvince"
                    noValueText={t(t1, 'SELECT_STATE_OR_PROVINCE')}
                    label={t(t1, 'STATE_OR_PROVINCE')}
                    options={methods.getValues('mailingAddress.country') ?
                      StateProvinceforMailing && StateProvinceforMailing.map((each) => ({
                        name: t(t1, each.Name),
                        value: each.ID,
                      })) : []
                    }
                    rules={{ required: true }}
                    isRequiredMsg={'ERR_STATE_OR_PROVINCE_IS_REQUIRED'}
                    InputProps={{
                      'aria-required': 'true',
                    }}
                    handleOnChange={(e) => {
                      setMailingCountry(false);
                    }}
                    stepNumber={2}
                    gridMd={12}
                    disabled={disable || !isEditable}
                    customeStyle={disable ? Style.disabledLabelStyle : ''}
                  />
                </Grid>, 'mailing', 'state', fieldPermissions)
              )
              :
              (
                SopfDetailView(<Grid
                  item
                  xs={4}
                  sx={{ mt: '-20px !important' }}
                >
                  <GridTextfield
                    componentName="mailing"
                    fieldName="region"
                    noValidate
                    inputClass={disable ? 'disabledField' : ''}
                    isViewOnly={viewOnlyMailing}
                    name="mailingAddress.region"
                    label={t(t1, 'REGION')}
                    rules={{
                      required: true,
                      validate: (v) =>
                        methods?.getValues('mailingAddress.region')
                          ? verifyIdentificationNumberWithoutSpecialCharacters(v, ``, t1, t(t1, 'ERR_REGION_MIN_LENGTH_CHAR'), t(t1, 'ERR_REGION_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_REGION'), {
                            minLength: 1,
                            maxLength: 255,
                          })
                          : null,
                    }}
                    isRequiredMsg={'ERR_REGION_IS_REQUIRED'}
                    onKeyDown={(e) => {
                      setMailingCountry(false);
                    }}
                    trimLeadingSpaces
                    stepNumber={2}
                    gridMd={12}
                    disabled={disable || !isEditable}
                    customTextClassName={viewOnlyMailing ? 'viewOnlyTextareaContent' : ''}
                  />
                </Grid>, 'mailing', 'region', fieldPermissions)
              )}

            {SopfDetailView(<Grid
              item
              xs={4}
              sx={{ mt: '-20px !important' }}
            >
              <GridTextfield
                componentName="mailing"
                fieldName="postalCode"
                inputClass={disable ? 'disabledField' : ''}
                isViewOnly={viewOnlyMailing}
                name="mailingAddress.postalCode"
                label={t(t1, 'POSTAL_CODE')}
                rules={{
                  required: true,
                  validate: (v) =>
                    methods?.getValues('mailingAddress.postalCode')
                      ? validatingPostalCode(v, ``, t1, { minLength: 3, maxLength: 20 })
                      : null,
                }}
                isRequiredMsg={'ERR_POSTAL_CODE_IS_REQUIRED'}
                trimLeadingSpaces
                stepNumber={2}
                gridMd={12}
                refField={operatingNameRef}
                disabled={disable || !isEditable}
                customTextClassName={viewOnlyMailing ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'mailing', 'postalCode', fieldPermissions)}
          </Grid>
        </FormCardAddFacility>
      </div>
      <div
        className={`STEP_CLASSNAME_${COMPONENT_NAMES.CONTACT_INFORMATION} MODE_${mode} ${viewOnlyAdditional ? 'additional-viewonly' : ''
        }`}
      >
        <FormCard
          customClass={'additional_contact_information customFormCard'}
          title={isRevisionRequested ? t(t1, 'ENTITY_CONTACT_INFORMATION') : t(t1, 'ADDITIONAL_CONTACT_INFORMATION')}
          indicateRequired
          description={'indicate required fields'}
          setDefaultValues={setDefaultValues}
          stepNumber={4}
          mode={mode}
          handleEnableEdit={handleEnableEditAdditional}
          handleSubmitUpdate={handleSubmitUpdateAdditional}
          cancelRegistraionContactEdit={handleAdditionalcancel}
          saveDisabled={clearContactDirtyFields}
          stepData={stepDataAdditional}
          stepName={COMPONENT_NAMES.SELECT_ENTITY_TYPE}
          showEditbtn={isRevisionRequested ? false : null}
          showSaveCancelbtn={isRevisionRequested ? false : null}
          customeStyle={Style.formCardPadding}
          isEditAccess={isEditable}
        >
          <Grid
            container
            spacing={2.5}
            sx={{ mt: 1 }}
          >
            {SopfDetailView(<Grid
              item
              xs={viewOnlyAdditional ? 2.5 : 3.2}
              sx={{ mt: '-35px !important' }}
            >
              <GridTextfield
                componentName="entityContact"
                fieldName="telephoneNumber"
                isViewOnly={viewOnlyAdditional}
                name="contactInfo.teleNumber"
                label={t(t1, 'TELEPHONE_NUMBER')}
                rules={{
                  required: true,
                  validate: (v) =>
                    methods?.getValues('contactInfo.teleNumber')
                      ? validatePhoneNumber(v, `${t(t1, 'TELEPHONE_NUMBER')}`, t1, `${t(t1, 'ERR_TELEPHONE_NUMBER_MIN_LENGTH_CHAR')}`, `${t(t1, 'ERR_TELEPHONE_NUMBER_MAX_LENGTH_CHAR')}`, `${t(t1, 'ERR_INVALID_TELEPHONE_NUMBER')}`, '')
                      : null,
                }}
                trimLeadingSpaces
                isRequiredMsg={'ERR_TELEPHONE_NUMBER_IS_REQUIRED'}
                stepNumber={2}
                gridMd={12}
                refField={legalNameRef}
                disabled={!isEditable}
                customTextClassName={viewOnlyAdditional ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'entityContact', 'telephoneNumber', fieldPermissions)}

            {SopfDetailView(<Grid
              item
              xs={viewOnlyAdditional ? 1.5 : .8}
              sx={{ mt: '-35px !important', ...Style.contentEntityContact }}
            >
              <GridTextfield
                componentName="entityContact"
                fieldName="ext"
                isViewOnly={viewOnlyAdditional}
                name="contactInfo.ext"
                label={t(t1, 'EXT')}
                rules={{
                  minLength: {
                    value: 1,
                    message: `${t(t1, 'ERR_EXT_MIN_LENGTH_CHAR')}`,
                  },
                  maxLength: {
                    value: 10,
                    message: `${t(t1, 'ERR_EXT_MAX_LENGTH_CHAR')}`,
                  },
                  validate: (v) =>
                    methods?.getValues('contactInfo.ext')
                      ? validatePattern(v, `${t(t1, 'EXT')}`, t1, extPattern, `${t(t1, 'ERR_INVALID_EXT')}`)
                      : null,
                }}
                onKeyDown={(_) => {
                  if (_.code === 'Space') _.preventDefault();
                }}
                trimLeadingSpaces
                stepNumber={2}
                gridMd={12}
                refField={operatingNameRef}
                disabled={!isEditable}
                customTextClassName={viewOnlyAdditional ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'entityContact', 'ext', fieldPermissions)}
            {SopfDetailView(<Grid
              item
              xs={4}
              sx={{ mt: '-35px !important' }}
            >
              <GridTextfield
                componentName="entityContact"
                fieldName="mobilePhone"
                isViewOnly={viewOnlyAdditional}
                name="contactInfo.mobilePhone"
                label={t(t1, 'MOBILE_PHONE')}
                rules={{
                  validate: (v) =>
                    methods?.getValues('contactInfo.mobilePhone')
                      ? validatePhoneNumber(v, `${t(t1, 'MOBILE_PHONE')}`, t1, `${t(t1, 'ERR_MOBILE_PHONE_MIN_LENGTH_CHAR')}`, `${t(t1, 'ERR_MOBILE_PHONE_MAX_LENGTH_CHAR')}`, `${t(t1, 'ERR_INVALID_MOBILE_PHONE')}`, '')
                      : null,
                }}
                trimLeadingSpaces
                stepNumber={2}
                gridMd={12}
                refField={operatingNameRef}
                disabled={!isEditable}
                customTextClassName={viewOnlyAdditional ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'entityContact', 'mobilePhone', fieldPermissions)}
            {SopfDetailView(<Grid
              item
              xs={4}
              sx={{ mt: '-35px !important' }}
            >
              <GridTextfield
                componentName="entityContact"
                fieldName="facsimileNumber"
                isViewOnly={viewOnlyAdditional}
                name="contactInfo.facsimileNumber"
                label={t(t1, 'FACSIMILE_NUMBER')}
                rules={{
                  validate: (v) =>
                    methods?.getValues('contactInfo.facsimileNumber')
                      ? validatePhoneNumber(v, `${t(t1, 'FACSIMILE_NUMBER')}`, t1, `${t(t1, 'ERR_FACSIMILE_NUMBER_MIN_LENGTH_CHAR')}`, `${t(t1, 'ERR_FACSIMILE_NUMBER_MAX_LENGTH_CHAR')}`, `${t(t1, 'ERR_INVALID_FACSIMILE_NUMBER')}`, '')
                      : null,
                }}
                trimLeadingSpaces
                stepNumber={2}
                gridMd={12}
                refField={operatingNameRef}
                disabled={!isEditable}
                customTextClassName={viewOnlyAdditional ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'entityContact', 'facsimileNumber', fieldPermissions)}
          </Grid>

          <Grid
            container
            spacing={2.5}
            sx={{ mt: 1.5 }}
          >
            {SopfDetailView(<Grid
              item
              xs={4}
              sx={{ mt: '-35px !important' }}
            >
              <GridTextfield
                componentName="entityContact"
                fieldName="email"
                isViewOnly={viewOnlyAdditional}
                name="contactInfo.email"
                label={t(t1, 'EMAIL_ADDRESS')}
                rules={{
                  required: true,
                  maxLength: {
                    value: 205,
                    message: `${t(t1, 'ERR_EMAIL_ADDRESS_MAX_LENGTH_CHAR')}`,
                  },
                  pattern: {
                    value: emailPatternValidation,
                    message: `${t(t1, 'ERR_INVALID_EMAIL_ADDRESS')}`,
                  },
                }}
                isRequiredMsg={'ERR_EMAIL_ADDRESS_IS_REQUIRED'}
                onKeyDown={(_) => {
                  if (_.code === 'Space') _.preventDefault();
                }}
                trimLeadingSpaces
                stepNumber={2}
                gridMd={12}
                refField={legalNameRef}
                disabled={!isEditable}
                customTextClassName={viewOnlyAdditional ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'entityContact', 'email', fieldPermissions)}

            {SopfDetailView(<Grid
              item
              xs={4}
              sx={{ mt: '-35px !important' }}
            >
              <GridTextfield
                componentName="entityContact"
                fieldName="website"
                isViewOnly={viewOnlyAdditional}
                name="contactInfo.website"
                label={t(t1, 'WEBSITE_ADDRESS')}
                rules={{
                  maxLength: {
                    value: 205,
                    message: `${t(t1, 'ERR_WEBSITE_ADDRESS_MAX_LENGTH_CHAR')}`,
                  },
                  pattern: {
                    value: website,
                    message: `${t(t1, 'ERR_INVALID_WEBSITE_ADDRESS')}`,
                  },
                }}
                onKeyDown={(_) => {
                  if (_.code === 'Space') _.preventDefault();
                }}
                trimLeadingSpaces
                stepNumber={2}
                gridMd={12}
                refField={operatingNameRef}
                disabled={!isEditable}
                customTextClassName={viewOnlyAdditional ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'entityContact', 'websiteAddress', fieldPermissions)}
          </Grid>
        </FormCard>
      </div>
      {mode === COMPONENT_MODES.ADD && (
        <FormActions
          handleSubmit={(data) => {
            onSubmit(data);
          }}
          stepNumber={4}
          continueDisabled={!methods?.formState.isValid}
          onExitClick={() => setIsExitInProgress(true)}
          onCancelExit={() => setIsExitInProgress(false)}
        />
      )}
      <UnsavedPrompt id={id} when={!isExitInProgress && displayHead} title={t(t1, 'WARNING')}
                     message={t(t1, 'CONFIRM_LEAVE_PAGE')} onSuccess={resetData} />
    </FormProvider>
  );
}
