import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { MUITableGrid } from 'components/MUITableGrid';
import { Controller, useForm } from 'react-hook-form';
import { GridRadioButton } from 'components/GridRadioButton';
import { FormProvider } from 'components/FormProvider';
import { FormCard, GridTextfield } from 'components';
import { useTranslation } from 'react-i18next';
import { fetcherPatch } from 'utils/fetcher';
import { setDialogMessage, setLoader, setModelMessage } from 'store/commonSlice';
import {
  t,
  getCurrentPageName,
  statusTypes,
  ERROR,
  isEmpty,
  COMMENT_MAX_CHAR_LENGTH,
  REFRESH_REQUIRED,
} from 'utils/constants';
import { CustomizeButton } from 'components/CustomizeButton';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import PassphraseConfirmationDialog from '../../WiringInstructions/components/PassphraseConfirmationDialog';
import {
  getProposedBudgetRecords,
  getStatus,
  getProposedToAdminBudgetRecords,
  getProposedToAuthorityBudgetRecords,
  getBudgetDetails,
  getEffectiveBudgetRecords,
  getAllowanceHistory,
  getWCIProposedBudgetRecords,
  setISLoadingBudgetDetails,
} from '../../../store/manageJurisdictionBudgetSlice';
import Style from '../ManageJurisdictionBudget.style';
import { DialogMessage } from '../../../components';
import { StyledDetailKey, StyledEntryDetailContainer } from '../../AccountDetails/AccountDetails.style';
import { budgetRecords } from './ColumnSchema';
import { isRolesAssigned, USER_ROLES, USER_ROLE_TYPES, APIS } from '../../../utils/constants';
import { JURISDICTION_BUDGET_COMPONENTS_FLP, getFLPHeaders } from '../constant';

function ProposedBudgetRecordsCard(props) {
  const { ProposePage, flp } = props;
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { proposedBudgetRecords, status } = useSelector(({ ManageJurisdictionBudget }) => ManageJurisdictionBudget);
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { isJurisdictionUserAdmin } = useMemo(
    () => ({
      isJurisdictionUserAdmin: isRolesAssigned(userDetails, [USER_ROLE_TYPES.JURISDICTION_USER], [USER_ROLES.ADMIN]),
    }),
    [userDetails],
  );
  const { isJurisdictionUserAuthority } = useMemo(
    () => ({
      isJurisdictionUserAuthority: isRolesAssigned(userDetails, [USER_ROLE_TYPES.JURISDICTION_USER], [USER_ROLES.USER]),
    }),
    [userDetails],
  );
  const { isWCIAdmin } = useMemo(
    () => ({
      isWCIAdmin: isRolesAssigned(userDetails, [USER_ROLE_TYPES.SYSTEM_USER], [USER_ROLES.WCI_ADMIN_DEFAULT_ORG]),
    }),
    [userDetails],
  );
  const radioOptions = [
    { name: 'Request Revisions', value: statusTypes.REVISIONS_REQUESTED, isDisplay: true },
    { name: 'Approve', value: statusTypes.APPROVED, isDisplay: !isJurisdictionUserAdmin },
    { name: 'Propose to Authority', value: statusTypes.PROPOSED_TO_AUTHORITY, isDisplay: !!isJurisdictionUserAdmin },
    { name: 'Deny', value: statusTypes.DENIED, isDisplay: true },
  ];
  const [showPassPhraseDialogue, setShowPassPhraseDialogue] = useState(false);
  const { dialogMessage } = useSelector(({ common }) => common);
  const [selectedRows, setSelectedRows] = useState([]);
  const methods = useForm({ mode: 'all', reValidateMode: 'onChange', defaultValues: {} });
  const { reset, handleSubmit, watch, control, trigger, formState: { errors } } = methods;
  const values = watch();
  const location = useLocation();

  const onSubmit = () => {
    setShowPassPhraseDialogue(true);
  };
  const handleSelectionChange = (params) => {
    setSelectedRows(params);
  };
  const clearAllSelection = () => {
    setSelectedRows([]);
    reset();
  };
  const setWarningModal = () => {
    dispatch(setDialogMessage(true));
  };
  const handleModalSubmit = () => {
    reset();
    clearAllSelection();
  };
  let message = '';
  const successMessage = (entityAction) => {
    if (entityAction === statusTypes.REVISIONS_REQUESTED) message = t(t1, 'REQUEST_REVISION_SUCCESS');
    else if (entityAction === statusTypes.DENIED) message = t(t1, 'DENIED_SUCCESS');
    else message = isJurisdictionUserAuthority ? t(t1, 'PROPOSE_SUCCESS_MESSAGE_AUTHORITY') : t(t1, 'PROPOSE_SUCCESS_MESSAGE_ADMIN');
    return message;
  };

  const onPropose = (body, entityAction) => {
    dispatch(setISLoadingBudgetDetails(true));
    clearAllSelection();
    fetcherPatch(`${APIS.BUDGETS}/jurisdictions/${jurisdictionId}/budget-record`, { jurisdictioBudgetRecord: body },
      getFLPHeaders(JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_RECORD))
      .then(() => {
        dispatch(setISLoadingBudgetDetails(false));
        const data = {
          title: 'PROPOSE_SUCCESS',
          message1: successMessage(entityAction),
          error: false,
        };
        dispatch(setModelMessage(data));
        if (isJurisdictionUserAuthority) dispatch(getProposedToAuthorityBudgetRecords(jurisdictionId));
        if (isJurisdictionUserAdmin) dispatch(getProposedToAdminBudgetRecords(jurisdictionId));
        dispatch(getAllowanceHistory(jurisdictionId));
        dispatch(getBudgetDetails(jurisdictionId));
        dispatch(getEffectiveBudgetRecords(jurisdictionId));
        reset();
        if (isJurisdictionUserAuthority) navigate(`/budgets/${jurisdictionId}`);
      })
      .catch((error) => {
        dispatch(setISLoadingBudgetDetails(false));
        const data = {
          title: ERROR,
          message1: error?.response?.data?.data === REFRESH_REQUIRED ? t(t1, 'REFRESH_ERROR_MESSAGE') : error?.response?.data?.data,
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        reset();
      });
  };
  const { id: jurisdictionId } = useParams();
  useEffect(() => {
    dispatch(getStatus());
    if (userDetails) {
      if (isJurisdictionUserAuthority && ProposePage) dispatch(getProposedToAuthorityBudgetRecords(jurisdictionId));
      else if (isJurisdictionUserAdmin && ProposePage) dispatch(getProposedToAdminBudgetRecords(jurisdictionId));
      else if (!ProposePage && isWCIAdmin) dispatch(getWCIProposedBudgetRecords(jurisdictionId));
      else if (!ProposePage && !isWCIAdmin) dispatch(getProposedBudgetRecords(jurisdictionId, isJurisdictionUserAuthority));
    }
  }, [dispatch, jurisdictionId, ProposePage, userDetails, isJurisdictionUserAuthority, isJurisdictionUserAdmin, isWCIAdmin]);

  const obj = ProposePage
    ? { checkboxSelection: true, onSelectionModelChange: handleSelectionChange, selectionModel: selectedRows }
    : {};
  const column = useMemo(() => budgetRecords(t1, isWCIAdmin, true, proposedBudgetRecords), [proposedBudgetRecords, isWCIAdmin, t1]);

  useEffect(() => {
    reset();
    setSelectedRows([]);
  }, [ProposePage, reset]);

  // scroll to proposed to authority card view
  const queryParams = new URLSearchParams(location.search);
  const scrollToId = queryParams.get('scrollTo');
  if (scrollToId) {
    const element = document.getElementById(scrollToId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <Box id="pbr_view">
      <FormCard
        title={t(t1, 'PROPOSED_BUDGET_RECORDS')}
        indicateRequired={false}
        customeStyle={{ paddingBottom: '40px' }}
      >
        <Box sx={{ ...Style.userRegistrationPage, paddingTop: '16px' }}>
          <MUITableGrid
            fileName={t(t1, 'PROPOSED_BUDGET_RECORDS')}
            rows={proposedBudgetRecords || []}
            columns={column}
            cellClick={false}
            count={5}
            isExport
            isSearch
            isSettings
            enablePrintView
            pinnedColumns={{ left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'budgetYear'], right: ['QuickActions'] }}
            fieldsMetaData={flp?.metaData}
            {...obj}
          />
        </Box>
        {ProposePage && (
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container>
              <Grid
                item
                md={4}
              >
                <StyledEntryDetailContainer style={{ paddingTop: '22px' }}>
                  <StyledDetailKey>{t(t1, 'APPROVE_PROPOSED_BUDGETS')}</StyledDetailKey>
                  <Controller
                    control={control}
                    name="entityAction"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <GridRadioButton
                        gridMd={12}
                        labelSx={{
                          padding: '5px',
                          fontSize: '14px',
                          lineHeight: '1.5',
                          fontWeight: '400 !important',
                        }}
                        sx={Style.selectRadioComponent}
                        name="entityAction"
                        radios={radioOptions}
                        onRadioChange={field.onChange}
                        selectedRadio={field.value}
                        LabelShowAsName
                        customTrigger={() => {
                          if (watch('entityAction') && methods.getValues('comment') <= COMMENT_MAX_CHAR_LENGTH) {
                            methods.clearErrors('comment');
                          }
                        }}
                      />
                    )}
                  />
                </StyledEntryDetailContainer>
              </Grid>
              <Grid
                item
                md={8}
              >
                <GridTextfield
                  name="comment"
                  label={t(t1, 'COMMENTS')}
                  placeholder={t(t1, 'COMMENTS_PLACEHOLDER')}
                  gridMd={12}
                  multiline
                  trimLeadingSpaces
                  rows={2}
                  rules={{
                    required: [statusTypes.REVISIONS_REQUESTED, statusTypes.DENIED].includes(values.entityAction),
                    maxLength: {
                      value: COMMENT_MAX_CHAR_LENGTH,
                      message: `${t(t1, 'ERR_COMMENTS_MAX_LENGTH_CHAR')}`,
                    },
                  }}
                  isRequiredMsg={'ERR_COMMENTS_IS_REQUIRED'}
                />
              </Grid>
            </Grid>
            <Box sx={{ ml: 'auto' }}>
              <Grid
                item
                sm={12}
                sx={Style}
              >
                <div
                  className="bottom-btn"
                  style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}
                >
                  <CustomizeButton
                    disableElevation
                    variant="outlined"
                    csName="rc"
                    name="reset_btn"
                    privId="priv_btn_reset"
                    label={t(t1, 'BTN_RESET')}
                    handleClick={() => setWarningModal()}
                  />
                  <CustomizeButton
                    disableElevation
                    variant="contained"
                    type="submit"
                    customStyle={{ marginLeft: 1 }}
                    disabled={
                      !values.entityAction ||
                      !selectedRows.length ||
                      (!values.comment &&
                        ![statusTypes.APPROVED, statusTypes.PROPOSED_TO_AUTHORITY].includes(values.entityAction)) ||
                      (!isJurisdictionUserAdmin && !isJurisdictionUserAuthority) ||
                      !isEmpty(errors)
                    }
                    label={t(t1, 'BTN_SUBMIT')}
                    privId="priv_btn_Submit"
                  />
                </div>
              </Grid>
            </Box>
          </FormProvider>
        )}
        {dialogMessage ? (
          <DialogMessage
            warning
            handleModalClick={handleModalSubmit}
            buttonMessage={false}
            title={t(t1, 'WARNING')}
            message2={t(t1, 'DIALOG_RESET_BUDGET_MESSAGE')}
          />
        ) : null}
        <PassphraseConfirmationDialog
          title={t(t1, 'PROPOSE_BUDGET')}
          cancelButtonLabel={t(t1, 'CANCEL')}
          confirmButtonLabel={t(t1, 'CONFIRM')}
          pageName=""
          onPassphraseSuccess={() => {
            setShowPassPhraseDialogue(false);
            const { comment, entityAction } = methods.getValues();
            const statusId = status.find(({ Name }) => Name === entityAction)?.ID;
            const jurisdictioBudgetRecord = selectedRows.map((id) => ({ id, comment, statusId }));
            onPropose(jurisdictioBudgetRecord, entityAction);
          }}
          handleCloseDialog={() => {
            setShowPassPhraseDialogue(false);
          }}
          onPassphraseFailure={() => setShowPassPhraseDialogue(true)}
          open={showPassPhraseDialogue}
        />
      </FormCard>
    </Box>
  );
}

export default ProposedBudgetRecordsCard;
