import { Typography, FormControlLabel, Checkbox, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 0, 5, 0),
}));

export const StyledBoxAccountRep = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 0, 3, 0),
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  fontSize: theme.body.fontSize,
  color: theme.palette.textColor,
  margin: theme.spacing(1, 0, 0, 0),
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  fontSize: theme.body.fontSize,
  margin: theme.spacing(0, 0, 0, 2),
  padding: theme.spacing(0.5),
}));

export const StyledAccountLabelTypograpy = styled(Typography)(({ theme }) => ({
  fontSize: theme.body.fontSize,
  color: theme.palette.textColor,
  fontWeight: '600',
  marginBottom: theme.spacing(2.5),
}));

export const StyledBoxEntityDetail = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  marginBottom: theme.spacing(3),
  marginLeft: theme.spacing(3),
}));

export const StyledTypograpyEntityDetail = styled(Typography)(({ theme }) => ({
  fontSize: theme.body.fontSize,
  color: theme.palette.textColor,
  minWidth: theme.spacing(40),
}));

export const StyledTypograpyTransferDetail = styled(Typography)(({ theme }) => ({
  fontSize: theme.body.fontSize,
  color: theme.palette.textColor,
  minWidth: theme.spacing(10),
  marginLeft: theme.spacing(2),
}));

export const StyledTransferExpirySettings = styled(Typography)(({ theme }) => ({
  fontSize: theme.body.fontSize,
  color: theme.palette.textColor,
  fontWeight: '600',
  marginBottom: 0,
}));