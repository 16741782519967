const Style = {
  FieldLabel: {
    fontSize: '0.875rem!important',
    color: 'common.black',
    fontWeight: 700,
    mb: 0.5,
    '&.MuiInputLabel-root': {
      /* Conflict Resolution Candidate
            fontWeight: 700,
            "&:empty": {
              height: "20px"
            }
          },
       */
      fontWeight: 700,
      maxWidth: '100%',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    },
  },
  formField: {
    width: 1,
    color: 'common.black',
    '&.fc-search': {
      '& .MuiOutlinedInput-input': {
        background: '#fff',
        color: 'common.black',
        minHeight: '25px',
        py: 0,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '6px',
      },
    },
    '& .fc-select-rounded': {
      borderRadius: '4px',
    },
    '.MuiOutlinedInput-input': {
      p: 0.2,
      fontSize: '0.875rem',
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: '0',
      borderColor: 'common.GrayColor',
    },
    '& .MuiOutlinedInput-root': {
      p: '2px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '4px',
      },
      '&.MuiInputBase-root.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'common.black',
          borderWidth: 1,
          borderRadius: '4px',

        },
      },
    },
  },
  valuetext: {
    paddingLeft: '4px',
  },
  updatedFieldBox: { display: 'flex', justifyContent: 'start', alignItems: 'center' },
  coloredBox: {
    padding: '2px 4px',
    /* Conflict Resolution Candidate
        width: "85%",
     */
    width: '95%',
    border: '1.5px solid',
    borderColor: 'common.warning',
    color: 'common.warning',
    '& .MuiTypography-root': { fontWeight: 600, fontSize: '14px' },
  },
};

export default Style;
