import { Container } from '@mui/material';
import { Loadable } from 'components/Loadable';
import RoleBasedGuard from 'guards/RoleBasedGuard';
import { lazy } from 'react';

const FSAAllReportsHome = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.FSAAllReportHome,
    })),
  ),
);

const FSAReport = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.FSAReport,
    })),
  ),
);


const ConsignmentWiringInstructionsReportAsyncPage = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.ConsignmentWiringInstructionsAsyncReportView,
    })),
  ),
);


export const FSAAllReportsRoutes = {
  path: '/fsa-all-reports',
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={'priv_lnk_fsa_home_reports'}>
          <FSAAllReportsHome />
        </RoleBasedGuard>
      ),
    },
  ],
};

export const FSAReportRoute = {
  path: '/fsa-report',
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={'priv_lnk_fsa_home_reports'}>
          <Container
            maxWidth="xl"
            sx={{
              minHeight: 'calc(100vh - 250px)',
            }}
          >
            <FSAReport />
          </Container>
        </RoleBasedGuard>

      ),
    },
  ],
};


export const ConsignmentWiringInstructionsReportAsync = {
  path: '/consignment-wiring-instructions-report',
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={'priv_lnk_fsa_home_reports'}>
          <ConsignmentWiringInstructionsReportAsyncPage />
        </RoleBasedGuard>
      ),
    },
  ],
};