import { Loadable } from 'components/Loadable';
import RoleBasedGuard from 'guards/RoleBasedGuard';
import { lazy } from 'react';

const MFSViewPage = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.MFSViewPage,
    })),
  ),
);
const EditFormMFS = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.EditFormMFS,
    })),
  ),
);

export const financialServicesDeliveryInstructions = {
  path: '/financial-services-delivery-instructions',
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={'priv_lnk_FSI_View'}>
          <MFSViewPage />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'edit',
      element: (
        <RoleBasedGuard privId={'priv_lnk_FSI_Edit'}>
          <EditFormMFS />
        </RoleBasedGuard>
      ),
    },
  ],
};

