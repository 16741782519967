// react router dom
// react hook form
// MUI Components
import { Box, Button, List, Popover, Typography, IconButton } from '@mui/material';
// MUI Icon
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { Link } from 'react-router-dom';
import { CustomizeButton } from 'components/CustomizeButton';

/**
 *
 * @param {*} props
 * Note: linksList prop format should be => { to: '/', name: "" }
 * @returns QuickLinks Icon with popup menu
 */
export default function QuickLinksMenu(props) {
  const {
    iconFontSize = '30px',
    iconClassName = '',
    popupClassName = '',
    onMenuDotsClick,
    openMenu = false,
    linksList = [],
    subMenuRef = null,
    anchorEl,
    handleClose,
    disabled,
    row,
    privId = '',
    label,
    isDotIcon = false,
    customStyle = {
      backgroundColor: '#ffffff',
      '& .MuiList-root': {
        '& .MuiTypography-root': {
          minHeight: '48px',
        },
      },
    },
    anchorOrigin = {
      vertical: 'top',
      horizontal: 'left',
    },
    transformOrigin = {
      vertical: 'top',
      horizontal: 'right',
    },
  } = props;

  const handleKeyDown = (event, link, popupState) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      if (link?.Access !== 'RO') {
        link?.onLinkClick(row);
        popupState.close();
      }
    }
  };

  return (
    <Box ref={subMenuRef}>
      <PopupState
        tabIndex={0}
        variant="popover"
        popupId="demo-popup-popover"
      >
        {(popupState) => (
          <>
            {isDotIcon ? (
              <IconButton
                onClick={onMenuDotsClick}
                {...bindTrigger(popupState)}
                disabled={disabled}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' || event.key === ' ') {
                    event.preventDefault();
                    event.stopPropagation();
                    event.target.click();
                  }
                }}
              >
                <MoreHorizIcon
                  sx={{ fontSize: iconFontSize }}
                  className={iconClassName}
                />
              </IconButton>
            ) : (
              <CustomizeButton
                customStyle={{
                  marginRight: '0px !important',
                  width: '150px !important',
                  height: '40px !important',
                  padding: '6px 6px',
                }}
                onClick={onMenuDotsClick}
                variant="contained"
                label={label}
                privId={privId}
                name="more-actions"
                bindTrigger={bindTrigger(popupState)}
              />
            )}
            <Popover
              // placement = "left"
              open={openMenu}
              anchorEl={anchorEl}
              onClose={handleClose}
              {...bindPopover(popupState)}
              anchorOrigin={anchorOrigin}
              transformOrigin={transformOrigin}
            >
              {/* <Box position="relative"> */}
              {/* {true && ( */}
              <Box
                component="div"
                sx={{ p: 1, ...customStyle }}
                className={popupClassName}
              >
                <List>
                  {linksList.length > 0 &&
                    linksList?.map(
                      (link, index) =>
                        (link?.Access !== 'NA' && !link?.isHiden) && (
                          <Typography
                            style={{ width: '100%', textAlign: 'center' }}
                            onKeyDown={(event) => handleKeyDown(event, link, popupState)}
                            tabIndex={link?.Access !== 'RO' ? 0 : -1}
                            key={`${link?.name}_${index}`}
                            onClick={
                              link?.Access !== 'RO'
                                ? () => {
                                  link?.onLinkClick(row);
                                  popupState.close();
                                }
                                : null
                            }
                            sx={{
                              ...(link?.Access !== 'RO'
                                ? { cursor: 'pointer' }
                                : {
                                  // cursor: "auto",
                                  pointerEvents: 'none',
                                  color: '#AAAAAA !important',
                                  '&:hover': { backgroundColor: 'transparent !important' },
                                }),
                              ...link?.sx,
                            }}
                            role={'button'}
                          >
                            {link?.name}
                          </Typography>
                        ),
                    )}
                </List>
              </Box>
              {/* )} */}
              {/* </Box>   */}
            </Popover>
          </>
        )}
      </PopupState>
    </Box>
  );
}
// aria-disabled={link?.Access === "RO"}
// to={link?.to}
