import { FormActions } from 'components/FormActions';
import { FormCard } from 'components/FormCard';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setLoader } from 'store/commonSlice';
/* Conflict Resolution Candidate
import { setInitialPrimaryChecked, setPrimaryChecked } from "store/createUserProfileSlice";
import { t, getCurrentPageName, scrollToTop, stepWiseFieldUserRegistration, compareTwoArrays } from "utils/constants";
 */
import { setInitialPrimaryChecked, setPrimaryChecked } from 'store/createUserProfileSlice';
import {
  t,
  getCurrentPageName,
  scrollToTop,
  COUNTRY_KEY_NAMES,
  MAILING_ADD_CARD_ID,
  RESIDENCE_ADD_CARD_ID,
} from 'utils/constants';
import { ResidenceForm } from './ResidenceForm';

export default function ResidenceAndMailing() {
  const { t: t1 } = useTranslation(getCurrentPageName());
  /* Conflict Resolution Candidate
    const {
      filledData,
      submitted,
      countries,
      states,
      formSteps: { activeStep },
      primaryChecked,
      initialPrimaryChecked,
    } = useSelector(({ createUserProfile }) => createUserProfile);
    const dispatch = useDispatch();
    const [mailDisabled, setMailDisabled] = useState(primaryChecked);

    useEffectOnce(() => {
      scrollToTop();
    });

    const defaultValues = submitted?.[3]
      ? filledData?.[3]
      : {
          primaryResidence: { country: "", stateOrProvince: "" },
          mailingAddress: { country: "", stateOrProvince: "" },
   */
  const {
    filledData,
    submitted,
    countries,
    states,
    formSteps: { activeStep },
    primaryChecked,
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const dispatch = useDispatch();
  const [mailDisabled, setMailDisabled] = useState(primaryChecked);

  useEffectOnce(() => {
    scrollToTop();
  });

  const defaultValues = submitted?.[3]
    ? filledData?.[3]
    : {
      primaryResidence: { country: '', stateOrProvince: '' },
      mailingAddress: { country: '', stateOrProvince: '' },
    };

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  /* Conflict Resolution Candidate

    useEffect(() => {
      if (submitted?.[3]) {
        const residenceValueArray = stepWiseFieldUserRegistration[3].map(
          (field) => filledData?.[3]?.primaryResidence?.[field]
        );

        const mailingValueArray = stepWiseFieldUserRegistration[3].map(
          (field) => filledData?.[3]?.mailingAddress?.[field]
        );

        const isSame = compareTwoArrays(residenceValueArray, mailingValueArray);
        dispatch(setPrimaryChecked(isSame));
        dispatch(setInitialPrimaryChecked(isSame));
      } else {
        dispatch(setPrimaryChecked(false));
        dispatch(setInitialPrimaryChecked(false));
      }
      setDefaultValues(3)
      setDefaultValues(4)
      dispatch(setLoader(false));
    }, []);

    // for apply the value of checkbox initially
    useEffect(() => {
      dispatch(setPrimaryChecked(initialPrimaryChecked));
    },[initialPrimaryChecked])

    const { formState } = methods;
    const { errors } = formState;

    const handleSameAddress = (checked) => {
      if (checked) {
        // dispatch(setPrimaryChecked(true));
        setMailDisabled(true);
   */
  useEffect(() => {
    dispatch(setLoader(false));
  }, []);

  const { formState } = methods;
  const { errors } = formState;

  const handleSameAddress = (checked) => {
    if (checked) {
      dispatch(setInitialPrimaryChecked(true));
      // dispatch(setPrimaryChecked(true));
      setMailDisabled(true);
      const primaryResidence = methods.getValues('primaryResidence');
      methods.setValue('mailingAddress', primaryResidence, {
        shouldDirty: true,
        shouldValidate: true,
      });
      methods.setValue('primaryResidence', primaryResidence, {
        shouldDirty: true,
        shouldValidate: true,
      });
      if (
        methods.getValues(`primaryResidence.country`) === COUNTRY_KEY_NAMES.CANADA ||
        methods.getValues(`primaryResidence.country`) === COUNTRY_KEY_NAMES.UNITED_STATES
      ) {
        const primaryResidence = methods.getValues('primaryResidence');
        methods.setValue('mailingAddress', primaryResidence, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    } else {
      /* Conflict Resolution Candidate
            const data = submitted?.[3] ? filledData?.[3] : defaultValues
            const updatedData = {}
            stepWiseFieldUserRegistration?.[3].forEach((field) => {
              updatedData[field] = data?.mailingAddress?.[field]
            });
            methods.setValue(
              "mailingAddress",
              updatedData,
              { shouldDirty: true, shouldValidate: true }
            );
            // if(activeStep !==4)
            // dispatch(setPrimaryChecked(false));
            setMailDisabled(false);
          }
        };

        const setDefaultValues = (step) => {
          const allValues = methods?.getValues()
          if (step === 4) {
            dispatch(setPrimaryChecked(initialPrimaryChecked));
            const finalClearedValue = { ...allValues, mailingAddress:filledData?.[3]?.mailingAddress }
            // console.log(finalClearedValue,"finalClearedValue")
            methods?.reset(finalClearedValue);
          }
          else{
            const finalClearedValue = { ...allValues, primaryResidence:filledData?.[3]?.primaryResidence }
            methods?.reset(finalClearedValue);
          }
       */
      methods.setValue('mailingAddress', {
        country: '',
        stateOrProvince: '',
        street1: '',
        street2: '',
        city: '',
        region: '',
        postalCode: '',
      }, { shouldValidate: true });
      setTimeout(methods.trigger, 0);
      // dispatch(setPrimaryChecked(false));
      dispatch(setInitialPrimaryChecked(false));
      setMailDisabled(false);
    }
  };

  const setDefaultValues = (formCardId) => {
    if (formCardId === MAILING_ADD_CARD_ID) {
      methods?.setValue('mailingAddress', defaultValues?.mailingAddress);
    } else if (formCardId === RESIDENCE_ADD_CARD_ID) {
      methods?.setValue('primaryResidence', defaultValues?.primaryResidence);
    } else {
      methods?.reset(defaultValues);
    }
  };

  let primarySaveDisabled = false;
  let mailingSaveDisabled = false;
  if (
    errors?.primaryResidence?.street1 ||
    errors?.primaryResidence?.street2 ||
    errors?.primaryResidence?.city ||
    errors?.primaryResidence?.stateOrProvince ||
    errors?.primaryResidence?.postalCode ||
    errors?.primaryResidence?.region ||
    errors?.primaryResidence?.country
  ) {
    primarySaveDisabled = true;
  } else primarySaveDisabled = false;

  if (
    errors?.mailingAddress?.street1 ||
    errors?.mailingAddress?.street2 ||
    errors?.mailingAddress?.city ||
    errors?.mailingAddress?.stateOrProvince ||
    errors?.mailingAddress?.postalCode ||
    errors?.mailingAddress?.region ||
    errors?.mailingAddress?.country
  ) {
    mailingSaveDisabled = true;
  } else mailingSaveDisabled = false;

  return (
    <FormProvider {...methods}>
      <FormCard
        formCardId={RESIDENCE_ADD_CARD_ID}
        title={t(t1, 'PRIMARY_RESIDENCE')}
        TermsAndConditions="true"
        stepNumber={3}
        methods={methods}
        customClass="primary_residence customFormCard"
        setDefaultValues={setDefaultValues}
        /* Conflict Resolution Candidate
                customeStyle={{ marginBottom: "20px !important" }}
                saveDisabled={primarySaveDisabled}
                methods={methods}
                defaultValues={defaultValues}
         */
        customeStyle={{ marginBottom: '20px !important' }}
        saveDisabled={primarySaveDisabled || !methods.formState.isDirty}
      >
        <ResidenceForm
          address="primaryResidence"
          privId="Residence"
          stepNumber={3}
          methods={methods}
          countries={countries}
          states={states}
        />
      </FormCard>
      <FormCard
        formCardId={MAILING_ADD_CARD_ID}
        title={t(t1, 'MAILING_ADDRESS')}
        formLabel={t(t1, 'CHECK_IF_SAME_AS_PRIMARY_RESIDENCE')}
        customClass="mailing_address customFormCard"
        handleSameAddress={handleSameAddress}
        setDefaultValues={setDefaultValues}
        stepNumber={4}
        methods={methods}
        setMailDisabled={setMailDisabled}
        saveDisabled={mailingSaveDisabled || !methods.formState.isDirty}
        /* Conflict Resolution Candidate
                methods={methods}
                defaultValues={defaultValues}
         */
      >
        <ResidenceForm
          disabled={mailDisabled}
          address="mailingAddress"
          privId="Mailing"
          stepNumber={4}
          methods={methods}
          countries={countries}
          states={states}
        />
      </FormCard>
      <FormActions stepNumber={3} />
    </FormProvider>
  );
}
