import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  t,
} from 'utils/constants';
import Style from './ApplicationChecklist.style';

const PageName = 'Page_ApplicationWithAttenstation';

function EntityContactInformation({ entityData }) {
  const { additionalContactInformation } = entityData || {};
  const { t: t1 } = useTranslation(PageName);
  return <>
    <Typography sx={Style.entityIdentification}>{t(t1, 'ENTITY_CONTACT_INFORMATION')}</Typography>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, 'TELEPHONE')}</Typography>
      <Typography sx={Style.entryValue}>{additionalContactInformation?.telephoneNumber}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, 'TELEPHONE_EXTENSION')}</Typography>
      <Typography sx={Style.entryValue}>{additionalContactInformation?.ext}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, 'MOBILE_PHONE')}</Typography>
      <Typography sx={Style.entryValue}>{additionalContactInformation?.mobilePhone}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, 'FACSIMILE_NUMBER')}</Typography>
      <Typography sx={Style.entryValue}>{additionalContactInformation?.facsimileNumber}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, 'E_MAIL_ADDRESS')}</Typography>
      <Typography sx={Style.entryValue}>{additionalContactInformation?.email}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, 'WEBSITE_ADDRESS')}</Typography>
      <Typography sx={Style.entryValue}>{additionalContactInformation?.websiteAddress}</Typography>
    </Box>
  </>;
}

export default EntityContactInformation;
