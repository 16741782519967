const Style1 = {
  limitedLines: {
    height: '42px',
    overflow: 'hidden',
  },

  limitedLinesExpanded: {
    height: 'auto',
    maxHeight: '115.5px',
    overflowY: 'scroll',
  },

  ExpandButton: {
    padding: '0 5px',
    color: 'primary.light',
    textTransform: 'unset',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
      fontWeight: 600,
    },
  },
};

export default Style1;
