import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useAuthentication({ redirectTo = '/' }) {
  const { isSignedIn } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSignedIn) {
      navigate(redirectTo, { replace: true });
    } else {
      navigate('/', { replace: true });
    }
  }, [isSignedIn, navigate, redirectTo]);
}
