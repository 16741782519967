import { createSlice } from '@reduxjs/toolkit';
import { CRON_JOB_COMPONENTS, CRON_JOB_PAGE } from 'pages/ManageCronJobs/support';
import { APIS } from 'utils/constants';
import { fetcherGet, fetcherPatch } from 'utils/fetcher';
import logger from 'utils/logger';
import { setLoader } from './commonSlice';

const initialState = {
  cronJobs: { isLoading: false, data: [] },
  openRescheduleDialog: false,
  openLimitCheckConfigureDialog: false,
  cronJurisdictionList: [],
  selectedCronJob: {},
};

const CronJobsSlice = createSlice({
  name: 'cronJobs',
  initialState,
  reducers: {
    setCronJobs: (state, action) => {
      state.cronJobs = action.payload;
    },
    setOpenRescheduleDialog: (state, action) => {
      state.openRescheduleDialog = action.payload;
    },
    setOpenLimitCheckConfigureDialog: (state, action) => {
      state.openLimitCheckConfigureDialog = action.payload;
    },
    setCronJurisdictionList: (state, action) => {
      state.cronJurisdictionList = action.payload;
    },
    setSelectedCronJob: (state, action) => {
      state.selectedCronJob = action.payload;
    },
  },
});

export const {
  setCronJobs,
  setOpenRescheduleDialog,
  setOpenLimitCheckConfigureDialog,
  setCronJurisdictionList,
  setSelectedCronJob,
} = CronJobsSlice.actions;
export default CronJobsSlice.reducer;

export function getCronJobs() {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setCronJobs({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(APIS.CRON_JOB, {}, { componentName: CRON_JOB_COMPONENTS.GET_CRON_JOBS });
      dispatch(setCronJobs({ isLoading: false, data: response.data }));
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setCronJobs({ isLoading: false, data: null }));
      dispatch(setLoader(false));
      logger({ error });
    }
  };
}

export function getCronJurisdictionList(cronJobDefinitionId) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setCronJurisdictionList([]));
    try {
      const response = await fetcherGet(
        `${APIS.BATCH_JOB}/cronJurisdictions/${cronJobDefinitionId}`,
        {},
        {
          componentName: CRON_JOB_COMPONENTS.GET_CRON_JURISDICTION_DETAILS,
        },
      );
      dispatch(setCronJurisdictionList(response?.data));
      dispatch(setLoader(false));
    } finally {
      dispatch(setLoader(false));
    }
  };
}

export function updateCron({ cronJobDefinitionId, payload }) {
  return (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPatch(`${APIS.BATCH_JOB}/cronJurisdictions/${cronJobDefinitionId}`, [...payload], {
      componentName: CRON_JOB_COMPONENTS.UPDATE_CRON_JURISDICTION_DETAILS,
    }).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function rescheduleCron(payload) {
  return (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPatch(
      `${APIS.BATCH_JOB}/updateCronJobSchedule`,
      {
        ...payload,
      },
      { componentName: CRON_JOB_COMPONENTS.UPDATE_CRON_JOB_SCHEDULE },
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}
