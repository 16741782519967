import BannerImage from '../../assets/images/banner-image.png';

const Style = {
  AnnouncementHeader: {
    padding: '2px',
    borderTop: '1px solid #283459',
    borderBottom: '1px solid #283459',
    backgroundColor: '#f2f2f2',
    color: 'common.textLightGray',
    textAlign: 'center',
  },
  MainHeader: {
    height: '62px',
    padding: '2px 140px',
    backgroundColor: '#ffffff',
    boxSizing: 'borderBox',
    position: 'relative',
  },
  HeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  CompanyLogo: {
    width: '115px',
    height: '75px',
    position: 'absolute',
    top: '-20px',
  },
  SelectorSection: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    marginTop: '18px',
    color: '#333333',
    '& div': {
      marginLeft: '20px',
      display: 'flex',
      alignItems: 'center',
      '& label': {
        marginRight: 5,
        color: 'grey',
      },
      '& img': {
        marginTop: '5px',
      },
    },
  },
  Navbar: {
    padding: '2px 140px',
    color: '#fff',
    height: '40px',
    backgroundColor: '#283459',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  NavbarItems: {
    display: 'flex',
    justifyContent: 'space-between',
    '& div': {
      '&:hover': {
        cursor: 'pointer',
        fontWeight: '500',
      },
    },
  },
  Banner: {
    // backgroundImage: `url(../../../../assets/images/banner-image.png)`,
    // backgroundImage: `url(${BannerImage})`,
    backgroundSize: 'cover',
    height: '360px',
    padding: '2px 0px',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 899px)': {
      height: '420px',
    },
  },
  Platform: {
    color: '#fff',
    fontWeight: '200',
    fontSize: '48px',
    '& p': {
      margin: 0,
    },
    '@media (max-width: 899px)': {
      textAlign: 'center',
      fontSize: '36px',
    },
  },
  Form: {
    width: '340px',
    height: 'auto',
    padding: '20px',
    borderRadius: '8px',
    border: '1px solid #d7d7d7',
    backgroundColor: '#f2f2f2',
    boxSizing: 'border-box',
    marginLeft: 'auto',
    marginTop: '0px',
    '& p': {
      fontFamily: '"OpenSans-SemiBold", "Open Sans SemiBold", "Open Sans", sans-serif',
      fontWeight: 650,
      color: '#283459',
      textAlign: 'left',
      lineHeight: 'normal`',
    },
    TextField: {
      padding: '2px',
      backgroundColor: '#ffffff',
      boxSizing: 'border-box',
      fontWeight: 650,
      color: '#999999',
      marginTop: '10px',
    },
    '@media (max-width: 899px)': {
      margin: '0 auto',
      width: '340px',
      display: 'block',
      marginTop: '15px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      backgroundColor: '#fff',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
  },
};

export default Style;
