import {
  BATCH_TRANSFER_STATUS,
  BATCH_TRANSFER_TYPES,
  CUSTOM_INPUT_ALLOWED_PATTERN,
  JURISDICTION_TRANSFER_TYPES,
  allowedAccountNameRegex,
  formatDateUpdated,
  t,
} from 'utils/constants';
import { getLanguageCode } from 'utils/support';
import { addMethod, array, object, string } from 'yup';

export const COMPONENTS = {
  BATCH_TRANSFERS_LIST: 'batchTransfersList',
};

export const transformHoldingsForTransfer = (data) =>
  data?.map((item, idx) => ({
    vintage: item?.vintageYear,
    jurisdiction: item?.jurisdiction?.name,
    type: item?.issuanceType,
    subType: item?.subType?.name,
    offsetType: item?.offsetType?.name,
    offsetProjectId: item?.projectId,
    specialCategory: item?.specialCategory?.name,
    quantity: item?.quantity,
    id: item?.id || idx + 1,
    availableHoldingQuantity: item?.availableHoldingQuantity,
    batchTransferHoldingId: item?.batchTransferHoldingId,
  }));

export const transformCurrencyOptions = (data) =>
  data?.map((item) => ({ label: item?.displayName, value: item?.currencyJurisdictionId }));

export const transformBatchTransferAccountDetails = (data) =>
  data?.map((item, idx) => ({
    vintage: item?.vintageYear,
    jurisdiction: item?.jurisdiction,
    type: item?.issuanceType,
    subType: item?.subType,
    offsetType: item?.offsetType,
    offsetProjectId: item?.projectId,
    specialCategory: item?.specialCategory,
    quantity: item?.quantity,
    accountNumber: item?.accountNumber,
    entityReference: item?.entityRef,
    proposedQuantity: item?.proposedQty,
    price: item?.price,
    id: item?.id || idx + 1,
    batchHoldingTransferId: item?.batchHoldingTransferId,
    batchHoldingDetailsId: item?.batchHoldingDetailsId,
    holdingId: item?.holdingId,
    currency: item?.currencyJurisdictionId,
    currencyName: item?.currency,
    transferId: item?.transferId,
    availableHoldingQuantity: item?.availableHoldingQuantity,
    accountType: item?.accountType,
    accountStatus: item?.accountStatus,
    legalName: item?.legalName,
    operationalName: item?.operationalName,
    transferUuid: item?.transferUuid,
  }));

addMethod(string, 'alphaNumeric', function bigInt(message) {
  return this.matches(CUSTOM_INPUT_ALLOWED_PATTERN.alphaNumPattern, { message, name: 'bigInt' });
});

export const receivingAccountValidationSchema = (t1, selectedLanguage) =>
  object().shape({
    receivingAccountDetails: array().of(
      object().shape({
        accountNumber: string()
          .nullable()
          .alphaNumeric(`${t(t1, 'ERR_ACCOUNT_NUMBER_IS_NOT_VALID_NUMERIC')}`)
          .matches(allowedAccountNameRegex, ({ value }) =>
            `${value}`),
        entityReference: string()
          .nullable(),
        proposedQuantity: string()
          .bigInt(`${t(t1, 'ERR_PROPOSED_QUANTITY_IS_NOT_VALID_NUMERIC')}`)
          .required(`${t(t1, 'ERR_PROPOSED_QUANTITY_IS_REQUIRED')}`)
          .test({
            name: 'greaterThanZero',
            message: `${t(
              t1,
              'VALIDATION_PROPOSED_QUANTITY_GREATER_THAN_ZERO',
            )}`,
            test: (value) => parseInt(value, 10) > 0,
          }),
        price: string()
          .nullable()
          .matches(selectedLanguage === 'French' ?
            CUSTOM_INPUT_ALLOWED_PATTERN.floatFrench
            : CUSTOM_INPUT_ALLOWED_PATTERN.float, `${t(t1, 'ERR_PRICE_INVALID_ERROR')}`)

          .test({
            name: 'requiredPrice',
            message: `${t(
              t1,
              'VALIDATION_PRICE_REQUIRED',
            )}`,
            test: (value, context) => {
              const { currency } = context.parent;
              return !(currency && value === '');
            },
          })
          .test({
            name: 'greaterThanZero',
            message: `${t(
              t1,
              'VALIDATION_PRICE_GREATER_THAN_ZERO',
            )}`,
            test: (value, context) => {
              const { currency } = context.parent;
              if (currency) {
                return parseFloat(value) > 0;
              }
              return true;
            },
          }),
        currency: string()
          .nullable()
          .when('price', {
            is: (price) => Boolean(price),
            then: string()
              .required(`${t(
                t1,
                'VALIDATION_CURRENCY_IS_REQUIRED',
              )}`)
              .nullable(),
          }),
      }),
    ),
  });

export const receivingAccountPCRAValidationSchema = (t1) =>
  object().shape({
    receivingAccountDetails: array().of(
      object().shape({
        proposedQuantity: string()
          .required(`${t(t1, 'ERR_PROPOSED_QUANTITY_IS_REQUIRED')}`)
          .test({
            name: 'greaterThanZero',
            message: `${t(
              t1,
              'VALIDATION_PROPOSED_QUANTITY_GREATER_THAN_ZERO',
            )}`,
            test: (value) => parseInt(value, 10) > 0,
          }),
      }),
    ),
  });

export function getErrorMessage(error, t1, accountDetailPCRA, rowCount) {
  const errorList = [];
  if (typeof error === 'string') {
    errorList.push({
      messageKey: t(t1, error),
    });
    return errorList;
  }
  if (error?.listOfTranferingAccountStatusCheck?.length) {
    errorList.push({
      messageKey: `${t(t1, 'TRANSFERRING_ACCOUNT_ERROR')}: ${JSON.stringify(
        error.listOfTranferingAccountStatusCheck,
      )} ${t(t1, 'TRANSFERRING_ACCOUNT_DESCRIPTION')}`,
    });
  }

  if (error?.InvalidRowCount?.length && error.InvalidRowCount[0] > 0) {
    errorList.push({
      messageKey: `${t(t1, 'VALIDATION_ERROR_ROWCOUNT_MEESAGE')}: ${error.InvalidRowCount[0]}, ${t(
        t1,
        'VALIDATION_ERROR_OUTOF',
      )} ${rowCount} ${t(t1, 'VALIDATION_ERROR_ROWCHECKED')}`,
    });
  }

  if (error?.listOfRequiredMissing?.length || error?.listOfPCRARequiredMissing?.length) {
    errorList.push({
      messageKey: `${t(t1, 'MANDATORY_FIELDS_ERROR')}`,
    });
  }

  if (error?.listOfTypeError?.length) {
    errorList.push({
      messageKey: `${t(t1, 'INCORRECT_FORMAT_DATA')}: ${JSON.stringify(error?.listOfTypeError)}`,
    });
  }

  if (error?.typeErrorAcccountNumbers?.length) {
    errorList.push({
      messageKey: `${t(t1, 'INCORRECT_FORMAT_DATA')}: ${JSON.stringify(error?.typeErrorAcccountNumbers)}`,
    });
  }

  if (error?.listOfReceivingAccountStatusCheck?.length) {
    errorList.push({
      messageKey: `${t(t1, 'RECEIVING_ACCOUNT_ERROR')}: ${JSON.stringify(error.listOfReceivingAccountStatusCheck)} ${t(
        t1,
        'RECEIVING_ACCOUNT_DESCRIPTION',
      )}`,
    });
  }

  if (error?.listOfComplianceInstrumentsCheck?.length) {
    errorList.push({
      messageKey: `${t(t1, 'COMPLIANCE_INSTRUMENTS_ERROR')}: ${JSON.stringify(error.listOfComplianceInstrumentsCheck)}`,
    });
  }

  if (error?.listOfTransferEligibilityForVREAccountCheck?.length) {
    errorList.push({
      messageKey: `${t(t1, 'VRE_ACCOUNT_TRANSFER_ERROR')}: ${JSON.stringify(error.listOfTransferEligibilityForVREAccountCheck)}`,
    });
  }

  let accountTypeCheckFlag = true;
  if (error?.listOfAccountAvailabilityCheck?.length) {
    errorList.push({
      messageKey: `${JSON.stringify(error?.listOfAccountAvailabilityCheck)} ${t(t1, 'ACCOUNT_NOT_FOUND')}`,
    });
    errorList.push({
      messageKey: `${JSON.stringify(error?.listOfAccountAvailabilityCheck)} ${t(
        t1,
        'NOT_VALID_ACCOUNT',
      )}`,
    });
    if (error?.listOfTranferingAndReceivingAccountTypeCheck?.length > 0) {
      accountTypeCheckFlag = false;
    }
  }

  if (error?.listOfTranferingAndReceivingAccountTypeCheck?.length && accountTypeCheckFlag) {
    errorList.push({
      messageKey: `${JSON.stringify(error?.listOfTranferingAndReceivingAccountTypeCheck)} ${t(
        t1,
        'NOT_VALID_ACCOUNT',
      )}`,
    });
  }

  if (error?.listOfEntityRefCodesCheck?.length) {
    errorList.push({
      messageKey: `${t(t1, 'REF_CODE_ERROR')}: ${JSON.stringify(error?.listOfEntityRefCodesCheck)}`,
    });
  }
  if (error?.listOfSufficientAvailableQuantityCheck?.length) {
    errorList.push({
      messageKey: `${t(t1, 'TRANSFERRED_QUANTITY_ERROR')}`,
    });
  }

  if (error?.listOfSufficientAvailableQuantityCheckForBatch?.length) {
    errorList.push({
      messageKey: `${t(t1, 'TRANSFERRED_QUANTITY_BATCH_ERROR_MESSAGE')}`,
    });
  }

  if (error?.listOfAcceptedCurrencyCheck?.length && error?.listOfAcceptedCurrencyCheck[0]?.length) {
    errorList.push({
      messageKey: `${t(t1, 'FOR_ACCOUNT_NUMBER')} ${JSON.stringify(error?.listOfAcceptedCurrencyCheck[0])}, ${t(
        t1,
        'CURRENCY_NOT_ACCEPTED',
      )} ${JSON.stringify(error?.listOfAcceptedCurrencyCheck[1])} ${t(t1, 'ENTER_ACCEPTED_CURRENCIES')}`,
    });
  }
  if (error?.listOfDuplicateRecordsCheck?.length) {
    const duplicateRecords =
      error?.listOfDuplicateRecordsCheck.length && error?.listOfDuplicateRecordsCheck.join(', ').toString();

    const uniqueDuplicateRecords = [];
    if (duplicateRecords) {
      const recordsArray = duplicateRecords.split(', ');
      recordsArray.forEach(item => {
        if (!uniqueDuplicateRecords.includes(item)) {
          uniqueDuplicateRecords.push(item);
        }
      });
    }
    errorList.push({
      messageKey: `${t(t1, 'DUPLICATE_RECORDS_ERROR')} [${uniqueDuplicateRecords}]`,
    });
  }
  if (error?.listOfDistinctTransferAndReceivingAccountCheck?.length) {
    errorList.push({
      messageKey: `${t(t1, 'SAME_TRANSFER_ACCOUNT_ERROR')}`,
    });
  }
  if (error?.listOfPriceLimitCheck?.length) {
    errorList.push({
      messageKey: `${t(t1, 'VALIDATION_PRICE_GREATER_THAN_ZERO')}: ${JSON.stringify(error?.listOfPriceLimitCheck)}`,
    });
  }

  if (error?.listOfProposedQuantityLimitCheck?.length) {
    errorList.push({
      messageKey: `${t(t1, 'VALIDATION_PROPOSED_QUANTITY_GREATER_THAN_ZERO')}: ${JSON.stringify(error?.listOfProposedQuantityLimitCheck)}`,
    });
  }

  if (error?.listOfPCRAProposedQuantityLimitCheck?.length) {
    errorList.push({
      messageKey: `${t(t1, 'VALIDATION_PROPOSED_QUANTITY_GREATER_THAN_ZERO')}: ${JSON.stringify(error?.listOfPCRAProposedQuantityLimitCheck)}`,
    });
  }

  if (error?.listOfCurrencyCheck?.length) {
    errorList.push({
      messageKey: `${JSON.stringify(error?.listOfCurrencyCheck)}: ${t(t1, 'CURRENCY_REQUIRED')}`,
    });
  }

  if (error?.listOfPriceCheck?.length) {
    errorList.push({
      messageKey: `${JSON.stringify(error?.listOfPriceCheck)}: ${t(t1, 'PRICE_REQUIRED')}`,
    });
  }

  if (error?.listOfPCRATypeError?.length) {
    errorList.push({
      messageKey: `${t(t1, 'INCORRECT_FORMAT_DATA_PCRA')}`,
    });
  }

  if (error?.listOfPCRADuplicateRecordsCheck?.length) {
    errorList.push({
      messageKey: `${t(t1, 'DUPLICATE_RECORDS_ERROR_PCRA')}`,
    });
  }

  if (error?.listOfPCRATranferingAccountStatusCheck?.length) {
    errorList.push({
      messageKey: `${t(t1, 'TRANSFERRING_ACCOUNT_ERROR')}: ${accountDetailPCRA?.account_number} ${t(t1, 'TRANSFERRING_ACCOUNT_DESCRIPTION')}`,
    });
  }

  if (error?.listOfPCRAReceivingAccountStatusCheck?.length) {
    errorList.push({
      messageKey: `${t(t1, 'RECEIVING_ACCOUNT_ERROR')}: ${accountDetailPCRA?.account_number} ${t(
        t1,
        'RECEIVING_ACCOUNT_DESCRIPTION',
      )}`,
    });
  }
  if (error?.listOfPCRASufficientAvailableQuantityCheck?.length) {
    errorList.push({
      messageKey: `${t(t1, 'TRANSFERRED_QUANTITY_ERROR_PCRA')}`,
    });
  }

  if (error?.listOfPCRASufficientAvailableAccountBalanceCheck?.length) {
    errorList.push({
      messageKey: `${t(t1, 'TRANSFERRED_QUANTITY_ACCOUNT_BALANCE_ERROR_PCRA')}`,
    });
  }

  if (error?.listOfPCRAComplianceInstrumentsCheck?.length) {
    errorList.push({
      messageKey: `${t(t1, 'ENTERED_HOLDING_IS_NOT_VALID')}`,
    });
  }

  if (error?.listOfValidComplianceInstrumentForReceivingAccountCheck?.length) {
    errorList.push({
      messageKey: `${t(t1, 'RECEIVING_ACCOUNT_ERROR')} ${JSON.stringify(error?.listOfValidComplianceInstrumentForReceivingAccountCheck)} ${t(t1, 'VALID_COMPLIANCE_ERROR')}`,
    });
  }

  if (error?.listOfStopAllFutureTransferInSavedCheck?.length) {
    errorList.push({
      messageKey: `${JSON.stringify(error?.listOfStopAllFutureTransferInSavedCheck)} ${t(t1, 'IS_NOT_FOUND')}`,
    });
  }

  return errorList;
}

export const transformHoldings = (data) =>
  data
    ?.map((item, idx) => ({
      vintage: item?.vintage,
      jurisdiction: item?.jurisdiction,
      type: item?.type,
      subType: item?.subType?.name,
      offsetType: item?.offsetType,
      offsetProjectId: item?.offsetProjectID,
      specialCategory: item?.specialCategory?.name,
      quantity: item?.quantity,
      id: item?.id || idx + 1,
      last_updated: item?.last_updated,
      availableHoldingQuantity: item?.availableHoldingQuantity,
    }))
    ?.sort((a, b) => new Date(b?.last_updated) - new Date(a?.last_updated));


// function formatAccTypeLanguage(array, languageCode) {
//   const result = array.find(item => item.language_code === languageCode);
//   return result ? result.account_name : null;
// }

// transform function, from array to mapper ex: {'L001': 'Name'}
function formatAccTypeLanguage(arr) {
  if (!Array.isArray(arr) || arr.length === 0) return null;
  const result = {};
  arr.forEach(item => {
    if (item && item.language_code && item.account_name) {
      result[item.language_code] = item.account_name;
    }
  });
  return result;
}

export const transformedJurisdictionAccountData = (jurisdictionAccounts) => ({
  accountNumber: jurisdictionAccounts?.account_number,
  accountBalance: jurisdictionAccounts?.account_balance,
  availableAccountBalance: jurisdictionAccounts?.available_account_balance,
  accountType: jurisdictionAccounts?.jurisdiction_account_type?.name,
  jurisdiction: jurisdictionAccounts?.jurisdiction?.name,
  accountTypeLanguage: formatAccTypeLanguage(jurisdictionAccounts?.accountTypeLanguage),
});

export function isEditable(status) {
  return status === BATCH_TRANSFER_STATUS.SAVED || status === BATCH_TRANSFER_STATUS.REVISIONS_REQUESTED;
}

export function isComplete(status) {
  return status === BATCH_TRANSFER_STATUS.APPROVED || status === BATCH_TRANSFER_STATUS.COMPLETE;
}

export function getStatusUpdateErrorMessage(error, t1, isProposal = false) {
  const errorList = isProposal ? [] : [{ messageKey: t(t1, 'BATCH_TRANSFER_APPROVAL_ERROR') }];
  if (typeof error === 'string') {
    errorList.push({
      messageKey: t(t1, error),
    });
    return errorList;
  }
  if (error?.invalidTransferringAccount?.length) {
    errorList.push({
      messageKey: `${t(t1, 'TRANSFERRING_ACCOUNT_ERROR')}: ${JSON.stringify(
        error.invalidTransferringAccount,
      )} ${t(t1, 'TRANSFERRING_ACCOUNT_COMPLIANCE_DESCRIPTION')}`,
    });
  }

  if (error?.invalidReceivingAccount?.length) {
    errorList.push({
      messageKey: `${t(t1, 'RECEIVING_ACCOUNT_ERROR')}: ${JSON.stringify(error.invalidReceivingAccount)} ${t(
        t1,
        'RECEIVING_ACCOUNT_COMPLIANCE_DESCRIPTION',
      )}`,
    });
  }

  if (isProposal && error?.invalidStopAllInProgressList?.length || error?.invalidStopSavedInProgressList?.length) {
    const invalidStopAllInProgressList = error?.invalidStopAllInProgressList || [];
    const invalidStopSavedInProgressList = error?.invalidStopSavedInProgressList || [];
    const combinedAccountList = [
      ...new Set([...invalidStopAllInProgressList, ...invalidStopSavedInProgressList]),
    ];
    errorList.push({
      messageKey: `${JSON.stringify(combinedAccountList)} ${t(t1, 'IS_NOT_FOUND')}`,
    });
  }

  if (!isProposal && error?.invalidStopAllInProgressList?.length || error?.invalidStopProposedInProgressList?.length) {
    const invalidStopAllInProgressList = error?.invalidStopAllInProgressList || [];
    const invalidStopProposedInProgressList = error?.invalidStopProposedInProgressList || [];
    const combinedAccountList = [
      ...new Set([...invalidStopAllInProgressList, ...invalidStopProposedInProgressList]),
    ];
    errorList.push({
      messageKey: `${JSON.stringify(combinedAccountList)} ${t(t1, 'IS_NOT_FOUND')}`,
    });
  }

  return errorList;

}

export const transformForPendingApproval = (data) =>
  data
    ?.map((item, idx) => ({
      id: item?.id || idx + 1,
      displayId: item?.batchId,
      keyInformation: item?.batchName,
      type: 'JURISDICTION_BATCH_TRANSFER',
      status: item?.Status?.name,
      lastUpdatedAt: formatDateUpdated(item?.updatedAt) || '',
    }))
    ?.sort((a, b) => new Date(b?.last_updated) - new Date(a?.last_updated));

export const ACCOUNT_TYPE_ISSUANCE = 'ISSUANCE';

export const ALLOWANCE = 'ALLOWANCE';

export const holdingsCheckForPCRA = ({ type, transferTypeName, vintage }) => {
  if (transferTypeName === BATCH_TRANSFER_TYPES.JURISDICTION_BATCH_TRANSFER_PCRA) {
    return type?.toUpperCase() === ALLOWANCE && !!vintage;
  }
  return true;
};

export const isBatchOrPCRA = (transferType) => transferType === BATCH_TRANSFER_TYPES.BATCH_TRANSFER || transferType === BATCH_TRANSFER_TYPES.JURISDICTION_BATCH_TRANSFER_PCRA;

export const isAdminOrReplenishmentTransfer = (transferType) => transferType === JURISDICTION_TRANSFER_TYPES.ADMINISTRATIVE || transferType === JURISDICTION_TRANSFER_TYPES.REPLENISHMENT;

export const showSelectedHoldingsGrid = (batchStatus) => !(batchStatus === BATCH_TRANSFER_STATUS.APPROVED || batchStatus === BATCH_TRANSFER_STATUS.COMPLETE || batchStatus === BATCH_TRANSFER_STATUS.DENIED);

export const BATCH_TRANSFER_COMPONENTS_FLP = {
  BATCH_TRANSFER_LIST: 'getBatchTransferList',
  BATCH_ACCOUNT_DETAILS: 'getBatchAccountDetails',
  SELECTED_HOLDINGS_BATCH: 'getBatchTransferHoldings',
  BATCH_TRANSFER_ACCOUNT_DETAILS: 'getBatchTransferAccountDetails',
  BATCH_TRANSFER_HISTORY: 'getBatchHistory',
  BATCH_TRANSFER_SAVE: 'createBatchtransferDetail',
  ADD_RECEIVING_ACCOUNT: 'createBatchtransferRecord',
  UPDATE_BATCH_TRANSFER: 'editBatchTransferDetail',
  BULK_UPLOAD_BATCH_TRANSFER: 'batchTransferBulkUpload',
  EDIT_BATCH_TRANSFER: 'editBatchTransferRecord',
  UPDATE_STATUS_BATCH_TRANSFER: 'editTransferStatus',
  BATCH_TRANSFER_APPROVE: 'editApprovalBatchTransfer',
};

export const PAGE_MANAGE_BATCH_TRANSFER = 'Page_ManageBatchTransfer';

function checkEqual(obj1, obj2) {
  if (obj1 === obj2) return true;

  if (obj1 === null && obj2 === '') return true;
  if (obj1 === '' && obj2 === null) return true;

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  const keys2 = Object.keys(obj2);

  return keys2.every(key => {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (val1 === val2) return true;
    if ((val1 === null && val2 === '') || (val1 === '' && val2 === null)) return true;
    if (typeof val1 === 'object' && typeof val2 === 'object') return checkEqual(val1, val2);

    return false;
  });
}

export function compareArrays(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((item, index) => checkEqual(item, arr2[index]));
}