import { Loadable } from 'components/Loadable';
import RoleBasedGuard from 'guards/RoleBasedGuard';
import { lazy } from 'react';

const ManageExchangeContractDescCode = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.ManageExchangeContractDescCode,
    })),
  ),
);

const AddExchangeContractDescCode = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.AddExchangeContractDescCode,
    })),
  ),
);

const EditExchangeContractDescCode = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.EditExchangeContractDescCode,
    })),
  ),
);

export const exchangeContractCodeRoutes = {
  path: '/exchange-contract-code',
  children: [
    {
      path: 'manage',
      element: (
        <RoleBasedGuard privId={''}>
          <ManageExchangeContractDescCode />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'add',
      element: (
        <RoleBasedGuard privId={''}>
          <AddExchangeContractDescCode />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'edit/:id',
      element: (
        <RoleBasedGuard privId={''}>
          <EditExchangeContractDescCode />
        </RoleBasedGuard>
      ),
    },
  ],
};
