import { createSlice } from '@reduxjs/toolkit';
import { MOF_COMPONENTS, MOF_PAGES } from 'pages/OffsetManagement/constants';
import { APIS, COMPONENT_NAME_EVENT, getCurrentPageName } from 'utils/constants';
import fetcher, { fetcherGet, fetcherPost, fetcherDelete, fetcherPatch, fetcherPut } from 'utils/fetcher';
import logger from 'utils/logger';
import { getJurisdictionUUID, getPermissionObj } from 'utils/support';
import { setLoader } from './commonSlice';

const initialState = {
  selectedEventDetails: {},
  selectedEventDetail: { isLoading: false, data: [] },
  selectedEventHistory: { isLoading: false, data: [] },
  eventType: { isLoading: false, data: [] },
  vintageType: [],
  crossJurisdiction: { isLoading: false, data: [] },
  cancelEvent: [],
  selectedReserveSaleEventDetail: { isLoading: false, data: [] },
  tiers: [],
  eventsType: null,
  eventDetails: null,
  patchEventDetails: null,
  eventApplicationList: { isLoading: false, data: [] },
  eventApplicationListByEventID: { isLoading: false, data: [] },
  eventList: { isLoading: false, data: [] },
  eventApplicationCount: { isLoading: false, data: [] },
  eventName: { isLoading: false, data: [] },
};

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setSelectedEventDetails: (state, action) => {
      state.selectedEventDetails = action.payload;
    },

    setSelectedEventDetail: (state, action) => {
      state.selectedEventDetail = action.payload;
    },
    setEventType: (state, action) => {
      state.eventType = action.payload;
    },
    setVintageType: (state, action) => {
      state.vintageType = action.payload;
    },
    setSelectedEventHistory: (state, action) => {
      state.selectedEventHistory = action.payload;
    },
    setCrossJurisdiction: (state, action) => {
      state.crossJurisdiction = action.payload;
    },
    setCancelEvent: (state, action) => {
      state.cancelEvent = action.payload;
    },
    setSelectedReserveSaleEventDetail: (state, action) => {
      state.selectedReserveSaleEventDetail = action.payload;
    },
    setTiers: (state, action) => {
      state.tiers = action.payload;
    },
    setEventDetails: (state, action) => {
      state.eventDetails = action.payload;
    },
    setEventApplicationList: (state, action) => {
      state.eventApplicationList = action.payload;
    },
    setEventApplicationListByEventID: (state, action) => {
      state.eventApplicationListByEventID = action.payload;
    },
    setEventListingData: (state, action) => {
      state.eventList = action.payload;
    },
    setEventApplicationCount: (state, action) => {
      state.eventApplicationCount = action.payload;
    },
    setEventName: (state, action) => {
      state.eventName = action.payload;
    },

  },
});

export default eventSlice.reducer;
export const {
  setEventDetails,
  setSelectedEventDetail,
  setEventType,
  setVintageType,
  setCrossJurisdiction,
  setSelectedEventHistory,
  setSelectedReserveSaleEventDetail,
  setTiers,
  setEventApplicationList,
  setEventApplicationListByEventID,
  setEventListingData,
  setEventApplicationCount,
  setEventName,
} = eventSlice.actions;


export function getCrossJurisdictions(PageName) {
  const pageDetails = { componentName: COMPONENT_NAME_EVENT.EVENT_CREATION, pageName: PageName };
  return (dispatch) => {
    dispatch(setCrossJurisdiction({ isLoading: true }));
    fetcherGet(APIS.CROSSJURYS, {}, pageDetails)
      .then((response) => {
        dispatch(setCrossJurisdiction({ isLoading: false, data: response?.data }));
      })
      .catch((err) => {
        dispatch(setCrossJurisdiction({ isLoading: false }));
      });
    dispatch(setLoader(false));
  };
}

export function addEvent(payload, PageName, eventTypePayload) {
  const pageDetails = {
    componentName: `${COMPONENT_NAME_EVENT.EVENT_CREATION},${eventTypePayload && eventTypePayload === 'Auction' ? COMPONENT_NAME_EVENT.EVENT_CREATION_AUCTION : COMPONENT_NAME_EVENT.EVENT_CREATION_RES_SALE}`,
    pageName: PageName,
  };
  const jurisdictionID = getJurisdictionUUID(); // Declare jurisdictionID as a constant
  if (jurisdictionID) {
    return async () => {
      return fetcherPost(`events/api/jurisdictions/${jurisdictionID}/events/create-event`, {
          ...payload,
        },
        pageDetails);
    };
  }
}

export function getEventType(eventComponentName, PageName) {
  const pageDetails = { componentName: eventComponentName, pageName: PageName };
  return async (dispatch) => {
    dispatch(setEventType({ isLoading: true }));
    const url = APIS.GET_EVENT_TYPE;
    const response = await fetcherGet(`${url}`, {}, pageDetails);
    if (response.messageKey === 'SUCCESS') {
      dispatch(setEventType({ isLoading: false, data: response?.data }));
    } else {
      dispatch(setEventType({ data: [] }));
    }
  };
}

export function getVintageTypes(eventComponentName, PageName) {
  const pageDetails = { componentName: eventComponentName, pageName: PageName };
  return async (dispatch) => {
    dispatch(setLoader(true));
    const response = await fetcherGet(`${APIS.GET_VINTAGE_TYPE}`, {}, pageDetails);
    if (response.messageKey === 'SUCCESS') {
      dispatch(setVintageType(response?.data));
    } else {
      dispatch(setVintageType([]));
    }
    dispatch(setLoader(false));
  };
}

// To fetch user details when selected from DataGrid Table by Event Management Listing.

export function getSelectedEventDetail(jurisdictionId, eventID, PageName) {
  const pageDetails = { componentName: COMPONENT_NAME_EVENT.VIEW_EVENT_AUCTION, pageName: PageName };
  return (dispatch) => {
    dispatch(setSelectedEventDetail({ isLoading: true }));
    if (jurisdictionId) {
      fetcher(`${APIS.EVENT_API}/${jurisdictionId}/auction-events/${eventID}`, 'GET', {}, false, pageDetails)
        .then((response) => {
          dispatch(setSelectedEventDetail({ isLoading: true, data: response?.data }));
          setTimeout(() => {
            dispatch(setSelectedEventDetail({ isLoading: false, data: response?.data }));
          }, 3000);
        })
        .catch((err) => {
          dispatch(setSelectedEventDetail({ isLoading: false }));
        });
    } else {
      dispatch(setSelectedEventDetail({ isLoading: false }));
    }
  };
}

export function getSelectedEventHistory(jurisdictionId, eventId, eventComponentName, PageName) {
  const pageDetails = { componentName: eventComponentName, pageName: PageName };
  return (dispatch) => {
    dispatch(setSelectedEventHistory({ isLoading: true }));
    if (jurisdictionId) {
      fetcher(`${APIS.EVENT_API}/${jurisdictionId}/events/${eventId}/history`, 'GET', {}, false, pageDetails)
        .then((response) => {
          dispatch(setSelectedEventHistory({ isLoading: false, data: response?.data }));
        })
        .catch((err) => {
          dispatch(setSelectedEventHistory({ isLoading: false }));
        });
    } else {
      dispatch(setSelectedEventHistory({ isLoading: false }));
    }
  };
}

export function handleDeleteEvent(jurisdictionId, eventID, PageName) {
  const pageDetails = { componentName: COMPONENT_NAME_EVENT.EVENT_CANCELLATION, pageName: PageName };
  return () => {
    if (jurisdictionId) {
      return fetcher(`${APIS.EVENT_API}/${jurisdictionId}/events/${eventID}`, 'DELETE', {}, false, pageDetails)
        .finally(() => {
          // Perform cleanup actions here if needed
        });
    }
  };
}

export function getSelectedReserveSaleEventDetail(jurisdictionId, eventID, PageName) {
  const pageDetails = { componentName: COMPONENT_NAME_EVENT.VIEW_EVENT_RES_SALE, pageName: PageName };
  return (dispatch) => {
    dispatch(setSelectedReserveSaleEventDetail({ isLoading: true, data: [] }));

    // Check if jurisdictionId is present before making the API call
    if (jurisdictionId) {
      fetcher(`${APIS.EVENT_API}/${jurisdictionId}/reserve-sale-events/${eventID}`, 'GET', {}, false, pageDetails)
        .then((response) => {
          dispatch(setSelectedReserveSaleEventDetail({ isLoading: false, data: response?.data }));
        })
        .catch((err) => {
          dispatch(setSelectedReserveSaleEventDetail({ isLoading: false, data: [] }));
        });
    }
  };
}

export function getTiers(eventComponentName, PageName) {
  const pageDetails = { componentName: eventComponentName, pageName: PageName };
  return (dispatch) => {
    fetcherGet('events/api/tiers', {}, pageDetails)
      .then((response) => {
        dispatch(setTiers(response?.data));
      })
      .catch((err) => {
      });
  };
}

export function updateEventDetails(jurisdictionId, eventId, data, PageName, EventType) {
  const pageDetails = {
    componentName: `${COMPONENT_NAME_EVENT.EDIT_EVENT},${EventType && EventType === 'AUCTION' ? COMPONENT_NAME_EVENT.EDIT_EVENT_AUCTION : COMPONENT_NAME_EVENT.EDIT_EVENT_RES_SALE}`,
    pageName: PageName,
  };
  return async (dispatch) => {
    dispatch(setLoader(true));
    if (jurisdictionId) {
      return fetcherPatch(`events/api/jurisdictions/${jurisdictionId}/events/${eventId}`, data, pageDetails)
        .finally(() => {
          dispatch(setLoader(false));
        });
    }
  };
}

export function getEventsApplicationList() {
  return (dispatch) => {
    const jurisdictionID = getJurisdictionUUID();

    if (!jurisdictionID) {
      console.error('Jurisdiction ID is missing');
      // Optionally, you can return a Promise.reject() or perform other actions
      return;
    }

    dispatch(setEventApplicationList({ isLoading: true, data: [] }));

    fetcherGet(`events/api/jurisdictions/${jurisdictionID}/event/jurisdiction-events`)
      .then((response) => {
        dispatch(setEventApplicationList({ isLoading: false, data: response?.data }));
      })
      .catch((error) => {
        logger({ error, name: 'eventApplicationManagement' });
        dispatch(setEventApplicationList({ isLoading: false, data: [] }));
      });
  };
}

export function getEventsApplicationListByEventID(eventID, PageName) {
  const pageDetails = {
    componentName: 'updateEventApplicationsDetails',
    pageName: PageName,
  };

  return (dispatch) => {
    const jurisdictionID = getJurisdictionUUID();

    if (!jurisdictionID) {
      console.error('Jurisdiction ID is missing');
      // Optionally, you can return a Promise.reject() or perform other actions
      return;
    }

    dispatch(setEventApplicationListByEventID({ isLoading: true, data: [] }));

    fetcherGet(`events/api/jurisdictions/${jurisdictionID}/event/${eventID}/event-applications`, {}, pageDetails)
      .then((response) => {
        dispatch(setEventApplicationListByEventID({ isLoading: false, data: response?.data }));
      })
      .catch((error) => {
        logger({ error, name: 'eventApplicationManagement' });
        dispatch(setEventApplicationListByEventID({ isLoading: false, data: [] }));
      });
  };
}

export function updateApplicationStatus(payload, id, PageName) {
  const pageDetails = {
    componentName: 'updateEventApplicationsDetails',
    pageName: PageName,
  };
  return async (dispatch) => {
    const jurisdictionID = getJurisdictionUUID();

    if (!jurisdictionID) {
      console.error('Jurisdiction ID is missing');
      return;
    }
    dispatch(setLoader(true));

    return fetcherPut(
      `events/api/jurisdictions/${jurisdictionID}/event/${id}/update-application-status`,
      payload, pageDetails,
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}


export function getEventListingData(jurisdictionId, PageName, eventComponentName, req = {}) {
  const pageDetails = { componentName: eventComponentName, pageName: PageName };
  return (dispatch) => {
    dispatch(setEventListingData({ isLoading: true, data: [] }));

    const params = new URLSearchParams(req);
    const queryParams = `?${params.toString()}`;
    fetcher(`events/api/jurisdictions/${jurisdictionId}/events${queryParams}`, 'GET', {}, false, pageDetails)
      .then((response) => {
        dispatch(setEventListingData({ isLoading: false, data: response?.data }));
      })
      .catch((err) => {
        dispatch(setEventListingData({ isLoading: false, data: [] }));
      });
  };
}

export function getEventApplicationCount(jurisdictionId, eventId, PageName) {
  const pageDetails = { componentName: COMPONENT_NAME_EVENT.EDIT_EVENT, pageName: PageName };
  return (dispatch) => {
    dispatch(setEventApplicationCount({ isLoading: true, data: [] }));

    fetcher(`${APIS.EVENT_API}/${jurisdictionId}/event/${eventId}/application-count`, 'GET', {}, false, pageDetails)
      .then((response) => {
        dispatch(setEventApplicationCount({ isLoading: false, data: response?.data }));

        setTimeout(() => {
          dispatch(setEventApplicationCount({ isLoading: false, data: response?.data }));
        }, 3000);
      })
      .catch((err) => {
        dispatch(setEventApplicationCount({ isLoading: false, data: [] }));
      });
  };
}

export function getEventName(payload, eventComponentName, PageName) {
  const pageDetails = { componentName: eventComponentName, pageName: PageName };
  return async () => {
    const jurisdictionId = getJurisdictionUUID();
    if (jurisdictionId) {
      return fetcherPost(`${APIS.EVENT_API}/${jurisdictionId}/events/check-existance`, {
        ...payload,
      }, pageDetails).finally(() => {
      });
    }
  };
}

export function getEventCurrencies(payload, PageName) {
  const pageDetails = { componentName: 'eventCreation', pageName: PageName };
  return async (dispatch) => {

    return fetcherPost(`events/api/getEventCurrency`, {
      ...payload,
    }, pageDetails).finally(() => {
      // dispatch(setLoader(false));
    });
  };
}

export function getEventBudgetRecords(payload, PageName) {
  const pageDetails = { componentName: 'eventCreation_Auction', pageName: PageName };
  return async (dispatch) => {
    // dispatch(setLoader(true));
    return fetcherPost(`events/api/getBudgetRecords`, {
      ...payload,
    }, pageDetails).finally(() => {
      // dispatch(setLoader(false));
    });
  };
}
