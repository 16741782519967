import authRoles from 'core/auth/authRole';
import Login from './Login';

const LoginConfig = {
  route: {
    path: '/',
    element: <Login />,
  },
  layout: {
    header: {
      display: false,
    },
    sidebar: {
      display: false,
    },
    footer: {
      display: false,
    },
  },
  auth: authRoles.onlyGuest,
};

export default LoginConfig;
