const Style = {
  FieldLabel: {
    fontSize: '0.875rem',
    color: 'common.textColor',
    fontWeight: 700,
    mb: 0.5,
  },
  formField: {
    width: 1,
    color: 'common.black',
    '&.fc-select-select': {
      '& .MuiOutlinedInput-input': {
        background: '#fff',
        minHeight: '40px',
        py: 0,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px',
      },
    },

    '&.fc-select-rounded-select': {
      '.MuiOutlinedInput-input': {
        py: 0.4,
        px: '10px',
        fontSize: '0.875rem',
      },
      '.MuiOutlinedInput-notchedOutline': {
        borderRadius: '4px',
      },
    },
    '.MuiOutlinedInput-input': {
      p: 0.6,
      fontSize: '0.875rem',
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: '4px',
      borderColor: 'common.GrayColor',
    },
    '&.MuiOutlinedInput-root': {
      '&.MuiInputBase-root.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: '#333333',
          borderWidth: 1,
        },
      },
    },
  },
  RadioSize: {
    pointerEvents: 'none',
    pt: '5px',
    '& .MuiButtonBase-root': {
      padding: '0 20px 0 10px',
    },
    '& .MuiFormControlLabel-label': {
      // paddingBottom: '3px'
      lineHeight: '19px',
      fontSize: '0.875rem',
    },
    '& .MuiRadio-root': {
      padding: '0',
    },
    '& .MuiSvgIcon-root path': {
      transform: 'scale(1.08)',
    },
  },

};

export default Style;

