import { Box, Container, Grid, Typography, TextField } from '@mui/material';
import { CustomeDialogMessage } from 'components/CustomeDialogMessage';
import { EntitySteps } from 'components/EntitySteps';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { QuickLinksMenu } from 'components/QuickLinksMenu';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setDialogMessage, setLoader, setModelMessage } from 'store/commonSlice';
import {
  getCountry,
  getEntities,
  getJurisdictions,
  getOrganization,
  getPurpose,
  getUserClassification,
  setFormValueOfStep,
  setNextStepSelect,
  setSubmittedOfSelectedStep,
} from 'store/entityApplicationSlice';
import { saveEntityStatus } from 'store/entityProfileSlice';
import {
  COMPONENT_NAMES,
  ENTITY_APPLICTION_STATUS_NAMES,
  ERROR,
  getCurrentPageName,
  SUCCESS,
  t,
  isEmpty,
  ENTITY_TYPE_NAMES,
  handleEntityStatusError,
} from 'utils/constants';
import ContactInformation from './components/ContactInformation/ContactInformation';
import EnterEntityInformation from './components/EnterEntityInformation/EnterEntityInformation';
import SelectEntityType from './components/SelectEntityType/SelectEntityType';
import SelectJurisdiction from './components/SelectJurisdiction/SelectJurisdiction';
import SubmitRequiredDocuments from './components/SubmitRequiredDocuments/SubmitRequiredDocuments';
import Style from './CreateEntityUserRegistration.style';
// eslint-disable-next-line no-nested-ternary
import AddFacility from './components/AddFacility/AddFacility';
import AddRepresentative from './components/AddRepresentative/AddRepresentative';
import Auction from './components/Auction/Auction';
import ReviewandSubmit from './components/ReviewandSubmit/ReviewandSubmit';

export default function CreateEntityUserRegistration({ handleSubmit }) {
  const [showActivateDeactivateModal, setShowActivateDeactivateModal] = useState(false);
  const [comment, setComment] = useState('');
  const [errorMsg, setErrorMsg] = useState(false);

  const { t: t1 } = useTranslation(getCurrentPageName());
  const { actionStatus, requestStatus, entities } = useSelector(({ entityProfile }) => entityProfile);

  const { steps, EditAccess, fieldPermissions } = useSelector(({ entityApplication }) => entityApplication);
  const { loader } = useSelector(({ common }) => common);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const getStepTitle = (activeStep) => t(t1, activeStep.pageTitle);

  useEffectOnce(() => {
    dispatch(getJurisdictions());
    dispatch(getEntities());
    dispatch(getCountry());
    dispatch(getPurpose());
    dispatch(getUserClassification());
    dispatch(getOrganization());
  }, []);

  useEffectOnce(() => {
    // dispatch(getCountries());
    dispatch(setDialogMessage(false));
    // dispatch(resetCreaeUserProfileState());
  }, []);

  /* comment box added - pending entity */
  const entityStatusChecked = entities?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.PENDING;
  const coveredEntity =
    entities?.data?.entityType?.name === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY
      ? t(t1, 'ASSOCIATE_FACILITY_RETIRED')
      : '';

  const activeStepObj = steps.filter((item) => item.selected)[0];
  const showCancelMenu = () => {
    return [
      COMPONENT_NAMES.ADD_FACILITIES,
      COMPONENT_NAMES.ADD_REPRESENTATIVES,
      COMPONENT_NAMES.AUCTION,
      COMPONENT_NAMES.REVIEW_AND_SUBMIT,
      COMPONENT_NAMES.SUBMIT_REQUIRED_DOCUMENTS,
    ].includes(activeStepObj.key);
  };

  const handleModalMessage = () => {
    setShowActivateDeactivateModal(true);
  };

  const handleModalCancel = () => {
    setComment('');
    setErrorMsg('');
    setShowActivateDeactivateModal(!showActivateDeactivateModal);
  };

  /* comment box added - pending entity */
  const handleBlur = (e) => {
    setComment(e.target.value?.trimStart());
    if (String(comment)?.trim()?.length > 8000) {
      setErrorMsg(`${t(t1, 'ERR_COMMENT_MAX_LENGTH_CHAR')}`);
    }
    if (String(comment)?.trim()?.length <= 0) {
      setErrorMsg(`${t(t1, 'COMMENTS_ISREQUIRED')}`);
    }
  };

  const handleContinue = useCallback((data) => {
    setShowActivateDeactivateModal(false);
    dispatch(setSubmittedOfSelectedStep(true));
    dispatch(setFormValueOfStep(data));
    if (handleSubmit) {
      handleSubmit(data);
    } else {
      dispatch(setNextStepSelect({ steps }));
    }
  }, []);

  const handleCancelApplication = () => {
    setShowActivateDeactivateModal(false);
    if (id) {
      dispatch(setLoader(true));
      const actionData = { comment: entityStatusChecked ? comment : '' };
      actionData.requestStatusId = requestStatus.find(
        (item) => item.name === ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_CANCELLED_BY_USER,
      ).id;
      actionData.statusId = actionStatus.find((item) => item.Name === 'CANCELLED').ID;
      actionData.entityId = id;
      saveEntityStatus(actionData)
        .then((response) => {
          let data = null;
          if (response.messageKey === 'CANCEL_STATUS_UPDATED') {
            data = {
              title: SUCCESS,
              message1: response.messageKey,
              error: false,
              isDefaultDisplay: false,
            };
            dispatch(getEntities());
            window.history.replaceState(null, null, '/');
            navigate('/home?exit=1');
          } else {
            data = {
              title: ERROR,
              message1: response.data,
              error: true,
              isDefaultDisplay: true,
            };
          }
          dispatch(setModelMessage(data));
        })
        .catch((error) => {
          const hasNavigated = handleEntityStatusError(error, id, dispatch, navigate);
          if (hasNavigated) return;
          dispatch(
            setModelMessage({
              title: ERROR,
              message1: error.message,
              error: true,
              isDefaultDisplay: true,
            }),
          );
        })
        .finally(() => {
          dispatch(setLoader(false));
        });
    } else {
      navigate('/home');
    }
  };
  const cancelEntityIcon = [
    {
      name: t(t1, 'CANCEL_ENTITY_APPLICATION_CAPTITAL').toUpperCase(),
      Access: null,
      onLinkClick: () => handleModalMessage(),
    },
  ];

  const isEditable = EditAccess;

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={2.5}
      >
        <Grid
          item
          xs={3}
        >
          <Box sx={{ paddingTop: '25px', position: 'sticky', top: 0 }}>
            <EntitySteps />
          </Box>
        </Grid>
        <Grid
          item
          xs={9}
        >
          <Box sx={{ pt: 3 }}>
            <div style={Style.entityCancelIconDiv}>
              <PageTitle title={`${activeStepObj && activeStepObj.stepNumber}. ${getStepTitle(activeStepObj)}`} />
              {showCancelMenu() && isEditable && (
                <QuickLinksMenu
                  popupClassName="quick-link-style"
                  linksList={cancelEntityIcon}
                  label={t(t1, 'MORE ACTIONS')}
                />
              )}
            </div>
            {showActivateDeactivateModal &&
            entities?.data?.entityStatus?.name !== ENTITY_APPLICTION_STATUS_NAMES.PENDING ? (
              <CustomeDialogMessage
                title={t(t1, 'WARNING')}
                continueBtn={t(t1, 'CONTINUE')}
                open={showActivateDeactivateModal}
                handleModalCancel={handleModalCancel}
                handleClose={handleModalCancel}
                message1={t(t1, 'CANCEL_ENTITY_ACCOUNT_APPLICATION')}
                message2={t(t1, 'CANCEL_WARNING_CONTENT2')}
                message3={t(t1, 'CANCEL_WARNING_CONTENT3')}
                borderColor="red"
                cancelApplicationModal
                handleContinue={handleCancelApplication}
              />
            ) : (
              <CustomeDialogMessage
                title={t(t1, 'WARNING')}
                handleButtonClick={handleCancelApplication}
                handleModalCancel={handleModalCancel}
                open={showActivateDeactivateModal}
                message1={t(t1, 'CANCEL_WARNING_CONTENT5')}
                message2={coveredEntity}
                message3={t(t1, 'CANCEL_WARNING_CONTENT_FOR_PENDING')}
                message4={t(t1, 'PLEASE_ADD_COMMENT')}
                buttonDisplay={t(t1, 'CANCEL_APPLICATION')}
                closeBtnDisplay
                borderColor="#ca2c1c"
                disabled={!isEmpty(comment)}
              >
                <Grid
                  item
                  md={12}
                  sx={{ mt: 1 }}
                  className="no-lable-field"
                >
                  <TextField
                    value={comment}
                    onChange={handleBlur}
                    onBlur={handleBlur}
                    fullWidth
                    gridMd={12}
                    name="cancelComment"
                    multiline
                    className="input-group"
                    rows={2}
                    error={(String(comment)?.trim()?.length <= 0 || String(comment)?.trim()?.length > 8000) && errorMsg}
                  />
                </Grid>
                <span className="custom_error_msg custom_error_msg_wrapper">
                  {(String(comment)?.trim()?.length <= 0 || String(comment)?.trim()?.length > 8000) ? errorMsg : ''}
                </span>
              </CustomeDialogMessage>
            )}
            <span
              style={{
                display: activeStepObj && activeStepObj.key === COMPONENT_NAMES.SELECT_JURISDICTION ? 'block' : 'none',
              }}
            >
              <SelectJurisdiction
                fieldPermissions={fieldPermissions}
                key={activeStepObj.key}
                stepData={activeStepObj}
              />
            </span>
            {activeStepObj && activeStepObj.key === COMPONENT_NAMES.SELECT_ENTITY_TYPE ? (
              <SelectEntityType
                key={activeStepObj.key}
                handleContinue={handleContinue}
              />
            ) : null}
            {activeStepObj && activeStepObj.key === COMPONENT_NAMES.ENTITY_INFORMATION ? (
              <EnterEntityInformation />
            ) : null}
            {(activeStepObj && activeStepObj.key === COMPONENT_NAMES.ADD_FACILITIES) ||
            activeStepObj.key === COMPONENT_NAMES.ADD_FACILITIES ? (
              <AddFacility />
            ) : null}
            {activeStepObj && activeStepObj.key === COMPONENT_NAMES.ADD_REPRESENTATIVES ? (
              <AddRepresentative removeViwingAgent />
            ) : null}
            {activeStepObj && activeStepObj.key === COMPONENT_NAMES.CONTACT_INFORMATION ? <ContactInformation /> : null}
            {activeStepObj && activeStepObj.key === COMPONENT_NAMES.AUCTION ? <Auction /> : null}

            {activeStepObj && activeStepObj.key === COMPONENT_NAMES.REVIEW_AND_SUBMIT ? <ReviewandSubmit /> : null}
            {activeStepObj && activeStepObj.key === COMPONENT_NAMES.SUBMIT_REQUIRED_DOCUMENTS ? (
              <SubmitRequiredDocuments />
            ) : null}
          </Box>
        </Grid>
      </Grid>
      <Loader loader={loader} />
    </Container>
  );
}
