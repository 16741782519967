import React, { useMemo } from 'react';
import Button from '@mui/material/Button';
import useAuth from 'hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { setFilledData, setNextActiveStep, setSubmittedState, validateUser } from 'store/createUserProfileSlice';

import { useNavigate } from 'react-router-dom';
import { setLoader } from 'store/commonSlice';
import { t, getCurrentPageName, isEmpty } from 'utils/constants';
import { checkIsAuthenticated, getUserDetails } from 'store/authSlice';

export default function ButtonFill({
                                     children,
                                     styleSx = {},
                                     stepNumber,
                                     handleUpdateCB,
                                     handleFinish,
                                     handleRegister,
                                     name,
                                     privId = '',
                                     acceptTAndC = null,
                                     saveDisabled = false,
                                   }) {
  const { isSignedIn, user: loggedUser } = useAuth();
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user;
  const { t: t1 } = useTranslation(getCurrentPageName());
  const methods = useFormContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* Conflict Resolution Candidate
    const updatedStepNumber = useMemo(() => stepNumber === 4 ? 3 : stepNumber, [stepNumber])
   */

  const {
    formSteps: { activeStep },
    filledData,
    isValid,
    userRegCaptcha,
  } = useSelector(({ createUserProfile }) => createUserProfile);

  let readOnly = false;
  if (privId === '') {
    if (PrivAttributes?.[name]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[name]?.PrivRole === 'RO';
  } else {
    if (PrivAttributes?.[privId]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[privId]?.PrivRole === 'RO';
  }

  let btnDisable = false;

  // functionality to update data if it is edited on 'Edit & Review' Page
  const updateDetails = async (data) => {
    let showEmployerList;
    // Field 'employer' is used in user registration flow whereas 'Employer' field is used in COJ flow
    if (data?.employer) {
      showEmployerList = data?.employer?.filter(employer => employer.title !== '');
      methods?.setValue('employer', showEmployerList);
    } else {
      showEmployerList = data?.Employer?.filter(employer => employer.title !== '');
      methods?.setValue('Employer', showEmployerList);
    }

    dispatch(setFilledData({
      step: stepNumber, data: {
        ...data,
        employer: data?.employer?.filter(employer => employer.title !== ''),
      },
    }));
    // setting the value of Date field, as it is not directly reflected in the form, if updated by a user
    methods?.setValue('date', data.date);
  };

  const submitHandler = async (data) => {
    /* Conflict Resolution Candidate
        if (activeStep === 4) {
          // dispatch(setFilledData({ step: updatedStepNumber, data }));
          // set the value of the registration form
          if(!isSignedIn) {
            if(updatedStepNumber === 3){
              const lastData = filledData?.[3]
              if(stepNumber === 4){
                dispatch(setFilledData({ step: updatedStepNumber, data:{...lastData,mailingAddress:data.mailingAddress} }));
              } else {
              dispatch(setFilledData({ step: updatedStepNumber, data:{...lastData,primaryResidence:data.primaryResidence} }));
              }
            }
            else{
              dispatch(setFilledData({ step: updatedStepNumber, data }));
            }
          }
        } else {
          methods.trigger("confirmEmail")
          dispatch(setSubmittedState({ step: updatedStepNumber, data: true }));
            dispatch(setFilledData({ step: updatedStepNumber, data }));
          if (activeStep === 5) {
            handleRegister(data);
            // dispatch(setNextActiveStep());
          } else {
            // console.log(data);
            // await dispatch(validateUser({ email: data.email }));
            // // console.log("error");
            //   if (!isValid) {
            //     methods.formState.isValid = false;
            //     console.log("error");
            //     // return `${t(t1, "EMAIL_ADDRESS") + t(t1, "IS_NOT_AVAILABLE")}`;
            //   }
            //   // return null;
            if (activeStep === 1 && !checkIsAuthenticated() && isEmpty(loggedUser)) {
              dispatch(getUserDetails({ jurisdiction: data?.jurisdiction?.OrgID, pageName: "Page_UserRegistration" }));
            }
            dispatch(setNextActiveStep());
          }
     */
    methods.trigger('confirmEmail');
    dispatch(setSubmittedState({ step: stepNumber, data: true }));
    if (data?.employer) {
      dispatch(setFilledData({
        step: stepNumber,
        data: {
          ...data,
          employer: data?.employer?.filter(employer => employer.title !== ''),
        },
      }));
    } else {
      dispatch(setFilledData({ step: stepNumber, data }));
    }

    if (activeStep === 5) {
      handleRegister(data);
      // dispatch(setNextActiveStep());
    } else {
      // console.log(data);
      // await dispatch(validateUser({ email: data.email }));
      //   if (!isValid) {
      //     methods.formState.isValid = false;
      //     console.log("error");
      //     // return `${t(t1, "EMAIL_ADDRESS") + t(t1, "IS_NOT_AVAILABLE")}`;
      //   }
      //   // return null;
      if (activeStep === 1 && !checkIsAuthenticated() && isEmpty(loggedUser)) {
        dispatch(getUserDetails({ jurisdiction: data?.jurisdiction?.OrgID, pageName: 'Page_UserRegistration' }));
      }
      dispatch(setNextActiveStep());
    }
  };

  if (activeStep === 5) {
    if (methods && methods?.formState?.isValid && acceptTAndC) btnDisable = false;
    else btnDisable = true;

  } else if (activeStep === 4) {
    btnDisable = saveDisabled;
  } else if (activeStep !== 6) {
    btnDisable = true;
    if (methods && methods?.formState?.isValid) btnDisable = false;
  } else {
    btnDisable = false;
  }

  const onClick = (e) => {
    if (activeStep === 6) {
      navigate('/');
    } else {
      if (activeStep !== 4) {
        dispatch(setLoader(true));
      }
      if (children.includes('Testing'))
        handleFinish();
      if (activeStep === 4 && !btnDisable) {
        handleUpdateCB();
        methods?.handleSubmit(updateDetails)(e); // to save and update details
      } else {
        methods?.handleSubmit(submitHandler)(e);
      }
    }
  };
  return (
    <Button
      variant="contained"
      disableElevation
      disabled={btnDisable}
      onClick={onClick}
      id={name}
      sx={{
        py: 1,
        minWidth: 155,
        border: '1px solid',
        borderColor: 'primary.main',
        '&:hover': {
          bgcolor: 'primary.light',
          /* Conflict Resolution Candidate
                    borderColor: "primary.light",
                    fontWeight: 650,
           */
          borderColor: 'primary.light',
        },
        '&.Mui-disabled': {
          border: '1px solid',
          borderColor: 'common.borderButtonColor',
          color: 'common.white',
          backgroundColor: 'common.textLightGray',
          textFillColor: '#fff !important',
        },
        ...styleSx,
      }}
    >
      {children}
    </Button>
  );
}
