const Style = {
  ModelWrapper: {
    zIndex: 9999,
    width: 750,
    maxWidth: 1,
    position: 'fixed',
    // top: "-5%",
    left: '50%',
    transform: 'translateX(-50%)',
    boxShadow: 2,
    borderRadius: 3,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    border: '2px solid',
    borderTop: 0,
    py: 1.8,
    px: 2.5,
    animation: 'myEffect 500ms ease-in',
    '@keyframes myEffect': {
      '0%': {
        top: '-20%',
      },
      '100%': {
        top: '0%',
      },
    },
    '@keyframes revMyEffect': {
      '0%': {
        top: '0%',
      },
      '100%': {
        top: '-20%',
      },
    },
  },
  ModelSuccess: {
    bgcolor: 'common.lightGreen',
    borderColor: 'common.green',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.35)',
    minHeight: '150px',
    width: '750px',
  },
  ModelError: {
    bgcolor: 'common.lightRed',
    borderColor: 'common.red',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.35)',
    minHeight: '150px',
    width: '750px',
  },
};
export default Style;