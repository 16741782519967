import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  t,
  getCurrentPageName,
  ENTITY_FLOW,
  USER_ROLES,
  USER_ROLE_TYPES,
  isRolesAssigned,
  COMPONENT_NAME_FOR_ROLE,
  ENTITY_APPLICTION_STATUS_NAMES,
} from 'utils/constants';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { CustomizeButton } from 'components/CustomizeButton';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { FormCard } from 'components';
import { Loader } from 'components/Loader';
import { getRepresentative } from 'store/entityApplicationSlice';
import { ApplicationChecklist } from '../../../../EntityProfile/components/ApplicationChecklist/ApplicationChecklist';
import {
  ApplicationWithAttestations,
} from '../../../../EntityProfile/components/ApplicationChecklist/ApplicationWithAttestations';
import { getEntityById } from '../../../../../store/entityProfileSlice';

export default function Document({ downloadChecklist = false, downloadAttestation = false }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { entities } = useSelector(({ entityProfile }) => entityProfile);
  const dispatch = useDispatch();
  const { id } = useParams();
  const checklistRef = useRef();
  const attestationRef = useRef();
  const { updatedAt, entityStatus } = entities?.data || {};
  const EntityID = entities?.data?.entityId;
  const navigate = useNavigate();
  const [pageLoader, setPageLoader] = useState(false);
  const [searchParams] = useSearchParams();

  useEffectOnce(() => {
    setPageLoader(true);
    Promise.allSettled([dispatch(getEntityById(id)), dispatch(getRepresentative(id, null, false))])
      .then(() => {
        setTimeout(() => {
          if (downloadChecklist) {
            console.log(checklistRef.current);
            handleChecklistPrint();
          }
          if (downloadAttestation) {
            handleAttestationPrint();
          }
        }, [4000]);
      })
      .finally(() => {
        setPageLoader(false);
      });
  }, []);

  const handleChecklistPrint = useReactToPrint({
    content: () => checklistRef.current,
    documentTitle: searchParams.get('pdfName') || '',
  });

  const handleAttestationPrint = useReactToPrint({
    content: () => attestationRef.current,
    documentTitle: searchParams.get('pdfName') || '',
  });

  const handleDownloadForm = () => {
    if (downloadChecklist) {
      handleChecklistPrint();
    }
    if (downloadAttestation) {
      handleAttestationPrint();
    }
  };

  if (ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_IN_PROGRESS === entityStatus?.name && downloadAttestation) {
    navigate('/permissiondenied');
  }

  return (
    <>
      <Loader loader={pageLoader || (downloadAttestation && !entities)} />
      <div className="download_form_div">
        <FormCard indicateRequired={false}>
          {downloadChecklist && (
            <ApplicationChecklist
              ref={checklistRef}
              entityId={EntityID}
              lastUpdated={updatedAt}
            />
          )}
          {downloadAttestation && (
            <ApplicationWithAttestations
              page={ENTITY_FLOW.REGISTRATION}
              ref={attestationRef}
              entities={entities || {}}
            />
          )}
        </FormCard>
        <div className="download_btn_div">
          <CustomizeButton
            type="submit"
            name="priv_btn_save"
            styleSx={{ py: 0.4, px: 2, minWidth: 80 }}
            handleClick={handleDownloadForm}
            label={t(t1, 'DOWNLOAD')}
            csName="btn-primary-sm download_btn "
          >
            {t(t1, 'DOWNLOAD')}
          </CustomizeButton>
        </div>
      </div>
    </>
  );
}
