import ErrorIcon from '@mui/icons-material/Error';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { COMMA_SEPERATED_NUMBERS_REGX, getCurrentPageName, t } from 'utils/constants';
import { isInProposalForAuthority, isInProposalForJSA } from 'pages/CreateParticipatingJurisdiction/constants';
import { useJurisdictionUserRole } from './useUserRole';

let JurisdictionsDataGlobal = [];
const useCommonFunc = (selectedJurisdictionId) => {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const {
    jurisdictionDetails = [],
    Jurisdictions,
    selectedJurisdictionName,
  } = useSelector(({ jurisdiction }) => jurisdiction);
  const { requestStatus } =
    useSelector(({ onboardJurisdiction }) => onboardJurisdiction);
  const { isJSA, isAuthority } = useJurisdictionUserRole();
  useEffect(() => {
    JurisdictionsDataGlobal = jurisdictionDetails;
  }, [jurisdictionDetails]);
  const validateQuantity = (value) => {
    if (!/^\d+$/.test(value)) return `${t(t1, 'PLEASE_ENTER_VALID_QUANTITY')}`;
    const quantityValue = Number(value);
    if (Number.isNaN(quantityValue) || !Number.isInteger(quantityValue) || quantityValue <= 0) {
      return `${t(t1, 'PLEASE_ENTER_VALID_QUANTITY')}`;
    }
    return true;
  };

  const validateTransferIds = (input) => {
    if (!input) return true;
    return COMMA_SEPERATED_NUMBERS_REGX.test(input) ? true : t(t1, 'INVALID_TRANSFER_IDS');
  };

  const OFFSET_LINKS = [
    {
      id: 1,
      name: `${t(t1, 'OFFSET_PROJECT_NAME_MANAGEMENT')}`,
      path: '/offset-management/project-name/manage',
    },
    {
      id: 2,
      name: `${t(t1, 'OFFSET_PROJECT_OPERATOR_MANAGEMENT')}`,
      path: '/offset-management/project-operator/manage',
    },
    {
      id: 3,
      name: `${t(t1, 'VERIFICATION_BODY_MANAGEMENT')}`,
      path: '/offset-management/verification-body/manage',
    },
    {
      id: 4,
      name: `${t(t1, 'OFFSET_ISSUANCE_REPORT')}`,
      path: '/offset-management/issuance-report',
    },
    {
      id: 5,
      name: `${t(t1, 'OFFSET_TRACKING')}`,
      path: '/offset-management/tracking-record/manage',
    },
  ];

  const JURISDICTION_MANAGEMENT_LINKS = useMemo(
    () => [
      {
        id: 1,
        name: `${t(t1, 'JURISDICTION_DETAILS')}`,
        html:
          (isJSA && isInProposalForJSA(requestStatus?.name)) || (isAuthority && isInProposalForAuthority(requestStatus?.name)) ? (
            <>
              <ErrorIcon sx={{ color: '#CA2C1C', fontSize: '20px', ml: 10, mb: -0.5 }} />
              <span style={{ marginLeft: '4px', color: '#CA2C1C' }}>{t(t1, 'PENDING_APPROVAL')}</span>
            </>
          ) : null,
        path: `/manage-jurisdiction-details/${selectedJurisdictionId}`,
      },
      {
        id: 2,
        name: `${t(t1, 'CONFIGURATION_SETTINGS')}`,
        path: '/settings',
      },
      {
        id: 3,
        name: `${t(t1, 'CONTENT_MANAGEMENT_SYSTEM')}`,
        path: '/content-management',
      },
    ],
    [isJSA, requestStatus?.name, selectedJurisdictionId],
  );

  const formatUTC = (dateInt, addOffset = false) => {
    const date = !dateInt || dateInt.length < 1 ? new Date() : new Date(dateInt);
    if (typeof dateInt === 'string') return date;

    const offset = addOffset ? date.getTimezoneOffset() : -date.getTimezoneOffset();
    const offsetDate = new Date();
    offsetDate.setTime(date.getTime() + offset * 60000);
    return offsetDate;
  };
  const getSelectedJuridictionDetails = (jusriDetails, id) => {
    if (jusriDetails && jusriDetails.length > 0) {
      return jusriDetails?.find(item => item.ID === id);
    }
    return false;
  };

  const getJurisdictionByName = (name) => {
    const juri = JurisdictionsDataGlobal?.find(item => item.Name === name);
    return juri;
  };

  // get jurisdictionName
  const displayJurisdictionName = () => {
    if (selectedJurisdictionName) return selectedJurisdictionName;
    let name = '';
    name = Jurisdictions?.filter((jurisdiction) => jurisdiction?.OrgID === localStorage.getItem('jurisdiction'))?.[0]?.OrgName;
    if (!name) {
      name = jurisdictionDetails?.filter((jurisdiction) => jurisdiction?.dynamoId === localStorage.getItem('jurisdiction'))?.[0]?.Name;
    }
    return name;
  };

  return {
    validateQuantity,
    OFFSET_LINKS,
    JURISDICTION_MANAGEMENT_LINKS,
    validateTransferIds,
    formatUTC,
    getSelectedJuridictionDetails,
    getJurisdictionByName,
    displayJurisdictionName,
  };
};

export default useCommonFunc;
