import Grid from '@mui/material/Grid';
import { useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { FormCard } from 'components/FormCard';
import { MUITableGrid, StyledCellContent } from 'components/MUITableGrid';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getRepresentativeStatusHistory } from 'store/entityApplicationSlice';
import {
  formatDate,
  getCurrentPageName,
  isEmpty,
  isRolesAssigned,
  t,
  USER_ROLES,
  USER_ROLE_TYPES,
  translateRows,
  ENTITY_APPLICTION_STATUS_NAMES,
  getFilterForColumn,
  removeTimeZone,
  formatDateUpdated,
} from 'utils/constants';
import { useParams } from 'react-router-dom';
import Style from './AddRepresentative.style.js';

export default function RepresentativeStatusHistorySection(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { RepresentativeStatusHistory, EntitiesData } = useSelector(
    ({ entityApplication }) => entityApplication,
  );
  const dispatch = useDispatch();
  const { id } = useParams();

  const RepresentativeStatusHistoryRecords = RepresentativeStatusHistory.data ? setFilterSearchData(RepresentativeStatusHistory.data) : [];
  const [tableRepresentativeStatusHistoryRow, setTableRepresentativeStatusHistoryRow] = useState(RepresentativeStatusHistoryRecords);
  const { isIndividualUser, isJurisdictionRegistrarUser, isJurisdictionUser } = useMemo(
    () => ({
      isIndividualUser: isRolesAssigned(userDetails, [USER_ROLE_TYPES.INDIVIDUAL_USER]),
      isJurisdictionRegistrarUser: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.REGISTRAR],
      ),
      isJurisdictionUser: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
      ),
    }),
    [userDetails],
  );

  const representativeStatusForColumn = getFilterForColumn(RepresentativeStatusHistoryRecords, t, t1, ['status', 'role']);

  let tableRepresentativeStatusHistoryColumns = [
    {
      id: 1,
      field: 'name',
      headerName: t(t1, 'NAME'),
      flex: 2,
    },
    {
      id: 1,
      field: 'role',
      headerName: t(t1, 'ROLE'),
      flex: 2,
      // minWidth: 120,
      renderCell: (params) => {
        return <StyledCellContent>{params?.row?.role ? t(t1, params?.row?.role) : ''}</StyledCellContent>;
      },
      type: 'singleSelect',
      valueOptions: representativeStatusForColumn?.role,
    },
    {
      id: 1,
      field: 'status',
      headerName: t(t1, 'STATUS'),
      flex: 1,
      valueFormatter: ({ value }) => {
        return t(t1, value);
      },
      renderCell: (params) => {
        return <StyledCellContent>{params?.row?.status ? t(t1, params?.row?.status) : ''}</StyledCellContent>;
      },
      type: 'singleSelect',
      valueOptions: representativeStatusForColumn?.status,
    },
    {
      id: 1,
      field: 'updateDate',
      headerName: `${t(t1, 'UPDATE_DATE_COLUMN_HEADER')}`,
      flex: 2,
      valueGetter: ({ value }) => {
        return value ? `${removeTimeZone(formatDateUpdated(value))}` : `${t('NA')}`;
      },
      renderCell: (params) => {
        return <span>{removeTimeZone(formatDateUpdated(params?.row?.updateDate))}</span>;
      },
    },
    {
      id: 1,
      field: 'retirementDate',
      headerName: t(t1, 'RETIRE_DATE_COLUMN_HEADER'),
      flex: 1,
      valueGetter: ({ value }) => {
        return value ? `${removeTimeZone(formatDateUpdated(value))}` : `${t('NA')}`;
      },
      renderCell: (params) => {
        return <StyledCellContent>{removeTimeZone(formatDateUpdated(params?.row?.retireDate)) ? removeTimeZone(formatDateUpdated(params?.row?.retireDate)) : 'N/A'}</StyledCellContent>;
      },
    },
    {
      id: 1,
      field: 'updatedBy',
      headerName: t(t1, 'UPDATED_BY'),
      flex: 1,
      renderCell: (params) => {
        return <span>{params?.row?.updatedBy ? t(t1, params?.row?.updatedBy) : ''}</span>;
      },
    },
  ];

  if (isJurisdictionRegistrarUser) {
    tableRepresentativeStatusHistoryColumns = tableRepresentativeStatusHistoryColumns.filter(item => item.field !== 'is_allowed_access');
  }
  if (isRolesAssigned(userDetails, [USER_ROLE_TYPES.INDIVIDUAL_USER])) {
    tableRepresentativeStatusHistoryColumns = tableRepresentativeStatusHistoryColumns.filter(item => item.field !== 'updatedBy');
  }
  useEffect(() => {
    setTableRepresentativeStatusHistoryRow(setFilterSearchData(RepresentativeStatusHistory?.data));
  }, [RepresentativeStatusHistory?.data]);


  function setFilterSearchData(RepresentativeStatusHistory) {
    const userRows = [];
    RepresentativeStatusHistory?.forEach((item, index) => {
      userRows.push({
        ...item,
        index,
        update_date: item?.representative?.designationDate,
        retire_date: item?.updatedAt,
      });
    });

    return userRows;
  }

  useEffect(() => {
    let pageDetails = {};

    if (isIndividualUser) {
      pageDetails = { componentName: 'userRepresentativeAssignmentHistory', pageName: 'Page_EntityProfile' };
    }

    if (isJurisdictionRegistrarUser) {
      pageDetails = { componentName: 'representativeStatusHistory', pageName: 'Page_EntityProfile' };
    }

    if (isJurisdictionUser) {
      pageDetails = { componentName: 'representativeStatusHistory', pageName: 'Page_EntityProfile' };
    }

    dispatch(getRepresentativeStatusHistory(id, pageDetails));

  }, [isJurisdictionRegistrarUser]);

  return (
    <div className="representative-history-viewonly">
      <FormCard
        customClass={'customFormCard'}
        customeStyle={!isJurisdictionRegistrarUser && (EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.PENDING || ENTITY_APPLICTION_STATUS_NAMES.REVISIONS_REQUESTED) ? Style.styleForPendingandRevision : Style.formCardPadding}
        title={(t('REPRESENTATIVE_STATUS_HISTORY') || 'Representative Status History')}
        indicateRequired={false}
        // stepNumber={3}
      >
        <Typography
          variant="h2"
          component="div"
          sx={{ fontSize: 16 }}
        >
          {' '}
          {t('REPRESENTATIVE_STATUS_HISTORY')}</Typography>
        <Grid container spacing={2} sx={{ mt: 0, mb: isJurisdictionRegistrarUser ? 0 : 2.5 }}>
          <Grid item sm={12}>

            <MUITableGrid
              isExport
              isSearch
              isSettings
              getRowClassName={(params) => {
                return !isEmpty(params?.row?.proposeChanges) && 'PROPOSED';
              }
              }
              rows={translateRows([...tableRepresentativeStatusHistoryRow], ['role', 'status'], t, t1)}
              columns={tableRepresentativeStatusHistoryColumns}
              page={1}
              customTableStyle={Style.tableStyle}
              // initialSortedFields={[
              //   { field: "updateDate", sort: "desc" },
              // ]}
              fileName={t(t1, 'REPRESENTATIVE_STATUS_HISTORY', 'retirementDate', 'updateDate')}
            />
          </Grid>
        </Grid>
      </FormCard>
    </div>
  );
}
