const Style = {
  spacing: {
    padding: '30px 0px',
    '& h4': {
      background: '#4d8da4',
      padding: '12px 25px',
      color: '#fff',
      fontSize: '16px',
      marginBottom: '0px',
    },
    '& .paneldetail': {
      marginLeft: '40px',
      '& .custompanel': {
        display: 'flex',
        background: '#e6eff2',
        marginBottom: '0px',
        '& .MuiAccordionSummary-content': {
          order: '2',
        },
        '& .MuiSvgIcon-root': {
          fill: '#4d8da4',
          width: '1.5em',
          height: '1.5em',
        },
      },
      '& .MuiPaper-elevation': {
        margin: '0px',
      },
    },
  },
  TableGrid: {
    '& .MuiDataGrid-columnHeaders': {
      background: '#e6eff2',
      fontSize: '14px',
      fontWeight: 'bold',
      textAlign: 'center',
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        width: '100%',
        display: 'block',
        '& .MuiDataGrid-columnHeaderTitleContainerContent': {
          width: '100%',
          display: 'block',
        },
      },
      '& .MuiDataGrid-columnSeparator': {
        height: '100%',
      },
    },
    '& .MuiDataGrid-row': {
      width: '100%',
      textAlign: 'center',
      '&:nth-child(odd)': {
        background: '#fff',
      },
      '&:nth-child(even)': {
        background: '#f2f2f2',
      },
    },
    '& .MuiDataGrid-cell': {
      borderRight: '1px solid #ccc',
      borderBottom: '1px solid #ccc',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      overflow: 'visible !important',
      whiteSpace: 'unset !important',
      '& .custom-popup-links': {
        position: 'absolute',
        right: 80,
        top: 0,
        zIndex: 1,
      },
      '&:focus': { outline: 'none' },
    },
  },
  ListNavigationBox: {
    background: '#fff',
    padding: '10px',
    width: '260px',
    maxWidth: '260px',
    border: '1px solid #ccc',
    '&.MuiList-root a': {
      color: '#4d8da4',
      fontSize: '14px',
      textDecoration: 'none',
      textTransform: 'uppercase',
      textAlign: 'center',
      display: 'block',
      padding: '6px 10px',
      borderRadius: '8px',
      wordBreak: 'break-word',
      '&:hover': {
        background: '#4d8da4',
        color: '#fff',
      },
    },
  },
  RadioGroupStyle: {
    display: 'inline-block',
    margin: 0,
    padding: 0,
    fontSize: '14px !important',
    '& .MuiCheckbox-root': { margin: 0, padding: 0 },
    '& .MuiFormControlLabel-root': {
      height: '19px',
      '& .MuiButtonBase-root': {
        '& .MuiSvgIcon-root': {
          width: '14px',
          height: '14px',
        },
      },
      '& .MuiTypography-root': {
        fontSize: '14px !important',
      },
    },
  },
  ClickLinkCenter: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& a': {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      verticalAlign: 'center',
    },
  },
};
export default Style;
