import { FormControlLabel, styled, Typography } from '@mui/material';

export const StyledRequiredAstrik = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.common.red,
  margin: theme.spacing(0, 0.5, 0, 0.5),
  fontWeight: 700,
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.textColor,
  fontWeight: 700,
  whiteSpace: 'normal',
  lineHeight: '1.4375em',
  fontSize: '0.875rem',
}));
