const Style = {
  spacing: {
    padding: '30px 0px',
    '& .titalwithbtn': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItem: 'center',
      paddingBottom: '10px',
      '& h3': {
        marginBottom: '0px',
        fontSize: '36px',
        fontWeight: '300',
        color: '#333',
        '@media (max-width: 1023px)': {
          fontSize: '2.5rem !important',
        },
      },
    },
    '& h6': {
      fontSize: '24px',
      fontWeight: '300',
      marginBottom: '20px',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      margin: '0px',
    },
    '& .MuiTypography-label': {
      fontSize: '14px',
      color: '#333',
    },
    '& .MuiFormControlLabel-root': {
      '& .MuiCheckbox-root': {
        padding: '4px',
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '14px',
        color: '#333',
        fontWeight: '300',
      },
      '& .MuiSvgIcon-root': {
        height: '0.8em',
        width: '0.8em',
      },
    },
    '& .bottom-btn': {
      display: 'flex',
      justifyContent: 'flex-end',
      '@media (max-width: 1100px)': {
        marginTop: '30px',
      },
    },
    '& h4': {
      background: '#4d8da4',
      padding: '12px 25px',
      color: '#fff',
      fontSize: '16px',
      marginBottom: '0px',
      // marginTop: "40px",
    },
    '& .managementlink': {
      padding: '0px 0px 0px 40px',
      '& .MuiListItem-root': {
        padding: '10px 15px',
        background: '#e6eff2',
        borderBottom: '1px solid #4d8da4',
        '& a': {
          color: '#333333',
          fontSize: '16px',
          outline: 'none',
          textDecoration: 'none',
        },
      },
    },
    '& .select-box-main': {
      '&.select-box-main': {
        '& .MuiTypography-label': {
          fontWeight: '400',
        },
      },
      '& .MuiTypography-label': {
        marginBottom: '8px',
        display: 'block',
      },
      '& .MuiBox-root': {
        fontSize: '14px',
      },
      '& .MuiFormControl-root': {
        margin: 0,
      },
      '& .selectbox-custom': {
        fontSize: '14px',
        color: 'common.textColor',
        marginBottom: '35px',
        width: '370px',
        '&.adjust-spacing': {
          marginBottom: '20px',
        },
        '@media (max-width: 1440px)': {
          width: '370px',
        },
        '@media (max-width: 1300px)': {
          width: '100%',
        },
        '& .MuiSelect-select': {
          padding: '2.5px 35px 0px 5px',
          border: '1px solid inherit',
          display: 'flex',
          whiteSpace: 'normal',
          alignItems: 'center',
        },
        '& .MuiButtonBase-root': {
          margin: 0,
          padding: 0,
        },
        '& .MuiListItemText-root': {
          paddingLeft: '10px',
          fontSize: '10px',
          margin: '0',
        },
        '& .MuiTypography-root': {
          fontSize: '14px',
        },
      },
    },
    '& .MuiTextField-root': {
      width: '370px',
      fontSize: '13px',
      color: '#999',
      '@media (max-width: 1440px)': {
        width: '370px',
      },
      '@media (max-width: 1300px)': {
        width: '100%',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
        padding: '2.5px 5px',
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      color: '#333',
      fontWeight: '700',
      marginBottom: '8px',
    },
    '& .custom-text-right': {
      float: 'right',
    },
    '& .custom-padding': {
      paddingLeft: '22px',
    },
    '& .custom-tital': {
      '& h5': {
        fontSize: '24px',
        color: '#000',
        fontWeight: '300',
        marginBottom: '20px',
      },
    },
    '& .box-main': {
      display: 'flex',
      flexDirection: 'column',
      '& .MuiRadio-root': {
        padding: '0px 4px 0px 0px',
        marginLeft: '20px',
      },
      '& .MuiTypography-label': {
        marginBottom: '8px',
        fontSize: '14px',
        color: '#333',
        fontWeight: '600',
      },
    },
    '& .box-main-column': {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '20px',
      '& .MuiTypography-p': {
        fontSize: '14px',
        color: '#333',
        fontWeight: '300',
      },
      '& .MuiTypography-label': {
        marginBottom: '8px',
        fontSize: '14px',
        color: '#333',
        fontWeight: '600',
      },
    },
    '& .radio-table': {
      paddingLeft: '40px',
      '& .MuiTableHead-root': {
        '& .MuiTableCell-root': {
          color: '#333',
          fontSize: '14px',
          fontWeight: '600',
          padding: '3px 16px',
          width: '120px',
          border: '1px solid #d7d7d7',
          background: ' #e6eff2',
          borderBottom: 'none',
          lineHeight: '20px',
        },
        '& .control-head': {
          width: '70%',
        },
      },
      '& .MuiTableCell-root': {
        color: '#333',
        fontSize: '14px',
        border: '1px solid #d7d7d7',
        background: '#fff',
        '& .MuiRadio-root': {
          padding: '2.5px 5px',
        },
      },
    },
    '& .export-link': {
      margin: '20px 0px 0px 40px',
      color: '#4d8da4',
      fontSize: '14px',
      textDecoration: 'none',
      display: 'block',
    },
    '& .paneldetail': {
      '& .custompanel': {
        display: 'flex',
        background: '#4d8da4',
        marginBottom: '0px',
        fontSize: '16px',
        color: '#fff',
        '& .MuiAccordionSummary-content': {
          order: '2',
          alignItems: 'center',
        },
        '& .MuiSvgIcon-root': {
          fill: '#fff',
          width: '1.5em',
          height: '1.5em',
        },
      },
      '& .MuiPaper-elevation': {
        margin: '0px',
      },
      '& .MuiAccordionDetails-root': {
        padding: '0px 0px 0px 0px',
        background: '#f2f2f2',
      },
    },
    '& .MuiPaper-root': {
      marginBottom: 0,
    },
  },
  popupStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '1024px',
    bgcolor: 'background.paper',
    boxShadow: 2,
    borderRadius: '8px',
    padding: '20px',
    '& .MuiPaper-root': {
      marginBottom: 0,
    },
  },
  FontWeight: {
    '&.MuiInputLabel-root': {
      fontWeight: '400 !important',
    },
  },
  tableGridStyle: {
    '& .MuiDataGrid-row': {
      background: '#fff',
    },
  },
  tabStyling: {
    width: '100%',
    pb: 0,
    '& #simple-tabpanel-0': {
      '& .MuiBox-root': {
        mb: 0,
        pb: 0,
        pt: '20px',
        '& .MuiInputBase-root.MuiOutlinedInput-root': {
          height: '44px',
          width: '391px',
        },
      },
    },
    '& #simple-tabpanel-1': {
      '& .MuiBox-root': {
        mb: 0,
        pb: 0,
        pt: '20px',
        '& .MuiInputBase-root.MuiOutlinedInput-root': {
          height: '40px',
          width: '493px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            borderBottom: '2px solid #4d8da4',
            borderRadius: '0px',
          },
        },
        '& .MuiFormHelperText-root': {
          display: 'none',
        },
      },
    },
  },
};
export default Style;
