import { Loadable } from 'components/Loadable';
import RoleBasedGuard from 'guards/RoleBasedGuard';
import { lazy } from 'react';

export const URL_TRUE_UP = 'true-ups';

const TrueUpList = Loadable(
  lazy(() =>
    import('pages/TrueUps').then((module) => ({
      default: module.TrueUpList,
    })),
  ),
);

const TrueUpDetails = Loadable(
  lazy(() =>
    import('pages/TrueUps').then((module) => ({
      default: module.TrueUpDetails,
    })),
  ),
);

const BatchTrueUpList = Loadable(
  lazy(() =>
    import('pages/TrueUps').then((module) => ({
      default: module.BatchTrueUpList,
    })),
  ),
);

const CreateBatchTrueUp = Loadable(
  lazy(() =>
    import('pages/TrueUps').then((module) => ({
      default: module.CreateBatchTrueUp,
    })),
  ),
);

const BatchTrueUpDetails = Loadable(
  lazy(() =>
    import('pages/TrueUps').then((module) => ({
      default: module.BatchTrueUpDetails,
    })),
  ),
);

export const ManageTrueUpsRoutes = {
  path: `${URL_TRUE_UP}`,
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={''}>
          <TrueUpList />
        </RoleBasedGuard>
      ),
    },
    {
      path: ':id/batch/:batchId',
      element: (
        <RoleBasedGuard privId={''}>
          <TrueUpDetails />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'batches',
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard privId={''}>
              <BatchTrueUpList />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'create',
          element: (
            <RoleBasedGuard privId={''}>
              <CreateBatchTrueUp />
            </RoleBasedGuard>
          ),
        },
        {
          path: ':id',
          element: (
            <RoleBasedGuard privId={''}>
              <BatchTrueUpDetails />
            </RoleBasedGuard>
          ),
        },
        {
          path: ':id/propose',
          element: (
            <RoleBasedGuard privId={''}>
              <BatchTrueUpDetails isProposalScreen />
            </RoleBasedGuard>
          ),
        },
      ],
    },
  ],
};
