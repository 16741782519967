import { createSlice } from '@reduxjs/toolkit';
import { APIS } from 'utils/constants';
import { fetcherGet, fetcherPatch, fetcherPost } from 'utils/fetcher';
import { transformBatchData } from 'pages/Emissions/constants';
import { getJurisdictionUUID } from 'utils/support';
import { transformProposedBatchTrueUps } from 'pages/Approvals/support';
import { BATCH_TRUE_UP_STATUSES } from 'pages/TrueUps/constants';
import { setLoader } from './commonSlice';

const initialState = {
  batches: [],
  batch: {},
  batchViewMode: true,
  selectedTrueUpRecordIds: [],
  batchTrueUpRecords: { isLoading: false, data: [] },
  trueUpsBatchHistoryList: { isLoading: false, data: [] },
  trueUpsBatch: { isLoading: false, data: [] },
  trueUpsList: { isLoading: false, data: [] },
  trueUpsTotalList: { isLoading: false, data: [] },
  trueUpInfo: { isLoading: false, data: [] },
  fileUploadErrors: [],
  trueUpBatches: [],
  isEditEnabled: {
    informationCard: false,
    editableGrid: false,
    facilityForm: false,
  },
};

const TrueUpsSlice = createSlice({
  name: 'true-ups',
  initialState,
  reducers: {
    setBatches: (state, action) => {
      state.batches = action.payload;
    },
    setBatch: (state, action) => {
      state.batch = action.payload;
    },
    setBatchViewMode: (state, action) => {
      state.batchViewMode = action.payload;
    },
    setBatchTrueUpRecords: (state, action) => {
      state.batchTrueUpRecords = action.payload;
    },
    setSelectedTrueUpRecordIds: (state, action) => {
      state.selectedTrueUpRecordIds = action.payload;
    },
    setTrueUpsBatchHistoryList: (state, action) => {
      state.trueUpsBatchHistoryList = action.payload;
    },
    setTrueUpsBatch: (state, action) => {
      state.trueUpsBatch = action.payload;
    },
    setTrueUpsList: (state, action) => {
      state.trueUpsList = action.payload;
    },
    setTrueUpsTotalList: (state, action) => {
      state.trueUpsTotalList = action.payload;
    },
    setTrueUpInfo: (state, action) => {
      state.trueUpInfo = action.payload;
    },
    setFileUploadErrors: (state, action) => {
      state.fileUploadErrors = action.payload;
    },
    setTrueUpBatches: (state, action) => {
      state.trueUpBatches = action.payload;
    },
    setIsEditEnabled: (state, action) => {
      const { key, value } = action.payload;
      state.isEditEnabled[key] = value;
    },
  },
});

export const {
  setBatches,
  setBatch,
  setBatchViewMode,
  setBatchTrueUpRecords,
  setSelectedTrueUpRecordIds,
  setTrueUpsBatchHistoryList,
  setTrueUpsBatch,
  setTrueUpsList,
  setTrueUpsTotalList,
  setTrueUpInfo,
  setFileUploadErrors,
  setTrueUpBatches,
  setIsEditEnabled,
} = TrueUpsSlice.actions;

export default TrueUpsSlice.reducer;

// get batch trueUp history
export function getTrueUpsBatchHistoryAPI(jurisdictionId, batchId) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setTrueUpsBatchHistoryList({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(
        `${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/batches/${batchId}/history`,
      );
      dispatch(
        setTrueUpsBatchHistoryList({
          isLoading: false,
          data: response?.data,
        }),
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setTrueUpsBatchHistoryList({ isLoading: false, data: [] }));
    } finally {
      dispatch(setLoader(false));
    }
  };
}

// get trueUp records for a particular jurisdiction and batch id, batch id optional
// when batch id is null, gives all emission records for a jurisdiction
export function getTrueUpsListAPI(jurisdictionId, batchId) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setTrueUpsList({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/batches/${batchId}/trueUpRecords`);
      dispatch(
        setTrueUpsList({
          isLoading: false,
          data: response?.data,
        }),
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setTrueUpsList({ isLoading: false, data: [] }));
    }
  };
}

export function getTrueUpsTotalListAPI(jurisdictionId) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setTrueUpsTotalList({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/trueUpRecords`);
      dispatch(
        setTrueUpsTotalList({
          isLoading: false,
          data: response?.data,
        }),
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setTrueUpsTotalList({ isLoading: false, data: [] }));
    }
  };
}

// get batch details by id for a jurisdiction
export function getBatchDetailsById(batchId) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem('jurisdictionID');
    dispatch(setLoader(true));
    dispatch(setBatch({}));
    const api = `${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/batches/${batchId}`;
    return fetcherGet(api)
      .then((response) => {
        dispatch(setBatch(transformBatchData(response)?.data));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

// get all batches of a jurisdiction
export function getBatches() {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem('jurisdictionID');
    dispatch(setLoader(true));
    dispatch(setBatches([]));
    const api = `${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/batches`;
    return fetcherGet(api)
      .then((response) => {
        dispatch(setBatches(transformBatchData(response)?.data));
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };
}

// get proposed true-up batches
export function getProposedTrueUpBatches() {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem('jurisdictionID');
    dispatch(setLoader(true));
    try {
      const response = await fetcherGet(`${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/batches`);
      const proposedRecords = transformProposedBatchTrueUps(response.data)?.filter(
        (item) => item.status === BATCH_TRUE_UP_STATUSES.PROPOSED,
      );
      dispatch(setTrueUpBatches(proposedRecords));
    } finally {
      dispatch(setLoader(false));
    }
  };
}

// update batch name and comment
export function updateBatchInformation({ id, ...payload }) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem('jurisdictionID');
    dispatch(setLoader(true));
    try {
      return await fetcherPatch(`${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/batches/${id}`, {
        ...payload,
      });
    } finally {
      dispatch(setLoader(false));
    }
  };
}

// cancel batch
export function cancelBatch(batchId) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem('jurisdictionID');
    dispatch(setLoader(true));
    return fetcherPatch(`${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/batches/${batchId}/cancel`).finally(
      () => {
        dispatch(setLoader(false));
      },
    );
  };
}

// create batch for trueUp
export function saveBatchTrueUp(payload) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem('jurisdictionID');
    dispatch(setLoader(true));
    try {
      return await fetcherPost(`${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/batches`, {
        ...payload,
      });
    } finally {
      dispatch(setLoader(false));
    }
  };
}

// propose batch
export function proposeBatch(batchId) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem('jurisdictionID');
    dispatch(setLoader(true));
    return fetcherPatch(`${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/batches/${batchId}/propose`).finally(
      () => {
        dispatch(setLoader(false));
      },
    );
  };
}

// upload csv file to bucket
export async function uploadFileToBucket({ formData, fileName, batchId, signal }) {
  const jurisdictionId = localStorage.getItem('jurisdictionID');
  const response = await fetcherPost(
    `${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/batches/${batchId}/uploadFile`,
    formData,
    {
      'File-Name': fileName,
      'Content-Type': undefined,
    },
    signal,
  );
  return response;
}

// upload excel file to bucket
export async function uploadFileToBucketExcel({ file, fileName, batchId, signal }) {
  return new Promise((resolve, reject) => {
    const jurisdictionId = localStorage.getItem('jurisdictionID');
    const reader = new FileReader();
    reader.onload = async (event) => {
      const base64Data = event.target.result.split(',')[1]; // Remove the data URL prefix
      try {
        const response = await fetcherPost(
          `${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/batches/${batchId}/uploadFile`,
          base64Data,
          {
            'File-Name': fileName,
          },
          signal,
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = (event) => {
      reject(event.target.error);
    };
    reader.readAsDataURL(file);
  });
}

// get antivirus status of uploaded file
export async function getFileStatus({ headerOptions, signal, batchId }) {
  const jurisdictionId = localStorage.getItem('jurisdictionID');
  const response = await fetcherGet(
    `${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/batches/${batchId}}/getStatus`,
    {},
    {
      ...headerOptions,
    },
    signal,
  );
  return response;
}

// process uploaded file
export function processUploadedFile(payload, batchId) {
  const jurisdictionId = localStorage.getItem('jurisdictionID');
  return fetcherPost(
    `${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/batches/${batchId}/trueUpRecords/processFile`,
    {
      ...payload,
    },
    {},
  );
}

// get true-up details
export function getTrueUpInfo({ batchId, id }) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setTrueUpInfo({ isLoading: true, data: [] }));
    try {
      const api = `${APIS.BATCH_TRUE_UPS}/jurisdiction/${getJurisdictionUUID()}/batches/${batchId}/trueUpRecords/${id}`;
      const response = await fetcherGet(api);
      dispatch(
        setTrueUpInfo({
          isLoading: false,
          data: response?.data,
        }),
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setTrueUpInfo({ isLoading: false, data: [] }));
    } finally {
      dispatch(setLoader(false));
    }
  };
}

// edit True Up Quantity
export function editTrueUpQuantity(batchId, id, payload, pgeDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    try {
      return await fetcherPatch(
        `${APIS.BATCH_TRUE_UPS}/jurisdiction/${getJurisdictionUUID()}/batches/${batchId}/trueUpRecords/${id}`,
        payload,
        pgeDetails,
      );
    } finally {
      dispatch(setLoader(false));
    }
  };
}

// approve batch
export function approveBatch(batchId, payload) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem('jurisdictionID');
    dispatch(setLoader(true));
    try {
      return await fetcherPatch(`${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/batches/${batchId}/approve`, {
        ...payload,
      });
    } finally {
      dispatch(setLoader(false));
    }
  };
}

// revision request batch
export function revisionRequestBatch(batchId, payload) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem('jurisdictionID');
    dispatch(setLoader(true));
    try {
      return await fetcherPatch(
        `${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/batches/${batchId}/revisionsRequest`,
        {
          ...payload,
        },
      );
    } finally {
      dispatch(setLoader(false));
    }
  };
}

// deny batch
export function denyBatch(batchId, payload) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem('jurisdictionID');
    dispatch(setLoader(true));
    try {
      return await fetcherPatch(`${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/batches/${batchId}/deny`, {
        ...payload,
      });
    } finally {
      dispatch(setLoader(false));
    }
  };
}

// add true-up records
export function addTrueUpRecords(batchId, payload) {
  return async (dispatch) => {
    const jurisdictionId = localStorage.getItem('jurisdictionID');
    dispatch(setLoader(true));
    try {
      return await fetcherPost(
        `${APIS.BATCH_TRUE_UPS}/jurisdiction/${jurisdictionId}/batches/${batchId}/trueUpRecords`,
        {
          facilityId: payload.facilityId,
          trueUpsQuantity: payload.trueUpQuantity,
          comment: payload.comment,
        },
      );
    } finally {
      dispatch(setLoader(false));
    }
  };
}

// remove trueUp quantity (individual and bulk)
export function removeTrueUpQuantity({ batchId, payload }) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    try {
      return await fetcherPatch(`${APIS.BATCH_TRUE_UPS}/jurisdiction/${getJurisdictionUUID()}/batches/${batchId}/trueUpRecords/delete`, {
        ...payload,
      });
    } finally {
      dispatch(setSelectedTrueUpRecordIds([]));
      dispatch(setLoader(false));
    }
  };
}  
