import React, { useEffect, useMemo } from 'react';
import { FormCard } from 'components';
import Grid from '@mui/material/Grid';
import { getCurrentPageName, t, formatNumber } from 'utils/constants';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPermissionObj } from 'utils/support';
import { getBudgetDetails } from '../../../store/manageJurisdictionBudgetSlice';
import {
  StyledDetailKey,
  StyledDetailValue,
  StyledEntryDetailContainer,
} from '../../AccountDetails/AccountDetails.style';
import { JURISDICTION_BUDGET_COMPONENTS_FLP } from '../constant';

function BudgetDetailsCard({ flp }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { budgetDetails } = useSelector(({ ManageJurisdictionBudget }) => ManageJurisdictionBudget);
  const { id: jurisdictionId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBudgetDetails(jurisdictionId));
  }, [dispatch, jurisdictionId]);
  const fieldAccess = useMemo(
    () =>
      getPermissionObj(
        flp?.fieldAccess,
        JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_DETAILS,
      ),
    [flp],
  );
  return (
    <FormCard
      title={t(t1, 'BUDGET_DETAILS')}
      indicateRequired={false}
      customeStyle={{ paddingBottom: '40px' }}
    >
      <Grid container>
        <Grid
          item
          sm={4}
        >
          <StyledEntryDetailContainer style={{ paddingBottom: '5px' }}>
            <StyledDetailKey>{t(t1, 'JURISDICTION')}</StyledDetailKey>
            <StyledDetailValue>
              {budgetDetails && fieldAccess?.jurisdictionName
                ? budgetDetails?.jurisdictionName
                : '-'}
            </StyledDetailValue>
          </StyledEntryDetailContainer>
        </Grid>
        <Grid
          item
          sm={4}
        >
          <StyledEntryDetailContainer style={{ paddingBottom: '5px' }}>
            <StyledDetailKey>{t(t1, 'RESERVE_BUDGET')}</StyledDetailKey>
            <StyledDetailValue>
              {budgetDetails && fieldAccess?.reserveBudget ? formatNumber(budgetDetails?.reserveBudget) : '-'}
            </StyledDetailValue>
          </StyledEntryDetailContainer>
        </Grid>
        <Grid
          item
          sm={4}
        >
          <StyledEntryDetailContainer style={{ paddingBottom: '5px' }}>
            <StyledDetailKey>{t(t1, 'RESERVE_ALLOWANCES_ISSUED')}</StyledDetailKey>
            <StyledDetailValue>
              {budgetDetails && fieldAccess?.reserveAllowanceIssued ? formatNumber(budgetDetails?.reserveAllowanceIssued) : '-'}
            </StyledDetailValue>
          </StyledEntryDetailContainer>
        </Grid>
        <Grid
          item
          sm={4}
        >
          <StyledEntryDetailContainer style={{ paddingBottom: '5px' }}>
            <StyledDetailKey>{t(t1, 'EARLY_REDUCTION_CREDITS_ISSUED')}</StyledDetailKey>
            <StyledDetailValue>
              {budgetDetails && fieldAccess?.earlyReductionCreditsIssued ? formatNumber(budgetDetails?.earlyReductionCreditsIssued) : '-'}
            </StyledDetailValue>
          </StyledEntryDetailContainer>
        </Grid>
      </Grid>
    </FormCard>
  );
}

export default BudgetDetailsCard;
