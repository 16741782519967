import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  body: {
    fontSize: '14px',
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 576,
      sm: 768,
      md: 991,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    common: {
      GrayColor: '#cccccc',
      pageBackground: '#f2f2f2',
      textGray: '#7F7F7F',
      textLightGray: '#AAAAAA',
      textColor: '#333333',
      blue: '#283459',
      black: '#000000',
      white: '#ffffff',
      red: '#CA2C1C',
      green: '#70b603',
      lightGreen: '#EBF0D8',
      lightRed: '#FAE6E6',
      warning: '#4d8da4',
      red04: 'rgba(202, 44, 28, 0.4)',
      warning04: 'rgba(77, 141, 164, 0.4)',
      logoGreen: '#057f48',
      borderColor: '#d7d7d7',
      borderButtonColor: '#C7C8CA',
    },
    primary: {
      light: '#4D8DA4',
      main: '#283459',
      dark: '#202a47',
      contrastText: '#ffffff',
    },
    error: {
      main: '#CA2C1C',
    },
    text: {
      primary: '#333333',
    },
  },

  typography: {
    fontFamily: 'Open Sans',
    fontWeight: '400',
    fontWeightLight: '300',
    fontWeightRegular: '400',
    fontWeightMedium: '500',
    fontWeightBold: '700',

    h1: {
      fontFamily: 'Open Sans',
      fontWeight: '300',
      fontSize: '2.25rem',
    },
    h2: {
      fontFamily: 'Open Sans',
      fontWeight: '300',
      fontSize: '1.5rem',
    },
    h3: {
      fontFamily: 'Open Sans',
    },
    h4: {
      fontFamily: 'Open Sans',
    },
    h5: {
      fontFamily: 'Open Sans',
    },
    h6: {
      fontFamily: 'Open Sans',
    },
    subtitle1: {
      fontFamily: 'Open Sans',
      fontSize: '1.875rem',
    },
    subtitle2: {
      fontFamily: 'Open Sans',
      fontSize: '1.125rem',
      fontWeight: '400',
    },
    subtitle3: {
      fontFamily: 'Open Sans',
      fontSize: '1rem',
      fontWeight: '300',
    },
    body1: {
      fontFamily: 'Open Sans',
      fontSize: '1rem',
    },
    body2: {
      fontFamily: 'Open Sans',
      fontSize: '0.875rem',
    },
    button: {
      fontFamily: 'Open Sans',
    },
    caption: {
      fontFamily: 'Open Sans',
    },
    overline: {
      fontFamily: 'Open Sans',
    },
    paragraph: {
      fontFamily: 'Open Sans',
    },
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-toolbarContainer': {
            '& .MuiButtonBase-root': {
              fontSize: '15px',
              '& .MuiTypography-root ': {
                fontSize: '15px',
              },
            },
          },
          '& .MuiDataGrid-main': {
            // border: "1px solid red",
            // '&:focus': {
            //   border: "1px solid #ccc",
            // },
            // '&:focus-within': {
            //   border: "1px solid #ccc",
            // }
          },
          '& .MuiDataGrid-cell': {
            // border: "1px solid green",
            '&:focus': {
              // border: "1px solid red",
              // border: "none !important",
              // outline: "none !important"

            },
          },
          '& .MuiDataGrid-cell:focus-within': {
            // border: "none !important",
            // outline: "none !important"
          },
        },
      },
    },
  },
});

export default theme;
