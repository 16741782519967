import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ErrorIcon from '@mui/icons-material/Error';

import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, Grid, TextField, Tooltip, tooltipClasses } from '@mui/material';
import { t, months, getCurrentPageName } from 'utils/constants';
import CustomPopper from 'components/_CustomPopper/customPopper';
import styled from '@emotion/styled';
import Style from './GridSelectSearch.style';

export default function GridSelectSearch(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { control, getValues } = useFormContext();
  const {
    formSteps: { isEditField, activeStep },
    userProfileEdit,
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user; // Here we get fields permission like hidden, read Only or write
  const getUserDetails = useSelector(({ userHome }) => userHome.userDetails);

  const {
    name,
    type = 'text',
    label = '',
    rules,
    noValueText,
    options,
    stepNumber,
    InputProps,
    onChange,
    defaultValue,
    labelValue = '',
    gridMd = 4,
    disabled = false,
    className = '',
    isViewOnly = '',
    isAstricDisplay = false,
    isShowOld = false,
    RevisionDate = '',
    privId = '',
    customeStyle = '',
    isRequiredMsg = '',
  } = props;
  /* Conflict Resolution Candidate

    const d = RevisionDate ? new Date(RevisionDate) : new Date();
    const revisionDate = `${t(t1, "REVISION_MADE_ON")} ${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
   */

  const d = new Date(RevisionDate);
  const revisionDate =
    RevisionDate && `${t(t1, 'REVISION_MADE_ON')} ${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
  let readOnly = false;
  if (privId === '') {
    if (PrivAttributes?.[name]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[name]?.PrivRole === 'RO';
  } else {
    if (PrivAttributes?.[privId]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[privId]?.PrivRole === 'RO';
  }

  if (rules && rules?.required && label) {
    rules.required = `${t(t1, isRequiredMsg)}`;
  }

  const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        padding: '1px 7px',
        border: '1px solid #000',
        borderRadius: '0px',
      },
    }),
  );
  /* Conflict Resolution Candidate
    return (
      <Grid item md={gridMd} className={className} sx={{ ...customeStyle,"& .MuiOutlinedInput-input": { color:  ['',undefined].includes(getValues(`${name}`)) ? "#ccc !important": '#333' } }}>
   */

  const displayReadOnlyText = () => {
    switch (name) {
      case 'ResCountry':
      case 'MailCountry':
      case 'ResState':
      case 'MailState':
        return t(t1, getValues(name)) || 'N/A';
      default:
        return t(t1, getValues(name)) || 'N/A';
    }
  };

  return (
    <Grid item md={gridMd} className={className} sx={{ ...customeStyle }}>
      {label ? (
        <InputLabel htmlFor="customize-select" sx={Style.FieldLabel}>
          {label}
          {rules?.required || isAstricDisplay ? (
            <Typography
              variant="body2"
              sx={{
                display: 'inline-block',
                color: 'common.red',
                ml: 0.3,
                fontWeight: 700,
              }}
            >
              *
            </Typography>
          ) : null}
        </InputLabel>
      ) : null}
      {(!isEditField[stepNumber] && activeStep === 4) || isViewOnly || readOnly ? (
        <Typography variant="body2" sx={Style.valuetext}>
          {displayReadOnlyText()}
        </Typography>
      ) : (
        <Controller
          name={name}
          type={type}
          rules={rules}
          control={control}
          render={({ field: { onChange, onBlur, value, ...field }, fieldState }) => (
            <>
              <Autocomplete
                PopperComponent={CustomPopper}
                clearText=""
                closeText=""
                openText=""
                disabled={disabled}
                defaultValue={options[0]}
                getOptionDisabled={(option) => option?.value === ''}
                value={options?.find((item) => {
                  if (getValues(`${name}`)) {
                    return item.name === t(t1, getValues(`${name}`));
                  }
                  return options[0];
                })}
                id="country-select-demo"
                sx={Style.formField}
                options={options}
                onBlur={onBlur}
                className="fc-search"
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    error={Boolean(fieldState.error)}
                    {...params}
                    // label="Choose a country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                      placeholder: noValueText,
                    }}
                  />
                )}
                onChange={(_, data) => {
                  onChange(data?.value);
                  return data?.name;
                }}
                // onInputChange={(_,data) =>{
                //   onChange(data.value);
                //   return data.name;
                // }}
              />
              {/* {fieldState.isTouched && rules?.required && value === "" ? (
                <FormHelperText error>{`${label} ${t(t1, "IS_REQUIRED")}`}</FormHelperText>
              ) : null} */}
              {rules && (
                <FormHelperText error>
                  {fieldState?.error ? fieldState?.error?.message : ' '}
                </FormHelperText>
              )}
            </>
          )}
        />
      )}
      {isViewOnly && isShowOld && (
        <StyledTooltip
          title={
            <Typography color="black" sx={{ fontSize: '14px' }}>
              {revisionDate}
            </Typography>
          }
        >
          <Box mt={1 / 4} sx={Style.updatedFieldBox}>
            <ErrorIcon sx={{ color: '#4d8da4', fontSize: '20px', mx: 1 / 4 }} />
            <Box sx={Style.coloredBox}>
              <Typography
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {t(t1, isShowOld)}
              </Typography>
            </Box>
          </Box>
        </StyledTooltip>
      )}
    </Grid>
  );
}