import { Loadable } from 'components/Loadable';
import RoleBasedGuard from 'guards/RoleBasedGuard';
import { lazy } from 'react';

const ServiceProviderPage = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.ServiceProviderPage,
    })),
  ),
);

export const serviceProviderSelectionManagement = {
  path: '/service-provider-selection-and-management',
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={'priv_lnk_service_pro_selection_mgm_view'}>
          <ServiceProviderPage />
        </RoleBasedGuard>
      ),
    },
  ],
};
