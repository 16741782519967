const Style = {
  formCardPadding: { paddingBottom: '20px', minHeight: '181px', marginBottom: '20px' },
  formCardPaddingLg: { paddingBottom: '50px' },
  formCardMarginLg: { marginBottom: '20px' },
  otherCard: { paddingBottom: '20px' },
  btnSpacing: { paddingBottom: '40px !important', marginBottom: '20px', marginTop: '20px' },
  btnOutline: {
    background: '#fff',
    color: '#4d8da4',
    fontSize: '14px',
    border: 'solid 1px #c7c8ca',
    '&:hover': {
      background: '#fff',
      border: 'solid 1px #c7c8ca',
    },
    '&.Mui-disabled': {
      background: '#aaa',
      borderColor: '#aaa',
      color: '#fff',
      textFillColor: '#fff !important',
    },
  },
  btnPrimary: {
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#4d8da4',
    },
  },
  innerFormCard: {
    p: 0,
    backgroundColor: 'red !important',
    '& .MuiPaper-elevation2': {
      boxShadow: 'none !important',
    },
  },
  selectRadioComponent: {
    px: 0,
    mt: 2,
    '& .fc-textarea': {
      '& .Mui-disabled': {
        textarea: {
          background: 'rgb(242, 242, 242)',
        },
      },
    },
    '& .MuiFormControlLabel-root': {
      marginBottom: '4px',
    },
    '& .MuiFormControlLabel-root .MuiTypography-root': {
      fontSize: '0.875rem !important',
    },
  },
  flexDisplay: {
    display: 'flex',
  },
  customMarginforReferencebox: {
    marginTop: '20px',
  },
  customMarginforReferenceRadiobox: {
    marginBottom: '-22px !important',
    display: 'flex',
  },
  tableStyle: {
    // "& .MuiDataGrid-row": {
    //   border: "1px solid ",
    // },
    '& .MuiDataGrid-columnHeaderTitle': {
      textOverflow: 'clip',
      whiteSpace: 'nowrap',
    },
    '& .PROPOSED': {
      border: '1px solid #4d8da4',
    },
  },
  btnContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
    marginTop: '15px',
  },
  FieldLabel: {
    fontSize: '0.875rem',
    color: 'common.textColor',
    fontWeight: 700,
    mb: 0.5,
  },
  formField: {
    width: 1,
    color: 'common.textColor',
    '.MuiOutlinedInput-input': {
      py: 0.6,
      fontSize: '14px',
      '& .MuiTypography-root': {
        fontSize: '14px',
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: '0',
      borderColor: 'common.GrayColor',
    },
    '&.MuiOutlinedInput-root': {
      '&.MuiInputBase-root.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: '#333',
          borderWidth: 1,
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '4px',
    },
    '& .Mui-error': {
      marginLeft: 0,
    },
  },
  inputStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  checkBoxLabel: {
    '& .MuiTypography-root': {
      fontWeight: '500 !important',
      fontSize: '14px !important',
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: '#333 !important',
    },
    '& .Mui-checked': {
      color: '#283459 !important',
    },
  },
  checkBoxLabelBold: {
    '& .MuiTypography-root': {
      fontWeight: '700',
      fontSize: '14px',
    },
    '& .MuiCheckbox-root': {
      padding: '9px',
    },
  },
  QuickActionBox: {
    '& .MuiPopover-paper': {
      // border: "1px solid #4D8DA4",
      borderRadius: '4px',
      // boxShadow: "none !important",
      boxSizing: 'border-box',
      '& .MuiList-root': {
        background: '#ffffff',
        border: '1px solid #4d8da4',
        // padding: "2px 2px 2px 2px",
        padding: '6px !important',
        borderRadius: '4px',
        // borderRradius: 0,
        width: '251px',
      },
      '& .MuiButtonBase-root': {
        backgroundColor: '',
        // marginLeft: "10px",
        // marginRight: "10px",
        borderRadius: '4px',
        // minHeight: "52.4px",
        minHeight: '46.67px',
        fontFamily: 'Open Sans',
        justifyContent: 'center',
        color: '#4d8da4',
        fontSize: '14px',
        fontWeight: '400',
        margin: '0 0 4px',
        '&:hover': {
          backgroundColor: '#4d8da4',
          color: '#ffffff',
          fontWeight: '500',
        },
        '&:last-of-type': {
          marginBottom: '0px',
        },
      },

    },
  },
  SelectBox: {
    marginTop: '-22px',
  },
  paddingB20: {
    paddingBottom: '40px !important',
  },
  styleForPendingandRevision: {
    marginBottom: '0 !important',
    marginTop: '0 !important',
  },
};
// Adding media query here
Style.manageRoleLayoutGrid = {
  '@media screen and (max-width: 600px)': {
    display: 'contents',
    '.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4.css-1wuc594-MuiGrid-root': {
      paddingLeft: '1rem !important',
    },
  },
};

export default Style;
