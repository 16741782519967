import React from 'react';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';

export default function ButtonOutline({ children, styleSx = {}, handleClick, name, className = '', disabled }) {
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user;

  if (PrivAttributes?.[name]?.PrivRole === 'NA') {
    return null;
  }
  const readOnly = !disabled ? PrivAttributes?.[name]?.PrivRole === 'RO' : disabled; // This will set read only field


  return (
    <Button
      className={className}
      variant="outlined"
      disableElevation
      disabled={readOnly}
      onClick={handleClick}
      type="button"
      id={name}
      sx={{
        py: 1,
        /* Conflict Resolution Candidate
                minWidth: 155,
                color: "primary.main",
                borderColor: "primary.main",
                "&:hover": {
                  borderColor: "primary.main",
         */
        minWidth: 150,
        height: 40,
        color: '#4d8da4',
        background: '#fff',
        borderColor: '#C7C8CA',
        '&:hover': {
          borderColor: '#C7C8CA',
          background: '#fff',
        },
        ...styleSx,
      }}
    >
      {children}
    </Button>
  );
}
