/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect, useRef } from 'react';

export default function Loader({ loader }) {
  const loaderRef = useRef();
  useEffect(() => {
    if (loader) {
      loaderRef.current.focus();
    }
  }, [loader]);

  return (
    <Backdrop
      sx={{ color: '#fff !important', zIndex: (theme) => theme.zIndex.drawer + 999, '&:focus': { outline: 'none' } }}
      open={loader}
      onBlur={(e) => {
        e.target.focus();
      }}
      ref={loaderRef}
      tabIndex="0"
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
