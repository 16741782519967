// MUI Components
import { Box, List, ListItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// React hooks
import React, { useEffect, useRef, useState } from 'react';
// i18n hooks and object
// import i18n from "config/lang/i18n";
import { useTranslation } from 'react-i18next';
// Redux hooks
import { useDispatch, useSelector } from 'react-redux';
// React print page/component library
import { useReactToPrint } from 'react-to-print';

import { FileDownload } from 'assets/icons/FileDownload';
import { FormActions } from 'components/FormActions';
// MUI Custom Components
import { FormCard } from 'components/FormCard';
import { FormPrintStructure } from 'components/FormPrintStructure/FormPrintStructure';
import { Loader } from 'components/Loader';
// React Custom Hooks
import { useEffectOnce } from 'hooks/useEffectOnce';
// Redux slices
import { getConfigurations } from 'store/configSlice';
// Global constants
import {
  defaultORGID,
  displayParsedIconWithLink,
  getCurrentPageName,
  getFilterForms,
  isEmpty,
  scrollToTop,
  t,
  threeDummyFormsData,
} from 'utils/constants';

// Component Styling
import Style from './SubmitDocuments.style';

export default function SubmitDocuments(props) {
  const { t: t1, i18n } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const theme = useTheme();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // Redux state
  const { selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);
  const {
    filledData,
    submitted,
    userReferenceCode,
    formSteps: { activeStep },
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { config, defaultFileUrl } = useSelector(({ config }) => config);
  const { userDetails, referenceCode } = useSelector(({ userHome }) => userHome);
  const { loader } = useSelector(({ common }) => common);
  const userId = submitted?.[5] ? filledData?.[5]?.createUserID : '-';

  // React state
  const [filteredForms, setFilteredForms] = useState([]);
  const [sentenceWiseForms, setSentenceWiseForms] = useState([]);
  const [firstParaData, setFirstParaData] = useState([]);
  // Constants
  const sentenceFormsList = []; // to de-structure docFiles & push customized sentence list

  const topParagraph = [
    {
      isHide: true,
      text: t(t1, 'SUBMITTED_DOCUMENT_USER_ID'),
      boldText: userId,
    },
    {
      text: t(t1, 'SUBMITTED_DOCUMENT_USER_REFERENCE_CODE'),
      // boldText: userReferenceCode,
      boldText: referenceCode?.ReferenceCode,
    },
    {
      text: t(t1, 'SUBMITTED_DOCUMENT_KEEP_A_RECORD'),
    },
    {
      text: t(t1, 'SUBMITTED_DOCUMENT_FORMS_MUST_BE_PRINTED'),
    },
  ]; // Starting paragraph with userId & reference code

  // Parent Props
  const { parentPageName = '', startingContent, submitedBy = '' } = props;

  useEffectOnce(() => {
    if (activeStep === 6) {
      scrollToTop();
    }
  }, []);

  useEffectOnce(() => {
    if (activeStep === 6) {
      scrollToTop();
    }
  }, []);

  useEffectOnce(() => {
    dispatch(getConfigurations(selectedJurisdiction || defaultORGID));
  }, [selectedJurisdiction]);

  const contactSupportEmail = config['notification-settings']?.contact_us_email?.AttributeValue;

  // Add label key into array to filter required form/url
  const formsLabelList = [...threeDummyFormsData];

  // Add label key into array to filter required form/url
  const userFormList = [
    t(t1, 'USER_REGISTRATION_CHECKLIST'),
    t(t1, 'USER_REGISTRATION_FORM'),
    t(t1, 'IDENTIFICATION_VERIFICATION_FORM'),
  ];

  // Add translation label into array to display sentence list in forms postfix
  const formStaticSentenceList = [
    'SUBMIT_ALL_REQUIRED_DOCUMENTS_AS_INDICATED_ON_THE',
    'PRINT_AND_SUBMIT_ORIGINAL_SIGNATURE_ON',
    'THIRD_FORM_SUBMIT_TXT',
  ];

  // Add translation label into array to display only sentence without form list
  const staticSentenceList = [
    t(t1, 'PRINTED_MATERIALS_MUST_HAVE_ORIGINAL_SIGNATURES'),
    t(t1, 'REGISTRATION_APPLICATION_MAY_REQUIRE'),
  ];

  // To set state of only FormsList
  useEffect(() => {
    // do not remove this code
    // const filterForms = defaultFileUrl?.data ? defaultFileUrl?.data?.filter((eachFile) =>
    //   formsLabelList?.includes(eachFile?.SK?.split("#")?.pop())
    // ) : [
    //   {
    //     EntityName: "URL",
    //     SK: "File#English#Page_DocFiles#SUBMITTED_DOCUMENT_TITLE_FIRST",
    //     NotifyUser: true,
    //     PK: "ORG#00101",
    //     LabelSaved: "",
    //     LabelPublished: "",
    //   },
    //   {
    //     EntityName: "URL",
    //     SK: "File#English#Page_DocFiles#SUBMITTED_DOCUMENT_TITLE_THIRD",
    //     NotifyUser: true,
    //     PK: "ORG#00101",
    //     LabelSaved: "",
    //     LabelPublished: "",
    //   }
    // ];

    // // To add Form-2 object for it's Label
    // const form2Obj = {
    //   EntityName: "URL",
    //   SK: "File#English#Page_DocFiles#SUBMITTED_DOCUMENT_TITLE_SECOND",
    //   NotifyUser: true,
    //   PK: "ORG#00101",
    //   LabelSaved: "",
    //   LabelPublished: "",
    // };

    // filterForms?.splice(1, 0, form2Obj);
    // setFilteredForms(filterForms);

    (async () => {
      const filteredForms = await getFilterForms(formsLabelList, defaultFileUrl);
      setFilteredForms(filteredForms);
    })();
  }, [defaultFileUrl]);

  // To set state of FormsList with sentence in postfix
  useEffect(() => {
    filteredForms?.map((eachFile, index) => {
      const formObj = { ...eachFile, Sentence: formStaticSentenceList?.[index] };
      sentenceFormsList?.push(formObj);
      return sentenceFormsList;
    });
    setSentenceWiseForms(sentenceFormsList);
  }, [filteredForms]);

  useEffect(() => {
    if (isEmpty(parentPageName)) {
      setFirstParaData(topParagraph);
    } else {
      // console.log(isEmpty(parentPageName), startingContent);
      setFirstParaData(startingContent);
    }
  }, [startingContent, referenceCode, t1]);

  // Ending paragraph (Footer)
  const bottomParagraph = [
    t(t1, 'SUBMITTED_DOCUMENT_YOU_MAY_USE_YOUR_LOG_IN_INFORMATION'),
    t(t1, 'SUBMITTED_DOCUMENT_SHOULD_YOU_HAVE_ANY_QUESTIONS'),
  ];

  return (
    <>
      {(firstParaData?.length > 0) && (firstParaData?.filter((e, i) => {
        return e?.text !== '';
      }).length > 2) && (filteredForms?.length > 0) && (sentenceWiseForms?.length > 0) ?
        <FormCard indicateRequired={false}>
          {firstParaData?.map((paragraph, index) => {
            return (
              <Typography sx={Style.SingleParagraph} key={index}>
                {paragraph.text}
                {paragraph.boldText && (
                  <Box component="span" sx={{ fontWeight: 600, ml: 0.5 }}>
                    {paragraph.boldText}
                  </Box>)}
              </Typography>
            );
          })}
          <Typography sx={{
            ' & ul': {
              paddingLeft: '44px',
              fontWeight: 600,
            },
          }}>{displayParsedIconWithLink(t1(['SUBMIT_DOC_TEXT', '']), handlePrint)}</Typography>
          {!isEmpty(submitedBy) && <Typography sx={Style.Submited}>{submitedBy}</Typography>}
          {bottomParagraph.map((paragraph, index) => (
            <Typography sx={Style.SingleParagraph} key={index}>
              {paragraph}
              {/* {console.log(paragraph)} */}
              {index === bottomParagraph.length - 1 && (
                <a
                  href={`mailto:${contactSupportEmail}`}
                  className={isEmpty(contactSupportEmail) && 'disabled'}
                  rel="noreferrer"
                  target="_blank"
                >
                  {t(t1, 'JURISDICTION_SUPPORT')}{' '}
                </a>
              )}
            </Typography>
          ))}
        </FormCard>
        : <Loader loader={loader} />
      }
      {isEmpty(parentPageName) && (
        <>
          <FormActions stepNumber={2} />
          {/* {false && (
            <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
              <Button
                sx={{ px: "28px", py: "10px" }}
                onClick={() => generateHTMLPDF("downloadContent")}
                defaultValue="Generate PDF"
                variant="contained"
              >
                Generate PDF
              </Button>
            </Box>
          )} */}
        </>
      )}
      <Typography sx={{ display: 'none' }}>
        <FormPrintStructure ref={componentRef} parentPageName={parentPageName} />
      </Typography>
    </>
  );
}
