import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DialogMessage, GridSelect, GridTextfield, CustomAutocomplete } from 'components';
import { CustomizeButton } from 'components/CustomizeButton';
import { FormCard } from 'components/FormCard';
import { FormCardAddFacility } from 'components/FormCardAddFacility';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { setDialogMessage, setLoader, setModelMessage, setIsFormEditable } from 'store/commonSlice';
import { setHideModal, setShowModal } from 'store/customModalSlice';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

// eslint-disable-next-line no-nested-ternary
import InfoIcon from '@mui/icons-material/Info';
import Checkbox from '@mui/material/Checkbox';
import PassphraseConfirmationDialog from 'pages/WiringInstructions/components/PassphraseConfirmationDialog';
import CustomeDialogMessage from 'components/CustomeDialogMessage/CustomeDialogMessage.js';
import { CustomizeToolTip } from 'components/CustomizeToolTip/CustomizeToolTip.js';
import { FormActions } from 'components/EntityFormActions';
import MUITableGrid from 'components/MUITableGrid/MUITableGrid.js';
import QuickLinksMenu from 'components/QuickLinksMenu/QuickLinksMenu.js';
import TextTruncate from 'components/TextTruncate/TextTruncate.js';
import { useEffectOnce } from 'hooks/useEffectOnce';
import _ from 'lodash';
import { StyledQuickAction } from 'pages/AccountDetails/AccountDetails.style.js';
import {
  checkGHGIDExists,
  getAlternateContact,
  getFacilityCountry,
  getNAICS,
  getStateProvince,
  removeFacilityById,
  retiredSingleFacility,
  retireFacilityById,
  saveFacility,
  saveProposeFacility,
  setFacilities, setFacilityAddMode, setFormValueOfStep,
  setNextStepSelect,
  setStateProvinceFacility,
  getEnityFacilityById,
  revertChanges,
  setDisabledFacilitySubmitBtn,
  resetSteps, setEntityType, setEntityID,
} from 'store/entityApplicationSlice';
import {
  checkingEmptySpace,
  validatePhoneNumber,
  COMPONENT_MODES,
  COMPONENT_NAMES,
  COUNTRY_KEY_NAMES,
  ENTITY_APPLICTION_STATUS_NAMES,
  FACILITY_STATUS,
  formatDate,
  getCurrentPageName, isEmpty,
  isRolesAssigned,
  JURISDICTIONS_NAMES,
  ENTITY_TYPE_NAMES,
  ShowInlineAddressAddFacility,
  numbersImportantDoNotAllowAllZero,
  otherSpecialCharNotAllowed,
  scrollToTop,
  someSpecialCharWithSpaceNotAllowed,
  specialCharNotAllowed,
  t,
  USER_ROLES, ShowInlineAddressFacility,
  USER_ROLE_TYPES,
  IndividualUser,
  validateComment,
  GHG_API_ERROR_KEYS,
  SopfDetailView,
  COMPONENT_NAME_FOR_ROLE,
  getFacilityStatusForFilterDropdown,
  validateCommentModule,
  COMMENT_MAX_CHARACTER_MODULE,
  verifyFirstName,
  verifyLastName,
  validatingPostalCode,
  verifyGHGNumber,
  verifyIdentificationNumberWithoutSpecialCharacters,
  removeTimeZone,
  formatDateUpdated,
  emailPatternValidation,
  lastNamePattern,
  firstnamePattern,
  validatePattern,
  jobtitleEmployerPattern,
  verifyCityOrRegion,
  verifyStreet1orStreet2,
} from 'utils/constants';
import Loader from 'components/Loader/Loader.js';
import UnsavedPrompt from '../../../../../components/UnsavedDialog';
import Style from './AddFacility.style.js';
import '../../ComponentTextTruncate.css';


export default function AddFacility(props) {
  const {
    displayHead = true,
    mode = COMPONENT_MODES.ADD,
    stepData = true,
    isRevisionRequested = false,
    handleEnableEdit,
    saveDisabled,
    editRowRef,
    isProposed = false,
    setCheckFacility = false,
    isEditAccess = true,
    enableSaveChange,
    CheckformInvalid,
    showIdStatus,
    hideDataGrid = true,
    proposedFacilityChange,
    handleSubmitChanges,
    editModeforReviewandSubmit = true,
    switchFacilityMode,
    showOnlyFacilityForm = false,
    externalShowFormTrigger = null,
    passPhraseFeature = false,
    showCancelPopup,
    showSavePopup,
    hideCard = false,
    usage = '',
    handleResetEdit = () => {
    },
    onRevisionRequested = false,
    isPending = false,
  } = props;

  const { user } = useSelector(({ auth }) => auth);
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { modalTitle, modalBodyMessage, showModal, modalConfirmButton } = useSelector(({ customModal }) => customModal);

  const {
    steps,
    FacilityCountry,
    StateProvinceFacility,
    NAICS,
    Facilities,
    FacilityAddMode,
    EntitiesData,
    selectedJurisdiction,
    EditAccess,
    AlternateContact,
    FacilitiesList,
    fieldPermissions,
    selectedEntity,
  } = useSelector(
    ({ entityApplication }) => entityApplication,
  );
  const { dialogMessage, loader } = useSelector(({ common }) => common);

  const { REVISIONS_REQUESTED, ACTIVE, CLOSED, APPLICATION_IN_PROGRESS, PENDING } = ENTITY_APPLICTION_STATUS_NAMES;
  const [isReadMore, setIsReadMore] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedRetireRow, setselectedRetireRow] = useState(null);

  const dispatch = useDispatch();
  const countryData = [];
  const stateProvinceData = [];
  const naicsData = [];
  const entityData = _.find(steps, (item) => item.key === 'SELECT_JURISDICTION').filledData;
  const { QUEBEC, NOVA_SCOTIA } = JURISDICTIONS_NAMES;
  const [showPhysicalRegion, setShowPhysicalRegion] = useState(false);
  const [NAICSCode, setNAICSCode] = useState();
  const [showMailingRegion, setShowMailingRegion] = useState(false);
  const [saveButton, setSaveButton] = useState(false);
  const [editingRow, setEditingRow] = useState({});
  const [physicalState, setPhysicalState] = useState([]);
  const [mailingState, setMailingState] = useState([]);
  const { id } = useParams();
  const EntityId = id;
  const [disableUseCheck, setDisableUseCheck] = useState(false);
  const [ghgexistingMessage] = useState('');
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [disableAllFields, setDisableAllFields] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [exitDialog, setExitDialog] = useState(false);
  const [disableGHGField, setDisableGHGField] = useState(false);
  const [showRetireConfirmation, setShowRetireConfirmation] = useState(false);
  const [showPassPhraseDialogue, setShowPassPhraseDialogue] = useState(false);
  const [validatingGHG, setValidatingGHG] = useState(false);
  const [previousGHGId, setPreviousGHGId] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [showAdd, setShowAdd] = useState(false);
  const [isExitInProgress, setIsExitInProgress] = useState(false);
  const navigate = useNavigate();
  const [customLoader, setCustomLoader] = useState(false);


  // To show passphrase add facility on Active.
  const [showPassphraseDialogForAddFacility, setShowPassphraseDialogForAddFacility] = useState(false);
  const [showPassphraseDialogForEditFacility, setShowPassphraseDialogForEditFacility] = useState(false);

  const { isIndividualUser, isJurisdictionRegistrarUser, isJurisdictionAdminUser, isJurySysAdmin } = useMemo(
    () => ({
      isIndividualUser: isRolesAssigned(userDetails, [USER_ROLE_TYPES.INDIVIDUAL_USER]),

      isJurisdictionRegistrarUser: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.REGISTRAR],
      ),
      isJurisdictionAdminUser: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.ADMIN],
      ),
      isJurySysAdmin: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.JURISDICTION_SYSTEM_ADMIN],
      ),
    }),
    [userDetails],
  );

  const resetData = (str) => {
    if (id) {
      dispatch(resetSteps());
      dispatch(setEntityType(''));
      dispatch(setEntityID(0));
      navigate('/start-entity-registration');
    }
  };

  const addMoreFacilityButton = () => {
    if (isIndividualUser && !showForm && !isRevisionRequested && usage !== COMPONENT_NAMES.REVIEW_AND_SUBMIT && !isProposed && EditAccess) {

      return <Button
        variant="contained"
        onClick={() => {
          setShowForm(true);
          switchFacilityMode?.(COMPONENT_MODES.ADD);
          methods.clearErrors();
        }}
        sx={{ width: 'auto', marginTop: '0' }}
        className="btn-primary"
      >
        {t(t1, 'ADD_FACILITY')}
      </Button>;
    }
  };
  const associateFacilityButton = () => {
    if (
      isRolesAssigned(userDetails, [USER_ROLE_TYPES.INDIVIDUAL_USER], [USER_ROLES.INDIVIDUAL_USER]) &&
      entityData?.Status?.Name === ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_IN_PROGRESS
    ) {
      return (<Button
        variant="contained"
        onClick={() => navigate(`/associate-retired-facility/${id}`)}
        sx={{ width: 'auto', marginTop: '0' }}
        className="btn-primary"
      >
        {t(t1, 'ASSOCIATE_FACILITY')}
      </Button>);
    }
    return null;
  };

  useEffect(() => {
    if (rows?.length !== 0 && !showOnlyFacilityForm && !isRevisionRequested && !isProposed) {
      setShowForm(false);
    }

  }, [rows]);

  useEffect(() => {
    if (rows?.length === 0 && selectedEntity === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [rows]);

  useEffect(() => {
    if (isRevisionRequested && stepData?.editMode) {
      setShowForm(true);
    }
  });

  useEffect(() => {
    if (editModeforReviewandSubmit) {
      setShowForm(true);
      switchFacilityMode?.(COMPONENT_MODES.ADD);
      methods.clearErrors();
    }
  }, [editModeforReviewandSubmit]);


  const pageDetail = { componentName: 'addedFacilities', pageName: 'Page_EntityProfile' };
  let alternateContactPersonPageDetail = {
    componentName: COMPONENT_NAME_FOR_ROLE.ALTERNATE_CONTACT_PERSON,
    pageName: 'Page_EntityProfile',
  };
  if (isJurisdictionRegistrarUser) {
    alternateContactPersonPageDetail = {
      componentName: COMPONENT_NAME_FOR_ROLE.ALTERNATE_CONTACT_PERSON,
      pageName: 'Page_JurisdictionEntityProfile',
    };
  }

  useEffectOnce(() => {
    dispatch(getNAICS(id));
    dispatch(getFacilityCountry());
    dispatch(getStateProvince());
    dispatch(getAlternateContact(id, alternateContactPersonPageDetail));
    dispatch(getEnityFacilityById(id, pageDetail, (EntitiesData?.data?.entityStatus?.name === 'DENIED' || EntitiesData?.data?.entityStatus?.name === 'CANCELLED')));

    if (t(t1, 'FOOTER_DISCLAIMER_CONTENT').length > 250) {
      setIsReadMore(true);
    } else {
      setIsReadMore(false);
    }
    const defaultValues = steps.find((item) => item.key === COMPONENT_NAMES.ADD_FACILITIES).filledData;
    methods?.reset();

    setRows(isEmpty(FacilitiesList?.data) ? [] : FacilitiesList?.data);
  });

  const defaultValuesData = steps.find((item) => item.key === COMPONENT_NAMES.ADD_FACILITIES).filledData;

  useEffect(() => {
    setRows(isEmpty(FacilitiesList?.data) ? [] : FacilitiesList?.data);
  }, [FacilitiesList?.data, selectedEntity]);

  useEffect(() => {
    if (FacilityCountry) {
      const defaultValues = steps.find((item) => item.selected).filledData;
      if (defaultValues.mailingSameAsPhysical) handleSameAddressCheck(defaultValues.mailingSameAsPhysical);
      if (defaultValues?.mailingAddress?.CountryID) {
        updateMailingStateByCountry(defaultValues?.mailingAddress?.CountryID);
      }
      if (defaultValues?.physicalAddress?.CountryID) {
        updatePhysicalStateByCountry(defaultValues?.physicalAddress?.CountryID);
      }
    }
  }, [FacilityCountry]);

  if (FacilityCountry?.length > 0) {
    FacilityCountry?.map((item) => {
      if (item.Name !== '') {
        countryData.push(item.Name);
      }
      return countryData;
    });
  }

  if (StateProvinceFacility?.length > 0) {
    StateProvinceFacility?.map((item) => {
      stateProvinceData.push(item.Name);
      return stateProvinceData;
    });
  }

  if (NAICS?.length > 0) {

    NAICS?.map((item) => {
      naicsData.push({
        ...item,
        label: `${item.naicsCode}  ${localStorage.getItem('i18nextLng') === 'French' ? item.descriptionInFrench : item.descriptionInEnglish
        }`,
      });
      // naicsData.push();
      return naicsData;
    });
  }

  // Sorting country list
  function countrySort(countryList) {
    // eslint-disable-next-line prefer-arrow-callback
    const data = countryList.slice(2).sort((a, b) => {
      if (a.Name < b.Name) {
        return -1;
      }
      if (a.Name > b.Name) {
        return 1;
      }
      return 0;
    });
    return data;
  }

  const restCountryList = countrySort(FacilityCountry);
  const initialCountryList = FacilityCountry.slice(0, 2);
  const sortedCountryList = [...initialCountryList, ...restCountryList];

  const handleSameAddressCheck = (check) => {
    const mailingAddressID = methods.getValues('mailingAddress.id'); // keep mailing address id persistent upon check unckeck
    if (check) {
      setShowMailingRegion(showPhysicalRegion);
      methods?.trigger('mailingAddress');
      methods?.trigger('physicalAddress');
      const physicalAddress = methods.getValues('physicalAddress');
      const country = FacilityCountry?.find((item) => item.ID === physicalAddress.country);
      setMailingState(country?.states);
      methods.setValue('mailingAddress', physicalAddress, {
        shouldDirty: true,
        shouldValidate: true,
      });
      methods.setValue('mailingAddress.id', mailingAddressID); // set persistent ID
      methods.setValue('mailingSameAsPhysical', check);
      setDisable(true);
    } else {
      setMailingState([]);
      setDisable(false);
      setShowMailingRegion(false);
      methods.setValue(
        'mailingAddress',
        {
          ...defaultValues.mailingAddress,
        },
        { shouldDirty: true, shouldValidate: true },
      );
      methods.setValue('mailingAddress.id', mailingAddressID); // set persistent ID
    }
    methods.setValue('mailingSameAsPhysical', check);
    methods?.trigger('physicalAddress.region'); // Region cloning is taking time & add button disabled.
    methods?.trigger('mailingAddress.region');
  };

  const defaultValues = {
    isFacilityInRegistry: false,
    ghgEmissionsReportingId: '',
    NAICSCode: '',
    facilityName: '',
    operatingName: '',
    useEntityData: false,
    mailingSameAsPhysical: false,
    physicalAddress: {
      street1: '',
      street2: '',
      city: '',
      country: '',
      stateOrProvince: '',
      postalCode: '',
      region: '',
      id: '',
    },
    mailingAddress: {
      street1: '',
      street2: '',
      city: '',
      country: '',
      stateOrProvince: '',
      postalCode: '',
      region: '',
      id: '',
    },
    contactInfo: {
      firstName: '',
      lastName: '',
      jobTitle: '',
      phone: '',
      mobilePhone: '',
      email: '',
      id: '',
    },
    rows: [],
  };

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const retiredMethods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: { comment: '' },
  });

  const methodsForContinue = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {},
  });

  useEffect(() => {
    if (stepData && !stepData.editMode) {
      methods.reset();
    }
  }, [stepData?.editMode]);

  const onSubmit = async (data) => {
    setCustomLoader(true);
    methodsForContinue?.handleSubmit(addFacilityHandler);
    dispatch(getStateProvince);
    const defaultValue = steps.find((item) => item.key === COMPONENT_NAMES.ADD_FACILITIES).filledData;
    const updatedValue = [...data]?.map((item, index) => {
      const newItem = { ...item, is_updateGHG: false };
      if (defaultValue[index]?.ghgEmissionsReportingId && newItem?.ghgEmissionsReportingId !== defaultValue[index]?.ghgEmissionsReportingId) {
        newItem.is_updateGHG = true;
      }
      return newItem;
    });
    dispatch(setIsFormEditable(false));
    try {
      await dispatch(saveFacility(id, updatedValue, NAICS, t1));
      dispatch(setFormValueOfStep(updatedValue));
      dispatch(setNextStepSelect({ steps }));
    } catch (error) {
      console.error('Error saving facility:', error);
    } finally {
      setCustomLoader(false);
    }
  };


  const setDefaultValues = () => {
    methods?.reset(defaultValues);
  };

  function flattenObject(obj, parentKey = '') {
    return Object.keys(obj).reduce((acc, key) => {
      const prefixedKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        Object.assign(acc, flattenObject(obj[key], prefixedKey));
      } else {
        acc[prefixedKey] = obj[key];
      }
      return acc;
    }, {});
  }

  const flatDefaultValues = flattenObject(defaultValues);

  const setCustomDefaultValues = () => {
    const excludeReset = {
      isFacilityInRegistry: defaultValues.isFacilityInRegistry,
      ghgEmissionsReportingId: defaultValues.ghgEmissionsReportingId,
      NAICSCode: defaultValues.NAICSCode,
      useEntityData: defaultValues.useEntityData,
    };

    const newDefaultValues = { ...flatDefaultValues };

    Object.keys(excludeReset).forEach(key => {
      delete newDefaultValues[key];
    });

    Object.keys(newDefaultValues).forEach(field => {
      methods.setValue(field, '');
    });

  };

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const updatePhysicalStateByCountry = (countryID, fromUserChange = false) => {
    if (String(fromUserChange) === 'userChanged') {
      methods.setValue('physicalAddress.stateOrProvince', '');
      methods.setValue('physicalAddress.region', '');
      methods.clearErrors('physicalAddress.region');
      methods.clearErrors('physicalAddress.stateOrProvince');
    }
    const country = FacilityCountry?.find((item) => item.ID === countryID);
    if (country) {
      dispatch(setStateProvinceFacility(country.states));
      if ((country && country.Name === COUNTRY_KEY_NAMES.UNITED_STATES) || country.Name === COUNTRY_KEY_NAMES.CANADA) {
        setShowPhysicalRegion(false);
        setPhysicalState(country.States);
      } else {
        setShowPhysicalRegion(true);
      }
    }
    //  methods?.trigger();
  };

  const updateMailingStateByCountry = (countryID, fromUserChange = false) => {
    if (String(fromUserChange) === 'userChanged') {
      methods.setValue('mailingAddress.stateOrProvince', '');
      methods.setValue('mailingAddress.region', '');
      methods.clearErrors('mailingAddress.region');
      methods.clearErrors('mailingAddress.stateOrProvince');
    }
    const country = FacilityCountry?.find((item) => item.ID === countryID);
    if (country) {
      dispatch(setStateProvinceFacility(country.states));

      if ((country && country.Name === COUNTRY_KEY_NAMES.UNITED_STATES) || country.Name === COUNTRY_KEY_NAMES.CANADA) {
        setShowMailingRegion(false);
        setMailingState(country.States);
      } else {
        setShowMailingRegion(true);
      }
    }
  };

  const addFacilityHandler = () => {
    dispatch(setLoader(true));
    dispatch(setIsFormEditable(true));
    setDisableAllFields(false);
    if (!isProposed && mode !== COMPONENT_MODES.EDIT || mode === COMPONENT_MODES.ADD) {
      //  scrollToTop();
    }
    const facility = methods?.getValues();
    facility.is_new = true;
    const newRows = [...rows, facility];
    if (!isProposed) {
      setRows(newRows);
    }
    dispatch(setFacilities(newRows));
    methods.reset();
    setDisableUseCheck(false);
    setEditingRow({});
    setDisable(false);
    if (enableSaveChange) {
      enableSaveChange(false); // this will set save button on form card of propose facility
    }

    setPhysicalState([]);
    setMailingState([]);
    setTimeout(() => {
    }, 1000);

    if (handleEnableEdit) {
      handleEnableEdit(false);
      setShowForm(true);
    } else if (handleSubmitChanges) {
      handleSubmitChanges?.(newRows);
      setShowForm(true);
    } else {
      setShowForm(false);
    }
    dispatch(setLoader(false));
  };

  const returnFormattedRows = () => {
    if (rows) {
      return rows?.reduce((acc, item, index) => {
        const countryObj = FacilityCountry?.find((x) => x?.ID === item?.physicalAddress?.country);
        const statesArr = countryObj?.States;
        const stateName = statesArr?.find((x) => x?.ID === item?.physicalAddress?.stateOrProvince);
        return [
          ...acc,
          {
            facilityId: item?.facilityId || item?.proposeChanges?.facilityId,
            status: `${t(t1, item?.facilityStatus) || t(t1, item?.proposeChanges?.facilityStatus)}`,
            id: index,
            fId: item?.proposeChanges?.id ? item?.proposeChanges?.id : item?.id,
            facilityName: item?.facilityName || item?.proposeChanges?.facilityName,
            operatingName: item?.operatingName || item?.proposeChanges?.operatingName,
            ghgEmissionsReportingId: item?.ghgEmissionsReportingId || item?.proposeChanges?.ghgEmissionsReportingId,
            effectiveDate: (item?.effectiveDate) || (item?.proposeChanges?.retirementDate),
            retirementDate: item?.facilityStatus === FACILITY_STATUS.RETIRED ? (item?.effectiveDate) || (item?.proposeChanges?.retirementDate) : 'N/A',
            createdBy: item?.proposeChanges?.createdBy ? item?.proposeChanges?.createdBy : item?.createdBy,
            physicalAddress: ShowInlineAddressAddFacility(item?.physicalAddress, t, t1, countryObj, stateName) ||
              ShowInlineAddressAddFacility(item?.proposeChanges?.physicalAddress, t, t1, countryObj, stateName),
            updatedBy: item?.proposeChanges?.updatedBy ? item?.proposeChanges?.updatedBy : item?.updatedBy,
            isFacilityInRegistry: (item?.facilityIsInRegistry ? item?.facilityIsInRegistry : item?.isFacilityInRegistry) || item?.proposeChanges?.facilityIsInRegistry,
            proposedBy: item?.proposeChanges?.proposedBy ? item?.proposeChanges?.proposedBy : item?.proposedBy,
            facilityUUID: item?.proposeChanges?.facilityUuid || item.id || item?.proposeChanges?.id,
            isEditProposedFacility: true ? (item?.proposeChanges?.facilityStatus === FACILITY_STATUS.PROPOSED) : false,
          },
        ];
      }, []);
    }
    return [];
  };

  // Below effect is used to remove error for physical address when click on use entity data button
  const physicalAddressFormData = methods?.getValues('physicalAddress');
  useEffect(() => {
    const { region } = physicalAddressFormData || {};
    if (region) {
      methods?.clearErrors('physicalAddress.stateOrProvince');
    } else if (methods.getValues('useEntityData')) {
      methods?.clearErrors('physicalAddress.region');
    }
  }, [showPhysicalRegion, physicalAddressFormData]);

  // Below effect is used to remove error for mailing address when click on use entity data button
  const mailingAddressFormData = methods?.getValues('mailingAddress');
  useEffect(() => {
    const { region } = mailingAddressFormData || {};
    if (region) {
      methods?.clearErrors('mailingAddress.stateOrProvince');
    } else if (methods.getValues('useEntityData')) {
      methods?.clearErrors('mailingAddress.region');
    }
  }, [showMailingRegion, mailingAddressFormData]);

  const handleUseEntity = (check, data = null) => {
    const currentRow = rows[data?.id];
    const mailingId = currentRow?.mailingAddress?.id ? currentRow?.mailingAddress?.id : methods.getValues('mailingAddress.id');
    const physicalId = currentRow?.physicalAddress?.id ? currentRow?.physicalAddress?.id : methods.getValues('physicalAddress.id');  // keep object addresses persistent
    const contactId = currentRow?.contact?.id ? currentRow?.contact?.id : methods.getValues('contactInfo.id');
    setLoader(true);
    const entityInfo = _.find(steps, (item) => item.key === COMPONENT_NAMES.ENTITY_INFORMATION).filledData;

    const entityContactInfo = _.find(steps, (item) => item.key === COMPONENT_NAMES.CONTACT_INFORMATION).filledData;
    methods.setValue('useEntityData', check, { shouldDirty: true });
    if (check) {
      methods.setValue('facilityName', entityInfo?.legalName);
      methods.setValue('operatingName', entityInfo?.operationName);
      methods.setValue('physicalAddress', entityContactInfo?.physicalAddress);
      methods.setValue('physicalAddress.country', entityContactInfo?.physicalAddress?.country?.id);
      methods.setValue('mailingAddress', entityContactInfo?.mailingAddress);
      methods.setValue('mailingAddress.country', entityContactInfo?.mailingAddress?.country?.id);
      methods.setValue('mailingSameAsPhysical', entityContactInfo?.mailingSameAsPhysical);
      if (!isEmpty(AlternateContact?.data) && (AlternateContact?.data?.firstName !== '' || AlternateContact?.data?.lastName !== '' || AlternateContact?.data?.position !== '' || AlternateContact?.data?.telephoneNumber !== '' || AlternateContact?.data?.mobilePhone !== '' || AlternateContact?.data?.email !== '')) {
        methods.setValue('contactInfo.firstName', AlternateContact?.data?.firstName);
        methods.setValue('contactInfo.lastName', AlternateContact?.data?.lastName);
        methods.setValue('contactInfo.jobTitle', AlternateContact?.data?.position);
        methods.setValue('contactInfo.phone', AlternateContact?.data?.telephoneNumber);
        methods.setValue('contactInfo.mobilePhone', AlternateContact?.data?.mobilePhone);
        methods.setValue('contactInfo.email', AlternateContact?.data?.email);
      }
      if (!isEmpty(AlternateContact?.data)) {
        setDisableUseCheck(true);
      }
      setDisableUseCheck(true);
    } else {
      methods.setValue('facilityName', '');
      methods.setValue('operatingName', '');
      methods.setValue('physicalAddress', defaultValues.physicalAddress);
      methods.setValue('mailingAddress', defaultValues.mailingAddress);
      methods.setValue('mailingSameAsPhysical', defaultValues.mailingSameAsPhysical);
      if (!isEmpty(AlternateContact?.data) && (AlternateContact?.data?.firstName !== '' || AlternateContact?.data?.lastName !== '' || AlternateContact?.data?.position !== '' || AlternateContact?.data?.telephoneNumber !== '' || AlternateContact?.data?.mobilePhone !== '' || AlternateContact?.data?.email !== '')) {
        methods.setValue('contactInfo.firstName', '');
        methods.setValue('contactInfo.lastName', '');
        methods.setValue('contactInfo.jobTitle', '');
        methods.setValue('contactInfo.phone', '');
        methods.setValue('contactInfo.mobilePhone', '');
        methods.setValue('contactInfo.email', '');
      }
      setDisableUseCheck(false);
    }
    methods.setValue('mailingAddress.id', mailingId);
    methods.setValue('physicalAddress.id', physicalId);
    methods.setValue('contactInfo.id', contactId);

    setLoader(false);
    methods.trigger([
      'useEntityData',
      'facilityName',
      'operatingName',
      'physicalAddress',
      'physicalAddress.country',
      'mailingAddress',
      'mailingAddress.country',
      'mailingSameAsPhysical',
      'contactInfo.firstName',
      'contactInfo.lastName',
      'contactInfo.jobTitle',
      'contactInfo.phone',
      'contactInfo.mobilePhone',
      'contactInfo.email',
    ]);
  };

  const handleQuickAction = (event) => {
    methods?.clearErrors();
  };

  const removeRow = (rowData) => {
    setShowRemoveModal(rowData);
  };

  const getConfirmation = () => {
    dispatch(setHideModal());
    setShowRetireConfirmation(true);
  };


  const continueRemoveRow = async () => {
    dispatch(setLoader(true));
    dispatch(setIsFormEditable(true));
    const newRows = rows.filter((item, index) => item.ghgEmissionsReportingId !== showRemoveModal.GHGEmissionsReportingID);
    setShowRemoveModal(false);
    dispatch(setLoader(true));
    methods.reset();
    setSaveButton(false);
    setEditingRow({});
    if (handleEnableEdit) {
      handleEnableEdit(false);
    }
    if (!isEmpty(showRemoveModal.fId)) {
      dispatch(removeFacilityById(showRemoveModal.fId, EntityId));
    }
    if (isEmpty(editingRow.createdBy) && isEmpty(editingRow.fId) && isEmpty(editingRow.facilityId)) {
      if (isEmpty(showRemoveModal.fId)) {
        const updatedFacilities = rows.filter((item, index) => item.ghgEmissionsReportingId !== showRemoveModal.ghgEmissionsReportingId);
        setRows(updatedFacilities);
      } else {
        setRows(newRows);
      }
      if (isJurisdictionRegistrarUser) {
        setRows(rows.filter((item) => {
          return item.id !== showRemoveModal;
        }));
        dispatch(retireFacilityById(showRemoveModal, id));
      }
      dispatch(setLoader(false));

    } else {
      try {
        dispatch(setLoader(true));
        const response = await checkGHGIDExists({
          ghg_emission_report_id: showRemoveModal.GHGEmissionsReportingID,
          is_facility_registry: false,
          entityId: id,
        });
        dispatch(setLoader(false));
        if (response && response?.messageKey === GHG_API_ERROR_KEYS.GHG_VERIFY_ALREADY_EXIST) {
          dispatch(removeFacilityById(showRemoveModal.fId, EntityId)).then(setRows(newRows));
        }
      } catch (error) {
        if (error && error?.response?.data?.messageKey === GHG_API_ERROR_KEYS.GHG_VERIFY_ALREADY_EXIST) {
          dispatch(removeFacilityById(showRemoveModal.fId, EntityId)).then(setRows(newRows));
        }
      }
    }
    if (usage === COMPONENT_NAMES.REVIEW_AND_SUBMIT) {
      dispatch(setIsFormEditable(false));
      switchFacilityMode?.(COMPONENT_MODES.EDIT);
      handleResetEdit?.();
    }
    dispatch(setLoader(false));
    if (rows?.length > 1) {
      dispatch(setDisabledFacilitySubmitBtn(false));
    }
    methods.clearErrors();
  };

  const continueRevertRow = () => {
    setShowRemoveModal(false);
    dispatch(revertChanges(showRemoveModal.data.id, {
      sections: showRemoveModal.data.sectionData.sections,
    }));
  };


  const handleEditCancelClick = () => {
    switchFacilityMode?.(COMPONENT_MODES.EDIT);
    setShowWarningModal(true);
  };

  const handleEditSaveClick = useCallback(() => {
    setShowConfirmationPopUp(false);
    setShowPassphraseDialogForEditFacility(false);
    setDisableGHGField(false);
    dispatch(setIsFormEditable(true));
    switchFacilityMode?.(COMPONENT_MODES.EDIT);
    const newRows = [...rows];
    newRows[editingRow.id] = methods.getValues();
    if (!isProposed) {
      setRows(newRows);
    }
    if (enableSaveChange) {
      enableSaveChange(false); // this will set save button on form card of propose facility
    }
    if (editRowRef) {
      const facilityDetails = { ...newRows[editingRow.id] };
      editRowRef.current = {
        ...facilityDetails,
        ...(facilityDetails?.id ? { facilityId: facilityDetails?.id } : {}),
        // id,  commenting id as this is entity ID not facility id
      }; // used when save is clicked
    }

    methods.reset();
    setSaveButton(false);
    // handleEnableEdit(false); // close editable form
    proposedFacilityChange?.();
    if (handleSubmitChanges) {
      handleSubmitChanges([newRows[editingRow.id]]);
      setShowForm(true);
    } else {
      setShowForm(false);
    }
    setDisableAllFields(false);
    setDisable(false);
    setDisableUseCheck(false);

  }, [rows, editingRow]);

  const handleEditRow = (row) => {
    switchFacilityMode?.(COMPONENT_MODES.ADD);
    methods.setValue('ACTION', 'edit');
    setShowForm(true);
    setSaveButton(true);
    if (handleEnableEdit) {
      handleEnableEdit(true);
    }
    setEditingRow(row);
    let editRow = {};
    const currentRow = rows[row.id];
    if (!isEmpty(currentRow?.proposeChanges)) {
      editRow = { ...currentRow?.proposeChanges };
      if (isEmpty(editRow?.contact)) {
        editRow.contact = currentRow?.contact;
      }
      if (isEmpty(editRow?.mailingAddress)) {
        editRow.mailingAddress = currentRow?.mailingAddress;
      }
      if (isEmpty(editRow?.physicalAddress)) {
        editRow.physicalAddress = currentRow?.physicalAddress;
      }
      editRow = { ...editRow };
      editRow.proposedFacilityId = currentRow?.proposeChanges?.id;
      methods.setValue('proposedFacilityId', currentRow?.proposeChanges?.id);
      if (isEmpty(currentRow?.id)) {
        methods.setValue('ACTION', 'add');
      }
    } else {
      editRow = currentRow;
    }

    if (AlternateContact?.data && !editRow?.contact) {
      setDisableGHGField(true);
    }
    methods.setValue('facilityId', editRow?.facilityId);
    methods.setValue('status', editRow?.status);

    methods.setValue('physicalAddress', editRow?.physicalAddress);
    methods.setValue('physicalAddress.country', editRow?.physicalAddress?.country?.id || editRow?.physicalAddress?.country);
    methods.setValue('physicalAddress.stateOrProvince', editRow?.physicalAddress?.state?.id ? editRow?.physicalAddress?.state?.id : editRow?.physicalAddress?.stateOrProvince);
    methods.setValue('mailingAddress', editRow?.mailingAddress);
    methods.setValue('mailingAddress.country', editRow?.mailingAddress?.country?.id || editRow?.mailingAddress?.country);
    methods.setValue('mailingAddress.stateOrProvince', editRow?.mailingAddress?.state?.id ? editRow?.mailingAddress?.state?.id : editRow?.mailingAddress?.stateOrProvince);
    methods.setValue('contactInfo', editRow?.contact ? editRow?.contact : editRow?.contactInfo);
    methods.setValue('mailingSameAsPhysical', editRow?.mailingSameAsPhysical || editRow?.mailingAddress?.sameAsPhysicalAddress);
    methods.setValue('useEntityData', (editRow?.useEntityData ? editRow?.useEntityData : editRow?.isUseEntity) ?? false);


    methods.setValue('physicalAddress.id', editRow?.physicalAddress?.id);
    methods.setValue('mailingAddress.id', editRow?.mailingAddress?.id);
    methods.setValue('contactInfo.phone', editRow?.contact?.telephoneNumber ? editRow?.contact?.telephoneNumber : editRow?.contactInfo?.phone);
    methods.setValue('contactInfo.email', editRow?.contact?.emailAddress ? editRow?.contact?.emailAddress : editRow?.contactInfo?.email);
    methods.setValue('contactInfo.mobilePhone', editRow?.contact?.mobileNumber ? editRow?.contact?.mobileNumber : editRow?.contactInfo?.mobilePhone);

    methods.setValue('operatingName', editRow?.operatingName);
    methods.setValue('facilityName', editRow?.facilityName);
    methods.setValue('NAICSCode', editRow?.naicsCode?.id || editRow?.NAICSCode);
    methods.setValue('isFacilityInRegistry', editRow?.facilityIsInRegistry ? editRow?.facilityIsInRegistry : editRow?.isFacilityInRegistry);
    methods.setValue('ghgEmissionsReportingId', editRow?.ghgEmissionsReportingId);

    if (editRow?.facilityIsInRegistry || editRow?.isFacilityInRegistry) {
      setDisableAllFields(true);
    } else {
      setDisableAllFields(false);
    }

    methods.setValue('id', editRow?.id);
    methods.trigger(['physicalAddress', 'mailingAddress', 'facilityName', 'operatingName', 'contactInfo', 'NAICSCode']);
  };

  const headerNameLabel = selectedJurisdiction === JURISDICTIONS_NAMES.WASHINGTON_STATE ? t(t1, 'EPA_OR_WASHINGTON_GREENHOUSE_GAS_REPORTING_ID') : t(t1, 'GHG_EMISSIONS_REPORTING_ID');

  const [showConfirmationPopUp, setShowConfirmationPopUp] = useState(false);
  const showPassPharseOnCoreValueChange = () => {
    const checkDirtyFields = methods?.formState?.dirtyFields?.isFacilityInRegistry
      || methods?.formState?.dirtyFields?.ghgEmissionsReportingId
      || methods?.formState?.dirtyFields?.NAICSCode
      || methods?.formState?.dirtyFields?.facilityName
      || methods?.formState?.dirtyFields?.operatingName
      || methods?.formState?.dirtyFields?.useEntityData;

    if (showSavePopup) {
      setShowConfirmationPopUp(true);
    } else if (passPhraseFeature) {
      setShowPassphraseDialogForEditFacility(true);
    } else {
      handleEditSaveClick();
    }
  };

  const facilityTableColumns = [
    { id: 1, field: 'facilityName', headerName: t(t1, 'FACILITY_NAME'), flex: 1, align: 'center' },
    { id: 2, field: 'operatingName', headerName: t(t1, 'OPERATING_NAME'), flex: 1, align: 'center' },

    {
      id: 3,
      field: 'ghgEmissionsReportingId',
      headerName: headerNameLabel,
      minWidth: 270,
      flex: 2,
      align: 'center',
      renderCell: (cellValues) => {
        const { status, ghgEmissionsReportingId, facilityUUID, fId } = cellValues?.row || {};

        if (status?.toLowerCase() === 'retired' || !isIndividualUser) {
          return ghgEmissionsReportingId;
        }
        if (status === 'undefined') {
          return ghgEmissionsReportingId;
        }

        if (isIndividualUser) {
          const facilityIdentifier = facilityUUID || fId;
          return (
            <NavLink className="link-cell" to={`/entity-profile/${id}/facility-profile-details/${facilityIdentifier}`}>
              {ghgEmissionsReportingId}
            </NavLink>
          );
        }

        return null;
      },
    },
    {
      id: 4,
      field: 'physicalAddress',
      headerName: t(t1, 'PHYSICAL_ADDRESS'),
      flex: 1,
      align: 'center',
    },


  ];

  if (showIdStatus) {
    if (!isIndividualUser) {
      facilityTableColumns.unshift({
        id: 0,
        field: 'facilityId',
        headerName: t(t1, 'FACILITY_ID'),
        flex: 1,
        hideable: false,
        groupable: false,
        align: 'center',
        resizable: false,
        renderCell: (cellValues) => {
          const { facilityUUID, fId, facilityId } = cellValues?.row || {};
          const facilityIdentifier = facilityUUID || fId;

          return (
            <NavLink className="link-cell" to={`/entity-profile/${id}/facility-profile-details/${facilityIdentifier}`}>
              {facilityId}
            </NavLink>
          );
        },
      });
    }

    facilityTableColumns.splice(4, 0, {
      id: 9,
      field: 'status',
      headerName: t(t1, 'FACILITY_STATUS'),
      flex: 1,
      align: 'center',
      type: 'singleSelect',
      valueOptions: getFacilityStatusForFilterDropdown(t, t1),
    });
  }

  if (!isProposed) {
    facilityTableColumns.push({
      id: 5,
      field: 'isFacilityInRegistry',
      headerName: t(t1, 'FACILITY_IN_REGISTRY'),
      flex: 1,
      align: 'center',
      filterable: false,
      valueFormatter: ({ value }) => {
        return value ? t(t1, 'TRUE') : t(t1, 'FALSE');
      },
      renderCell: (cellValues) => (
        cellValues?.row?.isFacilityInRegistry ? <CheckBoxIcon
            color="primary"
            sx={{

              '&:hover': {
                bgcolor: 'transparent',
              },
            }}

          /> :
          <CheckBoxOutlineBlankIcon color="disabled"
                                    sx={{

                                      '&:hover': {
                                        bgcolor: 'transparent',
                                      },
                                    }}
          />
      ),
    });

  }

  const conditionToShow = () => (stepData && stepData.editMode) || mode === COMPONENT_MODES.ADD;

  if (isProposed && mode === COMPONENT_MODES.EDIT) {
    facilityTableColumns.push({
      id: 6,
      field: 'effectiveDate',
      headerName: t(t1, 'EFFECTIVE_DATE'),
      flex: 1,
      align: 'center',
      valueGetter: ({ value }) => `${removeTimeZone(formatDateUpdated(value))}`,
      renderCell: (params) => {
        return (
          <span>
            {params?.row?.effectiveDate ? `${removeTimeZone(formatDateUpdated(params?.row?.effectiveDate))}` : ''}
          </span>
        );
      },
    });
    if (isJurisdictionRegistrarUser) {
      facilityTableColumns.push({
        id: 7,
        field: 'retirementDate',
        headerName: t(t1, 'RETIREMENT_DATE'),
        flex: 1,
        align: 'center',
        valueGetter: ({ value }) => `${removeTimeZone(formatDateUpdated(value))}`,
        renderCell: (params) => {
          return (
            <span>
              {params?.row?.retirementDate ? `${removeTimeZone(formatDateUpdated(params?.row?.retirementDate))}` : ''}
            </span>
          );
        },
      });
    }
  }
  if (isRevisionRequested) {
    facilityTableColumns.push({
      id: 6,
      field: 'effectiveDate',
      headerName: t(t1, 'EFFECTIVE_DATE'),
      flex: 1,
      align: 'center',
      valueGetter: ({ value }) => `${removeTimeZone(formatDateUpdated(value))}`,
      renderCell: (params) => {
        return (
          <span>
            {params?.row?.effectiveDate ? `${removeTimeZone(formatDateUpdated(params?.row?.effectiveDate))}` : ''}
          </span>
        );
      },
    });
  }
  if (isProposed && mode === COMPONENT_MODES.EDIT && isJurisdictionRegistrarUser) {
    facilityTableColumns.push({
      id: 9,
      field: 'quickActions',
      headerName: t(t1, 'QUICK_ACTIONS'),
      disableExport: true,
      flex: 1,
      hideable: false,
      sortable: false,
      groupable: false,
      filterable: false,
      align: 'center',
      renderCell: (cellValues) => {
        const list = [];
        if (cellValues.row.status.toUpperCase() === FACILITY_STATUS.ACTIVE && !cellValues?.row?.isEditProposedFacility) {
          list.push({
            name: t(t1, 'RETIRE_FACILITY1').toUpperCase(),
            onLinkClick: () => {
              setselectedRetireRow(cellValues.row?.facilityUUID);
              dispatch(setShowModal({
                  modalTitle: t(t1, 'RETIRE_FACILITY'),
                  modalBodyMessage: [t(t1, 'RETIRE_FACILITY_WANT'), <br />, t(t1, 'ACTION_RETIRE_FACILITY')],
                  modalConfirmButton: t(t1, 'RETIRE_FACILITY'),
                  modalCloseButton: true,
                }),
                retiredMethods?.reset(defaultValues),
              );
            },
          });
        }
        if (isEmpty(list)) {
          return null;
        }
        return <QuickLinksMenu
          iconClassName="dot-icon"
          popupClassName="quick-link-style"
          linksList={list || []}
          row={cellValues?.row}
          isDotIcon={!false}
        />;
      },
    });
  }
  if ((isIndividualUser || mode === COMPONENT_MODES.ADD && !isProposed || (isProposed && !isJurisdictionRegistrarUser)) && editModeforReviewandSubmit) {

    if (!saveButton && ![ENTITY_APPLICTION_STATUS_NAMES.PENDING, ENTITY_APPLICTION_STATUS_NAMES.ACTIVE, ENTITY_APPLICTION_STATUS_NAMES.CLOSED].includes(EntitiesData?.data?.entityStatus?.name) && !isJurySysAdmin && EditAccess) {
      facilityTableColumns.push({
        id: 8,
        field: 'quickActions',
        headerName: t(t1, 'QUICK_ACTIONS'),
        disableExport: true,
        flex: 1,
        hideable: false,
        sortable: false,
        groupable: false,
        align: 'center',
        filterable: false,
        renderCell: (cellValues) => {
          const list = [];
          if (isIndividualUser || [REVISIONS_REQUESTED, APPLICATION_IN_PROGRESS]?.includes(EntitiesData?.data?.entityStatus?.name)) {
            if (cellValues.row.status.toUpperCase() !== FACILITY_STATUS.RETIRED && EntitiesData?.data?.entityStatus?.name === REVISIONS_REQUESTED ? userDetails.UID.includes(cellValues.row.createdBy) : true) {
              list.push({
                name: t(t1, 'EDIT_FACILITY_INFORMATION').toUpperCase(),
                Access: isIndividualUser || isJuryAdminUser,
                onLinkClick: (cellRow) => {
                  methods?.clearErrors();
                  setSelectedRow(cellRow);
                  handleEditRow(cellRow);
                },
              });
            }
            if ((isJuryAdminUser) && userDetails.UID.includes(cellValues.row.createdBy)) {
              list.push({
                name: t(t1, 'EDIT_FACILITY_INFORMATION').toUpperCase(),
                Access: isIndividualUser || isJuryAdminUser,
                onLinkClick: (cellRow) => {
                  methods?.clearErrors();
                  setSelectedRow(cellRow);
                  handleEditRow(cellRow);
                },
              });
            }
          }


          if (isJurisdictionRegistrarUser) {

            if (cellValues.row.status?.toUpperCase() === FACILITY_STATUS.PROPOSED && [PENDING]?.includes(EntitiesData?.data?.entityStatus?.name)) {
              list.push({
                name: t(t1, 'REMOVE_FACILITY').toUpperCase(),
                Access: isJurisdictionRegistrarUser,
                onLinkClick: (cellRow) => {
                  removeRow({ ACTION: 'REMOVE', ...cellRow });
                },
              });
            }
          } else {


            // PROPOSED Facility  RR, IP OR ACTIVE
            // eslint-disable-next-line no-lonely-if
            if ((cellValues?.row?.proposedBy === 'INDIVIDUAL_USER_AR' && isIndividualUser && EditAccess) || userDetails?.UID?.includes(cellValues.row.createdBy) && cellValues?.row?.status?.toUpperCase() === FACILITY_STATUS?.PROPOSED && [REVISIONS_REQUESTED, APPLICATION_IN_PROGRESS]?.includes(EntitiesData?.data?.entityStatus?.name)) {
              list.push({
                name: t(t1, 'REMOVE_FACILITY').toUpperCase(),
                Access: isIndividualUser,
                onLinkClick: (cellRow) => {
                  removeRow({ ACTION: 'REMOVE', ...cellRow });
                },
              });
            } else if (mode === COMPONENT_MODES.ADD && ((cellValues.row.proposedBy === 'INDIVIDUAL_USER_AR' && isIndividualUser && EditAccess) || userDetails?.UID?.includes(cellValues.row.createdBy)) && [APPLICATION_IN_PROGRESS]?.includes(EntitiesData?.data?.entityStatus?.name)) {
              list.push({
                name: t(t1, 'EDIT_FACILITY_INFORMATION').toUpperCase(),
                Access: isIndividualUser || isJuryAdminUser,
                onLinkClick: (cellRow) => {
                  methods?.clearErrors();
                  setSelectedRow(cellRow);
                  handleEditRow(cellRow);
                },
              });
              list.push({
                name: t(t1, 'REMOVE_FACILITY').toUpperCase(),
                Access: EditAccess || isIndividualUser,
                onLinkClick: (cellRow) => {
                  removeRow({ ACTION: 'REMOVE', ...cellRow });
                },
              });
            } else if (cellValues.row.status?.toUpperCase() === FACILITY_STATUS.PROPOSED && userDetails?.UID?.includes(cellValues.row.createdBy) && !isEmpty(cellValues?.row?.fId)) {
              list.push({
                name: t(t1, 'REMOVE_FACILITY').toUpperCase(),
                Access: isJurisdictionAdminUser,
                onLinkClick: () => {
                  setShowRemoveModal({ ACTION: 'REMOVE', ...cellValues?.row });

                },
              });
            }
          }
          if ([APPLICATION_IN_PROGRESS]?.includes(EntitiesData?.data?.entityStatus?.name) && EditAccess && isIndividualUser && isEmpty(cellValues?.row?.createdBy)) {
            list.push({
              name: t(t1, 'REMOVE_FACILITY').toUpperCase(),
              Access: isIndividualUser,
              onLinkClick: (cellRow) => {
                removeRow({ ACTION: 'REMOVE', ...cellRow });
              },
            });
          }
          if (!isEmpty(list)) {
            return <QuickLinksMenu
              iconClassName="dot-icon"
              popupClassName="quick-link-style"
              linksList={list || []}
              row={cellValues?.row}
              isDotIcon={!false}
            />;
          }
          return null;
        },

      });
    }
  }
  if (isPending) {
    // Finding index of efective date
    const index = facilityTableColumns.findIndex(item => item.field === 'effectiveDate');
    // Removing index from facilityTableColumns
    facilityTableColumns.splice(index, 1);
  }
  // Handle EDIT/REMOVE options on ACTIVE Entity APPLICATIONS
  if ((EditAccess || isJurisdictionAdminUser) && !saveButton && EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.ACTIVE) {
    facilityTableColumns.push({
      id: 8,
      field: 'quickActions',
      headerName: t(t1, 'QUICK_ACTIONS'),
      disableExport: true,
      flex: 1,
      hideable: false,
      sortable: false,
      groupable: false,
      align: 'center',
      filterable: false,
      renderCell: (cellValues) => {
        if (cellValues?.row?.status?.toUpperCase() === FACILITY_STATUS.RETIRED) {
          return null;
        }
        const list = [];
        const editFacilityOption = {                                                                                   // ANY USER WITH EDIT ACCESS CAN EDIT ANY FACILITY
          name: t(t1, 'EDIT_FACILITY_INFORMATION').toUpperCase(),
          Access: isIndividualUser || isJuryAdminUser,
          onLinkClick: (cellRow) => {
            methods?.clearErrors();
            setSelectedRow(cellRow);
            handleEditRow(cellRow);
          },
        };
        if (userDetails?.UID?.includes(cellValues.row.createdBy) && !isJurisdictionAdminUser && !['JURISDICTION_ADMINISTRATOR', 'JURISDICTION_REGISTRAR'].includes(cellValues?.row?.proposedBy)) {  // Juriadmin user caanot edit his proposed facility either
          list.push(editFacilityOption);
        } else if (!['JURISDICTION_ADMINISTRATOR', 'JURISDICTION_REGISTRAR'].includes(cellValues?.row?.proposedBy) && ['ROLE#00108', 'ROLE#00109', 'ROLE#00110', 'ROLE#00102'].includes(userDetails?.RoleDetail?.Role[0]?.id)   // explicitely for AR
        ) {
          list.push(editFacilityOption);
        }

        const removefacilityOption = {                                                                                 // need to handle some exceptions
          name: t(t1, 'REMOVE_FACILITY').toUpperCase(),
          Access: isJurisdictionRegistrarUser,
          onLinkClick: (cellRow) => {
            removeRow({
              ACTION: 'REVERT', data: {
                id, sectionData: {
                  sections: [{
                    section: 'facilities',
                    ids: [cellRow.fId],
                  }],
                },
              },
            });
          }, // instead revert facility
        };

        if (cellValues?.row?.status?.toUpperCase() !== FACILITY_STATUS.ACTIVE) {

          if (userDetails?.UID?.includes(cellValues.row.createdBy)) {
            list.push(removefacilityOption);
          } else if (cellValues?.row?.proposedBy !== 'JURISDICTION_ADMINISTRATOR' && ['ROLE#00108', 'ROLE#00109', 'ROLE#00110', 'ROLE#00102'].includes(userDetails?.RoleDetail?.Role[0]?.id) // explicitely for AR
          ) {
            list.push(removefacilityOption);
          }
        }

        if (!isEmpty(list)) {
          return <QuickLinksMenu
            iconClassName="dot-icon"
            popupClassName="quick-link-style"
            linksList={list || []}
            row={cellValues?.row}
            isDotIcon={!false}
          />;
        }
        return null;
      },
    });
  }

  const setRegistryValues = (values) => {
    setLoader(true);
    if (!isEmpty(values)) {
      methods.setValue('NAICSCode', values.naics_code);
      methods.setValue('facilityName', values.legal_name);
      methods.setValue('operatingName', values.operating_name);
      methods.setValue('useEntityData', values.is_use_entity ?? false);

      methods.setValue('mailingAddress.street1', values.MailingAddress.Street1);
      methods.setValue('mailingAddress.street2', values.MailingAddress.Street2);
      methods.setValue('mailingAddress.city', values.MailingAddress.City);
      methods.setValue('mailingAddress.region', values.MailingAddress.Region);
      methods.setValue('mailingAddress.country', values.MailingAddress.CountryID);
      methods.setValue('mailingAddress.stateOrProvince', values.MailingAddress.StateID);
      methods.setValue('mailingAddress.postalCode', values.MailingAddress.PostalCode);
      methods.setValue('mailingSameAsPhysical', values.MailingAddress.SameAsPhysicalAddress);


      methods.setValue('physicalAddress.street1', values.PhysicalAddress.Street1);
      methods.setValue('physicalAddress.street2', values.PhysicalAddress.Street2);
      methods.setValue('physicalAddress.city', values.PhysicalAddress.City);
      methods.setValue('physicalAddress.region', values.PhysicalAddress.Region);
      methods.setValue('physicalAddress.country', values.PhysicalAddress.CountryID);
      methods.setValue('physicalAddress.stateOrProvince', values.PhysicalAddress.StateID);
      methods.setValue('physicalAddress.postalCode', values.PhysicalAddress.PostalCode);
      methods.setValue('contactInfo.first_name', values.contact.first_name);
      methods.setValue('contactInfo.last_name', values.contact.last_name);
      methods.setValue('contactInfo.email_address', values.contact.email_address);
      methods.setValue('contactInfo.mobile_number', values.contact.mobile_number);
      methods.setValue('contactInfo.phone_number', values.contact.phone_number);
      methods.setValue('contactInfo.job_title', values.contact.job_title);
    }
    setLoader(false);
    methods.trigger();
  };
  // add 1 sec debounce while searching  unique Id
  const userIsUnique = _.debounce(async (value, type) => {
    const errorMessage = verifyGHGNumber(value, ``, t1, `${t(t1, 'ERR_GHG_EMISSIONS_REPORTING_ID_MIN_LENGTH_CHAR')}`, `${t(t1, 'ERR_GHG_EMISSIONS_REPORTING_ID_MAX_LENGTH_CHAR')}`, `${t(t1, 'ERR_GHG_EMISSIONS_REPORTING_ID_NO_SPECIAL_CHAR_ALLOW')}`, {
      minLength: 6,
      maxLength: 20,
    });
    if (errorMessage) {
      return methods?.setError('ghgEmissionsReportingId', { type: 'custom', message: errorMessage });
    }
    setShowAdd(false);
    if (rows) {
      const isDuplicate = rows.find((item) => {
        if (item?.proposeChanges) {
          return value === item?.proposeChanges?.ghgEmissionsReportingId && item.id !== selectedRow?.facilityUUID;
        }
        return value === item?.ghgEmissionsReportingId && item?.facilityStatus !== 'RETIRED';
      });
      if (isDuplicate && !saveButton) {
        return methods?.setError('ghgEmissionsReportingId', {
          type: 'custom',
          message: t(t1, 'DUPLICATE_FACILITY_ERROR_MESSAGE'),
        });
      }
      if (isDuplicate && saveButton && methods.getValues('ghgEmissionsReportingId') !== editingRow.ghgEmissionsReportingId && methods.getValues('isFacilityInRegistry')) {
        return methods?.setError('ghgEmissionsReportingId', {
          type: 'custom',
          message: t(t1, 'DUPLICATE_FACILITY_ERROR_MESSAGE'),
        });
      }
      if (isDuplicate && saveButton && methods.getValues('ghgEmissionsReportingId') !== editingRow.ghgEmissionsReportingId && !methods.getValues('isFacilityInRegistry')) {

        return methods?.setError('ghgEmissionsReportingId', {
          type: 'custom',
          message: t(t1, 'DUPLICATE_FACILITY_ERROR_MESSAGE'),
        });
      }
    }
    if (type === 'checkValidUser' && value !== previousGHGId) {
      try {
        dispatch(setLoader(true));
        setValidatingGHG(true);
        setPreviousGHGId(value);
        const response = await checkGHGIDExists({
          ghg_emission_report_id: value.toString().padStart(6, '0'),
          is_facility_registry: methods.getValues('isFacilityInRegistry') || false,
          entityId: id,
        });
        dispatch(setLoader(false));
        if (methods.getValues('isFacilityInRegistry') && response?.messageKey === GHG_API_ERROR_KEYS.GHG_VERIFY_ALREADY_EXIST) {
          if (response.data.naics_code && !isEmpty(naicsData.find((naics) => naics.id === response.data.naics_code))) {
            methods.setValue('NAICSCode', response.data.naics_code);
          }
          methods.setValue('facilityName', response.data.legal_name);
          methods.setValue('operatingName', response.data.operating_name);
          methods.setValue('useEntityData', response.data.is_use_entity ?? false);

          methods.setValue('mailingAddress.street1', response.data.MailingAddress.Street1);
          methods.setValue('mailingAddress.street2', response.data.MailingAddress.Street2);
          methods.setValue('mailingAddress.city', response.data.MailingAddress.City);
          methods.setValue('mailingAddress.region', response.data.MailingAddress.Region);
          methods.setValue('mailingAddress.country', response.data.MailingAddress.CountryID);
          methods.setValue('mailingAddress.stateOrProvince', response.data.MailingAddress.StateID);
          methods.setValue('mailingAddress.postalCode', response.data.MailingAddress.PostalCode);
          methods.setValue('mailingSameAsPhysical', response.data.MailingAddress.SameAsPhysicalAddress);


          methods.setValue('physicalAddress.street1', response.data.PhysicalAddress.Street1);
          methods.setValue('physicalAddress.street2', response.data.PhysicalAddress.Street2);
          methods.setValue('physicalAddress.city', response.data.PhysicalAddress.City);
          methods.setValue('physicalAddress.region', response.data.PhysicalAddress.Region);
          methods.setValue('physicalAddress.country', response.data.PhysicalAddress.CountryID);
          methods.setValue('physicalAddress.stateOrProvince', response.data.PhysicalAddress.StateID);
          methods.setValue('physicalAddress.postalCode', response.data.PhysicalAddress.PostalCode);

          methods.setValue('contactInfo.first_name', response.data.contact.first_name);
          methods.setValue('contactInfo.last_name', response.data.contact.last_name);
          methods.setValue('contactInfo.email_address', response.data.contact.email_address);
          methods.setValue('contactInfo.mobile_number', response.data.contact.mobile_number);
          methods.setValue('contactInfo.phone_number', response.data.contact.phone_number);
          methods.setValue('contactInfo.job_title', response.data.contact.job_title);

          methods?.trigger('physicalAddress');
          methods?.trigger('mailingAddress');
          methods?.trigger('contactInfo');

          methods.clearErrors();
          return null;
        }

        if (
          value === editingRow.ghgEmissionsReportingId && methods.getValues('isFacilityInRegistry') && response?.messageKey === GHG_API_ERROR_KEYS.GHG_VERIFY_ALREADY_EXIST_PLEASE_SELECT_FACILITY_IS_IN_REGISTRY
        ) {
          return methods?.clearErrors('ghgEmissionsReportingId');
        }
        if (methods.getValues('ghgEmissionsReportingId') && !methods.getValues('isFacilityInRegistry') && response?.messageKey === GHG_API_ERROR_KEYS.GHG_VERIFY_ALREADY_EXIST_PLEASE_SELECT_FACILITY_IS_IN_REGISTRY) {
          return methods?.setError('ghgEmissionsReportingId', {
            type: 'custom',
            message: t(t1, 'FACILITY_EXISTS_ERROR_MESSAGE'),
          });
        }
        if (methods.getValues('isFacilityInRegistry') && response?.messageKey === GHG_API_ERROR_KEYS.GHG_ID_IS_UNIQUE) {
          return methods?.setError('ghgEmissionsReportingId', {
            type: 'custom',
            message: t(t1, 'FACILITY_DOES_NOT_EXIST_ERROR_MESSAGE'),
          });
        }
        if (response?.messageKey === GHG_API_ERROR_KEYS.GHG_BELONGS_TO_ANOTHER_JURI) {
          setShowAdd(true);
          return methods?.setError('ghgEmissionsReportingId', {
            type: 'custom',
            message: t(t1, 'FACILITY_BELONGS_TO_ANOTHER_JURY'),
          });
        }
        if (response?.messageKey === GHG_API_ERROR_KEYS.GHG_VERIFY_ALREADY_EXIST_BUT_NOT_IN_REGISTRY) {
          return methods?.setError('ghgEmissionsReportingId', {
            type: 'custom',
            message: t(t1, 'GHG_VERIFY_ALREADY_EXIST_BUT_NOT_IN_REGISTRY'),
          });
        }
        if (!methods.getValues('isFacilityInRegistry') && response?.messageKey === GHG_API_ERROR_KEYS.GHG_ID_IS_UNIQUE) {
          return methods?.clearErrors('ghgEmissionsReportingId');
        }
        if (response && response?.messageKey === 'SUCCESS') {
          methods?.trigger();
          return null;
        }
        if (methods?.formState?.touchedFields?.physicalAddress?.region) {
          methods?.trigger('physicalAddress.region'); // Region cloning is taking time & add button disabled.
        }
        if (methods?.formState?.touchedFields?.mailingAddress?.region) {
          methods?.trigger('mailingAddress.region');
        }
        return null;
      } catch (error) {
        if (methods.getValues('isFacilityInRegistry')) {
          // set all data
          methods?.clearErrors();
          setRegistryValues(error?.response?.data?.data);
          methods?.trigger();
          return null;
        }
        if (saveButton && methods.getValues('ghgEmissionsReportingId') === editingRow.ghgEmissionsReportingId) {
          methods?.trigger();
          return null;
        }
        methods?.trigger();
        return methods?.setError('ghgEmissionsReportingId', {
          type: 'custom',
          message: selectedJurisdiction === JURISDICTIONS_NAMES.WASHINGTON_STATE ? t(t1, 'EPA_VERIFY_EXIST') : t(t1, error.response.data.messageKey),
        });
      } finally {
        setValidatingGHG(false);
      }
    }
    if (value) {
      return checkingEmptySpace(value, `${t(t1, 'GHG_EMISSIONS_REPORTING_ID')}`, t1, `${t(t1, 'ERR_INVALID_GHG_EMISSIONS_REPORTING_ID')}`);
    }
    return null;
  }, 300);


  useEffect(() => {
    dispatch(setFacilityAddMode(conditionToShow()));
  }, [conditionToShow]);

  const selectedCountry = methods.getValues('physicalAddress')?.country;
  useEffect(() => {
    updatePhysicalStateByCountry(selectedCountry);
  }, [selectedCountry]);

  const selectedMailingCountry = methods.getValues('mailingAddress')?.country;
  useEffect(() => {
    updateMailingStateByCountry(selectedMailingCountry);
  }, [selectedMailingCountry]);

  const [disable, setDisable] = useState(false);

  const handleModalContinue = () => {
    setShowWarningModal(true);
    dispatch(setLoader(true));
    methods.clearErrors();
    setDisableUseCheck(false);
    setDisableGHGField(false);
    switchFacilityMode?.(COMPONENT_MODES.EDIT);
    setDisableAllFields(false);
    setShowWarningModal(false);
    methods.reset();
    setSaveButton(false);
    if (handleEnableEdit) {
      handleEnableEdit(false);
    }
    setShowForm(false);
    dispatch(setIsFormEditable(false));
    setDisable(false);
    if (usage === COMPONENT_NAMES.REVIEW_AND_SUBMIT) {
      handleResetEdit?.();
    }
    dispatch(setLoader(false));
    displayCancelModal();
  };
  const displayCancelModal = () => {
    const data = {
      title: `${t(t1, 'COMMON_SUCCESS')}`,
      message1: t(t1, 'SUCCESSFULLY_CANCELLED'),
      error: false,
      isDefaultDisplay: false,
    };
    dispatch(setModelMessage(data));
  };

  if (setCheckFacility) {
    setCheckFacility(rows);
  }
  const disableOnInRegistryOrUseCheck = disableUseCheck || disableAllFields;
  const disableOnInRegistry = disableAllFields;
  const spreadSameAsPhysicalAddress = () => {
    if (methods.getValues('mailingSameAsPhysical')) {
      const value = methods.getValues('physicalAddress');
      methods.setValue('mailingAddress', value);
      methods.trigger('mailingAddress');
    }
  };

  useEffect(() => {
    if (CheckformInvalid) {
      CheckformInvalid(methods?.formState?.isValid);
    }
  });
  const {
    isJuryAdminUser,
  } = useMemo(() => ({
    isJuryAdminUser: isRolesAssigned(userDetails, [USER_ROLE_TYPES.JURISDICTION_USER], [USER_ROLES.ADMIN]),
  }), [userDetails]);

  const isviewonly = !EditAccess;
  const isEditable = EditAccess || isJuryAdminUser;

  const proposeFacility = () => {
    const facility = methods.getValues();

    facility.is_new = true;

    dispatch(saveProposeFacility(id, [facility], NAICS));
    navigate(-1);
  };
  const continueSubmit = () => {
    proposeFacility();
  };
  const handleApproveDenyChanges = () => {
    setShowPassPhraseDialogue(true);
  };
  const AddFacilityAfterPassPharseSuccess = (e) => {
    const facility = methods.getValues();
    methods?.handleSubmit(addFacilityHandler)(e);
    proposedFacilityChange?.([facility]);
    setShowPassphraseDialogForAddFacility(false);
    setShowConfirmationPopUp(false);
    setSaveButton(false);
  };

  const [isDisabled, setIsDisabled] = useState(false);


  const isDisabledAddButtonForAddFacility = () => {
    const { formState } = methods || {};
    const { errors, isValid } = formState || {};
    const hasError = !isEmpty(errors);
    return hasError || !isValid || !isEditable || validatingGHG || showAdd;
  };

  useEffect(() => {
    setIsDisabled(isDisabledAddButtonForAddFacility());
  }, [isDisabledAddButtonForAddFacility]);

  const inProgressApplication = EntitiesData?.data?.entityStatus?.name === APPLICATION_IN_PROGRESS || showOnlyFacilityForm;

  return (
    <>
      <FormProvider {...methods}>
        {displayHead && (
          <Typography style={Style.contentTextCard}>
            <div className="contentTextCardTwo" style={Style.contentTextCard}>
              <TextTruncate
                content={t(t1, 'FOOTER_DISCLAIMER_CONTENT')}
                animate
              />
            </div>
          </Typography>

        )}
        {hideDataGrid && <FormCard
          title={mode === COMPONENT_MODES.ADD && t(t1, 'FACILITIES')}
          renderContent={addMoreFacilityButton()}
          indicateRequired={false}
          setDefaultValues={setDefaultValues}
          stepNumber={7}
          customeStyle={isRevisionRequested || EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.PENDING ? Style.styleForPendingandRevision : null}
          customClass={'customFormCard'}
          hideCard={hideCard}
        >
          {(isProposed || isRevisionRequested) && (
            <Typography
              variant="h2"
              component="div"
              sx={{ mb: .5, fontSize: '16px' }}
            >
              {t(t1, 'ADDED_FACILITIES') || 'Added Facilities'}
            </Typography>
          )}
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', flexDirection: 'row-reverse' }}
          >
            {associateFacilityButton()}
          </Grid>
          <Box
            sx={{ width: '100%' }}
            // className="tabs-main"
          >
            <MUITableGrid
              isExport
              isSearch
              isSettings
              rows={returnFormattedRows()}
              columns={facilityTableColumns}
              count={10}
              customTableStyle={Style.customTable}
              fileName={t(t1, 'FACILITY_INFORMATION', 'effectiveDate')}
              customPinnedColumns={{ left: ['facilityId'] }}
            />
          </Box>
          {showRemoveModal && (
            <CustomeDialogMessage
              title={t(t1, 'WARNING')}
              continueBtn={t(t1, 'CONTINUE')}
              open={showRemoveModal}
              handleModalCancel={() => {
                setShowRemoveModal(false);
              }}
              handleClose={() => {
                setShowRemoveModal(false);
              }}
              message1={t(t1, 'CANCEL_FACILITY_REMOVE')}
              message3={t(t1, 'CONTINUE_FACILITY_REMOVE')}
              handleContinue={showRemoveModal?.ACTION === 'REVERT' ? continueRevertRow : continueRemoveRow}
              borderColor="red"
            />
          )}
        </FormCard>}

        {conditionToShow() ? (
          <div className="test">
            {(showForm && stepData) && (
              <FormCardAddFacility
                indicateRequired={false}
                customClass={'customFormCard add-facility'}
                customeStyle={hideCard ? { marginTop: '20px', paddingBottom: '40px' } : { paddingBottom: '40px' }}
                // customeStyle={Style.formCardPadding}
                title={
                  <>
                    <>{t(t1, 'ADD_FACILITY')} </>
                    <CustomizeToolTip
                      title={
                        <Typography
                          color="inherit"
                          variant="p"
                        >
                          {t(t1, 'INFO_ABOUT_FACILITY_IN_REGISTRY')}
                        </Typography>
                      }
                      placement="right-start"
                    >
                      <InfoIcon sx={{ color: '#4d8da4', width: '14px', height: '14px' }} />
                    </CustomizeToolTip>
                  </>
                }
                setDefaultValues={setDefaultValues}
                setCustomDefaultValues={setCustomDefaultValues}
                stepNumber={6}
                formLabel={t(t1, 'FACILITY_IS_IN_REGISTRY')}
                disableCheck={selectedRow && [ACTIVE]?.includes(EntitiesData?.data?.entityStatus?.name)} // If facility is edit mode and entity status is not REVISION_REQUESTED then FACILITY_IS_IN_REGISTRY check is disabled 
                handleSameAddress={(check) => {
                  const ACTION = methods.getValues('ACTION');
                  const proposedFacilityId = methods.getValues('proposedFacilityId');
                  const keepID = methods.getValues('id');
                  methods?.setValue('isFacilityInRegistry', check);
                  setDisableAllFields(check);
                  setDisableUseCheck(false);
                  setDisable(false);
                  const KEEPGHG = methods.getValues('ghgEmissionsReportingId');
                  if (!check) {
                    methods.clearErrors(['physicalAddress', 'mailingAddress', 'facilityName', 'operatingName']);
                    methods?.setValue('isFacilityInRegistry', check);
                    setPreviousGHGId();
                    methods.setValue('ghgEmissionsReportingId', KEEPGHG, { shouldValidate: true });
                  } else {
                    methods.clearErrors(['physicalAddress', 'mailingAddress', 'facilityName', 'operatingName']);
                    methods?.setValue('isFacilityInRegistry', check);
                    setPreviousGHGId();
                    methods.setValue('ghgEmissionsReportingId', KEEPGHG, { shouldValidate: true });
                  }
                  methods.setValue('ACTION', ACTION);
                  methods.setValue('proposedFacilityId', proposedFacilityId);
                  methods.setValue('id', keepID);
                  if (!isEmpty(selectedRow)) {
                    const currentRow = rows[selectedRow?.id];
                    const mailingId = currentRow?.mailingAddress?.id ? currentRow?.mailingAddress?.id : methods.getValues('mailingAddress.id');
                    const physicalId = currentRow?.physicalAddress?.id ? currentRow?.physicalAddress?.id : methods.getValues('physicalAddress.id');  // keep object addresses persistent
                    const contactId = currentRow?.contact?.id ? currentRow?.contact?.id : methods.getValues('contactInfo.id');

                    methods.setValue('mailingAddress.id', mailingId);
                    methods.setValue('physicalAddress.id', physicalId);  // setting correct ID's
                    methods.setValue('contactInfo.id', contactId);
                  }
                }}
                defaultChecked={disableOnInRegistry}
                disableCheckOnReview={!isEditable}
              />
            )}

            {(showForm || externalShowFormTrigger && stepData && conditionToShow()) && (
              <FormCard
                customClass={'customFormCard facility-identifiers'}
                customeStyle={Style.formCardPadding}
                title={t(t1, 'FACILITY_IDENTIFIERS')}
                description={t(t1, 'INDICATE_REQUIRED_FIELDS')}
                setDefaultValues={setDefaultValues}
                stepNumber={6}
                indicateRequired={!isRevisionRequested}
              >
                {SopfDetailView(<Grid
                  container
                  spacing={2.5}
                  sx={selectedJurisdiction === JURISDICTIONS_NAMES.WASHINGTON_STATE ? { mt: '-5px' } : { mt: '-25px' }}
                  className="s-label"
                >
                  <GridTextfield
                    componentName="facility"
                    fieldName="ghgEmissionsReportingId"
                    name="ghgEmissionsReportingId"
                    label={
                      selectedJurisdiction === JURISDICTIONS_NAMES.WASHINGTON_STATE
                        ? `${t(t1, 'EPA_OR_WASHINGTON_GREENHOUSE_GAS_REPORTING_ID')}`
                        : `${t(t1, 'GHG_EMISSIONS_REPORTING_ID')}`
                    }
                    privId="priv_txt_ghgid"
                    onBlur={() => setPreviousGHGId()}
                    onPaste={() => setPreviousGHGId()}
                    isRequiredMsg={selectedJurisdiction === JURISDICTIONS_NAMES.WASHINGTON_STATE ? 'ERR_EPA_OR_WS_GREENHOUSE_GAS_REPORTING_ID_IS_REQUIRED' : 'ERR_GHG_EMISSIONS_REPORTING_ID_IS_REQUIRED'}
                    rules={{
                      required: `${headerNameLabel} Required`,
                      validate: (data) => userIsUnique(data, 'checkValidUser'),
                      minLength: {
                        value: 6,
                        message: `${t(t1, selectedJurisdiction === JURISDICTIONS_NAMES.WASHINGTON_STATE ? 'ERR_EPA_OR_WS_GREENHOUSE_GAS_REPORTING_ID_MIN_LENGTH_CHAR' : 'ERR_GHG_EMISSIONS_REPORTING_ID_MIN_LENGTH_CHAR')}`,
                      },
                      maxLength: {
                        value: 20,
                        message: `${t(t1, selectedJurisdiction === JURISDICTIONS_NAMES.WASHINGTON_STATE ? 'ERR_EPA_OR_WS_GREENHOUSE_GAS_REPORTING_ID_MAX_LENGTH_CHAR' : 'ERR_GHG_EMISSIONS_REPORTING_ID_MAX_LENGTH_CHAR')}`,
                      },
                    }}
                    trimLeadingSpaces
                    InputProps={{
                      'aria-required': 'true',
                    }}
                    onKeyDown={(_) => {
                      if (_.code === 'Space') _.preventDefault();
                    }}
                    customeStyle={'Style.iconWrapper'}
                    gridStyles={'Style.iconWrapper'}
                    stepNumber={2}
                    gridMd={4}
                    subLabel={
                      <CustomizeToolTip
                        title={
                          <Typography
                            color="inherit"
                            variant="p"
                          >
                            {t(t1, 'INFO_ABOUT_GHG_EMISSION_ID')}
                          </Typography>
                        }
                        placement="right-start"
                      >
                        <InfoIcon sx={{ color: '#4d8da4', width: '14px', height: '14px' }} />
                      </CustomizeToolTip>
                    }
                    disabled={!isEditable}
                  />
                  <span>{ghgexistingMessage}</span>

                  {[JURISDICTIONS_NAMES.QUEBEC, JURISDICTIONS_NAMES.NOVA_SCOTIA]?.includes(
                    entityData?.jurisdiction?.Name,
                  ) && (
                    <CustomAutocomplete
                      privId="priv_txt_naicscode"
                      onClose={() => methods.trigger('NAICSCode')}
                      name="NAICSCode"
                      noValueText="Select..."
                      label={t(t1, 'NAICS_CODE')}
                      options={naicsData?.map((each) => ({ name: each.label, value: each.id }))}
                      rules={{ required: t(t1, 'NAICS_CODE_REQUIRED') }}
                      isRequiredMsg={'NAICS_CODE_REQUIRED'}
                      InputProps={{
                        'aria-required': 'true',
                      }}
                      className="input-group"
                      handleOnChange={(e) => {
                        const ncObject = naicsData?.find((item) => item?.id === e?.value);
                        setNAICSCode(ncObject);
                      }}
                      stepNumber={2}
                      gridMd={4}
                      disabled={!isEditable}
                      customeStyle={(disableOnInRegistry || !isEditable) ? Style.disabledLabelStyle : ''}
                    />
                  )}
                </Grid>, 'addedFacilities', 'ghgEmissionsReportingId', fieldPermissions)}
              </FormCard>
            )}

            {(showForm || externalShowFormTrigger && stepData && conditionToShow()) && (
              <FormCardAddFacility
                title={t(t1, 'FACILITY_INFORMATION_FOR_FACILITY_MANAGEMENT')}
                description={t(t1, 'INDICATE_REQUIRED_FIELDS')}
                setDefaultValues={setDefaultValues}
                stepNumber={6}
                handleSameAddress={handleUseEntity}
                formLabel={t(t1, 'USE_ENTITY_DATA')}
                indicateRequired={!isRevisionRequested}
                customClass={'customFormCard'}
                customeStyle={Style.formCardPadding}
                defaultChecked={disableUseCheck || methods?.getValues('useEntityData')}
                disableCheckOnReview={disableOnInRegistry || !isEditable}
                useEntityChecked={methods?.getValues('useEntityData')}
              >
                <Grid
                  container
                  spacing={2.5}
                  sx={{ mt: 1 }}
                >
                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-25px !important' }}
                  >
                    <span style={{ display: 'none' }}><GridTextfield
                      customeStyle={{ display: 'none' }}
                      name="facilityId"
                    />

                      <GridTextfield
                        sx={{ display: 'none' }}
                        customeStyle={{ display: 'none' }}
                        name="status"
                      />
                    </span>

                    <GridTextfield
                      componentName="facility"
                      fieldName="legalName"
                      customeStyle={disableUseCheck ? Style.disabledLabelStyle : ''}
                      inputClass={disableUseCheck ? 'disabledField' : ''}
                      name="facilityName"
                      label={t(t1, 'FACILITY_NAME')}
                      rules={{
                        required: true,
                        validate: (v) =>
                          methods?.getValues('facilityName')
                            ? verifyIdentificationNumberWithoutSpecialCharacters(v, ``, t1, t(t1, 'ERR_FACILITY_NAME_MIN_LENGTH_CHAR'), t(t1, 'ERR_FACILITY_NAME_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_FACILITY_NAME'), {
                              minLength: 3,
                              maxLength: 255,
                            })
                            : null,
                      }}
                      trimLeadingSpaces
                      isRequiredMsg={'ERR_FACILITY_NAME_IS_REQUIRED'}
                      stepNumber={2}
                      gridMd={12}
                      disabled={disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')}
                    />
                  </Grid>, 'facility', 'legalName', fieldPermissions)}

                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-25px !important' }}
                  >
                    <GridTextfield
                      componentName="facility"
                      fieldName="operatingName"
                      customeStyle={disableUseCheck ? Style.disabledLabelStyle : ''}
                      inputClass={disableUseCheck ? 'disabledField' : ''}
                      name="operatingName"
                      label={t(t1, 'OPERATING_NAME')}
                      rules={{
                        required: true,
                        validate: (v) =>
                          methods?.getValues('operatingName')
                            ? verifyIdentificationNumberWithoutSpecialCharacters(v, ``, t1, t(t1, 'ERR_OPERATING_NAME_MIN_LENGTH_CHAR'), t(t1, 'ERR_OPERATING_NAME_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_OPERATING_NAME'), {
                              minLength: 3,
                              maxLength: 255,
                            })
                            : null,
                      }}
                      trimLeadingSpaces
                      isRequiredMsg={'ERR_OPERATING_NAME_IS_REQUIRED'}
                      stepNumber={2}
                      gridMd={12}
                      disabled={disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')}
                    />
                  </Grid>, 'facility', 'operatingName', fieldPermissions)}
                </Grid>
              </FormCardAddFacility>
            )}
            {(showForm || externalShowFormTrigger && stepData && conditionToShow()) && (
              <FormCard
                customeStyle={Style.formCardPaddingOnAdd}
                title={t(t1, 'FACILITY_PHYSICAL_ADDRESS')}
                indicateRequired={!isRevisionRequested}
                description={t(t1, 'INDICATE_REQUIRED_FIELDS')}
                setDefaultValues={setDefaultValues}
                stepNumber={3}
              >
                <Grid
                  container
                  spacing={2.5}
                  sx={{ mt: 1 }}
                >
                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-35px !important' }}
                  >
                    <GridTextfield
                      componentName="facilityPhysicalAddress"
                      fieldName="street1"
                      customeStyle={disableUseCheck ? Style.disabledLabelStyle : ''}
                      inputClass={disableUseCheck ? 'disabledField' : ''}
                      name="physicalAddress.street1"
                      label={t(t1, 'STREET_1')}
                      rules={{
                        required: t(t1, 'STREET_1_REQUIRED'),
                        validate: (v) =>
                          methods?.getValues('physicalAddress.street1')
                            ? verifyStreet1orStreet2(v, ``, t1, t(t1, 'ERR_STREET_1_MIN_LENGTH_CHAR'), t(t1, 'ERR_STREET_1_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_STREET_1'), {
                              minLength: 1,
                              maxLength: 255,
                            })
                            : null,
                      }}
                      isRequiredMsg={'ERR_STREET_1_IS_REQUIRED'}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')}
                      onKeyUp={spreadSameAsPhysicalAddress}
                    />
                  </Grid>, 'facilityPhysicalAddress', 'street1', fieldPermissions)}

                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-35px !important' }}
                  >
                    <GridTextfield
                      componentName="facilityPhysicalAddress"
                      fieldName="street2"
                      customeStyle={disableUseCheck ? Style.disabledLabelStyle : '#333333'}
                      inputClass={disableUseCheck ? 'disabledField' : ''}
                      name="physicalAddress.street2"
                      label={t(t1, 'STREET_2')}
                      rules={{
                        validate: (v) =>
                          methods?.getValues('physicalAddress.street2')
                            ? verifyStreet1orStreet2(v, ``, t1, t(t1, 'ERR_STREET_2_MIN_LENGTH_CHAR'), t(t1, 'ERR_STREET_2_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_STREET_2'), {
                              minLength: 1,
                              maxLength: 255,
                            })
                            : null,
                      }}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')}
                      onKeyUp={spreadSameAsPhysicalAddress}
                    />
                  </Grid>, 'facilityPhysicalAddress', 'street2', fieldPermissions)}
                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-35px !important' }}
                  >
                    <GridTextfield
                      componentName="facilityPhysicalAddress"
                      fieldName="city"
                      customeStyle={disableUseCheck ? Style.disabledLabelStyle : ''}
                      inputClass={disableUseCheck ? 'disabledField' : ''}
                      name="physicalAddress.city"
                      label={t(t1, 'CITY')}
                      rules={{
                        required: t(t1, 'CITY_REQUIRED'),
                        validate: (v) =>
                          methods?.getValues('physicalAddress.city')
                            ? verifyCityOrRegion(v, ``, t1, t(t1, 'ERR_CITY_MIN_LENGTH_CHAR'), t(t1, 'ERR_CITY_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_CITY'), {
                              minLength: 1,
                              maxLength: 255,
                            })
                            : null,
                      }}
                      isRequiredMsg={'ERR_CITY_IS_REQUIRED'}
                      trimLeadingSpaces
                      stepNumber={2}
                      disableCheck={!isEmpty(methods?.formState?.errors)}
                      gridMd={12}
                      disabled={disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')}
                      onKeyUp={spreadSameAsPhysicalAddress}
                    />
                  </Grid>, 'facilityPhysicalAddress', 'city', fieldPermissions)}
                </Grid>

                <Grid
                  container
                  spacing={2.5}
                  sx={{ mt: inProgressApplication ? 1.3 : 0 }}
                >
                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-35px !important' }}
                  >
                    <CustomAutocomplete
                      componentName="facilityPhysicalAddress"
                      fieldName="country"
                      customeStyle={(disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')) ? Style.disabledLabelStyle : ''}
                      onClose={() => methods.trigger('physicalAddress.country')}
                      className={disableUseCheck ? 'disabledField' : ''}
                      privId="priv_txt_select_ountry"
                      name="physicalAddress.country"
                      label={t(t1, 'COUNTRY')}
                      noValueText={t(t1, 'SELECT_COUNTRY')}
                      options={sortedCountryList?.map((each) => ({ name: t(t1, each.Name), value: each.ID }))}
                      rules={{ required: true }}
                      InputProps={{
                        'aria-required': 'true',
                      }}
                      isRequiredMsg={'ERR_COUNTRY_IS_REQUIRED'}
                      handleOnChange={(e) => {
                        const fromUserChange = 'userChanged';
                        updatePhysicalStateByCountry(e?.value, fromUserChange);
                        methods?.setValue('physicalAddress.stateOrProvince', '');
                        methods?.setValue('physicalAddress.region', '');
                        spreadSameAsPhysicalAddress();
                      }}
                      stepNumber={2}
                      gridMd={12}
                      disabled={disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')}
                    />
                  </Grid>, 'facilityPhysicalAddress', 'country', fieldPermissions)}

                  {!showPhysicalRegion && (
                    SopfDetailView(<Grid
                      item
                      xs={4}
                      sx={{ mt: '-35px !important' }}
                    >
                      <CustomAutocomplete
                        componentName="facilityPhysicalAddress"
                        fieldName="state"
                        noValidate
                        onClose={() => methods.trigger('physicalAddress.stateOrProvince')}
                        customeStyle={(disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')) ? Style.disabledLabelStyle : ''}
                        className={disableUseCheck ? 'disabledField' : ''}
                        privId="priv_txt_state_province"
                        name="physicalAddress.stateOrProvince"
                        noValueText={t(t1, 'SELECT_STATE_OR_PROVINCE')}
                        label={t(t1, 'STATE_OR_PROVINCE')}
                        options={methods.getValues('physicalAddress.country') ?
                          physicalState && physicalState.map((each) => ({
                            name: t(t1, each.Name),
                            value: each.ID,
                          })) : []
                        }
                        rules={{ required: true }}
                        isRequiredMsg={'ERR_STATE_OR_PROVINCE_IS_REQUIRED'}
                        InputProps={{
                          'aria-required': 'true',
                        }}
                        handleOnChange={(e) => {
                          spreadSameAsPhysicalAddress();
                        }}
                        stepNumber={2}
                        gridMd={12}
                        disabled={disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')}
                      />
                    </Grid>, 'facilityPhysicalAddress', 'state', fieldPermissions)
                  )}
                  {showPhysicalRegion && (
                    SopfDetailView(<Grid
                      item
                      xs={4}
                      sx={{ mt: '-35px !important' }}
                    >
                      <GridTextfield
                        componentName="facilityPhysicalAddress"
                        fieldName="region"
                        noValidate
                        customeStyle={disableUseCheck ? Style.disabledLabelStyle : ''}
                        inputClass={disableUseCheck ? 'disabledField' : ''}
                        name="physicalAddress.region"
                        label={t(t1, 'REGION')}
                        rules={{
                          required: true,
                          validate: (v) =>
                            methods?.getValues('physicalAddress.region')
                              ? verifyIdentificationNumberWithoutSpecialCharacters(v, ``, t1, t(t1, 'ERR_REGION_MIN_LENGTH_CHAR'), t(t1, 'ERR_REGION_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_REGION'), {
                                minLength: 1,
                                maxLength: 255,
                              })
                              : null,
                        }}
                        trimLeadingSpaces
                        isRequiredMsg={'ERR_REGION_IS_REQUIRED'}
                        stepNumber={2}
                        gridMd={12}
                        disabled={disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')}
                        onKeyUp={spreadSameAsPhysicalAddress}
                      />
                    </Grid>, 'facilityPhysicalAddress', 'region', fieldPermissions)
                  )}

                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-35px !important' }}
                  >
                    <GridTextfield
                      componentName="facilityPhysicalAddress"
                      fieldName="postalCode"
                      customeStyle={disableUseCheck ? Style.disabledLabelStyle : ''}
                      inputClass={disableUseCheck ? 'disabledField' : ''}
                      name="physicalAddress.postalCode"
                      label={t(t1, 'POSTAL_CODE')}
                      rules={{
                        required: true,
                        validate: (v) =>
                          methods?.getValues('physicalAddress.postalCode')
                            ? validatingPostalCode(v, ``, t1, { minLength: 3, maxLength: 20 })
                            : null,
                      }}
                      trimLeadingSpaces
                      isRequiredMsg={'ERR_POSTAL_CODE_IS_REQUIRED'}
                      stepNumber={2}
                      gridMd={12}
                      disabled={disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')}
                      onKeyUp={spreadSameAsPhysicalAddress}
                    />
                  </Grid>, 'facilityPhysicalAddress', 'postalCode', fieldPermissions)}
                </Grid>
              </FormCard>
            )}
            {(showForm || externalShowFormTrigger && stepData && conditionToShow()) && (
              <FormCardAddFacility
                customClass={'Identifiers customFormCard mailing-address'}
                customeStyle={Style.formCardPadding}
                title={t(t1, 'FACILITY_MAILING_ADDRESS')}
                indicateRequired={!isRevisionRequested}
                description={t(t1, 'INDICATE_REQUIRED_FIELDS')}
                formLabel={t(t1, 'CHECK_IF_SAME_AS_PHYSICAL_ADDRESS_CHECKBOX')}
                setDefaultValues={setDefaultValues}
                handleSameAddress={handleSameAddressCheck}
                stepNumber={3}
                defaultChecked={methods?.getValues('mailingSameAsPhysical')}
                disableCheckOnReview={disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')}
              >
                <Grid
                  container
                  spacing={2.5}
                  sx={{ mt: 1 }}
                >
                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-35px !important' }}
                  >
                    <GridTextfield
                      componentName="facilityMailingAddress"
                      fieldName="street1"
                      customeStyle={disableUseCheck ? Style.disabledLabelStyle : ''}
                      inputClass={disable || disableUseCheck ? 'disabledField' : ''}
                      name="mailingAddress.street1"
                      label={t(t1, 'STREET_1')}
                      rules={{
                        required: true,
                        validate: (v) =>
                          methods?.getValues('mailingAddress.street1')
                            ? verifyStreet1orStreet2(v, ``, t1, t(t1, 'ERR_STREET_1_MIN_LENGTH_CHAR'), t(t1, 'ERR_STREET_1_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_STREET_1'), {
                              minLength: 1,
                              maxLength: 255,
                            })
                            : null,
                      }}
                      isRequiredMsg={'ERR_STREET_1_IS_REQUIRED'}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={disable || disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')}
                    />
                  </Grid>, 'facilityMailingAddress', 'street1', fieldPermissions)}

                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-35px !important' }}
                  >
                    <GridTextfield
                      componentName="facilityMailingAddress"
                      fieldName="street2"
                      customeStyle={disableUseCheck ? Style.disabledLabelStyle : ''}
                      inputClass={disable || disableUseCheck ? 'disabledField' : ''}
                      name="mailingAddress.street2"
                      label={t(t1, 'STREET_2')}
                      rules={{
                        validate: (v) =>
                          methods?.getValues('mailingAddress.street2')
                            ? verifyStreet1orStreet2(v, ``, t1, t(t1, 'ERR_STREET_2_MIN_LENGTH_CHAR'), t(t1, 'ERR_STREET_2_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_STREET_2'), {
                              minLength: 1,
                              maxLength: 255,
                            })
                            : null,
                      }}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={disable || disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')}
                    />
                  </Grid>, 'facilityMailingAddress', 'street2', fieldPermissions)}
                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-35px !important' }}
                  >
                    <GridTextfield
                      componentName="facilityMailingAddress"
                      fieldName="city"
                      customeStyle={disableUseCheck ? Style.disabledLabelStyle : ''}
                      inputClass={disable || disableUseCheck ? 'disabledField' : ''}
                      name="mailingAddress.city"
                      label={t(t1, 'CITY')}
                      rules={{
                        required: true,
                        validate: (v) =>
                          methods?.getValues('mailingAddress.city')
                            ? verifyCityOrRegion(v, ``, t1, t(t1, 'ERR_CITY_MIN_LENGTH_CHAR'), t(t1, 'ERR_CITY_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_CITY'), {
                              minLength: 1,
                              maxLength: 255,
                            })
                            : null,
                      }}
                      isRequiredMsg={'ERR_CITY_IS_REQUIRED'}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={disable || disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')}
                    />
                  </Grid>, 'facilityMailingAddress', 'city', fieldPermissions)}
                </Grid>

                <Grid
                  container
                  spacing={2.5}
                  sx={{ mt: inProgressApplication ? 1.3 : 0 }}
                >
                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-35px !important' }}
                  >
                    <CustomAutocomplete
                      componentName="facilityMailingAddress"
                      fieldName="country"
                      customeStyle={(disable || disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')) ? Style.disabledLabelStyle : ''}
                      className={disable || disableUseCheck ? 'disabledField' : ''}
                      privId="priv_txt_select_ountry"
                      onClose={() => methods.trigger('mailingAddress.country')}
                      name="mailingAddress.country"
                      label={t(t1, 'COUNTRY')}
                      noValueText={t(t1, 'SELECT_COUNTRY')}
                      options={sortedCountryList?.map((each) => ({ name: t(t1, each.Name), value: each.ID }))}
                      handleOnChange={(e) => {
                        const fromUserChange = 'userChanged';
                        updateMailingStateByCountry(e?.value, fromUserChange);
                        methods?.setValue('mailingAddress.stateOrProvince', '');
                        methods?.setValue('mailingAddress.region', '');
                      }}
                      rules={{ required: true }}
                      InputProps={{
                        'aria-required': 'true',
                      }}
                      isRequiredMsg={'ERR_COUNTRY_IS_REQUIRED'}
                      stepNumber={2}
                      gridMd={12}
                      disabled={disable || disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')}
                    />
                  </Grid>, 'facilityMailingAddress', 'country', fieldPermissions)}

                  {!showMailingRegion && (
                    SopfDetailView(<Grid
                      item
                      xs={4}
                      sx={{ mt: '-35px !important' }}
                    >
                      <CustomAutocomplete
                        componentName="facilityMailingAddress"
                        fieldName="state"
                        noValidate
                        customeStyle={(disable || disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')) ? Style.disabledLabelStyle : ''}
                        className={disable || disableUseCheck ? 'disabledField' : ''}
                        privId="priv_txt_state_province"
                        onClose={() => methods.trigger('mailingAddress.stateOrProvince')}
                        name="mailingAddress.stateOrProvince"
                        noValueText={t(t1, 'SELECT_STATE_OR_PROVINCE')}
                        label={t(t1, 'STATE_OR_PROVINCE')}
                        options={methods?.getValues('mailingAddress.country') ?
                          mailingState && mailingState.map((each) => ({ name: t(t1, each.Name), value: each.ID })) : []
                        }
                        rules={{ required: true }}
                        InputProps={{
                          'aria-required': 'true',
                        }}
                        isRequiredMsg={'ERR_STATE_OR_PROVINCE_IS_REQUIRED'}
                        stepNumber={2}
                        gridMd={12}
                        disabled={disable || disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')}
                      />
                    </Grid>, 'facilityMailingAddress', 'state', fieldPermissions)
                  )}

                  {showMailingRegion && (
                    SopfDetailView(<Grid
                      item
                      xs={4}
                      sx={{ mt: '-35px !important' }}
                    >
                      <GridTextfield
                        componentName="facilityMailingAddress"
                        fieldName="region"
                        noValidate
                        customeStyle={disableUseCheck ? Style.disabledLabelStyle : ''}
                        inputClass={disable || disableUseCheck ? 'disabledField' : ''}
                        name="mailingAddress.region"
                        label={t(t1, 'REGION')}
                        rules={{
                          required: true,
                          validate: (v) =>
                            methods?.getValues('mailingAddress.region')
                              ? verifyCityOrRegion(v, ``, t1, t(t1, 'ERR_REGION_MIN_LENGTH_CHAR'), t(t1, 'ERR_REGION_MAX_LENGTH_CHAR'), t(t1, 'ERR_INVALID_REGION'), {
                                minLength: 1,
                                maxLength: 255,
                              })
                              : null,
                        }}
                        trimLeadingSpaces
                        isRequiredMsg={'ERR_REGION_IS_REQUIRED'}
                        stepNumber={2}
                        gridMd={12}
                        disabled={disable || disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')}
                      />
                    </Grid>, 'facilityMailingAddress', 'region', fieldPermissions)
                  )}

                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-35px !important' }}
                  >
                    <GridTextfield
                      componentName="facilityMailingAddress"
                      fieldName="postalCode"
                      customeStyle={disableUseCheck ? Style.disabledLabelStyle : ''}
                      inputClass={disable || disableUseCheck ? 'disabledField' : ''}
                      name="mailingAddress.postalCode"
                      label={t(t1, 'POSTAL_CODE')}
                      rules={{
                        required: true,
                        validate: (v) =>
                          methods?.getValues('mailingAddress.postalCode')
                            ? validatingPostalCode(v, ``, t1, { minLength: 3, maxLength: 20 })
                            : null,
                      }}
                      isRequiredMsg={'ERR_POSTAL_CODE_IS_REQUIRED'}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={disable || disableOnInRegistryOrUseCheck || !isEditable || methods?.getValues('useEntityData')}
                    />
                  </Grid>, 'facilityMailingAddress', 'postalCode', fieldPermissions)}
                </Grid>
              </FormCardAddFacility>
            )}
            {((showForm || externalShowFormTrigger) && stepData && conditionToShow()) && (
              <FormCard
                customeStyle={Style.formCardPaddingOnAdd}
                title={t(t1, 'FACILITY_GHG_REPORTER_CONTACT')}
                setDefaultValues={setDefaultValues}
                stepNumber={4}
                indicateRequired={false}
              >
                <Grid
                  container
                  spacing={2.5}
                  sx={{ mt: 1 }}
                >
                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-24px !important' }}
                  >
                    <GridTextfield
                      componentName="facilityContact"
                      fieldName="firstName"
                      name="contactInfo.firstName"
                      label={t(t1, 'CONTACT_FIRST_NAME')}
                      customeStyle={(!isEmpty(AlternateContact?.data?.firstName) && (disableUseCheck || disableGHGField)) ? Style.disabledLabelStyle : ''}
                      inputClass={(!isEmpty(AlternateContact?.data?.firstName) && (disableUseCheck || disableGHGField)) ? 'disabledField' : ''}
                      rules={{
                        maxLength: {
                          value: 50,
                          message: t(t1, 'ERR_CONTACT_FIRST_NAME_MAX_LENGTH_CHAR'),
                        },
                        validate: (v) =>
                          methods?.getValues('contactInfo.firstName')
                            ? validatePattern(v, t1, t(t1, 'CONTACT_FIRST_NAME'), firstnamePattern, t(t1, 'ERR_INVALID_CONTACT_FIRST_NAME'))
                            : null,
                      }}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={(!isEmpty(AlternateContact?.data?.firstName) && (disableUseCheck || disableGHGField)) || !isEditable}
                    />
                  </Grid>, 'facilityContact', 'firstName', fieldPermissions)}

                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-24px !important' }}
                  >
                    <GridTextfield
                      componentName="facilityContact"
                      fieldName="lastName"
                      name="contactInfo.lastName"
                      label={t(t1, 'CONTACT_LAST_NAME')}
                      customeStyle={(!isEmpty(AlternateContact?.data?.lastName) && (disableUseCheck || disableGHGField)) ? Style.disabledLabelStyle : ''}
                      inputClass={(!isEmpty(AlternateContact?.data?.lastName) && (disableUseCheck || disableGHGField)) ? 'disabledField' : ''}
                      rules={{
                        maxLength: {
                          value: 100,
                          message: t(t1, 'ERR_CONTACT_LAST_NAME_MAX_LENGTH_CHAR'),
                        },
                        validate: (v) =>
                          methods?.getValues('contactInfo.lastName')
                            ? validatePattern(v, t1, t(t1, 'CONTACT_LAST_NAME'), lastNamePattern, t(t1, 'ERR_INVALID_CONTACT_LAST_NAME'))
                            : null,
                      }}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={(!isEmpty(AlternateContact?.data?.lastName) && (disableUseCheck || disableGHGField)) || !isEditable}
                    />
                  </Grid>, 'facilityContact', 'lastName', fieldPermissions)}
                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-24px !important' }}
                  >
                    <GridTextfield
                      componentName="facilityContact"
                      fieldName="jobTitle"
                      name="contactInfo.jobTitle"
                      label={t(t1, 'CONTACT_JOB_TITLE')}
                      customeStyle={(!isEmpty(AlternateContact?.data?.position) && (disableUseCheck || disableGHGField)) ? Style.disabledLabelStyle : ''}
                      inputClass={(!isEmpty(AlternateContact?.data?.position) && (disableUseCheck || disableGHGField)) ? 'disabledField' : ''}
                      rules={{
                        maxLength: {
                          value: 255,
                          message: t(t1, 'ERR_CONTACT_JOB_TITLE_MAX_LENGTH_CHAR'),
                        },
                        validate: (v) =>
                          methods?.getValues('contactInfo.jobTitle')
                            ? validatePattern(v, t(t1, 'CONTACT_JOB_TITLE'), t1, jobtitleEmployerPattern, t(t1, 'ERR_INVALID_CONTACT_JOB_TITLE'))
                            : null,
                      }}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={(!isEmpty(AlternateContact?.data?.position) && (disableUseCheck || disableGHGField)) || !isEditable}
                    />
                  </Grid>, 'facilityContact', 'jobTitle', fieldPermissions)}
                </Grid>
                <Grid
                  container
                  spacing={2.5}
                  sx={{ mt: inProgressApplication ? 0 : -1.3 }}
                >
                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-24px !important' }}
                  >
                    <GridTextfield
                      componentName="facilityContact"
                      fieldName="telephoneNumber"
                      name="contactInfo.phone"
                      label={t(t1, 'CONTACT_PHONE_NUMBER')}
                      customeStyle={(!isEmpty(AlternateContact?.data?.telephoneNumber) && (disableUseCheck || disableGHGField)) ? Style.disabledLabelStyle : ''}
                      inputClass={(!isEmpty(AlternateContact?.data?.telephoneNumber) && (disableUseCheck || disableGHGField)) ? 'disabledField' : ''}
                      rules={{
                        validate: (v) =>
                          methods?.getValues('contactInfo.phone')
                            ? validatePhoneNumber(v, `${t(t1, 'CONTACT_PHONE_NUMBER')}`, t1, `${t(t1, 'ERR_CONTACT_PHONE_NUMBER_MIN_LENGTH_CHAR')}`, `${t(t1, 'ERR_CONTACT_PHONE_NUMBER_MAX_LENGTH_CHAR')}`, `${t(t1, 'ERR_INVALID_CONTACT_PHONE_NUMBER')}`, '')
                            : null,
                      }}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={(!isEmpty(AlternateContact?.data?.telephoneNumber) && (disableUseCheck || disableGHGField)) || !isEditable}
                    />
                  </Grid>, 'facilityContact', 'telephoneNumber', fieldPermissions)}
                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-24px !important' }}
                  >
                    <GridTextfield
                      componentName="facilityContact"
                      fieldName="mobileNumber"
                      name="contactInfo.mobilePhone"
                      label={t(t1, 'CONTACT_MOBILE_NUMBER')}
                      customeStyle={(!isEmpty(AlternateContact?.data?.mobilePhone) && (disableUseCheck || disableGHGField)) ? Style.disabledLabelStyle : ''}
                      inputClass={(!isEmpty(AlternateContact?.data?.mobilePhone) && (disableUseCheck || disableGHGField)) ? 'disabledField' : ''}
                      rules={{
                        validate: (v) =>
                          methods?.getValues('contactInfo.mobilePhone')
                            ? validatePhoneNumber(v, `${t(t1, 'CONTACT_MOBILE_NUMBER')}`, t1, `${t(t1, 'ERR_CONTACT_MOBILE_NUMBER_MIN_LENGTH_CHAR')}`, `${t(t1, 'ERR_CONTACT_MOBILE_NUMBER_MAX_LENGTH_CHAR')}`, `${t(t1, 'ERR_INVALID_CONTACT_MOBILE_NUMBER')}`, '')
                            : null,
                      }}
                      trimLeadingSpaces
                      stepNumber={2}
                      gridMd={12}
                      disabled={(!isEmpty(AlternateContact?.data?.mobilePhone) && (disableUseCheck || disableGHGField)) || !isEditable}
                    />
                  </Grid>, 'facilityContact', 'mobileNumber', fieldPermissions)}

                  {SopfDetailView(<Grid
                    item
                    xs={4}
                    sx={{ mt: '-24px !important' }}
                  >
                    <GridTextfield
                      componentName="facilityContact"
                      fieldName="email"
                      name="contactInfo.email"
                      label={t(t1, 'CONTACT_EMAIL_ADDRESS')}
                      customeStyle={(!isEmpty(AlternateContact?.data?.email) && (disableUseCheck || disableGHGField)) ? Style.disabledLabelStyle : ''}
                      inputClass={(!isEmpty(AlternateContact?.data?.email) && (disableUseCheck || disableGHGField)) ? 'disabledField' : ''}
                      rules={{
                        maxLength: {
                          value: 205,
                          message: `${t(t1, 'ERR_CONTACT_EMAIL_ADDRESS_MAX_LENGTH_CHAR')}`,
                        },
                        pattern: {
                          value: emailPatternValidation,
                          message: `${t(t1, 'ERR_INVALID_CONTACT_EMAIL_ADDRESS')}`,
                        },
                      }}
                      autoComplete="off"
                      stepNumber={2}
                      gridMd={12}
                      disabled={(!isEmpty(AlternateContact?.data?.email) && (disableUseCheck || disableGHGField)) || !isEditable}
                    />
                  </Grid>, 'facilityContact', 'emailAddress', fieldPermissions)}
                </Grid>
              </FormCard>
            )}
          </div>
        ) : null}

        <Grid
          container
          md={12}
          fullWidth
          sx={{
            mt: EntitiesData?.data?.entityStatus?.name === ENTITY_APPLICTION_STATUS_NAMES.PENDING || ENTITY_APPLICTION_STATUS_NAMES.REVISIONS_REQUESTED ? 0 : 2.5,
            ml: -1.25,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {!isJuryAdminUser && conditionToShow() && !saveButton && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2.5 }}>
              {(isProposed || isRevisionRequested) && (
                <>
                  <CustomizeButton
                    privId="priv_btn_continue"
                    variant="contained"
                    type="submit"
                    color="primary"
                    label={t(t1, 'CANCEL')}
                    handleClick={() => {
                      setShowWarningModal(true);
                    }}
                    style={{ marginLeft: '0' }}
                    customStyle={{
                      backgroundColor: '#FFFFFF',
                      color: '#4D8DA4',
                      border: '1px solid #C7C8CA',
                      '&:hover': { backgroundColor: '#FFFFFF', border: '1px solid #C7C8CA' },
                    }}
                    disabled={!isEditable}
                  />

                  <CustomizeButton
                    privId="priv_btn_continue"
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={isDisabled}
                    label={t(t1, 'ADD')}
                    handleClick={(e) => {
                      if (passPhraseFeature) {
                        setShowPassphraseDialogForAddFacility(true);
                      } else {
                        const street2mailingAddress = methods.getValues('mailingAddress.street2');
                        const street2physicalAddress = methods.getValues('physicalAddress.street2');
                        // Making Sure that the value is null of street2 of mailing address and physical address, if street2 have falsy value.
                        if (!!street2mailingAddress === false && !!street2physicalAddress === false) {
                          methods.setValue('mailingAddress.street2', null);
                          methods.setValue('physicalAddress.street2', null);
                        }
                        const facility = methods.getValues();
                        methods?.handleSubmit(addFacilityHandler)(e);
                        proposedFacilityChange?.([facility]);
                      }
                      methods?.clearErrors();
                    }}
                    style={{ marginLeft: '0' }}
                  /></>

              )}
              {showForm && (<>
                {!(isProposed || isRevisionRequested) && <>
                  {(rows?.length !== 0 || usage === COMPONENT_NAMES.REVIEW_AND_SUBMIT)
                    ?
                    <CustomizeButton
                      privId="priv_btn_continue"
                      variant="contained"
                      type="submit"
                      color="primary"
                      csName="rc cancelBtnAction"
                      label={t(t1, 'CANCEL')}
                      handleClick={handleModalContinue}
                      style={{ marginLeft: '0' }}
                    />
                    :
                    <CustomizeButton
                      privId="priv_btn_continue"
                      variant="contained"
                      csName="rc"
                      type="submit"
                      color="primary"
                      label={t(t1, 'EXIT_APPLICATION')}
                      handleClick={(e) => {
                        dispatch(setDialogMessage(true));
                        setExitDialog(true);
                      }}
                      style={{ marginLeft: '0' }}
                    />
                  }
                  {exitDialog && dialogMessage &&
                    <DialogMessage
                      borderColor="#4d8da4"
                      title={t(t1, 'EXIT_APPLICATION')}
                      message1={t(t1, 'EXIT_APPLICATION_WARNING_CONTENT1')}
                      message2={t(t1, 'EXIT_APPLICATION_WARNING_CONTENT2')}
                      buttonMessage={t(t1, 'EXIT_APPLICATION_WARNING_CONTENT3')}
                      handleModalClick={() => {
                        navigate('/home');
                      }}
                    />}
                </>}
                {!isRevisionRequested && !isProposed &&
                  <CustomizeButton // multiple ADD buttons on request revision page
                    privId="priv_btn_continue"
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={isDisabled}
                    label={t(t1, 'ADD')}
                    handleClick={(e) => {
                      const facility = methods.getValues();
                      methods?.handleSubmit(addFacilityHandler)(e);
                      proposedFacilityChange?.([facility]);
                      switchFacilityMode?.(COMPONENT_MODES.EDIT);
                      methods.clearErrors();
                      //  handleSubmitChanges?.();
                    }}
                    style={{ marginLeft: '0' }}
                  />}
              </>)}
            </Box>
          )}
          {
            isJuryAdminUser && !hideDataGrid && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>

                <CustomizeButton
                  privId="priv_btn_continue"
                  variant="contained"
                  type="submit"
                  color="primary"
                  label={t(t1, 'CANCEL')}
                  handleClick={(e) => {
                    methods.reset();
                    navigate(-1);
                  }}
                  style={{ marginLeft: '0' }}
                  disabled={!isEditable}
                  customStyle={{
                    backgroundColor: '#FFFFFF',
                    color: '#4D8DA4',
                    border: '1px solid #C7C8CA',
                    '&:hover': { backgroundColor: '#FFFFFF', border: '1px solid #C7C8CA' },
                  }}
                />

                <CustomizeButton
                  privId="priv_btn_continue"
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={isDisabled}
                  label={t(t1, 'ADD_FACILITY')}
                  handleClick={
                    handleApproveDenyChanges
                  }
                  style={{ marginLeft: '0' }}
                />

              </Box>
            )
          }
          {showWarningModal && (
            <CustomeDialogMessage
              title={t(t1, 'WARNING')}
              continueBtn={t(t1, 'CONTINUE')}
              open={showWarningModal}
              handleModalCancel={() => {
                setShowWarningModal(false);
              }}
              handleClose={() => {
                setShowWarningModal(false);
              }}
              message1={t(t1, 'CANCEL_FACILITY_EDIT')}
              message3={t(t1, 'CONTINUE_FACILITY_EDIT')}
              handleContinue={handleModalContinue}
              borderColor="red"
            />
          )}
          <PassphraseConfirmationDialog
            title={t(t1, 'FACILITY_PROPOSED_CHANGE')}
            cancelButtonLabel={t(t1, 'CANCEL')}
            confirmButtonLabel={t(t1, 'CONFIRM')}
            pageName={'Page_UserHome'}
            onPassphraseSuccess={() => {
              continueSubmit();
            }}
            handleCloseDialog={() => {
              setShowPassPhraseDialogue(false);
            }}
            onPassphraseFailure={() => {
            }}
            open={showPassPhraseDialogue}
          />
          {conditionToShow() && saveButton && (
            <Grid container justifyContent="flex-end" md={12}>
              <Grid item>
                <CustomizeButton
                  privId="priv_btn_continue"
                  variant="outlined"
                  csName="rc"
                  color="primary"
                  label={t(t1, 'CANCEL')}
                  handleClick={() => {
                    if (Object.values(methods?.formState?.dirtyFields || {}).some(Boolean)) {
                      setShowWarningModal(true);
                    } else {
                      handleModalContinue();
                    }
                  }}
                  disabled={!isEditable}

                />
                <CustomizeButton
                  variant="contained"
                  color="primary"
                  label={t(t1, 'SAVE')}
                  disabled={(isDisabled || methods && !methods?.formState?.isValid || !Object.values(methods?.formState?.dirtyFields || {}).some(Boolean))}
                  handleClick={showPassPharseOnCoreValueChange}
                />

              </Grid>
            </Grid>
          )}
          {showWarningModal && (
            <CustomeDialogMessage
              title={t(t1, 'WARNING')}
              continueBtn={t(t1, 'CONTINUE')}
              open={showWarningModal}
              handleModalCancel={() => {
                setShowWarningModal(false);
              }}
              handleClose={() => {
                setShowWarningModal(false);
              }}
              message1={t(t1, 'CANCEL_FACILITY_EDIT')}
              message3={t(t1, 'CONTINUE_FACILITY_EDIT')}
              handleContinue={handleModalContinue}
              borderColor="red"
            />
          )}
        </Grid>
        <UnsavedPrompt when={!isExitInProgress && displayHead} title={t(t1, 'WARNING')}
                       message={t(t1, 'CONFIRM_LEAVE_PAGE')} onSuccess={resetData} id={id} />
      </FormProvider>
      <div style={{ float: 'right', marginTop: '-40px' }}>
        <FormProvider {...methodsForContinue}>
          {!showForm && !saveButton && mode === COMPONENT_MODES.ADD && (
            <FormActions
              continueDisabled={rows.length === 0}
              handleSubmit={() => {
                onSubmit(rows);
              }}
              pageName="ADD_FACILITY"
            />
          )}
        </FormProvider>
      </div>

      {/* {showConfirmationPopUp ? (
        <PassphraseConfirmationDialog
          title={t(t1, "ENTER_PASSPHRASE_TO_SUBMIT")}
          cancelButtonLabel={t(t1, "CANCEL")}
          confirmButtonLabel={t(t1, "SUBMIT")}
          pageName={"Page_UserHome"}
          onPassphraseSuccess={AddFacilityAfterPassPharseSuccess}
          handleCloseDialog={() => setShowConfirmationPopUp(false)}
          open={showConfirmationPopUp}
        />
      ) : null} */}
      {showConfirmationPopUp && (
        <CustomeDialogMessage
          title={t(t1, 'SAVE')}
          continueBtn={t(t1, 'CONTINUE')}
          open={showConfirmationPopUp}
          handleModalCancel={() => {
            setShowConfirmationPopUp(false);
          }}
          handleClose={() => {
            setShowConfirmationPopUp(false);
          }}
          message1={t(t1, 'CANCEL_REVERT_MSG')}
          message3={t(t1, 'CONTINUE_FACILITY_EDIT')}
          handleContinue={() => {
            if (passPhraseFeature) {
              setShowPassphraseDialogForEditFacility(true);
            } else {
              handleEditSaveClick();
            }
          }}
          borderColor="#4d8da4"
        />
      )}

      {/* Cancel application using 3 dot */}
      {showModal && selectedRetireRow && (
        <FormProvider {...retiredMethods}>
          <CustomeDialogMessage
            title={modalTitle}
            handleButtonClick={getConfirmation}
            handleModalCancel={() => {
              dispatch(setHideModal());
              setselectedRetireRow(null);
            }}
            open={showModal}
            message1={modalBodyMessage}
            message3={entityData?.EntityType?.Name === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY ?
              t(t1, 'CANCEL_ENTITY_APPLICATION_CONTENT_LINE3') : ''}
            message4={t(t1, 'CANCEL_ENTITY_APPLICATION_CONTENT_LINE4')}
            buttonDisplay={modalConfirmButton}
            closeBtnDisplay
            borderColor="#4d8da4"
            disabled={retiredMethods.formState?.isValid}
          >

            <Grid item md={12} sx={{ mt: 1 }} className="no-lable-field">
              <GridTextfield
                gridMd={12}
                name="comment"
                multiline
                className="input-group"
                rows={2}
                rules={{
                  required: true,
                  maxLength: {
                    value: COMMENT_MAX_CHARACTER_MODULE,
                    message: `${t(t1, 'ERR_COMMENTS_MAX_LENGTH_CHAR')}`,
                  },
                  validate: (v) => validateCommentModule(v, `${t(t1, 'COMMENTS')}`, t1),
                }}
                isRequiredMsg={'ERR_COMMENTS_IS_REQUIRED'}
              />
            </Grid>
          </CustomeDialogMessage>
        </FormProvider>
      )}
      {showRetireConfirmation && <CustomeDialogMessage
        title={t(t1, 'WARNING')}
        continueBtn={t(t1, 'CONTINUE')}
        open={showRetireConfirmation}
        handleModalCancel={() => {
          setShowRetireConfirmation(false);
        }}
        handleClose={() => {
          setShowRetireConfirmation(false);
        }}
        message1={t(t1, 'CANCEL_FACILITY_REMOVE')}
        message3={t(t1, 'CONTINUE_FACILITY_RETIRE')}
        handleContinue={() => {
          setShowRetireConfirmation(false);
          dispatch(retiredSingleFacility({
            id: selectedRetireRow,
            comment: retiredMethods?.getValues('comment'),
            entityId: EntityId,
          }));
          dispatch(setHideModal());
          setselectedRetireRow(null);
        }}
        borderColor="red"
      />
      }

      {showPassphraseDialogForAddFacility ? (
        <PassphraseConfirmationDialog
          title={t(t1, 'ENTER_PASSPHRASE_TO_SUBMIT')}
          cancelButtonLabel={t(t1, 'CANCEL')}
          confirmButtonLabel={t(t1, 'SUBMIT')}
          pageName={'Page_UserHome'}
          onPassphraseSuccess={AddFacilityAfterPassPharseSuccess}
          handleCloseDialog={() => setShowPassphraseDialogForAddFacility(false)}
          open={showPassphraseDialogForAddFacility}
        />
      ) : null}

      {showPassphraseDialogForEditFacility ? (
        <PassphraseConfirmationDialog
          title={t(t1, 'ENTER_PASSPHRASE_TO_SUBMIT')}
          cancelButtonLabel={t(t1, 'CANCEL')}
          confirmButtonLabel={t(t1, 'SUBMIT')}
          pageName={'Page_UserHome'}
          onPassphraseSuccess={handleEditSaveClick}
          handleCloseDialog={() => setShowPassphraseDialogForEditFacility(false)}
          open={showPassphraseDialogForEditFacility}
        />
      ) : null}
      <Loader loader={customLoader || loader} />
    </>
  );
}
