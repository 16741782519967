import { Typography, Container, Grid, List, ListItem, FormControlLabel, Checkbox, Button } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { CounterField } from 'components/CounterField';
import { TextFieldDescription } from 'components/TextFieldDescription';
import { FormProvider, useForm } from 'react-hook-form';
import { CustomizeButton } from 'components/CustomizeButton';
import { useDispatch, useSelector } from 'react-redux';
import { DialogMessage } from 'components';
import { getConfigurations, getNotificationSettingsConfig, SubmitConfigurations } from 'store/configSlice';
import { setDialogMessage, setModelMessage } from 'store/commonSlice';
import { Loader } from 'components/Loader';
import {
  emailPattern,
  t,
  getCurrentPageName,
  configSettingsCountRegex,
  isEmpty,
  ERROR,
  JSA_ROLE,
} from 'utils/constants';
import { useAuthentication } from 'hooks/useAuthentication';
import { NotificationSettings } from './NotificationSettings';
import Style from './styles';
import { AccountSettings } from './AccountSettings';
import Transfers from './Transfers';
import { transformTransferSettings } from './constants';

function ConfigurationSettings() {
  useAuthentication({ redirectTo: '/settings' });
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const { config } = useSelector(({ config }) => config);
  const { userDetails } = useSelector(({ userHome }) => userHome);
  /* Conflict Resolution Candidate
    const { userDetails } = useSelector(({ userHome }) => userHome);
    const { isSignedIn } = useAuth();
    const [isRememberMeDisabled, setisRememberMeDisabled] = useState(false);
    const { loader, dialogMessage } = useSelector(({ common }) => common);
    const { user } = useSelector(({ auth }) => auth);
    const [expanded, setExpanded] = useState({
      1:false,
      2:false,
      3:false,
    });
   */
  const [isRememberMeDisabled, setisRememberMeDisabled] = useState(false);
  const [maxValue, setMaxValue] = useState(0);
  const { loader, dialogMessage } = useSelector(({ common }) => common);
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user;
  const [showModal, setShowModal] = useState(false);
  const isJSAUser = userDetails?.RoleDetail?.Role.filter((item) => [JSA_ROLE].includes(item?.name))?.length > 0;
  const { selectedJurisdiction, selectedJurisdictionId } = useSelector(({ jurisdiction }) => jurisdiction);
  /* Conflict Resolution Candidate
    const navigate = useNavigate()
   */
  const defaultValues = {
    // for login settings
    'login-settings.failedLogin_count_tempLock.AttributeValue':
      config['login-settings']?.failedLogin_count_tempLock?.AttributeValue || '1',
    'login-settings.temp_lock_time.AttributeValue': config['login-settings']?.temp_lock_time?.AttributeValue || '1',
    'login-settings.failedLogin_count_permLock.AttributeValue':
      config['login-settings']?.failedLogin_count_permLock?.AttributeValue || '1',
    'login-settings.remember_userId_days.AttributeValue':
      config['login-settings']?.remember_userId_days?.AttributeValue || '1',
    'login-settings.passphrase_exp_days.AttributeValue':
      config['login-settings']?.passphrase_exp_days?.AttributeValue || '1',
    'login-settings.passphrase_reset.AttributeValue': config['login-settings']?.passphrase_reset?.AttributeValue || '1',
    'login-settings.session_timeout_time.AttributeValue':
      config['login-settings']?.session_timeout_time?.AttributeValue || '1',
    /* Conflict Resolution Candidate
        "remember_me":
          config["login-settings"]?.remember_userId?.AttributeValue || false,
     */

    // for notification
    'notification-settings.ju_email.AttributeValue': config['notification-settings']?.ju_email?.AttributeValue || '1',
    'notification-settings.contact_us_email.AttributeValue':
      config['notification-settings']?.contact_us_email?.AttributeValue || '1',

    // for notification
    /* Conflict Resolution Candidate
        "timeframe-settings.timeline_pending_application.AttributeValue":
          config["timeframe-settings"]?.timeline_pending_application?.AttributeValue || "1",
     */
    'timeframe-settings.timeline_pending_application.AttributeValue':
      config['timeframe-settings']?.timeline_pending_application?.AttributeValue || '1',
    'timeframe-settings.revision_request_days.AttributeValue':
      config['timeframe-settings']?.revision_request_days?.AttributeValue || '1',
    'timeframe-settings.incomplete_user_reg.AttributeValue':
      config['timeframe-settings']?.incomplete_user_reg?.AttributeValue || '1',

    // Added to set number of days after which pending users reminder email needs to be sent to JU users
    'registration-settings.pending_user_registration_reminder.AttributeValue':
      config['registration-settings']?.pending_user_registration_reminder?.AttributeValue || '1',

    // for entity default account settings

    'entity-default-account-settings.covered_compliance_account.AttributeValue': config['entity-default-account-settings']?.covered_compliance_account?.AttributeValue || false,
    'entity-default-account-settings.covered_general_account.AttributeValue': config['entity-default-account-settings']?.covered_general_account?.AttributeValue || false,
    'entity-default-account-settings.gmpi_compliance_account.AttributeValue': config['entity-default-account-settings']?.gmpi_compliance_account?.AttributeValue || false,
    'entity-default-account-settings.gmpi_general_account.AttributeValue': config['entity-default-account-settings']?.gmpi_general_account?.AttributeValue || false,
    'entity-default-account-settings.gmpo_general_account.AttributeValue': config['entity-default-account-settings']?.gmpo_general_account?.AttributeValue || false,
    'entity-default-account-settings.gmpo_compliance_account.AttributeValue': config['entity-default-account-settings']?.gmpo_compliance_account?.AttributeValue || false,

    // for entity-account-representative-settings

    'entity-account-representative-settings.covered_par_minimum.AttributeValue': config['entity-account-representative-settings']?.covered_par_minimum?.AttributeValue || 1,
    'entity-account-representative-settings.covered_par_maximum.AttributeValue': config['entity-account-representative-settings']?.covered_par_maximum?.AttributeValue || 1,
    'entity-account-representative-settings.covered_aar_minimum.AttributeValue': config['entity-account-representative-settings']?.covered_aar_minimum?.AttributeValue || 1,
    'entity-account-representative-settings.covered_aar_maximum.AttributeValue': config['entity-account-representative-settings']?.covered_aar_maximum?.AttributeValue || 1,
    'entity-account-representative-settings.covered_ava_minimum.AttributeValue': config['entity-account-representative-settings']?.covered_ava_minimum?.AttributeValue || 1,
    'entity-account-representative-settings.covered_ava_maximum.AttributeValue': config['entity-account-representative-settings']?.covered_ava_maximum?.AttributeValue || 1,
    'entity-account-representative-settings.gmpo_par_minimum.AttributeValue': config['entity-account-representative-settings']?.gmpo_par_minimum?.AttributeValue || 1,
    'entity-account-representative-settings.gmpo_par_maximum.AttributeValue': config['entity-account-representative-settings']?.gmpo_par_maximum?.AttributeValue || 1,
    'entity-account-representative-settings.gmpo_aar_minimum.AttributeValue': config['entity-account-representative-settings']?.gmpo_aar_minimum?.AttributeValue || 1,
    'entity-account-representative-settings.gmpo_aar_maximum.AttributeValue': config['entity-account-representative-settings']?.gmpo_aar_maximum?.AttributeValue || 1,
    'entity-account-representative-settings.gmpo_ava_minimum.AttributeValue': config['entity-account-representative-settings']?.gmpo_ava_minimum?.AttributeValue || 1,
    'entity-account-representative-settings.gmpo_ava_maximum.AttributeValue': config['entity-account-representative-settings']?.gmpo_ava_maximum?.AttributeValue || 1,
    'entity-account-representative-settings.gmpi_par_minimum.AttributeValue': config['entity-account-representative-settings']?.gmpi_par_minimum?.AttributeValue || 1,
    'entity-account-representative-settings.gmpi_par_maximum.AttributeValue': config['entity-account-representative-settings']?.gmpi_par_maximum?.AttributeValue || 1,
    'entity-account-representative-settings.gmpi_aar_minimum.AttributeValue': config['entity-account-representative-settings']?.gmpi_aar_minimum?.AttributeValue || 1,
    'entity-account-representative-settings.gmpi_aar_maximum.AttributeValue': config['entity-account-representative-settings']?.gmpi_aar_maximum?.AttributeValue || 1,
    'entity-account-representative-settings.gmpi_ava_minimum.AttributeValue': config['entity-account-representative-settings']?.gmpi_ava_minimum?.AttributeValue || 1,
    'entity-account-representative-settings.gmpi_ava_maximum.AttributeValue': config['entity-account-representative-settings']?.gmpi_ava_maximum?.AttributeValue || 1,
    'transfer-settings.current_vintage_year_allowance.AttributeValue': {
      name: config['transfer-settings']?.current_vintage_year_allowance?.AttributeValue || '',
      value: config['transfer-settings']?.current_vintage_year_allowance?.AttributeValue || '',
    },
    'transfer-settings.current_vintage_year_offset.AttributeValue': {
      name: config['transfer-settings']?.current_vintage_year_offset?.AttributeValue || '',
      value: config['transfer-settings']?.current_vintage_year_offset?.AttributeValue || '',
    },
    'transfer-settings.entity_transfer_proposed_expiry.AttributeValue': config['transfer-settings']?.entity_transfer_proposed_expiry?.AttributeValue || 0,
    'transfer-settings.entity_transfer_approved_expiry.AttributeValue': config['transfer-settings']?.entity_transfer_approved_expiry?.AttributeValue || 0,
    'transfer-settings.jurisdiction_transfer_administrative_expiry.AttributeValue': config['transfer-settings']?.jurisdiction_transfer_administrative_expiry?.AttributeValue || 0,
    'transfer-settings.jurisdiction_transfer_replenishment_expiry.AttributeValue': config['transfer-settings']?.jurisdiction_transfer_replenishment_expiry?.AttributeValue || 0,
  };

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues,
  });
  const {
    /* Conflict Resolution Candidate
        watch,
        handleSubmit,
     */
    handleSubmit,
    formState: { errors, isDirty },
  } = methods;
  const [configureState, setConfigureState] = useState({});
  const [isSaveDisable, setSaveDisable] = useState(true);
  useEffect(() => {
    setConfigureState({ ...config });
  }, [config]);


  /* Conflict Resolution Candidate
  // console.log(defaultValues,config,'defaultValues');

  const juEmailWatch = watch(["notification-settings.ju_email.AttributeValue"][0], '')
  const contactUSEmailWatch = watch(["notification-settings.contact_us_email.AttributeValue"][0], '')
  const remembermeUserIdWatch = watch(["remember_me"][0])
  const failedLoginTempLock = watch(["login-settings.failedLogin_count_tempLock.AttributeValue"][0], '')
  const tempLockTime = watch(["login-settings.failedLogin_count_permLock.AttributeValue"][0], '')
  const failedLoginPermLock = watch(["login-settings.failedLogin_count_permLock.AttributeValue"][0], '')
  const rememberUserIdDays = watch(["login-settings.remember_userId_days.AttributeValue"][0], '')
  const passphraseExpDays = watch(["login-settings.passphrase_exp_days.AttributeValue"][0], '')
  const passphraseReset = watch(["login-settings.passphrase_reset.AttributeValue"][0], '')
  const sessionTimeoutTime = watch(["login-settings.session_timeout_time.AttributeValue"][0], '')
  const physicalDoc = watch(["timeframe-settings.timeline_pending_application.AttributeValue"][0], '')
  const revisionRequestDays = watch(["timeframe-settings.revision_request_days.AttributeValue"][0], '')
  const incompleteUserReg = watch(["timeframe-settings.incomplete_user_reg.AttributeValue"][0], '')
   */
  const getAccountConfig = (accountSettings, accountRepresentativeSettings) => {
    return {
      'entity-default-account-settings': {
        covered_compliance_account: {
          AttributeValue: accountSettings.covered_compliance_account.AttributeValue,
          Description: config['entity-default-account-settings']?.covered_compliance_account?.Description,
        },
        covered_general_account: {
          AttributeValue: accountSettings.covered_general_account.AttributeValue,
          Description: config['entity-default-account-settings']?.covered_general_account?.Description,
        },
        gmpi_compliance_account: {
          AttributeValue: accountSettings.gmpi_compliance_account.AttributeValue,
          Description: config['entity-default-account-settings']?.gmpi_compliance_account?.Description,
        },
        gmpi_general_account: {
          AttributeValue: accountSettings.gmpi_general_account.AttributeValue,
          Description: config['entity-default-account-settings']?.gmpi_general_account?.Description,
        },
        gmpo_general_account: {
          AttributeValue: accountSettings.gmpo_general_account.AttributeValue,
          Description: config['entity-default-account-settings']?.gmpo_general_account?.Description,
        },
        gmpo_compliance_account: {
          AttributeValue: accountSettings.gmpo_compliance_account.AttributeValue,
          Description: config['entity-default-account-settings']?.gmpo_compliance_account?.Description,
        },
      },
      'entity-account-representative-settings': {
        covered_par_minimum: {
          AttributeValue: accountRepresentativeSettings.covered_par_minimum.AttributeValue,
          Description: config['entity-account-representative-settings']?.covered_par_minimum?.Description,
        },
        covered_par_maximum: {
          AttributeValue: accountRepresentativeSettings.covered_par_maximum.AttributeValue,
          Description: config['entity-account-representative-settings']?.covered_par_maximum?.Description,
        },
        covered_aar_minimum: {
          AttributeValue: accountRepresentativeSettings.covered_aar_minimum.AttributeValue,
          Description: config['entity-account-representative-settings']?.covered_aar_minimum?.Description,
        },
        covered_aar_maximum: {
          AttributeValue: accountRepresentativeSettings.covered_aar_maximum.AttributeValue,
          Description: config['entity-account-representative-settings']?.covered_aar_maximum?.Description,
        },
        covered_ava_minimum: {
          AttributeValue: accountRepresentativeSettings.covered_ava_minimum.AttributeValue,
          Description: config['entity-account-representative-settings']?.covered_ava_minimum?.Description,
        },
        covered_ava_maximum: {
          AttributeValue: accountRepresentativeSettings.covered_ava_maximum.AttributeValue,
          Description: config['entity-account-representative-settings']?.covered_ava_maximum?.Description,
        },
        gmpo_par_minimum: {
          AttributeValue: accountRepresentativeSettings.gmpo_par_minimum.AttributeValue,
          Description: config['entity-account-representative-settings']?.gmpo_par_minimum?.Description,
        },
        gmpo_par_maximum: {
          AttributeValue: accountRepresentativeSettings.gmpo_par_maximum.AttributeValue,
          Description: config['entity-account-representative-settings']?.gmpo_par_maximum?.Description,
        },
        gmpo_aar_minimum: {
          AttributeValue: accountRepresentativeSettings.gmpo_aar_minimum.AttributeValue,
          Description: config['entity-account-representative-settings']?.gmpo_aar_minimum?.Description,
        },
        gmpo_aar_maximum: {
          AttributeValue: accountRepresentativeSettings.gmpo_aar_maximum.AttributeValue,
          Description: config['entity-account-representative-settings']?.gmpo_aar_maximum?.Description,
        },
        gmpo_ava_minimum: {
          AttributeValue: accountRepresentativeSettings.gmpo_ava_minimum.AttributeValue,
          Description: config['entity-account-representative-settings']?.gmpo_ava_minimum?.Description,
        },
        gmpo_ava_maximum: {
          AttributeValue: accountRepresentativeSettings.gmpo_ava_maximum.AttributeValue,
          Description: config['entity-account-representative-settings']?.gmpo_ava_maximum?.Description,
        },
        gmpi_par_minimum: {
          AttributeValue: accountRepresentativeSettings.gmpi_par_minimum.AttributeValue,
          Description: config['entity-account-representative-settings']?.gmpi_par_minimum?.Description,
        },
        gmpi_par_maximum: {
          AttributeValue: accountRepresentativeSettings.gmpi_par_maximum.AttributeValue,
          Description: config['entity-account-representative-settings']?.gmpi_par_maximum?.Description,
        },
        gmpi_aar_minimum: {
          AttributeValue: accountRepresentativeSettings.gmpi_aar_minimum.AttributeValue,
          Description: config['entity-account-representative-settings']?.gmpi_aar_minimum?.Description,
        },
        gmpi_aar_maximum: {
          AttributeValue: accountRepresentativeSettings.gmpi_aar_maximum.AttributeValue,
          Description: config['entity-account-representative-settings']?.gmpi_aar_maximum?.Description,
        },
        gmpi_ava_minimum: {
          AttributeValue: accountRepresentativeSettings.gmpi_ava_minimum.AttributeValue,
          Description: config['entity-account-representative-settings']?.gmpi_ava_minimum?.Description,
        },
        gmpi_ava_maximum: {
          AttributeValue: accountRepresentativeSettings.gmpi_ava_maximum.AttributeValue,
          Description: config['entity-account-representative-settings']?.gmpi_ava_maximum?.Description,
        },
      },

    };
  };

  const onSubmit = async (data) => {
    setShowModal(false);
    delete data['login-settings.failedLogin_count_tempLock.AttributeValue'];
    delete data['login-settings.temp_lock_time.AttributeValue'];
    delete data['login-settings.failedLogin_count_permLock.AttributeValue'];
    delete data['login-settings.remember_userId_days.AttributeValue'];
    delete data['login-settings.passphrase_exp_days.AttributeValue'];
    delete data['login-settings.passphrase_reset.AttributeValue'];
    delete data['login-settings.session_timeout_time.AttributeValue'];
    delete data['notification-settings.ju_email.AttributeValue'];
    delete data['notification-settings.contact_us_email.AttributeValue'];
    /* Conflict Resolution Candidate
        delete data["timeframe-settings.timeline_pending_application.AttributeValue"];
     */
    delete data['timeframe-settings.timeline_pending_application.AttributeValue'];
    delete data['timeframe-settings.revision_request_days.AttributeValue'];
    delete data['timeframe-settings.incomplete_user_reg.AttributeValue'];
    /** registration-settings */
    delete data['registration-settings.pending_user_registration_reminder.AttributeValue'];
    delete data.notification_email; // remove input field name used in custom modal
    /** entity-default-account-settings */
    delete data['entity-default-account-settings.covered_compliance_account.AttributeValue'];
    delete data['entity-default-account-settings.covered_general_account.AttributeValue'];
    delete data['entity-default-account-settings.gmpi_compliance_account.AttributeValue'];
    delete data['entity-default-account-settings.gmpi_general_account.AttributeValue'];
    delete data['entity-default-account-settings.gmpo_general_account.AttributeValue'];
    delete data['entity-default-account-settings.gmpo_compliance_account.AttributeValue'];
    /** entity-account-representative-settings */
    delete data['entity-account-representative-settings.covered_par_minimum.AttributeValue'];
    delete data['entity-account-representative-settings.covered_par_maximum.AttributeValue'];
    delete data['entity-account-representative-settings.covered_aar_minimum.AttributeValue'];
    delete data['entity-account-representative-settings.covered_aar_maximum.AttributeValue'];
    delete data['entity-account-representative-settings.covered_ava_minimum.AttributeValue'];
    delete data['entity-account-representative-settings.covered_ava_maximum.AttributeValue'];
    delete data['entity-account-representative-settings.gmpo_par_minimum.AttributeValue'];
    delete data['entity-account-representative-settings.gmpo_par_maximum.AttributeValue'];
    delete data['entity-account-representative-settings.gmpo_aar_minimum.AttributeValue'];
    delete data['entity-account-representative-settings.gmpo_aar_maximum.AttributeValue'];
    delete data['entity-account-representative-settings.gmpo_ava_minimum.AttributeValue'];
    delete data['entity-account-representative-settings.gmpo_ava_maximum.AttributeValue'];
    delete data['entity-account-representative-settings.gmpi_par_minimum.AttributeValue'];
    delete data['entity-account-representative-settings.gmpi_par_maximum.AttributeValue'];
    delete data['entity-account-representative-settings.gmpi_aar_minimum.AttributeValue'];
    delete data['entity-account-representative-settings.gmpi_aar_maximum.AttributeValue'];
    delete data['entity-account-representative-settings.gmpi_ava_minimum.AttributeValue'];
    delete data['entity-account-representative-settings.gmpi_ava_maximum.AttributeValue'];

    /** transfer-settings */
    delete data['transfer-settings.current_vintage_year_allowance.AttributeValue'];
    delete data['transfer-settings.current_vintage_year_offset.AttributeValue'];
    delete data['transfer-settings.entity_transfer_approved_expiry.AttributeValue'];
    delete data['transfer-settings.entity_transfer_proposed_expiry.AttributeValue'];
    delete data['transfer-settings.jurisdiction_transfer_replenishment_expiry.AttributeValue'];
    delete data['transfer-settings.jurisdiction_transfer_administrative_expiry.AttributeValue'];


    const {
      'entity-default-account-settings': accountSettings,
      'entity-account-representative-settings': accountRepresentativeSettings,
      'transfer-settings': transferSettings,
      ...payload
    } = data;
    const accountConfig = getAccountConfig(accountSettings, accountRepresentativeSettings);
    const errorMessage = (message) => {
      const data = {
        title: ERROR,
        message1: t(t1, message),
        error: true,
        isDefaultDisplay: false,
      };
      dispatch(setModelMessage(data));
    };
    if (!accountConfig['entity-default-account-settings'].covered_compliance_account.AttributeValue && !accountConfig['entity-default-account-settings'].covered_general_account.AttributeValue) {
      errorMessage('PLEASE_SELECT_AT_LEAST_ONE_ACCOUNT_TYPE_FOR_COVERED_ENTITY');
      return;
    }
    if (!accountConfig['entity-default-account-settings'].gmpi_compliance_account.AttributeValue && !accountConfig['entity-default-account-settings'].gmpi_general_account.AttributeValue) {
      errorMessage('PLEASE_SELECT_AT_LEAST_ONE_ACCOUNT_GENERAL_MARKET_PARTICIPANT_INDIVIDUAL');
      return;
    }
    if (!accountConfig['entity-default-account-settings'].gmpo_general_account.AttributeValue && !accountConfig['entity-default-account-settings'].gmpo_compliance_account.AttributeValue) {
      errorMessage('PLEASE_SELECT_AT_LEAST_ONE_ACCOUNT_GENERAL_MARKET_PARTICIPANT_ORGANIZATION');
      return;
    }
    const transferSettingConfig = transformTransferSettings(transferSettings);
    payload['login-settings'].remember_userId = { AttributeValue: Boolean(isRememberMeDisabled) };
    setConfigureState((val) => ({ ...val, ...payload, ...accountConfig, ...transferSettingConfig }));
    dispatch(SubmitConfigurations({ ...payload, ...transferSettingConfig }, selectedJurisdiction, accountConfig));
  };
  const setDefaultValues = () => {
    methods?.reset(defaultValues);
  };


  /* Conflict Resolution Candidate
  const [clickedOnce, setClickedOnce] = useState(0);
  const handleCheckedChange = (e) => {
    setClickedOnce(clickedOnce+1);
   */
  const handleCheckedChange = (e) => {
    setisRememberMeDisabled(!isRememberMeDisabled);
  };

  const handleCancel = () => {
    dispatch(setDialogMessage(true));
    setShowModal(true);
  };

  const closeConfigurationEditAction = () => {
    /* Conflict Resolution Candidate
        // navigate((userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) ? "/jurisdiction-user" : "/home")
        setDefaultValues();
        closeAccordion()
      };

      // close all accordion
      const closeAccordion = useCallback(() => {
        setExpanded({1:false,2:false,3:false})
      },[])
     */

    // navigate("/home")
    setDefaultValues();
    setShowModal(false);
  };

  useEffect(() => {
    setDefaultValues();
    if (!config['login-settings']?.remember_userId?.AttributeValue) {
      setisRememberMeDisabled(false);
    } else {
      setisRememberMeDisabled(true);
    }
  }, [config]);

  useEffect(() => {
    if (selectedJurisdiction.length > 0) {
      dispatch(getConfigurations(selectedJurisdiction));
    }
    if (selectedJurisdictionId) {
      dispatch(getNotificationSettingsConfig(selectedJurisdictionId));
    }
  }, [dispatch, selectedJurisdiction, selectedJurisdictionId]);

  const updateErrorValue = (name, value) => {
    if (name === 'login-settings.passphrase_exp_days.AttributeValue')
      setMaxValue(value);
    /* Conflict Resolution Candidate
        methods?.setValue(name, value);
        methods?.clearErrors(name);
        if(value==="NaN" || !countRegex.test(value)){
          setSaveDisable(true)
        }else{
          setSaveDisable(false)
        }
      };

      useEffect(() => {
        if (!emailPattern.test(juEmailWatch)|| !emailPattern.test(contactUSEmailWatch)||(contactUSEmailWatch === defaultValues?.["notification-settings.contact_us_email.AttributeValue"] && juEmailWatch === defaultValues?.["notification-settings.ju_email.AttributeValue"]))
          setSaveDisable(true)
          else setSaveDisable(false)
      }, [contactUSEmailWatch, juEmailWatch])

      useEffect(() => {
        // if(isRememberMeDisabled !== remembermeUserIdWatch){
        //   setSaveDisable(false)
        // }
        // if(isRememberMeDisabled === remembermeUserIdWatch){
        //   setSaveDisable(true)
        // }
        if(clickedOnce === 0){
          setSaveDisable(true)
        }
        if(clickedOnce > 0){
          setSaveDisable(false)
        }
      }, [remembermeUserIdWatch, isRememberMeDisabled, clickedOnce])


      // useEffect(() => {
      //   if(
      //     defaultValues?.["login-settings.failedLogin_count_tempLock.AttributeValue"] === failedLoginTempLock &&
      //     defaultValues?.["login-settings.temp_lock_time.AttributeValue"] === tempLockTime &&
      //     defaultValues?.["login-settings.failedLogin_count_permLock.AttributeValue"] === failedLoginPermLock &&
      //     defaultValues?.["login-settings.remember_userId_days.AttributeValue"] === rememberUserIdDays &&
      //     defaultValues?.["login-settings.passphrase_exp_days.AttributeValue"] === passphraseExpDays &&
      //     defaultValues?.["login-settings.passphrase_reset.AttributeValue"] === passphraseReset &&
      //     defaultValues?.["login-settings.session_timeout_time.AttributeValue"] === sessionTimeoutTime &&
      //     defaultValues?.["timeframe-settings.timeline_pending_application.AttributeValue"] === physicalDoc &&
      //     defaultValues?.["timeframe-settings.revision_request_days.AttributeValue"] === revisionRequestDays &&
      //     defaultValues?.["timeframe-settings.incomplete_user_reg.AttributeValue"] === incompleteUserReg
      //     ){
      //     setSaveDisable(true)
      //   }else{
      //     setSaveDisable(false)
      //   }
      // }, [failedLoginTempLock,
      //   incompleteUserReg,
      //   revisionRequestDays,
      //   physicalDoc,
      //   sessionTimeoutTime,
      //   passphraseReset,
      //   passphraseExpDays,
      //   rememberUserIdDays,
      //   failedLoginPermLock,
      //   tempLockTime
      // ])

      return (
        <>
     */
    setSaveDisable(false);
    methods?.setValue(name, value);
    methods?.clearErrors(name);
    methods?.trigger();
  };

  let isDisableSave = false;
  if (
    !isEmpty(errors?.['login-settings']?.failedLogin_count_tempLock?.AttributeValue?.message) ||
    !isEmpty(errors?.['login-settings']?.temp_lock_time?.AttributeValue?.message) ||
    !isEmpty(errors?.['login-settings']?.failedLogin_count_permLock?.AttributeValue?.message) ||
    !isEmpty(errors?.['login-settings']?.remember_userId_days?.AttributeValue?.message) ||
    !isEmpty(errors?.['login-settings']?.passphrase_exp_days?.AttributeValue?.message) ||
    !isEmpty(errors?.['login-settings']?.passphrase_reset?.AttributeValue?.message) ||
    !isEmpty(errors?.['login-settings']?.session_timeout_time?.AttributeValue?.message) ||
    !isEmpty(errors?.['notification-settings']?.ju_email?.AttributeValue?.message) ||
    !isEmpty(errors?.['notification-settings']?.contact_us_email?.AttributeValue?.message) ||
    !isEmpty(errors?.['timeframe-settings']?.timeline_pending_application?.AttributeValue?.message) ||
    !isEmpty(errors?.['timeframe-settings']?.revision_request_days?.AttributeValue?.message) ||
    !isEmpty(errors?.['timeframe-settings']?.incomplete_user_reg?.AttributeValue?.message) ||
    !isEmpty(errors?.['registration-settings']?.pending_user_registration_reminder?.AttributeValue?.message) ||
    !isEmpty(errors?.['transfer-settings']?.current_vintage_year_allowance?.AttributeValue?.type) ||
    !isEmpty(errors?.['transfer-settings']?.current_vintage_year_offset?.AttributeValue?.type) ||
    !isEmpty(errors?.['transfer-settings']?.entity_transfer_proposed_expiry?.AttributeValue?.message) ||
    !isEmpty(errors?.['transfer-settings']?.entity_transfer_approved_expiry?.AttributeValue?.message) ||
    !isEmpty(errors?.['transfer-settings']?.jurisdiction_transfer_administrative_expiry?.AttributeValue?.message) ||
    !isEmpty(errors?.['transfer-settings']?.jurisdiction_transfer_replenishment_expiry?.AttributeValue?.message)
  ) {
    isDisableSave = true;
  } else {
    isDisableSave = false;
  }

  const maxSessionTimeout = 99;

  return !loader ? (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container maxWidth="xl" sx={Style.spacing}>
          <Grid container spacing={2.5}>
            <Grid item sm={12}>
              <div className="titalwithbtn">
                <Typography variant="h3">{t(t1, 'CONFIGURATION_SETTINGS')}</Typography>
                <div className=" settingsBtn">
                  <CustomizeButton
                    disableElevation
                    variant="outlined"
                    // csName="rc"
                    name="cancel"
                    disabled={!isJSAUser}
                    customStyle={{
                      backgroundColor: isJSAUser ? 'white' : 'gray',
                      border: isJSAUser ? '1px solid #C7C8CA' : 0,
                      color: isJSAUser ? '#4D8DA4' : '',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#4D8DA4',
                      },
                    }}
                    //  Need this later
                    // privId="priv_btn_cancel"
                    label={t(t1, 'CANCEL')}
                    handleClick={handleCancel}
                  />

                  <CustomizeButton
                    disableElevation
                    variant="contained"
                    type="submit"
                    /* Conflict Resolution Candidate
                                        disabled={isSaveDisable}
                     */
                    disabled={isDisableSave}
                    label={t(t1, 'SAVE')}
                    name="save"
                    privId="priv_btn_Save"
                  />
                </div>
              </div>
              <Typography variant="h4" sx={{ fontWeight: 600 }} gutterBottom>
                {t(t1, 'HOME')}
              </Typography>
              <div className="paneldetail">
                {/* Conflict Resolution Candidate <Accordion sx={Style.accordianStyling} expanded={expanded?.[1]} onChange={() => setExpanded({...expanded,1:!expanded?.[1]})}> */}
                <Accordion sx={Style.accordianStyling}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    className="custompanel"
                    id="panel1a-header"
                    sx={{
                      minHeight: '44px !important',
                      maxHeight: '44px !important',
                    }}
                  >
                    <Typography>{t(t1, 'LOGIN-SETTINGS')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="plus-minus-btn-main">
                      <List>
                        <ListItem>
                          <CounterField
                            inputClass="hideBrowserDefault"
                            name="login-settings.failedLogin_count_tempLock.AttributeValue"
                            privId="Priv_Max_Num_Fail_Login_Attempts"
                            label={t(t1, 'MAX_NUM_FAIL_LOGIN_ATTEMPTS')}
                            defaultValue={
                              configureState['login-settings']?.failedLogin_count_tempLock?.AttributeValue || '1'
                            }
                            updateValue={(fieldName, val) => updateErrorValue(fieldName, val)}
                            type="text"
                            rules={{
                              required: true,
                              pattern: {
                                value: configSettingsCountRegex,
                                message: `${t(t1, 'ERR_INVALID_MAX_NUM_FAIL_LOGIN_ATTEMPTS')}`,
                              },
                            }}
                            isRequiredMsg={'ERR_MAX_NUM_FAIL_LOGIN_ATTEMPTS_IS_REQUIRED'}
                          />
                        </ListItem>
                        <ListItem>
                          <CounterField
                            inputClass="hideBrowserDefault"
                            name="login-settings.temp_lock_time.AttributeValue"
                            privId="priv_tempararily_locked_out_minutes"
                            label={t(t1, 'TEMPARARILY_LOCKED_OUT_MINUTES')}
                            defaultValue={configureState['login-settings']?.temp_lock_time?.AttributeValue || '1'}
                            updateValue={(fieldName, val) => updateErrorValue(fieldName, val)}
                            type="text"
                            rules={{
                              required: true,
                              pattern: {
                                value: configSettingsCountRegex,
                                message: `${t(t1, 'ERR_INVALID_TEMPARARILY_LOCKED_OUT_MINUTES')}`,
                              },
                            }}
                            isRequiredMsg={'ERR_TEMPARARILY_LOCKED_OUT_MINUTES_IS_REQUIRED'}
                          />
                        </ListItem>
                        <ListItem>
                          <CounterField
                            inputClass="hideBrowserDefault"
                            name="login-settings.failedLogin_count_permLock.AttributeValue"
                            privId="priv_max_num_fail_login_attempts_permenent_loc_out"
                            label={`${t(t1, 'MAX_NUM_FAIL_LOGIN_ATTEMPTS_PERMANENT_LOC_OUT')}`}
                            defaultValue={configureState['login-settings']?.failedLogin_count_permLock?.AttributeValue}
                            updateValue={(fieldName, val) => updateErrorValue(fieldName, val)}
                            type="text"
                            rules={{
                              required: true,
                              pattern: {
                                value: configSettingsCountRegex,
                                message: `${t(t1, 'ERR_INVALID_MAX_NUM_FAIL_LOGIN_ATTEMPTS_PERMANENT_LOC_OUT')}`,
                              },
                            }}
                            isRequiredMsg={'ERR_MAX_NUM_FAIL_LOGIN_ATTEMPTS_PERMANENT_LOC_OUT_IS_REQUIRED'}
                          />
                        </ListItem>
                      </List>
                      {PrivAttributes?.priv_EnableRememeberMe?.PrivRole !== 'NA' && (
                        <FormControlLabel
                          sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                          control={
                            <Checkbox
                              onClick={(e) => handleCheckedChange(e)}
                              checked={isRememberMeDisabled}
                              name="remember_me"
                              size="medium"
                              disabled={PrivAttributes?.priv_EnableRememeberMe?.PrivRole === 'RO'}
                            />
                          }
                          label={t(t1, 'ENABLE_REMEMBER_ME')}
                        />
                      )}
                      <List>
                        <ListItem>
                          <CounterField
                            isRememberMeDisabled={!isRememberMeDisabled}
                            inputClass="hideBrowserDefault"
                            name="login-settings.remember_userId_days.AttributeValue"
                            privId="priv_passphrase_remembered_days"
                            label={t(t1, 'PASSPHRASE_REMEMBERED_DAYS')}
                            defaultValue={configureState['login-settings']?.remember_userId_days?.AttributeValue || '1'}
                            updateValue={(fieldName, val) => updateErrorValue(fieldName, val)}
                            type="text"
                            rules={{
                              required: true,
                              pattern: {
                                value: configSettingsCountRegex,
                                message: `${t(t1, 'ERR_INVALID_PASSPHRASE_REMEMBERED_DAYS')}`,
                              },
                            }}
                            isRequiredMsg={'ERR_PASSPHRASE_REMEMBERED_DAYS_IS_REQUIRED'}
                          />
                        </ListItem>
                        <ListItem>
                          <CounterField
                            inputClass="hideBrowserDefault"
                            name="login-settings.passphrase_exp_days.AttributeValue"
                            privId="priv_passphrase_expires_after_days"
                            label={t(t1, 'PASSPHRASE_EXPIRES_AFTER_DAYS')}
                            defaultValue={configureState['login-settings']?.passphrase_exp_days?.AttributeValue || '1'}
                            updateValue={(fieldName, val) => updateErrorValue(fieldName, val)}
                            type="text"
                            rules={{
                              required: true,
                              pattern: {
                                value: configSettingsCountRegex,
                                message: `${t(t1, 'ERR_INVALID_PASSPHRASE_EXPIRES_AFTER_DAYS')}`,
                              },
                            }}
                            isRequiredMsg={'ERR_PASSPHRASE_EXPIRES_AFTER_DAYS_IS_REQUIRED'}
                          />
                        </ListItem>
                        <ListItem>
                          <CounterField
                            inputClass="hideBrowserDefault"
                            name="login-settings.passphrase_reset.AttributeValue"
                            privId="priv_passphrase_expiration_prompt_before"
                            label={t(t1, 'PASSPHRASE_EXPIRATION_PROMPT_BEFORE')}
                            defaultValue={configureState['login-settings']?.passphrase_reset?.AttributeValue || '1'}
                            updateValue={(fieldName, val) => updateErrorValue(fieldName, val)}
                            type="text"
                            maxValue={maxValue || configureState['login-settings']?.passphrase_exp_days?.AttributeValue || '1'}
                            rules={{
                              required: true,
                              pattern: {
                                value: configSettingsCountRegex,
                                message: `${t(t1, 'ERR_INVALID_PASSPHRASE_EXPIRATION_PROMPT_BEFORE')}`,
                              },
                              max: {
                                value: (maxValue || configureState['login-settings']?.passphrase_exp_days?.AttributeValue || 1) - 1,
                                message: `${t(t1, 'ERR_INVALID_PASSPHRASE_EXPIRATION_PROMPT_BEFORE_SHOULD_LESS_THAN')}`,
                              },
                            }}
                            isRequiredMsg={'ERR_PASSPHRASE_EXPIRATION_PROMPT_BEFORE_IS_REQUIRED'}
                          />
                        </ListItem>
                        <ListItem>
                          <CounterField
                            inputClass="hideBrowserDefault"
                            name="login-settings.session_timeout_time.AttributeValue"
                            privId="priv_session_timeout_minutes"
                            label={t(t1, 'SESSION_TIMEOUT_MINUTES')}
                            defaultValue={configureState['login-settings']?.session_timeout_time?.AttributeValue || '1'}
                            updateValue={(fieldName, val) => updateErrorValue(fieldName, val)}
                            type="text"
                            maxValue={maxSessionTimeout}
                            rules={{
                              required: true,
                              pattern: {
                                value: configSettingsCountRegex,
                                message: `${t(t1, 'ERR_INVALID_SESSION_TIMEOUT_MINUTES')}`,
                              },
                              max: {
                                value: maxSessionTimeout,
                                message: `${t(t1, 'ERR_INVALID_SESSION_TIMEOUT_MIN_SHOULD_BE_LESS_THAN', { timeout: `${maxSessionTimeout + 1}` })}`,
                              },
                            }}
                            isRequiredMsg={'ERR_SESSION_TIMEOUT_MINUTES_IS_REQUIRED'}
                          />
                        </ListItem>
                      </List>
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/* Conflict Resolution Candidate <Accordion sx={Style.accordianStyling} expanded={expanded?.[2]} onChange={() => setExpanded({...expanded,2:!expanded?.[2]})}> */}
                <Accordion sx={Style.accordianStyling}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    className="custompanel"
                    id="panel2a-header"
                    sx={{ minHeight: '43px !important', maxHeight: '43px !important' }}
                  >
                    <Typography>{t(t1, 'NOTIFICATION_SETTINGS')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <div className="textbox-detail">
                  <TextField id="outlined-size-small" label={t(t1, "ENTER_AN_EMAIL_ADDRESS")} size="small" />
                  <Typography variant="p">
                    {t(t1, "JURISDICTION_USER_RECEIVE_AUTOMATED_EMAIL")}
                  </Typography>
                </div> */}
                    {/* <Grid container spacing={4.5}> */}
                    <TextFieldDescription
                      // gridMd={3}
                      label={t(t1, 'JURISDICTION_USER_RECEIVE_AUTOMATED_EMAIL')}
                      name="notification-settings.ju_email.AttributeValue"
                      privId="priv_jurisdiction_user_receive_automated_email"
                      type="email"
                      placeholder={t(t1, 'ENTER_AN_EMAIL_ADDRESS')}
                      defaultValue={config['notification-settings']?.ju_email?.AttributeValue}
                      // inputClass="fc-text"
                      rules={{
                        required: true,
                        pattern: {
                          value: emailPattern,
                          message: `${t(t1, 'ERR_INVALID_EMAIL')}`,
                        },
                      }}
                      isRequiredMsg={'ERR_JURISDICTION_USER_RECEIVE_AUTOMATED_EMAIL_IS_REQUIRED'}
                      className="input-group"
                    />

                    {/* </Grid> */}
                    {/* <Grid container spacing={4.5}> */}
                    <TextFieldDescription
                      // gridMd={3}
                      label={t(t1, 'CONTACTUS_EMAIL')}
                      name="notification-settings.contact_us_email.AttributeValue"
                      type="email"
                      placeholder={t(t1, 'ENTER_AN_EMAIL_ADDRESS')}
                      privId="priv_contactus_email"
                      // defaultValue={config["notification-settings"]?.contact_us_email?.AttributeValue}
                      // inputClass="fc-text"
                      rules={{
                        required: true,
                        pattern: {
                          value: emailPattern,
                          message: `${t(t1, 'ERR_INVALID_EMAIL')}`,
                        },
                      }}
                      isRequiredMsg={'ERR_CONTACTUS_EMAIL_IS_REQUIRED'}
                      className="input-group"
                    />
                    {/* </Grid> */}
                    {/* <div className="textbox-detail">
                  <TextField id="outlined-size-small" label={t(t1, "ENTER_AN_EMAIL_ADDRESS")} size="small" />

                  <Typography variant="p">{t(t1, "CONTACTUS_EMAIL")}</Typography>
                </div> */}
                  </AccordionDetails>
                </Accordion>
                {/* Conflict Resolution Candidate <Accordion sx={Style.accordianStyling} expanded={expanded?.[3]} onChange={() => setExpanded({...expanded,3:!expanded?.[3]})}> */}
                <Accordion sx={Style.accordianStyling}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    className="custompanel"
                    id="panel3a-header"
                    sx={{ minHeight: '43px !important', maxHeight: '43px !important' }}
                  >
                    <Typography>{t(t1, 'TIMEFRAME_SETTINGS')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="plus-minus-btn-main">
                      <List>
                        <ListItem>
                          <CounterField
                            inputClass="hideBrowserDefault"
                            /* Conflict Resolution Candidate
                                                        name="timeframe-settings.timeline_pending_application.AttributeValue"
                                                        privId="priv_submit_physical_documents_days"
                                                        label={t(t1, "SUBMIT_PHYSICAL_DOCUMENTS_DAYS")}
                                                        defaultValue={
                                                          configureState["timeframe-settings"]?.timeline_pending_application?.AttributeValue || "1"
                             */
                            name="timeframe-settings.timeline_pending_application.AttributeValue"
                            privId="priv_submit_physical_documents_days"
                            label={t(t1, 'SUBMIT_PHYSICAL_DOCUMENTS_DAYS')}
                            defaultValue={
                              configureState['timeframe-settings']?.timeline_pending_application?.AttributeValue || '1'
                            }
                            updateValue={(fieldName, val) => updateErrorValue(fieldName, val)}
                            type="text"
                            rules={{
                              required: true,
                              pattern: {
                                value: configSettingsCountRegex,
                                message: `${t(t1, 'ERR_INVALID_SUBMIT_PHYSICAL_DOCUMENTS_DAYS')}`,
                              },
                            }}
                            isRequiredMsg={'ERR_SUBMIT_PHYSICAL_DOCUMENTS_DAYS_IS_REQUIRED'}
                          />
                        </ListItem>
                        <ListItem>
                          <CounterField
                            inputClass="hideBrowserDefault"
                            name="timeframe-settings.revision_request_days.AttributeValue"
                            privId="priv_submit_revisions_days"
                            label={t(t1, 'SUBMIT_REVISIONS_DAYS')}
                            defaultValue={
                              configureState['timeframe-settings']?.revision_request_days?.AttributeValue || '1'
                            }
                            updateValue={(fieldName, val) => updateErrorValue(fieldName, val)}
                            type="text"
                            rules={{
                              required: true,
                              pattern: {
                                value: configSettingsCountRegex,
                                message: `${t(t1, 'ERR_INVALID_SUBMIT_REVISIONS_DAYS_IS_REQUIRED')}`,
                              },
                            }}
                            isRequiredMsg={'ERR_SUBMIT_REVISIONS_DAYS'}
                          />
                        </ListItem>
                        <ListItem>
                          <CounterField
                            inputClass="hideBrowserDefault"
                            name="timeframe-settings.incomplete_user_reg.AttributeValue"
                            privId="priv_incomplete_registrations_deleted_days"
                            label={t(t1, 'INCOMPLETE_REGISTRATIONS_DELETED_DAYS')}
                            defaultValue={
                              configureState['timeframe-settings']?.incomplete_user_reg?.AttributeValue || '1'
                            }
                            updateValue={(fieldName, val) => updateErrorValue(fieldName, val)}
                            type="text"
                            rules={{
                              required: true,
                              pattern: {
                                value: configSettingsCountRegex,
                                message: `${t(t1, 'ERR_INVALID_INCOMPLETE_REGISTRATIONS_DELETED_DAYS')}`,
                              },
                            }}
                            isRequiredMsg={'ERR_INCOMPLETE_REGISTRATIONS_DELETED_DAYS'}
                          />
                        </ListItem>
                      </List>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion sx={Style.accordianStyling}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    className="custompanel"
                    id="panel3a-header"
                    sx={{ minHeight: '43px !important', maxHeight: '43px !important' }}
                  >
                    <Typography>{t(t1, 'REGISTRATION_SETTINGS')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="plus-minus-btn-main">
                      <List>
                        <ListItem>
                          <CounterField
                            inputClass="hideBrowserDefault"
                            name="registration-settings.pending_user_registration_reminder.AttributeValue"
                            privId="priv_pending_user_registration_reminder_days"
                            label={t(t1, 'PENDING_REGISTRATION_EMAIL_REMINDER_DAYS')}
                            defaultValue={
                              configureState['registration-settings']?.pending_user_registration_reminder?.AttributeValue || '1'
                            }
                            updateValue={(fieldName, val) => updateErrorValue(fieldName, val)}
                            type="text"
                            rules={{
                              required: true,
                              pattern: {
                                value: configSettingsCountRegex,
                                message: `${t(t1, 'ERR_INVALID_PENDING_REGISTRATION_EMAIL_REMINDER_DAYS')}`,
                              },
                            }}
                            isRequiredMsg={'ERR_PENDING_REGISTRATION_EMAIL_REMINDER_DAYS_IS_REQUIRED'}
                          />
                        </ListItem>
                      </List>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
            <NotificationSettings methods={methods} updateErrorValue={updateErrorValue}
                                  configureState={configureState} />
            <AccountSettings updateErrorValue={updateErrorValue} configureState={configureState} methods={methods} />
            <Transfers updateErrorValue={updateErrorValue} configureState={configureState} methods={methods} />
          </Grid>
        </Container>
        {dialogMessage && showModal ? (
          <DialogMessage
            warning
            title={t(t1, 'WARNING')}
            message1={t(t1, 'DIALOG_ERASE_MESSAGE')}
            message2={t(t1, 'DIALOG_RESTART_MESSAGE')}
            handleModalClick={closeConfigurationEditAction}
            /* Conflict Resolution Candidate
                        // buttonMessage={t(t1, "DIALOG_BUTTONS_INFORMATION_MESSAGE")}
            */
            buttonMessage={t(t1, 'DIALOG_BUTTONS_INFORMATION_MESSAGE')} />
        ) : null}
      </form>
    </FormProvider>
    /* Conflict Resolution Candidate
        {loader && <Loader loader={loader} />}
        </>
      )
     */
  ) : (
    <Loader loader />
  );
}

export default ConfigurationSettings;

