import styled from '@emotion/styled';
import ErrorIcon from '@mui/icons-material/Error';
import { Autocomplete, Box, Grid, Paper, TextField, Tooltip, tooltipClasses } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CustomPopper from 'components/_CustomPopper/customPopper';
import { NAICSCodeOption, getCurrentPageName, isEmpty, months, t } from 'utils/constants';
import Style from './CustomAutocomplete.style';

export default function CustomAutocomplete(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { control, getValues } = useFormContext();
  const {
    formSteps: { isEditField, activeStep },
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user; // Here we get fields permission like hidden, read Only or write

  const {
    name,
    type = 'text',
    label = '',
    rules,
    noValueText,
    options = [],
    stepNumber,
    InputProps,
    handleOnChange = false,
    defaultValue,
    labelValue = '',
    gridMd = 4,
    disabled = false,
    disabledReadOnly = true,
    className = '',
    isViewOnly = '',
    isAstricDisplay = false,
    isOptionEqualToValue,
    isShowOld = false,
    RevisionDate = '',
    privId = '',
    customeStyle = '',
    boxLabelClass = '',
    forcePopupIcon = true,
    isRequiredMsg = '',
  } = props;

  let getValueforID = options?.find((item) => item?.value === getValues(name))?.name;
  getValueforID = !isEmpty(getValueforID)
    ? getValueforID
    : options?.find((item) => item?.value === getValues(name))?.title;

  // To get existing value -
  const existingValue = options?.filter((ele) => ele?.value === getValues(name));
  const oldVal = !isEmpty(existingValue) ? existingValue[0] : '';

  const [myDefultValue, setDefaultValue] = useState('');

  useEffect(() => {
    if (isEmpty(oldVal)) {
      setDefaultValue(null);
    } else {
      setDefaultValue(oldVal);
    }
  }, [oldVal]);

  const d = new Date(RevisionDate);
  const revisionDate =
    RevisionDate && `${t(t1, 'REVISION_MADE_ON')} ${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
  let readOnly = false;
  if (privId === '') {
    if (PrivAttributes?.[name]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[name]?.PrivRole === 'RO';
  } else {
    if (PrivAttributes?.[privId]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[privId]?.PrivRole === 'RO';
  }

  if (rules && rules?.required && label) {
    rules.required = `${t(t1, isRequiredMsg)}`;
  }
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      padding: '1px 7px',
      border: '1px solid #000',
      borderRadius: '0px',
    },
  }));

  return (
    <Grid
      item
      md={gridMd}
      className={className}
      sx={{ ...customeStyle }}
    >
      <Box
        component="div"
        className={boxLabelClass || 'label-wrapper'}
      >
        {label ? (
          <InputLabel
            htmlFor="customize-select"
            sx={{ ...Style?.FieldLabel, ...customeStyle }}
          >
            {label}
            {rules?.required || isAstricDisplay ? (
              <Typography
                variant="body2"
                sx={{
                  display: 'inline-block',
                  color: 'common.red',
                  ml: 0.3,
                  fontWeight: 700,
                }}
              >
                *
              </Typography>
            ) : null}
          </InputLabel>
        ) : null}
      </Box>
      {(!isEditField[stepNumber] && activeStep === 4) || isViewOnly || readOnly ? (
        <Typography
          variant="body2"
          sx={Style.valuetext}
        >
          {getValueforID || (
            <Controller
              name={name}
              type={type}
              control={control}
              render={({ field: { onChange, onBlur, value, ...field }, fieldState }) => (
                <Autocomplete
                  PopperComponent={CustomPopper}
                  clearText=""
                  closeText=""
                  openText=""
                  disabled={disabledReadOnly}
                  getOptionLabel={(option) => option.name ?? myDefultValue?.name}
                  defaultValue={[myDefultValue]}
                  getOptionDisabled={(option) => option?.value === ''}
                  value={value || null}
                  id={`${name}_${new Date().valueOf()}`}
                  isOptionEqualToValue={isOptionEqualToValue}
                  options={options}
                  onBlur={onBlur}
                  className={`input-dropdown-disabled`}
                  autoHighlight
                  forcePopupIcon={forcePopupIcon}
                  renderInput={(params) => (
                    <TextField
                      className={disabledReadOnly ? 'disabledDropDown SearchableDropdown' : 'SearchableDropdown'}
                      error={Boolean(fieldState.error)}
                      {...params}
                      // label="Choose a country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                        'aria-autocomplete': 'none',
                      }}
                      placeholder={noValueText}
                    />
                  )}
                  ListboxProps={{
                    style: {
                      maxHeight: 190,
                    },
                  }}
                />
              )}
            />
          )}
        </Typography>
      ) : (
        <Controller
          name={name}
          type={type}
          rules={rules}
          control={control}
          render={({ field: { onChange, onBlur, value, ...field }, fieldState }) => (
            <>
              <Autocomplete
                PopperComponent={CustomPopper}
                clearText=""
                closeText=""
                openText=""
                disabled={disabled}
                getOptionLabel={(option) => option.name ?? myDefultValue?.name}
                defaultValue={[myDefultValue]}
                getOptionDisabled={(option) => option?.value === ''}
                value={value || null}
                id={`${name}_${new Date().valueOf()}`}
                sx={Style.formField}
                isOptionEqualToValue={isOptionEqualToValue}
                options={options}
                onBlur={onBlur}
                forcePopupIcon={forcePopupIcon}
                // isOptionEqualToValue={(option, value) =>
                //   value === undefined || value === "" || option.id === value.id
                // }
                className={`${className}-select`}
                autoHighlight
                renderOption={(props, option) => (
                  <Tooltip title={props.id.slice(0, 9) === `${NAICSCodeOption}` ? option?.name : ''}>
                    <Box
                      component="li"
                      {...props}
                    >
                      {option?.name}
                    </Box>
                  </Tooltip>
                )}
                renderInput={(params) => (
                  <TextField
                    className={disabled ? 'disabledDropDown SearchableDropdown' : 'SearchableDropdown'}
                    error={Boolean(fieldState.error)}
                    {...params}
                    // label="Choose a country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                      'aria-autocomplete': 'none',
                    }}
                    placeholder={noValueText}
                  />
                )}
                onChange={(event, values, reason) => {
                  if (reason === 'clear') {
                    onChange(null);
                    if (handleOnChange) {
                      handleOnChange(null);
                    }
                    return;
                  }
                  if (values) {
                    setDefaultValue(values);
                    onChange(values?.value);
                    if (handleOnChange) {
                      handleOnChange(values);
                    }
                  }
                }}
                ListboxProps={{
                  style: {
                    maxHeight: 190,
                  },
                }}

              />

              {rules && <FormHelperText error>{fieldState?.error ? fieldState?.error?.message : ' '}</FormHelperText>}
            </>
          )}
        />
      )}
      {isViewOnly && isShowOld && (
        <StyledTooltip
          title={
            <Typography
              color="black"
              sx={{ fontSize: '14px' }}
            >
              {revisionDate}
            </Typography>
          }
        >
          <Box
            mt={1 / 4}
            sx={Style.updatedFieldBox}
          >
            <ErrorIcon sx={{ color: '#4d8da4', fontSize: '20px', mx: 1 / 4 }} />
            <Box sx={Style.coloredBox}>
              <Typography
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {isShowOld}
              </Typography>
            </Box>
          </Box>
        </StyledTooltip>
      )}
    </Grid>
  );
}
