import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Spinner() {
  return (
    <Box
      data-testid="progress-bar"
      sx={{ display: 'flex', alignItems: 'center', my: 3, justifyContent: 'center', mx: 'auto' }}
    >
      <CircularProgress data-testid="progress-bar" />
    </Box>
  );
}

export default Spinner;
