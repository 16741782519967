const { t, formatDateUpdated } = require('utils/constants');

export const transformData = (linkRequests, t1) => linkRequests.map((item) => ({
  id: item.receivingJurisdictionCode,
  keyInformation: item.requestingJurisdictionName,
  type: 'JURISDICTION_STATUS',
  status: t(t1, item.linkRequestStatus),
  lastUpdatedAt: formatDateUpdated(item.lastUpdate) || '',
}));

export const transformProposedBatchEmissions = (data) =>
  data
    ?.map((item, idx) => ({
      id: item?.id || idx + 1,
      displayId: item?.batchId,
      keyInformation: item?.batchName,
      type: 'COMMON_BATCH_EMISSIONS',
      status: item?.status,
      lastUpdatedAt: formatDateUpdated(item?.updatedAt) || '',
    }))
    ?.sort((a, b) => new Date(b?.last_updated) - new Date(a?.last_updated));

export const transformProposedBatchTrueUps = (data) =>
  data
    ?.map((item, idx) => ({
      id: item?.id || idx + 1,
      displayId: item?.batchId,
      keyInformation: item?.batchName,
      type: 'COMMON_TRUEUP_BATCH',
      status: item?.status,
      lastUpdatedAt: formatDateUpdated(item?.updatedAt) || '',
    }))
    ?.sort((a, b) => new Date(b?.last_updated) - new Date(a?.last_updated));

