import { QuickLinksMenu } from 'components/QuickLinksMenu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentPageName, t } from 'utils/constants';
import { useDispatch } from 'react-redux';
import { setSelectedTransferIds } from 'store/batchTransferSlice';
import { isEditable } from 'pages/ManageBatchTransfer/constants';


function QuickActionsMenu({ params, status, onRowEditModeChange, setOpenConfirmDialog }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const quickLinkList = React.useMemo(
    () => [
      ...(isEditable(status)
        ? [
          {
            name: t(t1, 'EDIT_BATCH_RECORD')?.toUpperCase(),
            Access: params.row.editMode ? 'RO' : '',
            onLinkClick: () => {
              onRowEditModeChange(params.row.rowId);
            },
          },
        ]
        : []),
      {
        name: t(t1, 'REMOVE_BATCH_RECORD')?.toUpperCase(),
        Access: null,
        onLinkClick: () => {
          dispatch(setSelectedTransferIds(params?.row?.transferUuid));
          setOpenConfirmDialog(true);
        },
      },
    ],
    [dispatch, onRowEditModeChange, params, setOpenConfirmDialog, status, t1],
  );

  return (
    <QuickLinksMenu
      iconClassName="dot-icon"
      popupClassName="quick-link-style"
      linksList={quickLinkList || []}
      isDotIcon
    />
  );
}

export default QuickActionsMenu;
