import { TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import { CheckCircle } from 'assets/icons';
import { CustomeDialogMessage } from 'components/CustomeDialogMessage';
import { FormActions } from 'components/EntityFormActions';
import { FormCard } from 'components/FormCard';
import { TextTruncate } from 'components/TextTruncate';
import CustomPopper from 'components/_CustomPopper/customPopper';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setIsFormEditable, setLoader, setModelMessage } from 'store/commonSlice';
import logger from 'utils/logger';

import { Loader } from 'components/Loader';
import {
  getEntities,
  getEntityContactById,
  resetSteps,
  setEntityID,
  setEntityType,
  setFormValueOfStepByStepName,
  setFormValueSubmittedOfStep,
  setNextStepSelect,
  setSelectedClassification,
  setSelectedOrganization,
  setSkipStepByKey,
  updateEntity,
} from 'store/entityApplicationSlice';
import {
  COMPONENT_MODES,
  COMPONENT_NAMES,
  ENTITY_TYPE_NAMES,
  ERROR,
  PERMISSION_NAMES,
  checkIsReadOrWrite,
  getCurrentPageName,
  handleEntityStatusError,
  isEmpty,
  scrollToTop,
  t,
} from 'utils/constants';
import UnsavedPrompt from '../../../../../components/UnsavedDialog';
import '../../ComponentTextTruncate.css';
import { ENTITY_INFORMATION_MANDATORY_FIELDS_BY_JURISDICTION } from '../../util/entityRegistrationUtil';
import Style from './SelectEntityType.style';

export default function SelectEntityType({
                                           displayHead = true,
                                           mode = COMPONENT_MODES.ADD,
                                           updateCardEdit = () => {
                                           },
                                           isReviewAndSubmit = false,
                                         }) {
  const { steps, Entities, selectedEntity, EditAccess, fieldPermissions } = useSelector(
    ({ entityApplication }) => entityApplication,
  );
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user;
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { id } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const themeColor = theme?.palette?.primary?.main;
  const [isReadMore, setIsReadMore] = useState(t(t1, 'FOOTER_DISCLAIMER_CONTENT').length > 250);
  const [stepData, setStepData] = useState({ editMode: false });
  const [EntityRows, setEntityRows] = useState([]);
  const [showActivateDeactivateModal, setShowActivateDeactivateModal] = useState(false);
  const [isExitInProgress, setIsExitInProgress] = useState(false);
  const [entity, setEntity] = useState(selectedEntity);
  const [pageLoader, setPageLoader] = useState(false);
  const [selectEntitySDBtn, setSelectEntitySDBtn] = useState(false);

  const viewOnly = mode === COMPONENT_MODES.EDIT && !stepData.editMode;
  const isReadOnlyisReadOnly = useMemo(() => {
    return checkIsReadOrWrite(fieldPermissions, 'entity', 'entityType') === PERMISSION_NAMES.READ;
  }, [fieldPermissions]);
  const readOnly = PrivAttributes?.priv_select_Jurisdiction?.PrivRole === 'RO' || isReadOnlyisReadOnly;
  const navigate = useNavigate();
  const defaultValues = {
    entityType: '',
  };
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  let nextStep = null;

  const resetEntityInformationFieldOnEntityChange = () => {
    const previousEntity = steps.find((item) => item.key === COMPONENT_NAMES.SELECT_ENTITY_TYPE)?.filledData?.entityType;

    if (
      methods.getValues()?.entityType?.ID !==
      previousEntity?.ID
      && methods.getValues()?.entityType?.Name !== ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY
    ) {
      const entityInformationData = steps.find((step) => step.key === COMPONENT_NAMES.ENTITY_INFORMATION);
      const entityInformationFilledData = entityInformationData?.filledData || {};

      const isGMPI = methods.getValues()?.entityType?.Name === ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_INDIVIDUAL;
      const newEntityData = { ...entityInformationFilledData };
      if (isGMPI) {
        delete newEntityData.purposeOfAccount;
        delete newEntityData.userClassification;
        delete newEntityData.otherClassification;
        delete newEntityData.country;
        delete newEntityData.province;
        delete newEntityData.incorporationRegion;
        delete newEntityData.dateOfIncorporation;
        delete newEntityData.IdentificationNumber;
        delete newEntityData.IDByAgency;
        delete newEntityData.idNumberAssignedByTheCaliforniaSecretaryOfState;
        delete newEntityData.dunsNumber;
        delete newEntityData.UnitedBusinessID;
        delete newEntityData.québecEnterpriseNumber;
        delete newEntityData.registryID;
        delete newEntityData.businessNumber;
      } else {
        delete newEntityData.organisationType;
        delete newEntityData.otherOrganization;
      }
      dispatch(setSelectedClassification(''));
      dispatch(setFormValueOfStepByStepName({ stepName: 'ENTITY_INFORMATION', data: newEntityData }));
      dispatch(
        setFormValueSubmittedOfStep({
          page: COMPONENT_NAMES.ENTITY_INFORMATION,
          isSubmitted: false,
        }),
      );
    }
    dispatch(setSelectedOrganization(''));
  };

  const handleSubmitUpdate = useCallback(() => {
    const values = methods.getValues();
    setPageLoader(true);
    updateEntity(
      {
        entityType: {
          entityType: methods.getValues().entityType?.ID,
        },
      },
      id,
      false,
      {
        pageName: 'Page_EntityRegistration',
        componentName: 'entityType',
      },
    )
      .then((response) => {
        // reset data on change of enity type
        methods.reset({});
        const isCoveredEntityType =
          response?.data?.EntityType?.Name === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY;
        nextStep = {
          stepName: 'ADD_FACILITIES',
          skip: !isCoveredEntityType,
        };
        dispatch(setEntityType(response?.data?.EntityType?.Name));

        // After entityType change.
        if (
          !isEmpty(id) &&
          response?.data?.EntityType?.Name === ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_ORGANIZATION
        ) {
          values.changedEntitytype = true;
        }
        return Promise.all([dispatch(getEntityContactById(id, false, false, false))]);
      })
      .then(() => {
        resetEntityInformationFieldOnEntityChange();
        dispatch(setSelectedOrganization(''));
        handleEnableEdit(false);
        if (isReviewAndSubmit) {
          dispatch(setModelMessage({
            title: `${t(t1, 'COMMON_SUCCESS')}`,
            message1: t(t1, 'DATA_UPDATED_SUCCESSFULLY'),
            error: false,
            isDefaultDisplay: false,
          }));
        }
        dispatch(setIsFormEditable(false));
        dispatch(setFormValueOfStepByStepName({ stepName: 'SELECT_ENTITY_TYPE', data: values }));
      })
      .catch((error) => {
        setPageLoader(false);
        const hasNavigated = handleEntityStatusError(error, id, dispatch, navigate);
        if (hasNavigated) return;
        logger({ error, name: 'Put updateEntityType' });
        const data = {
          title: ERROR,
          message1: !isEmpty(error?.response?.data?.messageKey) ? error?.response?.data?.messageKey : 'ERROR',
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
      })
      .finally(() => {
        setPageLoader(false);
      });
  }, []);

  const handleEnableEdit = (value) => {
    setSelectEntitySDBtn(false);
    setStepData({ ...stepData, editMode: value });
    dispatch(setIsFormEditable(true));
    updateCardEdit({ cardName: 'ENTITY_SELECTION', isEditing: value });
  };

  const cancelEntityType = () => {
    setSelectEntitySDBtn(false);
    Object.entries(steps.find((item) => item.key === COMPONENT_NAMES.SELECT_ENTITY_TYPE).filledData).forEach(([key, value]) => {
      methods?.setValue(key, value, { shouldValidate: true });
    });
    // methods?.reset(steps.find((item) => item.key === COMPONENT_NAMES.SELECT_ENTITY_TYPE).filledData);
    dispatch(setIsFormEditable(false));
    updateCardEdit({ cardName: 'ENTITY_SELECTION', isEditing: false });
  };

  const resetData = () => {
    dispatch(resetSteps());
    dispatch(setEntityType(''));
    dispatch(setEntityID(0));
    navigate('/start-entity-registration');
  };

  useEffectOnce(() => {
    scrollToTop();
  });

  useEffectOnce(() => {
    dispatch(getEntities());
  }, []);

  useEffectOnce(() => {
    const defaultValues = steps.find((item) => item.key === COMPONENT_NAMES.SELECT_ENTITY_TYPE).filledData;
    methods?.reset(defaultValues);
  }, [steps]);

  useEffect(() => {
    const defaultValues = steps.find((item) => item.key === COMPONENT_NAMES.SELECT_ENTITY_TYPE).filledData;

    if (!isEmpty(id) && !isEmpty(defaultValues) && !isEmpty(defaultValues.entityType)) {
      const rows = [...Entities].map((item) => {
        const entityGroup = [
          'COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY',
          'GENERAL_MARKET_PARTICIPANT_ORGANIZATION',
        ];
        if (
          (entityGroup.includes(defaultValues?.entityType.Name) && entityGroup.includes(item.Name)) ||
          (['GENERAL_MARKET_PARTICIPANT_INDIVIDUAL'].includes(defaultValues?.entityType.Name) &&
            ['GENERAL_MARKET_PARTICIPANT_INDIVIDUAL'].includes(item.Name))
        ) {
          return { ...item, disabled: false };
        }
        return { ...item, disabled: true };
      });
      setEntityRows(rows);
    } else {
      setEntityRows(Entities);
    }
    dispatch(setLoader(Entities.length === 0));
  }, [Entities]);

  if (PrivAttributes?.priv_select_Jurisdiction?.PrivRole === 'NA') {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <div
        className={`STEP_CLASSNAME_${COMPONENT_NAMES.SELECT_ENTITY_TYPE} MODE_${mode} ${viewOnly ? 'VIEW_ONLY' : ''}`}
      >
        {displayHead && (
          <Typography style={Style.contentTextCard}>
            <div
              className="contentTextCardTwo"
              style={Style.contentTextCard}
            >
              <TextTruncate
                content={t(t1, 'FOOTER_DISCLAIMER_CONTENT')}
                animate
              />
            </div>
          </Typography>
        )}

        <FormCard
          customClass={'customFormCard entity-type'}
          indicateRequired={false}
          setDefaultValues={() => methods?.reset(defaultValues)}
          stepNumber={2}
          title={t(t1, viewOnly ? 'SELECTED_ENTITY_TYPE' : 'SELECT_ENTITY_TYPE')}
          mode={mode}
          handleEnableEdit={handleEnableEdit}
          handleSubmitUpdate={handleSubmitUpdate}
          cancelEntityType={cancelEntityType}
          saveDisabled={!stepData.editMode || !methods?.formState.isValid || !methods?.formState.isDirty || !selectEntitySDBtn}
          stepData={stepData}
          stepName={COMPONENT_NAMES.SELECT_ENTITY_TYPE}
          isEditAccess={ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_INDIVIDUAL !== selectedEntity && EditAccess}
        >
          <FormControl
            sx={{
              mt: 2,
              ml: 0,
              width: 400,
              '& .Mui-error': { marginLeft: 0 },
            }}
          >
            {viewOnly || readOnly ? (
              <Typography
                component="div"
                className="icon-lg"
                sx={Style.inputStyle}
              >
                <CheckCircle color={themeColor} />
                {t(t1, methods.getValues('entityType')?.Name)}
              </Typography>
            ) : (
              <Controller
                name="entityType"
                control={methods?.control}
                rules={{ required: t(t1, 'SELECT_ENTITY_TYPE_REQUIRED') }}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <Autocomplete
                        PopperComponent={CustomPopper}
                        {...field}
                        value={field.value}
                        id="select-entity"
                        disableClearable
                        readOnly={mode === COMPONENT_MODES.EDIT && !stepData.editMode}
                        options={EntityRows}
                        getOptionLabel={(option) => (option.Name ? t(t1, option.Name) : '')}
                        getOptionDisabled={(option) => option.disabled === true}
                        renderOption={(props, option, state) => {
                          return (
                            <MenuItem
                              {...props}
                              disabled={option.disabled}
                              key={option.ID}
                              value={option.Name}
                              style={{ pointerEvents: 'none' }}
                              sx={{
                                padding: '0 !important',
                              }}
                            >
                              <ListItemText
                                primary={t(t1, option.Name)}
                                style={{ pointerEvents: !option.disabled ? 'auto' : '' }}
                                sx={{ px: '16px', py: '6px' }}
                              />
                            </MenuItem>
                          );
                        }}
                        sx={{
                          ...Style.formField,
                        }}
                        onChange={(e, newValue) => {
                          const event = {
                            ...e,
                            target: { ...e.target, value: newValue },
                          };
                          setSelectEntitySDBtn(true);
                          if (id && newValue) {
                            if (
                              newValue.Name !== ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_INDIVIDUAL &&
                              newValue.Name !== selectedEntity
                            ) {
                              setShowActivateDeactivateModal({
                                callback: () => {
                                  field?.onChange(event);
                                },
                              });
                            } else {
                              setShowActivateDeactivateModal(false);
                              field?.onChange(event);
                            }
                          }

                          if (isEmpty(id)) {
                            if (!entity) {
                              if (isEmpty(event?.target?.value?.Name)) {
                                field?.onChange(newValue);
                              } else {
                                field?.onChange(event);
                              }
                            } else if (
                              newValue?.Name !== entity &&
                              newValue?.Name !== methods.getValues('entityType')?.Name
                            ) {
                              setShowActivateDeactivateModal({
                                callback: () => {
                                  if (isEmpty(event?.target?.value?.Name)) {
                                    setEntity(newValue);
                                    field?.onChange(newValue);
                                  } else {
                                    setEntity(event?.target?.value?.Name);
                                    field?.onChange(event);
                                  }
                                },
                              });
                            } else {
                              field?.onChange(event);
                            }
                          }
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              error={fieldState?.error?.message}
                              placeholder={methods?.getValues('entityType')?.length ? '' : t(t1, 'SELECT_ENTITY_TYPE')}
                              className={'SearchableDropdown'}
                            />
                          );
                        }}
                        disabled={!EditAccess}
                      />

                      {fieldState?.error?.message ? (
                        <FormHelperText error>{fieldState?.error?.message}</FormHelperText>
                      ) : null}
                    </>
                  );
                }}
              />
            )}
          </FormControl>
        </FormCard>
        {mode === COMPONENT_MODES.ADD && (
          <FormActions
            handleSubmit={(data) => {
              const entityInformationData = steps.find((step) => step.key === COMPONENT_NAMES.ENTITY_INFORMATION);
              const jurisdictionName = steps.find((step) => step.key === COMPONENT_NAMES.SELECT_JURISDICTION)?.filledData?.jurisdiction?.Name;
              const entityInformationFilledData = entityInformationData?.filledData || {};
              const mandatoryFields = (ENTITY_INFORMATION_MANDATORY_FIELDS_BY_JURISDICTION[jurisdictionName] || [
                'IdentificationNumber',
                'IDByAgency',
              ]);
              dispatch(
                setFormValueSubmittedOfStep({
                  page: COMPONENT_NAMES.ENTITY_INFORMATION,
                  isSubmitted:
                    (
                      data?.entityType?.Name === ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_INDIVIDUAL &&
                      entityInformationFilledData.purposeOfAccount
                    ) ||
                    (data?.entityType?.Name !== ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_INDIVIDUAL &&
                      !mandatoryFields.some((field) => !entityInformationFilledData[field])),
                }),
              );
              if (id) {
                if (
                  methods.getValues()?.entityType?.ID !==
                  steps.find((item) => item.key === COMPONENT_NAMES.SELECT_ENTITY_TYPE)?.filledData?.entityType?.ID
                ) {
                  handleSubmitUpdate();
                } else {
                  const values = methods.getValues();
                  const isCoveredEntityType =
                    data?.entityType?.Name === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY;
                  dispatch(setSkipStepByKey({ stepName: 'ADD_FACILITIES', skip: !isCoveredEntityType }));
                  dispatch(setSelectedOrganization(''));
                  handleEnableEdit(false);
                  dispatch(setIsFormEditable(false));
                  dispatch(setFormValueOfStepByStepName({ stepName: 'SELECT_ENTITY_TYPE', data: values }));
                }
              } else {
                resetEntityInformationFieldOnEntityChange();
                const isCoveredEntityType =
                  data?.entityType?.Name === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY;
                dispatch(setSkipStepByKey({ stepName: 'ADD_FACILITIES', skip: !isCoveredEntityType }));
                dispatch(setEntityType(data?.entityType?.Name));
                dispatch(setEntityID(data?.entityType?.ID));
              }
              dispatch(setNextStepSelect({ steps }));
            }}
            stepNumber={3}
            continueDisabled={!methods?.formState.isValid}
            onExitClick={() => setIsExitInProgress(true)}
            onCancelExit={() => setIsExitInProgress(false)}
          />
        )}

        {showActivateDeactivateModal && (
          <CustomeDialogMessage
            title={t(t1, 'WARNING---')}
            continueBtn={t(t1, 'CONTINUE')}
            open={showActivateDeactivateModal}
            handleClose={() => setShowActivateDeactivateModal(false)}
            message1={t(t1, 'CHANGE_ENTITY_TYPE_WARNING')}
            message2={t(t1, 'CANCEL_WARNING_CONTENT2')}
            message3={t(t1, 'CHANGE_ENTITY_TYPE_WARNING_MESSAGE3')}
            borderColor="red"
            cancelApplicationModal
            handleContinue={() => {
              showActivateDeactivateModal.callback();
              setShowActivateDeactivateModal(false);
            }}
          />
        )}
      </div>
      <UnsavedPrompt id={id}
                     when={!isExitInProgress && displayHead}
                     title={t(t1, 'WARNING')}
                     message={t(t1, 'CONFIRM_LEAVE_PAGE')}
                     onSuccess={resetData}
      />
      {pageLoader && <Loader loader={pageLoader} />}
    </FormProvider>
  );
}
