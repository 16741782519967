import authRoles from 'core/auth/authRole';
import AddJurisdictionBudget from './AddBudgetRecord';

const AddJurisdictionBudgetConfig = {
  route: {
    path: '/budgets/:id/add',
    element: <AddJurisdictionBudget />,
  },
  layout: {
    header: {
      display: true,
    },
    sidebar: {
      display: false,
    },
    footer: {
      display: true,
    },
  },
  auth: authRoles.admin,
};

export default AddJurisdictionBudgetConfig;