import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  t,
  isEmpty,
  USER_ROLES,
  USER_ROLE_TYPES,
  isRolesAssigned,
  COMPONENT_NAME_FOR_ROLE,
} from 'utils/constants';
import Style from './ApplicationChecklist.style';
import { useEffectOnce } from '../../../../hooks/useEffectOnce';
import {
  getAlternateContact,
} from '../../../../store/entityApplicationSlice';

const PageName = 'Page_ApplicationWithAttenstation';

function EntityAlternateContact() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { AlternateContact } = useSelector(
    ({ entityApplication }) => entityApplication,
  );
  const { t: t1 } = useTranslation(PageName);

  const hasAlternateContact = !isEmpty(AlternateContact?.data);

  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { isIndividualUser, isJurisdictionRegistrarUser } = useMemo(
    () => ({
      isIndividualUser: isRolesAssigned(userDetails, [USER_ROLE_TYPES.INDIVIDUAL_USER]),
      isJurisdictionRegistrarUser: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.REGISTRAR],
      ),
    }),
    [userDetails],
  );

  const alternateContactPersonPageDetail = {
    componentName: COMPONENT_NAME_FOR_ROLE.ALTERNATE_CONTACT_PERSON,
    pageName: 'Page_EntityProfile',
  };


  useEffectOnce(() => {
    dispatch(getAlternateContact(id, alternateContactPersonPageDetail));
  });

  if (!hasAlternateContact) {
    return null;
  }

  return <>
    <Typography sx={Style.entityIdentification}>{t(t1, 'ALTERNATE_CONTACT_PERSON_OPTIONAL')}</Typography>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, 'CONTACT_FIRST_NAME')}</Typography>
      <Typography sx={Style.entryValue}>{AlternateContact?.data?.firstName}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, 'CONTACT_LAST_NAME')}</Typography>
      <Typography sx={Style.entryValue}>{AlternateContact?.data?.lastName}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, 'CONTACT_POSITION')}</Typography>
      <Typography sx={Style.entryValue}>{AlternateContact?.data?.position}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, 'CONTACT_TELEPHONE')}</Typography>
      <Typography sx={Style.entryValue}>{AlternateContact?.data?.telephoneNumber}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, 'CONTACT_MOBILE_NUMBER')}</Typography>
      <Typography sx={Style.entryValue}>{AlternateContact?.data?.mobilePhone}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, 'CONTACT_E_MAIL_ADDRESS')}</Typography>
      <Typography sx={Style.entryValue}>{AlternateContact?.data?.email}</Typography>
    </Box>
  </>;
}

export default EntityAlternateContact;
