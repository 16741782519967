import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ListItem from '@mui/material/ListItem';
import Spinner from 'components/Spinner';
import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { getGridDateOperators } from '@mui/x-data-grid-premium';
import { betweenOperators } from 'components/MUITableGrid/components/MUICustomFilterFunctions';
import { getPendingTransfers } from 'store/entityTransferSlice';
import {
  ENTITY_ACCOUNT_TYPES_FE,
  formatDateUpdated,
  getCurrentPageName,
  getFilterForColumn,
  removeTimeZone,
  showOnlyPermittedFields,
  t,
} from 'utils/constants';
import MUITableGrid from '../../components/MUITableGrid/MUITableGrid';

function PendingTransfers() {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const { pendingTransfers } = useSelector(({ entityTransfer }) => entityTransfer);

  const pendingTransfersRows = useMemo(() => !isEmpty(pendingTransfers.data?.data)
    ? getNormalizedPendingTransfers(pendingTransfers.data?.data)
    : [], [pendingTransfers.data?.data]);

  const valueOptions = useMemo(() => getFilterForColumn(pendingTransfersRows, t, t1, ['transferringAccountType', 'receivingAccountType', 'requestStatus']), [pendingTransfersRows, t1]);

  const getRouteURL = (val) => {
    if (!val?.transferringAccountFlag)
      return `/entity-transfers/details/${val?.entityUUID}/${val?.entityTransferId}/${val?.entityAccId}/${val?.transferType}/${val?.receivingEntityUUID}`;
    return `/entity-transfers/details/${val?.entityUUID}/${val?.entityTransferId}/${val?.entityAccId}/${val?.transferType}`;
  };

  const pendingTransfersColumns = [
    {
      id: 1,
      field: 'entityTransferId',
      headerName: t(t1, 'ENTITY_TRANSFER_ID'),
      minWidth: 160,
      align: 'center',
      hideable: false,
      flex: 1,
      renderCell: (cellValues) => (
        <RouterLink
          to={getRouteURL(cellValues.row)}
        >
          <Typography className="link-cell">{cellValues?.row?.entityTransferId}</Typography>
        </RouterLink>
      ),
    },
    {
      id: 2,
      field: 'transferringAccountName',
      headerName: t(t1, 'TRANSFERRING_ACCOUNT_NAME'),
      minWidth: 230,
      align: 'center',
      flex: 1,
    },
    {
      id: 3,
      field: 'transferringAccountType',
      headerName: t(t1, 'TRANSFERRING_ACCOUNT_TYPE'),
      minWidth: 230,
      align: 'center',
      flex: 1,
      valueGetter: ({ value }) => t(t1, value),
      type: 'singleSelect',
      valueOptions: valueOptions.transferringAccountType?.sort?.(),
    },
    {
      id: 4,
      field: 'receivingAccountName',
      headerName: t(t1, 'RECEIVING_ACCOUNT_NAME'),
      minWidth: 230,
      align: 'center',
      flex: 1,
    },
    {
      id: 5,
      field: 'receivingAccountType',
      headerName: t(t1, 'RECEIVING_ACCOUNT_TYPE'),
      minWidth: 230,
      align: 'center',
      flex: 1,
      valueGetter: ({ value }) => t(t1, value),
      type: 'singleSelect',
      valueOptions: valueOptions.receivingAccountType?.sort?.(),
    },

    {
      id: 6,
      field: 'requestStatus',
      headerName: t(t1, 'STATUS'),
      minWidth: 170,
      align: 'center',
      flex: 1,
      valueGetter: ({ value }) => t(t1, value),
      type: 'singleSelect',
      valueOptions: valueOptions.requestStatus?.sort?.(),
    },
    {
      id: 7,
      field: 'updatedAt',
      headerName: `${t(t1, 'LAST_UPDATED_ET')}`,
      minWidth: 200,
      align: 'center',
      flex: 1,
      filterOperators: [...getGridDateOperators(), ...betweenOperators],
      valueGetter: ({ value }) => removeTimeZone(value),
    },
  ];

  useEffect(() => {
    dispatch(getPendingTransfers());
  }, [dispatch]);

  function getNormalizedPendingTransfers(data) {
    if (isEmpty(data)) return null;
    return data
      .map((item, idx) => ({
        id: item?.entityTransferId || idx + 1,
        entityTransferId: item?.entityTransferId || '',
        transferringAccountName: item?.transferringAccountName || '',
        transferringAccountType: item?.transferringAccountType,
        receivingAccountName: item?.receivingAccountName || '',
        receivingAccountType: !isEmpty(item?.receivingAccountTypeLanguage)
          ? item.receivingAccountTypeLanguage?.find(
            (item) => item.language_code === localStorage.getItem('languageCode'),
          )?.account_name
          : item?.receivingAccountType,
        requestStatus: item?.requestStatus,
        updatedAt: formatDateUpdated(item?.updatedAt) || '',
        entityUUID: item?.entityUUID,
        entityAccId: item?.entityAccountId,
        transferType: item?.transferType,
        transferringAccountFlag: item?.transferringAccountFlag,
        receivingEntityUUID: item?.receivingEntityUUID,
      }))
      ?.sort((a, b) => b?.entityTransferId && b.entityTransferId - a.entityTransferId);
  }

  return (
    <ListItem className="pending-accordion">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{t(t1, 'PENDING_TRANSFERS')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {pendingTransfers.isLoading ? (
            <Spinner />
          ) : (
            <MUITableGrid
              isExport
              isSettings
              isSearch
              enablePrintView
              rows={pendingTransfersRows || []}
              columns={showOnlyPermittedFields(
                pendingTransfersColumns,
                pendingTransfers?.data?.metaData,
                pendingTransfers?.data?.fieldAccess,
              )}
              count={pendingTransfersRows?.length}
              pageSize={5}
              fileName={t(t1, 'PENDING_TRANSFERS')}
              customPinnedColumns={{ left: ['entityTransferId'] }}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
}

export default PendingTransfers;
