/* Conflict Resolution Candidate
import fetcher from "./fetcher";

export default function logger(data) {
  // fetcher(`logger`, "PATCH", data, false)
  //   .then((response) => {
  //     console.log("response", response);
  //   })
  //   .catch((err) => {
  //     console.log("err", err);
  //   });
 */
import { isEmpty } from './constants';

export default function logger(data) {
  if (!isEmpty(process.env.REACT_APP_IS_LOGGER)) {
    if (data.info) {
      console.info(data.info);
    }
    if (data.error) {
      console.info(data.error);
    }
  }
}
