import { Box, Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { DialogMessage, FormCard } from 'components';
import { CustomizeButton } from 'components/CustomizeButton';
import { Loader } from 'components/Loader';
import { TextTruncate } from 'components/TextTruncate';
import { RichTextWithScrollStyle } from 'config/common.styles';
import { useEffectOnce } from 'hooks/useEffectOnce';
import PassphraseConfirmationDialog from 'pages/WiringInstructions/components/PassphraseConfirmationDialog';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setDialogMessage, setIsFormEditable, setLoader, setModelMessage } from 'store/commonSlice';
import {
  changeStatusToSubmit,
  getRepresentative,
  resetSteps,
  saveFacility,
  setEntityID,
  setEntityType,
  setHandleRepReset,
  setNextStepSelect,
  setRepresentative,
  setRepresentativeOnLocal,
  setSubmittedOfSelectedStep,
  updateLock,
  updateRepresentative,
} from 'store/entityApplicationSlice';
import {
  COMPONENT_MODES,
  COMPONENT_NAMES,
  COMPONENT_NAME_FOR_ROLE,
  ENTITY_TYPE_NAMES,
  JURISDICTIONS_NAMES,
  REVIEW_PAGE_COMPONENTS,
  STATUS_IDS, SUCCESS,
  USER_ROLES, USER_ROLE_TYPES,
  getCurrentPageName,
  handleEntityStatusError,
  handleRepresentativeError,
  isEmpty,
  isRolesAssigned,
  t
} from 'utils/constants';
import logger from 'utils/logger';
import UnsavedPrompt from '../../../../../components/UnsavedDialog';
import '../../ComponentTextTruncate.css';
import AddFacility from '../AddFacility/AddFacility';
import { AlternateContactForm } from '../AddRepresentative/AddAlternateContact';
import AddRepresentative from '../AddRepresentative/AddRepresentative';
import Auction from '../Auction/Auction';
import ContactInformation from '../ContactInformation/ContactInformation';
import EnterEntityInformation from '../EnterEntityInformation/EnterEntityInformation';
import SelectEntityType from '../SelectEntityType/SelectEntityType';
import SelectJurisdiction from '../SelectJurisdiction/SelectJurisdiction';
import Style from './ReviewSubmitStyle';

export default function ReviewandSubmit({ displayHead = true }) {
  const { id } = useParams();
  const [formState, setFormState] = useState(null);
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const {
    steps,
    selectedEntity,
    Representative,
    NAICS,
    Facilities,
    EntityIsAuction,
    selectedJurisdiction,
    EditAccess,
    RepresentativesByGET,
    enableSaveBtnOnReview,
    EntitiesData,
    disabledFacilitySubmitBtn,
    lockIntervalId,
  } = useSelector(
    ({ entityApplication }) => entityApplication,
  );
  const [stepData, setStepData] = useState({ editMode: false });
  const [stepRepresentativeData, setStepRepresentativeData] = useState({ editMode: false });
  const [showModal, setShowModal] = useState(false);
  const [openEaaSuccessDialog, setOpenEaaSuccessDialog] = React.useState(false);
  const [EntityInformationError, setEntityInformationError] = useState(false);
  const [AlternateContactError, setAlternateContactError] = useState(false);
  const [facilityMode, setFacilityMode] = useState(COMPONENT_MODES.EDIT);
  const cardsValues = REVIEW_PAGE_COMPONENTS.map((item) => {
    return { cardName: item, isEditing: false };
  });
  const [cardEdit, setCardEdit] = useState(cardsValues);
  const [customLoader, setCustomLoader] = useState(false);
  const [isExitInProgress, setIsExitInProgress] = useState(false);
  const [enableSaveBtnOnRepresentative, setEnableSaveBtnOnRepresentative] = useState(false);
  const [newRepAdded, setNewRepAdded] = useState(false);
  const [repEdited, setRepEdited] = useState(false);
  const [passphraseDialog, setPassphraseDialog] = useState(false);


  const representativeAddBtn = () => {
    setEnableSaveBtnOnRepresentative(true);
  };

  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { isIndividualUser, isJurisdictionRegistrarUser } = useMemo(
    () => ({
      isIndividualUser: isRolesAssigned(userDetails, [USER_ROLE_TYPES.INDIVIDUAL_USER]),
      isJurisdictionRegistrarUser: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.REGISTRAR],
      ),
    }),
    [userDetails],
  );

  let representativesPageDetail = {
    componentName: COMPONENT_NAME_FOR_ROLE.REPRESENTATIVE,
    pageName: 'Page_EntityProfile',
  };
  if (isJurisdictionRegistrarUser) {
    representativesPageDetail = {
      componentName: COMPONENT_NAME_FOR_ROLE.REPRESENTATIVE,
      pageName: 'Page_JurisdictionEntityProfile',
    };
  }

  useEffectOnce(() => {
    const defaultValues = steps.find((item) => item.key === COMPONENT_NAMES.SELECT_JURISDICTION).filledData;
    methods?.reset(defaultValues);
  }, []);

  useEffect(() => {
    if (EntitiesData?.data?.entityStatus?.name === 'PENDING') {
      navigate(`/entity-profile/${id}`);
    }
  }, [EntitiesData]);
  const navigate = useNavigate();
  const resetData = () => {
    if (id) {
      dispatch(resetSteps());
      dispatch(setEntityType(''));
      dispatch(setEntityID(0));
      navigate('/start-entity-registration');
    }
  };
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {},
  });
  const passPhraseMethods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {},
  });
  const handleSuccessOk = () => {
    setOpenEaaSuccessDialog(false);
    dispatch(setNextStepSelect({ steps }));
  };

  const handleClose = () => {
    setOpenEaaSuccessDialog(false);
  };

  const handleEditSaveClick = () => {
    const countOfenabledButton = document.getElementsByClassName('cancelBtnAction');
    if (!isEmpty(countOfenabledButton)) {
      Object?.keys(countOfenabledButton)?.forEach((ele) => document.getElementsByClassName('cancelBtnAction')[ele].click());
    }
    setPassphraseDialog(true)
    methods.reset();
  };

  const handleCancelModal = () => {
    navigate('/home');
  };

  const handleEnableEdit = (value) => {
    setStepData({ ...stepData, editMode: value });
    updateCardEdit({ cardName: 'FACILITY', isEditing: value });
    dispatch(setIsFormEditable(true));
    if (value) {
      const facility = methods.getValues();
      methods.reset();
      Object.keys(facility).forEach((fieldName) => {
        methods.setValue(fieldName, facility[fieldName]);
      });
    }
  };

  const verifyNewRepAdded = (value) => {
    setNewRepAdded(value);
  };

  const verifyRepEdited = (value) => {
    setRepEdited(value);
  };


  const handleResetEdit = () => {
    setStepData({ ...stepData, editMode: false });
    updateCardEdit({ cardName: 'FACILITY', isEditing: false });
    dispatch(setIsFormEditable(false));
  };
  //
  const handleSubmitUpdate = async (facilityData) => {
    try {
      const defaultValue = steps.find((item) => item.key === COMPONENT_NAMES.ADD_FACILITIES).filledData;
      const updatedValue = facilityData?.map((item, index) => {
        const newItem = { ...item, is_updateGHG: false };
        if (newItem?.GHGID !== defaultValue[index]?.GHGID) {
          newItem.is_updateGHG = true;
        }
        return newItem;
      });
      dispatch(setLoader(true));
      await dispatch(saveFacility(id, updatedValue, NAICS, t1, false));
      handleEnableEdit(false);
      dispatch(setIsFormEditable(false));
      updateCardEdit({ cardName: 'FACILITY', isEditing: false });
    } catch (error) {
      console.error('Error saving facility:', error);
      handleEnableEdit(false);
      dispatch(setIsFormEditable(false));
      updateCardEdit({ cardName: 'FACILITY', isEditing: false });
    }
  };
  const updateCardEdit = (editingCard) => {
    setCardEdit((currentCardState) => {
      return currentCardState.map((card) => {
        if (card.cardName === editingCard.cardName) {
          return { ...editingCard };
        }
        return card;
      });
    });
  };

  const {
    register,
    formState: { errors },
  } = methods;
  const { dialogMessage, isFormEditable, loader } = useSelector(({ common }) => common);

  const handleCancel = () => {
    dispatch(setDialogMessage(true));
    setShowModal(true);
  };

  const handleReviewAndSubmit = async () => {
    try {
      setCustomLoader(true);
      setPassphraseDialog(false);
      const response = await changeStatusToSubmit(id);
      if (response?.messageKey === "APPLICATION_SUBMITTED_SUCCESSFULLY") {
        setCustomLoader(false);
      }
      clearInterval(lockIntervalId);
      dispatch(updateLock(id, false));
      dispatch(setSubmittedOfSelectedStep(true));
      dispatch(setNextStepSelect({ steps }));
      const data = {
        title: SUCCESS,
        message1: "A_CONFIRMATION_HAS_BEEN_EMAILED_TO_THE_ADDRESS_PROVIDED",
        error: false,
        isDefaultDisplay: false,
      };
      dispatch(setModelMessage(data));
    } catch (error) {
      setCustomLoader(false)
      const hasNavigated = handleEntityStatusError(error, id, dispatch, navigate);
      if (hasNavigated) return null;
      // further error handling
    }
  };

  // Repsentative

  const handleEnableEditReps = (value) => {
    setEnableSaveBtnOnRepresentative(false);
    setStepRepresentativeData({ ...stepRepresentativeData, editMode: value });
    updateCardEdit({ cardName: 'REPRESENTATIVE', isEditing: value });
    dispatch(setIsFormEditable(value));
    if (value) {
      const representativeField = methods.getValues();
      methods.reset();
      Object.keys(representativeField).forEach((fieldName) => {
        methods.setValue(fieldName, representativeField[fieldName]);
      });
    }
    setNewRepAdded(false);
    setRepEdited(false);
  };


  const AddRepresentativeShowError = (error) => {
    handleRepresentativeError(error, dispatch, logger);
  };

  const handleSubmitUpdateReps = () => {
    dispatch(setLoader(true));
    const reps = Representative?.map((item) => {
      return {
        id: item.id,
        role: (item.role?.id) ? item.role?.id : item.role,
        userReferenceCode: item.userReferenceCode,
        isAllowedAccess: item.isAllowedAccess,
        name: item.name,
        entityId: id,
        email: item.email,
        status: STATUS_IDS.PROPOSED_STATUS_ID,
      };
    });
    const postData = {
      representative: reps,
    };
    if (id) {
      dispatch(setRepresentativeOnLocal([]));
      updateRepresentative(postData, id).then((response) => {
        // TBD - After saved successfully need to notify
        const data = {
          title: SUCCESS,
          message1: response?.messageKey,
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setRepresentativeOnLocal([]));
        dispatch(getRepresentative(id, representativesPageDetail));
        dispatch(setLoader(false));
        dispatch(setIsFormEditable(false));
        handleEnableEditReps(false);
        updateCardEdit({ cardName: 'REPRESENTATIVE', isEditing: false });
      })
        .catch((error) => {
          dispatch(setLoader(false));
          const hasNavigated = handleEntityStatusError(error, id, dispatch, navigate);
          if (hasNavigated) return;
          AddRepresentativeShowError(error);
        });
    }
    dispatch(setHandleRepReset(true));
  };

  const handleCancelRepresentative = () => {
    setEnableSaveBtnOnRepresentative(false);
    dispatch(setRepresentativeOnLocal([]));
    dispatch(setRepresentative(RepresentativesByGET));
    updateCardEdit({ cardName: 'REPRESENTATIVE', isEditing: false });
    dispatch(setHandleRepReset(true));
  };
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  useMemo(() => {
    if (selectedEntity === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY) {
      setEntityInformationError(false);
    }
  }, [selectedEntity]);

  const getEntityError = (data) => {
    if (selectedEntity === ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_ORGANIZATION) {
      if (isEmpty(data)) {
        setEntityInformationError(true);
      } else {
        setEntityInformationError(false);
      }
    }
  };

  const [checkFacility, setCheckFacility] = useState(Facilities);

  const checkAlternateValidation = (value) => {
    setAlternateContactError(value);
  };

  const isEditable = EditAccess;
  const facilityModeShift = (value) => {
    setFacilityMode(value);
  };

  const hideCancel = isEmpty(steps.find((item) => item.key === COMPONENT_NAMES.ADD_FACILITIES).filledData);

  return (
    <>
      {displayHead && (
        <Typography style={Style.contentTextCard}>
          <div
            className="contentTextCardTwo"
            style={Style.contentTextCard}
          >
            <TextTruncate
              content={t(t1, "FOOTER_DISCLAIMER_CONTENT")}
              animate
            />
          </div>
        </Typography>
      )}
      {
        <SelectJurisdiction
          displayHead={false}
          mode={COMPONENT_MODES.EDIT}
          updateCardEdit={updateCardEdit}
        />
      }
      {
        <SelectEntityType
          displayHead={false}
          mode={COMPONENT_MODES.EDIT}
          updateCardEdit={updateCardEdit}
          isReviewAndSubmit
        />
      }
      {
        <EnterEntityInformation
          displayHead={false}
          mode={COMPONENT_MODES.EDIT}
          isReviewAndSubmit
          getEntityError={getEntityError}
          updateCardEdit={updateCardEdit}
        />
      }
      {
        <ContactInformation
          displayHead={false}
          mode={COMPONENT_MODES.EDIT}
          isReviewAndSubmit
          combineAddressUpdate
          updateCardEdit={updateCardEdit}
        />
      }
      {selectedEntity === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY && (
        <div className={stepData.editMode ? "facility-editonly" : "facility-viewonly"}>
          <FormCard
            title={t(t1, "FACILITY_INFORMATION")}
            mode={facilityMode}
            handleEnableEdit={handleEnableEdit}
            handleSubmitUpdate={handleSubmitUpdate}
            saveDisabled={!stepData.editMode}
            stepData={stepData}
            stepName={COMPONENT_NAMES.ADD_FACILITIES}
            customClass={"customFormCard add-facilities"}
            hideCancelSaveBtn
            customeStyle={!stepData.editMode ? Style.paddingBottom : Style.paddingBottom20}
            showCancelSaveForFacility={false}
            hideCancel={hideCancel}
            isEditAccess={EditAccess}
          >
            <AddFacility
              displayHead={false}
              handleResetEdit={handleResetEdit}
              usage={COMPONENT_NAMES.REVIEW_AND_SUBMIT}
              mode={COMPONENT_MODES.EDIT}
              stepData={stepData}
              setCheckFacility={setCheckFacility}
              handleSubmitChanges={handleSubmitUpdate}
              editModeforReviewandSubmit={stepData?.editMode}
              switchFacilityMode={facilityModeShift}
              hideCard
            />
          </FormCard>
        </div>
      )}

      {
        <div className={stepRepresentativeData.editMode ? "representative-editonly" : "representative-viewonly"}>
          <FormCard
            title={stepRepresentativeData.editMode ? "" : t(t1, "REPRESENTATIVES")}
            mode={COMPONENT_MODES.EDIT}
            handleEnableEdit={handleEnableEditReps}
            handleSubmitUpdate={handleSubmitUpdateReps}
            handleCancelRepresentative={handleCancelRepresentative}
            saveDisabled={Representative?.length === 0 || !(repEdited || newRepAdded)}
            stepData={stepRepresentativeData}
            stepName={COMPONENT_NAMES.ADD_REPRESENTATIVES}
            customClass={"customFormCard add-representatives"}
            isEditAccess={EditAccess}
            indicateRequired={!stepRepresentativeData.editMode}
            customeStyle={!stepRepresentativeData.editMode ? Style.paddingBottom : Style.paddingBottom20}
          >
            <AddRepresentative
              showRepTableTitle={stepRepresentativeData.editMode}
              title={false}
              representativeAddFunc={representativeAddBtn}
              removeViwingAgent
              displayHead={false}
              mode={COMPONENT_MODES.EDIT}
              stepData={stepRepresentativeData}
              showUpdateByColumn
              hideAlternateContactForm={false}
              onValidationChange={(isValid) => {
                setFormState(isValid);
              }}
              disabledOnReviewAndSubmit
              hideCard
              isReviewAndSubmit
              verifyNewRepAdded={verifyNewRepAdded}
              verifyRepEdited={verifyRepEdited}
            />
          </FormCard>
        </div>
      }

      <AlternateContactForm
        mode={COMPONENT_MODES.EDIT}
        updateCardEdit={updateCardEdit}
      />
      {selectedJurisdiction !== JURISDICTIONS_NAMES.QUEBEC && (
        <Auction
          displayHead={false}
          mode={COMPONENT_MODES.EDIT}
          updateCardEdit={updateCardEdit}
          isReviewAndSubmit
        />
      )}
      <FormProvider {...methods}>
        <Box
          sx={{
            mt: 5,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{ marginLeft: "auto" }}
            className="btn-wrapper"
          >
            <CustomizeButton
              customStyle={{ mr: 1 }}
              variant="outlined"
              csName="rc"
              label={t(t1, "EXIT_APPLICATION")}
              name="priv_modal_Cancel"
              handleClick={handleCancel}
            />
            {isEditable && (
              <Button
                variant="contained"
                color="primary"
                disabled={
                  (selectedEntity === ENTITY_TYPE_NAMES.COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY &&
                    checkFacility?.length === 0) ||
                  Representative?.length === 0 ||
                  EntityInformationError ||
                  AlternateContactError ||
                  !isEmpty(cardEdit.filter((card) => card.isEditing)) ||
                  disabledFacilitySubmitBtn
                }
                onClick={handleEditSaveClick}
                autoFocus
                styleSx={{ height: "40px" }}
                sx={{ width: 150 }}
                className="btn-primary"
              >
                {t(t1, "SUBMIT")}
              </Button>
            )}
          </Box>
        </Box>
        <UnsavedPrompt
          id={id}
          when={!isExitInProgress}
          title={t(t1, "WARNING")}
          message={t(t1, "CONFIRM_LEAVE_PAGE")}
          onSuccess={resetData}
        />
      </FormProvider>

      {passphraseDialog ? (
        <PassphraseConfirmationDialog
          title={t(t1, "ENTER_PASSPHRASE")}
          cancelButtonLabel={t(t1, "CANCEL")}
          confirmButtonLabel={t(t1, "CONTINUE")}
          pageName={"Page_EntityRegistration"}
          onPassphraseSuccess={passPhraseMethods.handleSubmit(handleReviewAndSubmit)}
          handleCloseDialog={() => {
            setPassphraseDialog(false);
          }}
          onPassphraseFailure={() => setPassphraseDialog(true)}
          open={passphraseDialog}
          renderFormElements={() => (
            <Box width="100%">
              <Typography
                sx={{ ...RichTextWithScrollStyle, marginTop: 0 }}
                component="p"
                variant="body2"
                color="text.primary"
              >
                {t(t1, "IC_T_AND_C_ON_SUBMIT_APPLICATION_IN_PROGRESS")}
              </Typography>
            </Box>
          )}
        />
      ) : null}
      <Dialog
        open={openEaaSuccessDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(t1, "A_CONFIRMATION_HAS_BEEN_EMAILED_TO_THE_ADDRESS_PROVIDED")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleSuccessOk();
            }}
          >
            {t(t1, "OK")}
          </Button>
        </DialogActions>
      </Dialog>

      {dialogMessage && showModal ? (
        <DialogMessage
          borderColor="#ca2c1c"
          title={t(t1, "EXIT_APPLICATION")}
          message1={t(t1, "EXIT_APPLICATION_WARNING_CONTENT1")}
          message2={t(t1, "EXIT_APPLICATION_WARNING_CONTENT2")}
          buttonMessage={t(t1, "EXIT_APPLICATION_WARNING_CONTENT3")}
          handleModalClick={handleCancelModal}
        />
      ) : null}
      <Loader loader={customLoader} />
    </>
  );
}
