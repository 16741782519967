import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalTitle: null,
  modalBodyMessage: null,
  modalConfirmButton: null,
  modalCloseButton: null,
  modalCancelButton: null,
  showModal: false,
  handleSubmit: false,
  handleCancel: false,
  loader: false,
};

const customModalSlice = createSlice({
  name: 'customModal',
  initialState,
  reducers: {

    setShowModal: (state, action) => {
      state.modalTitle = action.payload.modalTitle;
      state.modalBodyMessage = action.payload.modalBodyMessage;
      state.handleSubmit = action.payload.handleSubmit;
      state.handleCancel = action.payload.handleCancel;
      state.hideComment = action.payload.hideComment;
      state.modalConfirmButton = action.payload.modalConfirmButton;
      state.modalCancelButton = action.payload.modalCancelButton;
      state.modalCloseButton = action.payload.modalCloseButton;
      state.showModal = true;

    },
    setHideModal: (state) => {
      state.showModal = false;
      state.modalTitle = null;
      state.modalBodyMessage = null;
    },
    // setHandleSubmit: (state, action) => {
    //     state.handleSubmit = action.payload;
    // },
    setHandleCancel: (state, action) => {
      state.handleCancel = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
  },
});

export default customModalSlice.reducer;
export const {
  setModalTitle,
  setModalBodyMessage,
  setShowModal,
  setHideModal,
  setHandleSubmit,
  setHandleCancel,
  setLoader,
} = customModalSlice.actions;