import { Container, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { DialogMessage, PageTitle } from 'components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ADD_BATCH_PATH, BATCH_TRANSFER_STATUS, getCurrentPageName, isEmpty, t } from 'utils/constants';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CustomizeButton } from 'components/CustomizeButton';
import { useJurisdictionUserRole } from 'hooks/useUserRole';
import { useNavigate, useParams } from 'react-router-dom';
import { setDialogMessage, setModelMessage } from 'store/commonSlice';
import {
  getBatchHoldings,
  getBatchTransferAccountDetails,
  getBatchTransferHistory,
  getBatchandAccountDetailsById,
  getJurisdictionCurrencyList,
  getPCRADetails,
  getStatusList,
  getTransferType,
  resetBatchInformation,
  resetEditMode,
  saveBatchTransfer,
  setFileUploadErrors,
  transferStatusUpdate,
  updateTransferBatch,
} from 'store/batchTransferSlice';
import { Loader } from 'components/Loader';
import PassphraseConfirmationDialog from 'pages/WiringInstructions/components/PassphraseConfirmationDialog';
import {
  StyledErrorCard,
  StyledErrorCardTitle,
  StyledErrorListItems,
} from 'pages/AddBudgetRecord/AddBudgetRecord.style';
import { QuickLinksMenu } from 'components/QuickLinksMenu';
import { CustomeDialogMessage } from 'components/CustomeDialogMessage';
import { RevisionCard } from 'components/RevisionCard';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { WithPermissions } from 'components/WithPermissions';
import sleep from 'utils/sleep';
import { ErrorCardWithScroll } from 'components/ErrorCardWithScroll';
import { BatchTransferAccountDetailsCard } from './components/BatchTransferAccountDetails';
import { StyledTitleContainer } from './ManageBatchTransfer.style';
import { BatchTransferInformationForm } from './components/BatchTransferInformationForm';
import { SelectedHoldingsGrid } from './components/SelectedHoldingsGrid';
import { AddReceivingAccount } from './components/AddReceivingAccount';
import { BatchTransfers } from './components/BatchTransfers';
import { BatchTransferHistory } from './components/BatchTransferHistory';
import { ApprovalRequest } from './components/ApprovalRequest';
import {
  BATCH_TRANSFER_COMPONENTS_FLP,
  getStatusUpdateErrorMessage,
  isEditable,
  showSelectedHoldingsGrid,
} from './constants';

export default function ManageBatchTransfer() {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const params = useParams();
  const navigate = useNavigate();
  const { isAdmin, isAuthority } = useJurisdictionUserRole();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState();
  const dispatch = useDispatch();
  const [passphraseDialog, setPassphraseDialog] = useState(false);
  const {
    initiateTransferDetails: { transferType, selectedHoldings, juriAccountInformation },
    accountHoldings,
    batchDetails,
    batchReceivingAccounts,
    statuses,
    fileUploadErrors,
    selectedTransferIds,
    batchTransferHistory,
    isEditMode,
    openAddTransferModel,
    accountDetailPCRA,
    isSubmitting,
    isBatchAccDetailsLoading,
    flp,
  } = useSelector(({ batchTransfer }) => batchTransfer);
  const { loader, dialogMessage } = useSelector(({ common }) => common);

  const [juriAccount] = juriAccountInformation;
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'all',
    defaultValues: {},
  });

  const { getValues, reset, formState } = methods;

  const handleCancel = () => {
    reset();
    if (window.history.length === 1) {
      window.close();
    } else {
      navigate(-1);
    }
  };

  const saveBatch = () => {
    const selectedHoldingIds = selectedHoldings.map(({ id }) => id);
    const payload = {
      ...getValues(),
      selectedHoldingIds,
      accountNumber: juriAccount?.accountNumber,
      transferTypeId: transferType?.id,
    };
    return dispatch(saveBatchTransfer(payload))
      .then((response) => {
        reset();
        dispatch(resetBatchInformation());
        dispatch(
          setModelMessage({
            title: t(t1, 'TRANSFER_BATCH_SUCCESS_TITLE'),
            message1: t(t1, 'TRANSFER_BATCH_SUCCESS_MESSAGE'),
          }),
        );
        navigate(`/batch-transfer/${response?.data?.id}`, { replace: true });
      })
      .catch(({ response }) => {
        dispatch(
          setModelMessage({
            error: true,
            title: t(t1, 'ERROR_TITLE'),
            message1: isEmpty(response?.data) ? t(t1, 'BATCH_SAVE_ERROR_MESSAGE') : response?.data?.data,
          }),
        );
      });
  };

  const revisionComment = useMemo(
    () =>
      batchTransferHistory?.find((history) => history?.Status?.name === BATCH_TRANSFER_STATUS.REVISIONS_REQUESTED)
        ?.comment,
    [batchTransferHistory],
  );

  const batchActions = useMemo(
    () => [
      ...(batchDetails?.Status?.name === BATCH_TRANSFER_STATUS.PROPOSED
        ? [
          {
            name: t(t1, 'RETURN_TO_SAVED_STATE')?.toUpperCase(),
            onLinkClick: () => {
              setUpdatedStatus(BATCH_TRANSFER_STATUS.SAVED);
              setOpenConfirmDialog(true);
            },
          },
          {
            name: t(t1, 'CANCEL_PROPOSED_TRANSFER')?.toUpperCase(),
            onLinkClick: () => {
              setUpdatedStatus(BATCH_TRANSFER_STATUS.CANCELLED);
              setOpenConfirmDialog(true);
            },
          },
        ]
        : []),
    ],
    [batchDetails?.Status?.name, t1],
  );

  // Propose batch handler by Jurisdiction Admin
  const handleProposeBatch = useCallback(() => {
    const payload = {
      batchName: batchDetails?.batchName,
      comment: batchDetails?.comment,
      status: statuses[BATCH_TRANSFER_STATUS.PROPOSED]?.ID,
    };
    setPassphraseDialog(false);
    dispatch(updateTransferBatch({ id: params?.id, payload, transferTypeId: transferType?.id }))
      .then(() => {
        dispatch(
          setModelMessage({
            title: t(t1, 'SUCCESS_TITLE'),
            message1: t(t1, 'BATCH_PROPOSAL_SUCCESS'),
          }),
        );
        dispatch(getBatchandAccountDetailsById(params.id));
        dispatch(getBatchHoldings(params.id));
        dispatch(getBatchTransferAccountDetails(params.id));
        dispatch(getBatchTransferHistory(params.id));
      })
      .catch((error) => {
        dispatch(
          setModelMessage({
            error: true,
            title: t(t1, 'ERROR_TITLE'),
            multipleErrorKeys: getStatusUpdateErrorMessage(error?.response?.data?.data, t1, true),
          }),
        );
      });
  }, [batchDetails?.batchName, batchDetails?.comment, statuses, dispatch, params.id, transferType?.id, t1]);

  const setWarningModal = () => {
    dispatch(setDialogMessage(true));
  };

  const handleBatchStatusUpdate = () => {
    const payload = {
      status: statuses[updatedStatus]?.ID,
    };
    setOpenConfirmDialog(false);
    dispatch(transferStatusUpdate({ id: params?.id, transferTypeId: transferType?.id, ...payload }))
      .then(() => {
        dispatch(
          setModelMessage({
            title: t(t1, 'SUCCESS_TITLE'),
            message1: t(t1, `${updatedStatus}_SUCCESS`),
          }),
        );
        reset({});
        if (updatedStatus === BATCH_TRANSFER_STATUS.CANCELLED) {
          if (window.history.length === 1) {
            (async () => {
              await sleep(2000);
              window.close();
            })();
          } else {
            navigate(-1);
          }
        } else {
          dispatch(getBatchandAccountDetailsById(params.id));
          dispatch(getBatchHoldings(params.id));
          dispatch(getBatchTransferAccountDetails(params.id));
          dispatch(getBatchTransferHistory(params.id));
        }
      })
      .catch((error) => {
        dispatch(
          setModelMessage({
            error: true,
            title: t(t1, 'ERROR_TITLE'),
            message1: t(t1, error?.response?.data?.data),
          }),
        );
      })
      .finally(() => {
        setUpdatedStatus();
      });
  };

  useEffect(() => {
    if (params.id !== ADD_BATCH_PATH) {
      dispatch(getBatchandAccountDetailsById(params.id));
      dispatch(getBatchHoldings(params.id));
      dispatch(getBatchTransferAccountDetails(params.id));
      dispatch(getJurisdictionCurrencyList());
      dispatch(getStatusList());
      dispatch(getBatchTransferHistory(params.id));
      dispatch(getPCRADetails());
    } else {
      dispatch(resetBatchInformation());
    }
    dispatch(getTransferType());
    dispatch(resetEditMode());
  }, [dispatch, params.id]);

  useEffect(() => {
    dispatch(setFileUploadErrors([]));
  }, [batchDetails.Status.name, dispatch]);

  useEffectOnce(() => {
    if (params.id === ADD_BATCH_PATH && !transferType?.name) {
      navigate(-1);
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <StyledTitleContainer>
        <PageTitle
          title={t(t1, 'BATCH_TRANSFER')}
          titleNoSpace
        />
        {!!isAdmin && batchDetails?.Status?.name === BATCH_TRANSFER_STATUS.PROPOSED && (
          <QuickLinksMenu
            iconClassName="dot-icon"
            popupClassName="quick-link-style"
            linksList={batchActions}
            label={t(t1, 'MORE ACTIONS')}
          />
        )}
      </StyledTitleContainer>
      {fileUploadErrors.length > 0 && (
        <Grid
          item
          xs={12}
          mt={2}
        >
          <StyledErrorCard sx={{ m: 0 }}>
            <StyledErrorCardTitle variant="h2">{t(t1, 'ERRORS')}</StyledErrorCardTitle>
            <Grid
              container
              sx={{ wordBreak: 'break-word' }}
            >
              <Grid item xs={12}>
                <ErrorCardWithScroll fileUploadErrors={fileUploadErrors} />
              </Grid>
            </Grid>
          </StyledErrorCard>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        mt={2.5}
      >
        {batchDetails?.Status?.name === BATCH_TRANSFER_STATUS.REVISIONS_REQUESTED && !!isAdmin && (
          <RevisionCard>{revisionComment}</RevisionCard>
        )}
      </Grid>

      <WithPermissions componentName={[BATCH_TRANSFER_COMPONENTS_FLP.BATCH_ACCOUNT_DETAILS]}>

        <BatchTransferAccountDetailsCard
          accountDetails={{ juriAccount, selectedHoldings, batchDetails, batchReceivingAccounts, transferType }}
        />

        <BatchTransferInformationForm
          methods={methods}
          transferType={transferType}
          {...batchDetails}
        />

      </WithPermissions>


      {showSelectedHoldingsGrid(batchDetails?.Status?.name) && (
        <SelectedHoldingsGrid
          key={`selected_holdings_${batchDetails?.Status?.name}`}
          selectedHoldingsDetails={{
            selectedHoldings,
            juriAccount,
            accountHoldings,
            batchDetails,
            transferType,
            accountDetailPCRA,
          }}
          batchStatus={batchDetails?.Status}
          flp={flp[BATCH_TRANSFER_COMPONENTS_FLP.SELECTED_HOLDINGS_BATCH]}
        />
      )}

      {batchDetails?.Status?.name !== BATCH_TRANSFER_STATUS.ACTIVE && (
        <BatchTransfers
          batchTransfersDetails={{ batchReceivingAccounts, selectedTransferIds, transferType }}
          batchStatus={batchDetails?.Status?.name}
          flp={flp[BATCH_TRANSFER_COMPONENTS_FLP.BATCH_TRANSFER_ACCOUNT_DETAILS]}
        />
      )}

      {batchDetails?.Status?.name !== BATCH_TRANSFER_STATUS.ACTIVE && (
        <BatchTransferHistory batchTransferHistory={batchTransferHistory}
                              flp={flp[BATCH_TRANSFER_COMPONENTS_FLP.BATCH_TRANSFER_HISTORY]} />
      )}

      {!!isAuthority && batchDetails?.Status?.name === BATCH_TRANSFER_STATUS.PROPOSED && (
        <ApprovalRequest transferType={transferType} />
      )}

      {!!isAdmin &&
        (batchDetails?.Status?.name === BATCH_TRANSFER_STATUS.ACTIVE || isEditable(batchDetails?.Status?.name)) && (
          <Box sx={{ ml: 'auto' }}>
            <Grid item sm={12}>
              <div
                className="bottom-btn"
                style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}
              >
                <CustomizeButton
                  disableElevation
                  variant="outlined"
                  csName="rc"
                  name="add_batch_cancel"
                  privId="priv_btn_cancel"
                  label={t(t1, 'BTN_CANCEL')}
                  handleClick={setWarningModal}
                />
                {batchDetails?.Status?.name === BATCH_TRANSFER_STATUS.ACTIVE && (
                  <CustomizeButton
                    customStyle={{ marginLeft: 1 }}
                    disabled={!selectedHoldings.length || !formState.isValid || !!isEditMode?.editSelectedHoldings || loader}
                    disableElevation
                    variant="contained"
                    type="submit"
                    label={t(t1, 'BTN_SAVE')}
                    name="add_batch_save"
                    privId="priv_btn_Save"
                    handleClick={saveBatch}
                  />
                )}{' '}
                {isEditable(batchDetails?.Status?.name) && (
                  <CustomizeButton
                    customStyle={{ marginLeft: 1 }}
                    disabled={!batchReceivingAccounts.length || Object.values(isEditMode).some((item) => item)}
                    disableElevation
                    variant="contained"
                    label={t(t1, 'BTN_SUBMIT')}
                    name="batch_propose"
                    privId="priv_btn_Propose"
                    handleClick={() => {
                      setPassphraseDialog(true);
                    }}
                  />
                )}
              </div>
            </Grid>
          </Box>
        )}
      {dialogMessage ? (
        <DialogMessage
          warning
          handleModalClick={handleCancel}
          buttonMessage={false}
          title={t(t1, 'CANCEL_CREATE_MODAL_TITLE')}
          message1={t(t1, 'CANCEL_CREATE_MODAL_MESSAGE')}
          message2={t(t1, 'CANCEL_CREATE_MODAL_DESCRIPTION')}
        />
      ) : null}
      {openAddTransferModel && <AddReceivingAccount />}
      <PassphraseConfirmationDialog
        title={t(t1, 'SUBMIT_BATCH_CONFIRMATION')}
        open={passphraseDialog}
        pageName=""
        confirmButtonLabel={t(t1, 'SUBMIT_BUTTON')}
        onPassphraseFailure={() => setPassphraseDialog(true)}
        handleCloseDialog={() => setPassphraseDialog(false)}
        onPassphraseSuccess={handleProposeBatch}
      />
      <CustomeDialogMessage
        warning
        title={
          updatedStatus === BATCH_TRANSFER_STATUS.CANCELLED
            ? t(t1, 'CANCEL_PROPOSED_TRANSFER')
            : t(t1, 'RETURN_TO_SAVED_STATE')
        }
        continueBtn={t(t1, 'CONTINUE')}
        open={openConfirmDialog}
        handleModalCancel={() => {
          setOpenConfirmDialog(false);
          setUpdatedStatus();
        }}
        handleClose={() => {
          setOpenConfirmDialog(false);
          setUpdatedStatus();
        }}
        message1={t(t1, 'CONFIRM_DELETE_MESSAGE')}
        handleContinue={handleBatchStatusUpdate}
        borderColor="#CA2C1C"
      />

      <Loader loader={loader || isSubmitting || isBatchAccDetailsLoading} />
    </Container>
  );
}
