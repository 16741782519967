import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  t,
} from 'utils/constants';
import Style from './ApplicationChecklist.style';
import EntityUserInputForm from './EntityUserInputForm';

const PageName = 'Page_ApplicationWithAttenstation';

function EntityReps({ entityID, titleAttribute, informationLabelAttribute, reps }) {
  const { t: t1 } = useTranslation(PageName);
  return <>
    <Typography sx={Style.subTitle} my={3}>
      {t(t1, titleAttribute)}
    </Typography>
    {reps?.map(rep => {
      const firstName = rep?.name?.substring(0, rep.name?.indexOf(' '));
      const lastName = rep?.name?.substring((rep.name?.indexOf(' ') || 0) + 1);
      return (
        <Box key={rep?.id} my={3}>
          <Box sx={Style.entry}>
            <Typography sx={Style.entryAttribute}>
              {t(t1, 'CITSS_ENTITY_ID')}:
            </Typography>
            <Typography sx={Style.entryValue}>{entityID}</Typography>
          </Box>
          <Box sx={Style.entry}>
            <Typography sx={Style.entryAttribute}>{t(t1, informationLabelAttribute)}:</Typography>
            <Typography sx={Style.entryValue} />
          </Box>
          <Box sx={Style.entry}>
            <Typography sx={Style.entryAttribute}>{t(t1, 'LAST_NAME')}:</Typography>
            <Typography sx={Style.entryValue}>{lastName}</Typography>
          </Box>
          <Box sx={Style.entry}>
            <Typography sx={Style.entryAttribute}>{t(t1, 'FIRST_NAME')}:</Typography>
            <Typography sx={Style.entryValue}>{firstName}</Typography>
          </Box>
          <Box sx={Style.entry}>
            <Typography sx={Style.entryAttribute}>{t(t1, 'USER_REFERENCE_CODE')}:</Typography>
            <Typography sx={Style.entryValue}>{rep?.userReferenceCode}</Typography>
          </Box>
          <Typography sx={Style.contentBoxTitleText}>
            {t(t1, 'SECTION_3_PRIMARY_ACCOUNT_REPRESENTATIVE_PAR_ATTESTATION_DESCRIPTION_1')}
          </Typography>
          <Typography sx={Style.contentBoxTitleText}>
            {t(t1, 'SECTION_3_PRIMARY_ACCOUNT_REPRESENTATIVE_PAR_ATTESTATION_DESCRIPTION_2')}
          </Typography>
          <EntityUserInputForm />
        </Box>
      );
    })}
  </>;
}

export default EntityReps;
