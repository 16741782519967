import { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import { Box, Checkbox, ListItemText, Autocomplete, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { CheckCircle } from 'assets/icons/CheckCircle';
import { useTranslation } from 'react-i18next';
import CustomPopper from 'components/_CustomPopper/customPopper';
import { t, getCurrentPageName, isEmpty } from 'utils/constants';
import Style from './GridSelectRadioButton.style';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function GridSelectRadioButton(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { control, getValues, setValue } = useFormContext();

  const theme = useTheme();
  const themeColor = theme?.palette?.primary?.main;

  const {
    formSteps: { isEditField, activeStep },
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user; // Here we get fields permission like hidden, read Only or write

  const {
    methods,
    disabled,
    label,
    name = '',
    type = 'text',
    className = '',
    handleOnChange = false,
    rules,
    noValueText,
    options,
    stepNumber,
    gridMd = 4,
    selectValue = '',
    onClose,
    sx,
    customClass,
    errorLabelDisplay = props.label,
    privId = '',
    dropdownStyle = {},
    isRequiredMsg = '',
  } = props;

  const [myDefultValue, setDefaultValue] = useState({ name: noValueText });

  let readOnly = false;
  if (privId === '') {
    if (PrivAttributes?.[name]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[name]?.PrivRole === 'RO';
  } else {
    if (PrivAttributes?.[privId]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[privId]?.PrivRole === 'RO';
  }

  if (rules && rules.required === true && label) {
    rules.required = `${t(t1, isRequiredMsg)}`;
  }

  return (
    <Grid item md={gridMd} sx={sx}>
      {label && (
        <InputLabel htmlFor="customize-select" sx={Style.FieldLabel}>
          <Typography className={disabled ? `disable-${customClass}` : customClass}>{label}</Typography>
          {rules?.required ? (
            <Typography
              variant="body2"
              sx={{
                display: 'inline-block',
                color: 'common.red',
                ml: 0.3,
                fontWeight: 700,
              }}
            >
              *
            </Typography>
          ) : null}
        </InputLabel>
      )}

      {(!isEditField[stepNumber] && activeStep === 4) ? (
        <Typography variant="body2" sx={Style.valuetext}>
          {getValues(name) || 'N/A'}
        </Typography>
      ) : (
        <Controller
          name={name}
          type={type}
          rules={rules}
          control={control}
          render={({ field: { onChange, onBlur, value, ...field }, fieldState }) => (
            <>
              <Autocomplete
                PopperComponent={CustomPopper}
                clearText=""
                closeText=""
                openText=""
                disableClearable
                disabled={readOnly || disabled}
                getOptionLabel={(option) => {
                  if (name === 'RoleType') {
                    if (option.name) {
                      return t(t1, option?.cmsKey);
                    }
                    return t(t1, myDefultValue?.name);
                  }
                  if (name === 'ReasonForDenial' || name === 'ProfileUpdateDenialReason' || name === 'CJDenialReason') {
                    if (option.name) {
                      return t(t1, option?.name);
                    }
                    return t(t1, myDefultValue?.name);
                  }
                  return option.name ?? myDefultValue?.name;
                }}
                defaultValue={[myDefultValue]}
                getOptionDisabled={(option) => option?.value === ''}
                value={value}
                id={name}
                sx={Style.formField}
                options={options}
                onBlur={onBlur}
                className={`${className}-select`}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    className={'SearchableDropdown'}
                    error={Boolean(fieldState.error)}
                    sx={{
                      ...dropdownStyle,
                    }}
                    {...params}
                    // label="Choose a country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(event, values) => {
                  if (values) {
                    setDefaultValue(values);
                    setValue('Roles', value.roles);
                    if (handleOnChange) {
                      handleOnChange(values);
                    }
                    return onChange(values);
                  }
                }}
              />
              {rules && (
                <FormHelperText error>
                  {fieldState?.error?.message || ''}
                </FormHelperText>
              )}
            </>
          )}
        />
      )}
    </Grid>
  );
}