import authRoles from 'core/auth/authRole';
import UserWithinRole from './UserWithinRole';

const UserWithinRoleConfig = {
  route: {
    path: '/user-role',
    element: <UserWithinRole />,
  },
  layout: {
    header: {
      display: true,
    },
    sidebar: {
      display: false,
    },
    footer: {
      display: true,
    },
  },
  auth: authRoles.onlyGuest,
};

export default UserWithinRoleConfig;
