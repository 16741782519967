import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useTheme, styled } from '@mui/material/styles';
import useAuth from 'hooks/useAuth';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { setRouteState } from 'store/userSlice';
import { NAvalue } from 'utils/constants';
import AccessibleLink from 'components/AccessibleLink';
import useReports from './useReports';


const NavbarLinkExtended = styled(({ className, ...props }) => <Link {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    textDecoration: 'none',
    // userSelect: "none", 
    color: '#fff',
    // [`&:focus`]: {
    //   backgroundColor: "#fff",
    //   border: "1px solid #000",
    // },
  }),
);

function ReportsComponent() {
  const theme = useTheme();
  const { routesReports } = useReports();
  const { routeState } = useSelector(({ user }) => user);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElP, setAnchorElP] = useState(null);
  const [activeSubRoute, setActiveSubRoute] = useState(null);
  const navigate = useNavigate();
  const { isSignedIn } = useAuth();
  const { menu } = useSelector(({ auth }) => auth);

  const { PrivAttributes } = menu;

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, path) => {
    setActiveSubRoute(path);
    if (event?.target?.id === path) {
      setAnchorEl(event.currentTarget);
    }
  };

  const popupStateClose = (popupState) => {
    popupState.close();
  };

  const onRouteClick = (route, popupState) => {
    popupStateClose(popupState);
    dispatch(setRouteState({ path: route?.id, toggle: !routeState.toggle }));
  };

  const handleClickPOBtn = (event) => {
    setAnchorElP(event.currentTarget);
  };

  const showSubmenu = (route, popupState) => {
    if (route?.subRoutes && route?.subRoutes?.length) {
      return (
        <div>
          <Box
            onClick={(e) => handleClick(e, route.path)}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Button
              disableRipple
              id={route.path}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={{
                color: '#fff',
                fontSize: '14px',
                textTransform: 'none',
                padding: '0 8px',
                '&:focus-visible': {
                  backgroundColor: 'transparent',
                  textTransform: 'none',
                  textDecoration: 'underline',
                },
                '&:hover': {
                  backgroundColor: 'transparent',
                  textTransform: 'none',
                },
              }}
            >
              {route.name}
              {open && route.path === activeSubRoute ? (
                <KeyboardArrowDownIcon id={route.path} />
              ) : (
                <KeyboardArrowRightIcon id={route.path} />
              )}
              {/* TODO */}
            </Button>
          </Box>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open && route.path === activeSubRoute}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': route.name,
            }}
            sx={{
              '& .MuiMenu-list': {
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                listStyle: 'none',
              },
            }}
          >
            {route.path === activeSubRoute &&
              route?.subRoutes?.map((item) => [
                <MenuItem
                  sx={{
                    fontSize: '14px',
                    listStyleType: 'none',
                    color: 'common.white',
                    '&:focus-visible': {
                      backgroundColor: 'transparent',
                      textTransform: 'none',
                      textDecoration: 'underline',
                    },
                    '&:hover': {
                      backgroundColor: 'transparent',
                      textTransform: 'none',
                      fontWeight: 700, cursor: 'pointer',
                    },
                  }}
                  key={item.path}
                  onClick={() => {
                    handleClose();
                    onRouteClick(item, popupState);
                    navigate(item?.path);
                  }}
                >
                  <AccessibleLink
                    to={item.path}
                    key={item.path}
                    style={{ color: 'white', textDecoration: 'none' }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleClose();
                      onRouteClick(item, popupState);
                    }}
                  >
                    {item?.name}
                  </AccessibleLink>
                </MenuItem>,
              ])}
          </Menu>
        </div>
      );
    }

    return (
      //  main route
      <MenuItem
        sx={{
          fontSize: route.customStyle ? '16px' : '14px',
          pl: route.customStyle ? 0 : 1,
          py: 0,
          '&:hover': { cursor: 'pointer', textDecoration: 'underline', fontWeight: 500 },
        }}
        key={route.path}
        onClick={() => {
          handleClose();
          onRouteClick(route, popupState);
        }}
      >
        <AccessibleLink
          to={route.path}
          key={route.path}
          style={{
            textDecoration: 'none',
            color: 'white',
            '&:focus': { cursor: 'pointer', textDecoration: 'underline', fontWeight: 500 },
          }}
        >
          {route?.name}
        </AccessibleLink>
      </MenuItem>

    );
  };

  return (
    <PopupState
      variant="popover"
      popupId="demo-popup-popover"
    >
      {(popupState) => (
        <div>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginRight: { lg: '80px', md: '64px', sm: '40px' },
            }}
            {...bindTrigger(popupState)}
          >
            <Button
              disableRipple
              onClick={handleClickPOBtn}
              sx={{
                color: '#fff',
                textTransform: 'none',
                '&:hover': {
                  fontWeight: 700, cursor: 'pointer',

                },
                // border: popupState?.isOpen ? "1px solid #fff" : "none",
                fontWeight: popupState?.isOpen ? 700 : 500,
                fontSize: ['7px', '14px'],
                minWidth: ['auto', '64px'],
              }}
            >
              REPORTS
              <ArrowDropDownIcon sx={{ color: '#fff', width: ['12px', '24px'], height: ['12px', '24px'] }} />
            </Button>
          </Box>

          <Popover
            {...bindPopover(popupState)}
            anchorEl={anchorElP}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{ mt: 1, borderRadius: '10px', border: '1px solid #fff' }}
            className="header-menu-popup-state"
          >
            <Box
              sx={{
                minHeight: '100px',
                background: '#283459',
                color: '#fff',
                p: 3,
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '100%',
                  gap: '1rem',
                  width: '100%',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  '@media (min-width: 600px)': {
                    gridTemplateColumns: 'repeat(2, 50%)',
                  },
                  '@media (min-width: 900px)': {
                    gridTemplateColumns: 'repeat(3, 33.33%)',
                  },
                  '@media (min-width: 1200px)': {
                    gridTemplateColumns: 'repeat(4, 24.5%)',
                  },
                }}
              >
                {routesReports
                  .filter((item) => {
                    let data;
                    if (item.display === false) {
                      return null;
                    }
                    if (
                      item.signIn &&
                      isSignedIn &&
                      !item.isComponent &&
                      PrivAttributes?.[item?.privId]?.PrivRole !== NAvalue
                    ) {
                      data = item;
                    } else if (
                      item.signIn === false &&
                      !isSignedIn &&
                      PrivAttributes?.[item?.privId]?.PrivRole !== NAvalue
                    ) {
                      data = item;
                    } else if (
                      item.display &&
                      !item.isComponent &&
                      PrivAttributes?.[item?.privId]?.PrivRole !== NAvalue
                    ) {
                      data = item;
                    } else if (item.signIn && isSignedIn && item.display && item.isComponent) {
                      data = item;
                    }
                    return data;
                  })
                  .map((header, index) => (
                    <Box
                      key={header.id}
                      sx={{
                        flex: '33.33%',
                        [theme.breakpoints.up('sm')]: {
                          flex: '22.33%',
                        },
                      }}
                    >
                      <Typography>{header.name}</Typography>

                      {header.routes
                        ?.filter((item) => {
                          let data;
                          if (item.display === false) {
                            return null;
                          }
                          if (
                            item.signIn &&
                            isSignedIn &&
                            !item.isComponent &&
                            PrivAttributes?.[item?.privId]?.PrivRole !== NAvalue
                          ) {
                            data = item;
                          } else if (
                            item.signIn === false &&
                            !isSignedIn &&
                            PrivAttributes?.[item?.privId]?.PrivRole !== NAvalue
                          ) {
                            data = item;
                          } else if (
                            item.display &&
                            !item.isComponent &&
                            PrivAttributes?.[item?.privId]?.PrivRole !== NAvalue
                          ) {
                            data = item;
                          } else if (item.signIn && isSignedIn && item.display && item.isComponent) {
                            data = item;
                          }
                          return data;
                        })
                        .map((route) => (
                          <Box
                            key={route.path}
                            sx={{ display: 'flex', alignItems: 'center', width: '100%', mr: '250px' }}
                          >
                            {showSubmenu(route, popupState)}

                          </Box>
                        ))}
                    </Box>
                  ))}
              </Box>
            </Box>{' '}
          </Popover>
        </div>
      )}
    </PopupState>
  );
}

export default ReportsComponent;
