import { Box, Button, Grid, InputLabel, TextField } from '@mui/material';
import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { t, getCurrentPageName } from 'utils/constants';
import styled from '@emotion/styled';
import Style from './GridFileUpload.style';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


function GridFileUpload(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const uploadInputRef = useRef(null);
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user; // Here we get fields permission like hidden, read Only or write

  const {
    name = '',
    type = 'file',
    label = t(t1, 'BROWSE_FILES'),
    defaultValue = {},
    privId = '',
    disabled = false,
    labelStyle = {},
    variant = 'outlined',
    acceptFormat,
    rules,
    tabIndex = 0,
  } = props;

  let customStyle;
  if (variant === 'outlined') {
    customStyle = Style.outlined;
  } else if (variant === 'contained') {
    customStyle = Style.contained;
  } else {
    customStyle = Style.defaultColor;
  }
  let readOnly = false;
  if (privId === '') {
    if (PrivAttributes?.[name]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[name]?.PrivRole === 'RO';
  } else {
    if (PrivAttributes?.[privId]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[privId]?.PrivRole === 'RO';
  }
  // return (
  //   <Box sx={Style.fileBlock}>
  //     <InputLabel sx={{ ...labelStyle, ...customStyle, ...Style.Label }} tabIndex={tabIndex} onKeyUp={(e) =>  {
  //       if (e.code === "Enter") {
  //         const input = e.target.querySelector('input')
  //         input?.click()
  //       }
  //     }}>
  //       {label}
  //       <Controller
  //         name={name}
  //         rules={rules}
  //         defaultValue={defaultValue}
  //         render={({ field }) => (
  //           <TextField
  //             type={type}
  //             disabled={disabled}
  //             sx={Style.fileField}
  //             inputProps={{ accept: acceptFormat }}
  //             // retrigger onchange when same file is selected
  //             onClick = {event => {
  //               const { target = {} } = event || {};
  //               target.value = "";
  //             }}
  //             onChange={(e) => {
  //               const file = e.target.files[0];
  //               if (file)  field.onChange(e.target.files[0]);
  //                 if (e.target) {
  //                   e.target.value = null
  //               }
  //             }}
  //           />
  //         )}
  //       />
  //     </InputLabel>
  //   </Box>
  // );
  return (
    <Controller
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => (
        <span>

{/* <InputLabel sx={{ ...labelStyle, ...customStyle, ...Style.Label }} tabIndex={tabIndex} onKeyUp={(e) =>  {
        if (e.code === "Enter") {
          const input = e.target.querySelector('input')
          input?.click()
        }
      }}>
        {label}
        <Controller
          name={name}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <TextField
              type={type}
              disabled={disabled}
              sx={Style.fileField}
              inputProps={{ accept: acceptFormat }}
              // retrigger onchange when same file is selected
              onClick = {event => {
                const { target = {} } = event || {};
                target.value = "";
              }}
              onChange={(e) => {
                const file = e.target.files[0];
                if (file)  field.onChange(e.target.files[0]);
                  if (e.target) {
                    e.target.value = null
                }
              }}
            />
          )}
        />
      </InputLabel> */}
          <Button
            disabled={disabled}
            sx={{ ...labelStyle, ...customStyle, ...Style.Label }}
            tabIndex={-1}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault();
                uploadInputRef.current.click();
              }
            }}
            component="label"
            role={undefined}
            variant={variant}
            startIcon={label}
          >
          <VisuallyHiddenInput
            ref={uploadInputRef}
            type={type}
            disabled={disabled}
            sx={Style.fileField}
            accept={acceptFormat}
            inputProps={{ accept: acceptFormat }}
            // retrigger onchange when same file is selected
            onClick={event => {
              const { target = {} } = event || {};
              target.value = '';
            }}
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) field.onChange(e.target.files[0]);
              if (e.target) {
                e.target.value = null;
              }
            }}
          />
          </Button>
              </span>
      )}
    />
  );
}

export default GridFileUpload;


