import { createSlice } from '@reduxjs/toolkit';
import { APIS } from 'utils/constants';
import { fetcherGet, fetcherPost, fetcherPatch } from 'utils/fetcher';
import logger from 'utils/logger';
import { PURCHASE_LIMIT_COMPONENTS_FLP, getFLPHeaders } from 'pages/ManagePurchaseLimit/constants';
import { transformPurchaseLimits } from 'pages/ManagePurchaseLimit/components/utils';
import { setLoader } from './commonSlice';

const initialState = {
  purchaseLimits: [],
  coveredEntity: [],
  gmpIndividual: [],
  gmpOrganization: [],
  entityRecords: [],
  purchaseLimitOptions: {
    currentPurchaseLimit: [],
    futurePurchaseLimit: [],
  },
  isPurchaseLimitNull: false,
  isLoadingPurchaseLimits: false,
  isLoadingCoveredEntity: false,
  isLoadingGMPIndividual: false,
  isLoadingGMPOrganization: false,
  isLoadingEntityRecords: false,
  isLoadingPurchaseLimitOptions: false,
  fileUploadErrors: [],
  flp: {
    [PURCHASE_LIMIT_COMPONENTS_FLP.PURCHASE_LIMIT_TABLE_WCI]: {
      metaData: [],
      fieldAccess: [],
    },
    [PURCHASE_LIMIT_COMPONENTS_FLP.PURCHASE_LIMIT_TABLE_HISTORY_WCI]: {
      metaData: [],
      fieldAccess: [],
    },
    [PURCHASE_LIMIT_COMPONENTS_FLP.VIEW_ENTITY_PURCHASE_LIMIT]: {
      metaData: [],
      fieldAccess: [],
    },
    [PURCHASE_LIMIT_COMPONENTS_FLP.PURCHASE_LIMIT_DROPDOWN]: {
      metaData: [],
      fieldAccess: [],
    },
  },
};

const ManagePurchaseLimitSlice = createSlice({
  name: 'ManagePurchaseLimit',
  initialState,
  reducers: {
    setPurchaseLimits: (state, action) => {
      state.purchaseLimits = action.payload;
    },
    setCoveredEntity: (state, action) => {
      state.coveredEntity = action.payload;
    },
    setGMPIndividual: (state, action) => {
      state.gmpIndividual = action.payload;
    },
    setGMPOrganization: (state, action) => {
      state.gmpOrganization = action.payload;
    },
    setEntityRecords: (state, action) => {
      state.entityRecords = action.payload;
    },
    setPurchaseLimitOptions: (state, action) => {
      state.purchaseLimitOptions = action.payload;
    },
    setIsLoadingPurchaseLimits: (state, action) => {
      state.isLoadingPurchaseLimits = action.payload;
    },
    setIsLoadingCoveredEntity: (state, action) => {
      state.isLoadingCoveredEntity = action.payload;
    },
    setIsLoadingGMPIndividual: (state, action) => {
      state.isLoadingGMPIndividual = action.payload;
    },
    setIsLoadingGMPOrganization: (state, action) => {
      state.isLoadingGMPOrganization = action.payload;
    },
    setIsLoadingEntityRecords: (state, action) => {
      state.isLoadingEntityRecords = action.payload;
    },
    setIsLoadingPurchaseLimitOptions: (state, action) => {
      state.isLoadingPurchaseLimitOptions = action.payload;
    },
    setIsPurchaseLimitNull: (state, action) => {
      state.isPurchaseLimitNull = action.payload;
    },
    setFileUploadErrors: (state, action) => {
      state.fileUploadErrors = action.payload;
    },
    setFLP: (state, action) => {
      const { key, value } = action.payload;
      state.flp[key] = value;
    },
  },
});

export const {
  setPurchaseLimits,
  setCoveredEntity,
  setGMPIndividual,
  setGMPOrganization,
  setEntityRecords,
  setPurchaseLimitOptions,
  setIsLoadingPurchaseLimits,
  setIsLoadingCoveredEntity,
  setIsLoadingGMPIndividual,
  setIsLoadingGMPOrganization,
  setIsLoadingEntityRecords,
  setIsLoadingPurchaseLimitOptions,
  setIsPurchaseLimitNull,
  setFileUploadErrors,
  setFLP,
} = ManagePurchaseLimitSlice.actions;
export default ManagePurchaseLimitSlice.reducer;

export const getPurchaseLimits = (jurisdictionId) => (dispatch) => {
  dispatch(setIsLoadingPurchaseLimits(true));
  fetcherGet(`${APIS.MANAGE_PURCHASE_LIMITS}/jurisdiction/${jurisdictionId}/purchaseLimit`, {},
    {
      pageName: 'Page_ManagePurchaseLimit',
      componentName: PURCHASE_LIMIT_COMPONENTS_FLP.PURCHASE_LIMIT_TABLE_WCI,
    })
    .then((response) => {
      const flag = response?.data?.some(
        (item) => !item.purchase_limits?.currentPurchaseLimit || !item.purchase_limits?.futurePurchaseLimit,
      );
      dispatch(setIsPurchaseLimitNull(flag));
      response?.data?.forEach((item) => {
        item.purchase_limits.currentPurchaseLimit = item.purchase_limits.currentPurchaseLimit
          ? item.purchase_limits.currentPurchaseLimit
          : null;
        item.purchase_limits.futurePurchaseLimit = item.purchase_limits.futurePurchaseLimit
          ? item.purchase_limits.futurePurchaseLimit
          : null;
      });
      const { metaData, fieldAccess } = response;
      dispatch(setPurchaseLimits(transformPurchaseLimits(response?.data)));
      dispatch(
        setFLP({ key: PURCHASE_LIMIT_COMPONENTS_FLP.PURCHASE_LIMIT_TABLE_WCI, value: { metaData, fieldAccess } }),
      );
      dispatch(setIsLoadingPurchaseLimits(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsLoadingPurchaseLimits(false));
    });
};

export const getEntityHistoryRecords = (jurisdictionId) => (dispatch) => {
  dispatch(setIsLoadingCoveredEntity(true));
  dispatch(setIsLoadingGMPIndividual(true));
  dispatch(setIsLoadingGMPOrganization(true));
  fetcherGet(`${APIS.MANAGE_PURCHASE_LIMITS}/jurisdiction/${jurisdictionId}/history`, {},
    {
      pageName: 'Page_ManagePurchaseLimit',
      componentName: PURCHASE_LIMIT_COMPONENTS_FLP.PURCHASE_LIMIT_TABLE_HISTORY_WCI,
    })
    .then((response) => {
      const resp = response?.data;
      if (!resp.length) {
        dispatch(setCoveredEntity(resp));
        dispatch(setGMPIndividual(resp));
        dispatch(setGMPOrganization(resp));
      } else {
        const individualArray = resp.find((item) => item.name === 'GENERAL_MARKET_PARTICIPANT_INDIVIDUAL') || null;
        const organizationArray = resp.find((item) => item.name === 'GENERAL_MARKET_PARTICIPANT_ORGANIZATION') || null;
        const coveredEntityArray =
          resp.find((item) => item.name === 'COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY') || null;

        if (coveredEntityArray) dispatch(setCoveredEntity(transformPurchaseLimits(coveredEntityArray?.history)));
        if (individualArray) dispatch(setGMPIndividual(transformPurchaseLimits(individualArray?.history)));
        if (organizationArray) dispatch(setGMPOrganization(transformPurchaseLimits(organizationArray?.history)));
      }
      const { metaData, fieldAccess } = response;
      dispatch(
        setFLP({
          key: PURCHASE_LIMIT_COMPONENTS_FLP.PURCHASE_LIMIT_TABLE_HISTORY_WCI,
          value: { metaData, fieldAccess },
        }),
      );
      dispatch(setIsLoadingCoveredEntity(false));
      dispatch(setIsLoadingGMPIndividual(false));
      dispatch(setIsLoadingGMPOrganization(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsLoadingCoveredEntity(false));
      dispatch(setIsLoadingGMPIndividual(false));
      dispatch(setIsLoadingGMPOrganization(false));
    });
};

export function addPurchaseLimits(jurisdictionId, isEmptyPurchaseLimit, payload) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const method = isEmptyPurchaseLimit ? fetcherPost : fetcherPatch;
    try {
      await method(`${APIS.MANAGE_PURCHASE_LIMITS}/jurisdiction/${jurisdictionId}/purchaseLimit`, [...payload],
        isEmptyPurchaseLimit ? getFLPHeaders(PURCHASE_LIMIT_COMPONENTS_FLP.CREATE_PURCHASE_LIMIT_WCI) : getFLPHeaders(PURCHASE_LIMIT_COMPONENTS_FLP.UPDATE_DEFAULT_PURCHASE_LIMIT_WCI));
    } finally {
      dispatch(getPurchaseLimits(jurisdictionId));
      dispatch(getEntityHistoryRecords(jurisdictionId));
      dispatch(setLoader(false));
    }
  };
}

export const getEntityRecords = (jurisdictionId, value) => (dispatch) => {
  dispatch(setIsLoadingEntityRecords(true));
  dispatch(setFLP({
    key: PURCHASE_LIMIT_COMPONENTS_FLP.VIEW_ENTITY_PURCHASE_LIMIT,
    value: { metaData: [], fieldAccess: [] },
  }));
  fetcherGet(`${APIS.MANAGE_PURCHASE_LIMITS}/jurisdiction/${jurisdictionId}/entityPurchaseLimit?type=${value}`, {}, getFLPHeaders(PURCHASE_LIMIT_COMPONENTS_FLP.VIEW_ENTITY_PURCHASE_LIMIT))
    .then(({ data, metaData, fieldAccess }) => {
      dispatch(setEntityRecords(transformPurchaseLimits(data)));
      dispatch(setFLP({
        key: PURCHASE_LIMIT_COMPONENTS_FLP.VIEW_ENTITY_PURCHASE_LIMIT,
        value: { metaData, fieldAccess },
      }));
      dispatch(setIsLoadingEntityRecords(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsLoadingEntityRecords(false));
    });
};
export const getPurchaseLimitOptions = (jurisdictionId) => (dispatch) => {
  dispatch(setIsLoadingPurchaseLimitOptions(true));
  dispatch(setFLP({
    key: PURCHASE_LIMIT_COMPONENTS_FLP.PURCHASE_LIMIT_DROPDOWN,
    value: { metaData: [], fieldAccess: [] },
  }));
  fetcherGet(`${APIS.MANAGE_PURCHASE_LIMITS}/jurisdiction/${jurisdictionId}/purchaseLimitsByPercentage`, {}, getFLPHeaders(PURCHASE_LIMIT_COMPONENTS_FLP.PURCHASE_LIMIT_DROPDOWN))
    .then(({ data, metaData, fieldAccess }) => {
      dispatch(setPurchaseLimitOptions(transformPurchaseLimits(data)));
      dispatch(setFLP({ key: PURCHASE_LIMIT_COMPONENTS_FLP.PURCHASE_LIMIT_DROPDOWN, value: { metaData, fieldAccess } }));
      dispatch(setIsLoadingPurchaseLimitOptions(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsLoadingPurchaseLimitOptions(false));
    });
};

export function postPurchaseLimits(jurisdictionId, payload) {
  return (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPatch(
      `${APIS.MANAGE_PURCHASE_LIMITS}/jurisdiction/${jurisdictionId}/entityPurchaseLimitByPercentage`,
      [...payload],
      getFLPHeaders(PURCHASE_LIMIT_COMPONENTS_FLP.EDIT_PURCHASE_LIMIT_PERCENTAGE),
    );
  };
}

export function addPurchaseLimitByValueBulk({ jsonData }, fileName) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem('jurisdictionID');
    dispatch(setLoader(true));
    return fetcherPost(
      `${APIS.MANAGE_PURCHASE_LIMITS}/jurisdiction/${jurisdictionId}/entityPurchaseLimitByValue`,
      [...jsonData],
      { 'file-name': fileName, componentName: PURCHASE_LIMIT_COMPONENTS_FLP.BULK_UPDTAE },
    ).finally(() => {
      dispatch(getEntityRecords(jurisdictionId, 'Value'));
      dispatch(setLoader(false));
    });
  };
}

export function validatePurchaseLimitByValueBulk({ jsonData }, fileName) {
  return (dispatch) => {
    const jurisdictionId = localStorage.getItem('jurisdictionID');
    dispatch(setLoader(true));
    return fetcherPost(
      `${APIS.MANAGE_PURCHASE_LIMITS}/jurisdiction/${jurisdictionId}/validateEntityPurchaseLimitByValue`,
      [...jsonData],
      { 'file-name': fileName, componentName: PURCHASE_LIMIT_COMPONENTS_FLP.BULK_VALIDATE },
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}