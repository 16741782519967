import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { APIS } from 'utils/constants';
import { fetcherDelete, fetcherGet, fetcherPatch, fetcherPost } from 'utils/fetcher';
import {
  COMPONENTS,
  STATUSES,
  getFLPHeaders,
  mergeSequentialBlocks,
  transformClusterApprovals,
  transformClusterTransfer,
  transformClusterTransferHoldings,
} from 'pages/ManageAdministrativeTransfers/constants';
import logger from 'utils/logger';
import { setLoader } from './commonSlice';
import { getEntityAccountHoldings } from './entityTransferSlice';
import { getReplenishmentAccountDetails, setIsTransferFromJurisdiction } from './replenishmentTransferSlice';

const initialState = {
  initiateTransferDetails: {},
  selectedHoldings: [],
  transferTypes: [],
  transferDetails: {
    data: {},
  },
  transfersList: {
    data: [],
  },
  selectedTransferHoldings: {
    data: [],
  },
  transferHistory: {
    data: [],
  },
  editHoldings: false,
  currencyDetails: [],
  editAccount: false,
  addMoreModal: false,
  statuses: {},
  proposedAdminTransfers: [],
};

const AdministrativeTransferSlice = createSlice({
  name: 'administrativeTransfer',
  initialState,
  reducers: {
    initiateTransfer: (state, action) => {
      state.initiateTransferDetails = { ...state.initiateTransferDetails, ...action.payload };
    },
    setSelectedHoldings: (state, action) => {
      state.selectedHoldings = action.payload;
    },
    setTransferTypes: (state, action) => {
      state.transferTypes = action.payload;
    },
    setTransferDetails: (state, action) => {
      state.transferDetails = action.payload;
    },
    setTransfersList: (state, action) => {
      state.transfersList = action.payload;
    },
    setSelectedTransfersList: (state, action) => {
      state.selectedTransferHoldings = action.payload;
    },
    setEditHoldings: (state, action) => {
      state.editHoldings = action.payload;
    },
    setCurrencyDetails: (state, action) => {
      state.currencyDetails = action.payload;
    },
    setEditAccount: (state, action) => {
      state.editAccount = action.payload;
    },
    setAddMoreModal: (state, action) => {
      state.addMoreModal = action.payload;
    },
    setStatuses: (state, action) => {
      state.statuses = action.payload;
    },
    setTransferHistory: (state, action) => {
      state.transferHistory = action.payload;
    },
    setProposedAdminTransfers: (state, action) => {
      state.proposedAdminTransfers = action.payload;
    },
  },
});

export const {
  initiateTransfer,
  setSelectedHoldings,
  setTransferTypes,
  setTransferDetails,
  setTransfersList,
  setSelectedTransfersList,
  setEditHoldings,
  setCurrencyDetails,
  setEditAccount,
  setAddMoreModal,
  setStatuses,
  setTransferHistory,
  setProposedAdminTransfers,
} = AdministrativeTransferSlice.actions;
export default AdministrativeTransferSlice.reducer;

export const getEntityInfo = (id) => (dispatch) => {
  dispatch(setLoader(true));
  fetcherGet(`${APIS.ENTITY}/${id}`, {}, { componentName: 'entityDetails', pageName: 'Page_EntityProfile' })
    .then((response) => {
      dispatch(setLoader(false));
      dispatch(initiateTransfer(response?.data));
    })
    .catch((error) => {
      dispatch(setLoader(false));
      logger({ error });
    });
};

export const getTransferTypes = () => (dispatch) => {
  dispatch(setLoader(true));
  return fetcherGet(`${APIS.BATCH_TRANSFERS}/getTransferTypeDetails`)
    .then((response) => {
      dispatch(setTransferTypes(response.data));
    })
    .finally(() => {
      dispatch(setLoader(false));
    });
};

export const saveTransferDetails = (payload, cb) => (dispatch) => {
  const jurisdictionId = localStorage.getItem('jurisdictionID');
  dispatch(setLoader(true));
  return fetcherPost(
    `${APIS.CLUSTER_TRANSFERS}/jurisdiction/${jurisdictionId}/transferDetail`,
    payload,
    getFLPHeaders(COMPONENTS.INITIATE_TRANSFER),
  )
    .then((response) => {
      cb(response?.data?.id);
    })
    .finally(() => {
      dispatch(setLoader(false));
    });
};

export const getTransferDetails = (clusterId, isAdmin, preventEditToggle = false) => (dispatch) => {
  const jurisdictionId = localStorage.getItem('jurisdictionID');
  return fetcherGet(
    `${APIS.CLUSTER_TRANSFERS}/jurisdiction/${jurisdictionId}/clusterDetails`,
    { clusterId },
    getFLPHeaders(COMPONENTS.TRANSFERS_LIST),
  ).then(async (response) => {
    const transferDetails = transformClusterTransfer(response);
    const entityAccountId = transferDetails?.data?.[0]?.transferringEntityAccountID;
    let availableAccountBalance;
    let accountBalance;
    if (entityAccountId) {
      dispatch(getEntityAccountHoldings(entityAccountId));
      availableAccountBalance = transferDetails?.data?.[0]?.transferringEntity?.entityAccounts?.availableAccountBalance;
      accountBalance = transferDetails?.data?.[0]?.transferringEntity?.entityAccounts?.accountBalance;
    } else {
      const jurisdictionAccountNumber = transferDetails?.data?.[0]?.transferringAccountNumber;
      dispatch(setIsTransferFromJurisdiction(true));
      dispatch(getReplenishmentAccountDetails(true));
      const { data: { availableAccountBalance: availableBalance, accountBalance: balance } = {} } =
        await fetcherGet(`${APIS.ACCOUNT_INFORMATION_API}/${jurisdictionId}/${jurisdictionAccountNumber}`, {}, {
          pageName: 'Page_AccountDetails',
          componentName: 'accountInformation',
        });
      availableAccountBalance = availableBalance;
      accountBalance = balance;
    }
    dispatch(
      setTransferDetails({
        ...transferDetails,
        data: { ...transferDetails?.data?.[0], availableAccountBalance, accountBalance },
      }),
    );
    if (preventEditToggle) return;
    if (transferDetails?.data?.[0]?.status === STATUSES.SAVED && isAdmin) {
      dispatch(setEditHoldings(false));
    }
  });
};

export const getAllTransfers = () => (dispatch) => {
  const jurisdictionId = localStorage.getItem('jurisdictionID');
  dispatch(setLoader(true));
  return fetcherGet(
    `${APIS.CLUSTER_TRANSFERS}/jurisdiction/${jurisdictionId}/clusterDetails`,
    {},
    getFLPHeaders(COMPONENTS.TRANSFERS_LIST),
  )
    .then((response) => {
      dispatch(setTransfersList(transformClusterTransfer(response)));
    })
    .finally(() => {
      dispatch(setLoader(false));
    });
};

export const getProposedAdminTransfers = (jurisdictionId) => (dispatch) => {
  dispatch(setLoader(true));
  return fetcherGet(
    `${APIS.CLUSTER_TRANSFERS}/jurisdiction/${jurisdictionId}/clusterDetails`,
    {
      status: STATUSES.PROPOSED,
    },
    getFLPHeaders(COMPONENTS.TRANSFERS_LIST),
  )
    .then((response) => {
      if (response?.data) {
        const clusters = transformClusterApprovals(response)?.data || [];
        const proposedClusters = _.uniqBy(clusters, 'id').filter((cluster) => cluster?.status === STATUSES.PROPOSED);
        dispatch(setProposedAdminTransfers(proposedClusters));
      }
    })
    .finally(() => {
      dispatch(setLoader(false));
    });
};

export const getSelectedTransferHoldings = (id) => (dispatch) => {
  const jurisdictionId = localStorage.getItem('jurisdictionID');
  return fetcherGet(
    `${APIS.CLUSTER_TRANSFERS}/jurisdiction/${jurisdictionId}/cluster/${id}/clusterHoldings`,
    {},
    { componentName: COMPONENTS.TRANSFER_HOLDINGS },
  ).then((response) => {
    const transformedResponse = transformClusterTransferHoldings(response);
    const serializedBlocks = mergeSequentialBlocks(transformedResponse.data);
    dispatch(setSelectedTransfersList({ ...transformedResponse, data: serializedBlocks }));
  });
};

export const getTransferHistory = (id) => (dispatch) => {
  const jurisdictionId = localStorage.getItem('jurisdictionID');
  return fetcherGet(
    `${APIS.CLUSTER_TRANSFERS}/jurisdiction/${jurisdictionId}/cluster/${id}/clusterTransferHistory`,
    {},
    getFLPHeaders(COMPONENTS.TRANSFER_HISTORY),
  ).then((response) => {
    dispatch(setTransferHistory(response));
  });
};

export const getCurrencyDetails =
  (jurisdictionId = localStorage.getItem('jurisdictionID')) =>
    (dispatch) =>
      fetcherGet(`${APIS.TRANSFERS_BASE}/jurisdiction/${jurisdictionId}/getCurrency`)
        .then((response) => {
          dispatch(setCurrencyDetails(response.data || []));
        })
        .catch(() => {
          dispatch(setCurrencyDetails([]));
        });

export const saveClusterHoldings = (payload) => (dispatch) => {
  const jurisdictionId = localStorage.getItem('jurisdictionID');
  dispatch(setLoader(true));
  return fetcherPatch(
    `${APIS.ADMIN_TRANSFERS}/jurisdiction/${jurisdictionId}/clusterHoldingDetails`,
    {
      ...payload,
    },
    getFLPHeaders(COMPONENTS.SAVE_TRANSFER_HOLDINGS),
  ).finally(() => {
    dispatch(setLoader(false));
  });
};

export const updateTransferStatus = (payload, headers) => (dispatch) => {
  const jurisdictionId = localStorage.getItem('jurisdictionID');
  dispatch(setLoader(true));
  return fetcherPatch(
    `${APIS.ADMIN_TRANSFERS}/jurisdiction/${jurisdictionId}/updateAdminTransferCluster`,
    {
      ...payload,
    },
    headers,
  ).finally(() => {
    dispatch(setLoader(false));
  });
};

export function getStatusList() {
  return (dispatch) =>
    fetcherGet(`${APIS.STATUS_LIST}`, { type: 'CLUSTER_TRANSFER' }).then(({ data = [] }) => {
      dispatch(setStatuses(_.keyBy(data, 'Name')));
    });
}

export const addMoreHoldings =
  ({ id, ...payload }) =>
    (dispatch) => {
      const jurisdictionId = localStorage.getItem('jurisdictionID');
      dispatch(setLoader(true));
      return fetcherPost(
        `${APIS.CLUSTER_TRANSFERS}/jurisdiction/${jurisdictionId}/cluster/${id}/clusterHoldingDetails`,
        payload,
        getFLPHeaders(COMPONENTS.INITIATE_TRANSFER),
      ).finally(() => {
        dispatch(setLoader(false));
      });
    };

export const approveTransfer =
  ({ id, ...payload }) =>
    (dispatch) => {
      const jurisdictionId = localStorage.getItem('jurisdictionID');
      dispatch(setLoader(true));
      return fetcherPatch(
        `${APIS.CLUSTER_TRANSFERS}/jurisdiction/${jurisdictionId}/cluster/${id}/approveClusterTransferDetails`,
        payload,
        getFLPHeaders(COMPONENTS.UPDATE_TRANSFER_STATUS),
      ).finally(() => {
        dispatch(setLoader(false));
      });
    };

export const updateReceivingAccount = (payload) => (dispatch) => {
  const jurisdictionId = localStorage.getItem('jurisdictionID');
  dispatch(setLoader(true));
  return fetcherPatch(
    `${APIS.ADMIN_TRANSFERS}/jurisdiction/${jurisdictionId}/updateClusterAccountNumber`,
    payload,
    getFLPHeaders(COMPONENTS.UPDATE_ACCOUNT_NUMBER),
  ).finally(() => {
    dispatch(setLoader(false));
  });
};

export const deleteClusterHolding = (payload) => (dispatch) => {
  const jurisdictionId = localStorage.getItem('jurisdictionID');
  dispatch(setLoader(true));
  return fetcherDelete(`${APIS.ADMIN_TRANSFERS}/jurisdiction/${jurisdictionId}/clusterHoldingDetails`, payload).finally(
    () => {
      dispatch(setLoader(false));
    },
  );
};
