import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import Style from './CustomizeButton.style';

export default function CustomizeButton(props) {
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user; // Here we get fields permission like hidden, read Only or write

  const {
    name,
    label = '',
    rules,
    className,
    variant,
    csName,
    type,
    handleClick,
    disableElevation = true,
    disabled = false,
    privId = '',
    customStyle,
    bindTrigger = {},
  } = props;


  let readOnly = false;
  if (privId === '') {
    if (PrivAttributes?.[name]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[name]?.PrivRole === 'RO';
  } else {
    if (PrivAttributes?.[privId]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[privId]?.PrivRole === 'RO';
  }
  if (readOnly) {
    return (<Button disabled variant={variant} className={csName} sx={[Style.readOnly, customStyle]}>{label}</Button>);
  }
  return (
    <Button disabled={disabled} sx={[Style.normalButton, customStyle]} disableElevation={disableElevation}
            variant={variant} type={type} onClick={handleClick} className={csName} data-testid={name} {...bindTrigger}>
      {label}
    </Button>
  );
}
