import { Loadable } from 'components/Loadable';
import RoleBasedGuard from 'guards/RoleBasedGuard';
import { lazy } from 'react';

const CMSReportsLoadable = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.CMSReports,
    })),
  ),
);

export const CMSReportsRoutes = {
  path: '/cms-compare-reports',
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={''}>
          <CMSReportsLoadable />
        </RoleBasedGuard>
      ),
    },
  ],
};

 