import { createSlice } from '@reduxjs/toolkit';
import { fetcherGet } from 'utils/fetcher';
import { APIS } from 'utils/constants';
import { getPermissionObj } from 'utils/support';

const initialState = {
  fieldLevelPermissions: [],
  fieldPermissionsObj: {},
  fieldsMetadata: [],
  isLoading: false,
};

const FieldPermissionSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setFieldLevelPermissions: (state, action) => {
      state.fieldLevelPermissions = action.payload;
    },
    setFieldsMetadata: (state, action) => {
      state.fieldsMetadata = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setFieldPermissionsObj: (state, action) => {
      state.fieldPermissionsObj = action.payload;
    },
  },
});

export const {
  setFieldLevelPermissions,
  setFieldsMetadata,
  setIsLoading,
  setFieldPermissionsObj,
} = FieldPermissionSlice.actions;
export default FieldPermissionSlice.reducer;

export function getPagePermissions(pageName, componentName = [], entityId = null) {
  return (dispatch) => {
    dispatch(setIsLoading(true));
    return fetcherGet(`${APIS.FIELD_PERMISSIONS}`, { pageName, componentName: componentName.toString(), entityId })
      .then((response) => {
        dispatch(setFieldLevelPermissions(response?.data ?? []));
        dispatch(setFieldPermissionsObj(getPermissionObj(response?.data ?? [])));
        dispatch(setFieldsMetadata(response?.metaData ?? []));
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };
}
