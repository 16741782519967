import { Loadable } from 'components/Loadable';
import RoleBasedGuard from 'guards/RoleBasedGuard';
import { lazy } from 'react';

const EmissionList = Loadable(
  lazy(() =>
    import('pages/Emissions').then((module) => ({
      default: module.EmissionList,
    })),
  ),
);

const EmissionDetails = Loadable(
  lazy(() =>
    import('pages/Emissions').then((module) => ({
      default: module.EmissionDetails,
    })),
  ),
);

const BatchEmissionList = Loadable(
  lazy(() =>
    import('pages/Emissions').then((module) => ({
      default: module.BatchEmissionList,
    })),
  ),
);

const CreateBatchEmission = Loadable(
  lazy(() =>
    import('pages/Emissions').then((module) => ({
      default: module.CreateBatchEmission,
    })),
  ),
);

const BatchEmissionDetails = Loadable(
  lazy(() =>
    import('pages/Emissions').then((module) => ({
      default: module.BatchEmissionDetails,
    })),
  ),
);

export const ManageEmissionRoutes = {
  path: '/emissions',
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={''}>
          <EmissionList />
        </RoleBasedGuard>
      ),
    },
    {
      path: ':id/batch/:batchId',
      element: (
        <RoleBasedGuard privId={''}>
          <EmissionDetails />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'batches',
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard privId={''}>
              <BatchEmissionList />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'create',
          element: (
            <RoleBasedGuard privId={''}>
              <CreateBatchEmission />
            </RoleBasedGuard>
          ),
        },
        {
          path: ':id',
          element: (
            <RoleBasedGuard privId={''}>
              <BatchEmissionDetails />
            </RoleBasedGuard>
          ),
        },
        {
          path: ':id/propose',
          element: (
            <RoleBasedGuard privId={''}>
              <BatchEmissionDetails isProposalScreen />
            </RoleBasedGuard>
          ),
        },
      ],
    },
  ],
};
