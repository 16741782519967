import authRoles from 'core/auth/authRole';
import PageNotFound from './PageNotFound';

const PageNotFoundConfig = {
  route: {
    path: '/404',
    element: <PageNotFound />,
  },
  layout: {
    header: {
      display: false,
    },
    sidebar: {
      display: false,
    },
    footer: {
      display: false,
    },
  },
  auth: authRoles.onlyGuest,
};

export default PageNotFoundConfig;
