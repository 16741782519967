import { Box, Typography, Grid, Button, Modal, Checkbox, ListItemText, FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useSelector, useDispatch } from 'react-redux';
import { getJurisdictions, setJurisdiction } from 'store/jurisdictionSlice';
import { useTranslation } from 'react-i18next';
import { CheckCircle } from 'assets/icons';
import { LANGUAGES } from 'utils';
import { Loader } from 'components/Loader';
import { useTheme } from '@mui/material/styles';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import {
  DEFAULT_LANGUAGE_CODE,
  enLanguage,
  getAllData,
  getAllLang,
  getCurrentORGID,
  getCurrentPageName,
  isEmpty,
  languageCode,
} from 'utils/constants';
import { getCMSDetails } from 'store/configSlice';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Style from './styles';

function LangModal(props) {
  const { t, i18n } = useTranslation(getCurrentPageName());
  const [languagesData, setLanguageData] = useState([]);
  /* Conflict Resolution Candidate
  const [loaderState, setLoaderState] = useState(false);
 */
  const theme = useTheme();
  const languages = [];
  const themeColor = theme?.palette?.primary?.main;
  const dispatch = useDispatch();
  const { selectedJurisdiction, Jurisdictions } = useSelector(({ jurisdiction }) => jurisdiction);
  const { cmsLanguagePage } = useSelector(({ config }) => config);
  /* Conflict Resolution Candidate
  const { loader } = useSelector(({ common }) => common);
 */
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        height: 'auto',
        maxHeight: '120px',
      },
    },
    MenuListProps: { disablePadding: true },
  };
  const MenuPropsDefault = {
    PaperProps: {
      style: {
        height: 'auto',
      },
    },
  };

  const handleClick = () => {
    localStorage.setItem('i18nextLng', `${selectLang.value}`);
    localStorage.setItem('jurisdiction', selectValue.value); // added changes
    localStorage.setItem('languageCode', selectLang.languageCode);
    i18n.changeLanguage(`${decodeURIComponent(getCurrentORGID())}#${languageCode()}`);
    window.location.reload();
  };

  useEffect(() => {
    cmsLanguagePage?.data?.Languages?.map((item, index) => {
      languages.push({
        value: item?.SK?.split('#')?.pop(),
        title: item?.SK?.split('#')?.pop(),
        languageCode: item?.LanguageCode || DEFAULT_LANGUAGE_CODE,
      });
      return null;
    });
    setLanguageData(languages);
    let temp = 'selectLang';
    if (languages?.length > 0) {
      temp = languages?.filter((langObj) => langObj.value === 'English' && langObj.title === 'English')?.[0];
      localStorage.setItem('languageCode', temp?.languageCode);
    }

    setSelectLang(
      languages?.length > 0
        ? languages?.filter((langObj) => langObj.value === 'English' && langObj.title === 'English')?.[0]
        : 'selectLang',
    );
    /* Conflict Resolution Candidate
    i18n.changeLanguage(languages?.length > 0 ? `${getCurrentORGID()}#${languages?.filter((langObj) => langObj.value === "English" && langObj.title === "English")?.[0]?.value}` : `${getCurrentORGID()}#${enLanguage()}`);
    setLoaderState(false)
 */

    // i18n.changeLanguage(languages?.length > 0 ? languages?.filter((langObj) => langObj.value === "English" && langObj.title === "English")?.[0]?.value : enLanguage());
  }, [cmsLanguagePage]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectValue(
      // On autofill, we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    const LanguageParams = {
      OrgID: value.value,
      QueryType: getAllLang,
    };
    dispatch(getCMSDetails(LanguageParams));
    /* Conflict Resolution Candidate
    setLoaderState(true);
 */
    // setSelectLang(languagesData?.length > 0 ? {} : "selectLang");
    // setSelectLang("selectLang");
  };

  const selectLanguage = (event) => {
    const {
      target: { value },
    } = event;
    /* Conflict Resolution Candidate
    // localStorage.setItem("i18nextLng", event.target.value.value);
    i18n.changeLanguage(`${getCurrentORGID()}#${event.target.value.value}`);
 */
    localStorage.setItem('languageCode', value?.languageCode);
    // i18n.changeLanguage(event.target.value.value);
    setSelectLang(
      // On autofill, we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const juridictions = Jurisdictions.filter((jurisdiction) => jurisdiction.IsDefault === false)?.map((each, index) => ({
    title: each.OrgName,
    value: each.OrgID,
  }));
  const [selectValue, setSelectValue] = React.useState('selectJuri');
  const [selectLang, setSelectLang] = React.useState('selectLang');
  return (
    <>
      {juridictions.length > 0 && (
        <Modal
          sx={{ outline: 'none' }}
          open={props.isModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={Style.JurisdictionModal}>
            {/* Conflict Resolution Candidate
            <Typography id="modal-modal-title" variant="h2" sx={{ fontWeight: 600 }}>
              {t("SELECT_JURISDICTION")}
            </Typography>
            <Typography sx={{ mt: 4, mb: '0!important' }} variant="h6">
              {t("PLEASE_SELECT_A_JURISDICTION_TO_CONTINUE")}
            </Typography>
            <Typography variant="h6">
            {t("MORE_TO_TRANSLATE_TEXT")}
            </Typography>

            <Grid container spacing={0}>
              <Grid item lg={6} md={6} sm={6}>
                <Typography variant="h5">{t("SELECT_JURISDICTION")}</Typography>
                <Box sx={Style.CustomSelect}>
                  <FormControl className="CustomSelect" sx={{ m: 1, width: 300 }}>
            */}
            <Typography
              id="modal-modal-title"
              variant="h2"
              sx={{ fontWeight: 'bold' }}
            >
              {t('SELECT_JURISDICTION')}
            </Typography>
            <Typography sx={{ mt: 0 }}>{t('PLEASE_SELECT_A_JURISDICTION_TO_CONTINUE')}</Typography>
            <Typography variant="h6">{t('MORE_TO_TRANSLATE')}</Typography>
            <Grid
              container
              spacing={2.5}
            >
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
              >
                <Typography
                  variant="p"
                  sx={{ fontWeight: '600', fontSize: '18px', fontFamily: 'Open Sans Semibold ,\'Open Sans\'' }}
                >
                  {t('SELECT_JURISDICTION')}
                </Typography>
                <Box sx={Style.CustomSelect}>
                  <FormControl
                    className="CustomSelect"
                    sx={{ marginTop: '20px !important', width: '100%' }}
                  >
                    <Select
                      sx={Style.formField}
                      renderValue={(selected) =>
                        SelectRenderValue(selected, 'selectJuri', themeColor, t('SELECT_JURISDICTION'), t)
                      }
                      displayEmpty
                      value={selectValue}
                      onChange={(e) => handleChange(e)}
                      MenuProps={juridictions && juridictions?.length > 0 ? MenuProps : MenuPropsDefault}
                      inputProps={{
                        'aria-required': 'true',
                      }}
                    >
                      {juridictions &&
                        juridictions?.map((jurisdiction, index) => (
                          <MenuItem
                            key={index + jurisdiction}
                            value={jurisdiction}
                            sx={{ p: 0 }}
                          >
                            <Checkbox
                              checked={selectValue?.value === jurisdiction?.value}
                              icon={<RadioButtonUncheckedIcon color={themeColor} />}
                              checkedIcon={<RadioButtonCheckedIcon color={themeColor} />}
                            />
                            <ListItemText primary={`${t(`${jurisdiction.title}`)}`} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
              >
                {/* Conflict Resolution Candidate
                <Typography variant="h4">{t("SELECT_LANGUAGE")}</Typography>
                <Box sx={Style.CustomSelect}>
                  <FormControl className="CustomSelect" sx={{ m: 1, width: 300, float: "right" }}>
                    <Select
                      disabled={selectValue === 'selectJuri'}
                */}
                <Typography
                  variant="p"
                  sx={{ fontWeight: '600', fontSize: '18px', fontFamily: 'Open Sans Semibold ,\'Open Sans\'' }}
                >
                  {t('SELECT_LANGUAGE')}
                </Typography>
                <Box sx={Style.CustomSelect}>
                  <FormControl
                    className="CustomSelect"
                    sx={{ marginTop: '20px !important', width: '100%', ...Style.formField }}
                  >
                    <Select
                      disabled={selectValue === 'selectJuri'}
                      sx={Style.formField}
                      renderValue={(selected) =>
                        SelectRenderValue(selected, 'selectLang', themeColor, t('SELECT_LANGUAGE'), t)
                      }
                      displayEmpty
                      value={selectLang}
                      onChange={(e) => selectLanguage(e)}
                      MenuProps={languagesData && languagesData?.length > 0 ? MenuProps : MenuPropsDefault}
                      inputProps={{
                        'aria-required': 'true',
                      }}
                    >
                      {languagesData?.length > 0 &&
                        languagesData?.map((language, index) => (
                          <MenuItem
                            key={`${index}-${language}`}
                            value={language}
                            sx={{ p: 0 }}
                          >
                            <Checkbox
                              checked={language?.value === selectLang?.value}
                              icon={<RadioButtonUncheckedIcon color={themeColor} />}
                              checkedIcon={<RadioButtonCheckedIcon color={themeColor} />}
                            />
                            <ListItemText primary={language?.title} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Button
                /* Conflict Resolution Candidate
                className="ContinueBtn"
*/
                className="ContinueBtn btn-primary"
                variant="contained"
                color="primary"
                onClick={handleClick}
                disabled={selectLang === 'selectLang' || selectValue === 'selectJuri'}
              >
                {t('CONTINUE')}
              </Button>
            </Grid>
            {/* Conflict Resolution Candidate
            {loaderState && <Loader loader />}
          </Box>
        </Modal>
      )}
      {(juridictions?.length === 0) && <Loader loader />}
          */}
          </Box>
        </Modal>
      )}
      {juridictions?.length === 0 && <Loader loader />}
    </>
  );
}

function SelectRenderValue(selected, initialVal, color, defaultValue, t) {
  if (selected !== initialVal) {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        <Typography
          component="div"
          key={selected}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <CheckCircle color={color} />
          <Box sx={{ ml: 0.5 }}>{`${t(`${selected.title}`)}`}</Box>
        </Typography>
      </Box>
    );
  }
  return defaultValue;
}

export default LangModal;
