import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { FormControl, Grid, IconButton, Radio, RadioGroup, Typography } from '@mui/material';
import { GridTextfield } from 'components';
import { CustomizeButton } from 'components/CustomizeButton';
import { QuickLinksMenu } from 'components/QuickLinksMenu';
import { useJurisdictionUserRole } from 'hooks/useUserRole';
import { StyleFormControlLabel, StyleFormLabel, StyledQuickAction } from 'pages/AccountDetails/AccountDetails.style';
import PassphraseConfirmationDialog from 'pages/WiringInstructions/components/PassphraseConfirmationDialog';
import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAccountInfo } from 'store/IssuanceSlice';
import { setModelMessage } from 'store/commonSlice';
import { setIsLoadingAccountUpdate } from 'store/entityAccountSlice';
import { getJurisdictionAccounts } from 'store/jurisdictionSlice';
import { getStatusChangeAPI } from 'store/manageJuriAccountsSlice';
import { updateJurisdictionAccountStatus } from 'store/setJuriAccountsSlice';
import {
  ACCOUNT_ACTION_PAGE,
  ENTITY_ACCOUNT_STATUS,
  ERROR,
  SUCCESS,
  getCurrentPageName,
  t,
  validateCommentModule,
} from 'utils/constants';
import { decryptStr } from 'utils/support';

export default function AccountQuickActions({ accountStatus, accountId, page, isDotIcon, accountName, disabled }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { statusList } = useSelector(({ accountDetails }) => accountDetails);
  const { selectedJurisdictionId } = useSelector(({ jurisdiction }) => jurisdiction);
  const { juriAccountInformation } = useSelector(({ issuance }) => issuance);
  const [action, setAction] = useState(null);
  const { isJurisdictionRegistrarUser } = useJurisdictionUserRole();
  const params = useParams();
  const accNo = decryptStr(params?.acc || '') ?? '';
  const dispatch = useDispatch();

  const quickLinkList = useMemo(
    () => [
      ...(accountStatus !== ENTITY_ACCOUNT_STATUS.ACTIVE
        ? [
          {
            name: t(t1, 'ACTIVATE_JURISDICTION_ACCOUNT').toString().toUpperCase(),
            Access: null,
            onLinkClick: () => setAction(ENTITY_ACCOUNT_STATUS.ACTIVE),
          },
        ]
        : []),
      {
        name: t(t1, 'RESTRICT_JURISDICTION_ACCOUNT').toString().toUpperCase(),
        Access: null,
        onLinkClick: () => setAction(ENTITY_ACCOUNT_STATUS.RESTRICTED),
      },
      ...(accountStatus !== ENTITY_ACCOUNT_STATUS.SUSPENDED
        ? [
          {
            name: t(t1, 'SUSPEND_JURISDICTION_ACCOUNT').toString().toUpperCase(),
            Access: null,
            onLinkClick: () => setAction(ENTITY_ACCOUNT_STATUS.SUSPENDED),
          },
        ]
        : []),
      ...(accountStatus !== ENTITY_ACCOUNT_STATUS.INACTIVE
        ? [
          {
            name: t(t1, 'INACTIVATE_JURISDICTION_ACCOUNT').toString().toUpperCase(),
            Access: null,
            onLinkClick: () => setAction(ENTITY_ACCOUNT_STATUS.INACTIVE),
          },
        ]
        : []),
      ...(accountStatus !== ENTITY_ACCOUNT_STATUS.CLOSED
        ? [
          {
            name: t(t1, 'CLOSE_JURISDICTION_ACCOUNT').toString().toUpperCase(),
            Access: null,
            onLinkClick: () => setAction(ENTITY_ACCOUNT_STATUS.CLOSED),
          },
        ]
        : []),
    ],
    [accountStatus, t1],
  );

  if (!isJurisdictionRegistrarUser) {
    return null;
  }

  function changeJurisdictionAccountStatus(payload) {
    dispatch(setIsLoadingAccountUpdate(true));
    setAction(null);
    dispatch(updateJurisdictionAccountStatus(payload))
      .then((response) => {
        const data = {
          title: SUCCESS,
          message1: t(t1, 'SUCCESS_MESSAGE'),
          error: false,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
        dispatch(setIsLoadingAccountUpdate(false));
        setAction(null);
        if (ACCOUNT_ACTION_PAGE.JURISDICTION_ACCOUNTS === page) {
          dispatch(getJurisdictionAccounts(selectedJurisdictionId));
        } else if (ACCOUNT_ACTION_PAGE.JURISDICTION_ACCOUNT_DETAILS === page) {
          dispatch(getStatusChangeAPI(juriAccountInformation.data?.data?.id));
          dispatch(getAccountInfo(accNo, { pageName: 'Page_AccountDetails', componentName: 'accountInformation' }));
        }
      })
      .catch((error) => {
        dispatch(setIsLoadingAccountUpdate(false));
        setAction(null);
        const data = {
          title: ERROR,
          message1: error?.response?.data?.data,
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
      });
  }

  const handleConfirm = (data) => {
    let nextStatus = action;
    let nextStatusId = null;
    if (action === ENTITY_ACCOUNT_STATUS.RESTRICTED) {
      nextStatus = data?.restrictionType;
    }
    const status = statusList?.find((item) => item?.Name === nextStatus);
    if (status && data?.comment) {
      nextStatusId = status?.ID;
      const payload = {
        jurisdictionAccountId: accountId,
        newStatusId: nextStatusId,
        comment: data?.comment?.trim(),
      };
      changeJurisdictionAccountStatus(payload);
    }
  };

  return (
    <>
      {disabled && isDotIcon && (
        <IconButton disabled={disabled}>
          <MoreHorizIcon sx={{ fontSize: '2rem', scale: '1.4', marginTop: '4px' }} />
        </IconButton>
      )}

      {disabled && !isDotIcon && (
        <CustomizeButton
          customStyle={{
            marginRight: '0px !important',
            minWidth: '140px !important',
            height: '40px',
            padding: '6px 7px',
          }}
          variant="contained"
          label={t(t1, 'MORE_ACTIONS')}
          name="more-actions"
          disabled={disabled}
        />
      )}

      {!disabled && (
        <QuickLinksMenu
          iconClassName={'dot-icon'}
          popupClassName="quick-link-style"
          linksList={quickLinkList || []}
          label={t(t1, 'MORE_ACTIONS')}
          isDotIcon={isDotIcon}
        />
      )}

      <PassphraseConfirmationDialog
        title={`${t(t1, 'CONFIRM_STATUS_UPDATE')} ( ${accountName} )`}
        cancelButtonLabel={t(t1, 'CANCEL')}
        confirmButtonLabel={t(t1, 'CONFIRM')}
        pageName={getCurrentPageName()}
        onPassphraseSuccess={(data) => handleConfirm(data)}
        handleCloseDialog={() => {
          setAction(null);
        }}
        onPassphraseFailure={() => setAction(action)}
        open={action}
        resetFormElementsAvoid
        renderFormElements={() => (
          <>
            <Typography sx={{ fontSize: '13px', mb: '2rem', color: 'common.textColor' }}>
              {t(t1, `CONFIRM_STATUS_UPDATE_NOTE_${action}`)}
            </Typography>

            <Grid
              item
              md={12}
              sx={{ mt: 1 }}
            >
              {action === ENTITY_ACCOUNT_STATUS.RESTRICTED ? <RestrictionType accountStatus={accountStatus} /> : null}

              <GridTextfield
                label={t(t1, 'PLEASE_ADD_A_COMMENT')}
                gridMd={12}
                name="comment"
                multiline
                className="input-group"
                labelStyle={{ height: 'auto' }}
                rows={2}
                rules={{
                  required: true,
                  maxLength: {
                    value: 8000,
                    message: `${t(t1, 'ERR_COMMENTS_MAX_LENGTH_CHAR')}`,
                  },
                  validate: (v) => validateCommentModule(v, `${t(t1, 'COMMENT')}`, t1),
                }}
                isRequiredMsg={'COMMENTS_ISREQUIRED'}
              />
            </Grid>
          </>
        )}
      />
    </>
  );
}

function RestrictionType({ accountStatus }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { control, getValues } = useFormContext();
  return (
    <Controller
      control={control}
      name="restrictionType"
      rules={{ required: true }}
      render={({ field: { value, onChange } }) => (
        <FormControl sx={{ my: 1.5 }}>
          <StyleFormLabel
            id="restrictionType"
            size="small"
          >
            {`${t(t1, 'SELECT_RESTRICTION')}:`}
            <span
              style={{
                display: 'inline-block',
                color: '#CA2C1C',
                marginLeft: '4px',
                fontWeight: 700,
              }}
            >
              *
            </span>
          </StyleFormLabel>
          <RadioGroup
            aria-labelledby="restrict-reason"
            name="restrictionType"
            value={value}
            onChange={onChange}
            size="small"
          >
            {accountStatus !== ENTITY_ACCOUNT_STATUS.RESTRICTED_CANNOT_TRANSFER && (
              <StyleFormControlLabel
                disableTypography
                size="small"
                value={ENTITY_ACCOUNT_STATUS.RESTRICTED_CANNOT_TRANSFER}
                control={<Radio size="small" />}
                label={t(t1, 'RESTRICTED_CANNOT_TRANSFER')}
              />
            )}
            {accountStatus !== ENTITY_ACCOUNT_STATUS.RESTRICTED_CANNOT_RECEIVE && (
              <StyleFormControlLabel
                disableTypography
                size="small"
                value={ENTITY_ACCOUNT_STATUS.RESTRICTED_CANNOT_RECEIVE}
                control={<Radio size="small" />}
                label={t(t1, 'RESTRICTED_CANNOT_RECEIVE')}
              />
            )}
            {accountStatus !== ENTITY_ACCOUNT_STATUS.RESTRICTED_CANNOT_TRANSFER_AND_RECEIVE && (
              <StyleFormControlLabel
                disableTypography
                size="small"
                value={ENTITY_ACCOUNT_STATUS.RESTRICTED_CANNOT_TRANSFER_AND_RECEIVE}
                control={<Radio size="small" />}
                label={t(t1, 'RESTRICTED_CANNOT_TRANSFER_AND_RECEIVE')}
              />
            )}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
}
