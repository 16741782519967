import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { DialogMessage } from 'components';
import { defaultLayout, layouts } from 'config/layouts';
/* Conflict Resolution Candidate
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getCMSDetails } from "store/configSlice";
import { resetCreaeUserProfileState } from "store/createUserProfileSlice";
import { setJurisdiction } from "store/jurisdictionSlice";
import {t, getCurrentPageName, cancelRouteName, isEmpty, getAllLang } from "utils/constants";
 */
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetCreaeUserProfileState } from 'store/createUserProfileSlice';
import { t, getCurrentPageName } from 'utils/constants';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Main from './Main/Main';

export default function Layout(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t: t1 } = useTranslation(getCurrentPageName());
  let currentLayout = defaultLayout;
  const { dialogMessage, modelMessage } = useSelector(({ common }) => common);
  const {
    redirectionPath,
  } = useSelector(({ createUserProfile }) => createUserProfile);

  const path =
    location.pathname.lastIndexOf('/') === location.pathname.length - 1
      ? location.pathname.slice(0, location.pathname.length - 1)
      : location.pathname;

  layouts.forEach((layout) => {
    if (layout.route.path === path || path.includes(layout.route.path)) {
      currentLayout = layout.layout;
    }
  });

  const cancelCreateUser = () => {
    /* Conflict Resolution Candidate
        const {jurisdiction,route} =  redirectionPath

        navigate(route);
        dispatch(resetCreaeUserProfileState());

        // for jurisdiction change
        if(!isEmpty(jurisdiction)){
          dispatch(setJurisdiction(jurisdiction));
          const LanguageParams = {
            OrgID: jurisdiction,
            QueryType: getAllLang,
          };
          dispatch(getCMSDetails(LanguageParams));
        }

      };

      // cancel button text display dynamically by route
      const cancelButtonText = useMemo(()=>
        `${t(t1, "DIALOG_BUTTONS_INFORMATION_MESSAGE_1")} ${t(t1, `${cancelRouteName?.[redirectionPath?.route]}`)} ${t(t1, "DIALOG_BUTTONS_INFORMATION_MESSAGE_2")}`
      ,[t1,redirectionPath])
     */

    // console.log(redirectionPath,"path")
    navigate(redirectionPath);
    dispatch(resetCreaeUserProfileState());
  };

  return (
    <Box className="mainContent" sx={{ bgcolor: 'common.pageBackground' }}>
      <CssBaseline />
      {currentLayout?.header?.display ? <Header /> : null}
      {/* <Container maxWidth="xl"> */}
      <Main>{props.children}</Main>
      {/* </Container> */}
      {currentLayout?.footer?.display ? <Footer /> : null}
      {(dialogMessage && location.pathname === '/create-user-profile') ? (
        <DialogMessage
          warning
          title={t(t1, 'WARNING')}
          message1={t(t1, 'DIALOG_ERASE_MESSAGE')}
          message2={t(t1, 'DIALOG_RESTART_MESSAGE')}
          handleModalClick={cancelCreateUser}
          /* Conflict Resolution Candidate
                      buttonMessage={cancelButtonText}
           */
          buttonMessage={t(t1, 'DIALOG_BUTTONS_INFORMATION_MESSAGE')}
        />
      ) : null}
    </Box>
  );
}
