/* Conflict Resolution Candidate
import { LoadingScreen } from "components/LoadingScreen";
import authRoles from "core/auth/authRole";
import { lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";
import { enLanguage, getCurrentORGID } from "utils/constants";
 */
import { LoadingScreen } from 'components/LoadingScreen';
import authRoles from 'core/auth/authRole';
import { lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
// import LandingPageKnown from "./LandingPageKnown";
// import LandingPageUnknown from "../LandingPageUnknown/LandingPageUnknown";

// eslint-disable-next-line func-names
const Loadable = (Component) => function(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    /* Conflict Resolution Candidate
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
          <Component {...props} />
        </Suspense>
      );
    };

     */

    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};


const LandingPageKnown = Loadable(lazy(() => import('./LandingPageKnown')));
const LandingPageUnknown = Loadable(lazy(() => import('../LandingPageUnknown/LandingPageUnknown')));

const LandingPageKnownConfig = {
  route: {
    path: '/',
    element:
      localStorage.getItem('i18nextLng') && localStorage.getItem('jurisdiction') ? (
        <LandingPageKnown />
      ) : (
        <LandingPageUnknown />
      ),
  },
  layout: {
    header: {
      display: true,
    },
    sidebar: {
      display: false,
    },
    footer: {
      display: true,
    },
  },
  auth: authRoles.onlyGuest,
};

export default LandingPageKnownConfig;

