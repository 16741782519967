import { INTER_JURISDICTIONAL_TRANSFERS } from '../support';

export const jurisdictionInfoMockData = {
  messageKey: 'SUCCESS',
  data: {
    id: '2',
    jurisdiction: 'New Jurisdiction',
    jurisdictionID: 'JURO123',
    jurisdictionCode: '123456',
    country: 'United States',
    status: 'Pre-Linked',
  },
};

export const linkedJurisdictionMockData = {
  messageKey: 'SUCCESS',
  data: [
    {
      id: '1',
      jurisdiction: 'California',
      status: 'Linked',
    },
    {
      id: '2',
      jurisdiction: 'Quebec',
      status: 'Linked',
    },
  ],
};

export const interJurisdictionalTransfersFields = [
  {
    id: '1',
    name: 'ALLOW_TRANSFERS_FROM_PROPOSED_STATUS',
    value: 'proposed',
    type: INTER_JURISDICTIONAL_TRANSFERS.INTER_JURISDICTIONAL,
  },
  {
    id: '2',
    name: 'ALLOW_TRANSFERS_FROM_APPROVED_STATUS',
    value: 'approved',
    type: INTER_JURISDICTIONAL_TRANSFERS.INTER_JURISDICTIONAL,
  },
  {
    id: '3',
    name: 'ALLOW_TRANSFERS_FROM_ACCEPTED_STATUS',
    value: 'accepted',
    type: INTER_JURISDICTIONAL_TRANSFERS.INTER_JURISDICTIONAL,
  },
];

export const crossJurisdictionalTransfersWithFields = [
  {
    id: '1',
    name: 'ALLOW_TRANSFERS_FROM_SAVED_STATUS',
    value: 'saved',
    type: INTER_JURISDICTIONAL_TRANSFERS.CROSS_JURISDICTIONAL,
  },
  {
    id: '2',
    name: 'ALLOW_TRANSFERS_FROM_PROPOSED_STATUS',
    value: 'proposed',
    type: INTER_JURISDICTIONAL_TRANSFERS.CROSS_JURISDICTIONAL,
  },
  {
    id: '3',
    name: 'ALLOW_TRANSFERS_FROM_APPROVED_STATUS',
    value: 'approved',
    type: INTER_JURISDICTIONAL_TRANSFERS.CROSS_JURISDICTIONAL,
  },
  {
    id: '4',
    name: 'ALLOW_TRANSFERS_FROM_ACCEPTED_STATUS',
    value: 'accepted',
    type: INTER_JURISDICTIONAL_TRANSFERS.CROSS_JURISDICTIONAL,
  },
];


export const conflictFlagMockRes = { 'messageKey': 'SUCCESS', 'data': false };