import { useEffect, useState } from 'react';
import i18n from 'config/lang/i18n';

import {
  Container,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FormCard } from 'components';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomizeButton } from 'components/CustomizeButton';
import { DialogMessage } from 'components/DialogMessage';
import { ModelMessage } from 'components/ModelMessage';
import { PageTitle } from 'components/PageTitle';
import { UserSteps } from 'components/UserSteps';
import { t, getCurrentPageName } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import Style from './CreateUserProfile.style';

export default function CreateUserProfile() {
  const { t: t1 } = useTranslation(getCurrentPageName());

  const navigate = useNavigate();
  const theme = useTheme();
  const {
    formSteps: { activeStep },
  } = useSelector(({ createUserProfile }) => createUserProfile);

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={2.5}
      >
        <Grid
          item
          md={12}
        >
          <Box sx={{ pt: 3 }}>
            <PageTitle title={`${t(t1, 'HEADER_USER_REGISTRATION_PAGE')}`} />
            <FormCard
              indicateRequired={false}
              stepNumber={0}
              // setDefaultValues={setDefaultValues}
            >
              <Box sx={Style.userRegistrationPage}>
                <List
                  as="ol"
                  sx={Style.listButton}
                  className="list-item-btn"
                >
                  <ListItem disablePadding>
                    <ListItemButton disableRipple>
                      <Box>
                        <span>1</span>
                      </Box>
                      <ListItemText
                        primary={t(t1, 'CREATE_USER_PROFILE')}
                        secondary={
                          <>
                            <Typography
                              sx={{ display: 'block', marginBottom: '4px' }}
                              component="p"
                              variant="body2"
                              color="text.primary"
                            >
                              {t(t1, 'CREATE_USER_PROFILE_LINE_1', '')}
                            </Typography>
                            <Typography
                              sx={{ display: 'block' }}
                              component="p"
                              variant="body2"
                              color="text.primary"
                            >
                              {t(t1, 'CREATE_USER_PROFILE_LINE_2', '')}
                            </Typography>
                          </>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton disableRipple>
                      <Box>
                        <span>2</span>
                      </Box>
                      <ListItemText
                        primary={t(t1, 'SUBMIT_REQUIRED_DOCUMENTS')}
                        secondary={
                          <>
                            <Typography
                              sx={{ display: 'block', marginBottom: '4px' }}
                              component="p"
                              variant="body2"
                              color="text.primary"
                            >
                              {t(t1, 'SUBMIT_REQUIRED_DOCUMENTS_1')}
                            </Typography>
                            <Typography
                              sx={{ display: 'block' }}
                              component="p"
                              variant="body2"
                              color="text.primary"
                            >
                              {t(t1, 'SUBMIT_REQUIRED_DOCUMENTS_2')}
                            </Typography>
                          </>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      disableRipple
                      component="a"
                      href="#simple-list"
                    >
                      <Box>
                        <span>3</span>
                      </Box>
                      <ListItemText
                        primary={t(t1, 'AWAIT_JURIDICTION_DECISION')}
                        secondary={
                          <>
                            <Typography
                              sx={{ display: 'block', marginBottom: '4px' }}
                              component="p"
                              variant="body2"
                              color="text.primary"
                            >
                              {t(t1, 'AWAIT_JURIDICTION_DECISION_1')}
                            </Typography>
                            <Typography
                              sx={{ display: 'block' }}
                              component="p"
                              variant="body2"
                              color="text.primary"
                            >
                              {t(t1, 'AWAIT_JURIDICTION_DECISION_2')}
                            </Typography>
                          </>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            </FormCard>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                '& .MuiButtonBase-root': {
                  width: '239px',
                  fontWeight: 400,
                  border: 'none',
                  '&:hover': {
                    width: '239px',
                    /* Conflict Resolution Candidate
                    backgroundColor:"#283459",
                    fontWeight: 600,
 */
                    backgroundColor: '#4D8DA4',
                    fontWeight: 400,
                  },
                },
              }}
            >
              <CustomizeButton
                customStyle={{ marginRight: '0px!important' }}
                csName={'registrationBtn'}
                variant="contained"
                handleClick={() => navigate('/create-user-profile')}
                /* Conflict Resolution Candidate
                name="priv_btn_StartNewRegistration"
 */
                name="priv_btn_Search"
                label={t(t1, 'START_NEW_REGISTRAITON')}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
