import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

RHFCheckbox.propTypes = {
  name: PropTypes.string,
};

export function RHFCheckbox({
                              name = '',
                              value = false,
                              onChange = false,
                              privId = '',
                              btnDisabled = false,
                              tabIndex,
                              ...other
                            }) {
  const { control } = useFormContext();

  // Redux state de-structure
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user; // Here we get fields permission like hidden, read Only or write

  let readOnly = false;

  if (privId === '') {
    if (PrivAttributes?.[name]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[name]?.PrivRole === 'RO';
  } else {
    if (PrivAttributes?.[privId]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[privId]?.PrivRole === 'RO';
  }

  const handleKeypress = (e) => {
    const obj = { target: { checked: !e.target.checked } };
    onChange(obj);
  };

  return (
    <FormControlLabel
      control={
        <Controller
          disabled={readOnly || btnDisabled}
          name={name}
          control={control}
          render={({ field }) =>
            <Checkbox
              tabIndex={0}
              disabled={readOnly || btnDisabled}
              disableRipple={tabIndex ? null : true}
              disableFocusRipple={tabIndex ? null : true}
              disableTouchRipple
              {...field}
              checked={value}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && tabIndex) handleKeypress(e);
              }}
              onChange={onChange}
            />}
        />
      }
      {...other}
    />
  );
}

// ----------------------------------------------------------------------

RHFMultiCheckbox.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
};

export function RHFMultiCheckbox({ name, options, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option) =>
          field.value.includes(option) ? field.value.filter((value) => value !== option) : [...field.value, option];

        return (
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    checked={field.value.includes(option)}
                    onChange={() => field.onChange(onSelected(option))}
                  />
                }
                label={option}
                {...other}
              />
            ))}
          </FormGroup>
        );
      }}
    />
  );
}
