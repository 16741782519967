import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { UserRegistration } from 'pages/CreateUserProfile/components/UserRegistration';
import RoleBasedGuard from 'guards/RoleBasedGuard';
import AssociateRetiredFacility from 'pages/AssociateRetiredFacility/AssociateRetiredFacility';
import Document from 'pages/EntityRegistration/Registration/components/SubmitRequiredDocuments/Document';
import Facilityform from 'pages/EntityProfile/components/Facilityform';
import { exchangeContractCodeRoutes } from 'pages/ExchangeContractCode/routesMEC';
import { offsetManagementRoutes } from 'pages/OffsetManagement/routesMOF';
import { reportRoutes } from 'pages/Reports/routes';
import { Loadable } from 'components/Loadable';
import { manageEntityTransfersRoutes } from 'pages/EntityTransfers/routesMET';
import { modifiedRecordsForTransferRoutes } from 'pages/ModifiedRecordsForTransfers/routesMRT';
import { eventCreationAndManagementRoutes } from 'pages/EventCreationAndManagement/routesECAM';
import { eventApplicationManagementRoutes } from 'pages/EventApplicationManagement/routesEAM';
import { setUpInitialUsersRoutes } from 'pages/SetupInitialUser/routesInitialUser';
import { setUpJurisdictionAccountsRoutes } from 'pages/SetUpJurisdictionAccounts/routesSetUpJuriAcc';
import { manageJurisdictionAccountsRoutes } from 'pages/ManageJurisdictionAccounts/routesManageJuriAcc';
import { eventApplicationRoutes } from 'pages/EventApplicationJuri/routesEA';
import {
  AllReports,
  EARReports,
  EventADER,
  EventParticipantReport,
  RegistryEntityFacilityReport,
  EntityLegalOpNameChangeReport,
  FacilityGHGIdentifierChangeReport,
  FacilityLegalOpNameChangeReport,
  WiringInstructionsStatusReport,
  QualifiedBiddersReconciliationReport,
  BidLimitationsReconciliationReport,
  EntityUserRelationshipsReconciliationReport,
  ConsignmentWiringInstructionsReport,
  FacilityEmissionsReport,
} from 'pages/AllReports/routesAllReports';
import {
  FSAAllReportsRoutes,
  FSAReportRoute,
  ConsignmentWiringInstructionsReportAsync,
} from 'pages/FSAAllReports/routesFSAAllReports';
import { IndividualUsersAllReportsRoutes } from 'pages/IndividualUsersAllReports/routesIndividualUserAllReports';
import { financialServicesDeliveryInstructions } from 'pages/FinancialServicesDeliveryInstruction/routesMFS';
import { CMSReportsRoutes } from 'pages/CMS/components/CMSReports/routesCMSCompareReports';
import { manageTransfersRoutes } from 'pages/ManageTransfers/routesManageTransfers';
import { AuctionAdminRoute, AuctionAdminAllReportRoute } from 'pages/AuctionAdmin/routesForAuctionAdmin';
import { serviceProviderSelectionManagement } from 'pages/ServiceProviderSelectionManagement/routesServiceProvider';
import { MarketMonitorAllReportRoutes } from 'pages/MarketMonitor/RoutesMarketMonitorAllReport';
import { ManageEmissionRoutes } from 'pages/Emissions/routesEmissions';
import { eventApplicationReportRoute } from 'pages/EAReport/routesEAReport';
import { ManageTrueUpsRoutes } from 'pages/TrueUps/routesTrueUps';

const LandingPageKnown = Loadable(
  lazy(() =>
    import('pages/LandingPageKnown').then((module) => ({
      default: module.LandingPageKnown,
    })),
  ),
);
const LandingPageUnknown = Loadable(
  lazy(() =>
    import('pages/LandingPageUnknown').then((module) => ({
      default: module.LandingPageUnknown,
    })),
  ),
);
const CreateUserProfile = Loadable(
  lazy(() =>
    import('pages/CreateUserProfile').then((module) => ({
      default: module.CreateUserProfile,
    })),
  ),
);
const PageNotFound = Loadable(
  lazy(() =>
    import('pages/PageNotFound').then((module) => ({
      default: module.PageNotFound,
    })),
  ),
);
const UserHome = Loadable(
  lazy(() =>
    import('pages/UserHome').then((module) => ({
      default: module.UserHome,
    })),
  ),
);
const ConfigurationSettings = Loadable(
  lazy(() =>
    import('pages/ConfigurationSettings').then((module) => ({
      default: module.ConfigurationSettings,
    })),
  ),
);
const PrivilegeManagement = Loadable(
  lazy(() =>
    import('pages/PrivilegeManagement').then((module) => ({
      default: module.PrivilegeManagement,
    })),
  ),
);
const SystemRoles = Loadable(
  lazy(() =>
    import('pages/PrivilegeManagement/components/SystemRoles').then((module) => ({
      default: module.SystemRoles,
    })),
  ),
);

const RoleDetails = Loadable(
  lazy(() =>
    import('pages/PrivilegeManagement/components/RoleDetails').then((module) => ({
      default: module.RoleDetails,
    })),
  ),
);
const UserWithinRole = Loadable(
  lazy(() =>
    import('pages/PrivilegeManagement/components/UserWithinRole').then((module) => ({
      default: module.UserWithinRole,
    })),
  ),
);

const JurisdictionUserHome = Loadable(
  lazy(() =>
    import('pages/JurisdictionUserHome').then((module) => ({
      default: module.JurisdictionUserHome,
    })),
  ),
);

const ContactUs = Loadable(
  lazy(() =>
    import('pages/ContactUs').then((module) => ({
      default: module.ContactUs,
    })),
  ),
);

const UserProfile = Loadable(
  lazy(() =>
    import('pages/UserProfile').then((module) => ({
      default: module.UserProfile,
    })),
  ),
);

const EntityProfile = Loadable(
  lazy(() =>
    import('pages/EntityProfile').then((module) => ({
      default: module.EntityProfile,
    })),
  ),
);

const ChangeJurisdiction = Loadable(
  lazy(() =>
    import('pages/UserProfile/components/ChangeJurisdiction').then((module) => ({
      default: module.ChangeJurisdiction,
    })),
  ),
);

const CrossJurisdiction = Loadable(
  lazy(() =>
    import('pages/UserProfile/components/CrossJurisdiction').then((module) => ({
      default: module.CrossJurisdiction,
    })),
  ),
);

// -========jurisdiciton-user-details========

const JurisdictionUserActions = Loadable(
  lazy(() =>
    import('pages/UserProfile/components/JurisdictionUserActions').then((module) => ({
      default: module.JurisdictionUserActions,
    })),
  ),
);

// -========entity-user-details========

const JurisdictionEntityInformation = Loadable(
  lazy(() =>
    import('pages/JurisdictionEntityInformation').then((module) => ({
      default: module.JurisdictionEntityInformation,
    })),
  ),
);

// -========Market-Monitor========

const MarketMonitor = Loadable(
  lazy(() =>
    import('pages/MarketMonitor').then((module) => ({
      default: module.MarketMonitorPage,
    })),
  ),
);

// -========view-user-details========
const ViewUserDetails = Loadable(
  lazy(() =>
    import('pages/UserProfile/components/EditAndReviewUserDetails').then((module) => ({
      default: module.EditAndReviewUserDetail,
    })),
  ),
);

const ResetPassphraseLink = Loadable(
  lazy(() =>
    import('pages/ResetPassphraseLink').then((module) => ({
      default: module.ResetPassphraseLink,
    })),
  ),
);

const ResetPassphraseSecurityQuestions = Loadable(
  lazy(() =>
    import('pages/ResetPassphraseSecurityQuestions').then((module) => ({
      default: module.ResetPassphraseSecurityQuestions,
    })),
  ),
);

const CMSV2 = Loadable(
  lazy(() =>
    import('pages/CMS').then((module) => ({
      default: module.CMSV2,
    })),
  ),
);

const PermissionDenied = Loadable(
  lazy(() =>
    import('components/PermissionDenied').then((module) => ({
      default: module.PermissionDenied,
    })),
  ),
);

const StartEntityRegistration = Loadable(
  lazy(() =>
    import('pages/EntityRegistration/components/FormSteps').then((module) => ({
      default: module.FormSteps,
    })),
  ),
);

const EntityRegistration = Loadable(
  lazy(() =>
    import('pages/EntityRegistration').then((module) => ({
      default: module.EntityRegistration,
    })),
  ),
);

const EntityRevisionRequested = Loadable(
  lazy(() =>
    import('pages/EntityRevisionRequested').then((module) => ({
      default: module.RevisionRequested,
    })),
  ),
);
const EntityProfileInformation = Loadable(
  lazy(() =>
    import('pages/EntityProfileInformation').then((module) => ({
      default: module.EntityProfileInformation,
    })),
  ),
);
const JurisdictionEntityProfile = Loadable(
  lazy(() =>
    import('pages/JurisdictionEntityProfile').then((module) => ({
      default: module.JurisdictionEntityProfile,
    })),
  ),
);

const AccountDetails = Loadable(
  lazy(() =>
    import('pages/AccountDetails').then((module) => ({
      default: module.AccountDetails,
    })),
  ),
);

const WiringInstructions = Loadable(
  lazy(() =>
    import('pages/WiringInstructions').then((module) => ({
      default: module.WiringInstructions,
    })),
  ),
);

const AuctionReserveSale = Loadable(
  lazy(() =>
    import('pages/AuctionAndReserveSale').then((module) => ({
      default: module.AuctionAndReserveSale,
    })),
  ),
);

const FacilityDetails = Loadable(
  lazy(() =>
    import('pages/FacilityDetails').then((module) => ({
      default: module.FacilityDetails,
    })),
  ),
);

const FSAHome = Loadable(
  lazy(() =>
    import('pages/FSAUserHome').then((module) => ({
      default: module.FSAUserHome,
    })),
  ),
);

const NAICSCodeManagement = Loadable(
  lazy(() =>
    import('pages/NAICSCodeManagement').then((module) => ({
      default: module.NAICSCodeManagement,
    })),
  ),
);

const AddNAICSCode = Loadable(
  lazy(() =>
    import('pages/NAICSCodeManagement').then((module) => ({
      default: module.AddNAICSCode,
    })),
  ),
);

const EditNAICSCode = Loadable(
  lazy(() =>
    import('pages/NAICSCodeManagement').then((module) => ({
      default: module.EditNAICSCode,
    })),
  ),
);

const ViewNAICSCode = Loadable(
  lazy(() =>
    import('pages/NAICSCodeManagement').then((module) => ({
      default: module.ViewNAICSCode,
    })),
  ),
);

// COMPLIANCE INSTRUMENTS

const JurisdictionAccountList = Loadable(
  lazy(() => import('pages/JurisdictionAccountsList').then((module) => ({ default: module.JurisdictionAccountsList }))),
);

const JurisdictionAccountDetails = Loadable(
  lazy(() =>
    import('pages/JurisdictionAccountDetails').then((module) => ({ default: module.JurisdictionAccountDetails })),
  ),
);

const IssuanceRecords = Loadable(
  lazy(() => import('pages/IssuanceRecords').then((module) => ({ default: module.IssuanceRecords }))),
);

const OffsetIssuanceDetails = Loadable(
  lazy(() => import('pages/OffsetIssuanceDetails').then((module) => ({ default: module.OffsetIssuanceDetails }))),
);

const AllowanceIssuanceDetails = Loadable(
  lazy(() => import('pages/AllowanceIssuanceDetails').then((module) => ({ default: module.AllowanceIssuanceDetails }))),
);
const AllowanceIssuance = Loadable(
  lazy(() => import('pages/AllowanceIssuance').then((module) => ({ default: module.AllowanceIssuance }))),
);

const OffsetIssuance = Loadable(
  lazy(() => import('pages/OffsetIssuance').then((module) => ({ default: module.OffsetIssuance }))),
);
const Approvals = Loadable(lazy(() => import('pages/Approvals').then((module) => ({ default: module.Approvals }))));
const CorporateAssociations = Loadable(
  lazy(() =>
    import('pages/CorporateAssocations').then((module) => ({
      default: module.CorporateAssociations,
    })),
  ),
);

const AddCorporateAssociationGroup = Loadable(
  lazy(() =>
    import('pages/CorporateAssocations/components/AddNewGroup').then((module) => ({
      default: module.AddCorporateAssociationGroup,
    })),
  ),
);

const CAGdetails = Loadable(
  lazy(() =>
    import('pages/CorporateAssocations/components/CAGdetails').then((module) => ({
      default: module.CAGdetails,
    })),
  ),
);

const CreateLimitedExemptionBatch = Loadable(
  lazy(() =>
    import('pages/LimitedExemption').then((module) => ({
      default: module.CreateLimitedExemptionBatch,
    })),
  ),
);
const BatchList = Loadable(
  lazy(() =>
    import('pages/LimitedExemption').then((module) => ({
      default: module.BatchList,
    })),
  ),
);

const AddLimitedExemption = Loadable(
  lazy(() =>
    import('pages/LimitedExemption').then((module) => ({
      default: module.AddLimitedExemption,
    })),
  ),
);

const ExemptionList = Loadable(
  lazy(() =>
    import('pages/LimitedExemption').then((module) => ({
      default: module.ExemptionList,
    })),
  ),
);

const AddBudgetRecord = Loadable(
  lazy(() =>
    import('pages/AddBudgetRecord').then((module) => ({
      default: module.AddBudgetRecord,
    })),
  ),
);

const ManageJurisdictionBudget = Loadable(
  lazy(() =>
    import('pages/ManageJurisdictionBudget').then((module) => ({
      default: module.ManageJurisdictionBudget,
    })),
  ),
);

const ManagePurchaseLimit = Loadable(
  lazy(() =>
    import('pages/ManagePurchaseLimit').then((module) => ({
      default: module.ManagePurchaseLimit,
    })),
  ),
);

const BatchTransfersList = Loadable(
  lazy(() =>
    import('pages/ManageBatchTransfer').then((module) => ({
      default: module.BatchTransfersList,
    })),
  ),
);
const ManageBatchTransfer = Loadable(
  lazy(() =>
    import('pages/ManageBatchTransfer').then((module) => ({
      default: module.ManageBatchTransfer,
    })),
  ),
);

const ManageCronJobs = Loadable(
  lazy(() =>
    import('pages/ManageCronJobs').then((module) => ({
      default: module.ManageCronJobs,
    })),
  ),
);

const HoldingLimitsList = Loadable(
  lazy(() =>
    import('pages/ManageHoldingLimits').then((module) => ({
      default: module.HoldingLimitsList,
    })),
  ),
);

const ManageHoldingLimit = Loadable(
  lazy(() =>
    import('pages/ManageHoldingLimits').then((module) => ({
      default: module.ManageHoldingLimit,
    })),
  ),
);

const JurisdictionTransfersList = Loadable(
  lazy(() =>
    import('pages/ManageAdministrativeTransfers').then((module) => ({
      default: module.TransfersList,
    })),
  ),
);

const ManageJurisdictionTransfers = Loadable(
  lazy(() =>
    import('pages/ManageAdministrativeTransfers').then((module) => ({
      default: module.ManageTransfer,
    })),
  ),
);

const JurisdictionTransferDetails = Loadable(
  lazy(() =>
    import('pages/ManageAdministrativeTransfers').then((module) => ({
      default: module.TransferDetails,
    })),
  ),
);

const TransferDetails = Loadable(
  lazy(() =>
    import('pages/TransferDetails').then((module) => ({
      default: module.TransferDetails,
    })),
  ),
);

const ManageHoldingLimitCheck = Loadable(
  lazy(() =>
    import('pages/ManageHoldingLimits').then((module) => ({
      default: module.HoldingLimitsCheck,
    })),
  ),
);

const EventListingPage = Loadable(
  lazy(() =>
    import('pages/EventCreationAndManagement/EventListingPage').then((module) => ({
      default: module.EventListingPage,
    })),
  ),
);

const EventApplicationListingPage = Loadable(
  lazy(() =>
    import('pages/EventApplicationJuri/EventApplicationListing').then((module) => ({
      default: module.EventListingPage,
    })),
  ),
);
const CreateEventApplication = Loadable(
  lazy(() =>
    import('pages/EventApplication/').then((module) => ({
      default: module.CreateEventApplication,
    })),
  ),
);

const CreateParticipatingJurisdiction = Loadable(
  lazy(() =>
    import('pages/CreateParticipatingJurisdiction').then((module) => ({
      default: module.CreateParticipatingJurisdiction,
    })),
  ),
);

const ManageJurisdictionStatus = Loadable(
  lazy(() =>
    import('pages/ManageJurisdictionStatus').then((module) => ({
      default: module.ManageJurisdictionStatus,
    })),
  ),
);

const EditEventApplication = Loadable(
  lazy(() =>
    import('pages/EventApplication').then((module) => ({
      default: module.EditEventApplication,
    })),
  ),
);

const GenerateFincServInstruction = Loadable(
  lazy(() =>
    import('pages/EventApplication').then((module) => ({
      default: module.GenerateFincServInstruction,
    })),
  ),
);

const ViewEventApplication = Loadable(
  lazy(() =>
    import('pages/EventApplication').then((module) => ({
      default: module.ViewEventApplication,
    })),
  ),
);

const routes = [
  {
    path: '/',
    element:
    /* Conflict Resolution Candidate
  localStorage.getItem("i18nextLng") && localStorage.getItem("jurisdiction") ? (
    <LandingPageKnown />
*/
      localStorage.getItem('i18nextLng') && localStorage.getItem('jurisdiction') ? (
        <LandingPageKnown />
      ) : (
        <LandingPageUnknown />
      ),
  },
  {
    path: '/budgets',
    children: [
      {
        path: ':id',
        element: <ManageJurisdictionBudget key="view-budget" />,
      },
      {
        path: ':id/propose',
        element: (
          <ManageJurisdictionBudget
            propose
            key="propose-budget"
          />
        ),
      },
      {
        path: ':id/add',
        element: <AddBudgetRecord />,
      },
    ],
  },
  {
    path: '/purchase-limits/:id',
    element: <ManagePurchaseLimit />,
  },
  {
    path: '/user-registration',
    element: <CreateUserProfile />,
  },
  {
    path: '/create-user-profile',
    element: <UserRegistration />,
  },
  {
    path: '/404',
    element: <PageNotFound />,
  },
  {
    path: '/home',
    element: (
      <RoleBasedGuard privId={'priv_btn_Home'}>
        <UserHome />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/start-entity-registration',
    element: (
      <RoleBasedGuard
        privId={'priv_btn_start_entity_registration'}
        entityRegistrationPage
      >
        <StartEntityRegistration />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/entity-registration',
    element: (
      <RoleBasedGuard
        privId={'priv_btn_entity_registration'}
        checkEntityAccessForUser
      >
        <EntityRegistration />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/entity-registration/:id',
    element: (
      <RoleBasedGuard
        privId={'priv_btn_entity_registration'}
        checkEntityAccessForId
      >
        <EntityRegistration />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/settings',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Configuration Settings'}>
        {' '}
        <ConfigurationSettings />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/privilege',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Privilege Management'}>
        {' '}
        <PrivilegeManagement />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/roles',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Privilege Management'}>
        {' '}
        <SystemRoles />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/copy-roles',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Privilege Management'}>
        {' '}
        <SystemRoles module="copyRole" />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/create-role-details',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Privilege Management'}>
        {' '}
        <RoleDetails type="add" />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/edit-role-details',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Privilege Management'}>
        {' '}
        <RoleDetails type="edit" />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/role-details',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Privilege Management'}>
        {' '}
        <RoleDetails />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/user-role',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Privilege Management'}>
        {' '}
        <UserWithinRole />{' '}
      </RoleBasedGuard>
    ),
  },

  {
    path: '/jurisdiction-user',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Search_Users'}>
        {' '}
        <JurisdictionUserHome />{' '}
      </RoleBasedGuard>
    ),
  },

  {
    path: '/jurisdiction-user/:section',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Search_Users'}>
        {' '}
        <JurisdictionUserHome />{' '}
      </RoleBasedGuard>
    ),
  },


  {
    path: '/market-monitor',
    element: (
      <RoleBasedGuard privId={'priv_lnk_market_monitor'}>
        <MarketMonitor />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/start-entity-registration',
    element: (
      <RoleBasedGuard privId={'priv_btn_start_entity_registration'}>
        <StartEntityRegistration />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/entity-registration',
    element: (
      <RoleBasedGuard privId={'priv_btn_entity_registration'}>
        <EntityRegistration />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/entity-registration/:id',
    element: (
      <RoleBasedGuard privId={'priv_btn_entity_registration'}>
        <EntityRegistration />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/settings',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Configuration Settings'}>
        <ConfigurationSettings />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/privilege',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Privilege Management'}>
        <PrivilegeManagement />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/roles',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Privilege Management'}>
        {' '}
        <SystemRoles />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/copy-roles',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Privilege Management'}>
        {' '}
        <SystemRoles module="copyRole" />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/create-role-details',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Privilege Management'}>
        {' '}
        <RoleDetails type="add" />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/edit-role-details',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Privilege Management'}>
        {' '}
        <RoleDetails type="edit" />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/role-details',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Privilege Management'}>
        {' '}
        <RoleDetails />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/user-role',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Privilege Management'}>
        {' '}
        <UserWithinRole />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/jurisdiction-user',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Search_Users'}>
        {' '}
        <JurisdictionUserHome />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/contact-us',
    element: (
      <RoleBasedGuard privId={''}>
        <ContactUs />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/user-profile',
    element: (
      <RoleBasedGuard
        privId={'priv_lnk_myprofile'}
        isHeader
      >
        <UserProfile />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/entity-profile/:id',
    element: (
      <RoleBasedGuard
        privId={''}
        isHeader
        checkAccess
      >
        <EntityProfile />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/entity-profile/:id/wiring/:accountId/:action',
    element: (
      <RoleBasedGuard
        privId={'priv_lnk_wiring_CWI'}
        isHeader
      >
        <WiringInstructions />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/entity-profile/:id/account/:accountId',
    element: (
      <RoleBasedGuard
        privId={''}
        isHeader
      >
        <AccountDetails />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/reset-password/*',
    element: (
      <RoleBasedGuard
        privId={'priv_lnk_changePassphrase'}
        isHeader
      >
        <ResetPassphraseLink />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/reset-password-security-questions/*',
    /* Conflict Resolution Candidate
    element: <RoleBasedGuard privId={""}><ResetPassphraseSecurityQuestions /></RoleBasedGuard>,
 */
    element: <ResetPassphraseSecurityQuestions />,
  },
  {
    path: '/view-user-details',
    element: (
      <RoleBasedGuard privId={''}>
        <ViewUserDetails />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/change-jurisdiction',
    element: (
      <RoleBasedGuard privId={''}>
        <ChangeJurisdiction />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/cross-jurisdiction',
    element: (
      <RoleBasedGuard privId={''}>
        <CrossJurisdiction />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/jurisdiction-user-actions/*',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Jurisdiction_User_Actions'}>
        {' '}
        <JurisdictionUserActions />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/jurisdiction-entity-actions/:id',
    element: (
      <RoleBasedGuard privId={'priv_lnk_juri_entity_actions'}>
        {' '}
        <JurisdictionEntityInformation />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/entity-revision-requested/:id',
    element: (
      <RoleBasedGuard
        privId={'priv_lnk_entity_revision_requested'}
      >
        {' '}
        <EntityRevisionRequested />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/content-management',
    element: (
      <RoleBasedGuard privId={'priv_lnk_Content_Management_System'}>
        {' '}
        <CMSV2 />{' '}
      </RoleBasedGuard>
    ),
  },
  // {
  //   path: "/jurisdiction-entity-actions/:id",
  //   element: (
  //     <RoleBasedGuard privId={""}>
  //       {" "}
  //       <JurisdictionEntityInformation />{" "}
  //     </RoleBasedGuard>
  //   ),
  // },
  {
    path: '/entity-revision-requested/:id',
    element: (
      <RoleBasedGuard privId={'priv_lnk_entity_revision_requested'}>
        <EntityRevisionRequested />
      </RoleBasedGuard>
    ),
  },
  {
    /* Conflict Resolution Candidate
    path: "*",
    element: <Navigate to="/404" replace />,
  },
  {
    path: "/permissiondenied",
    element: <PermissionDenied />,
  },
 */
    path: '/entity-profile-information/:id',
    element: (
      <RoleBasedGuard privId={''}>
        {' '}
        <EntityProfileInformation />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/jurisdiction-entity-profile/:id',
    element: (
      <RoleBasedGuard privId={''}>
        {' '}
        <JurisdictionEntityProfile />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/auction-reserve-sale',
    element: (
      <RoleBasedGuard
        privId={''}
        checkAccessForAuction
      >
        <AuctionReserveSale />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/jurisdiction-entity-profile/:id',
    element: (
      <RoleBasedGuard privId={''}>
        {' '}
        <JurisdictionEntityProfile />{' '}
      </RoleBasedGuard>
    ),
  },
  {
    path: '/auction-reserve-sale',
    element: (
      <RoleBasedGuard privId={''}>
        <AuctionReserveSale />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/fsa-home',
    element: (
      <RoleBasedGuard privId={'priv_lnk_fsa_home_reports'}>
        <FSAHome />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/naics-code',
    children: [
      {
        index: true,
        element: (
          <RoleBasedGuard privId={'priv_lnk_NAICS_Code'}>
            {' '}
            <NAICSCodeManagement />{' '}
          </RoleBasedGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <RoleBasedGuard privId={'priv_lnk_NAICS_Code_add_edit'}>
            {' '}
            <AddNAICSCode />{' '}
          </RoleBasedGuard>
        ),
      },
      {
        path: 'edit/:id',
        element: (
          <RoleBasedGuard privId={'priv_lnk_NAICS_Code_add_edit'}>
            {' '}
            <EditNAICSCode />{' '}
          </RoleBasedGuard>
        ),
      },
      {
        path: 'view/:id',
        element: (
          <RoleBasedGuard privId={'priv_lnk_NAICS_Code'}>
            {' '}
            <ViewNAICSCode />{' '}
          </RoleBasedGuard>
        ),
      },
    ],
  },

  //  ************   COMPLLIANCE INSTRUMENTS

  {
    path: '/jurisdiction-accounts',
    element: (
      <RoleBasedGuard privId={''}>
        <JurisdictionAccountList />
      </RoleBasedGuard>
    ),
  },

  {
    path: '/jurisdiction-account/:acc',
    element: (
      <RoleBasedGuard privId={''}>
        <JurisdictionAccountDetails />
      </RoleBasedGuard>
    ),
  },

  {
    path: '/allowance-issuance/:acc',
    element: (
      <RoleBasedGuard privId={'priv_lnk_allowance_issuance'}>
        <AllowanceIssuance />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/offset-issuance/:acc',
    element: (
      <RoleBasedGuard privId={'priv_lnk_offset_issuance'}>
        <OffsetIssuance />
      </RoleBasedGuard>
    ),
  },
  // edit offset proposed
  {
    path: '/offset-issuance/:acc/:transferId/:complianceInstrumentId',
    element: (
      <RoleBasedGuard privId={'priv_lnk_offset_issuance'}>
        <OffsetIssuance />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/allowance-issuance/:acc/:transferId/:complianceInstrumentId',
    element: (
      <RoleBasedGuard privId={'priv_lnk_offset_issuance'}>
        <AllowanceIssuance />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/allowance-issuance-details/:id',
    element: (
      <RoleBasedGuard privId={'priv_lnk_issuance_acc'}>
        <AllowanceIssuanceDetails />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/offset-issuance-details/:id',
    element: (
      <RoleBasedGuard privId={'priv_lnk_issuance_acc'}>
        <OffsetIssuanceDetails />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/approvals',
    element: (
      <RoleBasedGuard privId={'priv_lnk_issuance_acc'}>
        <Approvals />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/issuance-records',
    element: (
      <RoleBasedGuard privId={'priv_lnk_issuance_acc'}>
        <IssuanceRecords />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/account-application-checklist-form/:id',
    element: <Document downloadChecklist />,
  },
  {
    path: '/account-application-attestation-form/:id',
    element:
      <RoleBasedGuard privId={'priv_account_application_attestation_form'}>
        <Document downloadAttestation />
      </RoleBasedGuard>,
  },
  {
    path: '/permissiondenied',
    element: <PermissionDenied />,
  },
  {
    path: '/associate-retired-facility/:id',
    element: (
      <RoleBasedGuard
        privId={''}
        isHeader
      >
        <AssociateRetiredFacility />
      </RoleBasedGuard>
    ),
  },

  /** -----  ------ */

  // keep this path last
  {
    path: '/entity-profile/:entityId/facility-profile-details/:id',
    element: (
      <RoleBasedGuard
        privId={''}
        isHeader
        checkAccessForFacility
      >
        <FacilityDetails />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/limited-exemptions',
    children: [
      {
        index: true,
        element: (
          <RoleBasedGuard privId={''}>
            <ExemptionList />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'batches/create',
        element: (
          <RoleBasedGuard privId={''}>
            <CreateLimitedExemptionBatch />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'batches',
        element: (
          <RoleBasedGuard privId={''}>
            <BatchList />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'batch/:id',
        element: (
          <RoleBasedGuard privId={''}>
            <AddLimitedExemption />
          </RoleBasedGuard>
        ),
      },
    ],
  },

  {
    path: 'batch-transfer',
    children: [
      {
        index: true,
        element: (
          <RoleBasedGuard privId={''}>
            <BatchTransfersList />
          </RoleBasedGuard>
        ),
      },
      {
        path: ':id',
        element: (
          <RoleBasedGuard privId={''}>
            <ManageBatchTransfer />
          </RoleBasedGuard>
        ),
      },
    ],
  },
  {
    path: 'cron-jobs',
    element: (
      <RoleBasedGuard privId={''}>
        <ManageCronJobs />
      </RoleBasedGuard>
    ),
  },
  {
    path: 'transfer-detail',
    children: [
      {
        path: ':transferId',
        element: (
          <RoleBasedGuard privId={''}>
            <TransferDetails />
          </RoleBasedGuard>
        ),
      },
      {
        path: ':transferId/:entityId',
        element: (
          <RoleBasedGuard privId={''}>
            <TransferDetails />
          </RoleBasedGuard>
        ),
      },
    ],
  },

  { ...exchangeContractCodeRoutes },

  { ...offsetManagementRoutes },

  { ...manageEntityTransfersRoutes },

  { ...modifiedRecordsForTransferRoutes },

  { ...eventCreationAndManagementRoutes },

  { ...financialServicesDeliveryInstructions },
  { ...setUpInitialUsersRoutes },

  { ...eventCreationAndManagementRoutes },

  { ...setUpJurisdictionAccountsRoutes },

  { ...manageJurisdictionAccountsRoutes },

  { ...eventApplicationManagementRoutes },

  { ...reportRoutes },

  { ...eventApplicationRoutes },

  { ...AllReports },

  { ...EARReports },

  { ...EventADER },

  { ...EventParticipantReport },

  { ...QualifiedBiddersReconciliationReport },

  { ...BidLimitationsReconciliationReport },

  { ...EntityUserRelationshipsReconciliationReport },

  { ...FSAAllReportsRoutes },

  { ...IndividualUsersAllReportsRoutes },

  { ...RegistryEntityFacilityReport },

  { ...CMSReportsRoutes },

  { ...EntityLegalOpNameChangeReport },

  { ...manageTransfersRoutes },

  { ...FacilityGHGIdentifierChangeReport },

  { ...FacilityLegalOpNameChangeReport },

  { ...WiringInstructionsStatusReport },

  { ...ConsignmentWiringInstructionsReport },

  { ...FSAReportRoute },

  { ...AuctionAdminRoute },

  { ...serviceProviderSelectionManagement },

  { ...ConsignmentWiringInstructionsReportAsync },

  { ...MarketMonitorAllReportRoutes },

  { ...AuctionAdminAllReportRoute },

  { ...ManageEmissionRoutes },

  { ...eventApplicationReportRoute },

  { ...ManageTrueUpsRoutes },

  { ...FacilityEmissionsReport },

  {
    path: 'holding-limits',
    children: [
      {
        index: true,
        element: (
          <RoleBasedGuard privId={''}>
            <HoldingLimitsList />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'checks',
        element: (
          <RoleBasedGuard privId={''}>
            <ManageHoldingLimitCheck />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'checks/:type/:id',
        element: (
          <RoleBasedGuard privId={''}>
            <ManageHoldingLimitCheck isHoldingLimitsCheckDetails />
          </RoleBasedGuard>
        ),
      },
      {
        path: ':id/:mode',
        element: (
          <RoleBasedGuard privId={''}>
            <ManageHoldingLimit />
          </RoleBasedGuard>
        ),
      },
    ],
  },
  {
    path: '/administrative-transfers',
    children: [
      {
        index: true,
        element: (
          <RoleBasedGuard privId={''}>
            <JurisdictionTransfersList />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <RoleBasedGuard privId={''}>
            <ManageJurisdictionTransfers />
          </RoleBasedGuard>
        ),
      },
      {
        path: ':id',
        element: (
          <RoleBasedGuard privId={''}>
            <JurisdictionTransferDetails />
          </RoleBasedGuard>
        ),
      },
    ],
  },
  {
    path: '/replenishment-transfers',
    children: [
      {
        path: 'add/:accountId',
        element: (
          <RoleBasedGuard privId={''}>
            <ManageJurisdictionTransfers isReplenishment />
          </RoleBasedGuard>
        ),
      },
      {
        path: ':id',
        element: (
          <RoleBasedGuard privId={''}>
            <JurisdictionTransferDetails isReplenishment />
          </RoleBasedGuard>
        ),
      },
    ],
  },
  // keep this path last
  {
    path: '*',
    element: (
      <Navigate
        to="/404"
        replace
      />
    ),
  },
  {
    path: '/facility-form/:id',
    element: (
      <RoleBasedGuard
        privId={'priv_lnk_facility_form'}
        isHeader
      >
        <Facilityform />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/corporate-associations',
    element: (
      <RoleBasedGuard privId={'priv_lnk_CAG'}>
        <CorporateAssociations />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/corporate-associations/add',
    element: (
      <RoleBasedGuard privId={'priv_lnk_CAG_add_edit'}>
        <AddCorporateAssociationGroup />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/corporate-associations/:id',
    element: (
      <RoleBasedGuard privId={'priv_lnk_CAG'}>
        <CAGdetails />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/event-creation-and-management',
    element: (
      <RoleBasedGuard privId={'priv_lnk_event_applications_create_edit'}>
        <EventListingPage />
      </RoleBasedGuard>
    ),
  },

  {
    path: '/event-applications-creation-and-management/:id/create',
    element: (
      <RoleBasedGuard
        privId={'priv_lnk_event_applications_only_create'}
        isHeader
      >
        <CreateEventApplication />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/event-applications-creation-and-management/:id/details/:applicationId',
    element: (
      <RoleBasedGuard
        privId={'priv_lnk_event_applications_details'}
        isHeader
      >
        <ViewEventApplication />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/event-applications-creation-and-management/:id/edit/:applicationId',
    element: (
      <RoleBasedGuard
        privId={'priv_lnk_event_applications_create_edit'}
        isHeader
      >
        <EditEventApplication />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/event-applications-creation-and-management/:id/generate/:applicationId',
    element: (
      <RoleBasedGuard
        privId={'priv_lnk_event_applications_create_edit'}
        isHeader
      >
        <GenerateFincServInstruction />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/onboard-new-jurisdiction',
    element: (
      <RoleBasedGuard privId={''}>
        <CreateParticipatingJurisdiction />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/manage-jurisdiction-details/:jurisdictionId',
    element: (
      <RoleBasedGuard privId={''}>
        <CreateParticipatingJurisdiction isViewScreen />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/manage-jurisdiction-details/:jurisdictionId/edit',
    element: (
      <RoleBasedGuard privId={''}>
        <CreateParticipatingJurisdiction isViewScreen isEditMode />
      </RoleBasedGuard>
    ),
  },
  {
    path: '/manage-jurisdiction-status/:id',
    element: (
      <RoleBasedGuard privId={''}>
        <ManageJurisdictionStatus />
      </RoleBasedGuard>
    ),
  },
  // {
  //   path: "/fsa-report",
  //   element: (
  //     <RoleBasedGuard
  //       privId={""}
  //     >
  //       {" "}
  //      <FSAReport/>{" "}
  //     </RoleBasedGuard>
  //   ),
  // },
  // {
  //   path: "/preview",
  //   element: (
  //     <RoleBasedGuard privId={""}>
  //       <AAHUCDetailsPreview />
  //     </RoleBasedGuard>
  //   ),
  // },
];

export default routes;
