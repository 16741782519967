import {
  Box,
  Typography,
  Container,
  Grid,
  Button,

  FormControlLabel,
  Checkbox,
  FormGroup,
  MenuItem,
  ListItemText,
  Select,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { FormCard, GridTextfield } from 'components';
import { useTranslation } from 'react-i18next';
import {
  formatDate,
  ShowInlineAddressForTable,
  ROLE_TYPES,
  usersTable,
  getCurrentPageName,
  t,
  /* Conflict Resolution Candidate
    getCurrentORGID,
   */
} from 'utils/constants';
import { useForm, FormProvider } from 'react-hook-form';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { MUITableGrid } from 'components/MUITableGrid';
import { getSearchUserDetails } from 'store/userSlice';
import { PrivilegeHeader } from '../PrivilegeHeader';

import { CustomizeTextfield } from '../../../../components/CustomizeTextfield';
import { RoleDetailBoxCard } from '../RoleDetailBoxCard';
import Style from '../../styles';

function SelectRenderValue(selected, themeColor, t1) {
  // const { t: t1 } = useTranslation(getCurrentPageName());

  if (selected) {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        <Typography component="div" key={selected} sx={Style.inputStyle}>
          <Box sx={{ ml: 0.5 }}>{selected}</Box>
        </Typography>
      </Box>
    );
  }
  return t(t1, 'PM_MENU_SELECT_ROLE');
}

function createData(id, firstname, lastname, organization, email, userstatus, userrole) {
  return { id, firstname, lastname, organization, email, userstatus, userrole };
}

const rows = [
  createData('1', 'FirstName0123', 'LastName0123', 'Org0123', 'FirstLast0123@Org.org', 'Active', 'Admin'),
  createData('2', 'FirstName0123', 'LastName0123', 'Org0123', 'FirstLast0123@Org.org', 'In Active', 'Guest'),
  createData('3', 'FirstName0123', 'LastName0123', 'Org0123', 'FirstLast0123@Org.org', 'Active', 'Admin'),
  createData('4', 'FirstName0123', 'LastName0123', 'Org0123', 'FirstLast0123@Org.org', 'In Active', 'Guest'),
  createData('5', 'FirstName0123', 'LastName0123', 'Org0123', 'FirstLast0123@Org.org', 'Active', 'Admin'),
  createData('6', 'FirstName0123', 'LastName0123', 'Org0123', 'FirstLast0123@Org.org', 'In Active', 'Guest'),
  createData('7', 'FirstName0123', 'LastName0123', 'Org0123', 'FirstLast0123@Org.org', 'Active', 'Admin'),
  createData('8', 'FirstName0123', 'LastName0123', 'Org0123', 'FirstLast0123@Org.org', 'In Active', 'Guest'),
  createData('9', 'FirstName0123', 'LastName0123', 'Org0123', 'FirstLast0123@Org.org', 'Active', 'Admin'),
  createData('10', 'FirstName0123', 'LastName0123', 'Org0123', 'FirstLast0123@Org.org', 'In Active', 'Guest'),
  createData('11', 'FirstName0123', 'LastName0123', 'Org0123', 'FirstLast0123@Org.org', 'Active', 'Admin'),
  createData('12', 'FirstName0123', 'LastName0123', 'Org0123', 'FirstLast0123@Org.org', 'In Active', 'Guest'),
  createData('13', 'FirstName0123', 'LastName0123', 'Org0123', 'FirstLast0123@Org.org', 'Active', 'Admin'),
];

function RoleDetails() {
  // =========================consts================================
  const { searchUserDetails, searchPendingUserDetails } = useSelector(({ user }) => user);
  const { selectedJurisdiction, Jurisdictions } = useSelector(({ jurisdiction }) => jurisdiction);
  const { roleDetails, role, roles, addRole, postRole, loading, Pages } = useSelector(({ privilege }) => privilege);

  const dispatch = useDispatch();

  const userRows = [];

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const { t: t1 } = useTranslation(getCurrentPageName());
  const theme = useTheme();
  const themeColor = theme?.palette?.primary?.main;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const {
    filledData,
    submitted,
    formSteps: { isEditField, activeStep },
  } = useSelector(({ createUserProfile }) => createUserProfile);

  // =====================states==========================================

  const [Userrows, setUserrows] = useState([]);
  // =======================functions=====================================
  const getSearchedUserList = (searchPending = false, filters = {}) => {
    const data = {};
    /* Conflict Resolution Candidate
        data.OrgID = selectedJurisdiction || getCurrentORGID();
     */
    data.OrgID = selectedJurisdiction || localStorage.getItem('jurisdiction');
    // data.last_key = searchPending ? searchUserLastKey[currentPage] || null : searchUserPendingLastKey[currentPage] || null;
    if (searchPending) {
      data.search_pending = true;
    }
    // console.log(data, "This is payload inside UserWithinRole.js");
    dispatch(getSearchUserDetails(data));
  };

  // =====================useEffects==========================================

  useEffectOnce(() => {
    getSearchedUserList();
    getSearchedUserList(true);
  }, [selectedJurisdiction]);

  useEffect(() => {
    searchUserDetails?.items?.map((item) => {
      const jurisName = Jurisdictions.find((each) => each.OrgID === item?.OrgID);
      userRows.push({
        id: item?.UID?.split('#')[1],
        Name: item?.Name,
        FamilyName: item?.FamilyName,
        jurisdiction: jurisName?.OrgName,
        Email: item?.Email,
        birthDate: formatDate(item?.BirthDate),
        UserStatus: item?.UserAccountStatus,
        RoleName: item?.RoleName,
        AccountStatus: item?.ApplicantAccountStatus,
        ReferenceCode: item?.RoleName,
        Employer: item?.Employer,
        UserID: item?.Username,
        OrgID: item?.OrgID,
        RequestStatus: item?.RequestStatus,
        RoleType: item?.RoleName,
        RegStartDate: formatDate(item?.RegistrationDate),
        PhysicalAddress: ShowInlineAddressForTable(item, t, t1),
      });
      return Userrows;
    });
    setUserrows(userRows);
  }, [searchUserDetails]);

  return (
    <Container maxWidth="xl" sx={Style.spacing}>
      <Grid container spacing={2.5}>
        <PrivilegeHeader />
        <Grid item sm={12}>
          <FormProvider {...methods}>
            <Box component="form" noValidate autoComplete="off" className="remove-margin">
              <FormCard indicateRequired={false}>
                <Typography variant="h6">{t(t1, 'PM_H6_SEARCH')}</Typography>
                <Grid container spacing={0}>
                  <Grid item sm={3}>
                    <Typography variant="label">{t(t1, 'PM_LBL_ROLE_TYPES')}</Typography>
                    <FormGroup sx={{ marginLeft: '5px !important' }}>
                      {ROLE_TYPES?.map((option) => (
                        // eslint-disable-next-line react/jsx-key
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: '#d7d7d7',
                                '&.Mui-checked': {
                                  color: '#283459',
                                },
                              }}
                            />
                          }
                          label={t(t1, option?.title)}
                        />
                      ))}
                    </FormGroup>
                  </Grid>
                  <Grid item sm={5}>
                    <div className="select-box-main">
                      <Typography variant="label">{t(t1, 'PM_LBL_ROLES')}</Typography>
                      <Select
                        sx={Style.formField}
                        className="selectbox-custom"
                        renderValue={(selected) => SelectRenderValue(selected, themeColor, t1)}
                        displayEmpty
                        MenuProps={MenuProps}
                      >
                        <MenuItem>{t(t1, 'JURISDICTION_USER')}</MenuItem>
                        <MenuItem value="Registrar" sx={{ p: 0 }}>
                          <Checkbox
                            sx={{
                              color: '#d7d7d7',
                              '&.Mui-checked': {
                                color: '#283459',
                              },
                            }}
                          />
                          <ListItemText primary={'Registrar'} />
                        </MenuItem>
                        <MenuItem value="Authority" sx={{ p: 0 }}>
                          <Checkbox
                            sx={{
                              color: '#d7d7d7',
                              '&.Mui-checked': {
                                color: '#283459',
                              },
                            }}
                          />
                          <ListItemText primary={'Authority'} />
                        </MenuItem>
                        <MenuItem value="Administrator" sx={{ p: 0 }}>
                          <Checkbox
                            sx={{
                              color: '#d7d7d7',
                              '&.Mui-checked': {
                                color: '#283459',
                              },
                            }}
                          />
                          <ListItemText primary={'Administrator'} />
                        </MenuItem>
                        <MenuItem value="Jurisdiction Program Staff" sx={{ p: 0 }}>
                          <Checkbox
                            sx={{
                              color: '#d7d7d7',
                              '&.Mui-checked': {
                                color: '#283459',
                              },
                            }}
                          />
                          <ListItemText primary={'Jurisdiction Program Staff'} />
                        </MenuItem>
                        <MenuItem>{t(t1, 'INDIVIDUAL_USER')}</MenuItem>
                        <MenuItem value="Individual User" sx={{ p: 0 }}>
                          <Checkbox
                            sx={{
                              color: '#d7d7d7',
                              '&.Mui-checked': {
                                color: '#283459',
                              },
                            }}
                          />
                          <ListItemText primary={'Individual User'} />
                        </MenuItem>
                        <MenuItem value="Account Representative" sx={{ p: 0 }}>
                          <Checkbox
                            sx={{
                              color: '#d7d7d7',
                              '&.Mui-checked': {
                                color: '#283459',
                              },
                            }}
                          />
                          <ListItemText primary={'Account Representative'} />
                        </MenuItem>
                        <MenuItem value="Alternative Account Representative" sx={{ p: 0 }}>
                          <Checkbox
                            sx={{
                              color: '#d7d7d7',
                              '&.Mui-checked': {
                                color: '#283459',
                              },
                            }}
                          />
                          <ListItemText primary={'Alternative Account Representative'} />
                        </MenuItem>
                        <MenuItem value="Account Viewing Agent" sx={{ p: 0 }}>
                          <Checkbox
                            sx={{
                              color: '#d7d7d7',
                              '&.Mui-checked': {
                                color: '#283459',
                              },
                            }}
                          />
                          <ListItemText primary={'Account Viewing Agent'} />
                        </MenuItem>
                        <MenuItem>{t(t1, 'SERVICE_PROVIDER')}</MenuItem>
                        <MenuItem value="Market Monitor" sx={{ p: 0 }}>
                          <Checkbox
                            sx={{
                              color: '#d7d7d7',
                              '&.Mui-checked': {
                                color: '#283459',
                              },
                            }}
                          />
                          <ListItemText primary={'Market Monitor'} />
                        </MenuItem>
                        <MenuItem value="Financial Service Administrator Tech Admin/System Admins" sx={{ p: 0 }}>
                          <Checkbox
                            sx={{
                              color: '#d7d7d7',
                              '&.Mui-checked': {
                                color: '#283459',
                              },
                            }}
                          />
                          <ListItemText primary={'Financial Service Administrator Tech Admin/System Admin'} />
                        </MenuItem>
                        <MenuItem value="System Admin" sx={{ p: 0 }}>
                          <Checkbox
                            sx={{
                              color: '#d7d7d7',
                              '&.Mui-checked': {
                                color: '#283459',
                              },
                            }}
                          />
                          <ListItemText primary={'System Admin'} />
                        </MenuItem>
                        <MenuItem value="Event Admin" sx={{ p: 0 }}>
                          <Checkbox
                            sx={{
                              color: '#d7d7d7',
                              '&.Mui-checked': {
                                color: '#283459',
                              },
                            }}
                          />
                          <ListItemText primary={'Event Admin'} />
                        </MenuItem>
                      </Select>

                      <GridTextfield
                        customeStyle={{ fontWeight: '400 !important' }}
                        name={'search'}
                        placeholder={t(t1, `PM_PH_SEARCH_USER`)}
                        label={t(t1, 'PM_LBL_USER_PAGE')}
                      />
                    </div>
                  </Grid>
                  {/* <Grid item sm={4}>
                    <div className="custom-text-right">
                      <CustomizeTextfield
                        name={"searchPage"}
                        placeholder={t(t1, `PM_PH_ENTER_NAME`)}
                        label={t(t1, "PM_LBL_SEARCH_PAGE")}
                      />
                    </div>
                  </Grid> */}
                  <Grid item sm={12}>
                    <div className="bottom-btn">
                      <Button sx={{ mr: '10px' }} variant="outlined" className="reset">
                        {t(t1, 'PM_BTN_RESET')}
                      </Button>
                      <Button sx={{ '&:hover': { backgroundColor: '#4d8da4' } }} variant="contained">
                        {t(t1, 'PM_BTN_SEARCH')}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </FormCard>
            </Box>
          </FormProvider>
        </Grid>
        <RoleDetailBoxCard
          // setDefaultValues={setDefaultValues}
          roledetails={role}
          roles={roles}
          // selectValue={selectValue}
          // type={type}
          // setSelectValue={handleChangeRoleType}
          customeStyles={{ paddingTop: '0px !important', '& .MuiBox-root': { marginTop: '17px !important' } }}
        />
        <Grid item sm={12}>
          <div className="custom-tital">
            <Typography variant="h5">{t(t1, 'USERS')}</Typography>
          </div>
          <Box>
            <FormCard indicateRequired={false}>
              <Paper
                className="datatable-main"
                sx={{
                  width: '100%',
                  overflow: 'hidden',
                  '& .MuiDataGrid-toolbarContainer': {
                    padding: 0,
                    borderBottom: 0,
                  },
                }}
              >
                <MUITableGrid rows={Userrows} columns={usersTable(t1)} count={10} />
              </Paper>
            </FormCard>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default RoleDetails;
