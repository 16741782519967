import Box from '@mui/material/Box';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { t, getCurrentPageName } from 'utils/constants';
import { setDialogMessage } from 'store/commonSlice';
import { DialogMessage } from 'components';
import { useNavigate } from 'react-router-dom';
import { CustomizeButton } from 'components/CustomizeButton';

import { setFormValueOfStep, setNextStepSelect, setSubmittedOfSelectedStep } from 'store/entityApplicationSlice';
import { ButtonFill } from './ButtonFill';

export default function FormActions({
                                      stepNumber,
                                      handleSubmit,
                                      continueDisabled = false,
                                      pageName = '',
                                      handleExit,
                                      onExitClick,
                                      onCancelExit,
                                      page,
                                    }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { steps, EditAccess } = useSelector(({ entityApplication }) => entityApplication);

  const { dialogMessage } = useSelector(({ common }) => common);

  const handleCancel = () => {
    dispatch(setDialogMessage(true));
    setShowModal(true);
    onExitClick?.();
  };
  const handleCancelModal = () => {
    navigate('/home');
  };


  const handleRegister = (data) => {

    dispatch(setSubmittedOfSelectedStep(true));
    dispatch(setFormValueOfStep(data));
    if (handleSubmit) {
      handleSubmit(data);
    } else {
      dispatch(setNextStepSelect({ steps }));
    }
  };

  return (
    <Box
      sx={{
        mt: 5,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {EditAccess &&
        <Box sx={{ ml: 'auto' }}>
          <CustomizeButton
            customStyle={{ mr: 1 }}
            variant="outlined"
            csName="rc"
            label={t(t1, 'EXIT_APPLICATION')}
            name="priv_modal_Cancel"
            handleClick={handleCancel}
          />
          {
            // pageName === "ADD_FACILITY" ?
            // <ButtonFill
            //   saveDisabled={continueDisabled}
            //   name="priv_btn_Continue"
            //   stepNumber={stepNumber}
            //   handleRegister={handleRegister}
            //   styleSx={{ height:"40px"}}
            // >
            //   {
            //     t(t1,"ADD")
            //   }
            // </ButtonFill>
            // :
            <ButtonFill
              saveDisabled={continueDisabled}
              name="priv_btn_Continue"
              stepNumber={stepNumber}
              handleRegister={handleRegister}
              styleSx={{ height: '40px' }}
            >
              {
                t(t1, 'CONTINUE')
              }
            </ButtonFill>
          }
        </Box>
      }

      {(page !== 'JURISDICTION' && !EditAccess) &&
        <Box sx={{ ml: 'auto' }}>
          <CustomizeButton
            customStyle={{ mr: 1 }}
            variant="outlined"
            csName="rc"
            label={t(t1, 'EXIT_APPLICATION')}
            name="priv_modal_Cancel"
            handleClick={handleCancelModal}
          />
        </Box>
      }
      {dialogMessage && showModal ? (
        <DialogMessage
          borderColor="#ca2c1c"
          title={t(t1, 'EXIT_APPLICATION')}
          message1={t(t1, 'EXIT_APPLICATION_WARNING_CONTENT1')}
          message2={t(t1, 'EXIT_APPLICATION_WARNING_CONTENT2')}
          buttonMessage={t(t1, 'EXIT_APPLICATION_WARNING_CONTENT3')}
          handleModalClick={handleCancelModal}
          handleCancelCustom={onCancelExit}
        />
      ) : null}
    </Box>
  );
}
