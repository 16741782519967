import React from 'react';
import authRoles from 'core/auth/authRole';
import ManageJurisdictionBudget from './ManageJurisdictionBudget';

const ManageJurisdictionBudgetConfig = {
  route: {
    path: '/budgets',
    element: <ManageJurisdictionBudget />,
  },
  layout: {
    header: {
      display: true,
    },
    sidebar: {
      display: false,
    },
    footer: {
      display: true,
    },
  },
  auth: authRoles.onlyGuest,
};

export default ManageJurisdictionBudgetConfig;
