import { createSlice } from '@reduxjs/toolkit';
import { APIS, COMPONENT_NAME_EVENT_APPLICATION } from 'utils/constants';
import fetcher from 'utils/fetcher';

const initialState = {
  selectedEventApplicationDetail: { isLoading: false, data: {} },
  selectedEventApplicationList: { isLoading: false, data: [] },
  selectedEventApplicationHistory: { isLoading: false, data: [] },
};

const eventApplicationJuriSlice = createSlice({
  name: 'eventApplicationJuri',
  initialState,
  reducers: {
    setSelectedEventApplicationDetail: (state, action) => {
      state.selectedEventApplicationDetail = action.payload;
    },
    setSelectedEventApplicationlList: (state, action) => {
      state.selectedEventApplicationList = action.payload;
    },
    setSelectedEventApplicationlHistory: (state, action) => {
      state.selectedEventApplicationHistory = action.payload;
    },
  },
});

export default eventApplicationJuriSlice.reducer;
export const {
  setSelectedEventApplicationDetail,
  setSelectedEventApplicationlList,
  setSelectedEventApplicationlHistory,
} = eventApplicationJuriSlice.actions;


export function getEventApplicationList(jurisdictionId, PageName, eventId = '') {
  const pageDetails = { componentName: COMPONENT_NAME_EVENT_APPLICATION.EVENT_APPLICATION_VIEW, pageName: PageName };

  return (dispatch) => {
    dispatch(setSelectedEventApplicationlList({ isLoading: true }));

    fetcher(`${APIS.EVENT_APPLICATION_API}/${jurisdictionId}/event-applications?eventId=${eventId}&isArReport=false`, 'GET', {}, false, pageDetails)
      .then((response) => {
        dispatch(setSelectedEventApplicationlList({ isLoading: false, data: response.data }));
      })
      .catch((err) => {
        dispatch(setSelectedEventApplicationlList({ isLoading: false }));
      });
  };
}


export function getSelectedEventApplicationDetail(jurisdictionId, entityId, applicationId, PageName) {
  const pageDetails = { componentName: COMPONENT_NAME_EVENT_APPLICATION.EVENT_APPLICATION_VIEW, pageName: PageName };
  return (dispatch) => {
    dispatch(setSelectedEventApplicationDetail({ isLoading: true }));
    fetcher(`${APIS.EVENT_APPLICATION_API}/${jurisdictionId}/entity/${entityId}/event-application/${applicationId}`, 'GET', {}, false, pageDetails)
      .then((response) => {
        dispatch(setSelectedEventApplicationDetail({ isLoading: false, data: response.data }));
      })
      .catch((err) => {
        dispatch(setSelectedEventApplicationDetail({ isLoading: false }));
      });
  };
}


export function getSelectedEventApplicationHistroy(jurisdictionId, entityId, applicationId, PageName) {
  const pageDetails = { componentName: COMPONENT_NAME_EVENT_APPLICATION.EVENT_APPLICATION_VIEW, pageName: PageName };
  return (dispatch) => {
    dispatch(setSelectedEventApplicationlHistory({ isLoading: true }));
    fetcher(`${APIS.EVENT_APPLICATION_API}/${jurisdictionId}/entity/${entityId}/event-application/${applicationId}/history`, 'GET', {}, false, pageDetails)
      .then((response) => {
        dispatch(setSelectedEventApplicationlHistory({ isLoading: false, data: response.data }));
      })
      .catch((err) => {
        dispatch(setSelectedEventApplicationlHistory({ isLoading: false }));
      });
  };
}
