const Style = {
  welcome: {
    width: '1199px',
    height: '89px',
    padding: '20px 32px 20px 32px',
    backgroundcolor: 'rgba(255, 255, 255, 0)',
    boxsizing: 'borderBox',
    fontfamily: 'OpenSans-Light, Open Sans Light, Open Sans, sans - serif',
    fontweight: '200',
    color: `#333333`,
    textalign: 'left',
    lineheight: 'normal',
  },
  WelcomeText: {
    paddingTop: '40px',
  },
  JurisdictionText: {
    paddingTop: '40px',
    paddingBottom: '140px',
    '@media (max-width: 1023px)': {
      paddingBottom: '40px',
    },
  },
  WelcomeTextTital: {
    fontWeight: '200',
    color: '#333',
    fontSize: '36px',
    '& p': {
      margin: 0,
    },
  },
  WelcomeTextPara: {
    fontWeight: '400',
    color: '#333',
    fontSize: '20px',
    '& p': {
      margin: 0,
    },
  },
  noticebox: {
    background: 'rgba(128, 174, 190, 0.2)',
    width: '340px',
    height: '342px',
    overflow: 'auto',
    marginLeft: 'auto',
    borderTop: '3px solid #4d8da4',
    borderBottom: '3px solid #4d8da4',
    paddingRight: '30px',
    paddingLeft: '15px',
    '&::-webkit-scrollbar': {
      width: '12px',
      background: '#F5F5F5',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
      borderRadius: '10px',
      background: 'rgba(128, 174, 190, 0.2)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
      background: '#4d8da4',
    },

    '& h4': {
      color: '#005c7d',
      fontWeight: '700',
      fontSize: '14px',
      textAlign: 'left',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginBottom: '0px',
      borderBottom: '2px solid #4d8da4',
    },
    '& h5': {
      color: '#005c7d',
      fontWeight: '700',
      fontSize: '14px',
      textAlign: 'left',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginBottom: '0px',
      borderTop: '2px solid #4d8da4',
      borderBottom: '2px solid #4d8da4',
    },
    '& ul': {
      padding: '0px',
      paddingTop: '10px',
      paddingBottom: '10px',
      // borderBottom: "1px solid #4d8da4",
      '& li': {
        padding: '0px',
        fontSize: '13px',
      },
    },
    '@media (max-width: 1350px)': {
      width: '100%',
    },
    '@media (max-width: 899px)': {
      margin: '0 auto',
      width: '340px',
      display: 'block',
      marginTop: '15px',
    },
  },
  '& .LoginButton': {
    height: '40px',
  },
  '& .Forgotline': {
    marginBottom: '0px',
    '& a': {
      color: '#4d8da4',
      cursor: 'pointer',
      textDecoration: 'none',
      fontSize: '14PX',
      fontWeight: '400',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  MuiError: {
    margin: '0px',
    position: 'absolute',
  },
  ErrorText: {
    position: 'relative',
    '& div': {
      background: '#fff',
      borderRadius: '8px',
    },
    '& MuiOutlinedInputRoot': {
      background: '#fff',
    },
    '& p': {
      margin: 0,
    },
  },
};
export default Style;
