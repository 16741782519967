import { ENTITY_TRANSFER_TYPES } from 'utils/constants';

export const MET_COMPONENT = {
  TRANSFER_DETAIL: 'transferDetails',
  ACCOUNTS: 'accounts',
  TRANSFER_EVENT_HISTORY: 'transferEventHistory',
  PRICING_INFO: 'pricingInformation',
  EXCHANE_AGG_COMPO: 'exchangeAgreementTransferDetails',
  OTC_TRANSFER_DETAILS: 'otcTransferDetails',

  TRANSFERRING_ACCOUNT_DETAILS: 'transferringAccountDetails',
  RECEIVING_ACCOUNT_DETAILS: 'receivingAccountDetails',
  RECEIVING_ACCOUNT_DETAILS_PAYLOAD: 'receivingAccountDetailsPayload',
  PRICING_INFORMATION: 'pricingInformation',
  FIXED_PRICE: 'fixedPrice',
  COST_BASE_PLUS_MARGIN: 'costBasePlusMargin',
  OTHER_PRICING_METHOD: 'otherPricingMethod',
  COMPLIANCE_INSTRUMENTS: 'complianceInstruments',
  EXCHANGE_CONTRACT_DESC_CODE: 'exchangeAndContractDesciptionCodes',

  APPROVE_TRANSFER: 'approveTransfer',
  DENY_TRANSFER: 'denyTransfer',
  ACCEPT_TRANSFERS: 'acceptTransfer',
  DECLINE_TRANSFERS: 'declineTransfer',
  CANCEL_ENTITY_TRANSFER: 'cancelEntityTransfer',
};

export const MET_PAGES = {
  // transfer details pages

  COMPLIANCE_TRANSFER_DETAIL_PAGE: 'Page_ComplianceTransferDetails',
  CONSIGNMENT_TRANSFER_DETAIL_PAGE: 'Page_ConsignmentTransferDetails',
  AAHA_TRANSFER_DETAIL_PAGE: 'Page_AAHTransferDetails',
  VRT_TRANSFER_DETAIL_PAGE: 'Page_VRTransferDetails',
  LUHA_TRANSFER_DETAIL_PAGE: 'Page_LUHATransferDetails',
  OTC_GT_TRANSFER_DETAIL_PAGE: 'Page_OTCGT3TransferDetails',
  OTC_LT_TRANSFER_DETAIL_PAGE: 'Page_OTCLTE3TransferDetails',
  NOT_TO_ECSP_TRANSFER_DETAIL_PAGE: 'Page_GeneralExchangeNotToECSPTransferDetails',
  TO_ECSP_TRANSFER_DETAIL_PAGE: 'Page_ExchangeAgreementToECSPECHATransferDetails',

  // proposal pages

  PROPOSE_AAH_TRANSFER: 'Page_ProposeAAHTransfer',
  PROPOSE_COMPLIANCE_TRANSFER: 'Page_ProposeComplianceTransfer',
  PROPOSE_CONSIGNMENT_TRANSFER: 'Page_ProposeConsignmentTransfer',
  PROPOSE_ECSP_ECHA_TO_CLEARING_PROVIDER_TRANSFER: 'Page_ProposeECSPECHAToClearingProvider',
  PROPOSE_ECSP_ECHA_FROM_CLEARING_PROVIDER_TRANSFER: 'Page_ProposeECSPECHAToClearingProvider',
  PROPOSE_EXCHANGE_AGREEMENT_NOT_TO_ECSP_TRANSFER: 'Page_ProposeExchangeAgreementNotToECSPTransfer',
  PROPOSE_LUHA_TRANSFER: 'Page_ProposeLUHATransfer',
  PROPOSE_OTC_LTE3DAYS_TRANSFER: 'Page_ProposeOTCLTE3Transfer',
  PROPOSE_OTC_GT3DAYS_TRANSFER: 'Page_ProposeOTCGT3Transfer',
  PROPOSE_VRT_TRANSFER: 'Page_ProposeVRTransfer',
};

export const getPageNameFromTransfer = {
  [ENTITY_TRANSFER_TYPES.COMPLIANCE_TRANSFER]: MET_PAGES.COMPLIANCE_TRANSFER_DETAIL_PAGE,
  [ENTITY_TRANSFER_TYPES.CONSIGNMENT_TRANSFER]: MET_PAGES.CONSIGNMENT_TRANSFER_DETAIL_PAGE,
  [ENTITY_TRANSFER_TYPES.ANNUAL_ALLOCATION_TRANSFER_COMPLIANCE]: MET_PAGES.AAHA_TRANSFER_DETAIL_PAGE,
  [ENTITY_TRANSFER_TYPES.ANNUAL_ALLOCATION_TRANSFER_GENERAL]: MET_PAGES.AAHA_TRANSFER_DETAIL_PAGE,
  [ENTITY_TRANSFER_TYPES.ECSP_ECHA_TO_CLEARING_PROVIDER]: MET_PAGES.TO_ECSP_TRANSFER_DETAIL_PAGE,
  [ENTITY_TRANSFER_TYPES.ECSP_ECHA_FROM_CLEARING_PROVIDER]: MET_PAGES.TO_ECSP_TRANSFER_DETAIL_PAGE,
  [ENTITY_TRANSFER_TYPES.GENERAL_EXCHANGE_AGREEMENT_TRANSFER_NOT_TO_ECSP]: MET_PAGES.NOT_TO_ECSP_TRANSFER_DETAIL_PAGE,
  [ENTITY_TRANSFER_TYPES.GENERAL_OTC_LESS_THAN_3_DAYS]: MET_PAGES.OTC_LT_TRANSFER_DETAIL_PAGE,
  [ENTITY_TRANSFER_TYPES.GENERAL_OTC_GREATER_THAN_3_DAYS]: MET_PAGES.OTC_GT_TRANSFER_DETAIL_PAGE,
  [ENTITY_TRANSFER_TYPES.VOLUNTARY_RETIREMENT_TRANSFER]: MET_PAGES.VRT_TRANSFER_DETAIL_PAGE,
  [ENTITY_TRANSFER_TYPES.LIMITED_USE_HOLDING_ACCOUNT_WASHINGTON_ONLY]: MET_PAGES.LUHA_TRANSFER_DETAIL_PAGE,
};

export const getProposalPageName = {
  [ENTITY_TRANSFER_TYPES.COMPLIANCE_TRANSFER]: MET_PAGES.PROPOSE_COMPLIANCE_TRANSFER,
  [ENTITY_TRANSFER_TYPES.CONSIGNMENT_TRANSFER]: MET_PAGES.PROPOSE_CONSIGNMENT_TRANSFER,
  [ENTITY_TRANSFER_TYPES.ANNUAL_ALLOCATION_TRANSFER_COMPLIANCE]: MET_PAGES.PROPOSE_AAH_TRANSFER,
  [ENTITY_TRANSFER_TYPES.ANNUAL_ALLOCATION_TRANSFER_GENERAL]: MET_PAGES.PROPOSE_AAH_TRANSFER,
  [ENTITY_TRANSFER_TYPES.ECSP_ECHA_TO_CLEARING_PROVIDER]: MET_PAGES.PROPOSE_ECSP_ECHA_TO_CLEARING_PROVIDER_TRANSFER,
  [ENTITY_TRANSFER_TYPES.ECSP_ECHA_FROM_CLEARING_PROVIDER]: MET_PAGES.PROPOSE_ECSP_ECHA_TO_CLEARING_PROVIDER_TRANSFER,
  [ENTITY_TRANSFER_TYPES.GENERAL_EXCHANGE_AGREEMENT_TRANSFER_NOT_TO_ECSP]:
  MET_PAGES.PROPOSE_EXCHANGE_AGREEMENT_NOT_TO_ECSP_TRANSFER,
  [ENTITY_TRANSFER_TYPES.GENERAL_OTC_LESS_THAN_3_DAYS]: MET_PAGES.PROPOSE_OTC_LTE3DAYS_TRANSFER,
  [ENTITY_TRANSFER_TYPES.GENERAL_OTC_GREATER_THAN_3_DAYS]: MET_PAGES.PROPOSE_OTC_GT3DAYS_TRANSFER,
  [ENTITY_TRANSFER_TYPES.VOLUNTARY_RETIREMENT_TRANSFER]: MET_PAGES.PROPOSE_VRT_TRANSFER,
  [ENTITY_TRANSFER_TYPES.LIMITED_USE_HOLDING_ACCOUNT_WASHINGTON_ONLY]: MET_PAGES.PROPOSE_LUHA_TRANSFER,
};

export const fetchEchaTransferType = (ttype) => {
  if (ttype === ENTITY_TRANSFER_TYPES.ECSP_ECHA_TO_CLEARING_PROVIDER || ttype === ENTITY_TRANSFER_TYPES.ECSP_ECHA_FROM_CLEARING_PROVIDER) return ENTITY_TRANSFER_TYPES.ECSP_ECHA_TO_CLEARING_PROVIDER;
  return ttype;
};