import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { APIS, JURISDICTION_TRANSFER_TYPES } from 'utils/constants';
import { fetcherGet } from 'utils/fetcher';
import { setLoader } from 'store/commonSlice';
import { transformHoldingsRes, transformHoldingsResReplenishment } from 'utils/support';
import { COMPONENTS } from 'pages/ManageAdministrativeTransfers/constants';
import { initiateTransfer } from './administrativeTransferSlice';

const initialState = {
  initiateReplenishmentFlag: false,
  holdingsListReplenishment: { isLoading: false, data: [] },
  holdingsListAdministrative: { isLoading: false, data: [] },
  selectedHoldingDetails: [],
  accountDetailReplenishment: [],
  isTransferFromJurisdiction: false,
};

const ReplenishmentTransferSlice = createSlice({
  name: 'replenishmentTransfer',
  initialState,
  reducers: {
    setInitiateReplenishmentFlag: (state, action) => {
      state.initiateReplenishmentFlag = action.payload;
    },
    setHoldingsListReplenishment: (state, action) => {
      state.holdingsListReplenishment = action.payload;
    },
    setHoldingsListAdministrative: (state, action) => {
      state.holdingsListAdministrative = action.payload;
    },
    setSelectedHoldingDetails: (state, action) => {
      state.selectedHoldingDetails = action.payload;
    },
    setAccountDetailReplenishment: (state, action) => {
      state.accountDetailReplenishment = action.payload;
    },
    setIsTransferFromJurisdiction: (state, action) => {
      state.isTransferFromJurisdiction = action.payload;
    },
  },
});

export const {
  setInitiateReplenishmentFlag,
  setHoldingsListReplenishment,
  setHoldingsListAdministrative,
  setSelectedHoldingDetails,
  setAccountDetailReplenishment,
  setIsTransferFromJurisdiction,
} = ReplenishmentTransferSlice.actions;

export const getSelectedTransferHoldingsList =
  (accountId, isReplFromJurisdiction = false, onAdminTransferPage = {}) =>
    (dispatch) => {
      dispatch(setHoldingsListReplenishment({ isLoading: true, data: [] }));
      dispatch(setHoldingsListAdministrative({ isLoading: true, data: [] }));
      const pageDetails = { pageName: 'Page_ComplianceAccountDetails', componentName: 'holdings' };
      const resp = !isReplFromJurisdiction
        ? fetcherGet(`${APIS.GET_ENTITY_ACCOUNT_HOLDINGS.replace(':accountId', accountId)}`)
        : fetcherGet(`${APIS.HOLDINGS_API}/${accountId}`, {}, pageDetails);
      resp
        .then((response) => {
          dispatch(setHoldingsListReplenishment({
            isLoading: false,
            data: isReplFromJurisdiction ?
              transformHoldingsResReplenishment(response?.data)
                .filter(
                  ({
                     availableHoldingQuantity,
                     type,
                     vintage,
                     subType,
                   }) => BigInt(availableHoldingQuantity?.replaceAll(',', '') ?? '0') > 0n && type === 'Allowance' && vintage && !subType,
                )
              :
              transformHoldingsRes(response?.data)
                .filter(
                  ({
                     availableHoldingQuantity,
                     type,
                     vintage,
                     subType,
                   }) => BigInt(availableHoldingQuantity?.replaceAll(',', '') ?? '0') > 0n && type === 'Allowance' && vintage && !subType,
                ),
          }));
          const transformedHoldings = transformHoldingsRes(response?.data)
            .filter(
              ({ availableHoldingQuantity }) => BigInt(availableHoldingQuantity?.replaceAll(',', '') ?? '0') > 0n,
            );
          dispatch(setHoldingsListAdministrative({
            isLoading: false,
            data: transformedHoldings,
          }));
          if (!isEmpty(onAdminTransferPage)) {
            dispatch(
              initiateTransfer({
                ...onAdminTransferPage.accountDetails,
                entityUuid: onAdminTransferPage.entityUuid,
                accountId,
                transferType: JURISDICTION_TRANSFER_TYPES.ADMINISTRATIVE,
                holdings: transformedHoldings?.filter(({ quantity }) => BigInt(quantity.replaceAll(',', '')) > 0n),
              }),
            );
          }
        })
        .catch((error) => {
          dispatch(setHoldingsListReplenishment({ isLoading: false, data: [] }));
          dispatch(setHoldingsListAdministrative({ isLoading: false, data: [] }));
        });
    };

export const getReplenishmentAccountDetails = (fromJurisdiction) => (dispatch) => {
  const jurisdictionId = localStorage.getItem('jurisdictionID');
  dispatch(setLoader(true));
  const resp = fromJurisdiction
    ? fetcherGet(
      `${APIS.CLUSTER_TRANSFERS}/jurisdiction/${jurisdictionId}/accountDetails?accountType=Price Containment, Auction`,
      {},
      {
        componentName: COMPONENTS.REPLENISHMENT_ACCOUNT_DETAILS,
      },
    )
    : fetcherGet(
      `${APIS.CLUSTER_TRANSFERS}/jurisdiction/${jurisdictionId}/accountDetails?accountType=Allocation`,
      {},
      {
        componentName: COMPONENTS.REPLENISHMENT_ACCOUNT_DETAILS,
      },
    );
  return resp
    .then(({ data }) => {
      if (data?.length) {
        if (fromJurisdiction) dispatch(setAccountDetailReplenishment(data));
        else dispatch(setAccountDetailReplenishment(data));
      } else {
        dispatch(setAccountDetailReplenishment([]));
      }
    })
    .catch(() => {
      dispatch(setAccountDetailReplenishment([]));
    })
    .finally(() => {
      dispatch(setLoader(false));
    });
};

export default ReplenishmentTransferSlice.reducer;
