const Style = {
  spacing: {
    padding: '30px 0px',
    '& .titalwithbtn': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItem: 'center',
      paddingBottom: '23px',
      '& .rightbtn': {
        button: {
          marginLeft: '10px',
          height: '43px',
          width: '100px',
        },
      },
      '& h3': {
        marginBottom: '0px',
        fontSize: '36px !important',
        fontWeight: '300',
        color: '#333',
        '@media (max-width: 1023px)': {
          fontSize: '2.5rem !important',
        },
      },
    },
    '& h4': {
      background: '#4d8da4',
      padding: '12px 25px',
      color: '#fff',
      fontSize: '16px',
      marginBottom: '0px',
    },
    '& .paneldetail': {
      marginLeft: '40px',
      '& .custompanel': {
        display: 'flex',
        background: '#e6eff2',
        marginBottom: '0px',
        '& .MuiAccordionSummary-content': {
          order: '2',
        },
        '& .MuiSvgIcon-root': {
          fill: '#4d8da4',
          width: '1.5em',
          height: '1.5em',
        },
      },
      '& .MuiPaper-elevation': {
        margin: '0px',
      },
    },
    '& .MuiAccordionDetails-root': {
      padding: '18px 60px 10px',
      '@media (max-width: 1023px)': {
        padding: '18px 30px 10px',
      },
      '& .textbox-detail': {
        display: 'flex',
        // alignItems: " center",
        marginBottom: '20px',
        '@media (max-width: 1023px)': {
          flexDirection: 'column',
          justifyContent: 'left',
          alignItems: 'flex-start',
        },

        '& .MuiTextField-root': {
          width: '293px',

          marginRight: '20px',
          '@media (max-width: 1023px)': {
            marginBottom: '10px',
          },
          // "& .MuiOutlinedInput-root": {
          //   height: "30px",
          // },
        },
        '& .MuiTypography-p': {
          fontSize: '14px',
          color: '#333',
          paddingTop: '5px',
        },
      },
      '& .plus-minus-btn-main': {
        '& .MuiOutlinedInput-root': {
          width: '40px',
          height: '26px',
          // border: "1px solid #d7d7d7",
          margin: '0px 3px',
          fontSize: '12px',
          '& input': {
            padding: '5px 8px',
            textAlign: 'center',
          },
        },
        '& .MuiSvgIcon-root ': {
          width: '0.8em',
          height: '0.8em',
        },
        '& .MuiTypography-p': {
          fontSize: '14px',
          color: '#333',
          paddingLeft: '8px',
          '@media (max-width: 960px)': {
            fontSize: '12px',
          },
          '&.disabled-cs-text': {
            color: 'common.textLightGray',
          },
        },
        '& .MuiFormControlLabel-root': {
          paddingLeft: '18px',
        },
        '& .Mui-disabled': {
          color: 'common.textLightGray',
          position: 'relative',
          zIndex: '9999',
          '& .MuiOutlinedInput-notchedOutline': {
            background: '#d7d7d7',
          },
        },
        '& .MuiButton-root': {
          width: 'auto',
          minWidth: 'auto',
          padding: '0px',
        },
      },
    },
    '& .notificationAccordion': {
      '& .MuiAccordionDetails-root': {
        padding: '20px',
      },
    },
    '& .settingsBtn': {
      '& .MuiButtonBase-root': {
        minWidth: '100px',
        minHeight: '43px',
        marginRight: '0px',
        marginLeft: '8px',
      },
    },
  },
  accordianStyling: {
    borderBottom: '1px solid #4d8da4', boxShadow: 'none', borderRadius: '0px !important',
  },
  bottomGridBox: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
    margin: '0px',
    '& .Mui-selected': {
      backgroundColor: '#4d8da4 !important',
      fontSize: '15px !important',
      color: '#fff !important',
      border: 'none !important',
      '&:hover': {
        backgroundColor: '#4d8da4 !important',
      },
    },
    '& .bottomBtn': {
      '& .MuiButtonBase-root': {
        minWidth: '100px',
        marginTop: '20px',
        minHeight: '43px',
        marginRight: '0px',
        marginLeft: '8px',
      },
    },
  },
  FieldLabel: {
    fontSize: '0.875rem',
    color: 'common.black',
    fontWeight: 700,
    mb: 0.5,
    mt: 3,
    '&.MuiInputLabel-root': {
      /* Conflict Resolution Candidate
            fontWeight: 700,
            "&:empty": {
              height: "20px"
            }
          },
       */
      fontWeight: 700,
      maxWidth: '100%',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    },
  },
  formField: {
    width: 1,
    color: 'common.black',
    '&.fc-search': {
      '& .MuiOutlinedInput-input': {
        background: '#fff',
        color: 'common.black',
        minHeight: '25px',
        py: 0,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '6px',
      },
    },
    '& .fc-select-rounded': {
      borderRadius: '4px',
    },
    '.MuiOutlinedInput-input': {
      p: 0.2,
      fontSize: '0.875rem',
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: '0',
      borderColor: 'common.GrayColor',
    },
    '& .MuiOutlinedInput-root': {
      p: '2px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '4px',
      },
      '&.MuiInputBase-root.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'common.black',
          borderWidth: 1,
          borderRadius: '4px',

        },
      },
    },
  },
};
export default Style;
