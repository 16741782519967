import authRoles from 'core/auth/authRole';
import SystemRoles from './SystemRoles';

const SystemRolesConfig = {
  route: {
    path: '/roles',
    element: <SystemRoles />,
  },
  layout: {
    header: {
      display: true,
    },
    sidebar: {
      display: false,
    },
    footer: {
      display: true,
    },
  },
  auth: authRoles.onlyGuest,
};

export default SystemRolesConfig;
