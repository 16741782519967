const Style = {
  JurisdictionModal: {
    width: '750px',
    /* Conflict Resolution Candidate
        height: "348px",
     */
    height: '348px',
    padding: '20px',
    border: '1px solid #797979',
    borderRadius: '10px',
    background: '#fff',
    boxsizing: 'border-box',
    margin: '0 auto',
    top: '50%',
    position: 'absolute',
    left: '0',
    right: '0',
    transform: 'translateY(-50%)',
    fontWeight: '400',
    outline: 'none',
    '@media(max-width: 814px)': {
      width: '700px',
    },
    '& h2': {
      color: '#333',
      marginBottom: '20px',
      fontSize: ' 24px',
    },
    '& h6': {
      fontSize: '16px',
      color: '#333',
      /* Conflict Resolution Candidate
            marginBottom: "10px",
       */
      marginBottom: '30px',
      fontWeight: '400',
    },
    '& h5': {
      fontSize: '18px',
      color: '#333',
      fontWeight: '500',
      marginBottom: '20px',
    },
    '& h4': {
      marginLeft: '55px',
      fontSize: '18px',
      color: '#333',
      fontWeight: '500',
      marginBottom: '20px',
    },
    '& .CustomSelect': {
      margin: '0px',
      ' & .MuiOutlinedInput-root': {
        borderRadius: '0px',
        /* Conflict Resolution Candidate
                height: "25px",
              },
              "& .MuiSelect-select": {
                padding: "0px 15px !important",
         */
        height: '30px',
      },
      '& .MuiSelect-select': {
        // padding: "0px 15px !important",
        borderRadius: '0px ',
      },
      '& .MuiSelect-outlined': {
        display: 'flex',
      },
    },
    '& .ContinueBtn': {
      position: 'absolute',
      bottom: '20px',
      right: '20px',
      /* Conflict Resolution Candidate
            width: "139px",
            borderRadius: "5px",
       */
      width: '150px',
      height: '40px',
      borderRadius: '4px',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#4D8DA4',
        border: 'solid 1px #4D8DA4',
        color: '#fff',
        boxShadow: 'none',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& input': {
        padding: '0px 15px',
        width: '500px',
        borderRadius: '5px',
      },
    },
    '& .MuiPopover-root': {
      '&.MuiPaper-root': {
        boxShadow: 'none',
      },
    },
  },
  CustomSelect: {
    body: {
      '& .MuiPopover-root': {
        '& .MuiPaper-root': {
          boxShadow: 'none',
        },
      },
    },
  },
  formField: {
    width: 1,
    color: 'common.textColor',
    '.MuiOutlinedInput-input': {
      py: 0.6,
      fontSize: '14px',
      '& .MuiTypography-root': {
        fontSize: '14px',
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: '0',
      borderColor: 'common.GrayColor',
    },
    '&.MuiOutlinedInput-root': {
      '&.MuiInputBase-root.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: '#333',
          borderWidth: 1,
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '4px',
    },
    '& .Mui-error': {
      marginLeft: 0,
    },
  },
};

export default Style;
