import { Typography, Box } from '@mui/material';
import { FormCard } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextTruncate } from 'components/TextTruncate';
import { getCurrentPageName, t } from 'utils/constants';

function RevisionCard({ children, title }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  return (
    <FormCard
      disableElevation
      indicateRequired={false}
      title={title || t(t1, 'REQUEST_REVISION')}
      customeStyle={{ border: '2px solid', color: 'common.red', mb: 0, pb: '20px' }}
    >
      <Box sx={{ wordBreak: 'break-word' }}>
        <TextTruncate content={children} variant="subtitle2" />
      </Box>
    </FormCard>
  );
}

export default RevisionCard;
