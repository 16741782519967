import { createSlice } from '@reduxjs/toolkit';
/* Conflict Resolution Candidate
import { enLanguage } from "utils/constants";
import fetcher from "utils/fetcher";

const initialState = {
  selectedJurisdiction: "",
  selectedLanguage: enLanguage(),
  Jurisdictions: []
 */
import { APIS } from 'utils/constants';
import fetcher, { fetcherDelete, fetcherGet, fetcherPost } from 'utils/fetcher';
import logger from 'utils/logger';
import { getJurisdictionUUID } from 'utils/support';
import { setLoader } from './commonSlice';

const initialState = {
  selectedJurisdiction: '',
  sliceJurisdiction: '',
  selectedLanguage: localStorage.getItem('i18nextLng'),
  Jurisdictions: [],
  jurisdictionDetails: null,
  selectedJurisdictionId: '',
  jurisdictionAccounts: { isLoading: false, data: [] },
  loadingOrganizations: false,
  deleteJurisdictionId: null,
  selectedJurisdictionName: '',
  selectedUserJurisdictionInfo: {},
};

const JurisdictionSlice = createSlice({
  name: 'jurisdiction',
  initialState,
  reducers: {
    setJurisdictions: (state, action) => {
      state.Jurisdictions = action.payload;
    },
    setJurisdiction: (state, action) => {
      // console.log('Slice called', action.payload);
      state.selectedJurisdiction = action.payload;
      state.sliceJurisdiction = action.payload;
      localStorage.setItem('jurisdiction', action.payload);
    },
    setLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
      localStorage.setItem('i18nextLng', action.payload);
    },
    setJurisdictionDetails: (state, action) => {
      state.jurisdictionDetails = action.payload;
    },
    setSelectedJurisdictionId: (state, action) => {
      state.selectedJurisdictionId = action.payload;
      localStorage.setItem('jurisdictionID', action.payload);
    },
    setJurisdictionAccounts: (state, action) => {
      state.jurisdictionAccounts = action.payload;
    },
    setLoadingOrganizations: (state, action) => {
      state.loadingOrganizations = action.payload;
    },
    setDeleteJurisdictionId: (state, action) => {
      state.deleteJurisdictionId = action.payload;
    },
    setSelectedJurisdictionName: (state, action) => {
      state.selectedJurisdictionName = action.payload;
    },
    setSelectedUserJurisdictionInfo: (state, action) => {
      state.selectedUserJurisdictionInfo = action.payload;
    },
  },
});

export const {
  setJurisdictions,
  setJurisdiction,
  selectedJurisdiction,
  setLanguage,
  setJurisdictionDetails,
  setSelectedJurisdictionId,
  setJurisdictionAccounts,
  setLoadingOrganizations,
  setDeleteJurisdictionId,
  setSelectedJurisdictionName,
  setSelectedUserJurisdictionInfo,
} = JurisdictionSlice.actions;
export default JurisdictionSlice.reducer;

export function getJurisdictions() {
  return (dispatch) => {
    dispatch(setLoadingOrganizations(true));
    fetcherGet(APIS.ORGANIZATION, {
      PK: 'Organization',
    })
      .then((response) => {
        dispatch(setJurisdictions(response.Items));
        dispatch(setLoadingOrganizations(false));
      })
      .catch((err) => {
        dispatch(setLoadingOrganizations(false));
      });
  };
}

export function getJurisdictionsDetails() {
  return (dispatch) => {
    fetcherGet(APIS.JURISDICTIONS)
      .then((response) => {
        dispatch(setJurisdictionDetails(response.data));
      })
      .catch((error) => {
        logger({ error, name: 'getJurisdictions' });
      });
  };
}

export function getJurisdictionAccounts(
  jurisdictionId,
  pageDetails = { pageName: 'Page_JurisdictionAccounts', componentName: 'jurisdictionAccounts' },
) {
  return async (dispatch) => {
    dispatch(setJurisdictionAccounts({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.JURISDICTION_ACCOUNTS_API}/${jurisdictionId}`, {}, pageDetails);
      dispatch(setJurisdictionAccounts({ isLoading: false, data: response }));
    } catch (error) {
      dispatch(setJurisdictionAccounts({ isLoading: false, data: [] }));
    }
  };
}

export function retryPeripheralsSetup(payload) {
  return (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(`${APIS.ONBOARD_JURISDICTION}/jurisdictionOnboarding/retriggerPeripheralForJurisdiction`, {
      ...payload,
    }).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function deleteJurisdiction(jurisdictionId) {
  return (dispatch) => {
    dispatch(setLoader(true));
    return fetcherDelete(`${APIS.ONBOARD_JURISDICTION}/jurisdictionOnboarding/deleteJurisdiction/jurisdiction/${jurisdictionId}`,
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getSelectedJurisdictionName(jurisdicitonId) {
  return (dispatch) => {
    dispatch(setSelectedJurisdictionName(''));
    fetcherGet(APIS.ORGANIZATION, {
      PK: jurisdicitonId,
    })
      .then((response) => {
        dispatch(setSelectedJurisdictionName(response?.Items?.name));
      })
      .catch((error) => {
        logger({ error, name: 'getJurisdictions' });
      });
  };
}

export function getUsersJurisdictionInfo(jurisdicitonId) {
  return (dispatch) => {
    dispatch(setSelectedUserJurisdictionInfo({}));
    fetcherGet(APIS.ORGANIZATION, {
      PK: jurisdicitonId,
    })
      .then((response) => {
        dispatch(setSelectedUserJurisdictionInfo(response?.Items));
      })
      .catch((error) => {
        logger({ error, name: 'getJurisdictions' });
      });
  };
}