import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  COUNTRIES_WITH_STATES,
  JURISDICTIONS_NAMES,
  isEmpty,
  t,
  SELECTED_LANGUAGE,
} from 'utils/constants';
import Style from './ApplicationChecklist.style';
import { getEnityFacilityById } from '../../../../store/entityApplicationSlice';

const PageName = 'Page_ApplicationWithAttenstation';
const EntityRegistrationPage = 'Page_EntityRegistration';

function EntityFacilities(props) {
  const { Facilities } = useSelector(({ entityApplication }) => entityApplication);
  const { selectedLanguage } = useSelector(({ jurisdiction }) => jurisdiction);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t: t1 } = useTranslation(PageName);
  const { t: tEntityRegistration } = useTranslation(EntityRegistrationPage);
  const jurisdictionName = props?.entityData?.jurisdiction?.name === JURISDICTIONS_NAMES.WASHINGTON_STATE;
  useEffect(() => {
    dispatch(getEnityFacilityById(id));
  }, []);

  return Facilities?.map((facility, index) => {
    const {
      physicalAddress,
      mailingAddress,
      contact,
    } = facility;

    return (
      <Box key={facility?.id} my={3}>


        <Typography sx={Style.entityIdentification}>
          {`${t(t1, 'FACILITY_IDENTIFICATION')} - ${t(t1, 'FACILITY')} ${index + 1} ${t(t1, 'OF')} ${Facilities?.length}`}
        </Typography>
        <Typography sx={Style.checklistDescription}>
          {t(t1, 'FACILITY_IDENTIFICATION_DESCRIPTION')}
        </Typography>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>
            {t(t1, 'THE_FACILITY_IS_IN_REGISTRY?')}
          </Typography>
          <Typography sx={Style.entryValue}>
            {facility?.facilityIsInRegistry ? 'Yes' : 'No'}
          </Typography>
        </Box>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>
            {t(t1, 'FACILITY_LEGAL_NAME')}
          </Typography>
          <Typography sx={Style.entryValue}>{facility?.facilityName}</Typography>
        </Box>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>
            {t(t1, 'FACILITY_OPERATING_NAME')}
          </Typography>
          <Typography sx={Style.entryValue}>{facility?.operatingName}</Typography>
        </Box>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>
            {jurisdictionName ? `${t(t1, 'EPA_OR_WASHINGTON_GREENHOUSE_GAS_REPORTING_ID')}` : `${t(t1, 'GHG_EMISSIONS_REPORTING_ID')}`}:
          </Typography>
          <Typography sx={Style.entryValue}>{facility?.ghgEmissionsReportingId}</Typography>
        </Box>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>
            {t(t1, 'NAICS_CODE')}
          </Typography>
          <Typography sx={Style.entryValue}>
            {!isEmpty(facility?.naicsCode) && (selectedLanguage === SELECTED_LANGUAGE.ENGLISH ? `${facility?.naicsCode?.naicsCode} - ${facility?.naicsCode?.descriptionInEnglish}` : `${facility?.naicsCode?.naicsCode} - ${facility?.naicsCode?.descriptionInFrench}`)}
            {isEmpty(facility?.naicsCode) && t(t1, 'NA')}
          </Typography>
        </Box>

        <Typography sx={Style.entityIdentification}>
          {t(t1, 'FACILITY_PHYSICAL_ADDRESS')}
        </Typography>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>
            {t(t1, 'ADDRESS_LINE_1')}
          </Typography>
          <Typography sx={Style.entryValue}>{physicalAddress?.street1}</Typography>
        </Box>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>
            {t(t1, 'ADDRESS_LINE_2')}
          </Typography>
          <Typography sx={Style.entryValue}>{physicalAddress?.street2}</Typography>
        </Box>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>{t(t1, 'CITY')}</Typography>
          <Typography sx={Style.entryValue}>{physicalAddress?.city}</Typography>
        </Box>
        {COUNTRIES_WITH_STATES.includes(physicalAddress?.country?.name) ?
          <Box sx={Style.entry}>
            <Typography sx={Style.entryAttribute}>{t(t1, 'PROVINCE_OR_STATE')}</Typography>
            <Typography
              sx={Style.entryValue}>{t(tEntityRegistration, physicalAddress?.state?.name) || 'NA'}</Typography>
          </Box>
          :
          <Box sx={Style.entry}>
            <Typography sx={Style.entryAttribute}>{t(tEntityRegistration, 'REGION')}</Typography>
            <Typography sx={Style.entryValue}>{physicalAddress?.region}</Typography>
          </Box>
        }
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>{t(t1, 'POSTCODE_OR_ZIP_CODE')}</Typography>
          <Typography sx={Style.entryValue}>{physicalAddress?.postalCode}</Typography>
        </Box>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>{t(t1, 'COUNTRY')}</Typography>
          <Typography
            sx={Style.entryValue}>{t(tEntityRegistration, physicalAddress?.country?.name) || 'NA'}</Typography>
        </Box>

        <Typography sx={Style.entityIdentification}>
          {t(t1, 'FACILITY_MAILING_ADDRESS')}
        </Typography>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>{t(t1, 'ADDRESS_LINE_1')}</Typography>
          <Typography sx={Style.entryValue}>{mailingAddress?.street1}</Typography>
        </Box>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>{t(t1, 'ADDRESS_LINE_2')}</Typography>
          <Typography sx={Style.entryValue}>{mailingAddress?.street2}</Typography>
        </Box>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>{t(t1, 'CITY')}</Typography>
          <Typography sx={Style.entryValue}>{mailingAddress?.city}</Typography>
        </Box>
        {COUNTRIES_WITH_STATES.includes(mailingAddress?.country?.name) ?
          <Box sx={Style.entry}>
            <Typography sx={Style.entryAttribute}>{t(t1, 'PROVINCE_OR_STATE')}</Typography>
            <Typography
              sx={Style.entryValue}>{t(tEntityRegistration, mailingAddress?.state?.name) || t(t1, 'NA')}</Typography>
          </Box>
          :
          <Box sx={Style.entry}>
            <Typography sx={Style.entryAttribute}>{t(tEntityRegistration, 'REGION')}</Typography>
            <Typography sx={Style.entryValue}>{mailingAddress?.region || 'NA'}</Typography>
          </Box>
        }
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>{t(t1, 'POSTCODE_OR_ZIP_CODE')}</Typography>
          <Typography sx={Style.entryValue}>{mailingAddress?.postalCode}</Typography>
        </Box>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>{t(t1, 'COUNTRY')}</Typography>
          <Typography sx={Style.entryValue}>{t(tEntityRegistration, mailingAddress?.country?.name) || 'NA'}</Typography>
        </Box>

        <Typography sx={Style.entityIdentification}>
          {t(t1, 'FACILITY_GHG_REPORTER_CONTACT')}
        </Typography>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>
            {t(t1, 'CONTACT_FIRST_NAME')}
          </Typography>
          <Typography sx={Style.entryValue}>{contact?.firstName || t(t1, 'NA')}</Typography>
        </Box>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>
            {t(t1, 'CONTACT_LAST_NAME')}
          </Typography>
          <Typography sx={Style.entryValue}>{contact?.lastName || t(t1, 'NA')}</Typography>
        </Box>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>
            {t(t1, 'CONTACT_JOB_TITLE')}
          </Typography>
          <Typography sx={Style.entryValue}>{contact?.jobTitle || t(t1, 'NA')}</Typography>
        </Box>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>
            {t(t1, 'CONTACT_PHONE_NUMBER')}
          </Typography>
          <Typography sx={Style.entryValue}>{contact?.telephoneNumber || t(t1, 'NA')}</Typography>
        </Box>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>
            {t(t1, 'CONTACT_MOBILE_PHONE_NUMBER')}
          </Typography>
          <Typography sx={Style.entryValue}>{contact?.mobileNumber || t(t1, 'NA')}</Typography>
        </Box>
        <Box sx={Style.entry}>
          <Typography sx={Style.entryAttribute}>
            {t(t1, 'CONTACT_E_MAIL_ADDRESS')}
          </Typography>
          <Typography sx={Style.entryValue}>{contact?.emailAddress || t(t1, 'NA')}</Typography>
        </Box>
      </Box>
    );
  });
}


export default EntityFacilities;