import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import {
  MANAGE_JURI_ACCOUNTS_COMPONENTS,
  MANAGE_JURI_ACCOUNTS_PAGES,
  transformInstrumentsToDropdown,
  transformJurisdictionAccounts,
  transformTransfersToDropdown,
} from 'pages/ManageJurisdictionAccounts/support';
import { APIS } from 'utils/constants';
import { fetcherDelete, fetcherGet, fetcherPatch } from 'utils/fetcher';
import { getPermissionObj, transformStatusChange } from 'utils/support';
import { setLoader } from './commonSlice';

const initialState = {
  manageJuriAccounts: { isLoading: false, data: null },
  accountDetailsInfo: { isLoading: false, data: [] },
  statusChange: { isLoading: false, data: [] },
  typeOfInstruments: { isLoading: false, data: [] },
  typeOfTransfers: { isLoading: false, data: [] },
  isAccountDetailsInfoEmpty: false,
};

const setManageJuriAccountsSlice = createSlice({
  name: 'manageJuriAccount',
  initialState,
  reducers: {
    setManageJuriAccounts: (state, action) => {
      state.manageJuriAccounts = action.payload;
    },
    setAccountDetailsInfo: (state, action) => {
      state.accountDetailsInfo = action.payload;
    },
    setStatusChange: (state, action) => {
      state.statusChange = action.payload;
    },
    setTypeOfInstruments: (state, action) => {
      state.typeOfInstruments = action.payload;
    },
    setTypeOfTransfers: (state, action) => {
      state.typeOfTransfers = action.payload;
    },
    setIsAccountDetailsInfoEmpty: (state, action) => {
      state.isAccountDetailsInfoEmpty = action.payload;
    },
  },
});

export default setManageJuriAccountsSlice.reducer;

export const {
  setManageJuriAccounts,
  setAccountDetailsInfo,
  setStatusChange,
  setTypeOfInstruments,
  setTypeOfTransfers,
  setIsAccountDetailsInfoEmpty,
} = setManageJuriAccountsSlice.actions;

export function getManageJuriAccounts(
  jurisdictionId,
  pageName = MANAGE_JURI_ACCOUNTS_PAGES.MANAGE_JURI_ACCOUNTS,
  componentName = MANAGE_JURI_ACCOUNTS_COMPONENTS.ACCOUNTS,
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setManageJuriAccounts({ isLoading: true, data: null }));
    try {
      const response = await fetcherGet(
        `${APIS.GET_JURISDICTION_ACCOUNTS}/${jurisdictionId}`,
        {},
        { pageName, componentName },
      );
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setManageJuriAccounts({
          isLoading: false,
          data: {
            data: transformJurisdictionAccounts(data),
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        }),
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setManageJuriAccounts({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

export function getAccountDetailsInfo(
  juriAccId,
  jurisdictionId,
  pageName = MANAGE_JURI_ACCOUNTS_PAGES.EDIT_ACCOUNT_DETAILS,
  componentName = MANAGE_JURI_ACCOUNTS_COMPONENTS.EDIT_ACCOUNT,
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setAccountDetailsInfo({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(
        `${APIS.GET_JURI_ACCOUNT_DETAILS}/${juriAccId}/${jurisdictionId}`,
        {},
        { pageName, componentName },
      );
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setAccountDetailsInfo({
          isLoading: false,
          data: {
            data,
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        }),
      );
      if (isEmpty(data)) {
        dispatch(setIsAccountDetailsInfoEmpty(true));
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setAccountDetailsInfo({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

export function updateAccountDetailsInfo(
  juriAccId,
  payload,
  pageName = MANAGE_JURI_ACCOUNTS_PAGES.EDIT_ACCOUNT_DETAILS,
  componentName = MANAGE_JURI_ACCOUNTS_COMPONENTS.UPDATE_ACCOUNT,
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPatch(`${APIS.UPDATE_JURI_ACCOUNT_DETAILS}`.replace(':jurisdictionAccountId', juriAccId), payload, {
      pageName,
      componentName,
    }).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getStatusChangeAPI(
  jurisdictionId,
  pageName = MANAGE_JURI_ACCOUNTS_PAGES.JURISDICTION_ACCOUNT_DETAILS,
  componentName = MANAGE_JURI_ACCOUNTS_COMPONENTS.STATUS_CHANGE,
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setStatusChange({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.GET_STATUS_CHANGE_API}/${jurisdictionId}`, {}, {});
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setStatusChange({
          isLoading: false,
          data: {
            data: transformStatusChange(data),
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        }),
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setStatusChange({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

export function getTypeOfInstruments() {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setTypeOfInstruments({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.GET_TYPE_OF_INSTRUMENTS}`);
      // const response = typeOfInstruments;
      const { data } = response;
      dispatch(
        setTypeOfInstruments({
          isLoading: false,
          data: transformInstrumentsToDropdown(data, true),
        }),
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setTypeOfInstruments({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

export function getTypeOfTransfers(juriAccId, jurisdictionId) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setTypeOfTransfers({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.GET_TYPE_OF_TRANSFERS}/${juriAccId}/${jurisdictionId}`);
      const { data } = response;
      dispatch(
        setTypeOfTransfers({
          isLoading: false,
          data: transformTransfersToDropdown(data),
        }),
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setTypeOfTransfers({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

export function deleteJurisdictionAccount(juriAccId) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcherDelete(`${APIS.DELETE_JURISDICTION_ACCOUNT}`.replace(':jurisdictionAccId', juriAccId)).finally(() => {
      dispatch(setLoader(false));
    });
  };
}
