import {
  APPROVAL_TYPES,
  BATCH_TRANSFER_TYPES,
  ENTITY_TRANSFER_TYPES,
  JURISDICTION_TRANSFER_TYPES,
  formatDateUpdated,
  t,
  formatNumber,
  HOLDING_LIMITS_STATUSES,
} from 'utils/constants';

export const transformCompleteHolding = (data, t1) =>
  data?.map((item) => ({
    EntityID: item?.Entity?.EntityID,
    LegalName: item?.Entity?.LegalName,
    OperationalName: item?.Entity?.OperationalName,
    dateTimeFailedCheck: formatDateUpdated(item?.dateTimeFailedCheck),
    transferId: item?.Transfer?.transactionId,
    transferType: t(t1, item?.Transfer?.transfer_type?.name),
    vintage: item?.Holdings?.vintage_year,
    amountOverHoldingLimit: formatNumber(item?.amountOverHoldingLimit),
    lastUpdatedBy: item?.lastUpdatedBy,
    entityHoldingLimit: formatNumber(item?.entityHoldingLimit),
    updatedAt: formatDateUpdated(item?.updatedAt),
    comment: item?.commments === null ? '' : item?.commments,
  }));

export const transformPeriodicHolding = (data) =>
  data?.map((item) => ({
    EntityID: item?.Entity?.EntityID,
    LegalName: item?.Entity?.LegalName,
    OperationalName: item?.Entity?.OperationalName,
    dateTimeFailedCheck: formatDateUpdated(item?.dateTimeFailedCheck),
    budgetYear: item?.budgetYear,
    amountOverHoldingLimit: formatNumber(item?.amountOverHoldingLimit),
    lastUpdatedBy: item?.actionBy,
    entityHoldingLimit: formatNumber(item?.entityHoldingLimit),
    updatedAt: formatDateUpdated(item?.updatedAt),
    comment: item?.commments === null ? '' : item?.commments,
  }));

export const transformTransferHolding = (data, t1) =>
  data?.map((item) => ({
    EntityID: item?.Entity?.EntityID,
    LegalName: item?.Entity?.LegalName,
    OperationalName: item?.Entity?.OperationalName,
    EventFailedCheck: t(t1, transformEventPassedFailedCheck(item?.EventFailedCheck?.name)),
    dateTimeFailedCheck: formatDateUpdated(item?.dateTimeFailedCheck),
    EventPassedCheck: t(t1, transformEventPassedFailedCheck(item?.EventPassedCheck?.name)),
    dateTimePassedCheck: formatDateUpdated(item?.dateTimePassedCheck),
    transferId: item?.Transfer?.transactionId,
    transferType: t(t1, item?.Transfer?.transfer_type?.name),
    vintage: item?.Holdings?.vintage_year,
    entityHoldingLimit: formatNumber(item?.entityHoldingLimit),
    amountOverHoldingLimit: formatNumber(item?.amountOverHoldingLimit),
    lastUpdatedBy: item?.lastUpdatedBy,
    updatedAt: formatDateUpdated(item?.updatedAt),
    comment: item?.commments === null ? '' : item?.commments,
  }));


export const transformEventPassedFailedCheck = (data) => {
  let cmsKey = data;
  if (data === HOLDING_LIMITS_STATUSES.PROPOSED)
    cmsKey = HOLDING_LIMITS_STATUSES.PROPOSE;
  else if (data === HOLDING_LIMITS_STATUSES.APPROVED)
    cmsKey = HOLDING_LIMITS_STATUSES.APPROVE;
  else if (data === HOLDING_LIMITS_STATUSES.DENIED)
    cmsKey = HOLDING_LIMITS_STATUSES.DENY;

  return cmsKey;
};

export const getURLBasedOnTransfer = (cellVal) => {
  const url = {
    [APPROVAL_TYPES.ALLOWANCE_ISSUANCE]: `/allowance-issuance-details/${cellVal?.Transfer?.transactionId}`,
    [APPROVAL_TYPES.OFFSET_ISSUANCE]: `/offset-issuance-details/${cellVal?.Transfer?.transactionId}`,
    [ENTITY_TRANSFER_TYPES.VOLUNTARY_RETIREMENT_TRANSFER]: `/entity-transfers/details/${cellVal?.Entity?.id}/${cellVal?.Transfer?.transactionId}/${cellVal?.Transfer?.transferring_entity_account_id}/${cellVal?.Transfer?.transfer_type?.name}`,
    [ENTITY_TRANSFER_TYPES.CONSIGNMENT_TRANSFER]: `/entity-transfers/details/${cellVal?.Entity?.id}/${cellVal?.Transfer?.transactionId}/${cellVal?.Transfer?.transferring_entity_account_id}/${cellVal?.Transfer?.transfer_type?.name}`,
    [JURISDICTION_TRANSFER_TYPES.ADMINISTRATIVE]: `/transfer-detail/${cellVal?.Transfer?.transactionId}`,
    [JURISDICTION_TRANSFER_TYPES.REPLENISHMENT]: `/transfer-detail/${cellVal?.Transfer?.transactionId}`,
    [BATCH_TRANSFER_TYPES.BATCH_TRANSFER]: `/transfer-detail/${cellVal?.Transfer?.transactionId}`,
    [BATCH_TRANSFER_TYPES.JURISDICTION_BATCH_TRANSFER_PCRA]: `/transfer-detail/${cellVal?.Transfer?.transactionId}`,
    [ENTITY_TRANSFER_TYPES.GENERAL_EXCHANGE_AGREEMENT_TRANSFER_NOT_TO_ECSP]: `/entity-transfers/details/${cellVal?.Entity?.id}/${cellVal?.Transfer?.transactionId}/${cellVal?.Transfer?.transferring_entity_account_id}/${cellVal?.Transfer?.transfer_type?.name}`,
    [ENTITY_TRANSFER_TYPES.GENERAL_OTC_LESS_THAN_3_DAYS]: `/entity-transfers/details/${cellVal?.Entity?.id}/${cellVal?.Transfer?.transactionId}/${cellVal?.Transfer?.transferring_entity_account_id}/${cellVal?.Transfer?.transfer_type?.name}`,
    [ENTITY_TRANSFER_TYPES.GENERAL_OTC_GREATER_THAN_3_DAYS]: `/entity-transfers/details/${cellVal?.Entity?.id}/${cellVal?.Transfer?.transactionId}/${cellVal?.Transfer?.transferring_entity_account_id}/${cellVal?.Transfer?.transfer_type?.name}`,
    [ENTITY_TRANSFER_TYPES.ANNUAL_ALLOCATION_TRANSFER_GENERAL]: `/entity-transfers/details/${cellVal?.Entity?.id}/${cellVal?.Transfer?.transactionId}/${cellVal?.Transfer?.transferring_entity_account_id}/${cellVal?.Transfer?.transfer_type?.name}`,
    [ENTITY_TRANSFER_TYPES.ANNUAL_ALLOCATION_TRANSFER_COMPLIANCE]: `/entity-transfers/details/${cellVal?.Entity?.id}/${cellVal?.Transfer?.transactionId}/${cellVal?.Transfer?.transferring_entity_account_id}/${cellVal?.Transfer?.transfer_type?.name}`,
    [ENTITY_TRANSFER_TYPES.ECSP_ECHA_TO_CLEARING_PROVIDER]: `/entity-transfers/details/${cellVal?.Entity?.id}/${cellVal?.Transfer?.transactionId}/${cellVal?.Transfer?.transferring_entity_account_id}/${cellVal?.Transfer?.transfer_type?.name}`,
    [ENTITY_TRANSFER_TYPES.ECSP_ECHA_FROM_CLEARING_PROVIDER]: `/entity-transfers/details/${cellVal?.Entity?.id}/${cellVal?.Transfer?.transactionId}/${cellVal?.Transfer?.transferring_entity_account_id}/${cellVal?.Transfer?.transfer_type?.name}`,
  };
  return url[cellVal?.Transfer?.transfer_type?.name] ?? '';
};

export const HOLDING_LIMITS_COMPONENTS = {
  COMPLETED_TRANSFER_HOLDINGS: 'getCompletedTransfer',
  COMPLETED_TRANSFER_HOLDING_DETAILS: 'editCompletedTransfer',
  TRANSFER_WARNINGS: 'getTransferWarning',
  TRANSFER_WARNINGS_DETAILS: 'editTransferWarning',
  PERIODIC_HOLDING: 'getPeriodicCheck',
  PERIODIC_HOLDING_DETAILS: 'editPeriodicCheck',
  HOLDING_LIMIT_DETAILS: 'getHoldingLimit',
  EDIT_HOLDING_LIMIT_DETAIL: 'editHoldingLimitDetail',
  EDIT_HOLDING_LIMIT_STATUS: 'editHoldingLimitStatus',
  HOLDING_LIMIT_HISTORY: 'getHoldingLimitHistory',
};

export const HOLDING_LIMIT_PAGE = 'Page_ManageHoldingLimit';

export const flattenTransferTypeObject = (data) =>
  data.map((item) => ({
    ...item,
    transferType: item?.Transfer?.transfer_type?.name,
  }));