import styled from '@emotion/styled';
import moment from 'moment-timezone';
import { Typography } from '@mui/material';
import { next, parse } from 'aws-cron-parser';
import { isValid } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { phone } from 'phone';
import * as pako from 'pako';
import HTMLReactParser from 'html-react-parser';
import { setLoader, setModelMessage } from 'store/commonSlice';
import dayjs from 'dayjs';
import _ from 'lodash';
import { StyledCellContent } from 'components/MUITableGrid';

let storeInstance = null;
let i18nInstance = null;
(() => {
  setTimeout(async () => {
    storeInstance = (await import('store/store.js')).default;
    i18nInstance = (await import('config/lang/i18n.js')).default;
  }, 5000);
})();

export const SECRET_KEY = 'WCI_Reg_Enc';
export const REACT_APP_MUI_LICENSE_KEY =
  'bb3ccf46b8217cea26ac18879f97ed42Tz03NjE3MixFPTE3MjgyNDYxNTcwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y';
export const PAGE_SIZE = 5;
export const defaultPdf =
  'https://wcitestbucket.s3.us-east-2.amazonaws.com/amazon-s3-bucket/fundingagreementcalifornia-20211217-en-signatureonfile.pdf';
export const LANGUAGES = [
  { title: 'German', value: 'German' },
  { title: 'English', value: 'English' },
  { title: 'French', value: 'French' },
];

const convertToObject = (array) => array.map((each, index) => ({ title: each, value: index + 1 }));

const convertToObjectcountry = (array) => array.map((each) => ({ title: each, value: each }));
export const getJurisdictionName = (Jurisdictions, orgId) => Jurisdictions.find((each) => each.OrgID === orgId);

export const findJurisdictionName = (juriList, orgId) => juriList?.find((each) => each?.dynamoId === orgId);

function getYearTotal() {
  const years = new Date().getFullYear() - 18 - 1930;
  return years;
}

export const DAYS = Array(31)
  .fill()
  .map((_, i) => ({
    title: i + 1,
    value: i + 1,
  }));

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const MONTHS = convertToObject(months);

export const YEARS = Array(getYearTotal())
  .fill()
  .map((_, i) => ({
    title: i + 1930,
    value: i + 1930,
  }));

export const entityTransfersFedwireInstrucRadios = {
  RADIO_1: 'YES',
  RADIO_2: 'NO',
  RADIO_3: 'I_DONT_KNOW',
};

const emailLanguagePreference = ['English', 'French', 'Spanish'];
export const EMAIL_LANGUAGE_PREFERENCE = convertToObjectcountry(emailLanguagePreference);

const countries = ['Canada', 'United States'];
export const COUNTRIES = convertToObjectcountry(countries);

const stateOrProvince = ['California', 'Nova Scotia', 'Québec', 'Washington'];
export const STATE_OR_PROVINCE = convertToObjectcountry(stateOrProvince);

export const JURISDICTIONS = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

const questionOneOptions = [
  'In which city or town was your first job?',
  'What was the last name of your third-grade teacher?',
  'What is your maternal grandmother\'s maiden name?',
];
export const QUESTION_ONE_OPTIONS = convertToObject(questionOneOptions);

const questionTwoOptions = [
  'What is your first pet’s name?',
  'What is your hometown?',
  'Who is your favorite musical artist?',
];
export const QUESTION_TWO_OPTIONS = convertToObject(questionTwoOptions);

const questionThreeOptions = [
  'What is your mother’s maiden name?',
  'What was the make and model of your first car?',
  'What is the destination of your favorite vacation?',
];
export const QUESTION_THREE_OPTIONS = convertToObject(questionThreeOptions);

export const USERS = [
  { role: 1, title: 'Admin', roleid: 'ROLE#00103' },
  { role: 2, title: 'Registrar', roleid: 'ROLE#00101' },
  { role: 3, title: 'User', roleid: 'ROLE#00102' },
  { role: 4, title: 'WCI, Inc Admin', roleid: 'ROLE#00104' },
  { role: 5, title: 'Individual Users', roleid: 'ROLE#00108' },
  { role: 6, title: 'Primary Account Representative', roleid: 'ROLE#00109' },
  { role: 7, title: 'Alternative Account Representative', roleid: 'ROLE#00110' },
  { role: 8, title: 'Account Viewing Agent', roleid: 'ROLE#00111' },
];

export const USER_ROLES = {
  ADMIN: 'ROLE#00103',
  REGISTRAR: 'ROLE#00101',
  USER: 'ROLE#00102',
  WCI_INC_ADMIN: 'ROLE#00104',
  INDIVIDUAL_USER: 'ROLE#00108',
  JURISDICTION_SYSTEM_ADMIN: 'ROLE#00105',
  PRIMARY_ACCOUNT_REPRESENTATIVE: 'ROLE#00109',
  ALTERNATE_ACCOUNT_REPRESENTATIVE: 'ROLE#00110',
  ACCOUNT_VIEWING_AGENT: 'ROLE#00111',
  WCI_ADMIN_DEFAULT_ORG: 'ROLE#00115',
  JURISDICTION_PROGRAM_STAFF: 'ROLE#00104',
  FINANCIAL_SERVICE_PROVIDERS: 'ROLE#00112',
  FINANCIAL_SERVICE_PROVIDERS_QA: 'ROLE#00107',
  PUBLIC_USER: 'ROLE#00113',
  APPLICANT: 'ROLE#00112',
  MARKET_MONITOR: 'ROLE#00106',
  AUCTION_ADMINISTRATOR: 'ROLE#00116',
};

export const USER_ROLE_TYPES = {
  INDIVIDUAL_USER: 'Individual Users',
  JURISDICTION_USER: 'Jurisdiction Users',
  SYSTEM_USER: 'Technical Admin/System Users',
  SERVICE_PROVIDERS: 'Service Providers',
};

export const ORGANIZATION_TYPE = convertToObjectcountry([
  'Academic',
  'Authorized Project Designee',
  'Brokerage/Investment Firm',
  'Charitable Trust',
  'Clearing Service Provider',
  'Company',
  'Corporation',
  'Early Action Offset Program',
  'Early Action Offset Project Operator',
  'General Partnership',
  'Other Classification',
]);

export const roleTypes = ['JURISDICTION_USER', 'INDIVIDUAL_USER', 'SERVICE_PROVIDER', 'TECH_SYSTEM_ADMIN'];
export const ROLE_TYPES = convertToObject(roleTypes);
export const ROLE_OPTIONS = convertToObjectcountry([
  'Primary Account Representative',
  'Alternate Account Representative',
]);
export const REPRESENTATIVE_RADIO_OPTIONS = {
  userRefCode: [
    {
      name: 'User Reference Code',
      value: 'UserReferenceCode',
      privId: 'user_reference_code',
      isDisplay: true,
    },
  ],
  self: [
    {
      name: 'Self',
      value: 'Self',
      privId: 'self',
      isDisplay: true,
    },
  ],
};

export const COUNTRIES_ID = {
  UNITED_STATES: '3ee7cc2b-517e-4cd0-87a7-f7d991df9283',
  CANADA: 'a7051c13-40d8-4b64-8145-4e8ccd4f0cab',
};

export const COUNTRY_KEY_NAMES = {
  UNITED_STATES: 'COUNTRY_UNITED_STATES',
  CANADA: 'COUNTRY_CANADA',
};

export const LOCALIZED_COUNTRY_NAMES = {
  UNITED_STATES: 'United States',
  CANADA: 'Canada',
};

export const LOCALIZED_STATE_NAMES = {};

export const REPRESENTATIVE_STATUS_NAMES = {
  activate: 'activate',
  cancel: 'cancel',
  suspend: 'suspend',
  retire: 'retire',
  remove: 'remove',
  propose: 'propose',
  propose_f: 'propose-f',
  retired: 'retired',
  retired_f: 'retired-f',
};

export const REPRESENTATIVE_STATUS = {
  RETIRED: 'RETIRED',
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
  PROPOSED: 'PROPOSED',
};

export const PAGES = [
  { id: 1, name: 'Page_PM_Home', path: '/privilege' },
  { id: 2, name: 'Page_PM_CopyRole', path: '/copy-roles' },
  { id: 4, name: 'Page_PM_SysDefRoles', path: '/roles' },
  { id: 6, name: 'Page_PM_RoleDetail_PageControl', path: '/role-details' },
  { id: 8, name: 'Page_UserHome', path: '/home' },
  { id: 9, name: 'Page_LandingPageKnown', path: '/' },
  { id: 11, name: 'Page_ConfigurationSetting', path: '/settings' },
  { id: 12, name: 'Page_PM_RoleDetail_PageControl', path: '/create-role-details' },
  { id: 13, name: 'Page_PM_RoleDetail_PageControl', path: '/edit-role-details' },
  { id: 14, name: 'Page_ContactUs', path: '/contact-us' },
  { id: 15, name: 'Page_JurisdictionUserHome', path: '/jurisdiction-user' },
  { id: 16, name: 'Page_JurisdictionUserActions', path: '/jurisdiction-user-actions' },
  { id: 17, name: 'Page_UserRegistration', path: '/user-registration' },
  { id: 18, name: 'Page_UserRegistration', path: '/create-user-profile' },
  { id: 19, name: 'Page_PM_RoleDetail_Users', path: '/user-role' },
  { id: 20, name: 'Page_UserProfile', path: '/user-profile' },
  { id: 21, name: 'Page_ResetPass&SecurityQuestion', path: '/reset-password/*' },
  { id: 22, name: 'Page_UserDetail', path: '/view-user-details' },
  { id: 23, name: 'Page_ChangeJusrisdiction', path: '/change-jurisdiction' },
  { id: 24, name: 'Page_CrossJurisdiction', path: '/cross-jurisdiction' },
  { id: 25, name: 'Page_Content_Management_System', path: '/content-management' },
  { id: 26, name: 'Page_ResetPass&SecurityQuestion', path: '/reset-password-security-questions' },
  { id: 27, name: 'Page_EntityRegistration', path: '/entity-registration' },
  { id: 29, name: 'Page_EntityProfile', path: '/entity-profile/*' },
  { id: 30, name: 'Page_WiringInstructions', path: '/entity-profile/:id/wiring/:accountId/:action' },
  { id: 31, name: 'Page_EntityRegistration', path: '/entity-revision-requested/*' },
  { id: 32, name: 'Page_JurisdictionEntityAction', path: '/jurisdiction-entity-actions' },
  { id: 33, name: 'Page_JurisdictionEntityAction', path: '/jurisdiction-entity-actions/*' },
  { id: 34, name: 'Page_AssociatedRetiredFacility', path: '/associate-retired-facility/*' },
  { id: 35, name: 'Page_EntityRegistration', path: '/entity-profile-information/*' },
  { id: 36, name: 'Page_EntityRegistration', path: '/jurisdiction-entity-profile/*' },
  { id: 37, name: 'Page_AccountDetails', path: '/entity-profile/:id/account/:accountId' },
  { id: 38, name: 'Page_EntityRegistration', path: '/start-entity-registration' },
  { id: 39, name: 'Page_AuctionAndReserveSale', path: '/auction-reserve-sale' },
  { id: 40, name: 'Page_EntityRegistration', path: '/account-application-checklist-form/*' },
  { id: 41, name: 'Page_EntityRegistration', path: '/account-application-attestation-form/*' },
  { id: 42, name: 'Page_EntityRegistration', path: '/facility-profile-details/*' },
  { id: 43, name: 'Page_EntityProfile', path: '/facility-form' },
  { id: 44, name: 'Page_EntityProfile', path: '/facility-form/*' },
  { id: 42, name: 'Page_NAICSCodeManagement', path: '/naics-code' },
  { id: 45, name: 'Page_CAG', path: '/corporate-associations' },
  { id: 46, name: 'Page_CAG', path: '/corporate-associations/*' },
  { id: 47, name: 'Page_ManageJurisdictionBudget', path: '/budgets' },
  { id: 48, name: 'Page_ManageJurisdictionBudget', path: '/budgets/*' },
  { id: 49, name: 'Page_ManageLimitedExemption', path: '/limited-exemptions' },
  { id: 50, name: 'Page_ManageLimitedExemption', path: '/limited-exemptions/*' },
  { id: 51, name: 'Page_IssuanceAccount', path: '/jurisdiction-account/*' },
  { id: 53, name: 'Page_AllowanceIssuance', path: '/allowance-issuance/*' },
  { id: 54, name: 'Page_OffsetIssuance', path: '/offset-issuance/*' },
  { id: 55, name: 'Page_Approvals', path: '/approvals' },
  { id: 56, name: 'Page_AllowanceIssuanceDetails', path: '/allowance-issuance-details' },
  { id: 57, name: 'Page_OffsetIssuanceDetails', path: '/offset-issuance-details/*' },
  { id: 58, name: 'Page_IssuanceRecords', path: '/issuance-records' },
  { id: 59, name: 'Page_ExchangeContractDescCode', path: '/exchange-contract-code' },
  { id: 60, name: 'Page_ExchangeContractDescCode', path: '/exchange-contract-code/*' },
  { id: 61, name: 'Page_FSAHome', path: '/fsa-home' },
  { id: 62, name: 'Page_OffsetManagement', path: '/offset-management/*' },
  { id: 63, name: 'Page_ManageBatchTransfer', path: '/batch-transfer' },
  { id: 64, name: 'Page_ManageBatchTransfer', path: '/batch-transfer/*' },
  { id: 65, name: 'Page_ManagePurchaseLimit', path: '/purchase-limits' },
  { id: 66, name: 'Page_ManagePurchaseLimit', path: '/purchase-limits/*' },
  { id: 67, name: 'Page_ManageHoldingLimit', path: '/holding-limits' },
  { id: 68, name: 'Page_ManageHoldingLimit', path: '/holding-limits/*' },
  { id: 69, name: 'Page_ManageEntityTransfers', path: '/entity-transfers/*' },
  { id: 70, name: 'Page_ManageAdministrativeTransfers', path: '/administrative-transfers/*' },
  { id: 71, name: 'Page_ManageReplenishmentTransfer', path: '/replenishment-transfers/*' },
  { id: 72, name: 'Page_ModifiedRecordsForTransfers', path: '/modified-records-for-transfers' },
  { id: 73, name: 'Page_ModifiedRecordsForTransfers', path: '/modified-records-for-transfers/*' },
  { id: 74, name: 'Page_JurisdictionAccounts', path: '/jurisdiction-accounts' },
  { id: 74, name: 'Page_TransferDetailsPage', path: '/transfer-detail/*' },
  { id: 75, name: 'Page_ManageCronJobs', path: '/cron-jobs' },
  { id: 76, name: 'Page_EventCreationAndManagement', path: '/event-creation-and-management' },
  { id: 77, name: 'EventListing', path: '/event-creation-and-management' },
  { id: 78, name: 'Page_EventApplication', path: '/event-applications-creation-and-management' },
  { id: 79, name: 'Page_SetupInitialUsers', path: '/set-up-initial-users/*' },
  { id: 80, name: 'Page_SetUpJurisdictionAccounts', path: '/set-up-jurisdiction-accounts/*' },
  { id: 81, name: 'Page_ManageJurisdictionAccounts', path: '/manage-jurisdiction-accounts/*' },
  { id: 82, name: 'Page_CreateParticipatingJurisdiction', path: '/onboard-new-jurisdiction' },
  { id: 83, name: 'Page_Reports', path: '/reports/*' },
  { id: 84, name: 'Page_EventApplicationManagement', path: '/event-application-management' },
  { id: 85, name: 'Page_CreateParticipatingJurisdiction', path: '/manage-jurisdiction-details/*' },
  { id: 86, name: 'Page_EventApplicationJuri', path: '/event-applications' },
  { id: 87, name: 'Page_ManageJurisdictionStatus', path: '/manage-jurisdiction-status/*' },
  { id: 88, name: 'Page_AllReports', path: '/all-reports' },
  { id: 89, name: 'Page_EarReports', path: '/ear-reports' },
  { id: 90, name: 'Page_EventParticipantReport', path: '/event-participant-report' },
  { id: 91, name: 'Page_EADEReport', path: '/event-application-data-export-report' },
  { id: 92, name: 'Page_FSAAllReportHome', path: '/fsa-all-reports' },
  { id: 93, name: 'Page_IndividualUsersAllReports', path: '/iu-all-reports' },
  { id: 94, name: 'Page_RegistryEntityFacilityReport', path: '/registered-entity-facility' },
  { id: 95, name: 'Page_EntityLegalNameOperatingNameChange', path: '/entity-legal-name-operating-name-change' },
  { id: 96, name: 'Page_CMSCompareReports', path: '/cms-compare-reports' },
  { id: 97, name: 'Page_ManageTransfers', path: '/manage-transfers/*' },
  { id: 98, name: 'Page_FinancialServicesDeliveryInstruction', path: '/financial-services-delivery-instructions' },
  { id: 99, name: 'Page_FSAReport', path: '/fsa-report' },
  { id: 100, name: 'Page_FacilityGHGIdentifierChange', path: '/facility-ghg-identifier-change' },
  { id: 101, name: 'Page_FacilityLegalOpNameChange', path: '/facility-name-operating-name-change' },
  { id: 102, name: 'Page_NAICSCodeDisplayManagement', path: '/naics-code-display-management' },
  { id: 103, name: 'Page_WiringInstructionsStatusReport', path: '/wiring-instructions-status-report' },
  {
    id: 104,
    name: 'Page_EURelationshipsReconciliationReport',
    path: '/entity-user-relationships-reconciliation-report',
  },
  { id: 105, name: 'Page_QualifiedBidderReconciliationReport', path: '/qualified-bidders-reconciliation-report' },
  { id: 106, name: 'Page_BidLimitationsReconciliationReport', path: '/bid-limitations-reconciliation-report' },
  { id: 107, name: 'Page_MarketMonitor', path: '/market-monitor' },
  { id: 108, name: 'Page_AuctionAdmin', path: '/auction-administrator' },
  { id: 109, name: 'Page_ConsignmentWiringInstructionsStatusReport', path: '/fsa-wiring-instructions-status-report' },
  { id: 110, name: 'Page_ConsignmentWiringInstructionsReport', path: '/consignment-wiring-instructions-report' },
  { id: 111, name: 'Page_MarketMonitorAllReport', path: '/market-monitor-all-reports' },
  { id: 112, name: 'Page_AuctionAdministratorAllReport', path: '/all-report-auction-administrator' },
  { id: 113, name: 'Page_ServiceProviderSelectionManagement', path: '/service-provider-selection-and-management' },
  { id: 114, name: 'Page_ManageEmissions', path: '/emissions' },
  { id: 115, name: 'Page_ManageEmissions', path: '/emissions/*' },
  { id: 116, name: 'Page_EventApplicationAr', path: '/event-applications-ar' },
  { id: 117, name: 'Page_ManageTrueUps', path: '/true-ups' },
  { id: 118, name: 'Page_ManageTrueUps', path: '/true-ups/*' },
  { id: 119, name: 'Page_FacilityEmissionsReport', path: '/facility-emissions-report' },
];

export const userReferenceCodeRegex = /^[a-zA-Z0-9-_]+$/;
export const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(\.\w{2,64})+$/;
export const mailPattern =
  /^\s*[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+[^.]+@[^-]+[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(\.\w{2,64})+\s*$/;
export const newLanguagePattern = /^(?![0-9-\s]*$)[A-Za-zÀ-ÖØ-Ýà-öø-ÿ0-9-\s_/]*$/;
export const phonePattern =
  /^[+]?\s?[(]?[0-9]{1,3}[)]?[-\s]?[(]?[0-9]{2,4}[)]?[-\s]?[0-9]{3,4}[-\s]?[0-9]{0,4}?[0-9]{4,10}$/;
export const faxNumberException =
  /^[+]?\s?[(]?[0-9]{1,3}[)]?[\s]?[(]?[0-9]{1,4}[)]?\s?[0-9]{2,4}[-\s]?[0-9]{3,4}[-\s]?[0-9]{3,4}$/;
export const alphaNumPattern = /^[a-zA-Z0-9]+$/;
export const extPattern = /^[0-9\s]*$/;

// Regex pattern to validate postal codes
export const postalCodePattern = /^(?![\u002D\u002E\u0028\u0029])(?!.*([ \u002D]{2,}|\u002E{2,}))[\p{L}\d\s\u002D\u002E\u0028\u0029]*(?<![\u002D\u002E\u0028\u0029\s])$/u;

// Explanation:
// - The pattern ensures that the postal code does not start/end with specific characters: 
//   - '-' (hyphen) - \u002D
//   - '.' (period) - \u002E
//   - ' ' (space)  - \u0020
//   - '(' (opening parenthesis) - \u0028
//   - ')' (closing parenthesis) - \u0029
// - It prevents consecutive characters:
//   - '-' (hyphen) - \u002D
//   - ' ' (space)  - \u0020
//   - '.' (period) - \u002E
// - It allows letters, digits, parentheses, spaces, hyphens, and periods in between.


const userIdPattern = /^[\p{L}\p{N}\-_.]*$/u;
const atleastOnePattern = /[\p{L}\p{N}]+/u;
export const upperLowercasePattern = /^(?=.*[a-z])(?=.*[A-Z]).*$/;
export const numberPattern = /\d/;
export const speciaCharPattern = /[-._!` #%&,:;<>=@{}~$()*+/?[\]^|]+/;
export const nonPrintingPattern = /^(?!.*[¶°·↵→¬]).{1,}$/;
export const nonPrintingSpecial = /[^\x20-\x7E]/;
const emojiPattern = /\p{Extended_Pictographic}/u;
const passwordAvoidCharPattern = /["'\\]/;
export const fullNamePattern = /^[\p{L}\-.' ()]*$/u;
export const namePattern = /^[A-Za-zÀ-ÖØ-Ýà-öø-ÿ-.' ]*$/;
export const middleNamePattern = /^[\p{L}\-.' ]*$/u;
export const salutationPattern = /^[\p{L}\-.' ]*$/u;
const salutationSpecialCharPattern = /^[-.']*$/u;
export const firstnamePattern = /^[\p{L}\-.' ()]*$/u;
export const suffixPattern = /^[\p{L}\-. ,]*$/u;
// regex pattern used to validate job title and employer
export const jobEmployerPattern = /^[\p{L}\d\-.'&() *+,:’"/|_°]*$/u;
export const jobtitleEmployerPattern = /^[\p{L}\-.'&() *+,:’"/\\|_°]*$/u;
export const cityOrRegionNamePattern = /^[\p{L}\d&()*+,-./;'" ]*$/u;
export const street1Pattern = /^[\p{L}\d\-.'" &()*+,#–:;°/\\|_’]*$/u;
export const lastNamePattern = /^[\p{L}\-.' ]*$/u;
export const onlyTextWithSpacePattern =
  /^(?! )[A-Za-z0-9øùúûüýþÿØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ ]*(?<! )$/;
export const onlyAlphaNumWithSpacePattern = /^[^\s][a-zA-Z0-9\s]+[^\s]$/;
export const shouldntStartOrEndWithSpace = /^\S.*[^.\s]$/;
export const shouldntStartWithSpace = /^\S.*$/gm;
export const shouldntStartWithSpaceAllowMultiline = /^\S.*$/gms;
export const alphaNumericWithSpecialAccentuatedCharacters = /^(?!.*\s\s)(\p{L}|\p{N}|\p{P}|\p{S}|\s)*\S$/u;
export const noSpacePattern = /^\S*$/;
export const faxNumberPattern = /^(\+?\d{1,}(\s?|-?)\d*(\s?|-?)\(?\d{2,}\)?(\s?|-?)\d{3,}\s?\d{3,})$/;
export const userId = /^[^\s](?:[\d,\\/().]*[a-zA-Z][a-zA-Z\d,\\/().]*)?[^\s]$/;
// Regular expression pattern for comma-separated positive numbers or decimal numbers
export const COMMA_SEPERATED_NUMBERS_REGX = /^(\d+,)*\d+$/;
export const allowCommaDot = /^[^-\s][a-zA-Z0-9_\s-.]+[^\s]$/;
export const answerField = /^[^\s][0-9a-zA-Z^<>{}"/'|;:.,~!\-`?@#$%^=&*\\()[\]_+\s]+[^\s]$/;
/* eslint-disable no-control-regex */
export const nonPrintableChar = /[\x00-\x1F\x7F-\x9F]/;
export const specialCharImportant = /^[^a-zA-Z0-9!@#$&()\\-`.+,/"]*$/;
export const commentPattern = /^[A-Za-zÀ-ÖØ-Ýà-öø-ÿ0-9\-!@#$%&*{}[\].,_/\s;'"°()+:\u2019\u2013|\\]*$/;
export const spaceImportant = /\s/;
export const numbersImportant = /^[0-9!@#%^$&^()*{}_\-+=[\]\\/|~`?:;'",<.>]+$/;
export const numbersImportantDoNotAllowAllZero =
  /^(?=.*[1-9!@#%^$&^()*{}_\-+=[\]\\/|~`?:;'",<.>])[0-9!@#%^$&^()*{}_\-+=[\]\\/|~`?:;'",<.>]+$/;
export const stringImportant = /^[A-Za-z\s]+$/;
export const emailImportant =
  /^(?!.*\.@)(?!.*@-)(?!\.)(?!.*-{2})(?!.*\.{2})[^@]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?!.*-)$/;
export const emailPatternValidation =
  /^(?!.*\.\.)(?!.*\.$)(?!^\.)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+(?<!\.)@[a-zA-Z0-9\u{00A0}-\u{FFFF}](?:[a-zA-Z0-9\u{00A0}-\u{FFFF}-]*[a-zA-Z0-9\u{00A0}-\u{FFFF}])?(?:\.(?!-)[a-zA-Z0-9\u{00A0}-\u{FFFF}](?:[a-zA-Z0-9\u{00A0}-\u{FFFF}-]*[a-zA-Z0-9\u{00A0}-\u{FFFF}])?)*(?:\.(?!-)[\p{L}\p{M}\p{N}][\p{L}\p{M}\p{N}-]*[\p{L}\p{N}])$/u;
export const numbersAndCharacters = /^[^\s][0-9\W]+[^\s]$/;
export const numericNumber = /^[^\s][\d\s+()-][^\s]$/;
export const countRegex = /^[0-9]\d*$/;
export const website =
  /^(g|h|ftp|https?):\/\/[^ "]+$|^(g|h|https?):[^ "]+$|^(https?:\/\/)?(www\.)?[a-zA-Z0-9\u00C0-\u017F][a-zA-Z0-9\u00C0-\u017F.-]*\.[a-zA-Z\u00C0-\u017F]{2,}(:\d+)?(\/[a-zA-Z0-9\u00C0-\u017F.-]*)*(\?[a-zA-Z0-9\u00C0-\u017F%&=-]*)?(#[a-zA-Z0-9\u00C0-\u017F_.-]*)?$/;
export const specialCharNotAllowed = /^[0-9a-zA-ZøùúûüýþÿØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ\s_]+$/;
export const otherSpecialCharNotAllowed = /^[ A-Za-zÀ-ÖØ-öø-ÿ0-9./,'"-]*$/;
export const someSpecialCharWithSpaceNotAllowed = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9./_[\]]*$/;
export const numberShouldNotStartWithZero = /^[1-9][0-9]*$/;
export const alphaNumeric = /^[A-Za-z0-9]*$/;
export const REGEX_DIGIT_ONLY = /[^0-9]/g;
export const COMMENT_MAX_CHARACTER_MODULE = 8000;
export const onlySpaceNotAccept = /^(?!\s)(?![\s\S]*\s$)[0-9a-zA-ZÀ-ÿ\s_!@#$%^&*()+\-=~`\]{}[;':"/\\|,.<>?]+$/;
export const parseOptions = {
  replace: (domNode) => {
    if (domNode.attribs && domNode.attribs.class === 'remove') {
      return null;
    }
    return null;
  },
};

// TELEPHONE VALIDATOR START

const isValidWithParentheses = (inputString) => {
  const sanitizeWithDash = inputString.replaceAll('-', '');
  // Allow numbers with parentheses enclosing any number of digits,
  const extendedPattern = /^\+?\d*(\(\d+\))?\d{7,}$/;
  if (extendedPattern.test(sanitizeWithDash)) return true;

  if (sanitizeWithDash.indexOf('(') < 0 && sanitizeWithDash.indexOf(')') < 0) return true;
  if (/\(\d{3}\)\d{7}$/.test(sanitizeWithDash)) return true;
  return false;
};

const replaceMultipleCharacters = (inputString) => {
  const result = inputString?.replace(/\D/g, '');
  return result;
};

const hasConsecutiveDashesOrDashAtStart = (input) => {
  // Check for consecutive dashes
  for (let i = 0; i < input.length - 1; i += 1) {
    if (input[i] === '-' && input[i + 1] === '-') return false;
  }
  // Check for a dash at the start
  if (input[0] === '-') return false;
  return true;
};

export const MAX_CHARACTERS = 500;

const isValidString = (input) => {
  // Use a regular expression to match characters other than digits, hyphens, plus signs, and parentheses
  const invalidCharsRegex = /[^0-9\-+()]/g;
  // Remove invalid characters from the input string
  const cleanedInput = input.replace(invalidCharsRegex, '');
  // Check if the cleaned input is the same as the original input
  return cleanedInput === input;
};

export const validateTelephone = (value, name, t1, invalidMsg, maxLengthMsg, minLengthMsg) => {
  if (!value) return true;
  if (value?.trim()?.length === 0) {
    return invalidMsg;
  }
  const testString = value?.trim();
  const sanitizedString = replaceMultipleCharacters(testString);

  if (testString.lastIndexOf('+') > 0) {
    return invalidMsg;
  }
  if (!isValidString(testString)) {
    return invalidMsg;
  }
  if (!hasConsecutiveDashesOrDashAtStart(testString) || testString?.lastIndexOf('-') === testString.length - 1) {
    return invalidMsg;
  }
  if (!isValidWithParentheses(testString) || testString?.match(/[()]/g)?.length > 2) {
    return invalidMsg;
  }

  if (sanitizedString.length < 10) {
    return minLengthMsg;
  }
  if (sanitizedString.length > 20) {
    return maxLengthMsg;
  }
  if (testString.match(/0{10,}/)) {
    return invalidMsg;
  }

  return true;
};

// TELEPHONE VALIDATOR END

export function formatDate(date, joinFormate = '/') {
  if (date === 'NA') return 'N/A';
  if (!date) return null;
  if (date === '--') return '--'; // Return "--" if date is "--"
  const d = new Date(date); // convert string into date format for further date operations
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = String(d.getFullYear());

  // To append '0' in prefix for single digit 'month'
  if (month.length < 2) month = `0${month}`;
  // To append '0' in prefix for single digit 'day'
  if (day.length < 2) day = `0${day}`;
  return [year, month, day].join(joinFormate);
}

export function formatDateTime(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const hour = d.getHours().toString();
  const minutes = d.getMinutes().toString();
  const seconds = d.getSeconds().toString();
  const year = `${d.getFullYear()}`;
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  const dateTime = `${[year, month, day].join('/')} ${[
    hour?.length < 2 ? `0${hour}` : hour,
    minutes?.length < 2 ? `0${minutes}` : minutes,
    seconds?.length < 2 ? `0${seconds}` : seconds,
  ].join(':')}`;
  return dateTime;
}

export function formatDateTime12Hours(date) {
  if (date.toString() === 'Invalid Date') return;
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const hour = d.getHours().toString();
  const ampm = hour >= 12 ? 'PM' : 'AM';
  let hours = hour % 12;
  hours = hours !== 0 ? hours : 12; // the hour '0' should be '12'
  const minutes = d.getMinutes().toString();
  const seconds = d.getSeconds().toString();
  const year = `${d.getFullYear()}`;
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  return `${[year, month, day].join('/')} ${[
    hours?.toString().length < 2 ? `0${hours}` : hours,
    minutes?.length < 2 ? `0${minutes}` : minutes,
    seconds?.length < 2 ? `0${seconds}` : seconds,
  ].join(':')} ${ampm} ET`;
}

export function edtTimeFormat(date, displayET = false, isHour12 = false) {
  if (!date) return null;
  const date1 = new Date(date);
  // "EDT" Time
  const dateTime = date1.toLocaleString('en-US', {
    timeZone: 'America/New_York',
    hour12: isHour12,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
  const year = date1.getFullYear();
  const m = (date1.getMonth() + 1).toString();
  const d = date1.getDate().toString();
  const month = m?.length < 2 ? `0${m}` : m;
  const day = d?.length < 2 ? `0${d}` : d;
  return `${[year, month, day].join('/')} ${dateTime} ${displayET ? 'ET' : 'EDT'}`;
}

export const formatUTC = (dateInt, addOffset = false) => {
  const date = !dateInt || dateInt.length < 1 ? new Date() : new Date(dateInt);
  if (typeof dateInt === 'string') return date;

  const offset = addOffset ? date.getTimezoneOffset() : -date.getTimezoneOffset();
  const offsetDate = new Date();
  offsetDate.setTime(date.getTime() + offset * 60000);
  return offsetDate;
};

// Module 8 constants starts for date/time format >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
export function etTimeFormat(date) {
  if (!date) {
    return null;
  }
  const dateObject = new Date(date);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true, // Use 12-hour clock
    timeZone: 'America/New_York',
  };

  // Format the date and time to a string in reverse order (MM/DD/YYYY, HH:MM:SS AM/PM)
  const reversedFormattedDate = dateObject.toLocaleString('en-US', options);

  // Extract components from the formatted date and time string
  const [, month, day, year, time] = reversedFormattedDate.match(/(\d{1,2})\/(\d{1,2})\/(\d{4}), (.+)/);

  const adjustedHour = time.startsWith('24') ? '00' : time.substring(0, 2);

  // Construct the final formatted date string in the desired format (YYYY/MM/DD HH:MM:SS AM/PM (ET))
  return `${year}/${month}/${day} ${adjustedHour}${time.substring(2)} (ET)`;
}

export function etTimeFormatForReport(date) {
  if (!date) return null;

  const dateObject = new Date(date);

  // Reverse the order of formatting options
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true, // Include AM/PM
    timeZone: 'America/New_York',
  };

  const reversedFormattedDate = dateObject.toLocaleString('en-US', options);
  // Extract individual date components
  const [, month, day, year, time] = reversedFormattedDate.match(/(\d{1,2})\/(\d{1,2})\/(\d{4}), (.+) (.+)/);

  const adjustedHour = time.startsWith('24') ? '00' : time.substring(0, 2);

  // Rearrange the components in "YYYY_MM_DD HH_mm_ss (ET)" format
  return `${year}-${month}-${day}_${adjustedHour}${time.substring(3, 5)}`;
}

export function FormatOnlyDateWithoutTimezoneConversion(date) {
  if (!date) return null;

  const dateObject = new Date(date);

  // Reverse the order of formatting options
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC', // Set timeZone option to UTC
  };

  // Format the date and time to a string in reverse order (MM/DD/YYYY, HH:MM:SS AM/PM)
  const reversedFormattedDate = dateObject.toLocaleString('en-US', options);

  // Extract components from the formatted date and time string
  const [, month, day, year] = reversedFormattedDate.match(/(\d{1,2})\/(\d{1,2})\/(\d{4}), (.+)/);

  // Rearrange the components in "YYYY-MM-DD" format
  return `${year}/${month}/${day}`;
}

export function etTimeFormatOnlyDate(date) {
  if (!date) return null;

  const dateObject = new Date(date);

  // Reverse the order of formatting options
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'America/New_York',
  };

  // Format the date and time to a string in reverse order (MM/DD/YYYY, HH:MM:SS AM/PM)
  const reversedFormattedDate = dateObject.toLocaleString('en-US', options);

  // Extract components from the formatted date and time string
  const [, month, day, year] = reversedFormattedDate.match(/(\d{1,2})\/(\d{1,2})\/(\d{4}), (.+)/);

  // Rearrange the components in "YYYY/MM/DD" format
  return `${year}/${month}/${day}`;
}

export function etTimeFormatViewEvent(date) {
  if (!date) return null;

  const dateObject = new Date(date);

  // Extract individual components for custom formatting
  const day = dateObject.getUTCDate(); // Use getUTCDate to ensure no timezone conversion
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(dateObject);
  const year = dateObject.getUTCFullYear(); // Use getUTCFullYear to ensure no timezone conversion
  const hours = dateObject.getUTCHours();
  const minutes = dateObject.getUTCMinutes();
  const seconds = dateObject.getUTCSeconds();

  // Format the time part
  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0'); // Ensure 2-digit hour format
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');
  const timePart = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
  return `${day} ${month} ${year} - ${timePart} (ET)`;
}

export function etTimeFormatEditEvent(date) {
  if (!date) return null;

  const dateObject = new Date(date);

  // Reverse the order of formatting options
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true, // Use 12-hour clock
    timeZone: 'UTC',
  };

  const reversedFormattedDate = dateObject.toLocaleString('en-US', options);

  // Extract individual date components
  const [, month, day, year, time] = reversedFormattedDate.match(/(\d+)\/(\d+)\/(\d+), (.+)/);

  // Handle the case where the hour is 24
  const adjustedHour = time.startsWith('24') ? '00' : time.substring(0, 2);

  // Rearrange the components in "YYYY/MM/DD" format
  return `${year}/${month}/${day} ${adjustedHour}${time.substring(2)} (ET)`;
}

export function etTimeFormatEditResetBtn(date) {
  if (!date) return null;

  const dateObject = new Date(date);

  // Reverse the order of formatting options
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true, // Use 12-hour clock
    timeZone: 'America/New_York',
  };

  const reversedFormattedDate = dateObject.toLocaleString('en-US', options);

  // Extract individual date components
  const [, month, day, year, time] = reversedFormattedDate.match(/(\d+)\/(\d+)\/(\d+), (.+)/);

  // Handle the case where the hour is 24
  const adjustedHour = time.startsWith('24') ? '00' : time.substring(0, 2);

  // Rearrange the components in "YYYY/MM/DD" format
  return `${year}/${month}/${day} ${adjustedHour}${time.substring(2)} (ET)`;
}

export const etTimeFormatresetbutton = (dateInput) => {
  const utcDate = new Date(dateInput);

  // Determine the Eastern Time (ET) offset
  const etOffset = new Date(utcDate.getFullYear(), 3, 14).getTimezoneOffset() === utcDate.getTimezoneOffset() ? -4 : -5;

  // Format date components
  const year = utcDate.getFullYear();
  const month = String(utcDate.getMonth() + 1).padStart(2, '0');
  const day = String(utcDate.getDate()).padStart(2, '0');
  const hours = String(utcDate.getHours()).padStart(2, '0');
  const minutes = String(utcDate.getMinutes()).padStart(2, '0');
  const seconds = String(utcDate.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${etOffset < 0 ? '-' : '+'}${Math.abs(
    etOffset,
  )
    .toString()
    .padStart(2, '0')}:00`;
};

export function etTimeFormatEditEventSubmit(date) {
  if (!date) return null;

  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');
  const hours = String(dateObject.getHours()).padStart(2, '0');
  const minutes = String(dateObject.getMinutes()).padStart(2, '0');
  const seconds = String(dateObject.getSeconds()).padStart(2, '0');
  const milliseconds = String(dateObject.getMilliseconds()).padStart(3, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
}

export function etTimeFormatForCurrentEtDateTime(date) {
  if (!date) return null;

  // Convert the input date to Eastern Time (ET)
  const etTime = moment.tz(date, 'America/New_York');

  // Format the ET date to the desired format
  return etTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
}

export function etTimeFormatForGenerate(date) {
  if (!date) return null;

  const dateObject = new Date(date);

  dateObject.setSeconds(dateObject.getSeconds() + 30);

  // Reverse the order of formatting options
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'America/New_York',
  };

  const reversedFormattedDate = dateObject.toLocaleString('en-US', options);

  // Extract individual date components
  const [, month, day, year, time] = reversedFormattedDate.match(/(\d{1,2})\/(\d{1,2})\/(\d{4}), (.+)/);

  // Handle the case where the hour is 24
  const adjustedHour = time.startsWith('24') ? '00' : time.substring(0, 2);

  // Rearrange the components in "YYYY/MM/DD" format
  return `${year}/${month}/${day} ${adjustedHour}${time.substring(2)} (ET)`;
}

// Module 8 constants ends for date/time format <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

export const maximumDate = new Date('2099-12-31');

export const MAX_DATE_ET_STR = '2099-12-31T00:00:00-05:00';

export const MIN_DATE_ET_STR = '2010-01-01T00:00:00-05:00';

export function formatTimeOnly(date) {
  const d = new Date(date);
  const hour = d.getHours().toString();
  const minutes = d.getMinutes().toString();
  return `${[hour?.length < 2 ? `0${hour}` : hour, minutes?.length < 2 ? `0${minutes}` : minutes].join(':')}`;
}

export function formatLongDate(date) {
  const currentDate = new Date(date);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return currentDate.toLocaleDateString('en-us', options);
}

export const urlValidate = (urlString) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const passwordValidater = (value) => {
  const passwordValidation = {
    length: false,
    alphaNum: false,
    upperLowercase: false,
    number: false,
    speciaChar: false,
    nonPrinting: false,
    nonEmoji: false,
  };

  passwordValidation.length = value.length >= 8 && value.length <= 50;
  passwordValidation.alphaNum = alphaNumPattern.test(value[0]?.trim()) && alphaNumPattern.test(value[value.length - 1]);
  passwordValidation.upperLowercase = upperLowercasePattern.test(value);
  passwordValidation.number = numberPattern.test(value);
  passwordValidation.speciaChar = speciaCharPattern.test(value) && !passwordAvoidCharPattern.test(value);

  passwordValidation.nonPrinting = !nonPrintingSpecial.test(value);
  passwordValidation.nonEmoji = !emojiPattern.test(value);
  return passwordValidation;
};

export const secretKeyValidater = (value) => {
  const validSecretkeyPattern =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])[^'"\\]*$/;

  const secretkeyValidation = {
    validSecretkeyPattern: false,
  };

  if (validSecretkeyPattern.test(value)) {
    secretkeyValidation.validSecretkeyPattern = true;
  } else {
    secretkeyValidation.validSecretkeyPattern = false;
  }

  return secretkeyValidation;
};

export function getConcatedString(item, join) {
  return item
    ?.join(join)
    .replace(/undefined|null/gi, '')
    .replace(/  +/g, ' ');
}

export const requestUserColumns = (t) =>
  t && [
    {
      id: 1,
      field: 'requestStatus',
      headerName: t('REQUEST_STATUS'),
      flex: 1,
      align: 'center',
      renderCell: ({ row }) => (
        <Typography variant="body2">{t(`${getCMSKey('RequestStatus', row.requestStatus)}`)}</Typography>
      ),
    },
    {
      id: 2,
      field: 'orgJuris',
      headerName: t('ORIGINAL_JURISDICTION'),
      flex: 1,
      align: 'center',
      renderCell: ({ row }) => (
        <Typography variant="body2">{t(`${getCMSKeySnakeCase(row.orgJuris || '')}`)}</Typography>
      ),
    },
    {
      id: 3,
      field: 'newJuris',
      headerName: t('WELCOMING_JURISDICTION'),
      flex: 1,
      renderCell: ({ row }) => (
        <Typography variant="body2">{t(`${getCMSKeySnakeCase(row.newJuris || '')}`)}</Typography>
      ),
      align: 'center',
    },
    {
      id: 4,
      field: 'userStatus',
      headerName: t('APPLICANT_USER_STATUS'),
      renderCell: ({ row }) => (
        <Typography variant="body2">{t(`${getCMSKey('UserStatus', row.userStatus)}`)}</Typography>
      ),
      flex: 1,
      align: 'center',
    },
    {
      id: 5,
      field: 'requestDate',
      headerName: t('REQUEST_INITIATION_DATE'),
      flex: 1,
      align: 'center',
    },
    {
      id: 7,
      field: 'comment',
      headerName: t('COMMENT'),
      flex: 1,
      align: 'center',
    },
    {
      id: 8,
      field: 'denialReason',
      flex: 1,
      align: 'center',
    },
  ];

export const duplicateUserColumns = (t) =>
  t && [
    { id: 1, field: 'firstName', headerName: t('FIRST_NAME'), flex: 1, align: 'center' },
    { id: 2, field: 'lastName', headerName: t('LAST_NAME'), flex: 1, align: 'center' },
    {
      id: 3,
      field: 'birthDate',
      headerName: t('DATE_OF_BIRTH'),
      flex: 1,
      align: 'center',
    },
    {
      id: 4,
      field: 'email',
      headerName: t('EMAIL'),
      flex: 1,
      align: 'center',
    },
    {
      id: 5,
      field: 'userId',
      headerName: t('USER_ID'),
      flex: 1,
      align: 'center',
    },
    {
      id: 6,
      field: 'role',
      headerName: t('ROLE'),
      flex: 1,
      align: 'center',
    },
    {
      id: 7,
      field: 'userStatus',
      headerName: t('APPLICANT_USER_STATUS'),
      renderCell: ({ row }) => (
        <Typography variant="body2">{t(`${getCMSKey('UserStatus', row.userStatus)}`)}</Typography>
      ),
      flex: 1,
      align: 'center',
    },
    {
      id: 8,
      field: 'accountStatus',
      headerName: t('ACCOUNT_STATUS'),
      renderCell: ({ row }) => (
        <Typography variant="body2">{t(`${getCMSKey('UserAccountStatus', row.accountStatus)}`)}</Typography>
      ),
      flex: 1,
      align: 'center',
    },
    {
      id: 9,
      field: 'jurisdiction',
      headerName: t('JURISDICTION'),
      renderCell: ({ row }) => <Typography variant="body2">{t(`${row.jurisdiction}`)}</Typography>,
      flex: 1,
      align: 'center',
    },
    {
      id: 10,
      field: 'requestStatus',
      headerName: t('REQUEST_STATUS'),
      renderCell: ({ row }) => (
        <Typography variant="body2">{t(`${getCMSKey('RequestStatus', row.requestStatus)}`)}</Typography>
      ),
      flex: 1,
      align: 'center',
    },
  ];

export const getStatusBasedUrl = (status, userDetails, t, t1) => {
  if (isRolesAssigned(userDetails, [USER_ROLE_TYPES.JURISDICTION_USER], [USER_ROLES.REGISTRAR])) {
    if (status === t(t1, ENTITY_APPLICTION_STATUS_NAMES.PENDING)) {
      return `/jurisdiction-entity-actions/`;
    }
  }
  return `/entity-profile/`;
};

export const getDuplicateEntitiesColumnsData = (t, t1 = {}, duplicateEntities = {}, userDetails = {}) => {
  const duplicateEntitiesValuesForColumn = getFilterForColumn(duplicateEntities?.data, t, t1, [
    'entityType',
    'entityStatus',
  ]);
  return (
    t && [
      {
        id: 1,
        field: 'entityId',
        headerName: t(t1, 'ENTITY_ID'),
        hideable: false,
        groupable: false,
        flex: 1,
        align: 'center',
        minWidth: 260,
        renderCell: (params) => {
          const EntityId = params?.row?.entityId;
          const url = `${getStatusBasedUrl(params?.row.entityStatus, userDetails, t, t1)}${params?.row?.id}`;

          return (
            <NavLink
              className="link-cell"
              to={url}
            >
              {' '}
              {EntityId}{' '}
            </NavLink>
          );
        },
      },
      { id: 2, field: 'legalName', headerName: t(t1, 'LEGAL_NAME'), flex: 1, align: 'center', minWidth: 280 },
      {
        id: 3,
        field: 'entityType',
        headerName: t(t1, 'ENTITY_TYPE'),
        flex: 1,
        align: 'center',
        minWidth: 544,
        type: 'singleSelect',
        valueOptions: duplicateEntitiesValuesForColumn?.entityType,
      },
      {
        id: 4,
        field: 'entityStatus',
        headerName: t(t1, 'ENTITY_STATUS'),
        flex: 1,
        align: 'center',
        minWidth: 300,
        type: 'singleSelect',
        valueOptions: duplicateEntitiesValuesForColumn?.entityStatus,
      },
    ]
  );
};

export const getEntityStatusHistoryData = (t, t1 = {}, entityHistory = {}) => {
  const entityStatusHistoryValuesForColumn = getFilterForColumn(entityHistory?.data, t, t1, [
    'requestStatus',
    'jurisdiction',
    'entityStatus',
  ]);
  return (
    t && [
      {
        id: 1,
        field: 'requestStatus',
        headerName: t(t1, 'REQUEST_STATUS'),
        flex: 2,
        align: 'center',
        type: 'singleSelect',
        valueOptions: entityStatusHistoryValuesForColumn?.requestStatus,
        minWidth: 350,
      },
      {
        id: 2,
        field: 'jurisdiction',
        headerName: t(t1, 'JURISDICTION'),
        flex: 1,
        align: 'center',
        type: 'singleSelect',
        valueOptions: entityStatusHistoryValuesForColumn?.jurisdiction,
        minWidth: 200,
      },
      {
        id: 3,
        field: 'entityStatus',
        headerName: t(t1, 'ENTITY_STATUS'),
        align: 'center',
        type: 'singleSelect',
        valueOptions: entityStatusHistoryValuesForColumn?.entityStatus,
        minWidth: 300,
      },
      {
        id: 4,
        field: 'updateDate',
        headerName: `${t(t1, 'CHANGE_DATE')}`,
        flex: 1,
        align: 'center',
        renderCell: (params) => <span>{removeTimeZone(formatDateUpdated(params?.row?.updateDate))}</span>,
        valueGetter: (cellValues) => ` ${removeTimeZone(formatDateUpdated(cellValues?.row?.updateDate))}`,
        minWidth: 300,
      },
      {
        id: 5,
        field: 'requestStatusUpdateDate',
        headerName: `${t(t1, 'REQUEST_STATUS_UPDATE_DATE')}`,
        flex: 2,
        align: 'center',
        renderCell: (params) => <span>{removeTimeZone(formatDateUpdated(params?.row?.requestStatusUpdateDate))}</span>,
        valueGetter: (cellValues) => ` ${removeTimeZone(formatDateUpdated(cellValues?.row?.requestStatusUpdateDate))}`,
        minWidth: 300,
      },
      {
        id: 6,
        field: 'denialReason',
        headerName: t(t1, 'DENIAL_REASON'),
        flex: 1,
        align: 'center',
        minWidth: 200,

        renderCell: (params) => (
          <StyledCellContent>
            {params?.row?.denialReason === null || params?.row?.denialReason === undefined
              ? 'N/A'
              : t(t1, params?.row?.denialReason)}
          </StyledCellContent>
        ),
        valueGetter: ({ value }) => t(t1, value),
      },
      {
        id: 7,
        field: 'comment',
        headerName: t(t1, 'COMMENT'),
        flex: 1,
        align: 'center',
        minWidth: 200,
      },
    ]
  );
};

export const getFacilityOwnerHistoryData = (t, entityStatus, entityId, isJurisdictionRegistrarUser) =>
  t && [
    {
      id: 1,
      field: 'entityId',
      headerName: t('ENTITY_ID'),
      flex: 1,
      align: 'center',
      groupable: false,
      hideable: false,
      renderCell: (params) => {
        const entityLink = params?.row?.entityId;
        return params?.row?.canViewEntity ? (
          <NavLink
            className="link-cell"
            to={
              isJurisdictionRegistrarUser && params?.row?.entityStatus === ENTITY_ACCOUNT_STATUSES.PENDING
                ? `/jurisdiction-entity-actions/${params?.row?.entityNavigateLink}`
                : `/entity-profile/${params?.row?.entityNavigateLink}`
            }
          >
            {entityLink}
          </NavLink>
        ) : (
          <span>{entityLink}</span>
        );
      },
    },
    { id: 2, field: 'entityLegalName', headerName: t('ENTITY_LEGAL_NAME'), flex: 1, align: 'center' },
    {
      id: 3,
      field: 'entityOperatingName',
      headerName: t('ENTITY_OPERATING_NAME'),
      flex: 1,
      align: 'center',
    },
    {
      id: 4,
      field: 'status',
      headerName: t('STATUS'),
      flex: 1,
      align: 'center',
    },
    {
      id: 5,
      field: 'effectiveDate',
      headerName: `${t('EFFECTIVE_DATE_YYYYDDMM')}`,
      flex: 1,
      align: 'center',
      valueGetter: (cellValues) =>
        ` ${formatDate(cellValues?.row?.effectiveDate ? cellValues?.row?.effectiveDate : 'NA').substring(0, 10)}`,
      renderCell: (params) => {
        const effectDateSetNull =
          [
            ENTITY_APPLICTION_STATUS_NAMES.APPLICATION_IN_PROGRESS,
            ENTITY_APPLICTION_STATUS_NAMES.PENDING,
            ENTITY_APPLICTION_STATUS_NAMES.REVISIONS_REQUESTED,
          ].includes(entityStatus) && params?.row?.status === 'Proposed';
        return (
          <span>
            {' '}
            {params?.row?.effectiveDate && !effectDateSetNull && params?.row?.effectiveDate !== 'undefined'
              ? formatDate(params?.row?.effectiveDate)
              : `${t('NA')}`}{' '}
          </span>
        );
      },
    },
    {
      id: 6,
      field: 'retiredDate',
      headerName: `${t('RETIREMENT_DATE_YYYYDDMM')}`,
      flex: 1,
      align: 'center',
      valueGetter: (cellValues) =>
        ` ${formatDate(cellValues?.row?.retiredDate ? cellValues?.row?.retiredDate : 'NA').substring(0, 10)}`,
      renderCell: (params) => (
        <span>
          {params?.row?.retiredDate && params?.row?.retiredDate !== 'undefined'
            ? formatDate(params?.row?.retiredDate)
            : `${t('NA')}`}
        </span>
      ),
    },
    {
      id: 7,
      field: 'comment',
      headerName: t('COMMENT'),
      flex: 1,
      align: 'center',
    },
  ];

export const roleChangeColumns = (t) =>
  t && [
    {
      id: 1,
      field: 'roleType',
      headerName: t('ROLE_TYPE'),
      flex: 1,
      align: 'center',
    },
    {
      id: 2,
      field: 'role',
      headerName: t('ROLE'),
      flex: 1,
      align: 'center',
    },
    {
      id: 4,
      field: 'jurisdiction',
      headerName: t('JURISDICTION'),
      flex: 1,
      align: 'center',
      renderCell: ({ row }) => <Typography variant="body2">{t(`${getCMSKeySnakeCase(row.jurisdiction)}`)}</Typography>,
    },
    {
      id: 5,
      field: 'updatedDateTime',
      headerName: t('DATE_TIME_UPDATED_EDT'),
      flex: 1,
      align: 'center',
    },
  ];

export const updatedByColumnInfo = (t) => ({
  id: 6,
  field: 'updatedBy',
  headerName: t('UPDATED_BY'),
  flex: 1,
  align: 'center',
});

export const usersTable = (t) =>
  t && [
    { id: 1, field: 'Name', headerName: t('FIRST_NAME'), flex: 1, align: 'center' },
    { id: 2, field: 'FamilyName', headerName: t('LAST_NAME'), flex: 1, align: 'center' },
    {
      id: 3,
      field: 'OrgID',
      headerName: t('ORGANISATION'),
      flex: 1,
      align: 'center',
      renderCell: ({ row }) => <Typography variant="body2">{t(`${row.OrgID}`)}</Typography>,
    },
    {
      id: 4,
      field: 'Email',
      headerName: t('EMAIL'),
      flex: 1,
      align: 'center',
    },
    {
      id: 5,
      field: 'UserStatus',
      headerName: t('STATUS'),
      flex: 1,
      align: 'center',
      renderCell: ({ row }) => (
        <Typography variant="body2">{t(`${getCMSKey('UserAccountStatus', row?.UserStatus)}`)}</Typography>
      ),
    },
    {
      id: 6,
      field: 'RoleType',
      headerName: t('USER_ROLE'),
      flex: 1,
      align: 'center',
    },
  ];

// Applicant/User Status
export const PendingStatus = 'Pending';
export const ActiveStatus = 'Active';
export const RevisionStatus = 'Revisions Requested';
export const ApprovedStatus = 'Approved';
export const ServiceProviderUser = 'Service Providers';
export const JurisdictionUser = 'Jurisdiction Users';
export const RegistrarUser = 'Registrar';
export const IndividualUser = 'Individual Users';
export const SYSTEM_USER = 'Technical Admin/System Users';
export const WCIIncAdmin = 'WCI, Inc. Admin';
export const InactiveStatus = 'Inactive';
export const CancelStatus = 'Cancelled';
export const ClosedStatus = 'Closed';
export const DenyStatus = 'Registration Denied';
export const RegistrationApproved = 'Registration Approved';
export const ProposetoAuthorityStatus = 'Proposed to Authority';
export const RegistrarRole = 'Registrar';
export const AuthorityRole = 'Authority';
export const JuriAdmin = 'Administrator';
export const ApprovedCorssJu = 'Approved Cross Jurisdiction';
export const LoginPermanentLocked = 'Login Permanently Locked';
export const LoginTemporaryLocked = 'Login Temporarily Locked';
export const ProfileAccessOnly = 'Profile Access only';
export const ProposedUserDetailChange = 'Proposed User Details Change';
export const ProposedRoleChange = 'Proposed Role Change';
export const ApplicantRole = 'Applicant';
export const PublicUserRole = 'Public User';
export const CancelByApplicant = 'Cancelled by Applicant';
export const CancelByJurisdiction = 'Cancelled by Jurisdiction';
export const PendingCOJStatus = 'Pending COJ';
export const PendingCJStatus = 'PENDING CROSS JURISDICTION';
export const PendingCrossJurisdictionStatus = 'Pending Cross Jurisdiction';
export const MarketMonitor = 'Market Monitor';
export const FSAHome = 'Financial Service Providers';
export const AuctionAdministrator = 'Auction Administrator';
export const PassphraseReset = 'Passphrase Reset';

// Change jurisdiction staus
export const ChangeJuriRequest = 'ChangeJurisdictionRequest';
export const ChangeCancelJuriRequest = 'CancelChangeJurisdictionRequest';
export const ApproveChangeJuriRequest = 'ApproveChangeJurisdictionRequest';
export const DenyChangeJuriRequest = 'DenyChangeJurisdictionRequest';

// Cross Jurisdiction status
export const CrossJuriRequest = 'CrossJurisdictionRequest';
export const CrossCancelJuriRequest = 'CrossJurisdictionRequestCancel';
export const ApproveCrossJuriRequest = 'ApproveCrossJurisdictionRequest';
export const DenyCrossJuriRequest = 'DenyCrossJurisdictionRequest';
export const UnshareCrossJuriRequest = 'UnshareCrossJurisdictionRequest';

// change password anc security questions
export const limitExceedException = 'LimitExceededException';
export const notAuthorizedException = 'NotAuthorizedException';
export const limitMessage = 'MSG_ERR_LIMIT';
export const notAuthorizedMessage = 'MSG_ERR_INCORRECT_PASS';
export const unknownErrorMessage = 'MSG_ERR_E_TAG__EXCEPTION';
export const invalidParameterException = 'InvalidParameterException';
export const previousPasswordApiErrorMsg = 'previousPassword';

// CMS send constants to fetch different data in same API.
// Constants for various "QueryType" parameter value
export const getAllData = 'get_all_pages_and_all_languages';
export const getAllLang = 'get_all_languages';
export const getAllPages = 'get_all_pages_and_all_labels';
export const getAllLabels = 'get_all_labels';
export const getFormLabels = 'get_form_labels';
export const getNewLanguage = 'add_new_language';
export const addUpdateLabels = 'add_update_labels';
export const enLanguage = () => localStorage.getItem('i18nextLng') || 'English';
export const languageCode = () => localStorage.getItem('languageCode') || 'L001';
export const publishSavedData = 'publish_saved_data';
export const searchInOrg = 'search_in_organization';
export const publishType = 'publish_saved_data';
export const getAllPageLabels = 'get_all_labels_for_given_org_lang';
export const getUrl = 'GET_URL';
export const uploadSuccess = 'UPLOAD_SUCCESS';
export const saveUrl = 'SAVE_URL';
export const deleteFile = 'DELETE_FILE';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const SUCCESSFULLY_SAVED = 'Successfully Saved';
export const APPROVED = 'approve';
export const NO_OPTIONS = 'No Options';

// Constants for common "Page" parameter value
export const AllPageName = 'Page';
export const docFilesPageName = 'Page_DocFiles';
export const LandingPageName = 'Page_LandingPageKnown';
export const HomePageName = 'Page_UserHome';
export const ResetPassQuesPageName = 'Page_ResetPass&SecurityQuestion';
export const HeaderImageLabelText = 'LANDING_KNOWN_IMAGE'; // Banner image SK postfix name
export const defaultORGID = localStorage.getItem('jurisdiction') || 'ORG#00101';

const getOrdIdForEntityProfileAndCojFlow = () => {
  if (window.location.pathname.includes('entity-profile')) {
    const jurisdictionDetails = storeInstance.getState().jurisdiction.jurisdictionDetails;
    const juriName = storeInstance.getState().entityProfile?.entities?.data?.jurisdiction?.name;
    const selectedJuri = jurisdictionDetails?.find(
      (juriItem) => juriItem?.Name?.toUpperCase() === juriName?.toUpperCase(),
    );
    if (selectedJuri?.dynamoId) {
      // return selectedJuri?.dynamoId?.split("#")?.pop();
      return selectedJuri?.dynamoId;
    }
  } else if (
    window.location.pathname.includes('change-jurisdiction') ||
    window.location.pathname.includes('cross-jurisdiction')
  ) {
    if (!isEmpty(localStorage.getItem('change_cross_jurisdiction'))) {
      // return localStorage.getItem("change_cross_jurisdiction")?.split("#")?.pop();
      return localStorage.getItem('change_cross_jurisdiction');
    }
  }
  return -1;
};

export const getCurrentORGID = () => {
  let count = 0;
  let orgID = '';
  if (
    window.location.pathname.includes('entity-profile') ||
    window.location.pathname.includes('change-jurisdiction') ||
    window.location.pathname.includes('cross-jurisdiction')
  ) {
    if (storeInstance === null || i18nInstance === null) {
      const interval = setInterval(() => {
        count += 1;
        if (i18nInstance !== null && storeInstance !== null) {
          orgID = getOrdIdForEntityProfileAndCojFlow();
          if (orgID !== -1) {
            i18nInstance.changeLanguage(`${decodeURIComponent(orgID)}#${languageCode()}`);
            clearInterval(interval);
            return orgID;
          }
        }
        if (count > 100) {
          clearInterval(interval);
          orgID = !isEmpty(localStorage.getItem('jurisdiction'))
            ? localStorage.getItem('jurisdiction')?.split('#')?.pop()
            : '00101';
          i18nInstance.changeLanguage(`${decodeURIComponent(orgID)}#${languageCode()}`);
          return orgID;
        }
      }, 2000);
    } else {
      orgID = getOrdIdForEntityProfileAndCojFlow();
      console.log('storeInstance loaded', storeInstance, i18nInstance, orgID);
      if (orgID !== -1) {
        // i18nInstance.changeLanguage(`${decodeURIComponent(orgID)}#${languageCode()}`);
        return orgID;
      }
    }
  }
  return localStorage.getItem('jurisdiction') || process.env.REACT_APP_DEFAULT_ORGID;
};
export const imgJPGFileType = 'JPG';
export const imgPNGFileType = 'PNG';
export const imgType = 'IMG';
export const fileType = 'FILE';
export const getFileType = 'File';
export const urlType = 'URL';
// type of cognito error
export const userNotFoundException = 'UserNotFoundException';
export const userNotAuthorizedException = 'NotAuthorizedException';

// purchase limit parameters
export const PL_PERCENTAGE = 'Percentage';
export const PL_VALUE = 'Value';
/**
 * to verify the argument value/string is null, undefined, empty or length is 0 then true else false.
 * @param {any} checkValue multiple parameters which are string variables.
 * @returns boolean value.
 */

export const isEmpty = (checkValue) =>
  checkValue === null ||
  checkValue === 'undefined' ||
  checkValue === 'null' ||
  checkValue === undefined ||
  checkValue === '' ||
  checkValue.length === 0 ||
  checkValue === 'false' ||
  Object.keys(checkValue).length === 0;

const replacePlaceholders = (str, values) =>
  // Use regular expression to find placeholders like {id}
  str.replace(/{(.*?)}/g, (match, key) =>
    // key will be 'id' or 'number'
    values[key] || match); // Replace with value from 'values' object or keep the placeholder if not found

export const t = (trans, arg1, param) => {
  if (!arg1) return arg1;
  let str = trans([arg1, '']);
  if (arg1 && str) {
    // for dynamic param if exists then replace those with values
    if (typeof param === 'object') {
      str = replacePlaceholders(str, param);
    }
    if (str?.toLocaleString()?.indexOf(CARD_PARSE_ID) > 0) {
      return HTMLReactParser(str);
    }
    return str;
  }
  return arg1;
};

export const tPreviewGetEdited = (arg1) => {
  const state = storeInstance?.getState();
  const editedValues = state?.cms?.preview?.keyValues || {};
  if (arg1 && editedValues[arg1]) {
    return editedValues[arg1];
  }
  const previewValues = state?.cms?.previewPageData || {};
  if (arg1 && previewValues?.[arg1]) {
    return previewValues?.[arg1];
  }
  return arg1;
};

export const tPreview = (trans, arg1) => {
  const state = storeInstance?.getState();
  const editedValues = state?.cms?.preview?.keyValues || {};
  if (arg1 && editedValues[arg1]) {
    const str = editedValues[arg1];
    if (str?.toLocaleString()?.indexOf(CARD_PARSE_ID) > 0) {
      return HTMLReactParser(str);
    }
    return editedValues[arg1];
  }
  const previewValues = state?.cms?.previewPageData || {};
  if (arg1 && previewValues?.[arg1]) {
    const str = previewValues?.[arg1];
    if (str?.toLocaleString()?.indexOf(CARD_PARSE_ID) > 0) {
      return HTMLReactParser(str);
    }
    return previewValues?.[arg1];
  }
  return arg1;
};
export const displayParsedString = (trans, arg1) => {
  const str = trans([arg1, '']);
  if (str.toLocaleString().indexOf(CARD_PARSE_ID) > 0) return HTMLReactParser(str);
  return trans([arg1, '']);
};
export const onlyDocList = ['TERMS_AND_CONDITIONS_TEXT', 'WCI_TERMS_OF_USE'];

export const isEqual = (arr1, arr2) =>
  Array.isArray(arr1) &&
  Array.isArray(arr2) &&
  arr1.length === arr2.length &&
  arr1.every((i) => arr2.some((j) => JSON.stringify(j) === JSON.stringify(i)));

export const getCurrentPageName = () => localStorage.getItem('pageName') || LandingPageName;

export const scrollToTop = () => window.scroll(0, 0);

export const RouteConst = {};
RouteConst.UserProfile = '/user-profile';
RouteConst.UserDetails = '/view-user-details';
RouteConst.ChangeJurisdiction = '/change-jurisdiction';
RouteConst.Home = '/home';
RouteConst.ResetPassword = '/reset-password';

// to validate city, street1, street2, region, job, employer
export const validateAccentuatedValues = (validationMsg, value, name) => {
  if (value.split('')[0] === ' ') {
    return `${validationMsg} ${name}`;
  }
  if (value.split('')[value.length - 1] === ' ') {
    return `${validationMsg} ${name}`;
  }
  const alphanumRegex = 'A-Za-z0-9';
  const specialCharsRegex = '\\s^$*.[\\]{}()?!@#%&\\/\\\\,><\':;|_~`+=\\-"';
  const fullRegex = new RegExp(
    `^[${alphanumRegex}${specialCharsRegex}]*[${alphanumRegex}]+[${alphanumRegex}${specialCharsRegex}]*$`,
  );

  if (!fullRegex.test(value)) {
    return `${validationMsg} ${name}`;
  }
};

const checkIfConsecutiveChar = (value, char, index) => {
  if (value.charAt(index) === char && value.charAt(index + 1) === char) {
    return true;
  }
  return false;
};

export const validatePhoneNumber = (
  value,
  name,
  t1,
  minLength,
  maxLength,
  invalidMsg,
  notAvailMsg,
  currentTelephone = '',
) => {
  const valueLength = value.length;
  if (t1) {
    if (valueLength > 20) {
      return `${maxLength}`;
    }
    if (valueLength < 10) {
      return `${minLength}`;
    }
    if (valueLength <= 20 && valueLength >= 10) {
      // check if enetered telephone is current user's telephone then show error
      if (
        currentTelephone?.replace('+', '') === value?.replace('+', '') &&
        currentTelephone?.replace('(', '') === value?.replace('(', '') &&
        currentTelephone?.replace(')', '') === value?.replace(')', '')
      ) {
        return `${notAvailMsg}`;
      }
      // Note: this is changed to support Canada n US numbers only across all modules
      // with or without +
      const index1 = value.indexOf('(');
      const index2 = value.indexOf(')');
      const regexForPhone = /^[+().-\d\s]+$/;
      let isPass = true;
      for (let i = 0; i < value.length; i += 1) {
        if (i + 1 < value.length) {
          if (checkIfConsecutiveChar(value, '.', i)) {
            isPass = false;
            break;
          }
          if (checkIfConsecutiveChar(value, '(', i)) {
            isPass = false;
            break;
          }
          if (checkIfConsecutiveChar(value, ')', i)) {
            isPass = false;
            break;
          }
          if (checkIfConsecutiveChar(value, '-', i)) {
            isPass = false;
            break;
          }
          if (checkIfConsecutiveChar(value, '+', i)) {
            isPass = false;
            break;
          }
        }
      }
      if (
        !(
          (index1 === -1 && index2 > -1) ||
          (index1 > -1 && index2 === -1) ||
          (index1 > -1 && index2 > -1 && index1 >= index2)
        ) &&
        isPass
      ) {
        if (phone(value)?.isValid && regexForPhone.test(value)) {
          return null;
        }
      }
      return `${invalidMsg}`;
    }
  }
};
export const verifyCityOrRegion = (
  value,
  name,
  t1,
  minLengthMsg,
  maxLengthMsg,
  invalidMsg,
  options = {},
) => {
  const { minLength, maxLength } = options;
  if (value.split('')[0] === ' ') {
    return `${invalidMsg}`;
  }
  if (value.split('')[value.length - 1] === ' ') {
    return `${invalidMsg}`;
  }
  if (!value.match(/^\S.*$/gm)) {
    return `${invalidMsg}`;
  }
  if (!isEmpty(value)) {
    const splittedValue = value?.split('');
    // eslint-disable-next-line no-restricted-syntax
    for (const i in splittedValue) {
      if (splittedValue[0] === ' ' || (splittedValue[i] === ' ' && splittedValue[i - 1] === ' ')) {
        return `${invalidMsg}`;
      }
    }
  }
  // Regex to disallow all zeros
  if (/^0+$/.test(value) || / 0+ /.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  if (minLength && value.length < minLength) return minLengthValidation(minLength, value, name, t1, minLengthMsg);
  if (maxLength && value.length > maxLength) return maxLengthValidation(maxLength, value, name, t1, maxLengthMsg);
  if (cityOrRegionNamePattern && !cityOrRegionNamePattern.test(value)) {
    return `${invalidMsg}`;
  }

};

export const validateFirstName = (value, name, t1) => {
  if (checkingMultipleSpacetextbox(value)) {
    return `${t(t1, 'ERR_INVALID_FIRST_NAME')}`;
  }
  if (!firstnamePattern.test(value)) {
    return `${t(t1, 'ERR_INVALID_FIRST_NAME')}`;
  }
  return null;
};

export const validateMiddleNames = (value, name, t1) => {
  if (checkingMultipleSpacetextbox(value)) {
    return `${t(t1, 'ERR_INVALID_MIDDLE_NAME')}`;
  }
  if (!middleNamePattern.test(value)) {
    return `${t(t1, 'ERR_INVALID_MIDDLE_NAME')}`;
  }
  return null;
};

export const validateSalutationPattern = (value, name, t1) => {
  if (checkingMultipleSpacetextbox(value)) {
    return `${t(t1, 'ERR_INVALID_SALUTATION')}`;
  }
  if (!salutationPattern.test(value)) {
    return `${t(t1, 'ERR_INVALID_SALUTATION')}`;
  }
  if (salutationSpecialCharPattern.test(value?.toString()?.trim()?.split('')?.[0])) {
    return `${t(t1, 'ERR_INVALID_SALUTATION')}`;
  }
  return null;
};

export const validateSuffixPattern = (value, name, t1) => {
  if (checkingMultipleSpacetextbox(value)) {
    return `${t(t1, 'ERR_INVALID_SUFFIX')}`;
  }
  if (!suffixPattern.test(value)) {
    return `${t(t1, 'ERR_INVALID_SUFFIX')}`;
  }
  return null;
};

export const validatePattern = (value, name, t1, pattern, invalidMsg) => {
  if (checkingMultipleSpacetextbox(value)) {
    return `${invalidMsg}`;
  }
  if (!pattern.test(value)) {
    return `${invalidMsg}`;
  }
  return null;
};

// placed this function at common place, as this validation is being used by other components
export const validatePostalCode = (value, name, t1, ERR_MSG = 'ERR_INVALID_POSTAL_CODE') => {
  const inValid = validatePattern(value, name, t1, postalCodePattern, t(t1, ERR_MSG));
  if (inValid) {
    return inValid;
  }
  const index1 = value.indexOf('(');
  const index2 = value.indexOf(')');
  if (
    (index1 === -1 && index2 > -1) ||
    (index1 > -1 && index2 === -1) ||
    (index1 > -1 && index2 > -1 && index1 >= index2)
  ) {
    return `${t(t1, ERR_MSG)}`;
  }
  return null;
};

export const validateSecurityAnswer = (value, name, t1, minLengthMsg, invalidMsg, pattern) => {
  if (value.startsWith(' ') || value.endsWith(' ')) {
    return displayParsedString(t1, 'NO_LEADING_TRAILING_SPACES');
  }
  if (value.length < 4) {
    return `${t(t1, 'ERR_ANSWER_SECURITY_MIN_INVALID_MSG')}`;
  }
  if (pattern.test(value)) {
    return `${t(t1, 'ERR_INVALID_ANSWER')}`;
  }
  return null;
};

export const validateDate = (value, name, t1, invalidMsg) => {
  const valueYear = value && new Date(value).getFullYear();
  const valueDate = value && new Date(value).getDate();
  const valueMonth = value && new Date(value).getMonth();
  const presentYear = new Date().getFullYear();
  const presentDate = new Date().getDate();
  const presentMonth = new Date().getMonth();
  const month = presentMonth - valueMonth;

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(valueYear)) {
    return `${invalidMsg}`;
  }

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(valueDate)) {
    return `${invalidMsg}`;
  }

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(valueMonth)) {
    return `${invalidMsg}`;
  }

  if (valueYear < 1930) {
    return `${invalidMsg}`;
  }

  if (valueMonth > 11) {
    return `${invalidMsg}`;
  }

  if (valueDate > 31) {
    return `${invalidMsg}`;
  }

  if (presentYear - valueYear < 18) {
    return `${invalidMsg}`;
  }

  if (valueYear >= presentYear) {
    return `${invalidMsg}`;
  }

  if (presentYear - valueYear === 18) {
    if (!(month < 0 || (month === 0 && presentDate < valueDate))) {
      return null;
    }
    return `${invalidMsg}`;
  }
  return null;
};

export const validateSimpleDate = (value, name, t1, invalidMsg) => {
  const valueYear = value && new Date(value).getFullYear();
  const valueDate = value && new Date(value).getDate();
  const valueMonth = value && new Date(value).getMonth();

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(valueYear)) {
    return `${invalidMsg}`;
  }

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(valueDate)) {
    return `${invalidMsg}`;
  }

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(valueMonth)) {
    return `${invalidMsg}`;
  }

  if (valueYear < 1930) {
    return `${invalidMsg}`;
  }

  if (valueMonth > 11) {
    return `${invalidMsg}`;
  }

  if (valueDate > 31) {
    return `${invalidMsg}`;
  }

  return null;
};

export const checkingMultipleSpace = (value) => {
  const splitValue = value.split('');
  let isError = false;
  // eslint-disable-next-line no-restricted-syntax
  for (const i in splitValue) {
    if (splitValue[i] === ' ' && splitValue[i - 1] === ' ') {
      isError = true;
    }
  }
  return isError;
};

export const checkingMultipleSpacetextbox = (value) => {
  const splitValue = value?.split('') || [];
  let isError = false;
  for (let i = 0; i < splitValue.length; i += 1) {
    if (splitValue[i] !== ' ') {
      isError = false;
      break;
    }
    if (splitValue[i] === ' ') {
      isError = true;
    }
  }
  return isError;
};

// Module 2 Field_Validation starts //

export const validatingPostalCode = (value, name, t1, options = {}) => {
  const { minLength, maxLength } = options;

  if (minLength && value.length < minLength) {
    return minLengthValidation(minLength, value, name, t1, t(t1, 'ERR_POSTAL_CODE_MIN_CHAR'));
  }

  if (maxLength && value.length > maxLength) {
    return maxLengthValidation(maxLength, value, name, t1, t(t1, 'ERR_POSTAL_CODE_MAX_CHAR'));
  }

  const inValid = validatePattern(value, name, t1, postalCodePattern);
  if (inValid) {
    return t(t1, 'ERR_INVALID_POSTAL_CODE');
  }

  const index1 = value.indexOf('(');
  const index2 = value.indexOf(')');

  if (/([^a-zA-Z0-9 ])\1/.test(value) ||
    (index1 === -1 && index2 > -1) ||
    (index1 > -1 && index2 === -1) ||
    (index1 > -1 && index2 > -1 && index1 >= index2)) {
    return t(t1, 'ERR_INVALID_POSTAL_CODE');
  }

  return null;
};


export const checkingEmptySpaceCAG = (value, name, t1, validationMsg) => {
  if (!isEmpty(value)) {
    const splitValue = value?.split('');
    // eslint-disable-next-line no-restricted-syntax
    for (const i in splitValue) {
      if (splitValue[0] === ' ' || (splitValue[i] === ' ' && splitValue[i - 1] === ' ')) {
        return `${validationMsg}`;
      }
    }
  }
};

export const checkingEmptySpace = (value, name, t1, invalidMsg) => {
  if (value.split('')[0] === ' ') {
    return `${invalidMsg}`;
  }
  if (value.split('')[value.length - 1] === ' ') {
    return `${invalidMsg}`;
  }
  if (!value.match(/^\S.*$/gm)) {
    return `${invalidMsg}`;
  }
  if (!isEmpty(value)) {
    const splittedValue = value?.split('');
    // eslint-disable-next-line no-restricted-syntax
    for (const i in splittedValue) {
      if (splittedValue[0] === ' ' || (splittedValue[i] === ' ' && splittedValue[i - 1] === ' ')) {
        return `${invalidMsg}`;
      }
    }
  }
};

export const checkInputRegex = (value, name, t1, regex, invalidMsg) => {
  if (!isEmpty(value) && regex) {
    const splittedValue = regex?.test(value);
    if (!splittedValue) return `${invalidMsg}`;
  }
};

export const minLengthValidation = (length, value, name, t1, minLengthMsg) => {
  if (!isEmpty(value)) {
    if (value.length < length) return `${minLengthMsg}`;
  }
};

export const maxLengthValidation = (length, value, name, t1, maxLengthMsg) => {
  if (!isEmpty(value)) {
    if (value.length > length) return `${maxLengthMsg}`;
  }
};

export const specialCharacterValidation = (value, name, t1, splCharErrMsg) => {
  if (!isEmpty(value)) {
    if (/[^a-zA-Z0-9]/.test(value)) return `${splCharErrMsg}`;
  }
};

export const alphabetNotAllowedValidation = (value, name, t1, alphabetNotAlllowedMsg) => {
  if (!isEmpty(value)) {
    if (/[a-zA-Z]/.test(value)) return `${alphabetNotAlllowedMsg}`;
  }
};

export const verifyGHGNumber = (value, name, t1, minLengthMsg, maxLengthMsg, splCharErrMsg, options = {}) => {
  // This Function is Used in
  //  #GHG_ID.
  const { minLength, maxLength } = options;
  // Regex to disallow all zeros
  if (/^0+$/.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  if (minLength && value.length < minLength) return minLengthValidation(minLength, value, name, t1, minLengthMsg);
  if (maxLength && value.length > maxLength) return maxLengthValidation(maxLength, value, name, t1, maxLengthMsg);
  if (/([^a-zA-Z0-9])\1/.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  if (/[^a-zA-Z0-9]/.test(value)) return specialCharacterValidation(value, name, t1, splCharErrMsg);
};

export const verifyFirstName = (
  value,
  name,
  t1,
  minLengthMsg,
  maxLengthMsg,
  invalidMsg,
  splCharErrMsg,
  options = {},
) => {
  // This Function is Used in
  //  #FirstName.
  const { minLength, maxLength } = options;
  // Regex to disallow all zeros
  if (/^0+$/.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  if (minLength && value.length < minLength) return minLengthValidation(minLength, value, name, t1, minLengthMsg);
  if (maxLength && value.length > maxLength) return maxLengthValidation(maxLength, value, name, t1, maxLengthMsg);
  if (/([^a-zA-Z0-9 ])\1/.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  if (/[^a-zA-Z0-9'().-À-ÖØ-öø-ÿ ]|^[-.]|[-.]$/.test(value))
    return specialCharacterValidation(value, name, t1, splCharErrMsg);
};

export const verifyLastName = (
  value,
  name,
  t1,
  minLengthMsg,
  maxLengthMsg,
  invalidMsg,
  splCharErrMsg,
  options = {},
) => {
  // This Function is Used in
  //  #LastName.
  const { minLength, maxLength } = options;
  // Regex to disallow all zeros
  if (/^0+$/.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  if (minLength && value.length < minLength) return minLengthValidation(minLength, value, name, t1, minLengthMsg);
  if (maxLength && value.length > maxLength) return maxLengthValidation(maxLength, value, name, t1, maxLengthMsg);
  if (/[^\p{L}\p{N}.\-' ]/u.test(value)) return specialCharacterValidation(value, name, t1, splCharErrMsg);
};

export const verifyFullName = (
  value,
  name,
  t1,
  minLengthMsg,
  maxLengthMsg,
  invalidMsg,
  splCharErrMsg,
  options = {},
) => {
  // This Function is Used in
  //  #FullName.
  const { minLength, maxLength } = options;
  // Regex to disallow all zeros
  if (/^0+$/.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  if (minLength && value.length < minLength) return minLengthValidation(minLength, value, name, t1, minLengthMsg);
  if (maxLength && value.length > maxLength) return maxLengthValidation(maxLength, value, name, t1, maxLengthMsg);
  if (/([^a-zA-Z0-9 ])\1/.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  // Added condiiton to accept specific special characters (FullName)
  if (/[^-.'\p{L}\p{N}\s]/u.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  // Added condiiton to disallow specific special characters in the beginning or end (FullName)
  if (/^[.'-]|[.'-]$/.test(value)) return t(t1, 'ERR_INVALID_INPUT');
};

export const verifyIdentificationNumber = (
  value,
  name,
  t1,
  minLengthMsg,
  maxLengthMsg,
  invalidMsg,
  splCharErrMsg,
  options = {},
) => {
  // This Function is Used in
  //  #All Business Fields in M2,
  const { minLength, maxLength } = options;
  // Regex to disallow all zeros
  if (/^0+$/.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  if (minLength && value.length < minLength) return minLengthValidation(minLength, value, name, t1, minLengthMsg);
  if (maxLength && value.length > maxLength) return maxLengthValidation(maxLength, value, name, t1, maxLengthMsg);
  if (/(\u002D{2}|\u2013{2}|\u2014{2}|\u2212{2})/.test(value) || /^(\u002D|\u2013|\u2014|\u2212)|(\u002D|\u2013|\u2014|\u2212)$/.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  if (/[^a-zA-Z0-9\u002D\u2013\u2014\u2212]/.test(value)) return specialCharacterValidation(value, name, t1, splCharErrMsg);
};

export const verifyIdentificationNumberForQuebec = (
  value,
  name,
  t1,
  minLengthMsg,
  maxLengthMsg,
  invalidMsg,
  splCharErrMsg,
  alphabetNotAlllowedMsg,
  options = {},
) => {
  // This Function is Used in #Telephone_EXT.
  const { minLength, maxLength } = options;
  // Regex to disallow all zeros
  if (/^0+$/.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  if (minLength && value.length < minLength) return minLengthValidation(minLength, value, name, t1, minLengthMsg);
  if (maxLength && value.length > maxLength) return maxLengthValidation(maxLength, value, name, t1, maxLengthMsg);
  if (/(\u002D{2}|\u2013{2}|\u2014{2}|\u2212{2})/.test(value) || /^(\u002D|\u2013|\u2014|\u2212)|(\u002D|\u2013|\u2014|\u2212)$/.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  if (/[^a-zA-Z0-9\u002D\u2013\u2014\u2212]/.test(value)) return specialCharacterValidation(value, name, t1, splCharErrMsg);
  if (/[^0-9]/.test(value)) return alphabetNotAllowedValidation(value, name, t1, alphabetNotAlllowedMsg);
};

export const verifyIdentificationNumberWithoutSpecialCharacters = (
  value,
  name,
  t1,
  minLengthMsg,
  maxLengthMsg,
  invalidMsg,
  options = {},
) => {
  // This Function is Used in
  //  #Legal_Name,
  //  #Operating_Name,
  //  #Other_Classification,
  //  #Other_Organization,
  //  #Street_1,
  //  #Street_2,
  //  #City,
  //  #Region,
  //  #Facility_Name,
  //  #Facility_Operating_Name,
  //  #Jobtitle &
  //  #Position.
  const { minLength, maxLength } = options;
  // Regex to disallow all zeros
  if (/^0+$/.test(value) || / 0+ /.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  if (minLength && value.length < minLength) return minLengthValidation(minLength, value, name, t1, minLengthMsg);
  if (maxLength && value.length > maxLength) return maxLengthValidation(maxLength, value, name, t1, maxLengthMsg);
};
export const verifyStreet1orStreet2 = (
  value,
  name,
  t1,
  minLengthMsg,
  maxLengthMsg,
  invalidMsg,
  options = {},
) => {
  // This Function is Used in
  //  #Legal_Name,
  //  #Operating_Name,
  //  #Other_Classification,
  //  #Other_Organization,
  //  #Street_1,
  //  #Street_2,
  //  #City,
  //  #Region,
  //  #Facility_Name,
  //  #Facility_Operating_Name,
  //  #Jobtitle &
  //  #Position.
  const { minLength, maxLength } = options;
  if (value.split('')[0] === ' ') {
    return `${invalidMsg}`;
  }
  if (value.split('')[value.length - 1] === ' ') {
    return `${invalidMsg}`;
  }
  if (!value.match(/^\S.*$/gm)) {
    return `${invalidMsg}`;
  }
  if (!isEmpty(value)) {
    const splittedValue = value?.split('');
    // eslint-disable-next-line no-restricted-syntax
    for (const i in splittedValue) {
      if (splittedValue[0] === ' ' || (splittedValue[i] === ' ' && splittedValue[i - 1] === ' ')) {
        return `${invalidMsg}`;
      }
    }
  }
  // Regex to disallow all zeros
  if (/^0+$/.test(value) || / 0+ /.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  if (minLength && value.length < minLength) return minLengthValidation(minLength, value, name, t1, minLengthMsg);
  if (maxLength && value.length > maxLength) return maxLengthValidation(maxLength, value, name, t1, maxLengthMsg);
  if (!street1Pattern.test(value)) {
    return `${invalidMsg}`;
  }
};

export const verifyTitle = (
  value,
  name,
  t1,
  minLengthMsg,
  maxLengthMsg,
  invalidMsg,
  options = {},
) => {
  // This Function is Used in
  //  #Title

  const { minLength, maxLength } = options;
  // Regex to disallow all zeros
  if (/^0+$/.test(value) || / 0+ /.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  // Added condiiton to accept specific special characters (Title)
  if (/[^\u0026\u0027\u0028\u0029\u002A\u002B\u002C\u002D\u2013\u2212\u2014\u002E\u003A\u003B\u007C\u002F\u2019\u005F\p{L}\d\u0022]/u.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  if (minLength && value.length < minLength) return minLengthValidation(minLength, value, name, t1, minLengthMsg);
  if (maxLength && value.length > maxLength) return maxLengthValidation(maxLength, value, name, t1, maxLengthMsg);
};

export const verifyCityRegion = (
  value,
  name,
  t1,
  minLengthMsg,
  maxLengthMsg,
  invalidMsg,
  options = {},
) => {
  // This Function is Used in
  //  #City
  //  #Region
  //  #Complete_Address

  const { minLength, maxLength } = options;
  // Regex to disallow all zeros
  if (/^0+$/.test(value) || / 0+ /.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  // Added condiiton to accept specific special characters (City/Region/Complete_Address)
  if (/[^\p{L}\d\u0026\u0027\u0028\u0029\u002A\u002B\u002C\u002D\u2013\u2212\u2014\u002E\u003B\u002F\s\u0022]/u.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  if (minLength && value.length < minLength) return minLengthValidation(minLength, value, name, t1, minLengthMsg);
  if (maxLength && value.length > maxLength) return maxLengthValidation(maxLength, value, name, t1, maxLengthMsg);
};

export const verifyAddress = (
  value,
  name,
  t1,
  minLengthMsg,
  maxLengthMsg,
  invalidMsg,
  options = {},
) => {
  // This Function is Used in
  //  #Address 1
  //  #Address 2
  //  #Return Address
  //  #Street Address

  const { minLength, maxLength } = options;
  // Regex to disallow all zeros
  if (/^0+$/.test(value) || / 0+ /.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  // Added condiiton to accept specific special characters (Address 1/Address 2/Return Address/Street Address)
  if (/[^\u0026\u0027\u0028\u0029\u002A\u002B\u002C\u002D\u2013\u2212\u2014\u002E\u0023\u003A\u003B\u002F\u00B0\u005C\u007C\u2019\u005F\p{L}\d\s\u0022]/u.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  if (minLength && value.length < minLength) return minLengthValidation(minLength, value, name, t1, minLengthMsg);
  if (maxLength && value.length > maxLength) return maxLengthValidation(maxLength, value, name, t1, maxLengthMsg);
};

export const verifyPosition = (
  value,
  name,
  t1,
  minLengthMsg,
  maxLengthMsg,
  invalidMsg,
  splCharErrMsg,
  options = {},
) => {
  const { minLength, maxLength } = options;
  // Regex to disallow all zeros
  if (/^0+$/.test(value) || / 0+ /.test(value)) return t(t1, 'ERR_INVALID_INPUT');
  if (minLength && value.length < minLength) return minLengthValidation(minLength, value, name, t1, minLengthMsg);
  if (maxLength && value.length > maxLength) return maxLengthValidation(maxLength, value, name, t1, maxLengthMsg);
  if (/[^\p{L}\p{N}"&'()*+,-.:/|_\\ ’]/u.test(value)) return specialCharacterValidation(value, name, t1, splCharErrMsg);
};

export const verifyPhoneANumber = (value, name, t1, options = {}) => {
  const regex = /[^0-9 \d\s+()-]/;

  if (!isEmpty(value)) {
    const splittedValue = regex?.test(value);
    if (splittedValue) return `${t(t1, 'VALIDATION_MESSAGE')} ${t(t1, 'INPUT')}`;
  }
};

export const checkingEmptySpaceForAddress = (value, name, t1, invalidMsg) => {
  if (value.split('')[0] === ' ') {
    return `${invalidMsg}`;
  }
  if (value.split('')[value.length - 1] === ' ') {
    return `${invalidMsg}`;
  }
  if (!value.match(/^\S.*$/gm)) {
    return `${invalidMsg}`;
  }
  if (!isEmpty(value)) {
    const splittedValue = value?.split('');
    // eslint-disable-next-line no-restricted-syntax
    for (const i in splittedValue) {
      if (splittedValue[0] === ' ' || (splittedValue[i] === ' ' && splittedValue[i - 1] === ' ')) {
        return `${invalidMsg}`;
      }
    }
  }
};

// Module 2 Field_Validation Ends //

export const validateEXTNumber = (value, name, t1) => {
  if (checkingMultipleSpacetextbox(value)) {
    return `${t(t1, 'VALIDATION_MESSAGE')} ${name}`;
  }
  if (!extPattern.test(value)) {
    return `${t(t1, 'VALIDATION_MESSAGE')} ${name}`;
  }
};

export const validateLimits = (value, name, t1, invalidMsg) => {
  if (value.match(/[a-z]/i)) {
    return `${invalidMsg}`;
  }
  if (value.match(/\+|-/i)) {
    return `${invalidMsg}`;
  }
  if (value.split('')[0] === ' ') {
    return `${invalidMsg}`;
  }
  if (value.split('')[value.length - 1] === ' ') {
    return `${invalidMsg}`;
  }
  if (checkingMultipleSpace(value)) {
    return `${invalidMsg}`;
  }
};

export const validateComment = (value, name, t1) => {
  if (value.split('')[0] === ' ') {
    return `${t(t1, 'VALIDATION_MESSAGE')} ${name}`;
  }
  if (shouldntStartWithSpace.test(value)) {
    return `${t(t1, 'VALIDATION_MESSAGE')} ${name}`;
  }
};

export const validateCommentModule = (value, name, t1) => {
  if (value?.split('')[0] === ' ') {
    return `${t(t1, 'ERR_INVALID_COMMENT')}`;
  }
  if (value?.split('')[value.length - 1] === ' ') {
    return `${t(t1, 'ERR_INVALID_COMMENT')}`;
  }
  if (!value?.match(/^\S.*$/gm)) {
    return `${t(t1, 'ERR_INVALID_COMMENT')}`;
  }
  if (!isEmpty(value)) {
    const splittedValue = value?.split('');
    // eslint-disable-next-line no-restricted-syntax
    for (const i in splittedValue) {
      if (splittedValue[0] === ' ' || (splittedValue[i] === ' ' && splittedValue[i - 1] === ' ')) {
        return `${t(t1, 'ERR_INVALID_COMMENT')}`;
      }
    }
  }
};

// To match ORG
export const matchOrg = (ORGID1, ORGID2) => ORGID1 === ORGID2;

// forms dummy data
export const threeDummyFormsData = [
  {
    EntityName: 'URL',
    SK: 'File#English#Page_DocFiles#SUBMITTED_DOCUMENT_TITLE_FIRST',
    NotifyUser: true,
    PK: 'ORG#00101',
    LabelSaved: '',
    LabelPublished: '',
  },
  {
    EntityName: 'URL',
    SK: 'File#English#Page_DocFiles#SUBMITTED_DOCUMENT_TITLE_SECOND',
    NotifyUser: true,
    PK: 'ORG#00101',
    LabelSaved: '',
    LabelPublished: '',
  },
  {
    EntityName: 'URL',
    SK: 'File#English#Page_DocFiles#SUBMITTED_DOCUMENT_TITLE_THIRD',
    NotifyUser: true,
    PK: 'ORG#00101',
    LabelSaved: '',
    LabelPublished: '',
  },
];

export const attestationDummyFormsData = [
  {
    EntityName: 'URL',
    SK: 'File#English#Page_DocFiles#ATTESTATION_FORM',
    NotifyUser: true,
    PK: 'ORG#00101',
    LabelSaved: '',
    LabelPublished: '',
  },
];

export const getFilterForms = async (formsLabelList, defaultFileUrl) =>
  Promise.all(
    formsLabelList?.map((formObj) => {
      const formSK = formObj?.SK?.split('#')?.pop();
      const indexOfIncludedForm = defaultFileUrl?.data?.findIndex((j) => formSK === j?.SK?.split('#')?.pop());
      if (indexOfIncludedForm >= 0) {
        return defaultFileUrl?.data?.[indexOfIncludedForm];
      }
      return formObj;
    }),
  );

export const formatDateUpdated = (dateValue, delimiter = '-', onlyDate = false, tZone = true) => {
  if (!dateValue) return null;
  const utcTime = new Date(dateValue);
  const localTimeString = utcTime
    .toLocaleString('en-US', {
      timeZoneName: 'short',
      timeZone: 'America/Indianapolis',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      formatMatcher: 'basic',
    })
    .replace(/\//g, '-')
    .replace(', ', ' ');

  const zone = tZone ? 'ET' : '';
  const [date, time] = localTimeString.split(' ');
  const [month, day, year] = date.split('-');

  const formattedHour = time.split(':')[0] === '24' ? '00' : time.split(':')[0];
  const formattedTimeString = `${formattedHour}:${time.split(':')[1]}:${time.split(':')[2]} ${zone}`;
  if (onlyDate) return `${year}${delimiter}${`0${month}`.slice(-2)}${delimiter}${`0${day}`.slice(-2)}`;
  return `${year}${delimiter}${`0${month}`.slice(-2)}${delimiter}${`0${day}`.slice(-2)} ${formattedTimeString}`;
};

export const formatDateUpdatedWithoutTZ = (dateValue, delimiter = '-', onlyDate = false) => {
  if (!dateValue) return null;
  const utcTime = new Date(dateValue);
  const localTimeString = utcTime
    .toLocaleString('en-US', {
      timeZoneName: 'short',
      timeZone: 'America/Indianapolis',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      formatMatcher: 'basic',
    })
    .replace(/\//g, '-')
    .replace(', ', ' ');

  const [date, time] = localTimeString.split(' ');
  const [month, day, year] = date.split('-');

  const formattedHour = time.split(':')[0] === '24' ? '00' : time.split(':')[0];
  const formattedTimeString = `${formattedHour}:${time.split(':')[1]}:${time.split(':')[2]}`;
  if (onlyDate) return `${year}${delimiter}${`0${month}`.slice(-2)}${delimiter}${`0${day}`.slice(-2)}`;
  return `${year}${delimiter}${`0${month}`.slice(-2)}${delimiter}${`0${day}`.slice(-2)} ${formattedTimeString}`;
};

export const removeTimeZone = (dateValue) => {
  if (!dateValue) return null;
  return dateValue?.slice(0, 19);
};

export const fileDownloadDateFormat = () => {
  const today = formatDateUpdated(new Date(), '-', false, false).trim();
  const arrDate = today.slice(0, 10).split('-');
  const formattedDate = `_${arrDate[1]}-${arrDate[2]}-${arrDate[0]}`;
  const { hour, minute } = getHourAndMinute(today);
  return `${formattedDate}_${hour}${minute}`;
};

export function secondsToHHMMSS(seconds) {
  const totalSeconds = Math.floor(seconds); // Round down to the nearest whole second
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
    remainingSeconds,
  ).padStart(2, '0')}`;
}

export function convertCronExpressionToET(utcCronExpression) {
  // Parse the UTC cron expression
  const parsedExpression = parse(utcCronExpression);

  // Calculate the time difference between UTC and ET (offset hours)
  const currentDateLocaleString = new Date().toLocaleString(LOCALE_EN_US, {
    timeZone: EST_TIMEZONE,
    timeZoneName: 'short',
  });

  const utcOffsetHours = currentDateLocaleString.includes(EDT_SHORT) ? 4 : 5;

  // Adjust the cron expression for ET by adding offset hours
  return {
    minutes: parsedExpression.minutes,
    hours: parsedExpression.hours.map((hour) => hour + utcOffsetHours),
    daysOfMonth: parsedExpression.daysOfMonth,
    months: parsedExpression.months,
    daysOfWeek: parsedExpression.daysOfWeek,
    years: parsedExpression.years,
  };
}

export function getNextScheduledJobTime(expression, date, status = BATCH_JOB_STATUS.SUCCESS) {
  try {
    const jobStartTime = date ? new Date(date) : new Date();
    const parsedExpression = convertCronExpressionToET(expression);
    let nextScheduledTime = next(parsedExpression, jobStartTime);
    // if job is in stuck in status in progress and the calculated next schedule becomes past date, the next schedule will be calculated from current date
    if (status === BATCH_JOB_STATUS.IN_PROGRESS && nextScheduledTime < new Date()) {
      nextScheduledTime = next(parsedExpression, new Date());
    }
    return formatDateUpdated(nextScheduledTime.toUTCString());
  } catch (error) {
    return '';
  }
}

export const BATCH_JOB_STATUS = {
  SUCCESS: 'BATCH_JOB_SUCCESS',
  FAILED: 'BATCH_JOB_FAILED',
  IN_PROGRESS: 'BATCH_JOB_IN_PROGRESS',
};

export const ASSOCIATE_FACILITY_STATUS = {
  RETIRED: 'b3d75c3f-e665-4596-804a-e7323560b75a',
  ACTIVE: 'ad20b069-eb5d-4e31-93b6-c48de05f469d',
};

export const COMPONENT_NAMES = {
  SELECT_JURISDICTION: 'SELECT_JURISDICTION',
  SELECT_ENTITY_TYPE: 'SELECT_ENTITY_TYPE',
  ENTER_ENTITY_INFORMATION: 'ENTER_ENTITY_INFORMATION',
  ENTITY_INFORMATION: 'ENTITY_INFORMATION',
  ADD_FACILITIES: 'ADD_FACILITIES',
  ADD_REPRESENTATIVES: 'ADD_REPRESENTATIVES',
  CONTACT_INFORMATION: 'CONTACT_INFORMATION',
  AUCTION: 'AUCTION',
  REVIEW_AND_SUBMIT: 'REVIEW_AND_SUBMIT',
  SUBMIT_REQUIRED_DOCUMENTS: 'SUBMIT_REQUIRED_DOCUMENTS',
  SELECT_ENTITY_TYPE_IDENTIFIERS: 'SELECT_ENTITY_TYPE_IDENTIFIERS',
};

export const REVIEW_PAGE_COMPONENTS = [
  'JURY_SELECTION',
  'ENTITY_SELECTION',
  'ENTITY_INFORMATION',
  'IDENTIFIERS',
  'PHYSICAL_ADDRESS',
  'MAILING_ADDRESS',
  'ADDITIONAL_INFORMATION',
  'FACILITY',
  'REPRESENTATIVE',
  'ALTERNATE_CONTACT_INFO',
  'AUCTION',
];

export const REVISION_REQUESTED_COMPONENTS = ['GENERAL', 'CONTACT_INFO', 'REPRESENTATIVE', 'FACILITY', 'AUCTION'];

export const COMPONENT_NAME_FOR_ROLE = {
  REPRESENTATIVE: 'representative',
  ALTERNATE_CONTACT_PERSON: 'contact',
};
export const COMPONENT_MODES = {
  EDIT: 'EDIT',
  ADD: 'ADD',
};

export const REPRESENTATIVE_ACCOUNT_IDS = {
  PRIMARY_ACCOUNT_ID: '4bb95c9d-c8cd-48c9-9eec-39bcd9854a31',
  ALTERNATIVE_ACCOUNT_ID: '5152cc54-cb46-43bb-b019-9131884dc358',
};
export const STATUS_IDS = {
  PENDING_STATUS_ID: '969add4b-1c6d-4536-849b-b7ea5a8c8d77',
  PROPOSED_STATUS_ID: '21533c59-af85-4d29-af8a-1d97508e211e',
};

export const COUNTRIES_WITH_STATES = [COUNTRY_KEY_NAMES.UNITED_STATES, COUNTRY_KEY_NAMES.CANADA];

export const JURISDICTION_ACCOUNT_TYPES = {
  Auction: 'Auction',
  Retirement: 'Retirement',
  Issuance: 'Issuance',
};

export const ENTITY_TYPE_NAMES = {
  COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY: 'COVERED_ENTITY_COVERED_SOURCE_OR_OPT_IN_ENTITY',
  GENERAL_MARKET_PARTICIPANT_ORGANIZATION: 'GENERAL_MARKET_PARTICIPANT_ORGANIZATION',
  GENERAL_MARKET_PARTICIPANT_INDIVIDUAL: 'GENERAL_MARKET_PARTICIPANT_INDIVIDUAL',
};

export const ENTITY_ACCOUNT_TYPE_NAMES = {
  GENERAL: 'GENERAL',
  COMPLIANCE: 'COMPLIANCE',
  LIMITED_USE_HOLDING_ACCOUNT: 'LIMITED_USE_HOLDING_ACCOUNT',
  EXCHANGE_CLEARING_HOLDING_ACCOUNT: 'EXCHANGE_CLEARING_HOLDING_ACCOUNT',
  G_ANNUAL_ALLOCATION_HOLDING: 'G_ANNUAL_ALLOCATION_HOLDING',
  C_ANNUAL_ALLOCATION_HOLDING: 'G_ANNUAL_ALLOWANCE_HOLDING_ACCOUNT',
};

export const ENTITY_TYPES_ORDER = {
  GENERAL: 1,
  COMPLIANCE: 2,
  EXCHANGE_CLEARING_HOLDING_ACCOUNT: 3,
  LIMITED_USE_HOLDING_ACCOUNT: 4,
  G_ANNUAL_ALLOCATION_HOLDING: 5,
  C_ANNUAL_ALLOCATION_HOLDING: 6,
};

export const ENTITY_ACCOUNT_TYPES = {
  GENERAL: 'GENERAL',
  COMPLIANCE: 'COMPLIANCE',
  LIMITED_USE_HOLDING_ACCOUNT: 'LIMITED_USE_HOLDING_ACCOUNT',
  EXCHANGE_CLEARING_HOLDING_ACCOUNT: 'EXCHANGE_CLEARING_HOLDING_ACCOUNT',
  G_ANNUAL_ALLOCATION_HOLDING: 'G_ANNUAL_ALLOCATION_HOLDING',
  C_ANNUAL_ALLOCATION_HOLDING: 'C_ANNUAL_ALLOCATION_HOLDING',
};

export const ENTITY_ACCOUNT_TYPES_FE = {
  GENERAL: 'General',
  COMPLIANCE: 'Compliance',
  LIMITED_USE_HOLDING_ACCOUNT: 'Limited Use Holding',
  EXCHANGE_CLEARING_HOLDING_ACCOUNT: 'Exchange Clearing Holding',
  G_ANNUAL_ALLOCATION_HOLDING: 'Annual Allocation Holdings (g)',
  C_ANNUAL_ALLOCATION_HOLDING: 'Annual Allocation Holdings (c)',
  GENERAL_EXCHANGE_AGREEMENT_TRANSFER_NOT_TO_ECSP: 'General - Exchange',
  Auction: 'Auction',
};

export const ENTITY_TRANSFER_TYPES = {
  // from General Acc
  GENERAL_OTC_LESS_THAN_3_DAYS: 'GENERAL_OTC_LESS_THAN_3_DAYS',
  GENERAL_OTC_GREATER_THAN_3_DAYS: 'GENERAL_OTC_GREATER_THAN_3_DAYS',
  GENERAL_EXCHANGE_AGREEMENT_TRANSFER_NOT_TO_ECSP: 'GENERAL_EXCHANGE_AGREEMENT_TRANSFER_NOT_TO_ECSP',
  COMPLIANCE_TRANSFER: 'COMPLIANCE_TRANSFER',
  LIMITED_USE_HOLDING_ACCOUNT_WASHINGTON_ONLY: 'LIMITED_USE_HOLDING_ACCOUNT_WASHINGTON_ONLY',
  ECSP_ECHA_TO_CLEARING_PROVIDER: 'ECSP_ECHA_TO_CLEARING_PROVIDER',
  ECSP_ECHA_FROM_CLEARING_PROVIDER: 'ECSP_ECHA_FROM_CLEARING_PROVIDER',

  VOLUNTARY_RETIREMENT_TRANSFER: 'VOLUNTARY_RETIREMENT_TRANSFER',

  // from LUHA Acc
  CONSIGNMENT_TRANSFER: 'CONSIGNMENT_TRANSFER',

  // AAH
  ANNUAL_ALLOCATION_TRANSFER_COMPLIANCE: 'ANNUAL_ALLOCATION_TRANSFER_COMPLIANCE',
  ANNUAL_ALLOCATION_TRANSFER_GENERAL: 'ANNUAL_ALLOCATION_TRANSFER_GENERAL',

  // Administrative
  Administrative: 'ADMINISTRATIVE',
  JURISDICTION_BATCH_TRANSFER: 'JURISDICTION_BATCH_TRANSFER',
};

export const ENTITY_TRANSFER_TYPES_FE = {
  GENERAL_OTC_LESS_THAN_3_DAYS: 'General - OTC <= 3 Days',
  GENERAL_OTC_GREATER_THAN_3_DAYS: 'General - OTC > 3 Days',
  GENERAL_EXCHANGE_AGREEMENT_TRANSFER_NOT_TO_ECSP: 'General - Exchange',
  COMPLIANCE_TRANSFER: 'Compliance',
  LIMITED_USE_HOLDING_ACCOUNT_WASHINGTON_ONLY: 'Limited Use Holding',
  ECSP_ECHA_TO_CLEARING_PROVIDER: 'To Clearing Provider',
  VOLUNTARY_RETIREMENT_TRANSFER: 'Voluntary Retirement',
  CONSIGNMENT_TRANSFER: 'Consignment',
  ANNUAL_ALLOCATION_TRANSFER_COMPLIANCE: 'Annual Allocation Holdings (c)',
  ANNUAL_ALLOCATION_TRANSFER_GENERAL: 'Annual Allocation Holdings (g)',
  ANNUAL_ALLOCATION: 'Annual Allocation',
  ECSP_ECHA_FROM_CLEARING_PROVIDER: 'From Clearing Provider',
  Allowance: 'Allowance',
  // Administrative
  Administrative: 'Administrative',
  Replenishment: 'Replenishment',
  JURISDICTION_BATCH_TRANSFER: 'Batch transfer',
};

export const ENTITY_TRANSFER_TYPE_RECEIVING_ACCOUNTS = {
  GENERAL_OTC_LESS_THAN_3_DAYS: ENTITY_ACCOUNT_TYPES.GENERAL,
  GENERAL_OTC_GREATER_THAN_3_DAYS: ENTITY_ACCOUNT_TYPES.GENERAL,
  GENERAL_EXCHANGE_AGREEMENT_TRANSFER_NOT_TO_ECSP: ENTITY_ACCOUNT_TYPES.GENERAL,
  COMPLIANCE_TRANSFER: ENTITY_ACCOUNT_TYPES.COMPLIANCE,
  LIMITED_USE_HOLDING_ACCOUNT_WASHINGTON_ONLY: ENTITY_ACCOUNT_TYPES.LIMITED_USE_HOLDING_ACCOUNT,
  ECSP_ECHA_TO_CLEARING_PROVIDER: ENTITY_ACCOUNT_TYPES.EXCHANGE_CLEARING_HOLDING_ACCOUNT,
  ECSP_ECHA_FROM_CLEARING_PROVIDER: ENTITY_ACCOUNT_TYPES.EXCHANGE_CLEARING_HOLDING_ACCOUNT,
  VOLUNTARY_RETIREMENT_TRANSFER: JURISDICTION_ACCOUNT_TYPES.Retirement,
  CONSIGNMENT_TRANSFER: JURISDICTION_ACCOUNT_TYPES.Auction,
  ANNUAL_ALLOCATION_TRANSFER_COMPLIANCE: ENTITY_ACCOUNT_TYPES.COMPLIANCE,
  ANNUAL_ALLOCATION_TRANSFER_GENERAL: ENTITY_ACCOUNT_TYPES.COMPLIANCE,
};

// mapper is being used in setup-jurisdiction-accounts
export const JURISDICTION_TRANSFER_TYPES_FE = {
  JURISDICTION_BATCH_TRANSFER: 'Batch',
  JURISDICTION_BATCH_TRANSFER_PCRA: 'Batch PCRA',
  AllowanceIssuance: 'Issuance',
  Replenishment: 'Replenishment',
};

export const JURISDICTION_TRANSFER_TYPES_BE = {
  JURISDICTION_BATCH_TRANSFER: 'JURISDICTION_BATCH_TRANSFER',
  JURISDICTION_BATCH_TRANSFER_PCRA: 'JURISDICTION_BATCH_TRANSFER_PCRA',
  AllowanceIssuance: 'AllowanceIssuance',
  Replenishment: 'Replenishment',
};

export const JURISDICTIONS_NAMES = {
  QUEBEC: 'QUEBEC',
  CALIFORNIA: 'CALIFORNIA',
  NOVA_SCOTIA: 'NOVA_SCOTIA',
  WASHINGTON_STATE: 'WASHINGTON_STATE',
};

export const JURISDICTIONS_ID = {
  QUEBEC: '01df741e-1036-4d00-a941-e650eece959f',
  CALIFORNIA: '25639c51-f558-440b-90ab-ee496dd32f27',
  NOVA_SCOTIA: '377a3150-11b0-4066-b1ac-bd1228c796f6',
  WASHINGTON_STATE: '8ed88405-b7de-497c-94b7-38aaef0f6293',
};

export const ENTITY_APPLICATION_ACTIONS = {
  REQUEST_REVISION: 'REQUEST_REVISION',
  APPROVE: 'APPROVE',
  DENY: 'DENY',
  CANCEL: 'CANCEL',
};

export const ENTITY_APPLICTION_STATUS_NAMES = {
  REVISIONS_REQUESTED: 'REVISIONS_REQUESTED',
  APPLICATION_CANCELLED_BY_JURISDICTION: 'APPLICATION_CANCELLED_BY_JURISDICTION',
  APPLICATION_CANCELLED_BY_USER: 'APPLICATION_CANCELLED_BY_USER',
  APPLICATION_DENIED: 'DENIED',
  CLOSED: 'CLOSED',
  APPLICATION_APPROVED: 'APPLICATION_APPROVED',
  APPLICATION_PENDING: 'APPLICATION_PENDING',
  APPLICATION_IN_PROGRESS: 'APPLICATION_IN_PROGRESS',
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
  ENTITY_APPLICATION_APPROVED: 'ENTITY_APPLICATION_APPROVED',
  APPLICATION_DENIED_JURIDICTION: 'APPLICATION_DENIED',
  ENTITY_APPLICATION_REVISIONS_REQUESTED: 'ENTITY_APPLICATION_REVISIONS_REQUESTED',
};

export const ENTITY_ACCOUNT_STATUSES = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  SUSPENDED: 'Suspended',
  CLOSED: 'Closed',
  PENDING: 'Pending',
  RESTRICTED_CANNOT_TRANSFER: 'Restricted- Cannot Transfer',
  RESTRICTED_CANNOT_RECEIVE: 'Restricted- Cannot Receive',
  RESTRICTED_CANNOT_TRANSFER_AND_RECEIVE: 'Restricted- Cannot Transfer and Receive',
};

export const USER_ACCOUNT_STATUSES = {
  ACTIVE: 'ACTIVE',
  CANCELLED: 'CANCELLED',
  NOT_ACTIVATED: 'NOT_ACTIVATED',
  CANCELLED_BY_APPLICANT: 'CANCELLED_BY_APPLICANT',
  INACTIVE: 'INACTIVE',
  CLOSED: 'CLOSED',
  UNSHARED: 'UNSHARED',
  PENDING: 'PENDING',
  PROPOSED_TO_AUTHORITY: 'PROPOSED_TO_AUTHORITY',
  PROPOSED_ROLE_CHANGE: 'PROPOSED_ROLE_CHANGE',
  PROPOSED_USER_DETAILS_CHANGE: 'PROPOSED_USER_DETAILS_CHANGE',
  ROLE_CHANGE_APPROVED: 'ROLE_CHANGE_APPROVED',
  PASSWORD_RESET: 'PASSWORD_RESET',
  REGISTRATION_APPROVED: 'REGISTRATION_APPROVED',
  CANCELLED_BY_JURISDICTION: 'CANCELLED_BY_JURISDICTION',
  LOGIN_TEMPORARILY_LOCKED: 'LOGIN_TEMPORARILY_LOCKED',
  USER_DETAILS_CHANGE_APPROVED: 'USER_DETAILS_CHANGE_APPROVED',
  RESET_PASSPHRASE: 'RESET_PASSPHRASE',
  APPROVED_CROSS_JURISDICTION: 'APPROVED_CROSS_JURISDICTION',
  REGISTRATION_DENIED: 'REGISTRATION_DENIED',
  USER_DETAILS_CHANGE_DENIED: 'USER_DETAILS_CHANGE_DENIED',
  PROFILE_ACCESS_ONLY: 'PROFILE_ACCESS_ONLY',
  APPROVED_COJ: 'APPROVED_COJ',
  REVISIONS_REQUESTED: 'REVISIONS_REQUESTED',
  ROLE_CHANGE_DENIED: 'ROLE_CHANGE_DENIED',
  CANCELLED_COJ: 'CANCELLED_COJ',
  JURISDICTION_REQUEST_REVISIONS: 'JURISDICTION_REQUEST_REVISIONS',
  PENDING_COJ: 'PENDING_COJ',
  CONFIRMED: 'CONFIRMED',
  LOGIN_PERMANENTLY_LOCKED: 'LOGIN_PERMANENTLY_LOCKED',
  DENIED_COJ: 'DENIED_COJ',
  PENDING_CROSS_JURISDICTION: 'PENDING_CROSS_JURISDICTION',
  USER_DETAILS_CHANGE_CANCELLED: 'USER_DETAILS_CHANGE_CANCELLED',
  DENIED_CROSS_JURISDICTION: 'DENIED_CROSS_JURISDICTION',
  CANCELLED_CROSS_JURISDICTION: 'CANCELLED_CROSS_JURISDICTION',
};

export const getEntityTypeNamesForFilterDropdown = (t, t1) =>
  Object.keys(ENTITY_TYPE_NAMES).map((item) => ({ value: t(t1, item), label: t(t1, item) }));
export const getJuriNamesForFilterDropdown = (t, t1) =>
  Object.keys(JURISDICTIONS_NAMES).map((item) => ({ value: t(t1, item), label: t(t1, item) }));
export const getAcoountStatusNamesForFilterDropdown = (t, t1) =>
  Object.keys(ENTITY_ACCOUNT_STATUS_FOR_MY_ACCOUNT).map((item) => ({ value: t(t1, item), label: t(t1, item) }));
export const getEntityAcoountTypeNamesForFilterDropdown = (t, t1) =>
  Object.keys(ENTITY_ACCOUNT_TYPE_NAMES).map((item) => ({ value: t(t1, item), label: t(t1, item) }));

export const getFilterForColumn = (list, t, t1, columns) => {
  if (isEmpty(list) || !list || list?.length === 0) {
    return {};
  }
  const filterObject = {};
  columns?.forEach((column) => {
    // Split column in case of nested key (if provided)
    const [parentKey] = column.split('.');
    filterObject[parentKey] = new Set();
  });
  list?.forEach((item) => {
    columns?.forEach((column) => {
      const [parentKey] = column.split('.');

      // Extract the value based on the presence of a key
      const columnValue = _.get(item, column, '');

      if (columnValue && columnValue !== '') {
        filterObject[parentKey]?.add(t(t1, columnValue));
      }
    });
  });
  const result = {};
  columns?.forEach((column) => {
    const [parentKey] = column.split('.');
    result[parentKey] = Array.from(filterObject[parentKey]);
  });
  return result;
};

export const getFilterForColumnFSA = (list, t1, columns) => {
  if (!list || list.length === 0) {
    return {};
  }
  const filterObject = {};
  columns.forEach((column) => {
    filterObject[column] = new Set();
  });
  list.forEach((item) => {
    columns.forEach((column) => {
      const columnValue = column === 'status' ? item.status.Name : item[column];
      if (columnValue !== undefined && columnValue !== '') {
        const convertVal = t(t1, columnValue);
        filterObject[column]?.add(convertVal);
      }
    });
  });
  const result = {};
  columns.forEach((column) => {
    result[column] = column === 'status' ? Array.from(filterObject[column]) : Array.from(filterObject[column]);
  });

  return result;
};

export const getCAGMembersStatusNamesforFilterDropdown = () => ['In progress', 'Active', 'Closed', 'Retired'];

export const getNAICSCodeDisplayedDropdownforFilterDropdown = () => ['Yes', 'No'];

export const getFacilityStatusForFilterDropdown = (t, t1) =>
  Object.keys(FACILITY_STATUS).map((item) => ({ value: t(t1, item), label: t(t1, item) }));

export const getRepresentativeStatusDropdownforFilterDropdown = () => ['Proposed', 'Active', 'Suspended', 'Retired'];

export const getRolesForInActiveEntity = (t, t1) =>
  Object.keys(REPRESENTATIVES_ROLES).map((item) => ({ value: t(t1, item), label: t(t1, item) }));

export const getRolesForActiveEntity = (t, t1) =>
  Object.keys({ ...REPRESENTATIVES_ROLES, ...REPRESENTATIVES_ROLES_ACTIVE }).map((item) => ({
    value: t(t1, item),
    label: t(t1, item),
  }));

export const getRolesForActiveEntityProposed = (t, t1) =>
  Object.keys({ ...REPRESENTATIVES_ROLES, ...REPRESENTATIVES_ROLES_ACTIVE }).map((item) => ({
    value: t(t1, item),
    label: t(t1, item),
  }));

export const getRolesForEntityAction = (t, t1) =>
  Object.keys({ ...REPRESENTATIVES_ROLES }).map((item) => ({ value: t(t1, item), label: t(t1, item) }));

export const getRequestStatusForWiringInstruction = () => [
  'CWI Confirmed',
  'CWI Proposed',
  'CWI Invalidated',
  'CWI Cancelled',
  'CWI Verified',
];

export const getCWIStatusForWiringInstruction = () => [
  'Proposed',
  'Cancelled',
  'Invalidated',
  'Confirmed by Representative',
  'Confirmed by FSA',
  'Verified',
];

export const showEntityReferenceCode = (user, code) => {
  if (
    user.RoleDetail?.RoleType[0]?.name === JurisdictionUser ||
    user.RoleDetail?.RoleType[0]?.name === IndividualUser
  ) {
    return code;
  }
  return 'XXXXXXXXX';
};

export const REPRESENTATIVES_ROLES = {
  ALTERNATE_ACCOUNT_REPRESENTATIVE: 'ALTERNATE_ACCOUNT_REPRESENTATIVE',
  PRIMARY_ACCOUNT_REPRESENTATIVE: 'PRIMARY_ACCOUNT_REPRESENTATIVE',
};

export const REPRESENTATIVES_ROLES_ACTIVE = {
  ACCOUNT_VIEWING_AGENT: 'ACCOUNT_VIEWING_AGENT',
};

export const ENTITY_FLOW = {
  PROFILE: 'PROFILE',
  REGISTRATION: 'REGISTRATION',
};

const COMPLIANCE_INSTRUMENTS_APIS = {
  // ••••••••• COMPLIANCE INSTRUMENTS ENDPOINTS •••••••••
  ACCOUNT_INFORMATION_API: 'issuance/api/compliance/accountInformation',
  JURISDICTION_ACCOUNTS_API: 'issuance/api/compliance/jurisdictionAccounts',
  HOLDINGS_API: 'issuance/api/compliance/holdings',
  HOLDINGS_VIEW_API: 'issuance/api/compliance/holdingsView',
  TRANSFERS_API: 'issuance/api/compliance/transfers',
  SUB_TYPE_GET: 'issuance/api/compliance/subType',
  VINTAGE_YEAR_GET: 'issuance/api/compliance/vintageYear',
  ACQUIRING_ACCOUNT_GET: 'issuance/api/compliance/acquiringAccount',
  ALLOWANCE_ISSUANCE_INFORMATION_POST: 'issuance/api/compliance/proposeAllowanceIssuance',
  API_TRANSFER_APPROVALS: 'issuance/api/compliance/transferApprovals',
  API_ISSUANCE_RECORDS: 'issuance/api/compliance/issuancerecords',
  API_ISSUANCE_INFORMATION: 'issuance/api/compliance/issuanceInformation',
  API_ACCOUNTS: 'issuance/api/compliance/transferAccount',
  API_COMPLIANCE_INSTRUMENTS: 'issuance/api/compliance/getComplianceInstrument',
  API_TRANSFER_HISTORY: 'issuance/api/compliance/transferHistory',
  API_OFFSET_ISSUANCE_INFORMATION: 'issuance/api/compliance/getOffsetIssuanceInformation',
  VINTAGE_YEAR: 'issuance/api/compliance/vintageYear',
  PROPOSE_ALLOWANCE_ISSUANCE: 'issuance/api/compliance/proposeAllowanceIssuance',
  OFFSET_TYPE_API: 'issuance/api/compliance/offsetType',
  PROJECT_SITE_API: 'issuance/api/compliance/projectSite',
  SPECIAL_CATEGORY: 'issuance/api/compliance/specialCategory',
  PROPOSE_OFFSET_ISSUANCE_API: 'issuance/api/compliance/proposeOffsetIssuance',
  API_ISSUANCE_REQUEST_REVISIONS: 'issuance/api/manageCompliance/requestRevisionIssuance',
  GET_OFFSET_ISSUANCE_INFO_API: 'issuance/api/compliance/getOffsetIssuanceInformation',
  GET_OFFSET_ISSUANCE_INFO: 'issuance/api/compliance/getOffsetIssuance',
  API_ISSUANCE_DENY: 'issuance/api/manageCompliance/denyIssuance',

  APPROVE_OFFSET_ISSUANCE_POST: 'issuance/api/compliance/approveOffsetIssuance',
  APPROVE_ALLOWANCE_ISSUANCE_POST: 'issuance/api/compliance/approveAllowanceIssuance',
  GET_REQUEST_REVISION_COMMENT: 'issuance/api/compliance/getRequestRevisionComment',
};

export const EXCHANGE_CONTRACT_CODE = {
  GET_EXCHANGE_CONTRACT_CODES: 'issuance/api/exchangeContractCode/getAllExchangeContractCode',
  POST_EXCHANGE_CONTRACT_CODE: 'issuance/api/exchangeContractCode/addExchangeContractCode',
  GET_EXCHANGE_CONTRACT_CODE_BY_ID: 'issuance/get_exchange_contract_code_by_id',
  EDIT_EXCHANGE_CONTRACT_CODE: 'issuance/api/exchangeContractCode/updateExchangeContractCode',
  GET_EDIT_EXCHANGE_CONTRACT_CODE: 'issuance/api/exchangeContractCode/editExchangeContractCode',
  POST_TOGGLE_DROPDOWN: 'issuance/api/exchangeContractCode/updateDisplayInDropDown',
  GET_EXCHANGE_CONTRACT_CODE_HISTORY: 'issuance/api/exchangeContractCode/getExchangeCodeHistory',
};

const OFFSET_MANAGEMENT_APIS = {
  // •••••••••    OFFSET MANAGEMENTS ENDPOINTS   •••••••••
  GET_PROJECT_NAME_EDIT_INFO: 'offsets/api/offsets/getOffsetProjectNameData',
  GET_PROJECT_NAME_HISTORY_API: 'offsets/api/offsets/offsetProjectHistory',
  EDIT_PROJECT_NAME_API: 'offsets/api/offsets/offsetProject',

  GET_PROJECT_OPERATORS_LIST: 'offsets/api/offsets/offsetProjectOperator',
  GET_PROJECT_OPERATOR_EDIT_INFO: 'offsets/api/offsets/getOffsetProjectOperatorData',
  EDIT_PROJECT_OPERATOR_API: 'offsets/api/offsets/offsetProjectOperator',
  GET_PROJECT_OPERATOR_HISTORY_API: 'offsets/api/offsets/offsetProjectOperatorHistory',

  GET_VERIFICATION_BODY_LIST: 'offsets/api/offsets/verificationBody',
  GET_VERIFICATION_BODY_EDIT_INFO: 'offsets/api/offsets/getOffsetVerificationBodyData',
  EDIT_VERIFICATION_BODY: 'offsets/api/offsets/updateOffsetVerificationBody',
  GET_VERIFICATION_BODY_HISTORY_API: 'offsets/api/offsets/offsetVerificationBodytHistory',

  POST_OFFSET_PROJECT_NAME_API: 'offsets/api/offsets/offsetProject',
  POST_OFFSET_PROJECT_OPERATOR_API: 'offsets/api/offsets/offsetProjectOperator',
  POST_VERIFICATION_BODY_API: 'offsets/api/offsets/verificationBody',

  API_GET_ALL_PROJECT_NAME: 'offsets/api/offsets/offsetProject',
  API_GET_ALL_PROJECT_OPERATOR: 'offsets/api/offsets/offsetProjectOperator',
  API_GET_ALL_VERIFICATION_BODY: 'offsets/api/offsets/verificationBody',
  API_TOGGLE_PROJECT_NAME: 'offsets/api/offsets/offsetProject',
  API_TOGGLE_PROJECT_OPERATOR: 'offsets/api/offsets/offsetProjectOperator',
  API_TOGGLE_VERIFICATION_BODY: 'offsets/api/offsets/updateOffsetVerificationBody',

  OFFSET_ISSUANCE_REPORT: 'offsets/api/offsets/offsetIssuanceReport',

  OFFSET_TRACKING_RECORD: 'offsets/api/offsets/offsetTrackingRecord',

  PROPOSE_OFFSET_TRACKING_RECORD_API: 'offsets/api/offsets/jurisdictions',
  GET_OTR_DATA: 'offsets/api/offsets/offsetTrackingRecord',
  GET_OFFSET_CREDITS: 'offsets/api/offsets/jurisdictionOffsetCredit',
  GET_AFFECTED_OFFSET_CREDITS: 'offsets/api/offsets/affectedOffsetCredit',
  GET_OTR_STATUS_HISTORY: 'offsets/api/offsets/offsetTrackingRecordHistory',
  DELETE_OTR: 'offsets/api/offsets/deleteOffsetTrackingRecord',
  ADD_OFFSET_AFFECTED_CREDITS: 'offsets/api/offsets/offsetAffectedCredit',
  API_GET_AFFECTED_ACCOUNTS: 'offsets/api/offsets/affectedOffsetAccount',
  GET_AFFECTED_OFFSET_ACCOUNTS_COMPLIANCE: 'offsets/api/offsets/affectedOffsetAccountsCompliance',
  API_FREEZE_DENY_OTR: 'offsets/api/offsets/jurisdictions/{jurisdictionId}/denyOffsetTrackingRecord',
  API_APPROVE_OTR: 'offsets/api/offsets/approveOffsetTrackingRecord',
  API_FREEZE_REQUEST_REVISION_OTR: 'offsets/api/offsets/jurisdictions/{jurisdictionId}/freezeRequestRevisionOTR',
  API_UNFREEZE_REQUEST_REVISION_OTR: 'offsets/api/offsets/jurisdictions/{jurisdictionId}/unFreezeRequestRevisionOTR',
  API_REQUEST_REVISION_COMMENT_OTR: 'offsets/api/offsets/getRequestRevisionComment',
  API_GET_OTR_APPROVALS: 'offsets/api/offsets/getOTRApprovals',
  PROPOSE_TO_UNFREEZE_OFFSETS_API: ['offsets/api/offsets/jurisdictions', 'unfreezeProposeOffsetTrackingRecord'],
  API_GET_OTR_REQUEST_REV_COMMENT: 'offsets/api/offsets/getRequestRevisionComment',
};

const ENTITY_TRANSFERS_APIS = {
  GET_EXCHANGE_CONTRACT_CODES_MET: 'issuance/api/exchangeContractCode/getAllExchangeCodesTransfers',
  API_GET_TRANSFERRING_ACCOUNT_DETAILS: 'transfers/api/entityTransfer/transferringAccountDetails',
  ENTITY_TRANSFERS_HOLDINGS_API: 'transfers/api/entityTransfer/holdingsIssuances',
  API_GET_RECEIVING_ACCOUNT_DETAILS: 'transfers/api/entityTransfer/receivingAccountDetails',
  API_PROPOSE_ENTITY_TRANSFER: 'transfers/api/entityTransfer/proposeTransfer',
  API_PROPOSE_GENERAL_TO_ECHA: 'transfers/api/entityTransfer/proposeGeneralToECHATransfer',
  API_PROPOSE_ECHA_TO_GENERAL: 'transfers/api/entityTransfer/proposeECHAToGeneralTransfer',
  API_PROPOSE_OTC: 'transfers/api/entityTransfer/proposeOTCTransfer',
  GET_ENTITY_TRANSFER_DETAILS_API: 'transfers/api/entityTransfer/transferDetails',
  GET_EXCHANGE_AGREE_TRANSFER_API: 'transfers/api/entityTransfer/getExchangeAgreementTransfer',
  GET_OTC_TRANSFER_API: 'transfers/api/entityTransfer/otcTransferDetails',
  GET_TRANSFER_EVENT_HISTORY: 'transfers/api/entityTransfer/transferEventHistory',
  GET_TRANSFER_ACCOUNTS: 'transfers/api/entityTransfer/transferAccount',
  APPROVE_TRANSFER_API: 'transfers/api/entityTransfer/approveEntityTransfer',
  DENY_TRANSFER_API: 'transfers/api/entityTransfer/denyEntityTransfer',
  ACCEPT_TRANSFER_API: 'transfers/api/entityTransfer/acceptEntityTransfer',
  DECLINE_TRANSFER_API: 'transfers/api/entityTransfer/declineEntityTransfer',
  CANCEL_PROPOSE_TRANSFER_API: 'transfers/api/entityTransfer/cancelEntityTransfer',
  GET_ENTITY_ACCOUNT_HOLDINGS: 'transfers/api/entityTransfer/entityHoldings/entityAccount/:accountId',
  GET_JURISDICTION_USERS_HOLDINGS: 'transfers/api/entityTransfer/getHoldingsForJurisdictionUsers/:accountId',
  GET_ENTITY_ACCOUNT_TRANSFERS: 'transfers/api/entityTransfer/transfers/:accountId',
  API_GET_PENDING_TRANSFER: 'transfers/api/entityTransfer/pendingTransfers',
  GET_COMPLIANCE_INSTR_MET: 'transfers/api/entityTransfer/getComplianceInstruments',
  JURISDICTION_ACCOUNTS_BY_ENTITY_ID: 'transfers/api/entityTransfer/jurisdictionAccountsByEntityId/:entityId',
  GET_AGREEMENT_TYPES: 'transfers/api/entityTransfer/getAgreementTypes/:jurisdictionId',
  GET_PRICING_INFO_API: 'transfers/api/entityTransfer/pricingInformation',
  GET_VRT_LIST_API: 'transfers/api/entityTransfer/getVoluntaryRetirement',
  CWI_STATUS_HISTORY_BY_ACCOUNT_ID: 'transfers/api/entityTransfer/getCwiStatusHistory/:accountId',
  GET_ENTITY_TRANSFER_WARNING: 'transfers/api/entityTransfer/transferWarning',
  GET_ENTITY_TRANSFER_DETAIL_ID: 'transfers/api/entityTransfer/entityDetails',
};

const MRT_APIS = {
  MRT: 'transfers/api/modifiedTransfersRecords',
  MODIFIED_TRANSFER_RECORD_HISTORY: 'transfers/api/modifiedTransfersRecordsHistory',
  ALL_MRT: 'transfers/api/entityTransfer/getAllModifiedRecordTransfers',
  MRT_DETAILS: 'transfers/api/modifiedRecordsTransfer/{transferId}/{entityUUID}',
};

const SETUP_INITIAL_USER_APIS = {
  ADD_INITIAL_USER: 'jurisdictions/api/jurisdictionOnboarding/postInitialUser',
  UPDATE_INITIAL_USER: 'jurisdictions/api/jurisdictionOnboarding/putInitialUser',
  GET_INITIAL_USERS: 'jurisdictions/api/jurisdictionOnboarding/initialusers/jurisdictionId',
  GET_EDIT_INITIAL_USER_DATA: 'jurisdictions/api/jurisdictionOnboarding/initialusers/jurisdictionId',
  GET_ROLE: 'jurisdictions/api/roles',
  GET_INITIAL_USERS_HISTORY: 'jurisdictions/api/jurisdictionOnboarding/jurisdiction',
  INVALIDATE_LINK: 'jurisdictions/api/jurisdictionOnboarding/invalidateLink/:userId',
  ACTION_REQUEST_USER_HISTORY: 'actionrequest/user-history',
  DEACTIVATE_USER: 'auth/activatedeactivateuser',
  RESEND_ACCOUNT_SETUP_LINK: 'jurisdictions/api/jurisdictionOnboarding/resendEmailLink',
  SEND_EMAIL: 'jurisdictions/api/sendActivationEmail',
  ACTIVATE_DEACTIVATE: 'jurisdictions/api/jurisdictionOnboarding/activateDeactivate',
  INITIAL_USERS_JURI_HOME: 'jurisdictions/api/jurisdictionOnboarding/users',
  UPDATE_INITIAL_USER_HISTORY_ACT_DEACT: 'jurisdictions/api/jurisdictionOnboarding/updateInitialHistoryActDeact',
};

const SETUP_JURISDICTION_ACOUNTS = {
  SAVE_JURISDICTION_ACCOUNTS: '',
  ADD_DEFAULT_JURI_ACCOUNTS: 'jurisdictions/api/jurisdictionAccount/jurisdiction',
  ADD_NEW_JURI_ACCOUNT:
    'jurisdictions/api/jurisdictionAccount/jurisdiction/:jurisdictionId/createJurisdictionAccountAndType',
  GET_STATUS_CHANGE_API: 'jurisdictions/api/jurisdictionOnboarding/jurisdictionAccountStatusChange',
  GET_JURISDICTION_ACCOUNTS: 'jurisdictions/api/jurisdictionOnboarding/jurisdictionAccountDetails',
  GET_DEFAULT_ACCOUNTS: 'jurisdictions/api/jurisdictionAccounts/getDefaultAccounts',
  GET_TYPE_OF_INSTRUMENTS: 'jurisdictions/api/complianceInstruments',
  GET_TYPE_OF_TRANSFERS: 'jurisdictions/api/transferTypes',
  GET_ALL_TYPE_OF_TRANSFERS: 'jurisdictions/api/getAllTransferType',
  GET_ACC_NAME_LANGUAGES: 'jurisdictions/api/jurisdictionAccount/jurisdiction',
  GET_DEFAULT_INSTRUMENTS_TRANSFERS: 'jurisdictions/api/jurisdictionOnboarding/getComplianceInstrumentTransfersType',
  DELETE_JURISDICTION_ACCOUNT: 'jurisdictions/api/jurisdictionAccount/jurisdictionAccountId/:jurisdictionAccId',
  GET_JURI_ACCOUNT_DETAILS: 'jurisdictions/api/jurisdictionOnboarding/jurisdictionAccountDetailsById',
  UPDATE_JURI_ACCOUNT_DETAILS: 'jurisdictions/api/jurisdictionAccount/updateJurisdictionAccount/:jurisdictionAccountId',
  UPDATE_ACCOUNT_STATUS: 'jurisdictions/api/jurisdictionAccount/updateJurisdictionAccountStatus',
  PROPOSE_JURISDICTION_STATUS: 'jurisdictions/api/jurisdictionStatus/jurisdiction/:jurisdictionId/proposeStatus',
  GET_LINKED_JURISDICTIONS: 'jurisdictions/api/jurisdictionStatus/listJurisdictionsToLink',
  GET_JURISDICTION_REQUEST_STATUS_CHANGE_HISTORY:
    'jurisdictions/api/jurisdictionAccount/jurisdictionRequestStatusChangeHisotry',
  APPROVE_STATUS_CHANGE_API: 'jurisdictions/api/jurisdictionAccount/jurisdictionApprovalStatusChange',
  REVISIONS_REQUESTED_API: 'jurisdictions/api/jurisdictionStatus/jurisdiction/:jurisdictionId/revisionRequestedStatus',
  LINK_REQUEST_DETAILS: 'jurisdictions/api/jurisdictionStatus/jurisdiction/:jurisdictionId/linkRequestDetails',
  VALIDATE_INITIAL_USER: 'jurisdictions/api/jurisdictionOnboarding/validateInitialUser',
};

const REPORTS_APIS = {
  GET_TRANSFER_AUDIT_EVENT: 'transfers/api/entityTransfer/transferEventAudit',
};

const MANAGE_TRANSFERS_APIS = {
  GET_INTER_JURISDICTIONAL_TRANSFERS: 'jurisdictions/api/jurisdictionStatus/fetchInterJurisdictionalTransfers',
  GET_CROSS_JURISDICTIONAL_TRANSFERS: 'jurisdictions/api/jurisdictionStatus/fetchCrossJurisdictionalTransfers',
  SAVE_INTER_CROSS_JURISDICTION: 'jurisdictions/api/jurisdictionTransfer/addNewInterCrossJurisdictionalTransfers',
  GET_CONFLICT_FLAG: 'jurisdictions/api/jurisdictionTransfer/confictFlagForAllLinkedJurisdictions',
};

const MANAGE_EMISSIONS_APIS = {
  BATCH_EMISSIONS: 'emissions/api/batchEmission',
};

const MANAGE_TRUE_UPS_APIS = {
  BATCH_TRUE_UPS: 'emissions/api/batchTrueUp',
};

export const APIS = {
  FACILITY: 'entityCommon/api/facility',
  UPDATE_FACILITY: 'entityCommon/api/facility',
  REPRESENTATIVES: 'entityCommon/api/representatives',
  FACILITY_VERIFY: 'entityCommon/api/verifyghg',
  ENTITY: 'entityCommon/api/entity',
  ALTERNATE_CONTACT: 'entityCommon/api/alternatecontact',
  ENTITY_STATUS_HISTORY: 'entityCommon/api/entity/statushistory',
  ENTITY_TYPES: 'entityCommon/api/entitytypes',
  JURISDICTIONS: 'entityCommon/api/jurisdictions',
  PORPOSES: 'entityCommon/api/purposes',
  CLASIFICATIONS: 'entityCommon/api/classifications',
  FACILITY_COUNTRIES: 'entityCommon/api/countries',
  ENTITY_COUNTRIES: 'entityCommon/api/countries',
  ORGANIZATION_TYPES: 'entityCommon/api/organizationtypes',
  GET_ROLES: 'roles/api/get-Roles',
  REPRESENTATIVE_SELF: 'entityCommon/api/self',
  REPRESENTATIVE: 'entityCommon/api/representative',
  SUSPEND_REPRESENTATIVE: 'entityCommon/api/suspendRepresentatives',
  ACTIVATE_REPRESENTATIVE: 'entityCommon/api/activateRepresentative',
  RETIRE_REPRESENTATIVE: 'entityCommon/api/retireRepresentative',
  CANCEL_REPRESENTATIVE: 'entityCommon/api/cancelRepresentativeRequests',
  MY_REPRESENTATIVE_ASSIGNMENTS: 'entityCommon/api/assignedRepresentative',
  PROPOSED_REPRESENTATIVE: 'entityCommon/api/propose/representatives',
  GET_PROPOSED_REPRESENTATIVE: 'entityCommon/api/propose/representatives',
  REPRESENTATIVE_REFERENCECODE: 'entityCommon/api/referencecode',
  REVIEW_AND_SUBMIT: 'entityCommon/api/reviewsubmit/entity',
  AUCTION: 'entityCommon/api/auction',
  IS_SUMITTED: 'entityCommon/api/isSubmitted',
  ASSOCIATE_FACILITY: 'entityCommon/api/facility/associate',
  REQUEST_STATUS: 'entityCommon/api/requeststatus',
  RETIRED_FACILITY: 'entityCommon/api/facility/retire',
  SPECIAL_PURPOSE_ACCOUNT: 'entityCommon/api/specialPurposeAccount',
  ANNUAL_ALLOCATION_ACCOUNT: 'entityCommon/api/annualAllocationAccount',
  DENIAL_REASON: 'entityCommon/api/denialReasons',
  ENTITY_STATUSES: 'entityCommon/api/statuses',
  REQUEST_STATUSES: 'entityCommon/api/requeststatuses',
  DUPLICATE_ENTITIES: 'entityCommon/api/duplicateEntities',
  ENTITY_STATES: 'entityCommon/api/states',
  ORGANIZATION: 'public/organization',
  ROLE: 'auth/role',
  ROLE_TYPE: 'auth/roletype',
  ROLE_PAGE: 'auth/rolepage',
  PAGE: 'auth/page',
  SEARCH_PAGES: 'registration/search_pages',
  PRIVILEGE: 'auth/privilege',
  PUBLIC_PRIVILEGE: 'public/privilege',
  IS_EDIT_ACCESS: 'entityCommon/api/entityAccess',
  UPDATE_LOCK: 'entityCommon/api/updatelocked',
  PURPOSE_ENTITY: 'entityCommon/api/propose/entity',
  PURPOSE_FACILITY: 'entityCommon/api/propose/facility',
  APPROVE_DENY_CHANGES: 'entityCommon/api/approve-deny/proposedchange',
  REVERT_CHANGES: 'entityCommon/api/revert/proposedchange',
  STATUS_LIST: 'entityCommon/api/statuses',
  ACCOUNTS: 'entityCommon/api/entity/accounts',
  ACCOUNTS_STATUS: 'entityCommon/api/accounts',
  CONFIG: 'entityCommon/api/config',
  REVISIONS_REQUESTED: 'entityCommon/api/updateRevisions/entity',
  CWI: 'entityCommon/api/cwi',
  REPRESENTATIVE_STATUS_HISTORY: 'entityCommon/api/representative/history',
  FACILITY_OWNER_HISTORY: 'entityCommon/api/facilityOwnerHistory',
  NAICS_CODE: 'entityCommon/api/naicscodes',
  REMOVE_FACILITY: 'entityCommon/api/facility',
  SPECIAL_ACCOUNTS: 'entityCommon/api/special-accounts',
  CAG_DETAILS: 'entityCommon/api/cag',
  ADD_CAG_MEMBERS: 'entityCommon/api/cag/member',
  VERIFY_GROUP_REF: 'entityCommon/api/cag/verifygroupref',
  VERIFY_GROUP_NAME: 'entityCommon/api/cag/verifygroupname',
  CAG_GROUP_LIST: 'entityCommon/api/cag-groups',
  CAG_HISTORY: 'entityCommon/api/cag-history',
  CAG_DETAILS_BY_ENTITYID: 'entityCommon/api/cag/entity',
  CAGJURYS: 'entityCommon/api/cross-jurisdiction',
  ACTIVATE_CAG: 'entityCommon/api/cag-activate',
  CLOSE_CAG: 'entityCommon/api/cag-closed',
  ACCOUNT_APPLICATIONS: 'entityCommon/api/entity/inprogress',
  MY_ACCOUNTS: 'entityCommon/api/entity/active',
  PENDING_ACCOUNT_APPLICATIONS: 'entityCommon/api/entity/pending',
  REPORT_STATUS: 'report-status',
  API_MGMT: 'sync/api',

  ...COMPLIANCE_INSTRUMENTS_APIS,
  ...OFFSET_MANAGEMENT_APIS,
  ...EXCHANGE_CONTRACT_CODE,
  ...ENTITY_TRANSFERS_APIS,
  ...MRT_APIS,
  ...SETUP_INITIAL_USER_APIS,
  ...SETUP_JURISDICTION_ACOUNTS,
  ...REPORTS_APIS,
  ...MANAGE_TRANSFERS_APIS,
  ...MANAGE_EMISSIONS_APIS,
  ...MANAGE_TRUE_UPS_APIS,

  FIELD_PERMISSIONS: 'entityCommon/api/field-permissions',
  BUDGETS: 'budgets/api',
  BATCH_TRANSFERS: 'transfers/api/batchTransfer',
  LIMITED_EXEMPTIONS: 'budgets/api/limited-exemptions',
  NAME_CHANGE_HISTORY: 'entityCommon/api/entity/name-change-history',
  ENTITY_TYPE_CHANGE_HISTORY: 'entityCommon/api/entity/type-change-history',
  MANAGE_PURCHASE_LIMITS: 'limits/api/purchaseLimit',
  MANAGE_HOLDING_LIMITS: 'limits/api/holdingLimit',
  CLUSTER_TRANSFERS: 'transfers/api/clusterTransfer',
  ADMIN_TRANSFERS: 'transfers/api/adminTransfer',
  TRANSFERS_BASE: 'transfers/api',
  SEARCH_FACILITY: 'entityCommon/api/search-facility',
  SEARCH_FACILITY_LIST: 'entityCommon/api/search-facility-list',
  CRON_JOB: 'batchJobs/api/batch/cronJob',
  BATCH_JOB: 'batchJobs/api/batch',
  CROSSJURYS: 'events/api/jurisdictions/cross-jurisdiction',
  CREATE_EVENT: 'events/api/jurisdictions/{jurisdictionId}/events/create-event',
  GET_EVENT_TYPE: 'events/api/events-type',
  GET_VINTAGE_TYPE: 'events/api/vintage-types',
  EVENT_API: 'events/api/jurisdictions',
  GET_BID_GUARANTEE: 'events/api/bid-guarantee-types',
  FETCH_USER_DETAILS: 'jurisdictions/api/jurisdictionOnboarding/fetchUserDetails',
  ONBOARD_JURISDICTION: 'jurisdictions/api',
  EVENT_APPLICATION_API: 'events/api/jurisdictions',
  GET_ENTITY_ID: 'events/api/jurisdictions',
  REPORTS_API: 'reports/api/jurisdictions',
  GET_JURISDICTIONS: 'events/api/getJurisdictionDetails',
  REGISTRYENTITY_REPORT: 'registeredEntity-facilityReport',
  ENTITY_LEGAL_OP_NAME_REPORT: 'entityLegalName_OperatingNameChangeReport',
  FACILITY_GHG_REPORT: 'facility-ghg-report',
  FACILITY_NAME_CHANGE_REPORT: 'facility-name-change-report',
  NAICS_CODE_DISPLAY_REPORT: 'naics-code-display-report',
  WIRING_INST_STATUS_REPORT: 'wiring-instruction-status-report',
  SERVICEPROSELMGM_API: 'events/api/jurisdictions',
  SERVICEPROVIDERMUIAUC_API: 'events/api',
};

// This will accept user details, userRoleTypes and roleIds and return if those are assign to current user
export const isRolesAssigned = (user, userRoleTypes = [], roleIds = []) => {
  if (isEmpty(user)) {
    return null;
  }

  if (!isEmpty(userRoleTypes) && !isEmpty(roleIds)) {
    const roleTypes = user.RoleDetail?.RoleType?.find((item) => userRoleTypes.includes(item.name));
    const roles = user.RoleDetail?.Role?.find((item) => roleIds.includes(item.id));

    return roleTypes && roles;
  }

  return user.RoleDetail?.RoleType?.find((item) => userRoleTypes.includes(item.name));
};

export function ShowInlineEntityAddress(address, t, t1) {
  if (isEmpty(address)) return;
  return [
    address.street1,
    address.street2,
    address.city,
    t(t1, address?.State?.Name) || t(t1, address?.state) || t(t1, address?.region),
    t(t1, address?.Country?.Name) || t(t1, address?.country),
    address?.postalCode,
  ]
    .filter((item) => item)
    .join(', ');
}

export function ShowInlineAddressCaps(address, t, t1) {
  if (isEmpty(address)) return;
  return [
    address.Street1,
    address.Street2,
    address.City?.Name,
    t(t1, address?.Country?.Name),
    t(t1, address?.State?.Name),
    address?.postalCode,
  ]
    .filter((item) => item)
    .join(', ');
}

export function ShowInlinePendingAddressFacility(address, t, t1) {
  if (isEmpty(address)) return;
  return [
    address?.street1,
    address?.street2,
    address?.city,
    address.region,
    t(t1, address?.state?.name),
    t(t1, address?.country?.name),
    address?.postalCode,
  ]
    .filter((item) => item)
    .join(', ');
}

export function ShowInlinePurposeAddressFacility(address, t, t1) {
  if (isEmpty(address)) return;
  return [
    address?.street1,
    address?.street2,
    address?.city,
    address?.region,
    t(t1, address?.state?.name),
    t(t1, address?.country?.name),
    address?.postalCode,
  ]
    .filter((item) => item)
    .join(', ');
}

export function ShowInlineAddressFacility(address, t, t1) {
  if (isEmpty(address)) return;
  return [
    address.street1,
    address.street2,
    address.city,
    address.region,
    t(t1, address?.State?.name ? address?.State?.name : address?.state?.name),
    t(t1, address?.Country?.name ? address?.Country?.name : address?.country?.name),
    address?.postalCode,
  ]
    .filter((item) => item)
    .join(', ');
}

export function ShowInlineAddressAddFacility(address, t, t1, Country, State) {
  if (isEmpty(address)) return;
  return [
    address?.street1,
    address?.street2,
    address?.city,
    address?.region,
    t(t1, address?.state?.name) || t(t1, State?.Name),
    t(t1, address?.country?.name) || t(t1, Country?.Name),
    address?.postalCode,
  ]
    .filter((item) => item)
    .join(', ');
}

export function ShowInlineAddressSearchFacility(address, t, t1) {
  if (isEmpty(address)) return '';
  return [
    address?.street1,
    address?.street2,
    address?.city,
    address?.region,
    t(t1, address?.state),
    t(t1, address?.country),
    address?.postalCode,
  ]
    .filter((item) => item)
    .join(', ');
}

export function ShowInlineAddressForTable(item, t, t1) {
  if (isEmpty(item)) return;
  return [
    item.ResStreet1,
    item.ResStreet2,
    item.ResCity,
    item.region,
    t(t1, item?.ResState),
    t(t1, item?.ResCountry),
    item?.ResPinCode,
  ]
    .filter((item) => item)
    .join(', ');
}

export const digitValidation = (value, name, t1) => {
  if (value <= 100 && value >= 0) {
    return true;
  }
  return `${t(t1, name)}`;
};

export const RESPONSE_MESSAGE_KEYS = {
  ENTITY_IS_CLOSED: 'ENTITY_IS_CLOSED',
  ENTITY_IS_ACTIVE: 'ENTITY_IS_ACTIVE',
  APPROVE_STATUS_UPDATED: 'APPROVE_STATUS_UPDATED',
  CANCEL_STATUS_UPDATED: 'CANCEL_STATUS_UPDATED',
  DENY_STATUS_UPDATED: 'DENY_STATUS_UPDATED',
  REQUEST_REVISION_STATUS_UPDATED: 'REQUEST_REVISION_STATUS_UPDATED',
  DENY_CHANGES_SUCCESSFULLY: 'DENY_CHANGES_SUCCESSFUL',
  APPROVE_CHANGES_SUCCESSFULLY: 'APPROVE_CHANGES_SUCCESSFUL',
  REVERT_CHANGES_SUCCESSFULLY: 'REVERT_CHANGES_SUCCESSFUL',
  ENTITY_ACCOUNTS_ARE_NOT_CLOSED: 'ENTITY_ACCOUNTS_ARE_NOT_CLOSED',
  CLOSE_CAG_SUCCESSFULLY: 'CLOSE_CAG_SUCCESSFULLY',
  ACTIVATE_CAG_SUCCESSFULLY: 'ACTIVATE_CAG_SUCCESSFULLY',
  CAG_GROUP_ADDED_SUCCESSFULLY: 'CAG_GROUP_ADDED_SUCCESSFULLY',
  SUCCESSFULLY_ADDED_THE_MEMBER_TO_CAG: 'SUCCESSFULLY_ADDED_THE_MEMBER_TO_CAG',
  ENTITY_ID_BELONGS_TO_OTHER_JURISDICTION_CAN_NOT_BE_ADDED_OR_EDITED:
    'ENTITY_ID_BELONGS_TO_OTHER_JURISDICTION_CAN_NOT_BE_ADDED_OR_EDITED',
  CANNOT_CLOSE_THIS_ENTITY_AS_THIS_IS_ASSOCIATED_WITH_A_CORPORATE_ASSOCIATION_GROUP:
    'CANNOT_CLOSE_THIS_ENTITY_AS_THIS_IS_ASSOCIATED_WITH_A_CORPORATE_ASSOCIATION_GROUP',
  CAG_MEMBER_ALREADY_EXIST: 'CAG_MEMBER_ALREADY_EXIST',
};

export const ENTITY_CHANGES_ACTIONS = {
  APPROVE: 'approve',
  DENY: 'deny',
  REVERT: 'revert',
  CANCEL_REQUEST: 'cancelRequest',
};

export const PROPOSED_REPRESENTATIVE_STATUS = {
  PROPOSED: 'PROPOSED',
  PENDING: 'PENDING',
};

export const FACILITY_STATUS = {
  RETIRED: 'RETIRED',
  ACTIVE: 'ACTIVE',
  PROPOSED: 'PROPOSED',
};

export const GHG_API_ERROR_KEYS = {
  GHG_VERIFY_ALREADY_EXIST: 'GHG_VERIFY_ALREADY_EXIST',
  GHG_ID_IS_UNIQUE: 'GHG_ID_IS_UNIQUE',
  GHG_VERIFY_ALREADY_EXIST_PLEASE_SELECT_FACILITY_IS_IN_REGISTRY:
    'GHG_VERIFY_ALREADY_EXIST_PLEASE_SELECT_FACILITY_IS_IN_REGISTRY',
  GHG_BELONGS_TO_ANOTHER_JURI: 'GHG_BELONGS_TO_ANOTHER_JURI',
  GHG_EXISTS_IN_ANOTHER_ENTITY: 'GHG_EXISTS_IN_ANOTHER_ENTITY',
  GHG_VERIFY_ALREADY_EXIST_BUT_NOT_IN_REGISTRY: 'GHG_VERIFY_ALREADY_EXIST_BUT_NOT_IN_REGISTRY',
};

export const ENTITY_SECTION_TYPES = {
  FACILITIES: 'facilities',
  REPRESENTATIVES: 'representatives',
  GENERAL_DETAILS: 'general_details',
  IDENTIFIERS: 'identifiers',
  PHYSICAL_ADDRESS: 'physical',
  MAILING_ADDRESS: 'mailing',
  REPRESENTATIVESTATUSHISTORY: 'RepresentativeStatusHistorySection',
};

export const ENTITY_ACCOUNT_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  SUSPENDED: 'SUSPENDED',
  CLOSED: 'CLOSED',
  RESTRICTED: 'RESTRICTED', // Only for UI
  RESTRICTED_CANNOT_TRANSFER: 'RESTRICTED_CANNOT_TRANSFER',
  RESTRICTED_CANNOT_RECEIVE: 'RESTRICTED_CANNOT_RECEIVE',
  RESTRICTED_CANNOT_TRANSFER_AND_RECEIVE: 'RESTRICTED_CANNOT_TRANSFER_AND_RECEIVE',
};

export const ENTITY_STATUS = {
  CANCELLED: 'CANCELLED',
  APPLICATION_IN_PROGRESS: 'APPLICATION_IN_PROGRESS',
  PENDING: 'PENDING',
  REVISIONS_REQUESTED: 'REVISIONS_REQUESTED',
  ACTIVE: 'ACTIVE',
  DENIED: 'DENIED',
  CLOSED: 'CLOSED',
  APPLICATION_PENDING: 'APPLICATION_PENDING',
};

export const ACCOUNT_ACTION_PAGE = {
  ACCOUNT_DETAILS: 'accountDetails',
  ENTITY_PROFILE: 'entityProfile',
  JURISDICTION_ACCOUNTS: 'JurisdictionAccounts',
  JURISDICTION_ACCOUNT_DETAILS: 'JurisdictionAccountDetails',
};

export const FACILITIES_PAGE = {
  ENTITY_PROFILE: 'entityProfile',
};

export const CWI_ACTIONS = {
  ADD: 'add',
  EDIT: 'edit',
  VIEW: 'view',
  DENY: 'deny',
  VERIFY: 'verify',
  CANCEL: 'cancel',
  CONFIRM: 'confirm',
};

export const CWI_STATUS = {
  VERIFIED: 'CWI_VERIFIED',
  CANCELLED: 'CWI_CANCELLED',
  CONFIRMED: 'CWI_CONFIRMED',
  CWI_INVALIDATED_BY_FSA: 'CWI_INVALIDATED_BY_FSA',
  PROPOSED: 'CWI_PROPOSED',
  INVALIDATED: 'CWI_INVALIDATED',
  CWI_CONFIRMED_BY_FSA: 'CWI_CONFIRMED_BY_FSA',
};

export const CWI_STATUS_HISTORY = {
  CWI_VERIFIED_STATUS_HISTORY: 'CWI_VERIFIED_STATUS_HISTORY',
  CWI_INVALIDATED_STATUS_HISTORY: 'CWI_INVALIDATED_STATUS_HISTORY',
  CWI_CANCELLED_STATUS_HISTORY: 'CWI_CANCELLED_STATUS_HISTORY',
  CWI_PROPOSED_STATUS_HISTORY: 'CWI_PROPOSED_STATUS_HISTORY',
  CWI_CONFIRMED_BY_FSA_STATUS_HISTORY: 'CWI_CONFIRMED_BY_FSA_STATUS_HISTORY',
  CWI_INVALIDATED_BY_FSA_STATUS_HISTORY: 'CWI_INVALIDATED_BY_FSA_STATUS_HISTORY',
  CWI_INCOMPLETE_STATUS_HISTORY: 'CWI_INCOMPLETE_STATUS_HISTORY',
};

export const REPRESENTATIVE_CONFIG_VALUES = {
  MAXIMUM: 'MAXIMUM',
  MINIMUM: 'MINIMUM',
};

export const JURISDICTION_ORG_NAMES = {
  NOVA_SCOTIA: 'Nova Scotia',
  QUEBEC: 'Quebec',
  CALIFORNIA: 'California',
  WASHINGTON_STATE: 'Washington State',
};

export const FORM_ACTIONS = {
  ADD: 'add',
  EDIT: 'edit',
};

export const NEITHER_ACCOUNT_TYPE = 'NEITHER';

export const ENTITY_ACCOUNT_STATUS_FOR_MY_ACCOUNT = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  SUSPENDED: 'SUSPENDED',
  CLOSED: 'CLOSED',
  RESTRICTED_CANNOT_TRANSFER: 'RESTRICTED_CANNOT_TRANSFER',
  RESTRICTED_CANNOT_RECEIVE: 'RESTRICTED_CANNOT_RECEIVE',
  RESTRICTED_CANNOT_TRANSFER_AND_RECEIVE: 'RESTRICTED_CANNOT_TRANSFER_AND_RECEIVE',
};

export const CAD_STATUS = {
  ACTIVE: 'CAG_ACTIVE',
  CLOSED: 'CAG_CLOSE',
  IN_PROGRESS: 'CAG_IN_PROGRESS',
  CAG_MEMBER_IN_PROGRESS: 'CAG_MEMBER_IN_PROGRESS',
  CAG_MEMBER_RETIRED: 'CAG_MEMBER_RETIRED',
};

export const CAG_MEMBERS = {
  CAG_MEMBERS_DISABLE_CHECK: 'disabledCheck',
  CAG_MEMBERS_DISABLE_CHECK_VAL_TRUE: '1',
  CAG_MEMBERS_DISABLE_CHECK_VAL_FALSE: '0',
  CAG_MEMBERS_GRID_CELL_CLICK: 'cellClick',
  CAG_MEMBERS_GRID_CELL_CLICK_VAL_TRUE: '1',
  CAG_MEMBERS_GRID_CELL_CLICK_VAL_FALSE: '0',
  CAG_MEMBERS_VALID_ROW_CHECK: 'validRowCheck',
  CAG_MEMBERS_VALID_ROW_CHECK_VAL_TRUE: '1',
  CAG_MEMBERS_VALID_ROW_CHECK_VAL_FALSE: '0',
};

export const PERMISSION_NAMES = {
  READ: 'read',
  WRITE: 'write',
  MANDATORY: 'mandatory',
};

function isDateValue(value) {
  if (value === 'undefined' || value === null) {
    return false;
  }
  let checkDate = isValid(new Date(value));
  if (Number(value)) {
    checkDate = isValid(value);
  }
  return checkDate;
}

export const translateRows = (rows = [], columns = [], t = null, t1 = null) =>
  rows.map((item) => {
    const newItem = { ...item };
    columns.forEach((column) => {
      if (isDateValue(newItem[column])) {
        newItem[column] = newItem[column] ? `${formatDate(newItem[column])}` : `${t('NA')}`;
      } else {
        newItem[column] = t(t1, newItem[column]);
      }
    });
    return newItem;
  });

export const statusTypes = {
  PROPOSED_CHANGES: 'PROPOSED_CHANGES',
  PROPOSED: 'PROPOSED',
  PROPOSED_TO_AUTHORITY: 'PROPOSED_TO_AUTHORITY',
  REVISIONS_REQUESTED: 'REVISIONS_REQUESTED',
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
  CANCELLED: 'CANCELLED',
};

export const displayStatusTypes = {
  PROPOSED_TO_AUTHORITY: 'Proposed to Authority',
  APPROVALS_BR_KEY_INFO: 'Proposed Budget Records',
  APPROVALS_BR_TYPE: 'Budget Record',
};

export const LIMITED_EXEMPTION_STATUSES = {
  APPROVED: 'APPROVED',
  PROPOSED: 'PROPOSED',
  REQUEST_REVISION: 'REQUEST_REVISION',
  SAVED: 'SAVED',
  DENIED: 'DENIED',
  CANCELLED: 'CANCELLED',
};
export const ISSUANCE_STATUSES = {
  APPROVED: 'APPROVED',
  PROPOSED: 'PROPOSED',
  REQUEST_REVISION: 'REQUEST_REVISION',
  SAVED: 'SAVED',
  DENIED: 'DENIED',
};

export const ISSUANCE_STATUSES_BE = {
  REVISION_REQUESTED: 'REVISION_REQUESTED',
};

export const ACCEPT_DECLINE_STATUSES = {
  ACCEPT: 'ACCEPT',
  DECLINE: 'DECLINE',
};

export const LANGUAGE_LOCALES = {
  English: 'en',
  German: 'de',
  French: 'fr',
};

export const STATUSES = {
  ENTITY_ACCOUNTS: 'ENTITY_ACCOUNTS',
  JURISDICTION_ACCOUNTS: 'JURISDICTION_ACCOUNTS',
  JURISDICTIONS: 'JURISDICTIONS',
};

export const formatNumber = (data, options = {}) => {
  try {
    const { shouldFormatWithCMSSeperators, ...restOptions } = options;
    const parsedData = typeof data === 'string' ? BigInt(data) : data;
    if (!Number.isNaN(parsedData) && parsedData !== Infinity) {
      return shouldFormatWithCMSSeperators
        ? formatNumberString(data?.toString())
        : parsedData?.toLocaleString(LANGUAGE_LOCALES[enLanguage()], restOptions);
    }
    return data;
  } catch (error) {
    console.error('Error occurred while formatting number:', error);
    return data;
  }
};

export const TitleTypography = styled(Typography)({
  fontWeight: 700,
  fontSize: '14px',
  letterSpacing: '0.2px',
  margin: '1rem 0 0.5rem 0',
  display: 'flex',
});

export const getHourAndMinute = (dateTimeString) => {
  const [, timePart] = dateTimeString.split(' ');
  const [hour, minute] = timePart.split(':');
  return { hour, minute };
};

export const getTimeStampForExportedFile = () => {
  const today = formatDateUpdated(new Date(), '-', false, false).trim();
  const formattedDate = today.slice(0, 10);
  const { hour, minute } = getHourAndMinute(today);
  return `${formattedDate}_${hour}${minute}`;
};

export const LIMITED_EXEMPTIONS_EXCEL_HEADERS_ENG = {
  'Entity Identifier': 'entityId',
  'Limited Exemption': 'limitedExemption',
  Comment: 'comment',
  'Effective Date': 'effectiveDate',
};

export const LIMITED_EXEMPTIONS_EXCEL_HEADERS_FR = {
  'Numéro didentification dentité CITSS': 'entityId',
  Exemption: 'limitedExemption',
  Commentaire: 'comment',
  'Date dentrée en vigueur ': 'effectiveDate',
};

export const MAX_UPLOAD_FILE_SIZE = 5000000;
export const CWI_FSA_ACTIONS = {
  CONFIRM: 'confirm',
  INVALIDATE: 'invalid',
};

export const CURRENCY_OPTIONS = [
  {
    label: 'SELECT_CURRENCY',
    value: '',
  },
  {
    label: 'CAD',
    value: 'CAD',
  },
  {
    label: 'USD',
    value: 'USD',
  },
];

export const ADD_BATCH_PATH = 'add';

export const BATCH_TRANSFER_STATUS = {
  ACTIVE: 'ACTIVE',
  SAVED: 'SAVED',
  PROPOSED: 'PROPOSED',
  CANCELLED: 'CANCELLED',
  REVISIONS_REQUESTED: 'REVISIONS_REQUESTED',
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
  COMPLETE: 'COMPLETE',
};

export const BATCH_TRANSFER_TYPES = {
  BATCH_TRANSFER: 'JURISDICTION_BATCH_TRANSFER',
  JURISDICTION_BATCH_TRANSFER_PCRA: 'JURISDICTION_BATCH_TRANSFER_PCRA',
};

export const JURISDICTION_STATUSES = {
  APPROVED: 'APPROVED',
  REQUEST_REVISION: 'REQUEST_REVISION',
};

// Roles & privilege access - to show field
export const SopfDetailView = (component = null, uiComponentName = null, fieldName = null, fieldAccess = null) => {
  const fieldWithAccess = fieldAccess?.find((item) => item?.componentName === uiComponentName);
  const fieldPermission = fieldWithAccess?.componentFields?.find((item) => item?.fieldName === fieldName);
  if (fieldPermission?.fieldPermission?.includes('read') || fieldPermission?.fieldPermission?.includes('write')) {
    return component;
  }
  return '';
};

export const checkIsRequired = (fieldPermissions = [], componentName = '', fieldName = null) => {
  const component = fieldPermissions.find((item) => item.componentName === componentName);
  const field = component?.componentFields.find((item) => item.fieldName === fieldName);
  if (field?.fieldPermission?.includes('mandatory')) {
    return 'mandatory';
  }
  return false;
};

// Roles & privilege access - to show field
export const checkIsReadOrWrite = (fieldPermissions = [], componentName = '', fieldName = null) => {
  const component = fieldPermissions.find((item) => item.componentName === componentName);
  const field = component?.componentFields.find((item) => item.fieldName === fieldName);
  if (field?.fieldPermission?.includes('write')) {
    return 'write';
  }
  if (field?.fieldPermission?.includes('read')) {
    return 'read';
  }

  return false;
};

// To show field columns
export const showOnlyPermittedFields = (columns, fields = [], fieldAccess = [], componentName = null) => {
  if (!isEmpty(componentName)) {
    const component = fieldAccess?.find((item) => item.componentName === componentName);

    return columns.filter((column) => {
      const field = component?.componentFields.find((item) => item.fieldName === column.field);
      return !isEmpty(field) && field?.fieldPermission.includes('read');
    });
  }

  fields = [...fields, 'QuickActions', 'CAG', '__check__'];
  return columns.filter((column) => fields.includes(column.field));
};

export const NAvalue = 'NA';

export const NASlashValue = 'N/A';

export const MAX_CHAR_LIMIT = 60;

export const QUANTITY_MAX_CHAR_LIMIT = 18;

export const COMMENT_MAX_CHAR_LENGTH = 8000;

export const TEXT_MAX_CHAR_LENGTH = 255;

export const NAME_MAX_CHAR_LENGTH = 50;

export const EXCHANGE_CONTRACT_CHAR_LENGTH = 63;

export const PRICE_MAX_CHAR_LIMIT = 13;

export const MIN_CHARACTER_LENGTH = 3;

export const HOLDING_LIMITS_STATUSES = {
  SAVED: 'SAVED',
  PROPOSED: 'PROPOSED',
  APPROVED: 'APPROVED',
  REQUEST_REVISION: 'REQUEST_REVISION',
  DENIED: 'DENIED',
  CANCELLED: 'CANCELLED',
  PROPOSE: 'PROPOSE',
  APPROVE: 'APPROVE',
  DENY: 'DENY',
};

// Limits to add validations for create user profile form fields
export const CREATE_USER_PROFILE_FORM_FIELDS = {
  SALUTATION_MAX_CHAR: 20,
  EXTENSION_MAX_CHAR: 10,
  FIRST_NAME_MAX_CAR: 50,
  MIDDLE_NAME_MAX_CAR: 50,
  LAST_NAME_MAX_CAR: 100,
  POSTAL_CODE_MIN_CHAR: 3,
  POSTAL_CODE_MAX_CHAR: 20,
  STREET1_MAX_CHAR: 255,
  STREET2_MAX_CHAR: 255,
  CITY_MAX_CHAR: 255,
  REGION_MAX_CHAR: 255,
};

// Limits to add validations for jurisdiction user actions form fields
export const JURISDICTION_USER_ACTION_FORM_FIELDS = {
  INACTIVE_USER_REASON_MIN_CHAR: 3,
  INACTIVE_USER_REASON_MAX_CHAR: 1024,
  TELEPHONE_MIN_CHAR: 10,
  TELEPHONE_MAX_CHAR: 20,
  EMAIL_MAX_CHAR: 205,
};

export const EMISSIONS_STATUSES = {
  COMPLETE: 'COMPLETE',
  SAVED: 'SAVED',
  PROPOSED: 'PROPOSED',
  REVISIONS_REQUESTED: 'REVISIONS_REQUESTED',
  DENIED: 'DENIED',
  APPROVED: 'APPROVED',
};

export const TRUE_UP_STATUSES = {
  COMPLETE: 'COMPLETE',
  SAVED: 'SAVED',
  PROPOSED: 'PROPOSED',
  REVISIONS_REQUESTED: 'REVISIONS_REQUESTED',
  DENIED: 'DENIED',
  APPROVED: 'APPROVED',
};

export const OTR_STATUSES = {
  COMPLETE: 'COMPLETE',
  FREEZE_APPROVED: 'FREEZE_APPROVED',
  FREEZE_DENIED: 'FREEZE_DENIED',
  FREEZE_PROPOSED: 'FREEZE_PROPOSED',
  FREEZE_REVISIONS_REQUESTED: 'FREEZE_REVISIONS_REQUESTED',
  SAVED: 'SAVED',
  UNFREEZE_APPROVED: 'UNFREEZE_APPROVED',
  UNFREEZE_PROPOSED: 'UNFREEZE_PROPOSED',
  UNFREEZE_REVISIONS_REQUESTED: 'UNFREEZE_REVISIONS_REQUESTED',
  DELETE: 'DELETE',
  FREEZE_OTR: 'FreezeOTR',
  UNFREEZE_OTR: 'UnfreezeOTR',
  DELETED_OTR: 'Deleted OTR',
};

export const OTR_STATUSES_FE = {
  COMPLETE: 'Complete',
  FREEZE_APPROVED: 'Freeze Approved',
  FREEZE_DENIED: 'Freeze Denied',
  FREEZE_PROPOSED: 'Freeze Proposed',
  FREEZE_REVISIONS_REQUESTED: 'Freeze Revisions Requested',
  SAVED: 'Saved',
  UNFREEZE_APPROVED: 'Unfreeze Approved',
  UNFREEZE_PROPOSED: 'Unfreeze Proposed',
  UNFREEZE_REVISIONS_REQUESTED: 'Unfreeze Revisions Requested',
  DELETE: 'Deleted',
  FREEZE_OTR: 'FreezeOTR',
  UNFREEZE_OTR: 'UnfreezeOTR',
  DELETED_OTR: 'Deleted OTR',
};

export const OTR_ACTION = {
  APPROVE: 'APPROVE',
  REQUEST_REVISION: 'REQUEST_REVISION',
  DENY: 'DENY',
};

export const APPROVAL_TYPES = {
  ALLOWANCE_ISSUANCE: 'AllowanceIssuance',
  OFFSET_ISSUANCE: 'OffsetIssuance',
  OFFSET_TRACKING_RECORD: 'Offset Tracking Record',
  ADMINISTRATIVE: 'ADMINISTRATIVE',
  REPLENISHMENT: 'REPLENISHMENT',
  JURISDICTION_BATCH_TRANSFER: 'JURISDICTION_BATCH_TRANSFER',
  HOLDING_LIMIT: 'HOLDING_LIMIT',
  BATCH_EXEMPTION: 'BATCH_EXEMPTION',
  BUDGET_RECORD: 'Budget Record',
  JURISDICTION_STATUS: 'JURISDICTION_STATUS',
  COMMON_BATCH_EMISSIONS: 'COMMON_BATCH_EMISSIONS',
  COMMON_TRUEUP_BATCH: 'COMMON_TRUEUP_BATCH',
};

export const getProposedEntityType = (entityType = false, proposeChanges = {}) => {
  if (proposeChanges?.entity?.entityType?.name) {
    return proposeChanges?.entity?.entityType?.name === entityType;
  }
  return false;
};

export const compareVals = (current, defaultVal, proposedVal) => {
  if (isEmpty(current)) {
    return false;
  }
  if (!isEmpty(proposedVal?.toString())) {
    if (current === proposedVal) {
      return false;
    }
    if (current !== proposedVal) {
      if (current === defaultVal) {
        return false;
      }
      if (current !== defaultVal) {
        return true;
      }
    }
  }
  return !!(isEmpty(proposedVal?.toString()) && current !== defaultVal);
};

export const JURISDICTION_TRANSFER_TYPES = {
  ADMINISTRATIVE: 'Administrative',
  REPLENISHMENT: 'Replenishment',
};

export const DEFAULT_ERROR_MSG = 'Something went wrong';

// Telephone/email update proposal
export const ApproveEmailTelephoneRequest = 'ApprovedEmailAndTelephoneRequest';
export const CancelEmailTelephoneRequest = 'CancelEmailAndTelephoneRequest';
export const DenyEmailTelephoneRequest = 'DenyEmailAndTelephoneRequest';
export const MET_STATUSES_BE = {
  PROPOSED: 'PROPOSED',
  APPROVED: 'APPROVED',
  CANCELLED: 'CANCELLED',
  COMPLETE: 'COMPLETE',
  DENIED: 'DENIED',
  REQUEST_REVISION: 'REQUEST_REVISION',
};
// added to display message for blocked user case
export const USER_ID_OR_PASSPHRASE_ENTERED_IS_INVALID = 'USER_ID_OR_PASSPHRASE_ENTERED_IS_INVALID';
export const MSG_ERR_INTERNAL_SERVER = 'MSG_ERR_INTERNAL_SERVER';
export const MSG_ERR_USER_BLOCKED = 'MSG_ERR_USER_BLOCKED';

export const CUSTOM_INPUT_ALLOWED_PATTERN = {
  bigInt: /^\d{0,18}$/,
  float: /^\d{0,13}(\.\d{0,2})?$/,
  floatFrench: /^\d{0,13}(,\d{0,2})?$/,
  alphaNumPattern: /^[a-zA-Z0-9-]*$/,
};

export const allowedAccountNameRegex = /^[a-zA-Z0-9]+-[0-9]+$/;

const terminalStateList = [
  'Registration Denied',
  'Registration Approved',
  'Cancelled by Applicant',
  'Cancelled By Jurisdiciton',
  'Cancelled COJ',
  'Denied COJ',
  'Approved COJ',
  'Cancelled Cross Jurisdiction',
  'Denied Cross Jurisdiction',
  'Approved Cross Jurisdiction',
  'Unshared',
  'User Details Change Approved',
  'User Details Change Denied',
  'Proposal Approved',
  'Proposal Denied',
  'Role Change Denied',
  'Role Change Approved',
  'Cancelled due to COJ',
  'User Details Change Cancelled',
];

// Check if the given state is present in the terminalState and then allow user to proceed else hide btns/disable user
// to proceed further
export const IsTerminalState = (state) => terminalStateList.includes(state?.toString()?.trim());

// Used to generate unique string to use in cognito
export const getUniqueUsername = () => `${Math.floor(Math.random() * 1000000)}-${Date.now()}`;

export const allowedFileUploadFormats = ['csv', 'xls', 'xlsx'];

export const fileInputAcceptedFormat =
  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

export function removeTrailingEmptyObjects(arr) {
  let lastIndexWithData = arr.length - 1;
  while (lastIndexWithData >= 0) {
    const obj = arr[lastIndexWithData];
    if (Object.keys(obj).length > 0) {
      break;
    }
    arr.pop();
    lastIndexWithData -= 1;
  }
  return arr;
}

export const REFRESH_REQUIRED = 'REFRESH_ERROR';

// Prepare employer list to display in dynamic form fields
export const employerList = (defaultEmployer) => {
  let finalList = [];
  if (defaultEmployer) {
    // check if received value is string which is from backend
    if (typeof defaultEmployer === 'string' || defaultEmployer instanceof String) {
      // added condition to handle existing employer string value
      if (defaultEmployer.includes(`"title":`)) {
        finalList = JSON.parse(defaultEmployer);
      } else {
        finalList = [
          {
            title: defaultEmployer,
          },
        ];
      }
    } else {
      finalList = defaultEmployer;
    }
    return finalList.filter((employerObj) => employerObj.title !== '');
  }
  // send default object to display single employer empty field initially
  return [
    {
      title: '',
    },
  ];
};

// Used to display employers list in string format in table column
export const displayEmployerList = (employerArray) => {
  if (typeof employerArray === 'string') {
    if (employerArray.includes(':')) {
      try {
        return (
          JSON.parse(employerArray)
            ?.map((obj) => obj.title)
            .join(', ') || ''
        );
      } catch (e) {
        return employerArray;
      }
    }
  }
  return employerArray;
};

export const NOT_AVAILABLE_FROM_LIST_TEXT = 'Not Available from List';

// Limits to add validations for notification config settings
export const CONFIG_NOTI_SETTINGS_FIELDS = {
  EMAIL_MAX_CHAR: 320,
};

// Regex used to allow only numbers 1 to 9 in Configuration settings
export const configSettingsCountRegex = /^[1-9]\d*$/;

export const EDT_DATE_FORMAT_CONST = '(yyyy-mm-dd hh:mm:ss ET)';

export const NAICSCodeOption = 'NAICSCode';

export const accountStatusColumns = (t) =>
  t && [
    {
      id: 2,
      field: 'jurisdiction',
      headerName: t('JURISDICTION'),
      flex: 1,
      align: 'center',
      renderCell: ({ row }) => <Typography variant="body2">{t(`${getCMSKeySnakeCase(row.jurisdiction)}`)}</Typography>,
    },
    {
      id: 4,
      field: 'userStatus',
      headerName: t('APPLICANT_USER_STATUS'),
      flex: 1,
      align: 'center',
      renderCell: ({ row }) => (
        <Typography variant="body2">{t(`${getCMSKey('UserStatus', row.userStatus)}`)}</Typography>
      ),
    },
    {
      id: 4,
      field: 'userAccountStatus',
      headerName: t('USER_ACCOUNT_STATUS'),
      flex: 1,
      align: 'center',
      renderCell: ({ row }) => (
        <Typography variant="body2">{t(`${getCMSKey('UserAccountStatus', row.userAccountStatus)}`)}</Typography>
      ),
    },
    {
      id: 5,
      field: 'changeDate',
      headerName: t('CHANGE_DATE'),
      flex: 1,
      align: 'center',
    },
    {
      id: 7,
      field: 'comment',
      headerName: t('COMMENT'),
      flex: 1,
      align: 'center',
    },
  ];

export const holdingLimitCheckTypes = {
  COMPLETED_TRANSFER_HOLDING_DETAILS: 'COMPLETED_TRANSFER_HOLDING_DETAILS',
  PERIODIC_HOLDING_DETAILS: 'PERIODIC_HOLDING_DETAILS',
  TRANSFER_WARNINGS_DETAILS: 'TRANSFER_WARNINGS_DETAILS',
};

export const customSorting = (a, b) => {
  if (a == null && b == null) {
    return 0;
  }
  if (a == null) {
    return 1;
  }
  if (b == null) {
    return -1;
  }
  const aValue = typeof a === 'string' ? BigInt(a.replace(/,/g, '')) : a;
  const bValue = typeof b === 'string' ? BigInt(b.replace(/,/g, '')) : b;

  return aValue - bValue < 0n ? -1 : 1;
};

export const customSortingFloat = (a, b) => {
  if (a == null && b == null) {
    return 0;
  }
  if (a == null) {
    return 1;
  }
  if (b == null) {
    return -1;
  }
  const aValue = parseFloat(a.replace(/,/g, ''));
  const bValue = parseFloat(b.replace(/,/g, ''));

  return aValue - bValue < 0n ? -1 : 1;
};

// Used when authority raises request revision
export const JURISDICTION_REQUEST_REVISIONS = 'Jurisdiction Request Revisions';
export const USER_DETAILS_CHANGE_CANCELLED_STATUS = 'User Details Change Cancelled';
export const USER_DETAILS_CHANGE_APPROVED_STATUS = 'User Details Change Approved';
export const USER_DETAILS_CHANGE_DENY_STATUS = 'User Details Change Denied';
export const RESET_EXPIRE_LOGIN_MESSAGE_DISPLAYED_KEY = 'isResetMessagedDisplayed';

export const validateUserIDPattern = (originalValue) => {
  const value = originalValue?.toString();
  if (emailImportant.test(value)) {
    return false;
  }
  if (value?.toString()?.includes(' ')) {
    return false;
  }
  if (atleastOnePattern.test(value)) {
    if (userIdPattern.test(value)) {
      return true;
    }
  }
  return false;
};

export const USER_ID_VALIDATION = {
  USER_ID_MAX_LENGTH: 15,
  USER_ID_MIN_LENGTH: 6,
};

export const PINNED_COLUMN_MAX_WIDTH = 300;
// used to control displaying expire pwd msg popup for one time
export const SET_EXPIRE_PASSWORD_IS_DISPLAYED_KEY = 'SET_EXPIRE_PASSWORD_IS_DISPLAYED_KEY';

export const SET_SELECTED_DEFAULT_ACC = 'selectedDefaultAccounts';

export const MAILING_ADD_CARD_ID = 'MAILING_ADD_CARD_ID';
export const RESIDENCE_ADD_CARD_ID = 'RESIDENCE_ADD_CARD_ID';
export const checkIfSamePrimaryAndMailingAddress = (userDetails) => {
  if ([COUNTRY_KEY_NAMES.CANADA, COUNTRY_KEY_NAMES.UNITED_STATES].includes(userDetails?.ResCountry)) {
    if (userDetails?.ResState !== userDetails?.MailState) {
      return false;
    }
  } else if (userDetails?.ResRegion !== userDetails?.MailRegion) {
    return false;
  }
  if (
    !(userDetails?.ResStreet2 && userDetails?.MailStreet2) &&
    !userDetails?.ResStreet2 !== !userDetails?.MailStreet2
  ) {
    return false;
  }
  if (userDetails?.ResStreet2 && userDetails?.MailStreet2 && userDetails?.ResStreet2 !== userDetails?.MailStreet2) {
    return false;
  }
  if (
    userDetails?.ResStreet1 === userDetails?.MailStreet1 &&
    userDetails?.ResCity === userDetails?.MailCity &&
    userDetails?.ResPinCode === userDetails?.MailPinCode &&
    userDetails?.ResCountry === userDetails?.MailCountry
  )
    return true;
  return false;
};

export const UnshareWithRoleUpdate = 'UnshareWithRoleUpdate';
export const ResetPassphraseStatus = 'Reset Passphrase';

export const ACTION_BY_SYSTEM = 'System';
export const ACTION_BY_JOB_EXECUTOR = 'Job Executor';

export const POLLING_INTERVAL = 60 * 1000; // 1 minutes in milliseconds

export const MCI_REQUEST_STATUS = {
  PROPOSE_VINTAGE_YEAR_ALLOWANCE_ISSUANCE: 'PROPOSE_VINTAGE_YEAR_ALLOWANCE_ISSUANCE',
  PROPOSE_EARLY_REDUCTION_CREDIT_SUBTYPE_ALLOWANCE_ISSUANCE:
    'PROPOSE_EARLY_REDUCTION_CREDIT_SUBTYPE_ALLOWANCE_ISSUANCE',
  PROPOSE_PCRA_SUBTYPE_ALLOWANCE_ISSUANCE: 'PROPOSE_PCRA_SUBTYPE_ALLOWANCE_ISSUANCE',
  PROPOSE_PRICE_CEILING_UNIT_SUBTYPE_ALLOWANCE_ISSUANCE: 'PROPOSE_PRICE_CEILING_UNIT_SUBTYPE_ALLOWANCE_ISSUANCE',
  PROPOSE_OFFSET_ISSUANCE: 'PROPOSE_OFFSET_ISSUANCE',
  OFFSET_ISSUANCE_COMPLETE: 'OFFSET_ISSUANCE_COMPLETE',
  ALLOWANCE_ISSUANCE_APPROVED: 'ALLOWANCE_ISSUANCE_APPROVED',
  ALLOWANCE_ISSUANCE_COMPLETE: 'ALLOWANCE_ISSUANCE_COMPLETE',
  VINTAGE_YEAR_ALLOWANCE_ISSUANCE_COMPLETE: 'VINTAGE_YEAR_ALLOWANCE_ISSUANCE_COMPLETE',
  EARLY_REDUCTION_CREDIT_SUBTYPE_ALLOWANCE_ISSUANCE_COMPLETE:
    'EARLY_REDUCTION_CREDIT_SUBTYPE_ALLOWANCE_ISSUANCE_COMPLETE',
  PCRA_SUBTYPE_ALLOWANCE_ISSUANCE_COMPLETE: 'PCRA_SUBTYPE_ALLOWANCE_ISSUANCE_COMPLETE',
  PRICE_CEILING_UNIT_SUBTYPE_ALLOWANCE_ISSUANCE_COMPLETE: 'PRICE_CEILING_UNIT_SUBTYPE_ALLOWANCE_ISSUANCE_COMPLETE',
  APPROVED: 'APPROVED',
  COMPLETE: 'COMPLETE',
  DECLINE: 'DECLINE',
  EXPIRED: 'EXPIRED',
  SAVED: 'SAVED',
};

export const LOCALE_EN_US = 'en-US';
export const EST_TIMEZONE = 'America/New_York';
export const ET_TIMEZONE_NASSAU = 'America/Nassau';
export const EDT_SHORT = 'EDT';

export const QUEBEC_JURISDICTION_ID = 'ORG#00102';
export const NOVA_SCOTIA_JURISDICTION_ID = 'ORG#00104';

export const SEARCH_TYPE = {
  BY_KEY: 'keyValue',
  BY_PAGE: 'page',
  BY_DISPLAYED_VALUE: 'displayedValue',
};

export const SEARCH_KEY_CATEGORY = {
  CATEGORY_REGULAR: 'regular',
  CATEGORY_ALL_PAGE_KEYS: 'allPageKeys',
  CATEGORY_ALL_PAGE_DISPLAYED_ICON_KEYS: 'iIcon',
  CATEGORY_CARD: 'card',
  CATEGORY_SUCCESS_TOAST: 'successToast',
  CATEGORY_ERROR_TOAST: 'errorToast',
  CATEGORY_TOAST: 'toasts',
  CATEGORY_OTHER: 'otherCards',
  CATEGORY_OTHER_ERROR_CARD: 'errorCard',
  CATEGORY_OTHER_STATUS: 'status',
  CATEGORY_DENIAL_REASON: 'denialReason',
};

export const JSA_ROLE = 'Jurisdiction System Admin';
export const CMS_JURI_COL_SUFFIX = 'juriValue_';
export const DEFAULT_ENGLISH = 'English';
export const DEFAULT_ORG_ID = 'ORG#00100';
export const getJurisdictionRowDataForTable = (orgId, jurisdictionArray, keyData) => {
  const temp = {};
  jurisdictionArray.forEach((jurisdiction) => {
    if (
      jurisdiction?.OrgID !== DEFAULT_ORG_ID &&
      jurisdiction?.OrgID !== orgId &&
      keyData[`juriValue_${jurisdiction?.OrgID}`]
    ) {
      temp[`${CMS_JURI_COL_SUFFIX}${jurisdiction?.OrgID}`] = keyData[`juriValue_${jurisdiction?.OrgID}`];
    }
  });
  return temp;
};

export const getDynamicJuriColumnNames = (orgId, jurisdictionArray) => {
  const temp = [];
  jurisdictionArray.forEach((jurisdiction) => {
    if (jurisdiction?.OrgID !== DEFAULT_ORG_ID && jurisdiction?.OrgID !== orgId) {
      temp.push({
        columnField: `${CMS_JURI_COL_SUFFIX}${jurisdiction?.OrgID}`,
        juriName: jurisdiction?.OrgName,
      });
    }
  });
  return temp;
};

export const getJurisdictionRowDataForCards = (orgId, jurisdictionArray, keyData) => {
  const temp = [];
  jurisdictionArray.forEach((jurisdiction) => {
    if (
      jurisdiction?.OrgID !== DEFAULT_ORG_ID &&
      jurisdiction?.OrgID !== orgId &&
      keyData[`juriValue_${jurisdiction?.OrgID}`]
    ) {
      temp.push({
        jurisdictionName: jurisdiction.OrgName,
        jurisdictionValue: keyData[`juriValue_${jurisdiction?.OrgID}`],
      });
    }
  });
  return temp;
};

export const DISPLAY_SECTION = {
  VALUES_SECTION: 'VALUES',
  PAGE_CONTENT_SECTION: 'PAGE_CONTENT',
  SEARCH_KEY_MIN_LENGTH: 3,
  SEARCH_KEY_MAX_LENGTH: 255,
  SEARCH_DISPLAYED_VALUE_MIN_LENGTH: 3,
  SEARCH_DISPLAYED_VALUE_MAX_LENGTH: 255,
  CARD_DATA_MAX_LENGTH: 8000,
  QUESTION_MIN_LENGTH: 3,
  QUESTION_MAX_LENGTH: 255,
  ANSWER_MIN_LENGTH: 3,
  ANSWER_CARD_DATA_MAX_LENGTH: 8000,
};

export const JURISDICTION_STATUS = {
  ONBOARDING: 'ONBOARDING',
  PRE_LINKED: 'PRE_LINKED',
  LINKED: 'LINKED',
  UNLINKED: 'UNLINKED',
  POST_LINKED: 'POST_LINKED',
  OFFBOARDED: 'OFFBOARDED',
};

export const JURISDICTION_STATUS_FE = {
  ONBOARDING: 'Onboarding',
  PRE_LINKED: 'Pre-Linked',
  LINKED: 'Linked',
  UNLINKED: 'Unlinked',
  POST_LINKED: 'Post-Linked',
  OFFBOARDED: 'Offboarded',
};

export const eventApplicationStatus = {
  APPROVE: 'APPROVED',
  REJECT: 'REJECTED',
  UNDO_REJECTION: 'UNDO_REJECTION',
};
export const eventTypeNames = {
  AUCTION: 'AUCTION',
  RESERVE_SALE: 'RESERVE_SALE',
  Auction: 'Auction',
};

export const DOWNLOAD_FORMAT = {
  CSV: 'CSV',
  EXCEL: 'Excel',
};

export const CARD_TYPE = {
  DEFAULT_CARD: 'default_card',
  SUCCESS_TOAST_CARD: 'success_toast',
  ERROR_TOAST_CARD: 'error_toast',
  OTHER_CARD: 'other_card',
  OTHER_ERROR_CARD: 'other_error_card',
  CONTACT_CARD: 'contact_card',
  FAQ_QUESTION_CARD: 'faq_question_card',
};

export const DOCUMENT_TYPE = {
  LEGAL: 'legal',
  FORMS: 'forms',
  RESOURCES: 'resources',
  TYPE_FILE: 'FILE',
  TYPE_URL: 'URL',
};

export const CARD_PARSE_ID = 'cms_card_parse_id';

export const DELETE_OTR_STATUS_MESSAGE = 'The OTR is already deleted';

export const THOUSAND_SEPARATOR_ARRAY = (t1) => [
  {
    name: t(t1, 'COMMA'),
    value: 'comma',
  },
  {
    name: t(t1, 'SPACE'),
    value: 'space',
  },
];

export const DECIMAL_SEPARATOR_ARRAY = (t1) => [
  {
    name: t(t1, 'COMMA'),
    value: 'comma',
  },
  {
    name: t(t1, 'DOT'),
    value: 'dot',
  },
];

export const CREATE_LANGUAGE_TYPE = {
  CLONE: 'clone',
  ADD: 'add',
};

export const DEFAULT_LANGUAGE_CODE = 'L001';

export const FAQ_CONTACT_INFO_PAGE = 'Page_ContactInformation';

export const FAQ_QUESTION_PAGE = 'Page_FAQS';

export const PAGE_FOR_PASSPHRASE = 'Page_PassphraseRules';

export const PAGE_FOR_STATUSES = 'Page_Statuses';

export const PAGE_FOR_ROLES = 'Page_Roles';

export const PAGE_FOR_ROLE_TYPES = 'Page_RoleTypes';

export const PAGE_FOR_COUNTRIES = 'Page_Countries';

export const PAGE_FOR_STATES = 'Page_States';

export const PAGE_FOR_JURISDICTIONS = 'Page_Jurisdictions';

export const PAGE_FOR_DENIAL_REASON = 'Page_JurisdictionUserActions';

export const PAGE_USER_REGISTRATION = 'Page_UserRegistration';

// this is used for cms compare payload
export const CMS_COMPARE_REPORT = 'CMS_COMPARE_REPORT';

export const DEFAULT_UNKNOWN_PAGE_ORG_ID = '00100';

export const applyDisabledStyles = (isDisabled) => ({
  ...(isDisabled
    ? {
      '.MuiOutlinedInput-root': {
        backgroundColor: 'rgba(170, 170, 170, 0.5)',
      },
      '.MuiOutlinedInput-Input': {
        backgroundColor: 'rgba(170, 170, 170, 0.5)',
      },
    }
    : {}),
});

export const allowedPriceRegex = /^[0-9]{0,13}(\.[0-9]{1,2})?$/;

export const allowedPriceRegexFr = /^[0-9]{0,13}(,[0-9]{1,2})?$/;

export const FSAAssignedTo = (assignedToData, userDetails) => {
  const userDetailsUID = userDetails?.UID?.split('#')[1];
  const filteredData = assignedToData?.filter((item) => item?.uId === userDetailsUID)[0];
  const hasFSAHomeRole = filteredData?.roles?.includes(FSAHome);
  return filteredData && hasFSAHomeRole && filteredData?.jurisdictionAssigned?.length > 0;
};

export const MMAssignedTo = (assignedToData, userDetails) => {
  const userDetailsUID = userDetails?.UID?.split('#')[1];
  const filteredData = assignedToData?.filter((item) => item?.uId === userDetailsUID)[0];
  const hasFSAHomeRole = filteredData?.roles?.includes(MarketMonitor);
  return filteredData && hasFSAHomeRole && filteredData?.jurisdictionAssigned?.length > 0;
};

export const removeBlankValues = (val) => {
  if (typeof val === 'string') {
    return val?.trim()?.length ? val : undefined;
  }
  return val;
};

export const convertPascalToSnakeCase = (inputString = '') => inputString
  .toString()
  .replace(/([a-z])([A-Z])/g, '$1_$2')
  .toUpperCase();

export const getCMSKeySnakeCase = (inputString = '') =>
  inputString.toString().trim().replaceAll(' ', '_').toUpperCase();

export const getCMSKey = (requestParamName, statusToAppend) => {
  if (statusToAppend)
    return `${requestParamName?.toString().replace(/([a-z])([A-Z])/g, '$1_$2')}_${statusToAppend
      ?.toString()
      .trim()
      .replaceAll(' ', '_')}`.toUpperCase();
  return '';
};

export const handleRepresentativeError = (error, dispatch, logger) => {
  const data = error?.response?.data;
  let errorMessage = '';

  if (data?.messageKey === 'REPRESENTATIVE_VALIDATIONS_FAILED') {
    if (data && data.data && data.data.length > 0) {
      data.data.forEach((errorObj) => {
        switch (errorObj.messageKey) {
          case 'PAR_MINIMUM_VALIDATION_FAILED':
            errorMessage += `PAR Minimum value has not reached. Min PAR required is ${errorObj.parCountMinimumSettings}\n`;
            break;
          case 'PAR_MAXIMUM_VALIDATION_FAILED':
            errorMessage += `PAR Maximum value reached. Max PAR allowed is ${errorObj.parCountMaximumSettings}\n`;
            break;
          case 'AAR_MINIMUM_VALIDATION_FAILED':
            errorMessage += `AAR Minimum value has not reached. Min AAR required is ${errorObj.aarCountMinimumSettings}\n`;
            break;
          case 'AAR_MAXIMUM_VALIDATION_FAILED':
            errorMessage += `AAR Maximum value reached. Max AAR allowed is ${errorObj.aarCountMaximumSettings}\n`;
            break;
          case 'AVA_MINIMUM_VALIDATION_FAILED':
            errorMessage += `AVA Minimum value has not reached. Min AVA required is ${errorObj.avaCountMinimumSettings}\n`;
            break;
          case 'AVA_MAXIMUM_VALIDATION_FAILED':
            errorMessage += `AVA Maximum value reached. Max AVA allowed is ${errorObj.avaCountMaximumSettings}\n`;
            break;
          default:
            errorMessage += 'Unknown error\n';
            break;
        }
      });
    }
  } else if (data?.data === 'REPRESENTATIVE_STATUS_IS_NOT_ACTIVE') {
    errorMessage = 'Representative Not Active';
  }

  const datas = {
    title: 'ERROR!',
    message1: errorMessage,
    error: errorMessage !== '',
    isDefaultDisplay: false,
  };

  if (!datas.error) {
    datas.title = 'SUCCESS';
    datas.message1 = 'Representative Added/Modified Successfully';
  }

  dispatch(setModelMessage(datas));
  logger({ error, name: 'save/updateRepresentative' });
};

// Array to populate the security questions array
export const createQuestionsArray = (t1) => {
  const queestionsArray = [];
  Array.from({ length: 10 }, (_, i) => {
    const question = {
      title: t(t1, `SECURITY_QUESTION_${i + 1}`),
      value: t(t1, `SECURITY_QUESTION_${i + 1}`),
      key: `SECURITY_QUESTION_${i + 1}`,
    };
    queestionsArray.push(question);
    return null;
  });
  return queestionsArray;
};

export const securityQuestionToKey = (name, t1) =>
  createQuestionsArray(t1).find((question) => question?.value === name)?.key || '';


export const countrySort = (t1, countryList = []) => {
  const restSortedCountries = countryList.slice(2).sort((prev, next) => {
    if (prev.Name < next.Name) {
      return -1;
    }
    if (prev.Name > next.Name) {
      return 1;
    }
    return 0;
  });

  const initialCountryList = countryList.slice(0, 2);

  return [...initialCountryList, ...restSortedCountries].map((country) => ({
    name: t(t1, country.Name),
    value: country.ID,
  }));
};

const getInnerContent = (htmlString) => {
  const tempDiv = document.createElement('div');
  // Set the HTML content of the div to your string
  tempDiv.innerHTML = htmlString;
  // Find the <a> tag with the data-testid attribute
  const anchorElement = tempDiv.querySelector('a[data-btn-click="click-event"]');
  // Check if such element exists
  if (anchorElement) {
    // Get the inner content of the <a> tag
    const innerContent = anchorElement.innerHTML;
    console.log('getInnerContent indBtwTag result ', innerContent); // This will give you the inner content of the <a> tag with data-testid="1"
    // const stringContent = JSON.stringify(innerContent);
    // console.log("Stringified content:", stringContent);
    return innerContent;
  }
  console.log('getInnerContent indBtwTag result No match found.');
  return '';
};

export const addClickInHtmlTag = (linksText, handleClick) =>
  HTMLReactParser(linksText, {
    replace: (domNode) => {
      if (domNode.name === 'a' && domNode.attribs['data-btn-click']) {
        console.log('addClickInHtmlTag inside domenote=', domNode, ' string=');
        return (
          <a
            key="click-div"
            onKeyDown={() => {
            }}
            tabIndex={0}
            role="button"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={handleClick}
          >
            {HTMLReactParser(getInnerContent(linksText))}
          </a>
        );
      }
      return domNode;
    },
  });

// This function will unzip response data and return string parsed response
export function unzip(base64str) {
  const strData = atob(base64str);

  // Convert binary string to character-number array
  const charData = strData.split('').map((x) => x.charCodeAt(0));

  // Turn number array into byte-array
  const binData = new Uint8Array(charData);

  return JSON.parse(pako.inflate(binData, { to: 'string' }));
}

// dayjs
export function isAfterDate(d1, d2) {
  const date1 = dayjs(d1);
  const date2 = dayjs(d2);
  return date1.isAfter(date2);
}

export function isDateAfterDay(d1, d2) {
  const date1 = dayjs(d1);
  const date2 = dayjs(d2);
  return date1.isAfter(date2, 'd');
}

export function isBeforeDate(d1, d2) {
  const date1 = dayjs(d1);
  const date2 = dayjs(d2);
  return date1.isBefore(date2);
}

export function isDateBeforeDay(d1, d2) {
  const date1 = dayjs(d1);
  const date2 = dayjs(d2);
  return date1.isBefore(date2, 'd');
}

export function isValidDate(date) {
  const isValid = !date || date === 'Invalid Date' ? false : dayjs(date).isValid();
  return isValid;
}

function getNumFmtMUIGrid(value, lang) {
  let numericValue = value;
  let decimalExists = value.includes('.');

  // convert value back to original value without formatting
  if (numericValue && numericValue.length) {
    if (lang === 'French') {
      numericValue = numericValue.replace(/\s/g, '');
      if (numericValue.length >= 3 && numericValue[numericValue.length - 3] === ',') {
        decimalExists = true;
        numericValue = `${numericValue.substring(0, numericValue.length - 3)}.${numericValue.substring(
          numericValue.length - 2,
          numericValue.length,
        )}`;
      }
    }
    numericValue = numericValue.replace(/,/g, '');
    numericValue = numericValue.slice(0, 15);
  }
  const numFormat = decimalExists ? '#,##0.00' : '#,##0';
  return [numericValue, numFormat];
}

// exelJS Post data write process
export function excelPostProcess(workbook, worksheet, apiRef, lang) {
  // this will remove options sheet to support dropdown feature
  workbook.removeWorksheet(2);

  function getCellType(value, col) {
    const allColumns = apiRef.current.getAllColumns();
    return allColumns.find((obj) => obj.field === value)?.[col];
  }

  // this will remove dropdown feature from excel
  worksheet._columns?.forEach((element) => {
    if (getCellType(element._key, 'type') === 'singleSelect') {
      worksheet.eachRow({ includeEmpty: false }, (row) => {
        row.getCell(element._number).dataValidation = {
          type: 'textLength',
          operator: 'greaterThanOrEqual',
          formula1: 0,  // Allows any text
        };
      });
    }
    // this will transform string, bigint to number format for excel
    if (getCellType(element._key, 'stringAsNumber')) {
      worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
        const cellValue = row.getCell(element._number).value;
        if (typeof cellValue === 'string' && rowNumber !== 1 && cellValue !== '') {
          const value = String(cellValue);
          const [numericValue, numFormat] = getNumFmtMUIGrid(value, lang);
          if (numericValue !== '') {
            try {
              row.getCell(element._number).value = Number(numericValue);
              row.getCell(element._number).numFmt = numFormat;
            } catch (error) {
              console.error('Error occurred while transforming to number:', error);
            }
          }
        }
      });
    }
  });
}

// This function parses string with <a> and replace with icon for submit document page
export const displayParsedIconWithLink = (linksText, handleClick) => {
  const svgIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" cursor="pointer" fill="#283459"><path d="m12 17 4-4-1.4-1.4-1.6 1.55V9h-2v4.15L9.4 11.6 8 13Zm-6 5q-.825 0-1.412-.587Q4 20.825 4 20V8l6-6h8q.825 0 1.413.587Q20 3.175 20 4v16q0 .825-.587 1.413Q18.825 22 18 22Z" /></svg>';
  return HTMLReactParser(linksText, {
    replace: (domNode) => {
      if (domNode.name === 'a' && domNode.attribs['data-btn-click']) {
        return (
          <a
            key="click-div"
            onKeyDown={() => {
            }}
            tabIndex={0}
            role="button"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={handleClick}
          >
            {HTMLReactParser(svgIcon)}
          </a>
        );
        // eslint-disable-next-line
      } else if (domNode.name === 'a' && domNode.attribs?.href) {
        return (
          <a
            onKeyDown={() => {
            }}
            tabIndex={0}
            role="button"
            rel="noreferrer"
            target="_blank"
            href={domNode.attribs?.href}
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            {HTMLReactParser(svgIcon)}
          </a>
        );
      }
      return domNode;
    },
  });
};

// This function parses string with <a> and replace with icon for submit document page for preview
export const displayParsedIconWithLinkForPreview = (linksText) => {
  const svgIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" cursor="pointer" fill="#283459"><path d="m12 17 4-4-1.4-1.4-1.6 1.55V9h-2v4.15L9.4 11.6 8 13Zm-6 5q-.825 0-1.412-.587Q4 20.825 4 20V8l6-6h8q.825 0 1.413.587Q20 3.175 20 4v16q0 .825-.587 1.413Q18.825 22 18 22Z" /></svg>';
  return HTMLReactParser(linksText, {
    replace: (domNode) => {
      if (domNode.name === 'a' && domNode.attribs['data-btn-click']) {
        return (
          <a
            key="click-div"
            onKeyDown={() => {
            }}
            tabIndex={0}
            role="button"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            {HTMLReactParser(svgIcon)}
          </a>
        );
        // eslint-disable-next-line
      } else if (domNode.name === 'a' && domNode.attribs?.href) {
        return (
          <a
            onKeyDown={() => {
            }}
            tabIndex={0}
            role="button"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            {HTMLReactParser(svgIcon)}
          </a>
        );
      }
      return domNode;
    },
  });
};

export const COMPONENT_NAME_EVENT = {
  EVENT_CREATION: 'eventCreation',
  EVENT_LIST: 'eventList',
  EDIT_EVENT: 'editEvent',
  EVENT_CREATION_AUCTION: 'eventCreation_Auction',
  VIEW_EVENT_AUCTION: 'viewEvent_Auction',
  EDIT_EVENT_AUCTION: 'editEvent_Auction',
  EVENT_CANCELLATION: 'eventCancellation',
  EVENT_CREATION_RES_SALE: 'eventCreation_ReserveSale',
  VIEW_EVENT_RES_SALE: 'viewEvent_ReserveSale',
  EDIT_EVENT_RES_SALE: 'editEvent_ReserveSale',
};

export const COMPONENT_NAME_EVENT_APPLICATION = {
  EVENT_APPLICATION_CREATION: 'createEventApplication',
  EVENT_APPLICATION_UPDATE: 'updateEventApplication',
  EVENT_APPLICATION_VIEW: 'viewEventApplication',
  EVENT_APPLICATION_CANCELLATION: 'eventCancellation',
};

export const FSA_COMPONENT_NAMES = {
  FSA_CREATE_UPLOAD_FILE: 'createUploadCashFilesRecords',
};

export const FSI_COMPONENT_NAMES = {
  FSI_UPDATE_DETAILS: 'updateMFSDetails',
  FSI_VIEW_DETAILS: 'viewMFSDetails',
  CREATE_REPORT_RECORDS: 'createReportRecords',
  CREATE_EVENT_APPLICATION_REPORT_RECORDS: 'createEventApplicationReportRecords',
  REPORT: 'report',
};

export const excludePreviewForPages = [
  'Page_NAICSCodeDisplayManagement',
  'Page_ExchangeContractCodeDescCode',
  'Page_CommonLabels',
  'Page_DocFiles',
  'Page_FormsDocuments',
  'Page_LegalDocuments',
  'Page_MegaMenu',
  'Page_SecurityQuestions',
  'Page_XML_REPORT',
  'Page_commonLabels',
  'Page_reset-password',
  'Page_JurisdictionAccountDetails',
  'Page_EntityTransfer',
  'Page_DATA_EXPORT_REPORT',
];

export function getLanguageBasedSeparators() {
  const jurisdictionLanguages = JSON.parse(localStorage.getItem('jurisdictionLanguages'));
  const languageCode = localStorage.getItem('languageCode');
  if (jurisdictionLanguages?.length && languageCode) {
    const selectedLanguage = jurisdictionLanguages.find((language) => language?.languageCode === languageCode);
    const { thousandSeparator, decimalSeparator } = selectedLanguage;
    return { thousandSeparator, decimalSeparator };
  }
  return { thousandSeparator: 'comma', decimalSeparator: 'dot' };
}

export const SCANNED_FILE_STATUS = {
  CLEAN: 'clean',
  INFECTED: 'infected',
};

export const BUCKET_KEY_PREFIX = 'wci-reg-antivirus-uploadfiles';

// Map the separator strings to their actual characters
export const separatorsMap = {
  comma: ',',
  space: ' ',
  dot: '.',
};

export function formatNumberString(numberStr) {
  const { decimalSeparator, thousandSeparator } = getLanguageBasedSeparators();
  const decimalChar = separatorsMap[decimalSeparator];
  const thousandChar = separatorsMap[thousandSeparator];

  // Split the number into integer and decimal parts
  const [integerPart, decimalPart] = numberStr.split('.');

  // Format the integer part with thousand separators
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandChar);

  // Combine the integer part and the decimal part with the specified decimal separator
  const formattedNumber = decimalPart ? `${formattedIntegerPart}${decimalChar}${decimalPart}` : formattedIntegerPart;

  return formattedNumber;
}

export function handleEntityStatusError(error, id, dispatch, navigate) {
  if (error?.response?.status === 412) {
    const messageKey = error.response?.data?.messageKey;

    if (messageKey === 'ERR_ENTITY_STATUS_UPDATED_TO_CANCELLED') {
      const toastMessage = {
        title: 'COMMON_ERROR',
        message1: 'ERR_ENTITY_STATUS_UPDATED_TO_CANCELLED',
        error: true,
      };
      dispatch(setModelMessage(toastMessage));
      if (navigate) navigate('/home');
      else window.location.href = '/home';
      return true;
    }
    if (messageKey === 'ERR_ENTITY_STATUS_UPDATED_TO_PENDING') {
      const toastMessage = {
        title: 'COMMON_ERROR',
        message1: 'ERR_ENTITY_STATUS_UPDATED_TO_PENDING',
        error: true,
      };
      dispatch(setModelMessage(toastMessage));
      if (navigate) navigate(`/entity-profile/${id}`);
      else window.location.href = `/entity-profile/${id}`;
      return true;
    }
  }
  return false;
};

export const SELECTED_LANGUAGE = {
  ENGLISH: 'English',
};

export function getLanguageNameByLanguageCode(languageCode) {
  const jurisdictionLanguages = JSON.parse(localStorage.getItem('jurisdictionLanguages'));
  if (jurisdictionLanguages?.length && languageCode) {
    const selectedLanguage = jurisdictionLanguages.find((language) => language?.languageCode === languageCode);
    return selectedLanguage?.name ?? SELECTED_LANGUAGE.ENGLISH;
  }
  return SELECTED_LANGUAGE.ENGLISH;
}