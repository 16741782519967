import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { GridSelectSearch, GridTextfield } from 'components';
import { Loader } from 'components/Loader';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from 'store/createUserProfileSlice';
import { useEffect } from 'react';
import {
  cityOrRegionNamePattern,
  COUNTRY_KEY_NAMES,
  CREATE_USER_PROFILE_FORM_FIELDS,
  getCurrentPageName,
  isEmpty,
  LOCALIZED_STATE_NAMES,
  street1Pattern,
  t,
  validatePattern,
  validatePostalCode,
} from 'utils/constants';

export default function ResidenceForm({ address, stepNumber, methods, countries, states, privId, disabled = false }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { loader } = useSelector(({ common }) => common);
  const {
    formSteps: { activeStep, isEditField },
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { initialPrimaryChecked } = useSelector(({ createUserProfile }) => createUserProfile);

  const dispatch = useDispatch();
  const stateDataUS = [{ name: '', value: '' }];
  const stateDataCA = [{ name: '', value: '' }];
  const countryData = [{ name: '', value: '' }];
  if (states?.[0]?.length > 0) {
    states[0]?.map((item) => {
      stateDataUS.push({ name: t(t1, item.name), value: item.name });
      LOCALIZED_STATE_NAMES[item.name] = item.name;
      return stateDataUS;
    });
  }
  if (states?.[1]?.length > 0) {
    states[1]?.map((item) => {
      stateDataCA.push({ name: t(t1, item.name), value: item.name });
      LOCALIZED_STATE_NAMES[item.name] = item.name;
      return stateDataCA;
    });
  }
  if (countries?.length > 0) {

    countries?.map((item) => {
      countryData.push({ name: t(t1, item.name), value: item.name });
      COUNTRY_KEY_NAMES[item.name] = item.name;
      return countryData;
    });
  }

  const { watch } = methods;
  const watchShowCountry = watch(`${address}.country`, false);
  /* Conflict Resolution Candidate
    const watchShowPrimaryCountry = watch(`primaryResidence.country`, false);
   */
  const watchShowState = watch(`${address}.stateOrProvince`, false);
  const watchShowStreet1 = watch(`${address}.street1`, false);
  const watchShowStreet2 = watch(`${address}.street2`, false);
  const watchShowCity = watch(`${address}.city`, false);
  const watchShowPostalCode = watch(`${address}.postalCode`, false);
  const watchShowRegion = watch(`${address}.region`, false);

  useEffect(() => {
    if (initialPrimaryChecked) {
      methods.trigger('mailingAddress.country');
      methods.setValue(`mailingAddress.region`, methods.getValues(`primaryResidence.region`), {
        shouldDirty: true,
        shouldValidate: true,
      });
      methods.setValue('mailingAddress.country', watchShowCountry);
      methods.trigger(`primaryResidence.region`);
    }
    if (watchShowCountry) {
      if (
        methods.getValues(`${address}.country`) === COUNTRY_KEY_NAMES.CANADA ||
        methods.getValues(`${address}.country`) === COUNTRY_KEY_NAMES.UNITED_STATES
      ) {
        dispatch(getCountries(watchShowCountry));
        methods?.clearErrors(`${address}.region`);
        methods.trigger(`mailingAddress.stateOrProvince`);
        methods.trigger(`primaryResidence.stateOrProvince`);
      } else {
        methods.resetField(`${address}.stateOrProvince`);
      }
    }
    if (!initialPrimaryChecked) {
      // methods.resetField(`${address}.region`);
      methods.resetField(`${address}.region`);
      methods.resetField(`${address}.stateOrProvince`);
    }
  }, [methods.getValues(`${address}.country`)]);

  useEffect(() => {
    if (activeStep === 3 || (activeStep === 4 && isEditField?.[stepNumber])) {
      methods.setValue(`${address}.region`, '');
      if (!isEmpty(methods.getValues(`${address}.country`))) {
        methods.trigger(`${address}.region`);
      }
    } else {
      methods.resetField(`${address}.region`);
    }
    if (initialPrimaryChecked) {
      // console.log(methods.getValues(`primaryResidence.region`),'test');
      methods.setValue(`mailingAddress.region`, methods.getValues(`primaryResidence.region`));
    }
  }, [methods.getValues(`${address}.country`)]);

  useEffect(() => {
    if (initialPrimaryChecked) {
      methods.trigger('mailingAddress.country');
      methods.setValue('mailingAddress.country', methods.getValues(`primaryResidence.country`));
    }
    if (activeStep === 3 || (activeStep === 4 && isEditField?.[stepNumber])) {
      methods.setValue(`${address}.stateOrProvince`, '', {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      methods.resetField(`${address}.stateOrProvince`);
    }
  }, [methods.getValues(`${address}.country`)]);

  useEffect(() => {
    if (initialPrimaryChecked) {
      methods.setValue('mailingAddress.stateOrProvince', methods.getValues(`primaryResidence.stateOrProvince`));
      // if (isEmpty(methods.getValues(`primaryResidence.stateOrProvince`))) {
      //   methods.resetField(`mailingAddress.stateOrProvince`);
      // }
      methods.trigger('mailingAddress.stateOrProvince');
      methods.trigger('primaryResidence.stateOrProvince');
    }
  }, [methods.getValues(`${address}.stateOrProvince`)]);

  useEffect(() => {
    if (initialPrimaryChecked) {
      methods.setValue('mailingAddress.street1', methods.getValues(`${address}.street1`));
      methods.trigger('mailingAddress.street1');
      methods.trigger('primaryResidence.street1');
    }
  }, [methods.getValues(`${address}.street1`)]);

  useEffect(() => {
    if (initialPrimaryChecked) {
      methods.setValue('mailingAddress.street2', methods.getValues(`${address}.street2`));
      methods.trigger('mailingAddress.street2');
      methods.trigger('primaryResidence.street2');
    }
  }, [methods.getValues(`${address}.street2`)]);

  useEffect(() => {
    if (initialPrimaryChecked) {
      methods.setValue('mailingAddress.city', methods.getValues(`${address}.city`));
      methods.trigger('mailingAddress.city');
      methods.trigger('primaryResidence.city');
    }
  }, [methods.getValues(`${address}.city`)]);

  useEffect(() => {
    if (initialPrimaryChecked) {
      methods.setValue('mailingAddress.postalCode', methods.getValues(`${address}.postalCode`));
      methods.trigger('mailingAddress.postalCode');
      methods.trigger('primaryResidence.postalCode');
    }
  }, [methods.getValues(`${address}.postalCode`)]);

  useEffect(() => {
    if (initialPrimaryChecked) {
      methods.setValue('mailingAddress.region', methods.getValues(`${address}.region`));
      methods.trigger('mailingAddress.region');
      methods.trigger('primaryResidence.region');
    }
  }, [methods.getValues(`${address}.region`)]);

  /* Conflict Resolution Candidate
    const stateOrProvinceIsRequiredPrimary = useMemo(
      () =>
        stateOrProvinceRequired.includes(filledData?.[1]?.jurisdiction?.OrgID) &&
        (methods.getValues("primaryResidence.country") === "Canada" ||
          methods.getValues("primaryResidence.country") === "United States"),
      [methods.getValues("primaryResidence.country")]
    );
  
    const stateOrProvinceIsRequiredMailing = useMemo(
      () =>
        stateOrProvinceRequired.includes(filledData?.[1]?.jurisdiction?.OrgID) &&
        (methods.getValues("mailingAddress.country") === "Canada" ||
          methods.getValues("mailingAddress.country") === "United States"),
      [methods.getValues("mailingAddress.country")]
    );
  
    // useEffect(() => {
    //   if(!primaryChecked){
    //     methods.resetField(`mailingAddress.region`,filledData?.[3]?.mailingAddress?.region);
    //     methods.resetField(`mailingAddress.stateOrProvince`,filledData?.[3]?.mailingAddress?.stateOrProvince);
    //     if(!isEmpty(methods.getValues(`mailingAddress.country`))){
    //       methods.trigger(`mailingAddress.region`)
    //       methods.trigger(`mailingAddress.stateOrProvince`)
    //     }
    //   }
    // },[primaryChecked])
  
   */

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
      <Grid container spacing={2.5}>
        <GridTextfield
          disabled={disabled}
          name={`${address}.street1`}
          privId={`priv_txt_${privId}_Street1`}
          label={t(t1, 'STREET_1')}
          labelStyle={{ height: '100%' }}
          rules={{
            required: true,
            validate: (v) =>
              methods?.getValues(`${address}.street1`)
                ? validatePattern(v, `${t(t1, 'STREET_1')}`, t1, street1Pattern, `${t(t1, 'ERR_INVALID_STREET_1')}`)
                : null,
            maxLength: {
              value: CREATE_USER_PROFILE_FORM_FIELDS.STREET1_MAX_CHAR,
              message: `${t(t1, 'ERR_STREET_1_MAX_LENGTH_CHAR')}`,
            },
          }}
          isRequiredMsg={'ERR_STREET1_IS_REQUIRED'}
          InputProps={{
            'aria-required': 'true',
          }}
          stepNumber={stepNumber}
        />
        <GridTextfield
          disabled={disabled}
          name={`${address}.street2`}
          privId={`priv_txt_${privId}_Street2`}
          labelStyle={{ height: '100%' }}
          rules={{
            validate: (v) =>
              methods?.getValues(`${address}.street2`)
                ? validatePattern(v, `${t(t1, 'STREET_2')}`, t1, street1Pattern, `${t(t1, 'ERR_INVALID_STREET_2')}`)
                : null,
            maxLength: {
              value: CREATE_USER_PROFILE_FORM_FIELDS.STREET2_MAX_CHAR,
              message: `${t(t1, 'ERR_STREET_2_MAX_LENGTH_CHAR')}`,
            },
          }}
          label={t(t1, 'STREET_2')}
          stepNumber={stepNumber}
        />
        <GridTextfield
          disabled={disabled}
          name={`${address}.city`}
          privId={`priv_txt_${privId}_City`}
          label={t(t1, 'CITY')}
          labelStyle={{ height: '100%' }}
          rules={{
            required: true,
            validate: (v) =>
              methods?.getValues(`${address}.city`)
                ? validatePattern(v, `${t(t1, 'CITY')}`, t1, cityOrRegionNamePattern, `${t(t1, 'ERR_INVALID_CITY')}`)
                : null,

            maxLength: {
              value: CREATE_USER_PROFILE_FORM_FIELDS.CITY_MAX_CHAR,
              message: `${t(t1, 'ERR_CITY_MAX_LENGTH_CHAR')}`,
            },
          }}
          isRequiredMsg={'ERR_CITY_IS_REQUIRED'}
          InputProps={{
            'aria-required': 'true',
          }}
          stepNumber={stepNumber}
        />
        <Grid item md={4} sx={{ pt: { md: '20px !important', lg: '26px !important' } }}>
          <GridSelectSearch
            disabled={disabled}
            className="fc-select-rounded"
            name={`${address}.country`}
            privId={`priv_txt_${privId}_Country`}
            noValueText={t(t1, 'SELECT_COUNTRY')}
            label={t(t1, 'COUNTRY')}
            options={countryData}
            rules={{ required: true }}
            InputProps={{
              'aria-required': 'true',
            }}
            isRequiredMsg={'ERR_COUNTRY_IS_REQUIRED'}
            stepNumber={stepNumber}
            address={address}
            gridMd={12}
          />
        </Grid>
        {methods.getValues(`${address}.country`) === COUNTRY_KEY_NAMES.CANADA ||
        methods.getValues(`${address}.country`) === COUNTRY_KEY_NAMES.UNITED_STATES ? (
          <Grid item md={4} sx={{ pt: { md: '20px !important', lg: '26px !important' } }}>
            <GridSelectSearch
              disabled={disabled}
              className="fc-select-rounded"
              name={`${address}.stateOrProvince`}
              privId={`priv_txt_${privId}_State`}
              noValueText={t(t1, 'SELECT_STATE_OR_PROVINCE')}
              label={t(t1, 'STATE_OR_PROVINCE')}
              options={methods.getValues(`${address}.country`) === COUNTRY_KEY_NAMES.CANADA ? stateDataCA : stateDataUS}
              stepNumber={stepNumber}
              rules={{
                required: true,
              }}
              isRequiredMsg={'ERR_STATE_OR_PROVINCE_IS_REQUIRED'}
              InputProps={{
                'aria-required': 'true',
              }}
              address={address}
              gridMd={12}
            />
          </Grid>
        ) : (
          <Grid item md={4} sx={{ pt: { md: '20px !important', lg: '26px !important' } }}>
            <GridTextfield
              disabled={disabled}
              name={`${address}.region`}
              privId={`priv_txt_${privId}_Region`}
              label={t(t1, 'REGION')}
              labelStyle={{ height: 'auto' }}
              subLabel={t(t1, 'IF_NOT_IN_THE_U_S_OR_CANADA')}
              stepNumber={stepNumber}
              rules={{
                required: true,
                validate: (v) =>
                  methods?.getValues(`${address}.region`)
                    ? validatePattern(v, `${t(t1, 'REGION')}`, t1, cityOrRegionNamePattern, `${t(t1, 'ERR_INVALID_REGION')}`)
                    : null,
                maxLength: {
                  value: CREATE_USER_PROFILE_FORM_FIELDS.REGION_MAX_CHAR,
                  message: `${t(t1, 'ERR_REGION_MAX_LENGTH_CHAR')}`,
                },
              }}
              isRequiredMsg={'ERR_REGION_IS_REQUIRED'}
              InputProps={{
                'aria-required': 'true',
              }}
              gridMd={12}
            />
          </Grid>
        )}
        {loader && <Loader loader={loader} />}
        <Grid item md={4} sx={{ pt: { md: '20px !important', lg: '26px !important' } }}>
          <GridTextfield
            disabled={disabled}
            name={`${address}.postalCode`}
            privId={`priv_txt_${privId}_PostalCode`}
            label={t(t1, 'POSTAL_CODE')}
            labelStyle={{ height: 'auto' }}
            rules={{
              required: true,
              validate: (v) =>
                methods?.getValues(`${address}.postalCode`)
                  ? validatePostalCode(v, `${t(t1, 'POSTAL_CODE')}`, t1)
                  : null,
              minLength: {
                value: CREATE_USER_PROFILE_FORM_FIELDS.POSTAL_CODE_MIN_CHAR,
                message: `${t(t1, 'ERR_POSTAL_CODE_MIN_LENGTH_CHAR')}`,
              },
              maxLength: {
                value: CREATE_USER_PROFILE_FORM_FIELDS.POSTAL_CODE_MAX_CHAR,
                message: `${t(t1, 'ERR_POSTAL_CODE_MAX_LENGTH_CHAR')}`,
              },
            }}
            isRequiredMsg={'ERR_POSTAL_CODE_IS_REQUIRED'}
            InputProps={{
              'aria-required': 'true',
            }}
            stepNumber={stepNumber}
            gridMd={12}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
