export const FieldLabel = {
  dark: '#ba000d',
  fontSize: '0.875rem',
  color: 'common.textColor',
  fontWeight: 700,
  mb: 0.5,
};

export const LinkStyle = {
  textDecorationColor: 'inherit',
  '&:hover,&:active,&:focus': {
    fontWeight: 600,
    outline: 'none',
  },
};

export const ButtonSmallStyle = {
  padding: 0,
  minWidth: '80px',
  lineHeight: 1,
  minHeight: '30px',
};

export const RichTextWithScrollStyle = {
  maxHeight: 'calc(0.875rem * 1.43 * 5)',
  overflowY: 'auto',
  marginBottom: 3.5,
  marginTop: 1.5,
  '&>p': {
    margin: 0,
  },
};