import React from 'react';

export default function Visibility({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill={color}>
      <path
        d="M10 13.354q1.583 0 2.688-1.104 1.104-1.104 1.104-2.688 0-1.583-1.104-2.687Q11.583 5.771 10 5.771q-1.583 0-2.688 1.104-1.104 1.104-1.104 2.687 0 1.584 1.104 2.688Q8.417 13.354 10 13.354Zm0-1.542q-.938 0-1.594-.656-.656-.656-.656-1.594 0-.937.656-1.593.656-.657 1.594-.657.938 0 1.594.657.656.656.656 1.593 0 .938-.656 1.594-.656.656-1.594.656Zm0 4.021q-3.062 0-5.552-1.708-2.49-1.708-3.615-4.563 1.125-2.854 3.625-4.541Q6.958 3.333 10 3.333t5.542 1.688q2.5 1.687 3.625 4.541-1.125 2.855-3.615 4.563-2.49 1.708-5.552 1.708Zm0-6.271Zm0 4.521q2.333 0 4.281-1.218 1.948-1.219 2.969-3.303-1.021-2.083-2.969-3.281T10 5.083q-2.333 0-4.292 1.198Q3.75 7.479 2.729 9.562q1.021 2.084 2.979 3.303Q7.667 14.083 10 14.083Z" />
    </svg>
  );
}
