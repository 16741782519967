import { Box, Grid, List, Typography } from '@mui/material';
import { MUITableGrid } from 'components/MUITableGrid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BATCH_TRANSFER_TYPES, getCurrentPageName, t, getFilterForColumn } from 'utils/constants';
import {
  addReceivingAccountToHolding,
  getBatchHoldings,
  getBatchTransferAccountDetails,
  getBatchandAccountDetailsById,
  setOpenAddTransferModel,
  setFileUploadErrors,
} from 'store/batchTransferSlice';
import { useForm } from 'react-hook-form';
import { CustomizeButton } from 'components/CustomizeButton';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { StyledErrorListItems } from 'pages/AddBudgetRecord/AddBudgetRecord.style';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getErrorMessage,
  receivingAccountPCRAValidationSchema,
  receivingAccountValidationSchema,
} from 'pages/ManageBatchTransfer/constants';
import { useParams } from 'react-router-dom';
import { setModelMessage } from 'store/commonSlice';
import _ from 'lodash';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import { HoldingSchema, ReceivingAccountSchema, receivingAccountData } from './columnSchema';
import { ReceivingAccountPopup } from '../ReceivingAccountPopup';

function AddReceivingAccount() {
  const {
    openAddTransferModel,
    selectedHolding,
    currencyList,
    accountDetailPCRA,
    initiateTransferDetails: { transferType },
  } = useSelector(({ batchTransfer }) => batchTransfer);
  const [removeAccountsToggle, setRemoveAccountsToggle] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [validationErrors, setValidationErros] = useState([]);
  const params = useParams();
  const { t: t1 } = useTranslation(getCurrentPageName());
  const apiRef = useGridApiRef();


  const dispatch = useDispatch();

  const valueOptions = useMemo(
    () => getFilterForColumn(selectedHolding, t, t1, ['type', 'subType']),
    [selectedHolding, t1],
  );
  const holdingsColumns = useMemo(() => [...HoldingSchema(t1, valueOptions)], [t1, valueOptions]);
  const { selectedLanguage } = useSelector(({ jurisdiction }) => jurisdiction);

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      receivingAccountDetails: [receivingAccountData],
    },
    resolver: yupResolver(transferType?.name === BATCH_TRANSFER_TYPES.BATCH_TRANSFER ? receivingAccountValidationSchema(t1, selectedLanguage) : receivingAccountPCRAValidationSchema(t1)),
  });

  const {
    reset,
    formState: { errors, isSubmitting },
    clearErrors,
    setValue,
    watch,
  } = methods;

  const { receivingAccountDetails } = watch();

  const receivingColumns = useMemo(() =>
      [...ReceivingAccountSchema(t1, currencyList, transferType?.name, selectedLanguage)],
    [t1, currencyList, transferType?.name, selectedLanguage, removeAccountsToggle],
  );


  const getInitialRowData = useCallback(() => {
    if (transferType?.name !== BATCH_TRANSFER_TYPES.JURISDICTION_BATCH_TRANSFER_PCRA) {
      return [receivingAccountData];
    }
    const receivingAccountPCRA = {
      id: 0,
      accountNumber: accountDetailPCRA?.account_number,
      proposedQuantity: '',
    };
    return [receivingAccountPCRA];
  }, [accountDetailPCRA?.account_number, transferType?.name]);

  const handleClose = useCallback(() => {
    if (removeAccountsToggle) {
      setRemoveAccountsToggle(false);
      setValidationErros([]);
    } else {
      dispatch(setOpenAddTransferModel(false));
      // setReceivingAccountRows(getInitialRowData);
      reset({ receivingAccountDetails: getInitialRowData() });
      setValidationErros([]);
    }
    setSelectedRows([]);
  }, [dispatch, getInitialRowData, removeAccountsToggle, reset]);

  const handleContinue = () => {
    if (removeAccountsToggle) {
      handleRemoveAccount();
    } else {
      // eslint-disable-next-line no-unused-vars
      const payload = receivingAccountDetails.map(({
                                                     id,
                                                     currency,
                                                     price,
                                                     entityReference,
                                                     accountNumber,
                                                     proposedQuantity,
                                                   }) => ({
        batchHoldingDetailsId: selectedHolding[0]?.batchTransferHoldingId,
        holdingId: selectedHolding[0]?.id,
        currencyJurisdictionDetailsId: currency || undefined,
        entityReferenceCode: entityReference || undefined,
        receivingAccountNumber: accountNumber || undefined,
        price: price ? Number(price.replace(',', '.')) : undefined,

        proposedQuantity,
      }));
      return dispatch(addReceivingAccountToHolding({ id: params?.id, transferTypeId: transferType?.id, payload }))
        .then(() => {
          dispatch(
            setModelMessage({
              title: t(t1, 'SUCCESS_TITLE'),
              message1: t(t1, 'ADD_RECEIVING_ACCOUNT_SUCCESS'),
            }),
          );
          dispatch(getBatchandAccountDetailsById(params.id));
          dispatch(getBatchHoldings(params.id));
          dispatch(getBatchTransferAccountDetails(params.id));
          dispatch(setFileUploadErrors([]));
          handleClose();
        })
        .catch((e) => {
          const errorList = getErrorMessage(e?.response?.data?.data, t1, accountDetailPCRA);
          setValidationErros(errorList);
        });
    }
  };

  const handleAddNewRows = useCallback(() => {
    const lastIdx = receivingAccountDetails.length - 1;
    setValue('receivingAccountDetails', [...receivingAccountDetails, { ...receivingAccountData, id: lastIdx + 1 }]);
  }, [receivingAccountDetails, setValue]);

  const handleRemoveAccount = () => {
    const selectedTransfers = apiRef.current.getSelectedRows();
    const filteredRows = receivingAccountDetails.filter((account) => !selectedTransfers.has(account.id));

    setValue(
      'receivingAccountDetails',
      filteredRows.length ? filteredRows.map((item, index) => ({ ...item, id: index })) : [receivingAccountData],
    );
    setRemoveAccountsToggle(false);
    clearErrors();
    setSelectedRows([]);
  };


  const onInvalid = () => {
    setValidationErros([]);
    if (removeAccountsToggle) {
      handleRemoveAccount();
    }
  };

  const errorList = useMemo(
    () =>
      ['proposedQuantity', 'price', 'currency'].map((key) => ({
        key,
        message: errors.receivingAccountDetails?.find((error) => error?.[key])?.[key]?.message,
      })),
    [errors],
  );

  useEffect(() => {
    reset({ receivingAccountDetails: getInitialRowData() });
  }, [accountDetailPCRA, getInitialRowData, reset, transferType.name]);

  const shouldContinueDisable =
    removeAccountsToggle ? !selectedRows.length || receivingAccountDetails.every((item) => _.isEqual(item, receivingAccountData)) :
      receivingAccountDetails.some((item) => !item.accountNumber || !item.proposedQuantity);

  return (
    <ReceivingAccountPopup
      maxWidth={'xl'}
      open={openAddTransferModel}
      handleClose={handleClose}
      methods={methods}
      handleContinue={handleContinue}
      handleCustomButtonClick={handleAddNewRows}
      customButtonLabel={t(t1, 'ADD_RECEIVING_ACCOUNT')}
      showCustomBtn={!removeAccountsToggle && transferType?.name !== BATCH_TRANSFER_TYPES.JURISDICTION_BATCH_TRANSFER_PCRA}
      onInvalid={onInvalid}
      disableContinue={shouldContinueDisable || isSubmitting}
      continueBtn={removeAccountsToggle ? t(t1, 'CONFIRM_DELETE') : t(t1, 'ADD_TO_BATCH')}
      cancelBtn={transferType?.name === BATCH_TRANSFER_TYPES.JURISDICTION_BATCH_TRANSFER_PCRA || !removeAccountsToggle ? t(t1, 'CANCEL') : t(t1, 'CANCEL_DELETE')}
      customDialogStyle={{ '.MuiDialog-paper': { borderRadius: '8px', width: '998px', maxHeight: '500px' } }}
    >
      <>
        <Typography variant="h2">{t(t1, 'RECEIVING_ACCOUNT_INFORMATION')}</Typography>
        <Typography
          variant="caption"
          component="div"
          display="block"
          sx={{ mb: 3 }}
          className="indicate_fields"
        >
          <Typography
            variant="body2"
            sx={{
              display: 'inline-block',
              color: 'common.red',
              mr: 0.5,
              fontWeight: 700,
              mb: '0px',
            }}
          >
            *
          </Typography>
          {t(t1, 'INDICATE_REQUIRED_FIELDS')}
        </Typography>
        <>
          <Typography variant="body">{t(t1, 'HOLDING_RECORD')}</Typography>
          <MUITableGrid
            isExport
            isSearch
            isSettings
            rows={selectedHolding}
            count={selectedHolding.length}
            columns={holdingsColumns}
            page={1}
            customTableStyle={{ marginTop: '8px' }}
            initialSortedFields={[{ field: 'vintage', sort: 'asc' }]}
            fileName={t(t1, 'HOLDING_RECORD')}
          />
        </>

        <Box mt={3.625} mb={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="end"
          >
            <Typography variant="body">{t(t1, 'RECEIVING_ACCOUNT')}</Typography>
            {!removeAccountsToggle && transferType?.name === BATCH_TRANSFER_TYPES.BATCH_TRANSFER && (
              <CustomizeButton
                variant="contained"
                disableElevation
                label={t(t1, 'DELETE')}
                name="priv_modal_Edit"
                csName="rc"
                handleClick={() => setRemoveAccountsToggle(true)}
                customStyle={{
                  minHeight: '30px',
                  minWidth: '100px',
                  padding: '5px 15px',
                }}
              />
            )}
          </Box>

          <MUITableGrid
            isExport
            isSearch
            isSettings
            rows={[...receivingAccountDetails]}
            count={receivingAccountDetails.length}
            columns={receivingColumns}
            page={1}
            customTableStyle={{ marginTop: '8px' }}
            initialSortedFields={[{ field: 'vintage', sort: 'asc' }]}
            checkboxSelection={removeAccountsToggle}
            selectionModel={selectedRows}
            onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
            fileName={t(t1, 'RECEIVING_ACCOUNT')}
            apiRef={apiRef}
            enablePrintView
            customPinnedColumns={{ left: [GRID_CHECKBOX_SELECTION_COL_DEF.field] }}
          />
        </Box>
        {(errors?.receivingAccountDetails?.length > 0 || validationErrors.length > 0 || selectedRows.length > 0) && (
          <Grid
            container
            mt={2}
          >
            <List dense>
              {selectedRows.length > 0 &&
                !receivingAccountDetails.every((item) => _.isEqual(item, receivingAccountData)) && (
                  <StyledErrorListItems key={'delete_confirm'}>
                    {t(t1, 'DELETE_CONFIRMATION_MESSAGE')}
                  </StyledErrorListItems>
                )}
              {errors?.receivingAccountDetails?.find((item) => item?.accountNumber) && (
                <StyledErrorListItems key={'price__required_error'}>{`${t(
                  t1,
                  'ACCOUNT_NUMBER_FORMAT_INCORRECT',
                )} ${JSON.stringify(errors?.receivingAccountDetails?.reduce((prev, curr) => curr?.accountNumber?.message ? [...prev, curr?.accountNumber?.message] : [], []))}`}</StyledErrorListItems>
              )}
              {errorList.map((error) => (error.message &&
                <StyledErrorListItems key={error.key}>{error.message}</StyledErrorListItems>))}
              {validationErrors.map((error) => (
                <StyledErrorListItems key={error.messageKey}>{error.messageKey}</StyledErrorListItems>
              ))}
            </List>
          </Grid>
        )}
      </>
    </ReceivingAccountPopup>
  );
}

export default AddReceivingAccount;
