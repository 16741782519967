const Style = {
  Spacing: {
    padding: '40px 0px',
    paddingRight: '0px !important',
  },
  InfoText: {
    marginBottom: '25px',
    border: 'dashed 2.5px #797979',
    paddingBottom: '10px',
    fontSize: '20px',
  },
  InfoUL: {
    listStyleType: 'auto',
    margin: '20px 0',
    paddingLeft: '40px',
    '& li': {
      fontWeight: '600',
      fontSize: ' 16px',
      padding: '0',
      letterSpacing: '0.5px',
      display: 'list-item',
      listStyleType: 'disc',
      '& a': {
        color: '#4d9bc7',
      },
    },
  },
  InfoULInner: {
    margin: '20px 0',
    '& li': {
      fontSize: '18px',
      color: '#333',
      padding: 0,
      paddingLeft: '30px',
      paddingBottom: '15px',
    },
    '& li:last-child': {
      paddingBottom: '0',
    },
  },
  Submited: {
    fontSize: '18px',
    color: '#333',
    fontWeight: '600',
  },
  StatusText: {
    width: '100%',
    backgroundColor: '#fff',
    padding: '20px 32px',
    borderTop: '1px solid #000',
    borderBottom: '1px solid #000',
    fontSize: '24px',
    fontWeight: '300',
    marginTop: '20px',
    textTransform: 'uppercase',
  },
  SingleParagraph: {
    '&.MuiTypography-root:nth-child(3)': {
      marginBottom: '20px',
      mt: 3,
    },
  },
  BottomParagraph: {
    marginTop: '30px',
    marginBottom: '10px',
    '& a': {
      color: '#4d9bc7',
      paddingLeft: '10px',
    },
  },

  QuickActionBox: {
    '& .MuiPopover-paper': {
      borderRadius: '0px',
      boxShadow: 'none !important',
      '& .MuiList-root': {
        background: '#fff',
        border: '1px solid #4d8da4',
        borderRadius: '4px',
        borderRradius: 0,
        width: '251px',
        padding: '6px',
        height: '60px',
      },
      '& .MuiButtonBase-root': {
        backgroundColor: '',
        width: '239px',
        height: '48px',
        margin: '0',
        borderRadius: '4px',
        fontSize: '14px',
        fontFamily: 'Open Sans',
        justifyContent: 'center',
        color: '#4d8da4',
        fontWeight: '400',
        '&:hover': {
          backgroundColor: '#4d8da4 !important',
          color: '#ffffff',
        },
        '&:focus': {
          backgroundColor: '#4d8da4 !important',
          color: '#ffffff',
        },
      },

    },
  },
};
export default Style;
