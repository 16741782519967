export const accountRepresentativesData =
  [
    {
      entityName: 'COVERED_ENTITY_COVERED_SOURCE_OPT_IN_ENTITY',
      entityDetails: [
        {
          roles: 'PRIMARY_ACCOUNT_REPRESENTATIVES',
          limits: [
            { name: 'covered_par_minimum', label: 'MINIMUM' },
            { name: 'covered_par_maximum', label: 'MAXIMUM' },
          ],
        },
        {
          roles: 'ALTERNATIVE_ACCOUNT_REPRESENTATIVES',
          limits: [
            { name: 'covered_aar_minimum', label: 'MINIMUM' },
            { name: 'covered_aar_maximum', label: 'MAXIMUM' },
          ],
        },
        {
          roles: 'ACCOUNT_VIEWING_AGENTS',
          limits: [
            { name: 'covered_ava_minimum', label: 'MINIMUM' },
            { name: 'covered_ava_maximum', label: 'MAXIMUM' },
          ],
        },
      ],
    },
    {
      entityName: 'GENERAL_MARKET_PARTICIPANT_INDIVIDUAL',
      entityDetails: [
        {
          roles: 'PRIMARY_ACCOUNT_REPRESENTATIVES',
          limits: [
            { name: 'gmpi_par_minimum', label: 'MINIMUM' },
            { name: 'gmpi_par_maximum', label: 'MAXIMUM' },
          ],
        },
        {
          roles: 'ALTERNATIVE_ACCOUNT_REPRESENTATIVES',
          limits: [
            { name: 'gmpi_aar_minimum', label: 'MINIMUM' },
            { name: 'gmpi_aar_maximum', label: 'MAXIMUM' },
          ],
        },
        {
          roles: 'ACCOUNT_VIEWING_AGENTS',
          limits: [
            { name: 'gmpi_ava_minimum', label: 'MINIMUM' },
            { name: 'gmpi_ava_maximum', label: 'MAXIMUM' },
          ],
        },
      ],
    },
    {
      entityName: 'GENERAL_MARKET_PARTICIPANT_ORGANIZATION',
      entityDetails: [
        {
          roles: 'PRIMARY_ACCOUNT_REPRESENTATIVES',
          limits: [
            { name: 'gmpo_par_minimum', label: 'MINIMUM' },
            { name: 'gmpo_par_maximum', label: 'MAXIMUM' },
          ],
        },
        {
          roles: 'ALTERNATIVE_ACCOUNT_REPRESENTATIVES',
          limits: [
            { name: 'gmpo_aar_minimum', label: 'MINIMUM' },
            { name: 'gmpo_aar_maximum', label: 'MAXIMUM' },
          ],
        },
        {
          roles: 'ACCOUNT_VIEWING_AGENTS',
          limits: [
            { name: 'gmpo_ava_minimum', label: 'MINIMUM' },
            { name: 'gmpo_ava_maximum', label: 'MAXIMUM' },
          ],
        },
      ],
    },
  ];

export const transferExpiryRegex = /^(0|[1-9]\d{0,2})$/;

export function generateYearObjects(startYear) {
  const currentYear = new Date().getFullYear();
  const yearObjects = [];

  for (let year = startYear; year <= currentYear; year += 1) {
    yearObjects.push({ name: year.toString(), value: year.toString() });
  }

  return yearObjects;
}

export function transformTransferSettings(transferSettings) {
  return {
    'transfer-settings': {
      ...transferSettings,
      current_vintage_year_allowance: {
        AttributeValue: transferSettings?.current_vintage_year_allowance?.AttributeValue?.value,
      },
      current_vintage_year_offset: {
        AttributeValue: transferSettings?.current_vintage_year_offset?.AttributeValue?.value,
      },
    },
  };
}

  