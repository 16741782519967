import { GRID_CHECKBOX_SELECTION_COL_DEF, getGridDateOperators } from '@mui/x-data-grid-premium';
import { betweenOperators } from 'components/MUITableGrid/components/MUICustomFilterFunctions';
import { customSorting, formatDateUpdatedWithoutTZ, formatNumber, t, getFilterForColumn } from 'utils/constants';
import QuickActions from './QuickActionsMenu';

export const budgetRecords = (t1, isWCIAdmin = false, isProposedBudgetTable = false, budgetRecords = []) => {
  let budgetRecordsList = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      minWidth: 73,
      hideable: false,
    },
    {
      id: 1,
      field: 'budgetYear',
      headerName: t(t1, 'BUDGET_YEAR'),
      minWidth: 100,
      maxWidth: 1000,
      width: 130,
      align: 'center',
      flex: 1,
      hideable: false,
    },
    {
      id: 2,
      field: 'annualAllowanceBudget',
      headerName: t(t1, 'ANNUAL_ALLOWANCE_BUDGET'),
      minWidth: 100,
      maxWidth: 1000,
      width: 200,
      align: 'center',
      valueFormatter: ({ value }) => formatNumber(value),
      sortComparator: customSorting,
      stringAsNumber: true,
    },
    {
      id: 3,
      field: 'reserveAdjustedAllowanceBudget',
      headerName: t(t1, 'RESERVE_ANNUAL_ALLOWANCE_BUDGET'),
      minWidth: 100,
      maxWidth: 1000,
      width: 270,
      align: 'center',
      valueFormatter: ({ value }) => formatNumber(value),
      sortComparator: customSorting,
      stringAsNumber: true,
    },
    {
      id: 4,
      field: 'holding',
      headerName: t(t1, 'ALLOWANCES_ISSUED_AGAINST_RESERVE_ADJUSTED_ALLOWANCE_BUDGET'),
      minWidth: 100,
      maxWidth: 1000,
      width: 450,
      align: 'center',
      renderCell: (param) => formatNumber(param.row.holding?.quantity),
      valueGetter: (param) => param.row.holding?.quantity,
      valueFormatter: ({ value }) => formatNumber(value),
      sortComparator: customSorting,
      stringAsNumber: true,
    },
    {
      id: 5,
      field: 'allowanceOutstandingForReserveAdjustedAllowanceBudget',
      headerName: t(t1, 'ALLOWANCE_OUTSTANDING_AGAINST_RESERVE_ADJUSTED_ALLOWANCE_BUDGET'),
      minWidth: 100,
      maxWidth: 1000,
      width: 450,
      align: 'center',
      valueFormatter: ({ value }) => formatNumber(value),
      sortComparator: customSorting,
      stringAsNumber: true,
    },
    {
      id: 6,
      field: 'Status',
      headerName: 'Status',
      minWidth: 100,
      maxWidth: 1000,
      width: 100,
      align: 'center',
      valueGetter: ({ value }) => t(t1, value?.name),
      type: 'singleSelect',
      valueOptions: getFilterForColumn(budgetRecords, t, t1, ['Status.name']).Status?.sort(),
    },
  ];
  if (isWCIAdmin) {
    budgetRecordsList.push({
      id: 7,
      disableExport: true,
      filterable: false,
      field: 'QuickActions',
      headerName: t(t1, 'QUICK_ACTIONS'),
      minWidth: 120,
      maxWidth: 120,
      align: 'center',
      hideable: false,
      renderCell: (params) => <QuickActions params={params} />,
    });
    if (!isProposedBudgetTable) {
      budgetRecordsList = budgetRecordsList.filter((record) => record.field !== 'Status');
    }
  }
  return budgetRecordsList;
};
export const budgetStatusHistory = (t1, data = []) => [
  {
    id: 1,
    field: 'RequestStatus',
    headerName: t(t1, 'REQUEST_STATUS'),
    minWidth: 100,
    maxWidth: 1000,
    align: 'center',
    width: 130,
    valueGetter: ({ value }) => t(t1, value?.name),
    type: 'singleSelect',
    valueOptions: getFilterForColumn(data, t, t1, ['RequestStatus.name']).RequestStatus?.sort(),
  },
  {
    id: 2,
    field: 'JurisdictionBudget',
    headerName: t(t1, 'BUDGET_STATUS_HISTORY'),
    minWidth: 100,
    maxWidth: 1000,
    align: 'center',
    width: 130,
    valueGetter: ({ value }) => t(t1, value?.Status?.Name),
    type: 'singleSelect',
    valueOptions: getFilterForColumn(data, t, t1, ['budgetStatusName']).budgetStatusName?.sort(),
  },
  {
    id: 3,
    field: 'budgetYear',
    headerName: t(t1, 'BUDGET_YEAR'),
    minWidth: 100,
    maxWidth: 1000,
    align: 'center',
    flex: 1,
  },
  {
    id: 4,
    field: 'annualAllowanceBudget',
    headerName: t(t1, 'ANNUAL_ALLOWANCE_BUDGET'),
    minWidth: 100,
    maxWidth: 1000,
    width: 200,
    align: 'center',
    valueFormatter: ({ value }) => formatNumber(value),
    sortComparator: customSorting,
    stringAsNumber: true,
  },
  {
    id: 5,
    field: 'reserveAdjustedAllowanceBudget',
    headerName: t(t1, 'RESERVE_ANNUAL_ALLOWANCE_BUDGET'),
    minWidth: 100,
    maxWidth: 1000,
    align: 'center',
    width: 260,
    valueFormatter: ({ value }) => formatNumber(value),
    sortComparator: customSorting,
    stringAsNumber: true,
  },
  {
    id: 6,
    field: 'requestInitiationDate',
    headerName: `${t(t1, 'REQUEST_INITIATION_DATE_ET')}`,
    minWidth: 100,
    maxWidth: 1000,
    align: 'center',
    width: 230,
    filterOperators: [...getGridDateOperators(), ...betweenOperators],
    valueGetter: ({ value }) => formatDateUpdatedWithoutTZ(value),
  },
  {
    id: 7,
    field: 'requestStatusUpdateDate',
    headerName: `${t(t1, 'REQUEST_STATUS_UPDATE_DATE_ET')}`,
    minWidth: 100,
    maxWidth: 1000,
    width: 250,
    align: 'center',
    filterOperators: [...getGridDateOperators(), ...betweenOperators],
    valueGetter: ({ value }) => formatDateUpdatedWithoutTZ(value),
  },
  {
    id: 8,
    field: 'action_by',
    headerName: t(t1, 'UPDATED_BY'),
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    align: 'center',
  },
  {
    id: 9,
    field: 'comment',
    headerName: t(t1, 'COMMENT'),
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    align: 'center',
  },
];
