const Style = {
  TopBar: {
    py: 0.5,
    border: '1px solid',
    borderLeft: 'none',
    borderRight: 'none',
    borderColor: 'primary.main',
    textAlign: 'center',
  },
  logoBar: {
    py: 1,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    img: {
      mt: -4,
      width: '115px',
      height: '75px',
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  linkWrapper: {},
  SelectorSection: {
    display: 'flex',
    width: '80%',
    justifyContent: 'end',
    marginLeft: 'auto',
    alignItems: 'center',
    marginTop: '0px',
    color: '#333333',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      '& label': {
        color: 'grey',
      },
      '& img': {
        marginTop: '5px',
      },
    },
    '& .login-btn': {
      width: '155px',
      borderRadius: '4px',
    },
    '& p': {
      color: '#a9a9a9',
    },
  },
  menuWrapper: {
    p: 0,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    a: {
      color: 'common.white',
      textDecoration: 'none',
      textTransform: 'uppercase',
      fontSize: '0.875rem',
    },
    '> .MuiListItem-root': {
      '&:last-child': {
        ml: 'auto',
        mr: 0,
      },
    },
  },
  menuItem: {
    py: 2,
    width: 'auto',
    position: 'relative',
    mr: 3,
    svg: {
      ml: 1,
      fill: (theme) => theme.palette.common.white,
    },
  },
  subMenuWrapper: {
    position: 'absolute',
    left: '0',
    top: '100%',
    borderRadius: '0 0 15px 15px',
    border: '1px solid',
    borderTop: 'none',
    borderColor: 'common.white',
    minWidth: 224,
    bgcolor: 'primary.main',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
    '> .MuiListItem-root': {
      p: 1,
      pl: '10px',
    },
  },
  userMenuWrapper: {
    position: 'absolute',
    right: '0',
    top: '48px',
    borderRadius: '0 0 15px 15px',
    border: '1px solid',
    borderTop: 'none',
    borderColor: 'common.white',
    minWidth: 224,
    bgcolor: 'primary.main',
    zIndex: 1,
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
    '& .MuiListItem-root': {
      p: 1,
      pl: '10px',
    },
  },
  userMenuItem: {
    py: 1,
    width: 'auto',
    position: 'relative',
    mr: 0,
    color: '#fff',
    svg: {
      ml: 1,
      fill: (theme) => theme.palette.common.white,
    },
    '& p': {
      margin: 0,
      fontWeight: 600,
      fontSize: '14px',
    },
    '& a': {
      fontSize: '14px',
      textTransform: 'capitalize',
      display: 'block',
    },
    '&:hover a': {
      fontWeight: 700,
    },
  },
};

export default Style;
