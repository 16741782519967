import { useParams } from 'react-router-dom';
import { FormCard } from 'components';
import { Grid, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  getWCIProposedBudgetRecords,
  setEditBudgetRecords,
  getEffectiveBudgetRecords,
  getAllowanceHistory,
  getBudgetDetails,
  setISLoadingBudgetDetails,
} from 'store/manageJurisdictionBudgetSlice';
import { setDialogMessage, setModelMessage } from 'store/commonSlice';
import { isFieldValid, isValueLarger } from 'pages/AddBudgetRecord/utils';
import { fetcherPatch } from 'utils/fetcher';
import { TextTruncate } from 'components/TextTruncate';
import Typography from '@mui/material/Typography';
import { getCurrentPageName, t, isEmpty, ERROR, APIS, statusTypes, REFRESH_REQUIRED } from '../../../utils/constants';
import { GridTextfield, DialogMessage } from '../../../components';
import Style from '../../ContactUs/styles';
import { getStatus } from '../../../store/manageJurisdictionBudgetSlice';
import { CustomizeButton } from '../../../components/CustomizeButton';
import CustomeDialogMessage from '../../../components/CustomeDialogMessage/CustomeDialogMessage';
import { JURISDICTION_BUDGET_COMPONENTS_FLP, getFLPHeaders } from '../constant';

export default function Form() {
  const dispatch = useDispatch();
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { editBudgetRecords, status } = useSelector(({ ManageJurisdictionBudget }) => ManageJurisdictionBudget);

  const defaultValues = {
    budgetYear:
      editBudgetRecords?.budgetYear && isEmpty(editBudgetRecords?.budgetYear) ? editBudgetRecords?.budgetYear : '-',
    annualAllowanceBudget: editBudgetRecords ? editBudgetRecords?.annualAllowanceBudget : null,
    reserveAdjustedAllowanceBudget: editBudgetRecords ? editBudgetRecords?.reserveAdjustedAllowanceBudget : null,
    comment: editBudgetRecords?.comment && !isEmpty(editBudgetRecords?.comment) ? editBudgetRecords?.comment : null,
  };

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const {
    trigger,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { dialogMessage, loader } = useSelector(({ common }) => common);

  useEffect(() => {
    reset(editBudgetRecords);
  }, [reset, editBudgetRecords]);

  const handleCancelModalSubmit = () => {
    reset();
    dispatch(setEditBudgetRecords(null));
  };

  const onSubmit = async () => {
    setOpenConfirmDialog(true);
  };

  const { id: jurisdictionId } = useParams();
  useEffect(() => {
    dispatch(getStatus());
  }, [dispatch]);

  const onPropose = async () => {
    const { id } = editBudgetRecords;
    const { annualAllowanceBudget, reserveAdjustedAllowanceBudget } = methods.getValues();
    const statusId = status.find(({ Name }) => Name === statusTypes.PROPOSED)?.ID;
    const body = [{ id, statusId, annualAllowanceBudget, reserveAdjustedAllowanceBudget, comment: '' }];
    dispatch(setISLoadingBudgetDetails(true));

    await fetcherPatch(`${APIS.BUDGETS}/jurisdictions/${jurisdictionId}/budget-record`, {
        jurisdictioBudgetRecord: body,
      },
      getFLPHeaders(JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_RECORD))
      .then(() => {
        dispatch(setISLoadingBudgetDetails(false));
        const data = {
          title: 'PROPOSE_SUCCESS',
          message1: t(t1, 'EDIT_BUDGET_SUCCESS_MESSAGE'),
          error: false,
        };
        dispatch(setModelMessage(data));
        dispatch(getAllowanceHistory(jurisdictionId));
        dispatch(getBudgetDetails(jurisdictionId));
        dispatch(getEffectiveBudgetRecords(jurisdictionId));
        dispatch(getWCIProposedBudgetRecords(jurisdictionId));
      })
      .catch((error) => {
        dispatch(setISLoadingBudgetDetails(false));
        const data = {
          title: ERROR,
          message1: error?.response?.data?.data === REFRESH_REQUIRED ? t(t1, 'REFRESH_ERROR_MESSAGE') : error?.response?.data?.data,
          error: true,
          isDefaultDisplay: false,
        };
        dispatch(setModelMessage(data));
      });
    dispatch(setEditBudgetRecords(null));
  };
  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="budget-form"
        >
          <FormCard
            style={{ paddingBottom: '20px' }}
            title={t(t1, 'EDIT_BUDGET_RECORDS')}
            indicateRequired={false}
          >
            <Grid
              container
              spacing={2.5}
            >
              <GridTextfield
                gridMd={3.5}
                name="budgetYear"
                label={t(t1, 'BUDGET_YEAR')}
                InputProps={{
                  'aria-required': 'true',
                }}
                customClass={{ paddingTop: '10px' }}
                isViewOnly
              />
              <GridTextfield
                type="text"
                isQuantity
                gridMd={4}
                customTrigger={async () => {
                  await trigger(['annualAllowanceBudget', 'reserveAdjustedAllowanceBudget']);
                }}
                name="annualAllowanceBudget"
                label={t(t1, 'ANNUAL_ALLOWANCE_BUDGET')}
                InputProps={{
                  'aria-required': 'true',
                }}
                isRequiredMsg="ERR_ANNUAL_ALLOWANCE_BUDGET_IS_REQUIRED"
                rules={{
                  validate: {
                    isValid: () => {
                      const { annualAllowanceBudget } = methods.getValues();
                      return isFieldValid(
                        annualAllowanceBudget,
                        `${t(t1, 'ERR_ANNUAL_ALLOWANCE_BUDGET_MUST_WHOLE_NO')}`,
                      );
                    },
                    isLarger: () => {
                      const { annualAllowanceBudget, reserveAdjustedAllowanceBudget } = methods.getValues();
                      return isValueLarger(
                        annualAllowanceBudget,
                        reserveAdjustedAllowanceBudget,
                        `${t(t1, 'ERR_RESERVE_ADJUSTED_ALLOWANCE_GREATER_ANNUAL_ALLOWANCE_BUDGET')}`,
                      );
                    },
                  },
                  required: true,
                }}
                bigIntLimitCheck
              />
              <GridTextfield
                type="text"
                gridMd={4}
                isQuantity
                customTrigger={async () => {
                  await trigger(['annualAllowanceBudget', 'reserveAdjustedAllowanceBudget']);
                }}
                name="reserveAdjustedAllowanceBudget"
                label={t(t1, 'RESERVE_ADJUSTED_ALLOWANCE_BUDGET')}
                InputProps={{
                  'aria-required': 'true',
                }}
                isRequiredMsg="ERR_RESERVE_ADJUSTED_ALLOWANCE_BUDGET_IS_REQUIRED"
                rules={{
                  validate: {
                    isValid: () => {
                      const { reserveAdjustedAllowanceBudget } = methods.getValues();
                      return isFieldValid(
                        reserveAdjustedAllowanceBudget,
                        `${t(t1, 'ERR_RESERVE_ADJUSTED_ALLOWANCE_BUDGET_MUST_WHOLE_NO')}`,
                      );
                    },

                    isLarger: () => {
                      const { annualAllowanceBudget, reserveAdjustedAllowanceBudget } = methods.getValues();
                      return isValueLarger(
                        annualAllowanceBudget,
                        reserveAdjustedAllowanceBudget,
                        `${t(t1, 'ERR_RESERVE_ADJUSTED_ALLOWANCE_GREATER_ANNUAL_ALLOWANCE_BUDGET')}`,
                      );
                    },
                  },
                  required: true,
                }}
                bigIntLimitCheck
              />
              {defaultValues.comment && (
                <Box sx={{ paddingLeft: '20px', wordBreak: 'break-all' }}>
                  <Typography sx={{ fontWeight: 700, fontSize: '15px' }}>{t(t1, 'COMMENTS')}</Typography>
                  <TextTruncate content={defaultValues.comment} />
                </Box>
              )}
            </Grid>
            <Grid container>
              <Box sx={{ ml: 'auto' }}>
                <Grid
                  item
                  sm={12}
                  sx={Style}
                >
                  <div
                    className="bottom-btn"
                    style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}
                  >
                    <CustomizeButton
                      disableElevation
                      variant="outlined"
                      csName="rc"
                      name="cancel_btn"
                      privId="priv_btn_cancel"
                      label={t(t1, 'BTN_CANCEL')}
                      handleClick={() => {
                        dispatch(setDialogMessage(true));
                      }}
                    />
                    <CustomizeButton
                      disableElevation
                      disabled={!isDirty || !methods?.formState?.isValid}
                      variant="contained"
                      type="submit"
                      customStyle={{ marginLeft: '8px' }}
                      label={t(t1, 'BTN_PROPOSE')}
                      privId="priv_btn_Propose"
                    />
                  </div>
                </Grid>
              </Box>
            </Grid>
          </FormCard>
        </form>
      </FormProvider>
      {dialogMessage ? (
        <DialogMessage
          warning
          handleModalClick={handleCancelModalSubmit}
          buttonMessage={false}
          title={t(t1, 'WARNING')}
          message1={t(t1, 'DIALOG_CANCEL_BUDGET_RECORD_MESSAGE')}
        />
      ) : null}
      <CustomeDialogMessage
        title={t(t1, 'CONFIRM_PROPOSAL')}
        continueBtn={t(t1, 'CONTINUE')}
        open={openConfirmDialog}
        handleModalCancel={() => {
          setOpenConfirmDialog(false);
        }}
        handleClose={() => {
          setOpenConfirmDialog(false);
        }}
        message1={t(t1, 'CONFIRM_PROPOSAL_MESSAGE')}
        handleContinue={onPropose}
        disableContinue={loader}
        borderColor="#283459"
      />
    </>
  );
}
