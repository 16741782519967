import React, { useRef, useState } from 'react';
import { useBlocker } from '../../hooks/useUnloadConfirmationDialog';
import { CustomeDialogMessage } from '../CustomeDialogMessage';

export default function UnsavedPrompt({ when, message, title, onSuccess, id }) {
  const [open, setOpen] = useState(false);
  const blockRef = useRef(null);
  const pattern = '/entity-registration/*';
  const entityprofilepattern = '/entity-profile/*';

  function matchPattern(inputString, pattern) {
    // Replace '*' with '.*' to create a regular expression pattern
    const regexPattern = pattern.replace(/\*/g, '.*');

    // Create a regular expression object
    const regex = new RegExp(`^${regexPattern}$`);

    // Use the test method to check if the input string matches the pattern
    return regex.test(inputString);
  }

  useBlocker(tx => {
    const currentPath = tx?.location?.pathname;
    let i = 0;
    if (matchPattern(currentPath, pattern) && tx.action === 'PUSH') {
      onSuccess?.();
      tx.retry();
      blockRef.current = tx;
      i = 1;
    } else if (matchPattern(currentPath, pattern) && tx.action === 'REPLACE') {
      blockRef.current = tx;
      i = 1;
    } else if ((currentPath === '/start-entity-registration' || tx?.location?.search === '?exit=1') && tx.action === 'PUSH') {
      blockRef.current = tx;
      blockRef.current?.retry();
      i = 1;
    }
    if (i === 1) {
      return;
    }
    // Check if the location pathname matches the patterns
    const isEntityRegistration = (currentPath === '/entity-registration' || matchPattern(currentPath, entityprofilepattern) || matchPattern(currentPath, pattern));
    if (!isEntityRegistration || (currentPath === '/entity-registration' && (tx.action === 'POP' || tx.action === 'REPLACE')) || (currentPath === '/' && id !== undefined)) {
      setOpen(true);
    } else {
      onSuccess?.();
      tx.retry();
    }
    blockRef.current = tx;
  }, when);

  return (
    <CustomeDialogMessage
      title={title}
      handleContinue={() => blockRef.current?.action === 'POP' ? onSuccess?.() : blockRef.current?.retry()}
      handleClose={() => setOpen(false)}
      open={open}
      message1={message}
      borderColor={'#ca2c1c'}
    />
  );
}
