import { GridRadioButton } from 'components/GridRadioButton';
import Grid from '@mui/material/Grid';

export function RadioSelectionUser(props) {
  const {
    radioOptions,
    onChangeRadio,
    selectedRadio,
    methods,
    Style,
    otherSelf,
    disabled = false,
    customeStyle,
    customTrigger,
  } = props;
  return (
    <Grid container spacing={2}>
      <Grid item xs={4} sx={Style.customMarginforReferenceRadiobox} className="radio-btn-group">
        {radioOptions.map((item, index) => (
          <GridRadioButton
            //  gridMd={index === 0 ? 0 : 0}
            gridMd={index === 0}
            sx={{ ...Style.selectRadioComponent, ...customeStyle }}
            // privId="priv_radioGroup_refselection"
            name={item}
            rules={{ required: true }}
            radios={item}
            onRadioChange={onChangeRadio}
            selectedRadio={selectedRadio}
            methods={methods}
            LabelShowAsName
            customTrigger={customTrigger}
            disabled={disabled}
          />
        ))}
      </Grid>
    </Grid>
  );
}
