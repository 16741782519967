import { Typography, Container, Grid, Button, Modal, Box, CircularProgress, Backdrop } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  getRoles,
  getRolesDetails,
  getSysRoles,
  setIsBlankRole,
  publishAllRoles,
  setModalDetails,
} from 'store/privilegeSlice';
import { ButtonFillEdit } from 'components/ButtonFillEdit';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { CustomizeLinks } from 'components/CustomizeLinks';
import PageAccess from 'components/PageAccess/PageAccess';
import { CustomizeButton } from 'components/CustomizeButton';
import { ModelMessage } from 'components';
import { t, getCurrentPageName } from 'utils/constants';
import { useAuthentication } from 'hooks/useAuthentication';
import { PrivilegeHeader } from '../PrivilegeHeader';
import Style from './styles';

function SystemRoles(props) {
  const location = useLocation();
  // useAuthentication({ redirectTo: location });
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readOnly, setReadonly] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const {
    roles,
    roleDetails,
    sysRoles,
    selectedRoleTypes,
    selectedRoles,
    isBlankRole,
    isSysRole,
    loading,
    modalDetails,
  } = useSelector(({ privilege }) => privilege);
  const { selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);
  const [popupData, setPopupData] = useState();
  const { user, userRole, isWciAdmin } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user;

  const { modelMessage } = useSelector(({ common }) => common);
  const [publishMessage, setPublishMessage] = useState('');
  let roleData;
  let readData;
  let roleFilter = roles;
  const arr = [];
  if (selectedRoleTypes.length > 0) {
    roleFilter = roles.filter((role) => selectedRoleTypes.includes(role?.name));
  }

  if (selectedRoles.length > 0) {
    roleFilter = roleFilter.filter((role) => role?.roles.find((control) => selectedRoles.includes(control.name)));
  }
  useEffectOnce(() => {
    if (isWciAdmin === true || isBlankRole === true) {
      dispatch(getRoles());
    } else dispatch(getRoles(selectedJurisdiction));
    setReadonly(false);
    if (props.module === 'copyRole') {
      setReadonly(true);
    }
    setPublishMessage('RoleType Added SuccessFully');
  }, [dispatch, isBlankRole, isWciAdmin, props.module, selectedJurisdiction]);

  useEffect(() => {
    if (isBlankRole === false && location.pathname === '/copy-roles') {
      navigate('/roles');
      dispatch(getRoles(selectedJurisdiction));
    }
  }, [isBlankRole]);

  const handleEdit = () => {
    if (props.module === 'copyRole') {
      dispatch(publishAllRoles(selectedJurisdiction));
    } else {
      navigate('/copy-roles');
      setReadonly(true);
    }
  };

  const handleModalToggle = (e, state, data, roleId) => {
    e.preventDefault();

    if (data) dispatch(setModalDetails(data));
    setPopupData(data);

    if (state === 'open') {
      setModalOpen(true);
      if (roles.length === 0 || location.pathname === '/copy-roles') {

        dispatch(getRolesDetails(process.env.REACT_APP_DEFAULT_ORGID, roleId));
      } else {
        dispatch(getRolesDetails(typeof selectedJurisdiction === 'object' ? '' : selectedJurisdiction, roleId));
      }

    }
    if (e.target.nodeName === 'BUTTON') {
      setModalOpen(false);
      dispatch(setModalDetails(''));
    }
  };

  return (
    <Container maxWidth="xl" sx={Style.spacing}>
      <Grid container spacing={2.5}>
        <PrivilegeHeader />
        <Grid item sm={12}>
          <div className="sub-tital">
            <Typography variant="h5">
              {
                // eslint-disable-next-line no-nested-ternary
                (props.module === 'copyRole' || isBlankRole === false) && isWciAdmin === false
                  ? t(t1, 'SR_H5_MANAGE_ROLES')
                  : isWciAdmin === true
                    ? t(t1, 'SR_H5_DEFAULT_ROLES')
                    : t(t1, 'SR_H5_DEFAULT_ROLES_SELECT_COPY')
              }
            </Typography>
            {/* <ButtonFillEdit handleClick={handleEdit}>
              {props.module === "copyRole" ? t(t1, "SR_BTN_PUBLISH_ALL_ROLES") : t(t1, "SR_BTN_COPY_ALL_ROLES")}
            </ButtonFillEdit> */}
            {props.module === 'copyRole'
              ? PrivAttributes?.priv_btn_CopyAllRoles?.PrivRole !== 'NA' &&
              isBlankRole === true && (
                <CustomizeButton
                  privId="priv_btn_PublishAllRole"
                  handleClick={handleEdit}
                  variant="contained"
                  label={t(t1, 'SR_BTN_PUBLISH_ALL_ROLES')}
                  name="priv_btn_PublishAllRole"
                />
              )
              : PrivAttributes?.priv_btn_CopyAllRoles?.PrivRole !== 'NA' &&
              isBlankRole === true && (
                <CustomizeButton
                  privId="priv_btn_CopyAllRoles"
                  handleClick={handleEdit}
                  variant="contained"
                  label={t(t1, 'SR_BTN_COPY_ALL_ROLES')}
                  name="priv_btn_CopyAllRoles"
                />
              )}
          </div>
        </Grid>
        <Grid item sm={12}>
          {roleFilter.length > 0 &&
            roleFilter?.map((role, key) => {
              readData = { readOnly };
              roleData = { ...role.roles, ...readData };
              return (
                <div key={key}>
                  <Typography variant="h4" className={props.module === 'copyRole' ? 'disable-link' : ''}>
                    {role.name}
                  </Typography>
                  <CustomizeLinks
                    isNested="true"
                    classes={props.module === 'copyRole' ? 'managementlink disable-link' : 'managementlink'}
                    name={'roleTypes'}
                    lists={role.roles}
                    roleType={role.name}
                    handleClick={handleModalToggle}
                    listLinks={props.module === 'copyRole'}
                  />
                </div>
              );
            })}
          {sysRoles.length > 0 &&
            roles.length === 0 &&
            sysRoles?.map((role, key) => {
              readData = { readOnly };
              roleData = { ...role.roles, ...readData };
              return (
                <div key={key}>
                  <Typography variant="h4" className={props.module === 'copyRole' ? 'disable-link' : ''}>
                    {role.name}
                  </Typography>
                  <CustomizeLinks
                    isNested="true"
                    classes={props.module === 'copyRole' ? 'managementlink disable-link' : 'managementlink'}
                    name={'roleTypes'}
                    lists={role.roles}
                    roleType={role.name}
                    handleClick={handleModalToggle}
                    listLinks={props.module === 'copyRole'}
                  />
                </div>
              );
            })}
        </Grid>
      </Grid>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={(e) => handleModalToggle(e)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={Style.popupStyle}>
            <PageAccess
              name="priv_grd_PageControls"
              label={modalDetails}
              labelText="Role Privileges"
              accordionClass="modal-accordion-style"
              panelClass="modal-panel-style"
              roleDetails={roleDetails}
              userRole={userRole}
              accordionCss={{
                '& .MuiAccordionSummary-content': {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                },
              }}
            />
            <Box sx={{ marginTop: '20px', textAlign: 'right' }}>
              <Button
                disableElevation
                variant="contained"
                sx={{ padding: '8px 50px' }}
                onClick={(e) => handleModalToggle(e)}
              >
                {t(t1, 'BTN_CLOSE')}
              </Button>
            </Box>
            {loading && (
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </Box>
        </Modal>
      )}
      {loading && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Container>
  );
}

export default SystemRoles;
