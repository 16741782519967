import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  t,
} from 'utils/constants';
import Style from './ApplicationChecklist.style';

const PageName = 'Page_ApplicationWithAttenstation';

function EntityRepsList({ PARNames, AARNames }) {
  const { t: t1 } = useTranslation(PageName);
  return <>
    <Typography sx={Style.entityIdentification}>{t(t1, 'ACCOUNT_REPRESENTATIVES')}</Typography>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, 'PRIMARY_ACCOUNT_REPRESENTATIVE')}</Typography>
      <Typography sx={Style.entryValue}>{PARNames}</Typography>
    </Box>
    <Box sx={Style.entry}>
      <Typography sx={Style.entryAttribute}>{t(t1, 'ALTERNATE_ACCOUNT_REPRESENTATIVE')}</Typography>
      <Typography sx={Style.entryValue}>{AARNames}</Typography>
    </Box>
  </>;
}

export default EntityRepsList;
