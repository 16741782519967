import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalforageBackend from 'i18next-localforage-backend';
import localforage from 'localforage';

import { initReactI18next } from 'react-i18next';

// import { checkIsAuthenticated } from "store/authSlice";
import {
  DEFAULT_LANGUAGE_CODE,
  DEFAULT_UNKNOWN_PAGE_ORG_ID,
  enLanguage,
  getAllData,
  getCurrentORGID,
  isEmpty,
  languageCode,
  PAGES,
} from 'utils/constants';
import { withoutLoginPages } from 'utils/pages';
import store from '../../store/store';

// Get org id to fetch CMS page as per jurisdiction based on entity or other user
const getOrgId = () => {
  return new Promise((resolve, reject) => {
    let count = 0;
    if (window.location.pathname.includes('entity-profile')) {
      const interval = setInterval(() => {
        count += 1;
        const jurisdictionDetails = store.getState().jurisdiction.jurisdictionDetails;
        const juriName = store.getState().entityProfile?.entities?.data?.jurisdiction?.name;
        const selectedJuri = jurisdictionDetails?.find(
          (juriItem) => juriItem?.Name?.toUpperCase() === juriName?.toUpperCase(),
        );
        if (selectedJuri?.dynamoId) {
          clearInterval(interval);
          resolve(selectedJuri?.dynamoId?.split('#')?.pop());
        }
        if (count > 500) {
          clearInterval(interval);
          const orgID = !isEmpty(localStorage.getItem('jurisdiction'))
            ? localStorage.getItem('jurisdiction')?.split('#')?.pop()
            : '00101';
          resolve(orgID);
        }
      }, 2000);
      // this will handle flow to display labels based on selected jurisdiction in COJ n Cross flow
    } else if (
      window.location.pathname.includes('change-jurisdiction') ||
      window.location.pathname.includes('cross-jurisdiction')
    ) {
      let orgID;
      if (!isEmpty(localStorage.getItem('change_cross_jurisdiction'))) {
        orgID = localStorage.getItem('change_cross_jurisdiction')?.split('#')?.pop();
      } else {
        orgID = !isEmpty(localStorage.getItem('jurisdiction'))
          ? localStorage.getItem('jurisdiction')?.split('#')?.pop()
          : '00101';
      }
      resolve(orgID);
    } else {
      const orgID = !isEmpty(localStorage.getItem('jurisdiction'))
        ? localStorage.getItem('jurisdiction')?.split('#')?.pop()
        : DEFAULT_UNKNOWN_PAGE_ORG_ID;
      resolve(orgID);
    }
  });
};

const loadResources = async (ns) => {
  const orgID = await getOrgId();
  const languageCode = localStorage.getItem('languageCode') || DEFAULT_LANGUAGE_CODE;
  const endpoint = localStorage.getItem('access_token') ? '/cms/v2/api/details' : '/cms/v2/public/api/details';
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}${endpoint}?OrgID=ORG%23${orgID}&Page=${encodeURIComponent(
      ns,
    )}&LanguageCode=${languageCode}`,
    { headers: { authorization: `Bearer ${localStorage.getItem('access_token')}` } },
  )
    .then(async (response) => {
      const data = await response.json();
      if (data?.messageKey === 'ERROR') {
        throw new Error(data?.message);
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return response;
};

// Backend options for ChainedBackend library
const chainedBackendOptions = {
  backends: [LocalforageBackend, HttpBackend],
  backendOptions: [
    {
      expirationTime: 300 * 24 * 60 * 60 * 1000, // 300 days
      driver: process.env.NODE_ENV === 'test' ? [localforage.LOCALSTORAGE] : [localforage.INDEXEDDB],
      storeName: 'wci_cms_store',
      version: 1.0,
    },
    {
      loadPath: '{{lng}}|{{ns}}',
      request: (options, url, payload, callback) => {
        try {
          const [_, ns] = url.split('|');
          loadResources(ns).then((response) => {
            callback(null, {
              data: JSON.stringify(response),
              status: 200,
            });
          });

          // }
        } catch (e) {
          console.error(e);
          callback(null, {
            status: 500,
          });
        }
      },
    },
  ],
};

i18n
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    backend: chainedBackendOptions,
    ns: withoutLoginPages,
    defaultNS: [],
    lng: `${decodeURIComponent(getCurrentORGID())}#${languageCode()}`,
    fallbackLng: false,
    debug: false,
    preload: [],
    detection: { order: ['queryString', 'cookie', 'localStorage', 'navigator'], caches: false }, // ['cookie']}
    interpolation: {
      escapeValue: false,
    },
    partialBundledLanguages: true,
    initImmediate: false,
    react: {
      useSuspense: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
    },
  });

export default i18n;
