import { QuickLinksMenu } from 'components/QuickLinksMenu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentPageName, t, statusTypes } from 'utils/constants';
import { useDispatch } from 'react-redux';
import { setEditBudgetRecords, setCancelBudgetDialogMessage } from 'store/manageJurisdictionBudgetSlice';

function QuickActionsMenu({ params }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();

  const scrollToForm = () => {
    const divElement = document.getElementById('budget-form');
    divElement.scrollIntoView({ behavior: 'smooth' });
  };
  const quickLinkList = React.useMemo(() => {
    const quickActionList = [
      {
        name: (t(t1, 'EDIT_BUDGET_RECORDS')).toUpperCase(),
        Access: null,
        onLinkClick: () => {
          dispatch(setEditBudgetRecords(params?.row));
          setTimeout(scrollToForm, 0);
        },
      },
    ];
    if (
      params?.row?.Status?.name === statusTypes?.PROPOSED_TO_AUTHORITY ||
      params?.row?.Status?.name === statusTypes?.PROPOSED ||
      params?.row?.Status?.name === statusTypes?.REVISIONS_REQUESTED
    ) {
      if (params?.row?.Status?.name !== statusTypes?.REVISIONS_REQUESTED) {
        quickActionList.pop();
      }
      quickActionList.push({
        name: (t(t1, 'CANCEL_PROPOSED_RECORD')).toUpperCase(),
        Access: null,
        onLinkClick: () => {
          dispatch(setEditBudgetRecords(params?.row));
          dispatch(setCancelBudgetDialogMessage(true));
        },
      });
    }
    return quickActionList;
  }, [dispatch, params?.row, t1]);

  return (
    <QuickLinksMenu
      iconClassName="dot-icon"
      popupClassName="quick-link-style"
      linksList={quickLinkList || []}
      isDotIcon={!false}
    />

  );
}

export default QuickActionsMenu;
