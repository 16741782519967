const Style = {
  otherCard: { paddingBottom: '20px' },
  formCardPadding: {
    paddingBottom: '40px',
    minHeight: '181px',
    '& .MuiFormGroup-root': {
      marginTop: '10px',
    },
    '& .MuiCheckbox-root': {
      padding: '0 9px',
    },
  },
  formCardPaddingOnAdd: {
    paddingBottom: '40px',
  },
  formCardPaddingOnAdd1: {
    paddingBottom: '0px !important',
  },
  addFacilityButton: {
    marginLeft: '0px',
  },
  cancelButton: {
    color: 'white',
    textColor: '#4d8da4',
  },
  continueButton: {},
  formField: {
    width: 1,
    color: 'common.textColor',
    '.MuiOutlinedInput-input': {
      py: 0.6,
      fontSize: '14px',
      '& .MuiTypography-root': {
        fontSize: '14px',
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: '0',
      borderColor: 'common.GrayColor',
    },
    '&.MuiOutlinedInput-root': {
      '&.MuiInputBase-root.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: '#333',
          borderWidth: 1,
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '4px',
    },
    '& .Mui-error': {
      marginLeft: 0,
    },
  },
  inputStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  customTable: {
    marginTop: '16px',
    '& .MuiDataGrid-columnHeaderTitle': {
      textOverflow: 'clip',
      whiteSpace: 'normal',
      lineHeight: 'normal',
    },
    '& .MuiDataGrid-columnHeader': {
      height: 'auto !important',
    },
    '& .MuiDataGrid-columnHeaderDraggableContainer': {
      alignItems: 'center',
    },
  },
  iconWrapper: {
    position: 'relative',
    mt: '0px',
    paddingTop: '0px',
    '.MuiGrid-item': {
      pt: '0px',
    },
    '.MuiPaper-elevation': {
      position: 'absolute',

    },
  },
  styleForPendingandRevision: {
    paddingBottom: '20px !important',
    marginBottom: '0px !important',
  },
  disabledLabelStyle: {
    color: '#333 !important',
    visibility: 'visible !important',
  },
};

export default Style;
