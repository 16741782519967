const Style = {
  LoginTital: {
    fontSize: '24px',
    fontWeight: '700',
    color: '#283459',
    lineHeight: '1.5',
  },

  Forgotline: {
    marginBottom: '0px',
    '& a': {
      color: '#4d8da4',
      cursor: 'pointer',
      textDecoration: 'none',
      fontSize: '14PX',
      fontFamily: ' \'Open Sans Semibold\', Open Sans',
      fontWeight: '600',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  MuiError: {
    margin: '0px',
    position: 'absolute',
  },
  ErrorText: {
    position: 'relative',
    marginTop: '5px',
    marginBottom: '5px',

    '& div': {
      background: '#fff',
      borderRadius: '8px',
    },
    '& MuiOutlinedInputRoot': {
      background: '#fff',
    },
    '& p': {
      margin: 0,
    },
    '& input': {
      padding: '8px 14px',
      height: '1.4375em',
    },
    // "& label": {
    //   top:"-7px",
    // },
  },
  checkboxmain: {
    '& span': {
      fontSize: '13px',
    },
  },
  LoginButton: {
    '& button': {
      height: '40px',
      Width: '100%',
    },
  },
  checkBoxLabelBold: {
    '& .MuiTypography-root': {
      fontWeight: '600',
      fontSize: '14px',
    },
    '& .MuiCheckbox-root': {
      padding: '9px',
    },
  },
};
export default Style;
