import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_MAIN = '#283459';
  const PRIMARY_DARK = '#202a47';
  const PRIMARY_LIGHT = '#4D8DA4';

  const logo = (
    <Box sx={{ width: 80, height: 40, ...sx }}>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 220 220">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,208.000000) scale(0.100000,-0.100000)" fillRule="evenodd" stroke="none"
           strokeWidth="1"
           fill={PRIMARY_MAIN}>
          <path fill="#057f48" d="M820 2061 c-364 -80 -658 -347 -773 -702 -39 -117 -50 -213 -45 -361
        8 -194 48 -334 146 -503 64 -110 237 -282 350 -349 143 -85 342 -147 464 -145
        l53 0 -68 20 c-147 42 -272 115 -391 228 -124 120 -211 268 -257 441 -32 118
        -32 328 0 445 134 499 636 797 1131 670 159 -40 357 -163 461 -286 58 -68 122
        -166 146 -224 20 -50 37 -61 27 -17 -35 146 -124 321 -222 438 -124 148 -337
        283 -527 335 -115 31 -374 36 -495 10z" />
          <path d="M832 1134 c-5 -10 -42 -95 -82 -189 -40 -93 -73 -171 -74 -172 -1 -2
        -37 78 -80 177 l-78 180 -39 0 c-21 0 -39 -4 -39 -10 0 -13 219 -523 229 -533
        5 -5 43 72 85 170 43 98 81 181 84 185 4 4 43 -76 86 -178 44 -101 82 -184 85
        -184 6 0 219 494 228 529 5 19 1 21 -34 21 l-40 0 -74 -171 c-40 -94 -76 -172
        -79 -174 -3 -2 -39 74 -80 168 -94 220 -85 204 -98 181z" />
          <path d="M1470 1131 c-78 -24 -155 -97 -185 -174 -38 -100 -4 -234 76 -301 67
        -57 107 -71 204 -71 54 0 103 5 123 14 30 13 32 17 32 62 l0 50 -30 -16 c-173
        -90 -366 34 -328 211 18 84 112 154 205 154 27 0 91 -16 136 -35 16 -7 18 -3
        15 41 -3 47 -4 49 -43 60 -45 14 -167 16 -205 5z" />
          <path d="M1800 865 l0 -265 40 0 40 0 0 265 0 265 -40 0 -40 0 0 -265z" />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
