const Style = {
  spacing: {
    padding: '30px 0px',
    '& .titalwithbtn': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItem: 'center',
      paddingBottom: '10px',
      '& h3': {
        marginBottom: '0px',
        fontSize: '36px',
        fontWeight: '300',
        color: '#333',
        '@media (max-width: 1023px)': {
          fontSize: '2.5rem !important',
        },
      },
    },
    '& .sub-tital': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .MuiButton-root': {
        width: '171px',
      },
      '& h5': {
        fontSize: '24px',
        color: '#000',
        fontWeight: '300',
      },
    },
    '& h6': {
      fontSize: '24px',
      fontWeight: '300',
      marginBottom: '20px',
      width: '100%',
    },
    '& .MuiFormGroup-root': {
      margin: '0px',
    },
    '& .MuiTypography-label': {
      fontSize: '14px',
      color: '#333',
    },
    '& .MuiFormControlLabel-root': {
      '& .MuiCheckbox-root': {
        padding: '4px',
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '14px',
        color: '#333',
        fontWeight: '300',
      },
      '& .MuiSvgIcon-root': {
        height: '0.8em',
        width: '0.8em',
      },
    },
    '& .bottom-btn': {
      display: 'flex',
      justifyContent: 'flex-end',
      '& .MuiButton-root': {
        width: '150px',
        height: '40px',
        marginLeft: '10px',
        boxShadow: 'none',
      },
      '& .reset': {
        color: ' #4d8da4',
        border: '1px solid #d7d7d7',
      },
    },
    '& h4': {
      background: '#4d8da4',
      padding: '12px 25px',
      color: '#fff',
      fontSize: '16px',
      marginBottom: '0px',
      textTransform: 'uppercase',
    },
    '& .managementlink': {
      padding: '0px 0px 40px 40px',

      '& .MuiListItem-root': {
        padding: '10px 15px',
        background: '#e6eff2',
        borderBottom: '1px solid #4d8da4',
        '& a': {
          color: '#333333',
          fontSize: '16px',
          outline: 'none',
          textDecoration: 'none',
        },
      },
      '& .disablelist': {
        background: '#c7c8ca61',
      },
    },
    '& .select-box-main': {
      '& .MuiTypography-label': {
        marginBottom: '8px',
        display: 'block',
      },
      '& .MuiBox-root': {
        fontSize: '14px',
      },
      '& .MuiFormControl-root': {
        margin: 0,
      },
      '& .selectbox-custom': {
        width: '100%',
        fontSize: '14px',
        color: 'common.textColor',
        marginBottom: '35px',

        '@media (max-width: 1100px)': {
          width: '100%',
        },
        '& .MuiSelect-select': {
          padding: '0px 35px 0px 5px',
          border: '1px solid #d7d7d7',
        },
      },
    },
    '& .MuiTextField-root': {
      width: '370px',
      fontSize: '13px',
      color: '#999',
      '@media (max-width: 1100px)': {
        width: '100%',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
        padding: '2.5px 5px',
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      color: '#333',
      fontWeight: '700',
      marginBottom: '8px',
    },
    '& .custom-text-right': {
      float: 'right',
    },
    '& .disable-tital': {
      background: 'common.textLightGray',
    },
  },
  MuiListRoot: {
    '& li': {
      fontSize: '10px !important',
    },
  },
  popupStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '1024px',
    bgcolor: 'background.paper',
    boxShadow: 2,
    borderRadius: '8px',
    p: 2,
  },
};
export default Style;
