import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import StepIcon from '@mui/material/StepIcon';

import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { setSelectedStep } from 'store/entityApplicationSlice';
import { t, getCurrentPageName } from 'utils/constants';
import Style from './EntitySteps.style';

export default function EntitySteps() {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { steps } = useSelector(({ entityApplication }) => entityApplication);
  const dispatch = useDispatch();
  const handleStepSelection = (step) => {
    dispatch(setSelectedStep(step));
  };

  const activeStep = (steps.find((item) => item.selected)?.stepNumber || 1) - 1;

  return (
    <Box sx={Style.stepperWrapper}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        orientation="vertical"
      >
        {steps
          .filter((item) => !item.subStep)
          .map((ostep, index) => {
            const step = { ...ostep };
            let subSteps = [];
            if (step.items) {
              subSteps = step.items
                .map((keyName) => {
                  return steps.find((item) => item.key === keyName);
                })
                .filter((item) => !item.skip);
            }
            return (
              <Step
                className={step.subStep ? 'SubStep' : 'MainStep'}
                key={`leftstep_${index}`}
                completed={step.submitted}
              >
                <StepButton
                  disabled={!step.submitted || activeStep === 4}
                  color="inherit"
                  onClick={() => {
                    if (step.link) handleStepSelection(step);
                  }}
                  disableRipple
                  sx={{
                    '&.Mui-disabled': {
                      color: '#c0c0c0',
                    },
                    py: 0,
                  }}
                  icon={step.stepNumber}
                >
                  {t(t1, step.key)}
                  <StepIcon />
                </StepButton>
                <div id="substep-wrapper">
                  {subSteps.length > 1 &&
                    subSteps.map((subStep, subindex) => {
                      return (
                        <Step
                          className={'SubStep'}
                          key={`leftsubstep_${index}_${subindex}`}
                          completed={subStep.submitted}
                        >
                          <StepButton
                            disabled={(!subStep.submitted || activeStep === 4) && !subStep.forceEnable}
                            color="inherit"
                            onClick={() => {
                              handleStepSelection(subStep);
                            }}
                            disableRipple
                            sx={{
                              '&.Mui-disabled': {
                                color: '#c0c0c0',
                              },
                              py: 0,
                            }}
                            icon={subStep.stepNumber}
                          >
                            {t(t1, subStep.key)}
                          </StepButton>
                        </Step>
                      );
                    })}
                </div>
              </Step>
            );
          })}
      </Stepper>
    </Box>
  );
}
