import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  t,
} from 'utils/constants';
import Style from './ApplicationChecklist.style';

const PageName = 'Page_ApplicationWithAttenstation';
const EntityRegistrationPage = 'Page_EntityRegistration';

function EntityAuction({ entityData }) {
  const { t: t1 } = useTranslation(PageName);
  const { t: tEntityRegistration } = useTranslation(EntityRegistrationPage);
  return <>
    <Typography sx={Style.entityIdentification}>{t(t1, 'AUCTION_AND_RESERVE_SALE_INFORMATION')}</Typography>
    <Typography sx={Style.checklistDescription}>
      {t(t1, 'AUCTION_AND_RESERVE_SALE_INFORMATION_DESCRIPTION')}
    </Typography>
    <Typography sx={Style.checklistDescription}>
      {t(tEntityRegistration, entityData?.isAuction ? 'AUCTION_AND_RESERVE_SALE_INFORMATION_YES' : 'AUCTION_AND_RESERVE_SALE_INFORMATION_NO')}
    </Typography>
  </>;
}

export default EntityAuction;
