import { Loadable } from 'components/Loadable';
import RoleBasedGuard from 'guards/RoleBasedGuard';
import { lazy } from 'react';

const IUAllReportsView = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.IUAllReportsView,
    })),
  ),
);

export const IndividualUsersAllReportsRoutes = {
  path: '/iu-all-reports',
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={'priv_lnk_iu_allreports'}>
          <IUAllReportsView />
        </RoleBasedGuard>
      ),
    },
  ],
};
