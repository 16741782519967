const Style = {
  FieldLabel: {
    fontSize: '0.875rem',
    color: 'common.textColor',
    fontWeight: 700,
    mb: 0.5,
    span: {
      fontWeight: 400,
      ml: 0.5,
      color: 'common.textGray',
    },
  },
  formField: {
    width: 1,
    color: 'common.black',
    '.MuiFormHelperText-root': {
      mx: 0,
    },
    input: {
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: '0',
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: '0',
      },
      '&[type=number]': {
        MozAppearance: 'textfield',
      },
    },
    '.MuiOutlinedInput-input': {
      p: 0.6,
      fontSize: '0.875rem',
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: '0',
      borderColor: 'common.GrayColor',
    },
    '.MuiOutlinedInput-root': {
      '&.MuiInputBase-root.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'common.GrayColor',
          borderWidth: 1,
        },
      },
    },
  },
  valuetext: {
    textOverflow: 'hidden',
    overflow: 'hidden',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
  disabledLink: {
    '&:hover': {
      cursor: 'default !important',
    },
  },
  InfoBox: {
    position: 'absolute',
    width: '100%',
    minWidth: '300px',
    right: 70,
    top: 0,
    zIndex: 5,
  },
  infoListBox: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 5,
    border: '1px solid rgba(77, 141, 164, 1)',
    background: '#fff',
  },
  infoListBoxItem: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0px !important',
    borderRadius: 1,
    padding: '0 !important',
    background: 'transparent !important',
    '& a, .divLinkStyle': {
      display: 'block',
      width: '100%',
      textAlign: 'center',
      color: '#4D8DA4 !important',
      background: 'transparent',
      borderRadius: '4px',
      padding: '10px 15px',
      transition: 'background 0.1s ease-in-out',
      cursor: 'pointer',
    },
    '& a:hover , .divLinkStyle:hover': {
      background: '#4d8da4',
      color: '#fff !important',
      fontWeight: 600,
    },
  },
};

export default Style;
