import { Icon } from '@iconify/react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import { Loader } from 'components/Loader';
import useAuth from 'hooks/useAuth';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getAccessToken } from 'store/authSlice';
import { setModelMessage } from 'store/commonSlice';
import { ERROR, getCurrentPageName, noSpacePattern, t } from 'utils/constants';
import { GridTextfield } from '../../../components';
import { StyledCardTitle } from '../../AccountDetails/AccountDetails.style';
import { StyledPassphraseLabel, StyledRequiredAstrik } from '../WiringInstructions.style';

export default function PassphraseConfirmationDialog({
                                                       renderFormElements,
                                                       open,
                                                       handleCloseDialog = () => {
                                                         // This is intentional
                                                       },
                                                       onPassphraseSuccess,
                                                       isCallBackNeeded = false,
                                                       pageName = 'Page_WiringInstructions',
                                                       onHandleConfirmStart,
                                                       onPassphraseFailure,
                                                       title,
                                                       fontSize,
                                                       cancelButtonLabel,
                                                       confirmButtonLabel,
                                                       autoCompleteAvoid,
                                                       resetFormElementsAvoid,
                                                       passphraseLabel,
                                                     }) {
  const { t: t1 } = useTranslation(pageName || getCurrentPageName());
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const { authSignIn, user } = useAuth();


  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      passphrase: '',
      comment: null, // optional field
    },
  });


  const handleConfirmDialog = async (data) => {
    onHandleConfirmStart?.();
    if (data?.passphrase && user.username) {
      setLoader(true);
      await authSignIn({
        email: user.username,
        password: data?.passphrase,
        clientMetaData: {
          token: getAccessToken(),
        },
      })
        .then((response) => {
          setLoader(false);
          onPassphraseSuccess?.(data);
          setShowPassword(false);
          methods.reset();
        })
        .catch((e) => {
          if (isCallBackNeeded) {
            isCallBackNeeded(data);
            setLoader(false);
            methods.reset();
          } else {
            if (resetFormElementsAvoid) {
              methods.resetField('passphrase', { keepDirty: true });
            } else {
              methods.reset();
            }
            handleCloseDialog?.();
            dispatch(
              setModelMessage({
                title: ERROR,
                message1: t(t1, 'INCORRECT_PASSPHRASE'),
                error: true,
                isDefaultDisplay: true,
              }),
            );
            setLoader(false);
            onPassphraseFailure?.();
          }
        });
    }
  };

  const handleClose = () => {
    methods?.reset();
    handleCloseDialog?.();
    setShowPassword(false);
  };

  return (
    <Dialog
      indicateRequired={false}
      open={open}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '8px',
        },
      }}
    >
      <span
        style={{
          borderColor: '#283459',
          borderStyle: 'solid',
          borderRadius: '8px',
          color: 'black',
          padding: '20px',
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleConfirmDialog)}
            autoComplete="off"
          >
            <DialogTitle sx={{ p: 0, pb: 3 }}>
              <StyledCardTitle sx={{ mb: 0, fontSize: 24, color: '#333333' }}>{title}</StyledCardTitle>
              <Typography
                variant="caption"
                component="div"
                display="block"
              >
                <StyledRequiredAstrik variant="body2">*</StyledRequiredAstrik>
                {t(t1, 'INDICATE_REQUIRED_FIELDS')}
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
              <Grid container>
                {renderFormElements?.(methods)}
                <Box width="100%">
                  <StyledPassphraseLabel>
                    {passphraseLabel || t(t1, 'ENTER_PASSPHRASE_TO_SUBMIT')}
                    <StyledRequiredAstrik variant="body2">*</StyledRequiredAstrik>
                  </StyledPassphraseLabel>
                  <GridTextfield
                    autoComplete={autoCompleteAvoid ? 'new-password' : 'off'}
                    privId="priv_txt_passphrase"
                    type={showPassword ? 'text' : 'password'}
                    name="passphrase"
                    rules={{

                      required: `${t(t1, 'ERR_PASSPHRASE_IS_REQUIRED')}`,
                      minLength: {
                        value: 8,
                        message: ` ${t(t1, 'ERR_PASSPHRASE_MIN_LENGTH_CHAR')}`,
                      },
                      maxLength: {
                        value: 50,
                        message: `${t(t1, 'ERR_PASSPHRASE_MAX_LENGTH_CHAR')}`,
                      },
                    }}
                    isRequiredMsg={'ERR_PASSPHRASE_IS_REQUIRED'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            <Icon icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                      autoComplete: 'new-password',
                    }}
                    gridMd={6}
                    sx={{ zIndex: 999 }}
                  />
                </Box>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ px: 0, pb: 0 }}>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  width: 150,
                  height: 40,
                  color: '#4D8DA4',
                  backgroundColor: '#FFFFFF',
                  border: '1px solid #C7C8CA',
                  '&:hover': { backgroundColor: '#FFFFFF', border: '1px solid #C7C8CA' },
                }}
              >
                {cancelButtonLabel || t(t1, 'CANCEL')}
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  width: 150,
                  height: 40,
                  '&.Mui-disabled': {
                    backgroundColor: '#aaaaaa',
                    textFillColor: '#ffffff !important',
                  },
                  '&:hover': {
                    backgroundColor: '#4D8DA4',
                  },
                }}
                disabled={methods && !methods?.formState?.isValid}
              >
                {confirmButtonLabel || t(t1, 'CONTINUE')}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </span>
      {loader && <Loader loader={loader} />}
    </Dialog>
  );
}
