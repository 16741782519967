import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentPageName, jobEmployerPattern, validatePattern, t } from 'utils/constants';
import { GridTextfield } from 'components';
import Style from './DynamicMultiInputTextfield.style';

export default function DynamicMultiInputTextfield({
                                                     methods,
                                                     stepNumber,
                                                     fieldName,
                                                     userProfileEdit,
                                                     viewEditRegistration,
                                                   }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { formSteps: { isEditField, activeStep } } = useSelector(({ createUserProfile }) => createUserProfile);
  const { control, getFieldState, getValues, formState: { errors } } = methods;
  const {
    fields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
    replace,
  } = useFieldArray({
    control,
    name: fieldName,
  });

  return fields?.map((fieldObj, index) => (
    <Grid key={`${fieldObj?.id}-employer-key`} item md={4} sx={{
      pt: '10px !important',
      height: (!isEditField[stepNumber] && activeStep === 4) || !userProfileEdit ? 70 : 100,
    }}>
      <div>
        <GridTextfield
          name={`${fieldName}.${index}.title`}
          label={t(t1, index === 0 ? 'EMPLOYER' : 'ADDITIONAL_EMPLOYER')}
          privId={`priv_txt_Employer_${index}`}
          rules={{
            required: index === 0,
            minLength: {
              value: 1,
              message: `${t(t1, 'ERR_EMPLOYER_MIN_LENGTH_CHAR')}`,
            },
            maxLength: {
              value: 255,
              message: `${t(t1, 'ERR_EMPLOYER_MAX_LENGTH_CHAR')}`,
            },
            validate: (v) =>
              methods?.getValues('employer')
                ? validatePattern(v, `${t(t1, 'EMPLOYER')}`, t1, jobEmployerPattern, `${t(t1, 'ERR_INVALID_EMPLOYER')}`)
                : null,
          }}
          isRequiredMsg={'ERR_EMPLOYER_IS_REQUIRED'}
          isViewOnly={!userProfileEdit}
          className="input-group"
          stepNumber={2}
          gridMd={12}
          hideErrorMsg
          boxLabelClass={viewEditRegistration ? '' : 'box-label-wrapper'} // css used only for profile edit flow
        />
      </div>
      {index === fields.length - 1 && fields.length < 10 && userProfileEdit && !(!isEditField[stepNumber] && activeStep === 4) &&
        <div style={viewEditRegistration ? Style.addEmployerBtnContainer : Style.addEmployerBtnContainerViewProfile}>
          <Button
            onClick={() => {
              if ((getValues(`${fieldName}.${index}.title`) && !getFieldState(`${fieldName}.${index}.title`)?.invalid) || index === 0) {
                append({ title: '' });
              }
            }}
            variant="text"
            sx={Style.addEmployerBtn}
          >
            <Typography
              color="common.warning"
              sx={{
                fontSize: '14px',
                paddingTop: '0 !important',
              }}
            >
              + {t(t1, 'ADDITIONAL_EMPLOYER')}
            </Typography>
          </Button>
        </div>}
    </Grid>
  ));
};
