import { Box, Divider, Typography, Container, Stack, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getJurisdictions, setJurisdiction } from 'store/jurisdictionSlice';
import parse from 'html-react-parser';
import { t, parseOptions, getCurrentPageName, enLanguage, getCurrentORGID } from 'utils/constants';

import Style from './styles';
import LangModal from './components/Modal/LangModal';

function LandingPageUnknown() {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState(false);
  const { Jurisdictions } = useSelector(({ jurisdiction }) => jurisdiction);

  useEffect(() => {
    const language = localStorage.getItem('i18nextLng');
    const localJurisdiction = localStorage.getItem('jurisdiction');
    if (!language || !localJurisdiction) {
      setIsModal(true);
    }
  }, []);

  useEffectOnce(() => {
    dispatch(getJurisdictions());
  }, []);

  return (
    <>
      <Box className={Style.Banner} sx={Style.Banner}>
        <Container maxWidth="xl">
          <Grid container maxWidth="xl" alignItems="center">
            <Grid item lg={12} md={12} sm={12}>
              <Typography sx={Style.Platform} variant="h2">
                {/* {t(t1, "WCI_PLATFORM")} */}
                {t(t1, 'HEADER_IMAGE_TXT')}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Divider />
      <Container maxWidth="xl">
        <Stack className={Style.WelcomeText} direction="row" alignItems="center" sx={{ mb: 5, ...Style.WelcomeText }}>
          <Grid container spacing={0}>
            <Grid item lg={12} md={12} sm={12}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography sx={Style.WelcomeTextTital} variant="h3" gutterBottom>
                  {/* {t(t1, "WELCOME")} */}
                  {t(t1, 'WELCOME')}
                </Typography>
                <Typography sx={{ color: 'text.secondary', '& p': { margin: 0 } }}>
                  {/* {t(t1, "FOOTER_DISCLAIMER_CONTENT")} */}
                  {t(t1, 'HOME_MAIN_CONTENT')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </Container>
      {isModal && <LangModal isModal={isModal} />}
    </>
  );
}

export default LandingPageUnknown;
