import React from 'react';

export default function CopyIcon({ color, iconHeight, iconWidth }) {
  return (
    <svg width={iconWidth} height={iconHeight} xmlns="http://www.w3.org/2000/svg" fill={color}>
      <path
        d="M14.286 4.286h4.643c.297 0 .55.104.759.312.208.209.312.462.312.76v13.57c0 .298-.104.551-.313.76a1.033 1.033 0 0 1-.758.312H8.214c-.297 0-.55-.104-.759-.313a1.033 1.033 0 0 1-.312-.758v-3.215H1.07c-.297 0-.55-.104-.758-.312a1.033 1.033 0 0 1-.313-.76v-7.5c0-.297.074-.624.223-.981.149-.357.328-.64.536-.848L5.313.758c.208-.208.49-.387.848-.536C6.518.074 6.845 0 7.143 0h4.643c.297 0 .55.104.759.313.208.208.312.46.312.758v3.661c.506-.297.982-.446 1.429-.446ZM12.857 10V6.663L9.52 10h3.337ZM5.714 5.714V2.377L2.377 5.714h3.337Zm1.652 4.732c.149-.357.327-.64.536-.848l3.527-3.527V1.43H7.143V6.07c0 .298-.104.551-.313.76a1.033 1.033 0 0 1-.759.312H1.43v7.143h5.714v-2.857c0-.298.074-.625.223-.983Zm1.205 8.125h10V5.714h-4.285v4.643c0 .298-.104.55-.313.76a1.033 1.033 0 0 1-.759.312H8.571v7.142Z"
      />
    </svg>
  );
} 