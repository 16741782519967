import { Loadable } from 'components/Loadable';
import RoleBasedGuard from 'guards/RoleBasedGuard';
import { lazy } from 'react';

const EntityAccountBalance = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.EntityAccountBalance,
    })),
  ),
);

const HoldingLimitAndLimitedExemption = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.HoldingLimitAndLimitedExemption,
    })),
  ),
);

const JurisdictionBatchTransfer = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.JurisdictionBatchTransfer,
    })),
  ),
);

const PurchaseLimits = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.PurchaseLimits,
    })),
  ),
);

const TransferEventAudit = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.TransferEventAudit,
    })),
  ),
);

const EmissionListAR = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.EmissionListAR,
    })),
  ),
);

const TrueUpListAR = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.TrueUpListAR,
    })),
  ),
);

export const reportRoutes = {
  path: '/reports',
  children: [
    {
      path: 'entity-account-balance',
      element: (
        <RoleBasedGuard privId={''}>
          <EntityAccountBalance />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'holding-limit-limited-exemption',
      element: (
        <RoleBasedGuard privId={''}>
          <HoldingLimitAndLimitedExemption />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'jurisdiction-batch-transfer',
      element: (
        <RoleBasedGuard privId={''}>
          <JurisdictionBatchTransfer />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'purchase-limits',
      element: (
        <RoleBasedGuard privId={''}>
          <PurchaseLimits />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'transfer-event-audits',
      element: (
        <RoleBasedGuard privId={''}>
          <TransferEventAudit />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'emissions',
      element: (
        <RoleBasedGuard privId={''}>
          <EmissionListAR />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'true-ups',
      element: (
        <RoleBasedGuard privId={''}>
          <TrueUpListAR />
        </RoleBasedGuard>
      ),
    },
  ],
};
