import KeyValueCard from 'components/KeyValueCard/KeyValueCard';
import usePermissions from 'hooks/usePermissions';
import { BATCH_TRANSFER_COMPONENTS_FLP } from 'pages/ManageBatchTransfer/constants';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BATCH_TRANSFER_STATUS, formatNumber, getCurrentPageName, t } from 'utils/constants';

function BatchTransferAccountDetailsCard({ accountDetails }) {
  const { juriAccount, batchDetails, batchReceivingAccounts, transferType } = accountDetails;

  const detailedView = useMemo(
    () => batchDetails?.Status?.name !== BATCH_TRANSFER_STATUS.ACTIVE,
    [batchDetails?.Status?.name],
  );
  const totalBatchQuantity = useMemo(
    () =>
      batchReceivingAccounts.reduce((acc, holding) => {
        const qty = holding.proposedQuantity?.replaceAll(',', '');
        return acc + BigInt(qty ?? 0);
      }, 0n),
    [batchReceivingAccounts],
  );

  const { t: t1 } = useTranslation(getCurrentPageName());

  const batchTransferDetailsPermission = usePermissions(BATCH_TRANSFER_COMPONENTS_FLP.BATCH_ACCOUNT_DETAILS);

  const accInfoArr = [
    {
      id: 1,
      title: t(t1, 'TRANSFER_ACCOUNT_NUMBER'),
      value: juriAccount?.accountNumber || 'NA',
      access: batchTransferDetailsPermission?.readOnly?.JurisdictionAccounts ? ['read'] : undefined,
    },
    {
      id: 2,
      title: t(t1, 'ACCOUNT_TYPE'),
      value: juriAccount?.accountTypeLanguage
        ? juriAccount?.accountTypeLanguage?.[localStorage.getItem('languageCode')] || ''
        : juriAccount?.accountType || '',
      access: batchTransferDetailsPermission?.readOnly?.JurisdictionAccounts ? ['read'] : undefined,
      noNASlashValue: true,
    },
    ...(detailedView
      ? [
        {
          id: 3,
          title: t(t1, 'TRANSFER_TYPE'),
          value: t(t1, transferType?.name || 'NA'),
          access: batchTransferDetailsPermission?.readOnly?.TransferType ? ['read'] : undefined,
        },
        {
          id: 4,
          title: t(t1, 'BATCH_STATUS'),
          value: t(t1, batchDetails?.Status?.name || 'NA'),
          access: batchTransferDetailsPermission?.readOnly?.Status ? ['read'] : undefined,
        },
        {
          id: 5,
          title: t(t1, 'CREATED_BY'),
          value: t(t1, batchDetails?.createdBy || 'NA'),
          access: batchTransferDetailsPermission?.readOnly?.BatchTransferHistory ? ['read'] : undefined,
        },
        {
          id: 6,
          title: t(t1, 'BATCH_ID'),
          value: batchDetails?.batchId || 'NA',
          access: batchTransferDetailsPermission?.readOnly?.batchId ? ['read'] : undefined,
        },
      ]
      : []),
    {
      id: 7,
      title: t(t1, 'ACCOUNT_BALANCE'),
      value: formatNumber(juriAccount?.accountBalance) || 'NA',
      access: batchTransferDetailsPermission?.readOnly?.JurisdictionAccounts ? ['read'] : undefined,
    },
    {
      id: 8,
      title: t(t1, 'AVAILABLE_ACCOUNT_BALANCE'),
      value: formatNumber(juriAccount?.availableAccountBalance) || 'NA',
      access: batchTransferDetailsPermission?.readOnly?.JurisdictionAccounts ? ['read'] : undefined,
    },
    {
      id: 9,
      title: t(t1, 'BATCH_QUANTITY'),
      value: formatNumber(totalBatchQuantity.toString() || 0),
      access: batchTransferDetailsPermission?.readOnly?.JurisdictionAccounts ? ['read'] : undefined,
    },
    {
      id: 10,
      title: t(t1, 'NUMBER_OF_TRANSFER_ENTRIES'),
      value: batchReceivingAccounts?.length || '0',
      access: batchTransferDetailsPermission?.readOnly?.JurisdictionAccounts ? ['read'] : undefined,
    },
  ];
  return (
    <KeyValueCard
      title={`${t(t1, 'TRANSFERRING_ACCOUNT_DETAIL')}`}
      data={accInfoArr}
      isLoading={false}
      flp
    />
  );
}

export default BatchTransferAccountDetailsCard;
