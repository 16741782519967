import { Grid } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { GridTextfield } from 'components/GridTextfield';
import { CustomizeButton } from 'components/CustomizeButton';
import { useTranslation } from 'react-i18next';
import {
  JURISDICTION_USER_ACTION_FORM_FIELDS,
  emailPattern,
  getCurrentPageName,
  mailPattern,
  t,
  validatePhoneNumber,
} from 'utils/constants';
import Style from './CustomDialogMultiInput.style';

export default function CustomDialogMultiInput({
                                                 open = null,
                                                 handleClose,
                                                 methods,
                                                 handleContinue,
                                                 customDialogStyle = {},
                                                 currentUserTelephone = '',
                                               }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const [disableProposeBtn, setDisableProposeBtn] = useState(true);
  const { getFieldState, watch } = methods;
  const [validEmail, setValidEmail] = useState(false);
  const { validateUniqueUser } = useAuth();
  const styleSx = { ...Style?.ModelError };

  const emailWatchValue = watch('email');
  const telephoneWatchValue = watch('telephoneNumber');

  const emailIsUnique = async (value) => {
    if (value?.toString()?.length <= JURISDICTION_USER_ACTION_FORM_FIELDS.EMAIL_MAX_CHAR) {
      if (value.includes('..')) {
        setValidEmail(false);
        return `${t(t1, 'ERR_INVALID_EMAIL_ADDRESS')}`;
      }
      const isValid = await validateUniqueUser({ email: value });
      if (!isValid) {
        setValidEmail(false);
        return `${t(t1, 'ERR_EMAIL_ADDRESS_NOT_AVAILABLE')}`;
      }
      setValidEmail(true);
      return null;
    }
    setValidEmail(false);
    return `${t(t1, 'ERR_EMAIL_MAX_LENGTH_CHAR')}`;
  };

  useEffect(() => {
    if (emailWatchValue?.trim()?.length > 0 && validEmail && mailPattern?.test(emailWatchValue?.trim()) && (telephoneWatchValue?.trimStart()?.trimEnd()?.length === 0 || (!validatePhoneNumber((telephoneWatchValue?.trimStart()?.trimEnd() || ''), `${t(t1, 'TELEPHONE_NUMBER')}`, t1, `${t(t1, 'ERR_TELEPHONE_NUMBER_MIN_LENGTH_CHAR')}`, `${t(t1, 'ERR_TELEPHONE_NUMBER_MAX_LENGTH_CHAR')}`, `${t(t1, 'ERR_INVALID_TELEPHONE_NUMBER')}`, '', currentUserTelephone)) || !telephoneWatchValue?.trimStart()?.trimEnd())) {
      setDisableProposeBtn(false);
      return;
    }
    if (!validatePhoneNumber((telephoneWatchValue?.trimStart()?.trimEnd() || ''), `${t(t1, 'TELEPHONE_NUMBER')}`, t1, `${t(t1, 'ERR_TELEPHONE_NUMBER_MIN_LENGTH_CHAR')}`, `${t(t1, 'ERR_TELEPHONE_NUMBER_MAX_LENGTH_CHAR')}`, `${t(t1, 'ERR_INVALID_TELEPHONE_NUMBER')}`, '', currentUserTelephone) && (!emailWatchValue?.trim() || emailWatchValue?.trim()?.length === 0 || (validEmail && mailPattern?.test(emailWatchValue?.trim())))) {
      setDisableProposeBtn(false);
      return;
    }
    setDisableProposeBtn(true);
  }, [emailWatchValue, telephoneWatchValue, validEmail]);

  const handleClickPropose = () => {
    if (!getFieldState('telephoneNumber')?.error && !getFieldState('email')?.error && validEmail && telephoneWatchValue.trimStart().trimEnd()) {
      handleContinue(methods?.getValues('telephoneNumber').trimStart().trimEnd(), methods?.getValues('email').trim());
    } else if (!getFieldState('telephoneNumber')?.error && telephoneWatchValue.trimStart().trimEnd()) {
      handleContinue(methods?.getValues('telephoneNumber').trimStart().trimEnd());
    } else if (!getFieldState('email')?.error && validEmail) {
      handleContinue(null, methods?.getValues('email').trim());
    }
  };

  return (
    <Dialog
      sx={{
        ...Style?.ModelError,
        ...styleSx,
        '.messageModel': {
          margin: '20px',
          borderRadius: 8,
          //
          '& .MuiTypography-subtitle1': {
            fontSize: '24px',
            lineHeight: '24px',
            marginBottom: '15px',
          },
          '& .bottom-btn': {
            marginTop: '15px',
            display: 'flex',
            justifyContent: 'flex-end',
            '@media (max-width: 1100px)': {
              marginTop: '30px',
            },
            '& .MuiButton-root': {
              // width: "150px",
              height: '40px',
              marginLeft: '10px',
              boxShadow: 'none',
              border: '1px solid #d7d7d7',
              lineHeight: 'normal',
              outline: 0,
            },
            '& .submitBtn': {
              fontWeight: 400,
            },
          },
        },
        ...customDialogStyle,
      }}
      fullWidth
      maxWidth={'sm'}
      // open={dialogMessage}
      open={open}
      // onClose={(_, reason) => {
      //     if (reason !== "backdropClick") {
      //         handleModalCancel()
      //     }
      // }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box
        sx={{
          border: '2px solid',
          borderColor: '#4d8da4',
          borderRadius: '8px',
        }}
      >
        <Box className="messageModel">
          <Typography variant="subtitle1" sx={{ fontWeight: 300 }}>
            {t(t1, 'UPDATE_USER_TELEPHONE_NUMBER_AND_EMAIL')}
          </Typography>
          <Typography
            variant="caption"
            component="div"
            display="block"
          >
            {t(t1, 'PROPOSE_TO_EDIT_EITHER_PHONE_OR_EMAIL_OR_BOTH')}
          </Typography>
          <Grid item container spacing={2.5}>
            <Grid item md={6}>
              <GridTextfield
                gridMd={12}
                customeClass=""
                className="input-group"
                inputClass=""
                label={t(t1, 'TELEPHONE_NUMBER')}
                name={'telephoneNumber'}
                privId={'priv_linkmodal_txt_user_update_Telephonenumber'}
                rules={{
                  // minLength: {
                  //   value: JURISDICTION_USER_ACTION_FORM_FIELDS.TELEPHONE_MIN_CHAR,
                  //   message: `${t(t1, "ERR_TELEPHONE_NUMBER_MIN_LENGTH_CHAR")}`,
                  // },
                  // maxLength: {
                  //   value: JURISDICTION_USER_ACTION_FORM_FIELDS.TELEPHONE_MAX_CHAR,
                  //   message: `${t(t1, "ERR_TELEPHONE_NUMBER_MAX_LENGTH_CHAR")}`,
                  // },ERR_INVALID_TELEPHONE_NUMBER
                  validate: (v) => methods?.getValues('telephoneNumber') ? validatePhoneNumber(v?.trimStart().trimEnd(), `${t(t1, 'TELEPHONE_NUMBER')}`, t1, `${t(t1, 'ERR_TELEPHONE_NUMBER_MIN_LENGTH_CHAR')}`, `${t(t1, 'ERR_TELEPHONE_NUMBER_MAX_LENGTH_CHAR')}`, `${t(t1, 'ERR_INVALID_TELEPHONE_NUMBER')}`, '', currentUserTelephone) : null,
                }}
                InputProps={{
                  'aria-required': 'true',
                }}
              />
            </Grid>
            <Grid item md={6}>
              <GridTextfield
                gridMd={12}
                customeClass=""
                className="input-group"
                inputClass=""
                label={t(t1, 'EMAIL_ADDRESS')}
                name={'email'}
                privId={'priv_linkmodal_txt_user_updateemail'}
                rules={{
                  pattern: {
                    value: mailPattern,
                    message: `${t(t1, 'ERR_INVALID_EMAIL_ADDRESS')}`,
                  },
                  validate: (v) => emailIsUnique(v),
                }}
                InputProps={{
                  'aria-required': 'true',
                }}
              />
            </Grid>
          </Grid>
          <Box sx={{ textAlign: 'right', mb: 0 }} className="bottom-btn">
            <CustomizeButton
              variant="outlined"
              csName="rc"
              label={t(t1, 'CANCEL')}
              name="priv_btn_Reset"
              handleClick={handleClose}
            />
            <CustomizeButton
              csName="submitBtn"
              customStyle={{ width: 'auto', fontSize: '14px', mr: 0 }}
              variant="contained"
              disableElevation
              type="submit"
              label={t(t1, 'PROPOSE_BUTTON')}
              name="priv_btn_Continue"
              handleClick={handleClickPropose}
              disabled={disableProposeBtn}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
