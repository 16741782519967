import React, { useEffect, useMemo } from 'react';
import { FormCard } from 'components';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { MUITableGrid } from 'components/MUITableGrid';
import { getCurrentPageName, t } from 'utils/constants';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Style from '../ManageJurisdictionBudget.style';
import { getEffectiveBudgetRecords } from '../../../store/manageJurisdictionBudgetSlice';
import { budgetRecords } from './ColumnSchema';
import { isRolesAssigned, USER_ROLES, USER_ROLE_TYPES } from '../../../utils/constants';

function EffectiveBudgetRecordsCard({ flp }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { effectiveBudgetRecords } = useSelector(({ ManageJurisdictionBudget }) => ManageJurisdictionBudget);
  const dispatch = useDispatch();
  const { id: jurisdictionId } = useParams();
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { isWCIAdmin } = useMemo(
    () => ({
      isWCIAdmin: isRolesAssigned(userDetails, [USER_ROLE_TYPES.SYSTEM_USER], [USER_ROLES.WCI_ADMIN_DEFAULT_ORG]),
    }),
    [userDetails],
  );
  useEffect(() => {
    dispatch(getEffectiveBudgetRecords(jurisdictionId));
  }, [dispatch, jurisdictionId]);
  const column = useMemo(() => budgetRecords(t1, isWCIAdmin, false, effectiveBudgetRecords), [effectiveBudgetRecords, isWCIAdmin, t1]);

  return (
    <FormCard
      title={t(t1, 'EFFECTIVE_BUDGET_RECORDS')}
      indicateRequired={false}
      customeStyle={{ paddingBottom: '40px' }}
    >
      <Box sx={{ ...Style.userRegistrationPage, paddingTop: '16px' }}>
        <MUITableGrid
          fileName={t(t1, 'EFFECTIVE_BUDGET_RECORDS')}
          rows={effectiveBudgetRecords || []}
          columns={column}
          cellClick={false}
          count={5}
          isExport
          isSearch
          isSettings
          enablePrintView
          pinnedColumns={{ left: ['budgetYear'], right: ['QuickActions'] }}
          fieldsMetaData={flp?.metaData}
        />
      </Box>
    </FormCard>
  );
}

export default EffectiveBudgetRecordsCard;
