import React from 'react';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { t, getCurrentPageName } from 'utils/constants';

export default function ButtonFill({
                                     children,
                                     styleSx = {},
                                     handleRegister,
                                     name,
                                     privId = '',
                                     acceptTAndC = null,
                                     saveDisabled = false,
                                   }) {
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user;
  const { t: t1 } = useTranslation(getCurrentPageName());
  const methods = useFormContext();

  let readOnly = false;
  const componentName = privId === '' ? name : privId;
  if (PrivAttributes?.[componentName]?.PrivRole === 'NA') {
    return null;
  }
  readOnly = PrivAttributes?.[componentName]?.PrivRole === 'RO';

  const btnDisable = saveDisabled;

  const submitHandler = async (data) => {
    methods.trigger('confirmEmail');

    handleRegister(data);
  };


  const onClick = (e) => {
    methods?.handleSubmit(submitHandler)(e);
  };
  return (
    <Button

      variant="contained"
      disableElevation
      disabled={btnDisable}
      onClick={onClick}
      id={name}
      sx={{
        py: 1,
        minWidth: 155,
        border: '1px solid',
        borderColor: 'primary.main',
        '&:hover': {
          bgcolor: 'primary.light',
          borderColor: 'primary.light',
        },
        '&.Mui-disabled': {
          border: '1px solid',
          borderColor: 'common.borderButtonColor',
          color: 'common.white',
          backgroundColor: 'common.textLightGray',
          textFillColor: '#fff !important',
        },
        ...styleSx,
      }}
    >
      {children}
    </Button>
  );
}
