import { createSlice } from '@reduxjs/toolkit';
import { MOF_COMPONENTS, MOF_PAGES } from 'pages/OffsetManagement/constants';
import { APIS, getCurrentPageName } from 'utils/constants';
import fetcher, { fetcherGet, fetcherPost } from 'utils/fetcher';
import { getJurisdictionUUID, getPermissionObj } from 'utils/support';
import { setLoader } from './commonSlice';

const initialState = {
  modifiedRecordsForTransfers: { isLoading: false, data: [] },
  editMRTdata: { isLoading: false, data: [] },
  MRThistoryTableData: { isLoading: false, data: [] },
  MRTDetails: { isLoading: false, data: null },
};
const modifiedRecordsForTransfersSlice = createSlice({
  name: 'modifiedRecordsForTransfers',
  initialState,
  reducers: {
    setModifiedRecordsForTransfers: (state, action) => {
      state.modifiedRecordsForTransfers = action.payload;
    },
    setEditMRTdata: (state, action) => {
      state.editMRTdata = action.payload;
    },
    setMRThistoryTableData: (state, action) => {
      state.MRThistoryTableData = action.payload;
    },
    setMRTDetails: (state, action) => {
      state.MRTDetails = action.payload;
    },
  },
});

export default modifiedRecordsForTransfersSlice.reducer;

export const { setModifiedRecordsForTransfers, setEditMRTdata, setMRThistoryTableData, setMRTDetails } =
  modifiedRecordsForTransfersSlice.actions;

export function getAllModifiedRecordsForTransfers(
  pageName = MOF_PAGES.MANAGE_MRT,
  componentName = MOF_COMPONENTS.MANAGE_MRT_COMPO,
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setModifiedRecordsForTransfers({ isLoading: true, data: [] }));
    try {
      const jurisdictionID = getJurisdictionUUID();
      const response = await fetcherGet(`${APIS.ALL_MRT}/${jurisdictionID}`, {}, { pageName, componentName });
      dispatch(setModifiedRecordsForTransfers({ isLoading: false, data: response }));
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setModifiedRecordsForTransfers({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

export function addModifiedRecordForTransfer(payload, pageDetails) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(`${APIS.MRT}`, {
      ...payload,
    }, pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// to get edit prefilled data
export function getEditMRTAPI(
  id,
  pageName = MOF_PAGES.EDIT_MRT,
  componentName = MOF_COMPONENTS.EDIT_MRT_DATA,
) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setEditMRTdata({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.MRT}/${getJurisdictionUUID()}/${id}`, {}, { pageName, componentName });
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setEditMRTdata({
          isLoading: false,
          data: {
            data,
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        }),
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setEditMRTdata({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

// to get MRT history data
export function getMRTHistoryAPI(id, pageName = MOF_PAGES.EDIT_MRT, componentName = MOF_COMPONENTS.MRT_EDIT_HISTORY) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setMRThistoryTableData({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(
        `${APIS.MODIFIED_TRANSFER_RECORD_HISTORY}/${getJurisdictionUUID()}/${id}`,
        {},
        { pageName, componentName },
      );
      dispatch(setMRThistoryTableData({ isLoading: false, data: response }));
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setMRThistoryTableData({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
    }
  };
}

// update the MRT
export function updateMRT(payload, pageDetail) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcher(`${APIS.MRT}`, 'PUT', payload, false, pageDetail).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// get MRT details 
export function getMRTDetails(transferId, entityUUID, pageName = getCurrentPageName(), componentName = 'modifiedTransferRecord') {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setMRTDetails({ isLoading: true, data: [] }));
    try {
      const { data, fieldAccess, metaData } = await fetcherGet(
        `${APIS.MRT_DETAILS.replace('{transferId}', transferId).replace(
          '{entityUUID}',
          entityUUID,
        )}`,
        {},
        { pageName, componentName },
      );
      dispatch(setMRTDetails({
        isLoading: false, data: {
          data, fieldAccess: getPermissionObj(fieldAccess, 'modifiedTransferRecord'), metaData,
        },
      }));
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setMRTDetails({ isLoading: false, data: null }));
      dispatch(setLoader(false));
    }
  };
}
