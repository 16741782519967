import { useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { t, getCurrentPageName } from 'utils/constants';

import { styled } from '@mui/material/styles';
import Style from './TextFieldDescription.style';

export default function TextFieldDescription(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { control, getValues } = useFormContext();
  const {
    formSteps: { isEditField, activeStep },
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { user } = useSelector(({ auth }) => auth);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isInputFocused, setInputFocus] = useState(false);
  const inputElRef = useRef(null);
  const { PrivAttributes } = user; // Here we get fields permission like hidden, read Only or write

  const {
    name = '',
    readOnlyInput = false,
    type = 'text',
    label = '',
    subLabel = '',
    defaultValue = '',
    rules,
    placeholder,
    InputProps,
    gridMd = 4,
    customClass = '',
    inputClass = '',
    multiline = false,
    disabled = false,
    privId = '',
    isRequiredMsg = '',
  } = props;


  let readOnly = false;
  if (privId === '') {
    if (PrivAttributes?.[name]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[name]?.PrivRole === 'RO';
  } else {
    if (PrivAttributes?.[privId]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[privId]?.PrivRole === 'RO';
  }
  if (rules && label && rules.required === true) {
    rules.required = `${t(t1, isRequiredMsg)}`;
  }
  if (readOnlyInput) {
    readOnly = true;
  }

  return (
    <div className="textbox-detail">
      {/* <TextField id="outlined-size-small" label={t(t1, "ENTER_AN_EMAIL_ADDRESS")} size="small" /> */}
      <Controller
        name={name}
        type={type}
        defaultValue={defaultValue}
        rules={rules}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            disabled={readOnly}
            multiline={multiline}
            sx={Style.formField}
            type={type}
            className={inputClass}
            id={name}
            variant="outlined"
            placeholder={placeholder}
            InputProps={{ ...InputProps, inputProps: { 'data-testid': name } }}
            value={defaultValue}
            error={Boolean(fieldState.error)}
            helperText={fieldState?.error?.message || ''}
            {...field}
          />
        )}
      />
      {label ? <Typography variant="p">{label}</Typography> : null}
    </div>
  );
}
