const Style = {
  deleteBtn: {
    stroke: '#283459',
    strokeWidth: 4,
  },
  buttons: { minWidth: '100px', width: 'auto', fontSize: '14px', py: '8px' },
  modalHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  modalHeadingText: { margin: 'auto 0px', color: '#000000', fontSize: '24px', fontWeight: '300' },
  labelStyle: {
    border: '1px solid',
    cursor: 'pointer',
    borderRadius: '5px',
    boxShadow: 'none',
    minWidth: '150px',
    minHeight: '40px',

  },
  mainBox: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  box1: {
    border: '1px solid #aaaaaa',
    height: '100%',
    width: '100%',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '20px',
  },

  customButton: { minWidth: '80px', width: '80px', minHeight: '30px', height: '30px', fontSize: '14px', py: '8px' },
  uploadedFileNameTypograpgy: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
};
export default Style;
