import { useMemo } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ErrorIcon from '@mui/icons-material/Error';

import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Tooltip, tooltipClasses } from '@mui/material';
import {
  t,
  months,
  getCurrentPageName,
  checkIsReadOrWrite,
  checkIsRequired,
  PERMISSION_NAMES,
  isEmpty,
} from 'utils/constants';
import styled from '@emotion/styled';
import Style from './GridSelect.style';

export default function GridSelect(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { control, getValues } = useFormContext();
  const {
    formSteps: { isEditField, activeStep },
    userProfileEdit,
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user; // Here we get fields permission like hidden, read Only or write

  const {
    name,
    type = 'text',
    label,
    rules,
    noValueText,
    options,
    stepNumber,
    InputProps,
    onChange,
    onClose,
    defaultValue,
    gridMd = 4,
    className = '',
    minHeight = '',
    isViewOnly = '',
    RevisionDate = '',
    isShowOld = false,
    disabled = false,
    customeStyle = '',
    noValidate = false,
    customTextFieldStyle = {},
    /* Conflict Resolution Candidate
        privId = ""
      } = props;

      const getUserDetails = useSelector(({ userHome }) => userHome.userDetails);

      const d = RevisionDate ? new Date(RevisionDate) : new Date();
      const revisionDate = `${t(t1, "REVISION_MADE_ON")} ${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
     */
    privId = '',
    viewName = false,
    customTextClassName = '',
    value: fieldValue,
    noStyleForPlaceholder = false,
    componentName,
    fieldName,
    boxLabelClass = '',
    viewOnlyValue = null,
    labelStyle = {},
    customMenuProps,
  } = props;

  const { fieldPermissions } = useSelector(({ entityApplication }) => entityApplication);
  const isReadOnlyisReadOnly = useMemo(() => {
    return checkIsReadOrWrite(fieldPermissions, componentName, fieldName) === PERMISSION_NAMES.READ;
  }, [fieldPermissions]);
  const isRequired = useMemo(() => {
    return checkIsRequired(fieldPermissions, componentName, fieldName) === PERMISSION_NAMES.MANDATORY;
  }, [fieldPermissions]);


  const getUserDetails = useSelector(({ userHome }) => userHome.userDetails);
  let getValueforID = options?.find(item => item?.ID === getValues(name))?.Name;
  getValueforID = getValueforID !== undefined ? getValueforID : options?.find(item => item?.value === getValues(name))?.title;
  let content;
  if (viewName) {
    content = getValueforID;
  } else {
    content = (name === 'month' || name === 'Month') ? months[getValues(name) - 1] : getValues(name) || 'N/A';
  }

  const d = new Date(RevisionDate);
  const revisionDate = RevisionDate && `${t(t1, 'REVISION_MADE_ON')} ${months[d?.getMonth()]} ${d?.getDate()}, ${d?.getFullYear()}`;

  let readOnly = false;
  if (privId === '') {
    if (PrivAttributes?.[name]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[name]?.PrivRole === 'RO';
  } else {
    if (PrivAttributes?.[privId]?.PrivRole === 'NA') {
      return null;
    }
    readOnly = PrivAttributes?.[privId]?.PrivRole === 'RO';
  }

  const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        padding: '1px 7px',
        border: '1px solid #000',
        borderRadius: '0px',
      },
    }),
  );


  if (!isEmpty(componentName) && !isEmpty(fieldName)) {
    readOnly = isReadOnlyisReadOnly;
    if (!isRequired && !noValidate) {
      rules.required = false;
      delete rules.required;
    }
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 225,
      },
    },
  };

  return (
    <Grid item md={gridMd} className={className} sx={{ minHeight: minHeight || '', ...customeStyle }}>
      {/* Conflict Resolution Candidate
      <InputLabel htmlFor="customize-select" sx={Style.FieldLabel}>
        {label}
        {rules?.required ? (
          <Typography
            variant="body2"
            sx={{
              display: "inline-block",
              color: "common.red",
              ml: 0.3,
              fontWeight: 700,
            }}
          >
            {label}
            {rules?.required ? (
              <Typography
                variant="body2"
                sx={{
                  display: "inline-block",
                  color: "#CA2C1C",
                  ml: 0.3,
                  fontWeight: 700,
                  mt: 0,
                }}
              >
                *
              </Typography>
            ) : null}
          </InputLabel>
        ) : null}
      </InputLabel>
      {(!isEditField[stepNumber] && activeStep === 4) || isViewOnly || readOnly ? (
        <Typography variant="body2" sx={Style.valuetext}>
          {name === "month" || name === "Month" ? months[getValues(name) - 1] :
            getValues(name) || "N/A"}
      */}
      <Box component="div" className={boxLabelClass || 'label-wrapper'} sx={labelStyle}>
        {label ? (
          <InputLabel disabled={disabled} htmlFor="customize-select" sx={{ ...Style?.FieldLabel, ...customeStyle }}>
            {label}
            {rules?.required ? (
              <Typography
                variant="body2"
                sx={{
                  display: 'inline-block',
                  color: 'common.red',
                  ml: 0.3,
                  fontWeight: 700,
                  mt: 0,
                }}
              >
                *
              </Typography>
            ) : null}
          </InputLabel>
        ) : null}</Box>
      {(!isEditField[stepNumber] && activeStep === 4) || isViewOnly || readOnly ? (
        <Typography
          variant="body2"
          sx={Style.valuetext}
          className={customTextClassName}
        >
          {/* {name === "month" || name === "Month" ? months[getValues(name) - 1] :
            getValues(name) || "N/A"} */}
          {viewOnlyValue || fieldValue || content}
        </Typography>
      ) : (
        <Controller
          name={name}
          type={type}
          rules={rules}
          control={control}
          render={({ field, fieldState }) => (
            <>
              <Select
                onClose={onClose}
                disabled={disabled}
                sx={[Style.formField, customTextFieldStyle]}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  if (onChange) onChange(e);
                }}
                className={`${className}-select`}
                displayEmpty
                id={name}
                // InputProps={{ ...InputProps,inputProps:{"data-testid": name}}}
                inputProps={{ 'data-testid': noValueText, ...InputProps }}
                htmlFor="customize-select"
                error={Boolean(fieldState.error)}
                MenuProps={customMenuProps || MenuProps}
              >
                <MenuItem
                  value={''}
                  disabled
                  style={Style.placeholder}
                >
                  <div style={!noStyleForPlaceholder ? Style.noValueText : null}>{noValueText}</div>
                </MenuItem>
                {options?.map((option) => (
                  <MenuItem

                    key={option.value}
                    value={option.value}
                    style={{ ...customeStyle }}
                  >
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
              {fieldState?.error?.message ? <FormHelperText error>{fieldState?.error?.message}</FormHelperText> : null}
            </>
          )}
        />
      )}
      {isViewOnly && isShowOld && (
        <StyledTooltip
          title={
            <Typography
              color="black"
              sx={{ fontSize: '14px' }}
            >
              {revisionDate}
            </Typography>
          }
        >
          <Box
            mt={1 / 4}
            sx={Style.updatedFieldBox}
          >
            <ErrorIcon sx={{ color: '#4d8da4', fontSize: '20px', mx: 1 / 4 }} />
            <Box sx={Style.coloredBox}>
              <Typography
                sx={{
                  overflow: 'hidden',
                  wordWrap: 'break-word',
                  textOverflow: 'ellipsis',
                }}
              >
                {isShowOld}
              </Typography>
            </Box>
          </Box>
        </StyledTooltip>
      )}
    </Grid>
  );
}
