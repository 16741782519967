import { Loadable } from 'components/Loadable';
import { lazy } from 'react';

const ManageJurisdictionAccounts = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.ManageJurisdictionAccounts,
    })),
  ),
);

const EditJurisdictionAccounts = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.EditJurisdictionAccounts,
    })),
  ),
);

const JurisdictionAccountDetails = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.JurisdictionAccountDetails,
    })),
  ),
);

export const manageJurisdictionAccountsRoutes = {
  path: '/manage-jurisdiction-accounts',
  children: [
    {
      path: ':jurisdictionId',
      index: true,
      element: <ManageJurisdictionAccounts />,
    },
    {
      path: 'edit/:jurisdictionId/:accId',
      element: <EditJurisdictionAccounts />,
    },
    {
      path: 'details/:jurisdictionId/:accId',
      element: <JurisdictionAccountDetails />,
    },
  ],
};
