import { Box, Typography, styled } from '@mui/material';

export const StyledTitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(3),
}));

export const StyledTitleContainerPreview = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(2.5),
}));

export const StyledForm = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.35)',
  border: `1px solid ${theme.palette.common.GrayColor}`,
  margin: theme.spacing(2.5, 0),
  padding: theme.spacing(2.5, 2.5, 2.5, 2.5),
  paddingBottom: '40px',
}));

export const StyledCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  margin: theme.spacing(0, 0, 0, 0),
  fontWeight: 300,
  color: theme.palette.common.dark,
}));

export const StyledRequiredAstrik = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.common.red,
  margin: theme.spacing(0, 0.5, 0, 0.5),
  fontWeight: 700,
}));