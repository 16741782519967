const Style = {
  deleteIcon: {
    stroke: '#4D8DA4',
    strokeWidth: 4,
  },
  CustomChip: {
    '& .MuiButtonBase-root': {
      '& .MuiChip-label': {
        color: 'common.textColor',
        px: 0,
        pl: 1,
      },
      '& .MuiChip-deleteIcon': {
        fontSize: 12,
        color: 'common.warning',
        mx: 1,
      },
    },
  },
};
export default Style;
