import React, { useCallback, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { FormCard, GridTextfield } from 'components';
import { FormProvider } from 'components/FormProvider';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BATCH_TRANSFER_STATUS, getCurrentPageName, t, COMMENT_MAX_CHAR_LENGTH } from 'utils/constants';
import { setModelMessage } from 'store/commonSlice';
import { GridRadioButton } from 'components/GridRadioButton';
import { CustomizeButton } from 'components/CustomizeButton';
import PassphraseConfirmationDialog from 'pages/WiringInstructions/components/PassphraseConfirmationDialog';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getBatchHoldings,
  getBatchTransferAccountDetails,
  getBatchTransferHistory,
  getBatchandAccountDetailsById,
  transferStatusApproved,
  transferStatusUpdate,
} from 'store/batchTransferSlice';
import { CustomeDialogMessage } from 'components/CustomeDialogMessage';
import { getStatusUpdateErrorMessage } from 'pages/ManageBatchTransfer/constants';

/**
 * Approve/Deny Section in a Batch Exemptions Details for Jurisdiction Authority
 * @returns {ReactElement}
 */
function ApproveRequest({ transferType }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { t: t1 } = useTranslation(getCurrentPageName());
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {},
  });
  const { statuses } = useSelector(({ batchTransfer }) => batchTransfer);
  const [passphraseDialog, setPassphraseDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { handleSubmit, reset, control, watch, getValues, formState } = methods;
  const values = watch();

  const onSubmit = useCallback(() => {
    setPassphraseDialog(true);
  }, []);

  const radioOptions = useMemo(
    () =>
      [BATCH_TRANSFER_STATUS.REVISIONS_REQUESTED, BATCH_TRANSFER_STATUS.APPROVED, BATCH_TRANSFER_STATUS.DENIED].map(
        (status) => ({
          name: t(t1, `${status}_RADIO_LABEL`),
          value: statuses[status]?.ID,
          isDisplay: true,
        }),
      ),
    [statuses, t1],
  );

  // Approve/Deny/RequestRevision Handler
  const handleAction = useCallback(() => {
    setPassphraseDialog(false);
    const payload = getValues();
    const selectedStatus = Object.values(statuses).find((status) => status?.ID === payload.status)?.Name;
    if (selectedStatus === BATCH_TRANSFER_STATUS.APPROVED) {
      dispatch(transferStatusApproved({ id: params?.id, transferTypeId: transferType?.id, ...payload }))
        .then(() => {
          dispatch(
            setModelMessage({
              title: t(t1, 'SUCCESS_TITLE'),
              message1: t(t1, `${selectedStatus}_SUCCESS`),
            }),
          );
          reset({});
          dispatch(getBatchandAccountDetailsById(params.id));
          dispatch(getBatchHoldings(params.id));
          dispatch(getBatchTransferAccountDetails(params.id));
          dispatch(getBatchTransferHistory(params.id));
        })
        .catch((e) => {
          dispatch(
            setModelMessage({
              error: true,
              title: t(t1, 'ERROR_TITLE'),
              multipleErrorKeys: getStatusUpdateErrorMessage(e?.response?.data?.data, t1),
            }),
          );
        });
    } else {
      dispatch(transferStatusUpdate({ id: params?.id, transferTypeId: transferType?.id, ...payload }))
        .then(() => {
          dispatch(
            setModelMessage({
              title: t(t1, 'SUCCESS_TITLE'),
              message1: t(t1, `${selectedStatus}_SUCCESS`),
            }),
          );
          reset({});
          dispatch(getBatchandAccountDetailsById(params.id));
          dispatch(getBatchHoldings(params.id));
          dispatch(getBatchTransferAccountDetails(params.id));
          dispatch(getBatchTransferHistory(params.id));
        })
        .catch((e) => {
          dispatch(
            setModelMessage({
              error: true,
              title: t(t1, 'ERROR_TITLE'),
              message1: t(t1, e?.response?.data?.data),
            }),
          );
        });
    }
  }, [getValues, statuses, dispatch, params.id, t1, reset, transferType?.id, navigate]);

  return (
    <>
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormCard
          indicateRequired={false}
          title={t(t1, 'APPROVE_REQUEST')}
          customeStyle={{ paddingBottom: '20px', marginBottom: '20px' }}
        >
          <Grid
            container
            spacing={3.75}
          >
            <Controller
              control={control}
              name="status"
              rules={{ required: true }}
              render={({ field }) => (
                <GridRadioButton
                  name="status"
                  gridMd={3}
                  labelSx={{
                    padding: 0,
                    fontSize: '14px',
                    lineHeight: '1.5',
                    fontWeight: '400 !important',
                  }}
                  sx={{
                    marginTop: 2,
                    '& .MuiFormControlLabel-root': { marginBottom: 1 },
                    '& .MuiSvgIcon-root path': {
                      transform: 'scale(1.08)',
                    },
                  }}
                  LabelShowAsName
                  onRadioChange={field.onChange}
                  selectedRadio={field.value}
                  radios={radioOptions}
                  customTrigger={() => {
                    if (watch('status') && methods.getValues('comment') <= COMMENT_MAX_CHAR_LENGTH) {
                      methods.clearErrors('comment');
                    }
                  }}
                />
              )}
            />

            <GridTextfield
              gridMd={9}
              type="text"
              multiline
              rows={2}
              name="comment"
              label={t(t1, 'COMMENTS_LABEL')}
              placeholder={t(t1, 'COMMENTS_PLACEHOLDER')}
              rules={{
                required: values.status && values.status !== statuses[BATCH_TRANSFER_STATUS.APPROVED]?.ID,
                maxLength: {
                  value: COMMENT_MAX_CHAR_LENGTH,
                  message: `${t(t1, 'ERR_COMMENTS_MAX_LENGTH_CHAR')}`,
                },
              }}
              isRequiredMsg={'ERR_COMMENTS_IS_REQUIRED'}
              trimLeadingSpaces
            />
          </Grid>
        </FormCard>
        <Grid
          container
          justifyContent="end"
        >
          <Grid item>
            <CustomizeButton
              variant="outlined"
              csName="rc"
              handleClick={() => {
                setOpenConfirmDialog(true);
              }}
              label={t(t1, 'CANCEL_BUTTON')}
            />
          </Grid>
          <Grid item>
            <CustomizeButton
              customStyle={{ marginLeft: '8px' }}
              disabled={!formState.isValid}
              type="submit"
              label={t(t1, 'SUBMIT_BUTTON')}
            />
          </Grid>
        </Grid>
      </FormProvider>
      <CustomeDialogMessage
        warning
        title={t(t1, 'CANCEL_MODAL_TITLE')}
        continueBtn={t(t1, 'CONTINUE')}
        open={openConfirmDialog}
        handleModalCancel={() => {
          setOpenConfirmDialog(false);
        }}
        handleClose={() => {
          setOpenConfirmDialog(false);
        }}
        message1={t(t1, 'CANCEL_MODAL_MESSAGE')}
        buttonMessage={t(t1, 'CANCEL_MODAL_DESCRIPTION')}
        handleContinue={() => {
          navigate(-1);
        }}
        borderColor="#CA2C1C"
      />
      <PassphraseConfirmationDialog
        title={t(t1, 'SUBMIT_BATCH_TRANSFER_DECISION')}
        open={passphraseDialog}
        pageName=""
        confirmButtonLabel={t(t1, 'SUBMIT_BUTTON')}
        onPassphraseFailure={() => setPassphraseDialog(true)}
        handleCloseDialog={() => setPassphraseDialog(false)}
        onPassphraseSuccess={handleAction}
      />
    </>
  );
}

export default ApproveRequest;
