const Style = {
  FieldLabel: {
    fontSize: '0.875rem!important',
    color: 'common.textColor',
    fontWeight: 700,
    overflow: 'auto',
    whiteSpace: 'normal',
    textOverflow: 'initial',
    mb: 0.5,
    span: {
      fontWeight: 600,
      ml: 0.5,
      color: 'common.textGray',
    },
    '&.grid-text-label': {
      fontWeight: '600',
    },
    '&.MuiInputLabel-root': {
      fontWeight: 700,
      /* Conflict Resolution Candidate
      "&:empty": {
        height: "20px"
      }
 */
      // height:"40px",
      // display:"flex",
      // alignItems:"flex-end",
      whiteSpace: 'normal',
    },
  },
  commentLabel: { display: 'inline-block', color: 'common.textColor', fontWeight: 700 },
  formField: {
    width: 1,
    color: 'common.black',
    '.MuiFormHelperText-root': {
      mx: 0,
      py: 0,
      lineHeight: '1.30',
    },
    input: {
      fontSize: '14px',
      '&::-webkit-outer-spin-button': {
        // WebkitAppearance: "none",
        margin: '0',
      },
      '&::-webkit-inner-spin-button': {
        // WebkitAppearance: "none",
        margin: '0',
      },
      '&[type=number]': {
        // MozAppearance: "textfield",
      },
    },
    // ".MuiInputBase-root.Mui-focused": {
    //   border: "0px solid #333333",
    // },
    '&.fc-text': {
      '& .MuiOutlinedInput-root': {
        borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
        background: '#fff',
        minHeight: '40px',
        py: 0,
        px: '10px',
        borderRadius: '4px',
        fontSize: '16px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '4px',
      },
    },

    '&.fc-textarea': {
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        p: 0,
      },
      '& .MuiOutlinedInput-input': {
        background: '#fff',
        py: '5px',
        px: '10px',
        borderRadius: 0,
        fontSize: '16px',
        minHeight: '156px',
        height: '156px !important',
        overflowY: 'auto !important',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
      },
      '& .comment-label': {
        color: 'common.textColor',
        fontWeight: 700,
      },
      '&.fc-full': {
        width: '100%',
        marginBottom: '20px',
        '& .MuiOutlinedInput-input': {
          minHeight: '90px !important',
          height: '90px !important',
          width: '100%',
        },
      },
    },

    '.MuiOutlinedInput-input': {
      /* Conflict Resolution Candidate
      p: 0.6,
 */
      p: 0.62,
      fontSize: '0.875rem',
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: '4px',
      borderColor: 'common.GrayColor',
    },
    '.MuiOutlinedInput-root': {
      fontSize: '14px',
      '&.MuiInputBase-root.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: '#333',
          borderWidth: 1,
        },
      },
    },

    '&.password-tooltip .MuiTooltip-popper': {
      inset: '-47px auto auto 0px !important',
    },
    '&.password-tooltip .Mui-error .MuiTooltip-popper': {
      inset: '-71px auto auto 0px !important',
    },
    '&.password-tooltip.password-label .MuiTooltip-popper': {
      inset: '-40px auto auto 0px !important',
    },
    '&.password-tooltip.password-label .Mui-error .MuiTooltip-popper': {
      inset: '-61px auto auto 0px!important',
    },

    '&.password-tooltip-user .MuiTooltip-popper': {
      inset: '-83px auto auto 0px !important',
    },
    '&.password-tooltip-user .Mui-error .MuiTooltip-popper': {
      inset: '-107px auto auto 0px !important',
    },
    '&.password-tooltip-user.password-label .MuiTooltip-popper': {
      inset: '-78px auto auto 0px !important',
    },
    '&.password-tooltip-user.password-label .Mui-error .MuiTooltip-popper': {
      inset: '-100px auto auto 0px!important',
    },
  },
  valuetext: {
    textOverflow: 'hidden',
    overflow: 'hidden',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    // paddingLeft: "4px",
  },
  NAText: {
    textOverflow: 'hidden',
    overflow: 'hidden',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    color: '#333333',
  },
  popperStyle: {
    px: 0.5,
    display: 'flex',
    alignItems: 'center',
    // textAlign: 'justify',
    color: 'theme.text.primary',
    fontSize: 13,
    '& span > span': {
      display: 'block',
      background: '#ededed',
      padding: '2px 10px',
      borderRadius: '4px',
      margin: '5px 0',
    },
    '& .MuiSvgIcon-root': {
      height: '14px',
      width: '14px',
    },
    '& .MuiTooltip-tooltip': {
      maxWidth: '412px',
      width: '100%',
    },
  },
  updatedFieldBox: { display: 'flex', justifyContent: 'start', alignItems: 'center' },
  coloredBox: {
    padding: '2px 4px',
    width: '85%',
    border: '1.5px solid',
    borderColor: 'common.warning',
    color: 'common.warning',
    '& .MuiTypography-root': { fontWeight: 600, fontSize: '14px' },
  },
};

export default Style;
