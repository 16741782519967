import authRoles from 'core/auth/authRole';
import JurisdictionUserHome from './JurisdictionUserHome';

const JurisdictionUserHomeConfig = {
  route: {
    path: '/jurisdiction-user',
    element: <JurisdictionUserHome />,
  },
  layout: {
    header: {
      display: true,
    },
    sidebar: {
      display: false,
    },
    footer: {
      display: true,
    },
  },
  auth: authRoles.onlyGuest,
};

export default JurisdictionUserHomeConfig;
