import {
  activeUserLogin,
  userActiveDeactiveLogin,
  userCloseAccount,
  userInactiveProfile,
  userResetPassphraseAndSecurityQA,
  userUnblockLockedUser,
  userUpdateEmailOrTelephone,
} from 'store/userSlice';
import { ActiveStatus, InactiveStatus } from './constants';

export const handleUpdateEmailTelephoneDispatchFun = (dispatch, UID, modifiedby, telephoneNumber, email, navigate, orgId, isAPICall = false) => {
  const updatePayload = {
    action_update_email_and_telephone: true,
    UID: `U#${UID}`,
    modifiedby: `U#${modifiedby}`,
    telephoneNumber,
    email,
  };
  const flpData = { pageName: 'Page_JurisdictionUserHome', componentName: 'actionUpdateEmailTelephone' };
  dispatch(userUpdateEmailOrTelephone(updatePayload, navigate, orgId, isAPICall, flpData));
};

export const handleResetPassphraseAndSecurityQAFun = (
  dispatch,
  selectedUID,
  UID,
  navigate,
  orgId,
  isAPICall = false,
) => {
  dispatch(userResetPassphraseAndSecurityQA(selectedUID, navigate, orgId, isAPICall));
};

export const handleUnblockDispatchFun = (dispatch, UID, modifiedby, navigate, orgId, isAPICall = false) => {
  const unblockUserPayload = {
    action_update_user_account_block_status: true,
    UID: `U#${UID}`,
    modifiedby: `U#${modifiedby}`,
  };
  const flpData = { pageName: 'Page_JurisdictionUserHome', componentName: 'actionUpdateUserAccountBlockStatus' };
  dispatch(userUnblockLockedUser(unblockUserPayload, navigate, orgId, isAPICall, flpData));
};

export const handleInactiveDispatchFun = (
  dispatch,
  username,
  UID,
  modifiedby,
  reasons,
  navigate,
  orgId = '',
  isAPICall = false,
) => {
  const inactiveUserPayload = {
    username,
    enable: false,
    inactive_access: true,
    UID: `U#${UID}`,
    modifiedby: `U#${modifiedby}`,
    Reasons: reasons,
  };
  // console.log(inactiveUserPayload,"inactiveUserPayload");
  dispatch(userInactiveProfile(inactiveUserPayload, {}, '', navigate, orgId, isAPICall));
};

export const handleCloseAccountDispatchFun = (
  dispatch,
  UID,
  modifiedby,
  reasons,
  navigate,
  orgId,
  isAPICall = false,
) => {
  const closeAccountPayload = {
    action_update_user_account_close_status: true,
    UID: `U#${UID}`,
    modifiedby: `U#${modifiedby}`,
    Comment: reasons,
  };
  const flpData = { pageName: 'Page_JurisdictionUserHome', componentName: 'actionUpdateUserAccountCloseStatus' };
  dispatch(userCloseAccount(closeAccountPayload, {}, '', navigate, orgId, isAPICall, flpData));
};

export const handleActiveDeactiveDispatchFun = (
  dispatch,
  UID,
  modifiedby,
  reason,
  navigate,
  orgId,
  isAPICall = false,
) => {
  const flpData = { pageName: 'Page_JurisdictionUserHome', componentName: 'actionUpdateAccountStatus' };
  const activeDeactivePayload = {
    action_update_account_status: true,
    UID: `U#${UID}`,
    modifiedby: `U#${modifiedby}`,
    status: InactiveStatus,
    DenialReasons: reason,
  };
  dispatch(userActiveDeactiveLogin(activeDeactivePayload, {}, '', navigate, orgId, isAPICall, flpData));
};

export const handleActiveDispatchFun = (dispatch, UID, modifiedby, navigate, orgId, isAPICall = false) => {
  const activateUserPayload = {
    action_update_account_status: true,
    UID: `U#${UID}`,
    modifiedby: `U#${modifiedby}`,
    status: ActiveStatus,
  };
  const flpData = { pageName: 'Page_JurisdictionUserHome', componentName: 'actionUpdateAccountStatus' };
  dispatch(activeUserLogin(activateUserPayload, {}, '', navigate, orgId, isAPICall, flpData));
};