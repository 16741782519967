import { isEqual } from 'lodash';

// TODO: need to revisit

export const INITIAL_USER_COMPONENTS = {
  INITIAL_USERS: 'InitialUsers',
  ADD_INITIAL_USER: 'AddInitialUser',
  EDIT_INITIAL_USER: 'EditInitialUser',
  GET_INITIAL_USER_DETAIL: 'UserDetail',
  INITIAL_USER_HISTORY: 'InitialUserHistory',
};

export const INITIAL_USER_PAGES = {
  MANAGE_INITIAL_USERS: 'Page_ManageInitialUser',
  ADD_INITIAL_USER_PAGE: 'Page_AddInitialUser',
  EDIT_INITIAL_USER_PAGE: 'Page_EditInitialUser',
};

export const ACTIVATION = 'ACTIVATION';
export const DEACTIVATION = 'DEACTIVATION';

const rolesToDisplay = ['REGISTRAR', 'AUTHORITY', 'JURISDICTION_SYSTEM_ADMIN'];
export const getFilteredRoles = (roles) => roles?.filter((role) => rolesToDisplay.includes(role.value));

export const shouldSubmitBtnDisable = (prevValues, ...[curr]) => {
  if (
    !isEqual(prevValues?.firstName, curr[0]) ||
    !isEqual(prevValues?.lastName, curr[1]) ||
    !isEqual(prevValues?.emailAddress, curr[2]) ||
    !isEqual(prevValues?.roleName, curr[3]?.value)
  )
    return false;
  return true;
};

// func to make readable str: example: COMPLIANCE_TRANSFER -> Compliance Transfer
export function convertToReadableFormat(inputString) {
  const words = inputString.split('_');
  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  // Join the words to form the final readable string
  const result = capitalizedWords.join(' ');
  return result;
}

export const transformRolesRes = (data) =>
  data?.map((item) => ({
    id: item?.id,
    value: item?.name,
    name: convertToReadableFormat(item?.name),
  }));

function transformRoleString(str) {
  // Check if the string is already in uppercase, return as is
  if (str === str.toUpperCase()) {
    return str;
  }
  // Split the string into words, capitalize each word, and join them with underscores
  const transformedStr = str
    .split(' ')
    .map((word) => word.toUpperCase())
    .join('_');

  return transformedStr;
}

export const transformEditInitailUser = (data) => data?.map(item => ({
  ...item,
  roleName: transformRoleString(item?.roleName),
}));
