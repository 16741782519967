import { Loadable } from 'components/Loadable';
import RoleBasedGuard from 'guards/RoleBasedGuard';
import { lazy } from 'react';

const EventApplicationListing = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.EventApplicationListing,
    })),
  ),
);
const EventApplicationDetail = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.EventApplicationDetail,
    })),
  ),
);
export const eventApplicationRoutes = {
  path: '/event-applications',
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={'priv_lnk_event_applications'}>
          <EventApplicationListing />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'entity/:entityId/application/:applicationId',
      element: (
        <RoleBasedGuard privId={'priv_lnk_event_applications'}>
          <EventApplicationDetail />
        </RoleBasedGuard>
      ),
    },
  ],
};
