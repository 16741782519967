import { createSlice } from '@reduxjs/toolkit';
import { jurisdictionInfoMockData } from 'pages/ManageTransfers/__mocks__/manageTransfersMock';
import { fetcherGet, fetcherPost } from 'utils/fetcher';
import { APIS } from 'utils/constants';
import { setLoader } from './commonSlice';

const initialState = {
  jurisdictionInfoData: { isLoading: false, data: [] },
  interJurisdictionalTransfersData: { isLoading: false, data: [] },
  crossJurisdictionalTransfersData: { isLoading: false, data: [] },
  conflictFlagAllLinkedJuri: { isLoading: false, data: null },

  transferTypeCard: null,
  ijTransfersDefaultValues: {
    ij_proposed_status_within_entity: true,
    ij_proposed_status_jurisdiction_entity: true,
    ij_approved_status_within_entity: true,
    ij_approved_status_jurisdiction_entity: true,
    ij_stop_all_future_transfers: false,
    ij_stop_all_transfers_in_progress: false,
    ij_effective_date: null,
  },
  cjTransfersDefaultValues: {
    cj_stop_all_future_transfers: false,
    cj_stop_all_transfers_in_progress: false,
    cj_saved_status_administrative_transfer: true,
    cj_proposed_status_administrative_transfer: true,
    cj_effective_date: null,
    cj_saved_status_batch_transfer: true,
    cj_proposed_status_batch_transfer: true,
    cj_proposed_status_entity_transfer: true,
    cj_approved_status_entity_transfer: true,
  },
};

const managetransfersSlice = createSlice({
  name: 'manageTransfers',
  initialState,
  reducers: {
    setJuriInfo: (state, action) => {
      state.jurisdictionInfoData = action.payload;
    },
    setTransferTypeCard: (state, action) => {
      state.transferTypeCard = action.payload;
    },
    setInterJuriTransfersData: (state, action) => {
      state.interJurisdictionalTransfersData = action.payload;
    },
    setCrossJuriTransfersData: (state, action) => {
      state.crossJurisdictionalTransfersData = action.payload;
    },
    setConflictFlagAllLinkedJuri: (state, action) => {
      state.conflictFlagAllLinkedJuri = action.payload;
    },
  },
});

export default managetransfersSlice.reducer;

export const {
  setJuriInfo,
  setTransferTypeCard,
  setInterJuriTransfersData,
  setCrossJuriTransfersData,
  setConflictFlagAllLinkedJuri,
} = managetransfersSlice.actions;

export function getJurisdictionsInfoAPI(id, pageName) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setJuriInfo({ isLoading: true }));
    try {
      dispatch(setLoader(false));
      //   const response = await fetcherGet(`${APIS.API_TRANSFER_HISTORY}/${id}`, {}, pageDetails);
      const response = jurisdictionInfoMockData;
      dispatch(setJuriInfo({ isLoading: false, data: response.data }));
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setJuriInfo({ isLoading: false, data: [] }));
    }
  };
}

// fetch inter-jurisdictional transfers API
export function getInterJuriTransfersAPI(jurisdictionId, pageDetails = {}) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setInterJuriTransfersData({ isLoading: true }));
    try {
      dispatch(setLoader(false));
      const response = await fetcherGet(
        `${APIS.GET_INTER_JURISDICTIONAL_TRANSFERS}/${jurisdictionId}`,
        {},
        pageDetails,
      );
      dispatch(setInterJuriTransfersData({ isLoading: false, data: response.data }));
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setInterJuriTransfersData({ isLoading: false, data: [] }));
    }
  };
}

// fetch cross-jurisdictional transfers API
export function getCrossJuriTransfersAPI(fromJurisdictionId, toJurisdictionId, pageDetails = {}) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setCrossJuriTransfersData({ isLoading: true }));
    try {
      dispatch(setLoader(false));
      const response = await fetcherGet(
        `${APIS.GET_CROSS_JURISDICTIONAL_TRANSFERS}/${fromJurisdictionId}/${toJurisdictionId}`,
        {},
        pageDetails,
      );
      dispatch(setCrossJuriTransfersData({ isLoading: false, data: response.data }));
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setCrossJuriTransfersData({ isLoading: false, data: [] }));
    }
  };
}

// POST to save inter-cross jurisdictional transfers changes
export function saveInterCrossJurisdictionAPI(payload) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    return fetcherPost(`${APIS.SAVE_INTER_CROSS_JURISDICTION}`, payload).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

// fetch flag to know the conflict
export function getConflictFlagAllLinkedJuriAPI(payload, pageDetails = {}) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setConflictFlagAllLinkedJuri({ isLoading: true, data: null }));
    try {
      dispatch(setLoader(false));
      const response = await fetcherPost(APIS.GET_CONFLICT_FLAG, payload, pageDetails);
      dispatch(setConflictFlagAllLinkedJuri({ isLoading: false, data: response.data }));
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setConflictFlagAllLinkedJuri({ isLoading: false, data: null }));
    }
  };
}
