export const JURISDICTION_BUDGET_COMPONENTS_FLP = {
  JURISDICTION_BUDGET_YEARS: 'getBugetYears',
  JURISDICTION_BUDGET_DETAILS: 'getBudgetDetails',
  JURISDICTION_BUDGET_PROPOSED_RECORDS: 'getProposeBudgetRecords',
  JURISDICTION_BUDGET_HISTORY: 'getBudgetRecordHistory',
  JURISDICTION_BUDGET_RECORD: 'jurisdictioBudgetRecord',
  JURISDICTION_BUDGET: 'jurisdictionBudget',
};
export const getFLPHeaders = (componentName) => ({ pageName: 'Page_ManageJurisdictionBudget', componentName });

export const flattenJurisdictionBudgetStatusObject = (data) =>
  data.map((item) => ({
    ...item,
    budgetStatusName: item?.JurisdictionBudget?.Status?.Name,
  }));