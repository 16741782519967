import { createSlice } from '@reduxjs/toolkit';
import { APIS } from 'utils/constants';
import fetcher, { fetcherGet, fetcherPost } from 'utils/fetcher';
import logger from 'utils/logger';
import {
  getJurisdictionUUID,
  getPermissionObj,
  transFormVintageYearToDropdownOptions,
  transformEntityTransfersHoldings,
  transformHoldingsViewRes,
  transformMCIApprovalsRes,
  transformTransferRes,
  transformHoldingsResReplenishment,
} from 'utils/support';
import { setLoader } from './commonSlice';

const initialState = {
  subtype: { isLoading: false, data: [], metaData: null, fieldAccess: null },
  vintageYear: { isLoading: false, data: [] },
  juriAccountInformation: { isLoading: false, data: null },
  holdingsInformation: { isLoading: false, data: [], metaData: null, fieldAccess: null },
  transferHoldingsInformation: { isLoading: false, data: [], metaData: null, fieldAccess: null },
  TransfersInformation: { isLoading: false, data: [], metaData: null, fieldAccess: null },
  acquiringAccInfo: { isLoading: false, data: null },
  issuanceAccInfo: { isLoading: false, data: [] },
  transferAccInfo: { isLoading: false, data: [] },
  complianceInstrumentsInfo: { isLoading: false, data: [] },
  transferHistory: { isLoading: false, data: [] },
  offsetIssuanceInfo: { isLoading: false, data: [] },
  issuanceRecordsInfo: { isLoading: false, data: [] },
  approvals: { isLoading: false, data: [] },
  requestRevisionComment: { isLoading: false, data: [] },
  mergedholdingsForLinkedJurisdiction: { isLoading: false, data: [] },
};

const issuanceSlice = createSlice({
  name: 'issuance',
  initialState,
  reducers: {
    setSubtype: (state, action) => {
      state.subtype = { ...state.subtype, ...action.payload };
    },
    setVintageYear: (state, action) => {
      state.vintageYear = { ...state.vintageYear, ...action.payload };
    },
    setAcquiringAccInfo: (state, action) => {
      state.acquiringAccInfo = { ...state.acquiringAccInfo, ...action.payload };
    },
    setIssuanceAccInfo: (state, action) => {
      state.issuanceAccInfo = { ...state.issuanceAccInfo, ...action.payload };
    },
    setTransferAccInfo: (state, action) => {
      state.transferAccInfo = { ...state.transferAccInfo, ...action.payload };
    },
    setComplianceInstrumentsInfo: (state, action) => {
      state.complianceInstrumentsInfo = { ...state.complianceInstrumentsInfo, ...action.payload };
    },
    setTransferHistory: (state, action) => {
      state.transferHistory = { ...state.transferHistory, ...action.payload };
    },
    setOffsetIssuanceInfo: (state, action) => {
      state.offsetIssuanceInfo = { ...state.offsetIssuanceInfo, ...action.payload };
    },
    setIssuanceRecordsInfo: (state, action) => {
      state.issuanceRecordsInfo = { ...state.issuanceRecordsInfo, ...action.payload };
    },

    setJuriAccountInfo: (state, action) => {
      state.juriAccountInformation = action.payload;
    },

    setHoldingsInfo: (state, action) => {
      state.holdingsInformation = action.payload;
    },
    setTransferHoldingsInfo: (state, action) => {
      state.transferHoldingsInformation = action.payload;
    },
    setTransfersInfo: (state, action) => {
      state.TransfersInformation = action.payload;
    },
    setApprovals: (state, action) => {
      state.approvals = action.payload;
    },
    setAllowanceInfoEdit: (state, action) => {
      state.offsetInfoEdit = action.payload;
    },
    setRequestRevisionComment: (state, action) => {
      state.requestRevisionComment = { ...state.requestRevisionComment, ...action.payload };
    },
    setMergedHoldingsForLinkedJurisdiction: (state, action) => {
      state.mergedholdingsForLinkedJurisdiction = action.payload;
    },
  },
});

export default issuanceSlice.reducer;

export const {
  setSubtype,
  setVintageYear,
  setAcquiringAccInfo,
  setIssuanceAccInfo,
  setTransferAccInfo,
  setComplianceInstrumentsInfo,
  setTransferHistory,
  setOffsetIssuanceInfo,
  setIssuanceRecordsInfo,
  setProposeAllowanceIssuance,
  setJuriAccountInfo,
  setHoldingsInfo,
  setTransferHoldingsInfo,
  setTransfersInfo,
  setApprovals,
  setAllowanceInfoEdit,
  setRequestRevisionComment,
  setMergedHoldingsForLinkedJurisdiction,
} = issuanceSlice.actions;

export function getAcquiringAccInfo(accNo, pageName) {
  return async (dispatch) => {
    dispatch(setAcquiringAccInfo({ isLoading: true }));
    try {
      const pageDetails = { pageName, componentName: 'acquiringAccount' };
      const response = await fetcherGet(`${APIS.ACQUIRING_ACCOUNT_GET}/${accNo}`, {}, pageDetails);
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setAcquiringAccInfo({
          isLoading: false,
          data: {
            data,
            fieldAccess: getPermissionObj(fieldAccess, 'acquiringAccount'),
            metaData,
          },
        }),
      );
    } catch (error) {
      dispatch(setAcquiringAccInfo({ isLoading: false, data: null }));
    }
  };
}

export function getSubType(pageName = 'Page_AllowanceIssuance', componentName = 'allowanceIssuanceInformation') {
  return async (dispatch) => {
    dispatch(setSubtype({ isLoading: true }));
    try {
      const response = await fetcherGet(`${APIS.SUB_TYPE_GET}`, {}, { pageName, componentName });
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setSubtype({
          isLoading: false,
          data: {
            data,
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        }),
      );
    } catch (error) {
      dispatch(setSubtype({ isLoading: false, data: null }));
    }
  };
}

export function getVintageYear(pageName = 'Page_AllowanceIssuanceDetails', componentName = 'complianceInstruments', isAllowanceIssuance = false) {
  return async (dispatch) => {
    const jurisdictionId = getJurisdictionUUID();
    dispatch(setVintageYear({ isLoading: true }));
    try {
      const response = await fetcherGet(`${APIS.VINTAGE_YEAR_GET}/${jurisdictionId}`, { isAllowanceIssuance }, {
        pageName,
        componentName,
      });
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setVintageYear({
          isLoading: false,
          data: {
            data: transFormVintageYearToDropdownOptions(data),
            fieldAccess: getPermissionObj(fieldAccess, componentName),
            metaData,
          },
        }),
      );
    } catch (error) {
      dispatch(setVintageYear({ isLoading: false, data: null }));
    }
  };
}

export function addProposeAllowanceIssuance(payload, headers) {
  return async (dispatch) => {
    const jurisdictionId = getJurisdictionUUID();
    dispatch(setLoader(true));
    return fetcher(
      `${APIS.ALLOWANCE_ISSUANCE_INFORMATION_POST}/${jurisdictionId}`,
      'POST',
      {
        ...payload,
      },
      false,
      headers,
    ).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getIssuanceInformation(id, pageName) {
  return async (dispatch) => {
    dispatch(setIssuanceAccInfo({ isLoading: true }));
    const pageDetails = { pageName, componentName: 'issuanceInformation' };
    try {
      if (id) {
        const response = await fetcherGet(`${APIS.API_ISSUANCE_INFORMATION}/${id}`, {}, pageDetails);
        const { data, fieldAccess, metaData } = response;
        dispatch(
          setIssuanceAccInfo({
            isLoading: false,
            data: {
              data,
              fieldAccess: getPermissionObj(fieldAccess, response?.fieldAccess?.[0]?.componentName),
              metaData,
            },
          }),
        );
      }
    } catch (error) {
      dispatch(setIssuanceAccInfo({ isLoading: false, data: [] }));
    }
  };
}

export function getTransferAccInformation(id, pageName) {
  return async (dispatch) => {
    dispatch(setTransferAccInfo({ isLoading: true }));
    try {
      const pageDetails = { pageName, componentName: 'accounts' };
      const response = await fetcherGet(`${APIS.API_ACCOUNTS}/${id}`, {}, pageDetails);
      dispatch(setTransferAccInfo({ isLoading: false, data: response }));
    } catch (error) {
      dispatch(setTransferAccInfo({ isLoading: false, data: [] }));
    }
  };
}

// **********    JURISDICTION ISSUANCE ACCOUNTS   *********
export function getAccountInfo(accNo, pageDetails) {
  return async (dispatch) => {
    const jurisdictionId = getJurisdictionUUID();
    dispatch(setLoader(true));
    dispatch(setJuriAccountInfo({ isLoading: true, data: [] }));
    try {
      const response = await fetcherGet(`${APIS.ACCOUNT_INFORMATION_API}/${jurisdictionId}/${accNo}`, {}, pageDetails);
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setJuriAccountInfo({
          isLoading: false,
          data: {
            data,
            fieldAccess: getPermissionObj(fieldAccess, 'accountInformation'),
            metaData,
          },
        }),
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setJuriAccountInfo({ isLoading: false, data: null }));
      dispatch(setLoader(false));
      logger({ error });
    }
  };
}

export function getComplianceInstrumentsInformation(id, pageName) {
  return async (dispatch) => {
    dispatch(setComplianceInstrumentsInfo({ isLoading: true }));
    try {
      const pageDetails = { pageName, componentName: 'complianceInstruments' };
      const response = await fetcherGet(`${APIS.API_COMPLIANCE_INSTRUMENTS}/${id}`, {}, pageDetails);
      dispatch(setComplianceInstrumentsInfo({ isLoading: false, data: response }));
    } catch (error) {
      dispatch(setComplianceInstrumentsInfo({ isLoading: false, data: [] }));
    }
  };
}

export function getTransferHistory(id, pageName) {
  return async (dispatch) => {
    dispatch(setTransferHistory({ isLoading: true }));
    try {
      const pageDetails = { pageName, componentName: 'transferEventHistory' };
      const response = await fetcherGet(`${APIS.API_TRANSFER_HISTORY}/${id}`, {}, pageDetails);
      dispatch(setTransferHistory({ isLoading: false, data: response }));
    } catch (error) {
      dispatch(setTransferHistory({ isLoading: false, data: [] }));
    }
  };
}

export function getOffsetIssuanceInfo(id, pageName) {
  return async (dispatch) => {
    dispatch(setOffsetIssuanceInfo({ isLoading: true }));
    try {
      const pageDetails = { pageName, componentName: 'offsetIssuanceInformation' };
      const response = await fetcherGet(`${APIS.API_OFFSET_ISSUANCE_INFORMATION}/${id}`, {}, pageDetails);
      const { data, fieldAccess, metaData } = response;
      dispatch(
        setOffsetIssuanceInfo({
          isLoading: false,
          data: {
            data,
            fieldAccess: getPermissionObj(fieldAccess, fieldAccess?.[0]?.componentName),
            metaData,
          },
        }),
      );
    } catch (error) {
      dispatch(setOffsetIssuanceInfo({ isLoading: false, data: [] }));
    }
  };
}

export function getHoldings(accNo) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const response = await fetcherGet(
        `${APIS.HOLDINGS_VIEW_API}/${accNo}`,
        {},
        { pageName: 'Page_AccountDetails', componentName: 'holdings' },
      );
      const { data, metaData, fieldAccess } = response;
      dispatch(
        setHoldingsInfo({
          isLoading: false,
          data: transformHoldingsViewRes(data),
          metaData,
          fieldAccess,
        }),
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setHoldingsInfo({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
      logger({ error });
    }
  };
}

// transfer holdings
export function getTransferHoldings(accNo) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const response = await fetcherGet(
        `${APIS.ENTITY_TRANSFERS_HOLDINGS_API}/${accNo}`,
        {},
        { pageName: 'Page_AccountDetails', componentName: 'holdings' },
      );
      const { data, metaData, fieldAccess } = response;
      dispatch(
        setTransferHoldingsInfo({
          isLoading: false,
          data: transformEntityTransfersHoldings(data),
          metaData,
          fieldAccess,
        }),
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setTransferHoldingsInfo({ isLoading: false, data: [] }));
      dispatch(setLoader(false));
      logger({ error });
    }
  };
}

export function getTransfers(accNo) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const response = await fetcherGet(
        `${APIS.TRANSFERS_API}/${accNo}`,
        {},
        { pageName: 'Page_AccountDetails', componentName: 'transfers' },
      );
      dispatch(
        setTransfersInfo({
          isLoading: false,
          data: transformTransferRes(response?.data),
          metaData: response?.metaData,
          fieldAccess: response?.fieldAccess,
        }),
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setTransfersInfo({ isLoading: false, data: null }));
      dispatch(setLoader(false));
      logger({ error });
    }
  };
}

export function getIssuanceRecordsInformation(
  id,
  pageDetails = { pageName: 'Page_IssuanceRecords', componentName: 'issuanceRecords' },
) {
  return async (dispatch) => {
    dispatch(setIssuanceRecordsInfo({ isLoading: true }));
    try {
      const response = await fetcherGet(`${APIS.API_ISSUANCE_RECORDS}/${id}`, {}, pageDetails);
      dispatch(setIssuanceRecordsInfo({ isLoading: false, data: response }));
    } catch (error) {
      dispatch(setIssuanceRecordsInfo({ isLoading: false, data: [] }));
    }
  };
}

export function getMCIApprovals(
  id,
  pageDetails = { pageName: 'Page_TransferApprovals', componentName: 'transferApprovals' },
) {
  return async (dispatch) => {
    dispatch(setApprovals({ isLoading: true }));
    try {
      const response = await fetcherGet(`${APIS.API_TRANSFER_APPROVALS}/${id}`, {}, pageDetails);
      dispatch(setApprovals({ isLoading: false, data: response }));
    } catch (error) {
      dispatch(setApprovals({ isLoading: false, data: [] }));
    }
  };
}

export function postApproveIssuance(issuanceType, transferId, payload, pageName) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = issuanceType === 'Offset' ? APIS.APPROVE_OFFSET_ISSUANCE_POST : APIS.APPROVE_ALLOWANCE_ISSUANCE_POST;
    const pageDetails = {
      pageName,
      componentName: pageName === 'Page_AllowanceIssuanceDetails' ? 'approveAllowanceRequest' : 'approveOffsetRequest',
    };
    return fetcher(`${url}/${transferId}`, 'POST', payload, false, pageDetails).finally(() => {
      dispatch(setLoader(false));
    });
  };
}

export function getRequestRevisionComment(id, pageName) {
  return async (dispatch) => {
    dispatch(setRequestRevisionComment({ isLoading: true }));
    try {
      if (id) {
        const pageDetails = { pageName, componentName: 'revisonsRequested' };
        const response = await fetcherGet(`${APIS.GET_REQUEST_REVISION_COMMENT}/${id}`, {}, pageDetails);
        const { data, fieldAccess, metaData } = response;
        dispatch(
          setRequestRevisionComment({
            isLoading: false,
            data: {
              data,
              fieldAccess: getPermissionObj(fieldAccess, fieldAccess?.[0]?.componentName),
              metaData,
            },
          }),
        );
      }
    } catch (error) {
      dispatch(setRequestRevisionComment({ isLoading: false, data: [] }));
    }
  };
}

export function getMergedHoldingsForLinkedJurisdiction(accNo) {
  return async (dispatch) => {
    dispatch(setLoader(true));
    try {
      const pageDetails = { pageName: 'Page_ComplianceAccountDetails', componentName: 'holdings' };
      const response = await fetcherGet(`${APIS.HOLDINGS_API}/${accNo}`, {}, pageDetails);
      dispatch(
        setMergedHoldingsForLinkedJurisdiction({
          isLoading: false,
          data: transformHoldingsResReplenishment(response?.data),
        }),
      );
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setMergedHoldingsForLinkedJurisdiction({ isLoading: false, data: null }));
      dispatch(setLoader(false));
      logger({ error });
    }
  };
}
