import { JURISDICTIONS_NAMES } from 'utils/constants';

export const ENTITY_INFORMATION_MANDATORY_FIELDS_BY_JURISDICTION = {
  [JURISDICTIONS_NAMES.CALIFORNIA]: [
    'IdentificationNumber',
    'IDByAgency',
    'idNumberAssignedByTheCaliforniaSecretaryOfState',
  ],
  [JURISDICTIONS_NAMES.WASHINGTON_STATE]: ['IdentificationNumber', 'IDByAgency', 'UnitedBusinessID'],
  [JURISDICTIONS_NAMES.QUEBEC]: ['dunsNumber', 'québecEnterpriseNumber'],
  [JURISDICTIONS_NAMES.NOVA_SCOTIA]: ['registryID', 'businessNumber'],
};
