import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GridSelect, GridTextfield, PhoneInput } from 'components';
import { CustomizeSelect } from 'components/CustomizeSelect';
import { CustomizeTextfield } from 'components/CustomizeTextfield';
import DynamicMultiInputTextfield from 'components/DynamicMultiInputTextfield/DynamicMultiInputTextfield';
import { FormActions } from 'components/FormActions';
import { FormCard } from 'components/FormCard';
import GridDatefield from 'components/GridDatefield/GridDatefield';
import { Loader } from 'components/Loader';
import useAuth from 'hooks/useAuth';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { setLoader } from 'store/commonSlice';
import { validateUniqueUser, validateUser } from 'store/createUserProfileSlice';
import {
  CREATE_USER_PROFILE_FORM_FIELDS,
  DAYS,
  emailPattern,
  employerList,
  extPattern,
  faxNumberException,
  faxNumberPattern,
  formatDate,
  getCurrentPageName,
  jobEmployerPattern,
  JURISDICTION_USER_ACTION_FORM_FIELDS,
  MONTHS,
  namePattern,
  numericNumber,
  phonePattern,
  scrollToTop,
  specialCharImportant,
  t,
  validateAccentuatedValues,
  validateDate,
  validateEXTNumber,
  validatePhoneNumber,
  YEARS,
  validateSalutationPattern,
  validateSuffixPattern,
  validateFirstName,
  validatePattern,
  jobtitleEmployerPattern,
  mailPattern,
  applyDisabledStyles,
  validateMiddleNames,
  lastNamePattern,
} from 'utils/constants';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { validateInitialUserAPI } from 'store/setInitialUserSlice';


export default function PersonalInformation() {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const linkId = searchParams.get('LinkID');
  const { validateUniqueUser } = useAuth();
  const {
    filledData,
    submitted,
    isValid,
    prePopulatedDetails: { data },
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { loader } = useSelector(({ common }) => common);
  const { cmsLanguagePage } = useSelector(({ config }) => config);
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user;
  const [emailError, setEmailError] = useState(null);

  const emailLanguageOptions = useMemo(
    () =>
      cmsLanguagePage?.data?.Languages?.map((item) => ({
        value: item?.LanguageCode,
        title: item?.SK?.split('#')?.pop(),
      })),
    [cmsLanguagePage?.data?.Languages],
  );

  /* Conflict Resolution Candidate
    const isEmployerRequired = employerRequired.includes(filledData?.[1]?.jurisdiction?.OrgID);
   */

  useEffectOnce(() => {
    scrollToTop();
  });

  const defaultValues = submitted?.[2]
    ? filledData?.[2]
    : {
      /* Conflict Resolution Candidate
            day: "",
            month: "",
            year: "",
            date: "",
            emailLanguagePreference: "",
          };
       */
      day: '',
      month: '',
      year: '',
      date: '',
      emailLanguagePreference: '',
    };

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues: {
      ...defaultValues,
      employer: employerList(defaultValues?.employer),
    },
  });
  const { watch, formState, trigger, clearErrors, setValue } = methods;
  const { errors } = formState;

  const setDefaultValues = () => {
    methods?.reset(defaultValues);
  };
  const watchShowEmail = watch('email', false);
  /* Conflict Resolution Candidate
   const watchShowConfirmEmail = watch("confirmEmail", false);
  */

  const emailIsUnique = async (value, type) => {
    if (type === 'checkValidEmail' && watchShowEmail !== value) {

      // when confirmEmail is also matching with email, then user changes email, then confirmEmail should be validated again
      if (methods?.getValues('confirmEmail')?.length > 0) {
        if (methods?.getValues('confirmEmail')?.toLowerCase().trim() === value.toLowerCase().trim()) {
          clearErrors('confirmEmail');
        } else {
          trigger('confirmEmail');
        }
      }

      if (value.includes('..')) {
        setEmailError(`${t(t1, 'ERR_INVALID_EMAIL')}`);
        return `${t(t1, 'ERR_INVALID_EMAIL')}`;
      }

      const isValid = await validateUniqueUser({ email: value.trim() });
      const isValidInitialUser = await validateInitialUserAPI(value?.trim());
      if (!isValid || _.isEqual(isValidInitialUser, true)) {
        setEmailError(`${t(t1, 'ERR_EMAIL_ADDRESS_IS_NOT_AVAILABLE')}`);
        return `${t(t1, 'ERR_EMAIL_ADDRESS_IS_NOT_AVAILABLE')}`;
      }

      if (isValid) {
        setEmailError(null);
        return null;
      }
    }
    return emailError;
  };

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setValue('firstName', data?.InitialUsers?.first_name);
      setValue('lastName', data?.InitialUsers?.last_name);
      setValue('email', data?.InitialUsers?.email_address);
      setValue('confirmEmail', data?.InitialUsers?.email_address);
      setValue('referenceCode', data?.InitialUsers?.reference_code);
      setValue('roleName', data?.InitialUsers?.Roles?.name);
    }
  }, [data]);

  useEffect(() => {
    dispatch(setLoader(false));
  }, []);

  let personalSaveDisabled = false;
  if (
    errors?.salutation ||
    errors?.firstName ||
    errors?.middleName ||
    errors?.lastName ||
    errors?.suffix ||
    errors?.date ||
    errors?.telephoneNumber ||
    errors?.ext ||
    errors?.mobilePhone ||
    errors?.email ||
    errors?.confirmEmail ||
    errors?.emailLanguagePreference ||
    errors?.employer ||
    errors?.jobTitle ||
    errors?.faxNumber
  ) {
    personalSaveDisabled = true;
  } else personalSaveDisabled = false;

  return (
    <FormProvider {...methods}>
      <FormCard
        title={t(t1, 'PERSONAL_INFORMATION')}
        description="indicate required fields"
        stepNumber={2}
        setDefaultValues={setDefaultValues}
        customeStyle={{ marginBottom: '20px !important' }}
        saveDisabled={personalSaveDisabled || !methods.formState.isDirty}
        methods={methods}
        /* Conflict Resolution Candidate
              methods={methods}
              defaultValues={defaultValues}
       */
      >
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <Grid
            container
            spacing={2.5}
          >
            <Grid
              item
              md={4}
            >
              <Grid
                container
                spacing={2.5}
              >
                <GridTextfield
                  name="salutation"
                  label={t(t1, 'SALUTATION')}
                  rules={{
                    maxLength: {
                      value: CREATE_USER_PROFILE_FORM_FIELDS.SALUTATION_MAX_CHAR,
                      message: `${t(t1, 'ERR_SALUTATION_MAX_LENGTH_CHAR')}`,
                    },
                    validate: (v) =>
                      methods?.getValues('salutation')
                        ? validateSalutationPattern(v, `${t(t1, 'SALUTATION')}`, t1)
                        : null,
                  }}
                  stepNumber={2}
                />
                <GridTextfield
                  name="firstName"
                  label={t(t1, 'FIRST_NAME')}
                  privId="priv_txt_FirstName"
                  rules={{
                    required: true,
                    maxLength: {
                      value: CREATE_USER_PROFILE_FORM_FIELDS.FIRST_NAME_MAX_CAR,
                      message: `${t(t1, 'ERR_FIRST_NAME_MAX_LENGTH_CHAR')}`,
                    },
                    validate: (v) =>
                      methods?.getValues('firstName') ? validateFirstName(v, `${t(t1, 'FIRST_NAME')}`, t1) : null,
                  }}
                  InputProps={{
                    'aria-required': 'true',
                  }}
                  stepNumber={2}
                  gridMd={8}
                  disabled={!!linkId}
                  customTextFieldStyle={applyDisabledStyles(!!linkId)}
                  isRequiredMsg={'ERR_FIRST_NAME_IS_REQUIRED'}
                />
              </Grid>
            </Grid>
            <GridTextfield
              rules={{
                maxLength: {
                  value: CREATE_USER_PROFILE_FORM_FIELDS.MIDDLE_NAME_MAX_CAR,
                  message: `${t(t1, 'ERR_MIDDLE_NAME_MAX_LENGTH_CHAR')}`,
                },
                validate: (v) =>
                  methods?.getValues('middleName') ? validateMiddleNames(v, `${t(t1, 'MIDDLE_NAME')}`, t1) : null,
              }}
              name="middleName"
              label={t(t1, 'MIDDLE_NAME')}
              privId="priv_txt_MiddleName"
              className="input-group"
              stepNumber={2}
            />
            <Grid
              item
              md={4}
            >
              <Grid
                container
                spacing={2.5}
              >
                <GridTextfield
                  name="lastName"
                  label={t(t1, 'LAST_NAME')}
                  privId="priv_txt_LastName"
                  rules={{
                    required: true,
                    maxLength: {
                      value: CREATE_USER_PROFILE_FORM_FIELDS.LAST_NAME_MAX_CAR,
                      message: `${t(t1, 'ERR_LAST_NAME_MAX_LENGTH_CHAR')}`,
                    },
                    // this is final change for last name which will be used at all places across all modules
                    validate: (v) =>
                      methods?.getValues('lastName') ? validatePattern(v, t1, `${t(t1, 'LAST_NAME')}`, lastNamePattern, `${t(t1, 'ERR_INVALID_LAST_NAME')}`) : null,
                  }}
                  stepNumber={2}
                  InputProps={{
                    'aria-required': 'true',
                  }}
                  gridMd={8}
                  disabled={!!linkId}
                  customTextFieldStyle={applyDisabledStyles(!!linkId)}
                  isRequiredMsg={'ERR_LAST_NAME_IS_REQUIRED'}
                />
                <GridTextfield
                  rules={{
                    maxLength: {
                      value: 20,
                      message: `${t(t1, 'ERR_SUFFIX_MAX_LENGTH_CHAR')}`,
                    },
                    validate: (v) =>
                      methods?.getValues('suffix') ? validateSuffixPattern(v, `${t(t1, 'SUFFIX')}`, t1) : null,
                  }}
                  name="suffix"
                  label={t(t1, 'SUFFIX')}
                  privId="priv_txt_Suffix"
                  className="input-group"
                  stepNumber={2}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={4}
              sx={{
                pt: { md: '20px !important', lg: '10px !important' },
              }}
            >
              <GridDatefield
                onClose={() => methods.trigger('date')}
                gridMd={12}
                label={t(t1, 'DATE_OF_BIRTH')}
                name="date"
                privId="priv_select_Date"
                labelValue={`${t(t1, 'DATE_OF_BIRTH')}`}
                rules={{
                  required: true,
                  validate: (v) =>
                    methods?.getValues('date') ? validateDate(v, `${t(t1, 'DATE_OF_BIRTH')}`, t1, `${t(t1, 'ERR_INVALID_DATE_OF_BIRTH')}`) : null,
                }}
                stepNumber={2}
                isRequiredMsg={'ERR_DATE_OF_BIRTH_IS_REQUIRED'}
              />
              {/* Conflict Resolution Candidate
            <Grid item md={4}
            sx={{
              pt:"10px !important",
              '& .MuiInputBase-root.MuiOutlinedInput-root':{
                height: '30px',
                // marginLeft: '20px',
                width: '340px',
              }
            }}>
              <Grid container columnSpacing={2.5}>
                <Grid item md={12}>
                  <InputLabel
                    sx={{
                      fontSize: "0.875rem",
                      color: "common.textColor",
                      fontWeight: 700,
                      mb: 0.5,
                    }}
                  >
                    {t(t1, "DATE_OF_BIRTH")}
                    <Typography
                      variant="body2"
                      sx={{
                        display: "inline-block",
                        color: "common.red",
                        ml: 0.3,
                        fontWeight: 700,
                      }}
                    >
                      *
                    </Typography>
                  </InputLabel>
                </Grid>
                <GridDatefield
                gridStyles={{'& .MuiInputBase-root':{
                  width: '265px !important'
                }}}
                  name="date"
                  privId="priv_select_Date"
                  labelValue={`${t(t1, "DATE_OF_BIRTH")}`}
                  rules={{
                    required: true,
                    validate:(v)=>methods?.getValues("date")
                    ? validateDate(v, `${t(t1, "DATE_OF_BIRTH")}`, t1)
                    : null,
                  }}
                  stepNumber={2}
                />
              </Grid> */}
            </Grid>
            <Grid
              item
              md={4}
              sx={{ pt: '10px !important' }}
            >
              <Grid
                container
                spacing={2.5}
              >
                <GridTextfield
                  name="telephoneNumber"
                  label={t(t1, 'TELEPHONE_NUMBER')}
                  privId="priv_txt_Telephone"
                  rules={{
                    required: true,
                    validate: (v) =>
                      methods?.getValues('telephoneNumber')
                        ? validatePhoneNumber(v?.trimStart().trimEnd(), `${t(t1, 'TELEPHONE_NUMBER')}`, t1, `${t(t1, 'ERR_TELEPHONE_NUMBER_MIN_LENGTH_CHAR')}`, `${t(t1, 'ERR_TELEPHONE_NUMBER_MAX_LENGTH_CHAR')}`, `${t(t1, 'ERR_INVALID_TELEPHONE_NUMBER')}`, '')
                        : null,
                  }}
                  className="input-group"
                  stepNumber={2}
                  gridMd={8}
                  isRequiredMsg={'ERR_TELEPHONE_NUMBER_IS_REQUIRED'}
                />
                <GridTextfield
                  name="ext"
                  privId="priv_txt_Ext"
                  label={t(t1, 'EXT')}
                  type="text"
                  stepNumber={2}
                  rules={{
                    minLength: {
                      value: 1,
                      message: `${t(t1, 'ERR_EXT_MIN_LENGTH_CHAR')}`,
                    },
                    maxLength: {
                      value: CREATE_USER_PROFILE_FORM_FIELDS.EXTENSION_MAX_CHAR,
                      message: `${t(t1, 'ERR_EXT_MAX_LENGTH_CHAR')}`,
                    },
                    validate: (v) =>
                      methods?.getValues('ext') ? validatePattern(v, `${t(t1, 'EXT')}`, t1, extPattern, `${t(t1, 'ERR_INVALID_EXT')}`) : null,
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              item
              md={4}
              sx={{ pt: '10px !important' }}
            >
              <GridTextfield
                name="mobilePhone"
                privId="priv_txt_MobilePhone"
                rules={{
                  validate: (v) =>
                    methods?.getValues('mobilePhone')
                      ? validatePhoneNumber(v?.trimStart().trimEnd(), `${t(t1, 'MOBILE_PHONE')}`, t1, `${t(t1, 'ERR_MOBILE_PHONE_MIN_LENGTH_CHAR')}`, `${t(t1, 'ERR_MOBILE_PHONE_MAX_LENGTH_CHAR')}`, `${t(t1, 'ERR_INVALID_MOBILE_PHONE')}`, '')
                      : null,
                }}
                label={t(t1, 'MOBILE_PHONE')}
                className="input-group"
                stepNumber={2}
                gridMd={12}
              />
            </Grid>

            <Grid
              item
              md={4}
              sx={{ pt: '10px !important' }}
            >
              <GridTextfield
                name="email"
                label={t(t1, 'EMAIL')}
                privId="priv_txt_Email"
                type="text"
                rules={{
                  required: true,
                  maxLength: {
                    value: JURISDICTION_USER_ACTION_FORM_FIELDS.EMAIL_MAX_CHAR,
                    message: `${t(t1, 'ERR_EMAIL_MAX_LENGTH_CHAR')}`,
                  },
                  pattern: {
                    value: mailPattern,
                    message: `${t(t1, 'ERR_INVALID_EMAIL')}`,
                  },
                  validate: (v) =>
                    methods?.getValues('email') ? emailIsUnique(methods?.getValues('email'), 'checkValidEmail') : null,
                }
                }
                isRequiredMsg={'ERR_EMAIL_IS_REQUIRED'}
                /* Conflict Resolution Candidate
                                onKeyUp={() => {
                                  if(!isEmpty(watchShowConfirmEmail)) methods?.trigger("confirmEmail")
                                }}
                 */
                className="input-group"
                stepNumber={2}
                gridMd={12}
                disabled={!!linkId}
                customTextFieldStyle={applyDisabledStyles(!!linkId)}
              />
            </Grid>
            {loader && <Loader loader={loader} />}

            <Grid
              item
              md={4}
              sx={{ pt: '10px !important' }}
            >
              <GridTextfield
                name="confirmEmail"
                label={t(t1, 'CONFIRM_EMAIL')}
                privId="priv_txt_ConfirmEmail"
                type="text"
                rules={{
                  required: true,
                  maxLength: {
                    value: JURISDICTION_USER_ACTION_FORM_FIELDS.EMAIL_MAX_CHAR,
                    message: `${t(t1, 'ERR_CONFIRM_EMAIL_MAX_LENGTH_CHAR')}`,
                  },
                  pattern: {
                    value: mailPattern,
                    message: `${t(t1, 'ERR_INVALID_CONFIRM_EMAIL')}`,
                  },
                  validate: {
                    emailEqual: (value) =>
                      value.toLowerCase().trim() === methods.getValues().email.toLowerCase().trim() ||
                      `${t(t1, 'EMAIL_CONFIRM_EMAIL_MATCH')}`,
                  },
                }}
                className="input-group"
                stepNumber={2}
                isRequiredMsg={'ERR_CONFIRM_EMAIL_IS_REQUIRED'}
                gridMd={12}
                disabled={!!linkId}
                customTextFieldStyle={applyDisabledStyles(!!linkId)}
              />
            </Grid>
            <Grid
              item
              md={4}
              sx={{ pt: '10px !important' }}
            >
              <GridSelect
                name="emailLanguagePreference"
                privId="priv_txt_EmailLanguage"
                noValueText={t(t1, 'SELECT_LANGUAGE')}
                label={t(t1, 'EMAIL_LANGUAGE_PREFERENCE')}
                options={emailLanguageOptions || []}
                rules={{
                  required: `${t(t1, 'ERR_EMAIL_LANGUAGE_PREFERENCE_IS_REQUIRED')}`,
                }}
                InputProps={{
                  'aria-required': 'true',
                }}
                className="input-group"
                stepNumber={2}
                isRequiredMsg={'ERR_EMAIL_LANGUAGE_PREFERENCE_IS_REQUIRED'}
                viewOnlyValue={emailLanguageOptions?.find((option) => option?.value === defaultValues?.emailLanguagePreference)?.title}
                gridMd={12}
                customMenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 145,
                    },
                  },
                }}
              />
            </Grid>
            <DynamicMultiInputTextfield
              methods={methods}
              stepNumber={2}
              fieldName="employer"
              viewEditRegistration
              userProfileEdit
            />
            <Grid
              item
              md={4}
              sx={{ pt: '10px !important' }}
            >
              <GridTextfield
                rules={{
                  maxLength: {
                    value: 255,
                    message: `${t(t1, 'ERR_JOB_TITLE_MAX_LENGTH_CHAR')}`,
                  },
                  validate: (v) =>
                    methods?.getValues('jobTitle')
                      ? validatePattern(v, `${t(t1, 'JOB_TITLE')}`, t1, jobtitleEmployerPattern, `${t(t1, 'ERR_INVALID_JOB_TITLE')}`)
                      : null,
                }}
                name="jobTitle"
                label={t(t1, 'JOB_TITLE')}
                privId="priv_txt_JobTitle"
                className="input-group"
                stepNumber={2}
                gridMd={12}
              />
            </Grid>

            <Grid
              item
              md={4}
              sx={{ pt: '10px !important' }}
            >
              <GridTextfield
                name="faxNumber"
                label={t(t1, 'FAX_NUMBER')}
                privId="priv_txt_FaxNumber"
                className="input-group"
                gridMd={12}
                stepNumber={2}
                rules={{
                  minLength: {
                    value: 10,
                    message: `${t(t1, 'ERR_FAX_NUMBER_MIN_LENGTH_CHAR')}`,
                  },
                  validate: (v) =>
                    methods?.getValues('faxNumber')
                      ? validatePhoneNumber(v?.trim(), `${t(t1, 'FAX_NUMBER')}`, t1, `${t(t1, 'ERR_FAX_NUMBER_MIN_LENGTH_CHAR')}`, `${t(t1, 'ERR_FAX_NUMBER_MAX_LENGTH_CHAR')}`, `${t(t1, 'ERR_INVALID_FAX_NUMBER')}`, '')
                      : null,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </FormCard>
      <FormActions stepNumber={2} />
    </FormProvider>
  );
}
