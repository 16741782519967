import { createSlice } from '@reduxjs/toolkit';
import { JURISDICTION_BUDGET_COMPONENTS_FLP, checking } from 'pages/ManageJurisdictionBudget/constant';
import { APIS, statusTypes } from 'utils/constants';
import { fetcherGet } from 'utils/fetcher';
import logger from 'utils/logger';

const initialState = {
  budgetDetails: null,
  effectiveBudgetRecords: [],
  proposedBudgetRecords: [],
  allowanceHistory: [],
  status: [],
  editBudgetRecords: null,
  cancelBudgetDialogMessage: false,
  isLoadingEditBudgetRecords: false,
  isLoadingBudgetDetails: false,
  isLoadingEffectiveBudgetRecords: false,
  isLoadingProposedBudgetRecords: false,
  isLoadingAllowanceHistory: false,
  isLoadingStatus: false,
  flp: {
    [JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_DETAILS]: {
      metaData: [],
      fieldAccess: [],
    },
    [JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_YEARS]: {
      metaData: [],
      fieldAccess: [],
    },
    [JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_HISTORY]: {
      metaData: [],
      fieldAccess: [],
    },
    [JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS]: {
      metaData: [],
      fieldAccess: [],
    },
  },
};

const ManageJurisdictionBudgetSlice = createSlice({
  name: 'ManageJurisdictionBudget',
  initialState,
  reducers: {
    setBudgetDetails: (state, action) => {
      state.budgetDetails = action.payload;
    },
    setEffectiveBudgetRecords: (state, action) => {
      state.effectiveBudgetRecords = action.payload;
    },
    setProposedBudgetRecords: (state, action) => {
      state.proposedBudgetRecords = action.payload;
    },
    setAllowanceHistory: (state, action) => {
      state.allowanceHistory = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setEditBudgetRecords: (state, action) => {
      state.editBudgetRecords = action.payload;
    },
    setCancelBudgetDialogMessage: (state, action) => {
      state.cancelBudgetDialogMessage = action.payload;
    },
    setISLoadingEditBudgetRecords: (state, action) => {
      state.isLoadingEditBudgetRecords = action.payload;
    },
    setISLoadingBudgetDetails: (state, action) => {
      state.isLoadingBudgetDetails = action.payload;
    },
    setIsLoadingEffectiveBudgetRecords: (state, action) => {
      state.isLoadingEffectiveBudgetRecords = action.payload;
    },
    setIsLoadingProposedBudgetRecords: (state, action) => {
      state.isLoadingProposedBudgetRecords = action.payload;
    },
    setIsLoadingAllowanceHistory: (state, action) => {
      state.isLoadingAllowanceHistory = action.payload;
    },
    setIsLoadingStatus: (state, action) => {
      state.isLoadingStatus = action.payload;
    },
    setFLP: (state, action) => {
      const { key, value } = action.payload;
      state.flp[key] = value;
    },
  },
});

export const {
  setBudgetDetails,
  setEffectiveBudgetRecords,
  setProposedBudgetRecords,
  setAllowanceHistory,
  setStatus,
  setEditBudgetRecords,
  setCancelBudgetDialogMessage,
  setIsLoadingEditBudgetRecords,
  setIsLoadingStatus,
  setISLoadingBudgetDetails,
  setIsLoadingEffectiveBudgetRecords,
  setIsLoadingProposedBudgetRecords,
  setIsLoadingAllowanceHistory,
  setFLP,
} = ManageJurisdictionBudgetSlice.actions;
export default ManageJurisdictionBudgetSlice.reducer;

export const getBudgetDetails = (jurisdictionId) => (dispatch) => {
  dispatch(setISLoadingBudgetDetails(true));
  dispatch(
    setFLP({
      key: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_DETAILS,
      value: { metaData: [], fieldAccess: [] },
    }),
  );
  fetcherGet(`${APIS.BUDGETS}/jurisdictions/${jurisdictionId}/budget-details`, {},
    {
      pageName: 'Page_ManageJurisdictionBudget',
      componentName: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_DETAILS,
    })
    .then((response) => {
      dispatch(setBudgetDetails(response?.data));
      const { metaData, fieldAccess } = response;
      dispatch(
        setFLP({
          key: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_DETAILS,
          value: { metaData, fieldAccess },
        }),
      );
      dispatch(setISLoadingBudgetDetails(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setISLoadingBudgetDetails(false));
    });
};
export const getEffectiveBudgetRecords = (jurisdictionId) => (dispatch) => {
  dispatch(setIsLoadingEffectiveBudgetRecords(true));
  dispatch(
    setFLP({
      key: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS,
      value: { metaData: [], fieldAccess: [] },
    }),
  );
  fetcherGet(`${APIS.BUDGETS}/jurisdictions/${jurisdictionId}/budget-records`, {
      budgetStatus: `${statusTypes.APPROVED}`,
    },
    {
      pageName: 'Page_ManageJurisdictionBudget',
      componentName: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS,
    })
    .then((response) => {
      dispatch(setEffectiveBudgetRecords(response?.data));
      const { metaData, fieldAccess } = response;
      dispatch(
        setFLP({
          key: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS,
          value: { metaData, fieldAccess },
        }),
      );
      dispatch(setIsLoadingEffectiveBudgetRecords(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsLoadingEffectiveBudgetRecords(false));
    });
};
export const getProposedBudgetRecords = (jurisdictionId, isJurisdictionUserAuthority = false) => (dispatch) => {
  dispatch(setIsLoadingProposedBudgetRecords(true));
  dispatch(
    setFLP({
      key: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS,
      value: { metaData: [], fieldAccess: [] },
    }),
  );
  fetcherGet(`${APIS.BUDGETS}/jurisdictions/${jurisdictionId}/budget-records`, {
      budgetStatus: isJurisdictionUserAuthority ? `${statusTypes.PROPOSED},${statusTypes.REVISIONS_REQUESTED},${statusTypes.PROPOSED_TO_AUTHORITY}` : `${statusTypes.PROPOSED},${statusTypes.REVISIONS_REQUESTED}`,
    },
    {
      pageName: 'Page_ManageJurisdictionBudget',
      componentName: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS,
    })
    .then((response) => {
      dispatch(setProposedBudgetRecords(response?.data));
      const { metaData, fieldAccess } = response;
      dispatch(
        setFLP({
          key: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS,
          value: { metaData, fieldAccess },
        }),
      );
      dispatch(setIsLoadingProposedBudgetRecords(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsLoadingProposedBudgetRecords(false));
    });
};
export const getWCIProposedBudgetRecords = (jurisdictionId) => (dispatch) => {
  dispatch(setIsLoadingProposedBudgetRecords(true));
  dispatch(
    setFLP({
      key: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS,
      value: { metaData: [], fieldAccess: [] },
    }),
  );
  fetcherGet(`${APIS.BUDGETS}/jurisdictions/${jurisdictionId}/budget-records`, {
      budgetStatus: `${statusTypes.PROPOSED},${statusTypes.PROPOSED_TO_AUTHORITY},${statusTypes.REVISIONS_REQUESTED}`,
    },
    {
      pageName: 'Page_ManageJurisdictionBudget',
      componentName: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS,
    })
    .then((response) => {
      dispatch(setProposedBudgetRecords(response?.data));
      const { metaData, fieldAccess } = response;
      dispatch(
        setFLP({
          key: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS,
          value: { metaData, fieldAccess },
        }),
      );
      dispatch(setIsLoadingProposedBudgetRecords(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsLoadingProposedBudgetRecords(false));
    });
};
export const getProposedToAuthorityBudgetRecords = (jurisdictionId) => (dispatch) => {
  dispatch(setIsLoadingProposedBudgetRecords(true));
  dispatch(
    setFLP({
      key: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS,
      value: { metaData: [], fieldAccess: [] },
    }),
  );
  fetcherGet(`${APIS.BUDGETS}/jurisdictions/${jurisdictionId}/budget-records`, {
      budgetStatus: `${statusTypes.PROPOSED_TO_AUTHORITY}`,
    },
    {
      pageName: 'Page_ManageJurisdictionBudget',
      componentName: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS,
    })
    .then((response) => {
      dispatch(setProposedBudgetRecords(response?.data));
      const { metaData, fieldAccess } = response;
      dispatch(
        setFLP({
          key: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS,
          value: { metaData, fieldAccess },
        }),
      );
      dispatch(setIsLoadingProposedBudgetRecords(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsLoadingProposedBudgetRecords(false));
    });
};
export const getProposedToAdminBudgetRecords = (jurisdictionId) => (dispatch) => {
  dispatch(setIsLoadingProposedBudgetRecords(true));
  dispatch(
    setFLP({
      key: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS,
      value: { metaData: [], fieldAccess: [] },
    }),
  );
  fetcherGet(`${APIS.BUDGETS}/jurisdictions/${jurisdictionId}/budget-records`, {
      budgetStatus: `${statusTypes.PROPOSED},${statusTypes.PROPOSED_CHANGES}`,
    },
    {
      pageName: 'Page_ManageJurisdictionBudget',
      componentName: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS,
    })
    .then((response) => {
      dispatch(setProposedBudgetRecords(response?.data));
      const { metaData, fieldAccess } = response;
      dispatch(
        setFLP({
          key: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_PROPOSED_RECORDS,
          value: { metaData, fieldAccess },
        }),
      );
      dispatch(setIsLoadingProposedBudgetRecords(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsLoadingProposedBudgetRecords(false));
    });
};
export const getAllowanceHistory = (jurisdictionId) => (dispatch) => {
  dispatch(setIsLoadingAllowanceHistory(true));
  dispatch(
    setFLP({
      key: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_HISTORY,
      value: { metaData: [], fieldAccess: [] },
    }),
  );
  fetcherGet(`${APIS.BUDGETS}/jurisdictions/${jurisdictionId}/request-status-history`, {},
    {
      pageName: 'Page_ManageJurisdictionBudget',
      componentName: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_HISTORY,
    })
    .then((response) => {
      dispatch(setAllowanceHistory(response?.data));
      const { metaData, fieldAccess } = response;
      dispatch(
        setFLP({
          key: JURISDICTION_BUDGET_COMPONENTS_FLP.JURISDICTION_BUDGET_HISTORY,
          value: { metaData, fieldAccess },
        }),
      );
      dispatch(setIsLoadingAllowanceHistory(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsLoadingAllowanceHistory(false));
    });
};
export const getStatus = () => (dispatch) => {
  dispatch(setIsLoadingStatus(true));
  fetcherGet(`${APIS.ENTITY_STATUSES}`, { type: 'BUDGET' })
    .then((response) => {
      dispatch(setStatus(response?.data));

      dispatch(setIsLoadingStatus(false));
    })
    .catch((error) => {
      logger({ error });
      dispatch(setIsLoadingStatus(false));
    });
};
