import { Box, Container, Grid, Typography } from '@mui/material';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useEffectOnce } from 'hooks/useEffectOnce';
import { LoginForm } from 'pages/LandingPageKnown/components/LoginForm';
import { getFileUrl } from 'store/configSlice';
import {
  defaultORGID,
  enLanguage,
  getCurrentPageName,
  getFormLabels,
  HeaderImageLabelText,
  isEmpty,
  LandingPageName,
  parseOptions,
  t,
} from 'utils/constants';

import BannerImage from '../../assets/images/banner-image.png';
import Style from './styles';

function Banner({ loginOnSubmit }) {
  const { t: t1 } = useTranslation(getCurrentPageName(), {
    useSuspense: true,
  });
  const dispatch = useDispatch();

  // router hooks and methods
  const location = useLocation();

  // Redux states
  const { selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);
  const { defaultImageUrl } = useSelector(({ config }) => config);

  // React states
  const [imageUrl, setImageUrl] = useState(BannerImage);

  useEffectOnce(() => {
    dispatch(
      getFileUrl({
        OrgID: selectedJurisdiction || defaultORGID,
        PageLanguage: enLanguage(),
        Page: LandingPageName,
        QueryType: getFormLabels,
      }),
    );
  }, []);

  useEffect(() => {
    if (defaultImageUrl?.data?.length > 0) {
      defaultImageUrl?.data?.find((eachItem) => {
        if (eachItem?.SK?.split('#').pop() === HeaderImageLabelText) {
          if (isEmpty(eachItem?.LabelSaved) && isEmpty(eachItem?.LabelPublished)) {
            setImageUrl(BannerImage);
          } else {
            setImageUrl(
              isEmpty(eachItem?.LabelPublished) ? BannerImage : eachItem?.LabelPublished?.replace('#', '%23'),
            );
          }
        }
        return null;
      });
    } else {
      setImageUrl(BannerImage);
    }
  }, [defaultImageUrl]);

  return (
    <Box sx={Style.Banner} style={{ backgroundImage: `url(${imageUrl})` }}>
      <Container maxWidth="xl">
        <Grid container maxWidth="xl" alignItems="center">
          <Grid item md={8} sm={12}>
            <Typography sx={Style.Platform} variant="h2">
              {t(t1, 'HEADER_IMAGE_TXT')}
            </Typography>
          </Grid>
          <Grid item md={4} sm={12}>
            <Box sx={Style.Form}>
              {/* <LoginForm loginOnSubmit={loginOnSubmit} /> */}
              <LoginForm loginOnSubmit={loginOnSubmit} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Banner;
