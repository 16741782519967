import { Box, Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import { useSelector } from 'react-redux';
import { CounterField } from 'components/CounterField';
import {
  getCurrentPageName,
  isRolesAssigned,
  t,
  USER_ROLE_TYPES,
  USER_ROLES,
  countRegex,
  REPRESENTATIVE_CONFIG_VALUES,
} from '../../utils/constants';
import { accountRepresentativesData } from './constants';
import Style from './styles';
import {
  StyledAccountLabelTypograpy,
  StyledBox,
  StyledCheckbox,
  StyledFormControlLabel,
  StyledBoxEntityDetail,
  StyledTypograpyEntityDetail,
} from './ConfigurationSettings.style';

export function AccountSettings({ updateErrorValue, configureState, methods }) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { control, getValues } = useFormContext();
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { isJurisdictionSystemAdminUser } = useMemo(
    () => ({
      isJurisdictionSystemAdminUser: !!isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.JURISDICTION_SYSTEM_ADMIN],
      ),
    }),
    [userDetails],
  );
  const validateParAarMin = (label) => {
    const a = parseInt(
      getValues(
        `entity-account-representative-settings.covered_par_minimum.AttributeValue`,
      ),
      10,
    );
    const b = parseInt(
      getValues(
        `entity-account-representative-settings.covered_aar_minimum.AttributeValue`,
      ),
      10,
    );
    const c = parseInt(
      getValues(
        `entity-account-representative-settings.gmpi_par_minimum.AttributeValue`,
      ),
      10,
    );
    const d = parseInt(
      getValues(
        `entity-account-representative-settings.gmpi_aar_minimum.AttributeValue`,
      ),
      10,
    );
    const e = parseInt(
      getValues(
        `entity-account-representative-settings.gmpo_par_minimum.AttributeValue`,
      ),
      10,
    );
    const f = parseInt(
      getValues(
        `entity-account-representative-settings.gmpo_aar_minimum.AttributeValue`,
      ),
      10,
    );
    if (label === REPRESENTATIVE_CONFIG_VALUES.MINIMUM) {
      if (a === 0 && b === 0) {
        methods.setError(
          'entity-account-representative-settings.covered_par_minimum.AttributeValue',
          { type: 'custom', message: t(t1, 'PAR_AAR_CANNOT_BE_ZERO') },
        );
        methods.setError(
          'entity-account-representative-settings.covered_aar_minimum.AttributeValue',
          { type: 'custom', message: t(t1, 'PAR_AAR_CANNOT_BE_ZERO') },
        );

      }
      if (c === 0 && d === 0) {
        methods.setError(
          'entity-account-representative-settings.gmpi_par_minimum.AttributeValue',
          { type: 'custom', message: t(t1, 'PAR_AAR_CANNOT_BE_ZERO') },
        );
        methods.setError(
          'entity-account-representative-settings.gmpi_aar_minimum.AttributeValue',
          { type: 'custom', message: t(t1, 'PAR_AAR_CANNOT_BE_ZERO') },
        );
      }
      if (e === 0 && f === 0) {
        methods.setError(
          'entity-account-representative-settings.gmpo_par_minimum.AttributeValue',
          { type: 'custom', message: t(t1, 'PAR_AAR_CANNOT_BE_ZERO') },
        );
        methods.setError(
          'entity-account-representative-settings.gmpo_aar_minimum.AttributeValue',
          { type: 'custom', message: t(t1, 'PAR_AAR_CANNOT_BE_ZERO') },
        );
      }
    }
  };
  return (
    <Grid
      item
      sm={12}
    >
      <Typography
        variant="h4"
        sx={{ fontWeight: 600, textTransform: 'uppercase' }}
        gutterBottom
      >
        {t(t1, 'ENTITY_ACCOUNT_SETTINGS')}
      </Typography>
      <div className="paneldetail">
        {/** Account Representative start here */}
        <Accordion sx={Style.accordianStyling}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            className="custompanel"
            id="panel5a-header"
            sx={{ minHeight: '43px !important', maxHeight: '43px !important' }}
          >
            <Typography>{t(t1, 'ACCOUNT_REPRESENTATIVES')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="default-accounts-main">
              <Box>
                <p className="configuration-setting-intro-text">{t(t1, 'ACCOUNT_REPRESENTATIVES_CONTENT')}</p>
              </Box>
              <Box>
                <Box>
                  {accountRepresentativesData.map((item) => (
                    <StyledBox>
                      <StyledAccountLabelTypograpy>{t(t1, item.entityName)}</StyledAccountLabelTypograpy>
                      {item.entityDetails.map((entityDetail, index) => (
                        <div className="plus-minus-btn-main">
                          <StyledBoxEntityDetail>
                            <StyledTypograpyEntityDetail>{t(t1, `${entityDetail.roles}`)}</StyledTypograpyEntityDetail>
                            {entityDetail.limits.map((limit) => {
                              const name = `entity-account-representative-settings.${limit.name}.AttributeValue`;
                              const privId = `${limit.name}_Attemps`;
                              const { label } = limit;
                              const minValue = 0;
                              const maxKey = entityDetail?.limits?.[1]?.name;
                              const minKey = entityDetail?.limits?.[0]?.name;
                              return (
                                <div
                                  className="custom-counter-field"
                                  key={`${item.entityName}_${label}_${index}`}
                                >
                                  <CounterField
                                    inputClass="hideBrowserDefault"
                                    name={name}
                                    privId={privId}
                                    label={t(t1, label)}
                                    disabled={!isJurisdictionSystemAdminUser}
                                    defaultValue={
                                      configureState['entity-account-representative-settings']?.[limit.name]
                                        ?.AttributeValue || '1'
                                    }
                                    updateValue={(fieldName, val) => {
                                      if (val >= minValue) {
                                        updateErrorValue(fieldName, val);
                                      }
                                    }}
                                    minValue={minValue}
                                    type="text"
                                    rules={{
                                      required: true,
                                      pattern: {
                                        value: countRegex,
                                        message: `${t(t1, 'ERR_INVALID_LABEL_VALUE', { label: `${label}` })}`,
                                      },
                                      validate: (value) => {
                                        if (parseInt(value, 10) < minValue) {
                                          return `${t(t1, 'ERR_INVALID_LABEL_VALUE', { label: `${t(t1, label)}` })}`;
                                        }
                                        if (
                                          label === REPRESENTATIVE_CONFIG_VALUES.MAXIMUM &&
                                          parseInt(value, 10) <
                                          parseInt(
                                            getValues(
                                              `entity-account-representative-settings.${minKey}.AttributeValue`,
                                            ),
                                            10,
                                          )
                                        ) {
                                          return `${t(t1, 'ERR_LABEL_VALUE_MIN_LENGTH_CHAR', { label: `${t(t1, label)}` })}`;
                                        }
                                        if (
                                          label === REPRESENTATIVE_CONFIG_VALUES.MINIMUM &&
                                          parseInt(value, 10) >
                                          parseInt(
                                            getValues(
                                              `entity-account-representative-settings.${maxKey}.AttributeValue`,
                                            ),
                                            10,
                                          )
                                        ) {
                                          return `${t(t1, 'ERR_LABEL_VALUE_MAX_LENGTH_CHAR', { label: `${t(t1, label)}` })}`;
                                        }
                                        validateParAarMin(label);
                                        return true;
                                      },
                                    }}
                                    isRequiredMsg={`${t(t1, 'ERR_LABEL_IS_REQUIRED', { label: `${t(t1, label)}` })}`}

                                  />
                                </div>
                              );
                            })}
                          </StyledBoxEntityDetail>
                        </div>
                      ))}
                    </StyledBox>
                  ))}
                </Box>
              </Box>
            </div>
          </AccordionDetails>
        </Accordion>
        {/** Account Representative ends here */}
        {/** Account Settings starts here */}
        <Accordion sx={Style.accordianStyling}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            className="custompanel"
            id="panel3a-header"
            sx={{ minHeight: '43px !important', maxHeight: '43px !important' }}
          >
            <Typography>{t(t1, 'DEFAULT_ACCOUNTS')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="default-accounts-main">
              <Box>
                <p className="configuration-setting-intro-text">{t(t1, 'DEFAULT_ACCOUNTS_CONTENT')}</p>
              </Box>
              <Box>
                <StyledBox>
                  <StyledAccountLabelTypograpy>
                    {t(t1, 'COVERED_ENTITY_COVERED_SOURCE_OPT_IN_ENTITY')}
                  </StyledAccountLabelTypograpy>
                  <Box>
                    <StyledFormControlLabel
                      control={
                        <Controller
                          name="entity-default-account-settings.covered_general_account.AttributeValue"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <StyledCheckbox
                              checked={value}
                              disabled={!isJurisdictionSystemAdminUser}
                              onChange={(e) => {
                                updateErrorValue(
                                  'entity-default-account-settings.covered_general_account.AttributeValue',
                                  e?.target?.value,
                                );
                                onChange(e);
                              }}
                            />
                          )}
                        />
                      }
                      disableTypography
                      label={t(t1, 'GENERAL_ACCOUNT')}
                    />
                  </Box>
                  <Box>
                    <StyledFormControlLabel
                      control={
                        <Controller
                          name="entity-default-account-settings.covered_compliance_account.AttributeValue"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <StyledCheckbox
                              checked={value}
                              disabled={!isJurisdictionSystemAdminUser}
                              onChange={(e) => {
                                updateErrorValue(
                                  'entity-default-account-settings.covered_compliance_account.AttributeValue',
                                  e?.target?.value,
                                );
                                onChange(e);
                              }}
                            />
                          )}
                        />
                      }
                      disableTypography
                      label={t(t1, 'COMPLIANCE_ACCOUNT')}
                    />
                  </Box>
                </StyledBox>
                <StyledBox>
                  <StyledAccountLabelTypograpy>
                    {t(t1, 'GENERAL_MARKET_PARTICIPANT_INDIVIDUAL')}
                  </StyledAccountLabelTypograpy>
                  <Box>
                    <StyledFormControlLabel
                      control={
                        <Controller
                          name="entity-default-account-settings.gmpi_general_account.AttributeValue"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <StyledCheckbox
                              checked={value}
                              disabled={!isJurisdictionSystemAdminUser}
                              onChange={(e) => {
                                updateErrorValue(
                                  'entity-default-account-settings.gmpi_general_account.AttributeValue',
                                  e?.target?.value,
                                );
                                onChange(e);
                              }}
                            />
                          )}
                        />
                      }
                      disableTypography
                      label={t(t1, 'GENERAL_ACCOUNT')}
                    />
                  </Box>
                  <Box>
                    <StyledFormControlLabel
                      control={
                        <Controller
                          name="entity-default-account-settings.gmpi_compliance_account.AttributeValue"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <StyledCheckbox
                              checked={value}
                              onChange={(e) => {
                                updateErrorValue(
                                  'entity-default-account-settings.gmpi_compliance_account.AttributeValue',
                                  e?.target?.value,
                                );
                                onChange(e);
                              }}
                              disabled
                            />
                          )}
                        />
                      }
                      disableTypography
                      label={t(t1, 'COMPLIANCE_ACCOUNT')}
                    />
                  </Box>
                </StyledBox>
                <StyledBox>
                  <StyledAccountLabelTypograpy>
                    {t(t1, 'GENERAL_MARKET_PARTICIPANT_ORGANIZATION')}
                  </StyledAccountLabelTypograpy>
                  <Box>
                    <StyledFormControlLabel
                      control={
                        <Controller
                          name="entity-default-account-settings.gmpo_general_account.AttributeValue"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <StyledCheckbox
                              checked={value}
                              disabled={!isJurisdictionSystemAdminUser}
                              onChange={(e) => {
                                updateErrorValue(
                                  'entity-default-account-settings.gmpo_general_account.AttributeValue',
                                  e?.target?.value,
                                );
                                onChange(e);
                              }}
                            />
                          )}
                        />
                      }
                      disableTypography
                      label={t(t1, 'GENERAL_ACCOUNT')}
                    />
                  </Box>
                  <Box>
                    <StyledFormControlLabel
                      control={
                        <Controller
                          name="entity-default-account-settings.gmpo_compliance_account.AttributeValue"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <StyledCheckbox
                              checked={value}
                              onChange={(e) => {
                                updateErrorValue(
                                  'entity-default-account-settings.gmpo_compliance_account.AttributeValue',
                                  e?.target?.value,
                                );
                                onChange(e);
                              }}
                              disabled
                            />
                          )}
                        />
                      }
                      disableTypography
                      label={t(t1, 'COMPLIANCE_ACCOUNT')}
                    />
                  </Box>
                </StyledBox>
              </Box>
            </div>
          </AccordionDetails>
        </Accordion>
        {/** Account Settings ends here */}
      </div>
    </Grid>
  );
}
