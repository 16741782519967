const Style = {
  '& .bottom-btn': {
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 1100px)': {
      marginTop: '30px',
    },
  },

  '& .selectbox-custom .MuiFormControl-root': {
    background: '#fff',
  },
  noticebox: {
    background: 'rgba(128, 174, 190, 0.2)',
    width: '340px',
    maxHeight: '234px',
    overflow: 'auto',
    marginLeft: 'auto',
    borderTop: '4px solid #4d8da4',
    borderBottom: '4px solid #4d8da4',
    paddingRight: '30px',
    paddingLeft: '15px',
    paddingTop: '12px',
    '&::-webkit-scrollbar': {
      width: '12px',
      background: '#F5F5F5',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
      borderRadius: '10px',
      background: 'rgba(128, 174, 190, 0.2)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
      background: '#4d8da4',
    },

    '& h4': {
      color: '#005c7d',
      fontWeight: '700',
      fontSize: '14px',
      textAlign: 'left',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginBottom: '0px',
      borderBottom: '2px solid #4d8da4',
    },
    '& h5': {
      color: '#005c7d',
      fontWeight: '700',
      fontSize: '14px',
      textAlign: 'left',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginBottom: '0px',
      textTransform: 'uppercase',
    },
    '& ul': {
      padding: '0px',
      paddingBottom: '20px',
      '& li': {
        padding: '0px',
        fontSize: '13px',
      },
    },
    '@media (max-width: 1350px)': {
      width: '100%',
    },
    '@media (max-width: 899px)': {
      margin: '0 auto',
      width: '340px',
      display: 'block',
      marginTop: '15px',
    },
  },
};
export default Style;
