import _ from 'lodash';
import { JURISDICTION_TRANSFER_TYPES_BE, JURISDICTION_TRANSFER_TYPES_FE } from 'utils/constants';

export const MANAGE_JURI_ACCOUNTS_COMPONENTS = {
  ACCOUNTS: 'Accounts',
  ACCOUNT_DETAILS_INFO: 'AccountDetails',
  STATUS_CHANGE: 'StatusChange',
  EDIT_ACCOUNT: 'EditAccountDetails',
  UPDATE_ACCOUNT: 'editJurisdictionAccount',
};

export const MANAGE_JURI_ACCOUNTS_PAGES = {
  MANAGE_JURI_ACCOUNTS: 'Page_ManageJurisdictionAccounts',
  JURISDICTION_ACCOUNT_DETAILS: 'Page_JurisdictionAccountDetails',
  EDIT_ACCOUNT_DETAILS: 'Page_EditJurisdictionAccounts',
};

// function to convert api response into multiple select dropdown input
export const transformInstrumentsToDropdown = (data, disabled) =>
  data?.map((item) => ({
    value: item?.id,
    name: item?.name,
    displayName: item?.name,
    disabled: item?.mandatory ? item?.mandatory : disabled,
  }));

export const transformTransfersToDropdown = (data, disabled) =>
  data?.filter((i) => JURISDICTION_TRANSFER_TYPES_BE[i?.name])
    ?.map((item) => ({
      value: item?.id,
      name: item?.name,
      displayName: JURISDICTION_TRANSFER_TYPES_FE[item?.name],
      disabled: item?.mandatory ? item?.mandatory : disabled,
    }));

export const arrayIntersection = (arr1, arr2) => {
  arr1 = _.uniq(arr1);
  const result = arr1
    .map(item1 => arr2.find(item2 => item2?.name === item1))
    .map(item => item?.value);
  return result;
};

const getTypeOfTransferStr = (str) => {
  const resultArr = str.split(',').filter(item => JURISDICTION_TRANSFER_TYPES_BE[item.trim()])?.map(i => JURISDICTION_TRANSFER_TYPES_FE[i.trim()]);
  return resultArr.join(', ');
};

export const transformJurisdictionAccounts = (data) =>
  data?.map((item) => ({
    ...item,
    typeOfTransfers: item?.typeOfTransfers ? getTypeOfTransferStr(item.typeOfTransfers) : '',
  }));