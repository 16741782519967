import { t } from 'utils/constants';
import { StyledRequiredAstrik } from '../NAICSCodeManagement/NAICSCodeManagement.style';
import NumberTextField from './customCellEditor';
import { isCurrentFieldRequired, isFieldValid, isValueLarger } from './utils';

export const AddBudgetSchema = (t1, onChange) => [
  {
    id: 1,
    field: 'budgetYear',
    headerName: <strong style={{ fontWeight: 700 }}>{t(t1, 'BUDGET_YEAR')}</strong>,
    width: 50,
    flex: 1,
    sortable: false,
  },
  {
    id: 2,
    field: 'annualAllowanceBudget',
    headerName: t(t1, 'ANNUAL_ALLOWANCE_BUDGET'),
    type: 'number',
    flex: 2,
    sortable: false,
    renderHeader: () => (
      <strong>
        {t(t1, 'ANNUAL_ALLOWANCE_BUDGET')}
        <StyledRequiredAstrik variant="body2">*</StyledRequiredAstrik>
      </strong>
    ),
    renderCell: (params) => (
      <div onDoubleClick={(event) => event.stopPropagation()}>
        <NumberTextField
          name={`${params.row.budgetYear}_${t(t1, 'ANNUAL_ALLOWANCE_BUDGET')}`}
          value={params.row.annualAllowanceBudget}
          rules={{
            validate: {
              fieldRequired: () => {
                const { reserveAdjustedAllowanceBudget, annualAllowanceBudget, budgetYear } = params.row;
                return isCurrentFieldRequired(
                  reserveAdjustedAllowanceBudget,
                  annualAllowanceBudget,
                  `${budgetYear}: ${t(t1, 'ERR_ANNUAL_ALLOWANCE_BUDGET_IS_REQUIRED')} `,
                );
              },
              isValid: () => {
                const { annualAllowanceBudget, budgetYear } = params.row;
                return isFieldValid(
                  annualAllowanceBudget,
                  `${budgetYear}: ${t(t1, 'ERR_ANNUAL_ALLOWANCE_BUDGET_MUST_WHOLE_NO')} `,
                );
              },
              isNotSkipped: () => {
                let nextRow = params.api.getRow(params.id + 1);
                let isNotSkipped = true;
                while (nextRow) {
                  const { annualAllowanceBudget } = nextRow;
                  if (params.row.annualAllowanceBudget === null && annualAllowanceBudget !== null) {
                    isNotSkipped = `${params.row.budgetYear}: ${t(t1, 'ERR_ANNUAL_ALLOWANCE_BUDGET_CANNOT_SKIP')} `;
                  }
                  nextRow = params.api.getRow(nextRow.id + 1);
                }
                return isNotSkipped;
              },
            },
          }}
          onChange={(value) => {
            const newValue = value.length ? value : null;
            const { field } = params;
            onChange(params.row.budgetYear, field, newValue);
          }}
        />
      </div>
    ),
  },
  {
    id: 3,
    field: 'reserveAdjustedAllowanceBudget',
    headerName: t(t1, 'RESERVE_ADJUSTED_ALLOWANCE_BUDGET'),
    type: 'number',
    flex: 2,
    sortable: false,
    renderHeader: () => (
      <strong>
        {t(t1, 'RESERVE_ADJUSTED_ALLOWANCE_BUDGET')}
        <StyledRequiredAstrik variant="body2">*</StyledRequiredAstrik>
      </strong>
    ),
    renderCell: (params) => (
      <div onDoubleClick={(event) => event.stopPropagation()}>
        <NumberTextField
          name={`${params.row.budgetYear}_${t(t1, 'RESERVE_ADJUSTED_ALLOWANCE_BUDGET')}`}
          value={params.row.reserveAdjustedAllowanceBudget}
          rules={{
            validate: {
              fieldRequired: () => {
                const { reserveAdjustedAllowanceBudget, annualAllowanceBudget, budgetYear } = params.row;
                return isCurrentFieldRequired(
                  annualAllowanceBudget,
                  reserveAdjustedAllowanceBudget,
                  `${budgetYear} : ${t(t1, 'ERR_RESERVE_ADJUSTED_ALLOWANCE_BUDGET_IS_REQUIRED')}`,
                );
              },
              isValid: () => {
                const { reserveAdjustedAllowanceBudget, budgetYear } = params.row;
                return isFieldValid(
                  reserveAdjustedAllowanceBudget,
                  `${budgetYear}: ${t(t1, 'ERR_RESERVE_ADJUSTED_ALLOWANCE_BUDGET_MUST_WHOLE_NO')}`,
                );
              },
              isLarger: () => {
                const { reserveAdjustedAllowanceBudget, budgetYear, annualAllowanceBudget } = params.row;
                return isValueLarger(
                  annualAllowanceBudget,
                  reserveAdjustedAllowanceBudget,
                  `${budgetYear}: ${t(t1, 'ERR_RESERVE_ADJUSTED_ALLOWANCE_GREATER_ANNUAL_ALLOWANCE_BUDGET')}`,
                );
              },
              isNotSkipped: () => {
                let nextRow = params.api.getRow(params.id + 1);
                let isNotSkipped = true;
                while (nextRow) {
                  const { reserveAdjustedAllowanceBudget } = nextRow;
                  if (params.row.reserveAdjustedAllowanceBudget === null && reserveAdjustedAllowanceBudget !== null) {
                    isNotSkipped = `${params.row.budgetYear}: ${t(t1, 'ERR_RESERVE_ADJUSTED_ALLOWANCE_BUDGET_CANNOT_SKIP')}`;
                  }
                  nextRow = params.api.getRow(nextRow.id + 1);
                }
                return isNotSkipped;
              },
            },
          }}
          onChange={(value) => {
            const newValue = value.length ? value : null;
            const { field } = params;
            onChange(params.row.budgetYear, field, newValue);
          }}
        />
      </div>
    ),
  },
];
