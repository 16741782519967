import { useEffect, useState, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { FormCard } from 'components/FormCard';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GridTextfield } from 'components';
import { useEffectOnce } from 'hooks/useEffectOnce/index.js';
import { useLocation, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { setLoader, setIsFormEditable, setAlternateContactError, setModelMessage } from 'store/commonSlice';
import {
  t,
  getCurrentPageName,
  COMPONENT_MODES,
  isEmpty,
  isRolesAssigned,
  USER_ROLE_TYPES,
  USER_ROLES,
  SopfDetailView,
  COMPONENT_NAME_FOR_ROLE,
  validatePhoneNumber,
  verifyIdentificationNumberWithoutSpecialCharacters,
  emailPatternValidation,
  validateFirstName,
  verifyPosition,
  lastNamePattern,
  validatePattern,
  jobtitleEmployerPattern,
} from 'utils/constants';
import {
  saveAlternateContact, updateAlternateContact, getAlternateContact,
} from 'store/entityApplicationSlice';
import Style from './AddRepresentative.style.js';
import '../../ComponentTextTruncate.css';

const alterNativeContactDefultObject = {
  firstName: null,
  lastName: null,
  position: null,
  mobilePhone: null,
  telephoneNumber: null,
  email: null,
};

export function AlternateContactForm({
                                       saveDisabled = false,
                                       mode = COMPONENT_MODES.ADD,
                                       getAlternateValues = false,
                                       checkAlternateValidation = false,
                                       isEditAccess = true,
                                       viewOnlyProps = true,
                                       updateCardEdit = () => {
                                       },
                                     }) {

  const [AlternateContactMandatory, SetAlternateContactMandatory] = useState(false);
  const { t: t1 } = useTranslation(getCurrentPageName());
  const [stepData, setStepData] = useState({ editMode: false });
  const { id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const {
    AlternateContact,
    EditAccess,
    CancelAlternateContact,
    resetAlternateContactForm,
    fieldPermissions,
  } = useSelector(
    ({ entityApplication }) => entityApplication,
  );
  const { userDetails } = useSelector(({ userHome }) => userHome);

  const { isIndividualUser, isJurisdictionRegistrarUser, isJurisdictionAdminUser } = useMemo(
    () => ({
      isIndividualUser: isRolesAssigned(userDetails, [USER_ROLE_TYPES.INDIVIDUAL_USER]),

      isJurisdictionRegistrarUser: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.REGISTRAR],
      ),
      isJurisdictionAdminUser: isRolesAssigned(
        userDetails,
        [USER_ROLE_TYPES.JURISDICTION_USER],
        [USER_ROLES.ADMIN],
      ),
    }),
    [userDetails],
  );

  let pageDetail = { componentName: COMPONENT_NAME_FOR_ROLE.ALTERNATE_CONTACT_PERSON, pageName: 'Page_EntityProfile' };
  if (isJurisdictionRegistrarUser) {
    pageDetail = {
      componentName: COMPONENT_NAME_FOR_ROLE.ALTERNATE_CONTACT_PERSON,
      pageName: 'Page_JurisdictionEntityProfile',
    };
  }

  useEffectOnce(() => {
    dispatch(getAlternateContact(id, pageDetail));
  }, []);

  const defaultValues = { alternateContactPerson: alterNativeContactDefultObject };

  const methodsAlternateContact = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { formState: { isValid, dirtyFields }, setValue } = methodsAlternateContact;

  const handleAlternateFormValidation = () => {

    if (!isEmpty(methodsAlternateContact?.getValues('alternateContactPerson.firstName'))
      && !isEmpty(methodsAlternateContact?.getValues('alternateContactPerson.lastName'))) {
      SetAlternateContactMandatory(true);
    } else {
      SetAlternateContactMandatory(false);
    }

    setTimeout(() => {
      if (isEmpty(methodsAlternateContact?.getValues('alternateContactPerson.firstName'))
        || isEmpty(methodsAlternateContact?.getValues('alternateContactPerson.lastName'))) {
        methodsAlternateContact.trigger();
      } else {
        methodsAlternateContact.trigger('alternateContactPerson.lastName');
      }
    }, 100);
  };

  if (getAlternateValues) {
    getAlternateValues(methodsAlternateContact?.getValues('alternateContactPerson'));
  }


  const setDefaultValues = () => {
    methodsAlternateContact?.reset(defaultValues);
  };

  useEffect(() => {
    if (!isEmpty(AlternateContact?.data)) {
      setValue('alternateContactPerson', AlternateContact?.data, { shouldValidate: true });
    } else setDefaultValues();
  }, [AlternateContact?.data]);

  useEffect(() => {
    if (!isEmpty(AlternateContact?.data)) {
      handleAlternateFormValidation();
    }
  }, [AlternateContact?.data]);

  const handleEnableEdit = (value) => {
    setStepData({ ...stepData, editMode: value });
    updateCardEdit({ cardName: 'ALTERNATE_CONTACT_INFO', isEditing: value });
    dispatch(setIsFormEditable(true));
  };

  const handleSubmitUpdate = () => {
    const AlternateContactPersonValues = methodsAlternateContact.getValues('alternateContactPerson');
    const contact = { contact: { ...AlternateContactPersonValues, entityId: id } };

    const handleSuccess = () => {
      dispatch(setIsFormEditable(false));
      handleEnableEdit(false);
    };

    const handleError = (error) => {
      dispatch(setModelMessage({
        title: 'COMMON_ERROR',
        message1: error.message.replace('ALTERNATE_CONTACT_ERROR:', ''),
        error: true,
      }));
    };
    const performSaveOrUpdateForm = (action) => {
      dispatch(action)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => {
          dispatch(setLoader(false));
        });
    };

    dispatch(setLoader(true));
    updateCardEdit({ cardName: 'ALTERNATE_CONTACT_INFO', isEditing: false });

    if (AlternateContact?.data?.id) {
      performSaveOrUpdateForm(updateAlternateContact(contact, id, {}, true));
    } else if (!isEmpty(contact.contact?.firstName) || !isEmpty(contact.contact?.lastName)) {
      performSaveOrUpdateForm(saveAlternateContact(contact, id, {}, true));
    }
  };

  const handleCancelAlternateRepresentative = () => {
    if (AlternateContact?.data) {
      methodsAlternateContact?.reset();
      setValue('alternateContactPerson', AlternateContact?.data, { shouldValidate: true });
    } else {
      methodsAlternateContact?.reset(defaultValues);
    }
    handleAlternateFormValidation();
    updateCardEdit({ cardName: 'ALTERNATE_CONTACT_INFO', isEditing: false });
    dispatch(setIsFormEditable(false));
  };

  useEffect(() => {
    handleCancelAlternateRepresentative();
  }, [CancelAlternateContact]);


  useEffect(() => {
    if (resetAlternateContactForm) methodsAlternateContact.reset({}, { keepValues: true });
  }, [resetAlternateContactForm]);


  const viewOnly = !stepData.editMode && viewOnlyProps;
  const isEditable = EditAccess;

  const isALternativeDirty = Object.values(dirtyFields || {}).some(e => e);

  if (!isValid) {
    dispatch(setAlternateContactError(true));
  } else dispatch(setAlternateContactError(false));

  if (checkAlternateValidation) {
    if (pathname.includes('/entity-revision-requested/') || pathname.includes('/entity-profile/')) {
      checkAlternateValidation(isValid && isALternativeDirty);
    } else {
      checkAlternateValidation(isValid);
    }
  }

  return (
    <div className={`MODE_${mode} ${
      viewOnly ? 'altContact-viewonly' : 'altContact-editonly'
    }`}>
      <FormProvider {...methodsAlternateContact}>
        <FormCard
          customClass={'customFormCard'}
          title={t(t1, 'ALTERNATE_CONTACT_PERSON')}
          indicateRequired
          setDefaultValues={setDefaultValues}
          customeStyle={viewOnly ? Style.btnSpacing : Style.paddingB20}
          saveDisabled={!isValid || !isALternativeDirty}
          handleEnableEdit={handleEnableEdit}
          handleSubmitUpdate={handleSubmitUpdate}
          handleCancelAlternateRepresentative={handleCancelAlternateRepresentative}
          mode={mode}
          stepData={stepData}
          isEditAccess={isEditable}
          noMargin
          titleSize
        >
          <Grid
            container
            spacing={2}
            sx={{ mt: 0 }}
          >
            {SopfDetailView(<Grid
              item
              xs={4}
              sx={{ mt: '-13px' }}
            >
              <GridTextfield
                componentName="contact"
                fieldName="firstName"
                noValidate
                name="alternateContactPerson.firstName"
                label={t(t1, 'FIRST_NAME')}
                rules={{
                  required: AlternateContactMandatory,
                  maxLength: {
                    value: 50,
                    message: t(t1, 'ERR_FIRST_NAME_MAX_LENGTH_CHAR'),
                  },
                  validate: (v) =>
                    methodsAlternateContact?.getValues('alternateContactPerson.firstName')
                      ? validateFirstName(v, t(t1, 'FIRST_NAME'), t1)
                      : null,
                }}
                isRequiredMsg={AlternateContactMandatory ? 'ERR_FIRST_NAME_IS_REQUIRED' : ''}
                trimLeadingSpaces
                gridMd={12}
                onKeyUp={(e) => handleAlternateFormValidation()}
                disabled={!isEditable}
                isViewOnly={viewOnly || isJurisdictionRegistrarUser}
                customTextClassName={viewOnly ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'contact', 'firstName', fieldPermissions)}
            {SopfDetailView(<Grid
              item
              xs={4}
              sx={{ mt: '-13px' }}
            >
              <GridTextfield
                componentName="contact"
                fieldName="lastName"
                noValidate
                name="alternateContactPerson.lastName"
                label={t(t1, 'LAST_NAME')}
                rules={{
                  required: AlternateContactMandatory,
                  maxLength: {
                    value: 100,
                    message: t(t1, 'ERR_LAST_NAME_MAX_LENGTH_CHAR'),
                  },
                  validate: (v) =>
                    methodsAlternateContact?.getValues('alternateContactPerson.lastName')
                      ? validatePattern(v, t1, `${t(t1, 'LAST_NAME')}`, lastNamePattern, `${t(t1, 'ERR_INVALID_LAST_NAME')}`)
                      : null,
                }}
                isRequiredMsg={AlternateContactMandatory ? 'ERR_LAST_NAME_IS_REQUIRED' : ''}
                trimLeadingSpaces
                gridMd={12}
                onKeyUp={(e) => handleAlternateFormValidation()}
                disabled={!isEditable}
                isViewOnly={viewOnly || isJurisdictionRegistrarUser}
                customTextClassName={viewOnly ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'contact', 'lastName', fieldPermissions)}
            {SopfDetailView(<Grid
              item
              xs={4}
              sx={{ mt: '-13px' }}
            >
              <GridTextfield
                componentName="contact"
                fieldName="position"
                noValidate
                name="alternateContactPerson.position"
                label={t(t1, 'POSITION')}
                rules={{
                  required: AlternateContactMandatory,
                  maxLength: {
                    value: 255,
                    message: t(t1, 'ERR_POSITION_MAX_LENGTH_CHAR'),
                  },
                  validate: (v) =>
                    methodsAlternateContact?.getValues('alternateContactPerson.position')
                      ? validatePattern(v, t(t1, 'POSITION'), t1, jobtitleEmployerPattern, t(t1, 'ERR_INVALID_POSITION'))
                      : null,
                }}
                isRequiredMsg={AlternateContactMandatory ? 'ERR_POSITION_IS_REQUIRED' : ''}
                trimLeadingSpaces
                gridMd={12}
                onKeyUp={(e) => handleAlternateFormValidation()}
                disabled={!isEditable}
                isViewOnly={viewOnly || isJurisdictionRegistrarUser}
                customTextClassName={viewOnly ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'contact', 'position', fieldPermissions)}
          </Grid>

          <Grid
            container
            spacing={2}
          >
            {SopfDetailView(<Grid
              item
              xs={4}
            >
              <GridTextfield
                componentName="contact"
                fieldName="mobilePhone"
                noValidate
                name="alternateContactPerson.mobilePhone"
                label={t(t1, 'MOBILE_PHONE')}
                rules={{
                  required: AlternateContactMandatory,
                  validate: (v) =>
                    methodsAlternateContact?.getValues('alternateContactPerson.mobilePhone')
                      ? validatePhoneNumber(v, `${t(t1, 'MOBILE_PHONE')}`, t1, `${t(t1, 'ERR_MOBILE_PHONE_MIN_LENGTH_CHAR')}`, `${t(t1, 'ERR_MOBILE_PHONE_MAX_LENGTH_CHAR')}`, `${t(t1, 'ERR_INVALID_MOBILE_PHONE')}`, '')
                      : null,
                }}
                isRequiredMsg={AlternateContactMandatory ? 'ERR_MOBILE_PHONE_IS_REQUIRED' : ''}
                trimLeadingSpaces
                gridMd={12}
                onKeyUp={(e) => handleAlternateFormValidation()}
                disabled={!isEditable}
                isViewOnly={viewOnly || isJurisdictionRegistrarUser}
              />
            </Grid>, 'contact', 'mobilePhone', fieldPermissions)}

            {SopfDetailView(<Grid
              item
              xs={4}
            >
              <GridTextfield
                componentName="contact"
                fieldName="telephoneNumber"
                noValidate
                name="alternateContactPerson.telephoneNumber"
                label={t(t1, 'TELEPHONE_NUMBER')}
                rules={{
                  required: AlternateContactMandatory,
                  validate: (v) =>
                    methodsAlternateContact?.getValues('alternateContactPerson.telephoneNumber')
                      ? validatePhoneNumber(v, `${t(t1, 'TELEPHONE_NUMBER')}`, t1, `${t(t1, 'ERR_TELEPHONE_NUMBER_MIN_LENGTH_CHAR')}`, `${t(t1, 'ERR_TELEPHONE_NUMBER_MAX_LENGTH_CHAR')}`, `${t(t1, 'ERR_INVALID_TELEPHONE_NUMBER')}`, '')
                      : null,
                }}
                isRequiredMsg={AlternateContactMandatory ? 'ERR_TELEPHONE_NUMBER_IS_REQUIRED' : ''}
                trimLeadingSpaces
                gridMd={12}
                onKeyUp={(e) => handleAlternateFormValidation()}
                disabled={!isEditable}
                isViewOnly={viewOnly || isJurisdictionRegistrarUser}
              />
            </Grid>, 'contact', 'telephoneNumber', fieldPermissions)}
            {SopfDetailView(<Grid
              item
              xs={4}
            >
              <GridTextfield
                componentName="contact"
                fieldName="email"
                noValidate
                name="alternateContactPerson.email"
                label={t(t1, 'EMAIL_ADDRESS')}
                rules={{
                  required: AlternateContactMandatory,
                  maxLength: {
                    value: 205,
                    message: `${t(t1, 'ERR_EMAIL_ADDRESS_MAX_LENGTH_CHAR')}`,
                  },
                  pattern: {
                    value: emailPatternValidation,
                    message: `${t(t1, 'ERR_INVALID_EMAIL_ADDRESS')}`,
                  },
                }}
                onKeyDown={(_) => {
                  if (_.code === 'Space') _.preventDefault();
                }}
                isRequiredMsg={AlternateContactMandatory ? 'ERR_EMAIL_ADDRESS_IS_REQUIRED' : ''}
                gridMd={12}
                onKeyUp={(e) => handleAlternateFormValidation()}
                disabled={!isEditable}
                isViewOnly={viewOnly || isJurisdictionRegistrarUser}
                customTextClassName={viewOnly ? 'viewOnlyTextareaContent' : ''}
              />
            </Grid>, 'contact', 'email', fieldPermissions)}
          </Grid>
        </FormCard>
      </FormProvider>
    </div>
  );
}