import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useClickOutside } from 'hooks';
import useAuth from 'hooks/useAuth';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { setRedirectionPath } from 'store/createUserProfileSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  DenyStatus,
  JurisdictionUser,
  NAvalue,
  PendingStatus,
  ServiceProviderUser,
  SYSTEM_USER,
  USER_ROLE_TYPES,
  getCurrentPageName,
  isEmpty,
  isRolesAssigned,
  t, InactiveStatus,
  IndividualUser,
  JSA_ROLE,
  WCIIncAdmin,
  MarketMonitor,
  FSAHome,
  AuctionAdministrator,
} from 'utils/constants';
import { Typography } from '@mui/material';
import { checkIsAuthenticated, getMenuAccess } from 'store/authSlice';
import { setDialogMessage } from 'store/commonSlice';
import {
  resetSteps,
  setRepresentative,
  setStateProvince,
  setStateProvinceforMailing,
  setEntityType,
  setSelectedOrganization,
  setRepresentativeOnLocal,
} from 'store/entityApplicationSlice';
import { setRouteState } from 'store/userSlice';
import { useJurisdictionUserRole } from 'hooks/useUserRole';

import MenuComponent from '../Menu/Menu';
/* ReportsComponent */
import REPORTSComponent from '../Reports/Reports';
import Style from './HeaderMenu.style';


export default function HeaderMenu(props) {
  /* Conflict Resolution Candidate
    const { isParentTabOpen, closeParentTab } = props;
   */
  const { isParentTabOpen } = props;
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { menu } = useSelector(({ auth }) => auth);
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { routeState } = useSelector(({ user }) => user);
  const {
    isFSA,
    isFSAQA,
    isAAR,
    isPAR,
    isAVA,
    isPublicUser,
    isApplicant,
    isWCIincAdmin,
    isIndividualUser,
    isMarketMonitor,
    isAuctionAdministrator,
  } = useJurisdictionUserRole();

  const {
    formSteps: { activeStep },
  } = useSelector(({ createUserProfile }) => createUserProfile);
  const { PrivAttributes } = menu;
  const location = useLocation();
  const navigate = useNavigate();
  const [subMenu, setSubMenu] = useState({
    user: false,
    setting: false,
  });

  const subMenuRef = useRef(null);
  const { isSignedIn, user } = useAuth();
  const dispatch = useDispatch();
  const closeSubMenu = () => {
    setSubMenu({
      user: false,
      setting: false,
      faqsresources: false,
    });
  };

  useClickOutside(subMenuRef, closeSubMenu);

  const { selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);

  useEffect(() => {
    if (user && user.userId && !isEmpty(selectedJurisdiction))
      dispatch(getMenuAccess(selectedJurisdiction, encodeURIComponent(`U#${user.userId}`)));
    if (!checkIsAuthenticated() && isEmpty(user) && !isEmpty(selectedJurisdiction))
      dispatch(getMenuAccess(selectedJurisdiction));
  }, [dispatch, user, selectedJurisdiction]);

  useEffect(() => {
    if (isParentTabOpen)
      closeSubMenu();
  }, [isParentTabOpen]);
  const getHomeRoute = () => {
    let homeRoute = '';
    if (userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) {
      homeRoute = '/jurisdiction-user';
    } else if (userDetails?.RoleDetail?.Role[0]?.name === FSAHome) {
      homeRoute = '/fsa-home';
    } else if (userDetails?.RoleDetail?.Role[0]?.name === MarketMonitor) {
      homeRoute = '/market-monitor';
    } else if (userDetails?.RoleDetail?.Role[0]?.name === AuctionAdministrator) {
      homeRoute = '/auction-administrator';
    } else if ([SYSTEM_USER, IndividualUser].includes(userDetails?.RoleDetail?.RoleType[0]?.name)) {
      homeRoute = '/home';
    }
    return homeRoute;
  };
  const roleDetails = useJurisdictionUserRole()?.userDetails?.RoleDetail?.Role;

  const showCreateLink = userDetails?.ApplicantAccountStatus !== DenyStatus && userDetails?.ApplicantAccountStatus !== PendingStatus && userDetails.UserAccountStatus !== InactiveStatus && userDetails.UserAccountStatus !== InactiveStatus;

  const headerLink = [
    {
      name: t(t1, 'HEADER_HOME'),
      route: getHomeRoute(),
      display: true,
      isSubmenu: false,
      privId: 'priv_btn_Home',
    },
    {
      name: t(t1, 'MENU'),
      route: '/mu',
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: 'priv_h6_MENU',
      signIn: true,
      isComponent: true,
      component: <MenuComponent />,
    },

    {
      name: t(t1, 'REPORTS'),
      route: '/re',
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: 'priv_h6_REPORTS',
      signIn: true,
      isComponent: true,
      component: <REPORTSComponent />,
    },
    {
      name: t(t1, 'AUCTION_ADMINISTRATOR'),
      route: '/re',
      display: isSignedIn && (isAuctionAdministrator || false),
      isSubmenu: true,
      privId: 'priv_h6_REPORTS',
      signIn: true,
      isComponent: true,
      component: <REPORTSComponent />,
    },

    {
      name: t(t1, 'INDIVIDUAL_USER_REPORTS'),
      route: '/re',
      display: isSignedIn && (isAAR || isPAR || isAVA || isPublicUser || isApplicant || isWCIincAdmin || isIndividualUser || false),
      isSubmenu: true,
      privId: 'priv_h6_REPORTS',
      signIn: true,
      isComponent: true,
      component: <REPORTSComponent />,
    },

    {
      name: t(t1, 'ENTITY_ACCOUNT_APPLICATION_CAPS'),
      route: '/start-entity-registration',
      display: ((isSignedIn && isRolesAssigned(userDetails, [USER_ROLE_TYPES.INDIVIDUAL_USER]) && showCreateLink && (userDetails?.RequestStatus !== PendingStatus || userDetails?.RequestStatus !== DenyStatus))) || false,
      isSubmenu: false,
      privId: 'priv_btn_Home',
    },
    {
      name: t(t1, 'HEADER_USER_REGISTRATION'),
      route: '/rg',
      key: 'user',
      display: !isSignedIn || false,
      signIn: false,
      isSubmenu: true,
      privId: 'priv_btn_USER_REGISTRATION',
      submenu: [
        { name: t(t1, 'NEW_REGISTRATION'), route: '/user-registration', privId: 'priv_lnk_New_Registration' },
      ],
    },
    {
      name: t(t1, 'SETTING'),
      route: '/se',
      signIn: true,
      key: 'setting',
      isSubmenu: true,
      privId: 'priv_btn_SETTINGS',
      submenu: userDetails?.RoleDetail?.Role.filter((item) => [JSA_ROLE, WCIIncAdmin].includes(item?.name))?.length > 0 ? [
        {
          name: t(t1, 'CONTENT_MANAGEMENT_SYSTEM'),
          route: '/content-management',
          privId: 'priv_lnk_Content_Management_System',
        },
        { name: t(t1, 'HEADER_CONFIGURATION_SETTINGS'), route: '/settings', privId: 'priv_lnk_Configuration Settings' },
        { name: t(t1, 'HEADER_PRIVILEGE_MANAGEMENT'), route: '/privilege', privId: 'priv_lnk_Privilege Management' },
      ] : [
        { name: t(t1, 'HEADER_CONFIGURATION_SETTINGS'), route: '/settings', privId: 'priv_lnk_Configuration Settings' },
        { name: t(t1, 'HEADER_PRIVILEGE_MANAGEMENT'), route: '/privilege', privId: 'priv_lnk_Privilege Management' },
      ],
    },
    {
      name: t(t1, 'HEADER_MENU_SEARCH')?.toUpperCase(),
      route: '/um',
      signIn: true,
      key: 'usermanagement',
      display: (isSignedIn && userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) || false,
      isSubmenu: true,
      privId: 'priv_h6_USER_MANAGEMENT',
      submenu: [{ name: t(t1, 'SEARCH_USER'), route: '/jurisdiction-user', privId: 'priv_lnk_Search_Users' }],
    },
    {
      name: t(t1, 'FAQS_RESOURCES'),
      route: '/fe',
      key: 'faqsresources',
      display: true,
      isSubmenu: true,
      privId: 'priv_btn_FAQs_&_RESOURCES',
      submenu: [{ name: t(t1, 'FAQS_RESOURCES'), route: '' }],
    },
  ];

  if (roleDetails?.length === 1) {
    headerLink.push(
      {
        name: t(t1, 'FSA_REPORTS'),
        route: '/re',
        display: isSignedIn && (isFSA || isFSAQA || false),
        isSubmenu: true,
        privId: 'priv_h6_REPORTS',
        signIn: true,
        isComponent: true,
        component: <REPORTSComponent />,
      },
      {
        name: t(t1, 'MARKET_REPORTS'),
        route: '/re',
        display: isSignedIn && (isMarketMonitor || false),
        isSubmenu: true,
        privId: 'priv_h6_REPORTS',
        signIn: true,
        isComponent: true,
        component: <REPORTSComponent />,
      },
    );
  } else if (roleDetails?.length === 2) {
    headerLink.push(
      {
        name: t(t1, 'FSA_REPORTS'),
        route: '/re',
        display: isSignedIn && (isFSA || isFSAQA || false),
        isSubmenu: true,
        privId: 'priv_h6_REPORTS',
        signIn: true,
        isComponent: true,
        component: <REPORTSComponent />,
      },
    );
  }

  const handleSubMenu = (key) => {
    props?.closeParentTab();
    if (key) {
      setSubMenu({
        [key]: !subMenu[key],
      });
    } else {
      setSubMenu({
        user: false,
        setting: false,
        faqsresources: false,
      });
    }
  };

  const handleEnterKey = (e, hroute) => {
    if (e.key === 'Enter') {
      changedRoute(hroute);
    }
  };

  const changedRoute = (route) => {
    dispatch(setRouteState({ path: route, toggle: !routeState.toggle }));

    if (route === '/entity-registration' || route === '/start-entity-registration') {
      dispatch(resetSteps());
      dispatch(setRepresentative([]));
      dispatch(setStateProvinceforMailing([]));
      dispatch(setStateProvince([]));
      dispatch(setEntityType(null));
      dispatch(setSelectedOrganization(''));
      dispatch(setRepresentativeOnLocal([]));
    }
    if (location.pathname === '/create-user-profile' && activeStep !== 6) {
      dispatch(setRedirectionPath(route));
      dispatch(setDialogMessage(true));
    } else {
      navigate(route);
    }
    closeSubMenu();
  };

  return (
    <List
      sx={Style.menuWrapper}
      onMouseLeave={closeSubMenu}
    >
      {headerLink
        ?.filter((item) => {
          let data;
          if (item.display === false) {
            return null;
          }
          if (item.signIn && isSignedIn && !item.isComponent && PrivAttributes && PrivAttributes?.[item?.privId]?.PrivRole !== NAvalue) {
            data = item;
          } else if (item.signIn === false && !isSignedIn && PrivAttributes?.[item?.privId]?.PrivRole !== NAvalue) {
            data = item;
          } else if (item.display && !item.isComponent && PrivAttributes?.[item?.privId]?.PrivRole !== NAvalue) {
            data = item;
          } else if (item.signIn && isSignedIn && item.display && item.isComponent) {
            data = item;
          }
          return data;
        })
        .map((header, index) => {
          if (header.isComponent) {
            return <Box key={index}>
              {header.component}
            </Box>;
          }
          return (
            <ListItem
              disablePadding
              key={index}
              sx={Style.menuItem}
            >
              {header.isSubmenu ? (
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => handleSubMenu(header.key)}
                  disableRipple
                  tabIndex={0}
                  sx={{
                    backgroundColor: 'transparent',
                    padding: '0',
                    fontWeight: 400,
                    fontSize: ['7px', '14px'],
                    textTransform: 'none',
                    '&:hover': {
                      fontWeight: 700,
                      backgroundColor: 'transparent',
                      padding: '0',
                      fontSize: ['7px', '14px'],
                      textTransform: 'none',
                    },
                    minWidth: ['auto', '64px'],
                  }}
                >
                  {header.name}
                  <ArrowDropDownIcon sx={{ color: '#fff', width: ['12px', '24px'], height: ['12px', '24px'] }} />
                </Button>
              ) : (
                <Typography
                  tabIndex={0}
                  as="a"
                  sx={{
                    cursor: 'pointer',
                    fontSize: ['7px !important', '14px !important'],
                  }}
                  onMouseEnter={closeSubMenu}
                  onClick={() => changedRoute(header.route)}
                  onKeyDown={(e) => handleEnterKey(e, header.route)}
                >
                  {header.name}
                </Typography>
              )}

              {header.isSubmenu && subMenu[header?.key] ? (
                <Box ref={subMenuRef}>
                  <List
                    sx={[Style.subMenuWrapper, header?.route === '/fe' ? Style.subMenuRight : Style.subMenuLeft, header.isSubmenuHeading && Style.SubmenuHeaderLayout]}>
                    {header.isSubmenuHeading && <p className="Header_headtext">{header?.isSubmenuHeadingText}</p>}

                    {header?.submenu.map((headerSubmenu) => PrivAttributes?.[headerSubmenu?.privId]?.PrivRole !== NAvalue && (
                      <ListItem
                        sx={{
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          },
                          '& a': {
                            fontSize: '14px',
                            textTransform: 'capitalize',
                          },
                          '&:hover a': {
                            fontWeight: 700,
                          },
                        }}
                        disablePadding
                        key={headerSubmenu.name}
                      >
                        <Typography tabIndex={0}
                                    as="a" sx={{
                          cursor: 'pointer',
                          '&:focus-visible': {
                            textDecoration: 'underline',
                          },
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }} onClick={() => changedRoute(headerSubmenu.route)}
                                    onKeyDown={(e) => handleEnterKey(e, headerSubmenu.route)}>
                          {headerSubmenu.name}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ) : null}
            </ListItem>
          );
        })}
    </List>
  );
}
