import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CustomizeButton } from 'components/CustomizeButton';
import { GridTextfield } from 'components/GridTextfield';
import { Loader } from 'components/Loader';
import useAuth from 'hooks/useAuth';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { removeAccessToken } from 'store/authSlice';
import { setModelMessage } from 'store/commonSlice';
import { getUserRegDetails, resetUserState, verifySecurityQuestions } from 'store/userHomeSlice';
import {
  answerField,
  ERROR,
  getCurrentPageName,
  isEmpty,
  nonPrintableChar,
  t,
  validateSecurityAnswer,
} from 'utils/constants';
import { decrypt, encrypt } from 'utils/EncryptDecrypt';

import Style from './styles';

function SecurityQuestionModal(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { user, sendCustomChallengeAnswer, loader, authSignOut } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { modelMessage } = useSelector(({ common }) => common);


  let arr = [];
  const [qArray, setQArray] = useState([]);
  const [questionCount, setQuestionCount] = useState(arr[0]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [securityQuestionDisplay, setsecurityQuestionDisplay] = useState(props.securityQuestions[`Question${arr[0]}`]);

  useEffect(() => {
    arr = [1, 2, 3];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    arr = shuffle(arr);
    setQArray(arr);
    setQuestionCount(arr[0]);
    setQuestionIndex(0);
    setsecurityQuestionDisplay(props.securityQuestions[`Question${arr[0]}`]);

    function shuffle(array) {
      let currentIndex = array.length;
      let randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        // eslint-disable-next-line no-plusplus
        currentIndex--;
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
      return array;
    }
  }, []);

  const securityQuesiton = {};
  const cookies = new Cookies();
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {},
  });
  const { resetField, handleSubmit } = methods;

  const onSubmit = async (data) => {
    try {
      data.Question = securityQuestionDisplay;
      data.Answer = data.Answer?.toLowerCase();
      const response = await sendCustomChallengeAnswer(props.cognitoUser, `${data.Question}#${data.Answer}#${questionCount}`);
      if (response && response.challenge) {
        const data = {
          title: ERROR,
          message1: 'MSG_ERR_INVALID_ANSWER',
          error: true,
        };
        dispatch(setModelMessage(data));
      } else if (response && response.accessToken) {
        const firstTimeLoginCookies = cookies.get('FirstTimeLogin') ? cookies.get('FirstTimeLogin') : [];
        const TempFirstTimeLogin = cookies.get('TempFirstTimeLogin') ? cookies.get('TempFirstTimeLogin') : null;
        if (TempFirstTimeLogin) {
          const decryptedTempFirstTimeLogin = decrypt(TempFirstTimeLogin);
          const userName = decryptedTempFirstTimeLogin.split('::')[1];
          if (firstTimeLoginCookies.filter((item) => decrypt(item) === userName).length === 0) {
            firstTimeLoginCookies.push(encrypt(userName));
            cookies.set('FirstTimeLogin', firstTimeLoginCookies, {
              path: '/',
              maxAge: 365 * 86400,
              sameSite: 'strict',
              secure: true,
            });
            ;

          }
          cookies.remove('TempFirstTimeLogin');

        }
        props.handleClose();
        // navigate("/home");
      } else {
        const data = {
          title: ERROR,
          message1: 'MSG_ERR_INVALID_ANSWER',
          error: true,
        };
        dispatch(setModelMessage(data));
        // navigate('/home');
      }

      if (!isEmpty(response?.userId)) dispatch(getUserRegDetails({ UID: response?.userId }));

    } catch (error) {
      const data = {
        title: ERROR,
        message1: 'MSG_ERR_INVALID_ANSWER',
        error: true,
      };
      dispatch(setModelMessage(data));
      props.handleClose();
      // setError("notify", { type: "custom", message: error.message });
    }
  };


  useEffect(() => {
    if (modelMessage.error) {
      const newKey = `Question${qArray[questionIndex + 1]}`;
      setsecurityQuestionDisplay(props.securityQuestions[newKey]);
      setQuestionCount(qArray[questionIndex + 1]);
      setQuestionIndex(questionIndex + 1);
      if (questionIndex === 2) {
        props.handleClose();
        // setQuestionCount(1);
        // const newKey = `Question${1}`;
        // setsecurityQuestionDisplay(props.securityQuestions[newKey]);
        // props.handleClose();
        // handleLogout();
        // securityQuesiton[user?.userId] = 'true';
        // localStorage.setItem('securityQuestions',JSON.stringify(securityQuesiton))
        // navigate('/home');
      }
    } else if (modelMessage.title === 'SUCCESS') {
      props.handleClose();
      if (props.isRedirect) {
        securityQuesiton[user?.userId] = 'true';
        localStorage.setItem('securityQuestions', JSON.stringify(securityQuesiton));
        // navigate("/home");
      }
    }
    resetField('Answer');
  }, [modelMessage]);

  const handleClose = () => {
    authSignOut();
    removeAccessToken();
    dispatch(resetUserState());
    navigate('/');
  };

  /* Conflict Resolution Candidate
    useEffect(() => {
      const preventTab = (e) => {
        e = e || window.event;
        if (e.keyCode === 9) {
          e.preventDefault()
        }
      }
      if(props.isOpen){
        document.addEventListener("keydown",preventTab);
      }
      if(!props.isOpen){
        document.removeEventListener("keydown", preventTab);
      }
      return () =>document.removeEventListener("keydown", preventTab);
    }, [props.isOpen])

    return (
      <Modal
        open={props.isOpen}
   */
  return (
    <Modal
      open
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          props.isClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={Style.SecurityQuestionModal}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography id="modal-modal-title" variant="h2">
              {t(t1, 'SECURITY_QUESTION_TXT')}
            </Typography>
            <Typography variant="h6">{t(t1, securityQuestionDisplay)}</Typography>
            <Grid container spacing={5}>
              <GridTextfield
                label={`${t(t1, 'ANSWER')}`}
                gridMd={8}
                id="priv_txt_securityAnswer1"
                name="Answer"
                rules={{
                  required: true,
                  minLength: {
                    value: 4,
                    message: `${t(t1, 'ERR_ANSWER_SECURITY_MIN_INVALID_MSG')}`,
                  },
                  maxLength: {
                    value: 64,
                    message: `${t(t1, 'ERR_ANSWER_MAX_LENGTH_CHAR')}`,
                  },
                  validate: {
                    isValid: (v) =>
                      methods?.getValues('Answer') ? validateSecurityAnswer(v, `${t(t1, 'ANSWER')}`, t1, `${t(t1, 'ERR_ANSWER_SECURITY_MIN_INVALID_MSG')}`, `${t(t1, 'ERR_INVALID_ANSWER')}`, nonPrintableChar) : null,
                  },
                }}
                isRequiredMsg={'ERR_ANSWER_IS_REQUIRED'}
                placeholder={`${t(t1, 'PH_ANSWER')}...`}

                // value={props.value}
                // onChange={props.inputChange}
              />
            </Grid>
            <Box sx={{ mb: 0, mt: 1, textAlign: 'end' }} className="bottom-btn">
              <CustomizeButton
                variant="outlined"
                csName="rc"
                label={t(t1, 'CANCEL')}
                name="priv_btn_Reset"
                handleClick={handleClose}
              />
              <CustomizeButton
                disableElevation
                privId="priv_btn_continue"
                disabled={methods && !methods?.formState?.isValid || loader}
                variant="contained"
                color="primary"
                type="submit"
                label={t(t1, 'CONTINUE')}
                name="priv_btn_ContinueBtn"
              />
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}

export default SecurityQuestionModal;
