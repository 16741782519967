const Style = {
  ExpandButton: {
    padding: '0 5px',
    color: 'primary.light',
    textTransform: 'unset',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
      fontWeight: 600,
    },
  },
};

export default Style;
