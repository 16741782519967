const Style = {
  FieldLabel: {
    fontSize: '0.875rem',
    color: 'common.textColor',
    fontWeight: 700,
    /* Conflict Resolution Candidate
        "& .radio-select-lbl": { fontSize: "0.875rem", display: "inline-block", color: "common.textColor", fontWeight: 700 },
     */
    '& .radio-select-lbl': { fontSize: '0.875rem', display: 'inline-block', color: 'common.black', fontWeight: 700 },
    '& .disable-radio-select-lbl': {
      fontSize: '0.875rem',
      display: 'inline-block',
      color: 'common.textLightGray',
      fontWeight: 700,
    },
  },
  formField: {
    width: 1,
    color: 'common.textLightGray',
    fontSize: '0.875rem',
    lineHeight: '1.5',
    '.MuiOutlinedInput-input': {
      py: 0.4,
      fontSize: '14px',
      backgroundColor: 'common.white',
      '& .MuiTypography-root': {
        fontSize: '14px',
        // fontWeight: "700",
      },
    },
    // "& .MuiOutlinedInput-notchedOutline": {
    //   borderRadius: "0",
    //   borderColor: "common.GrayColor",
    // },
    // "& .MuiOutlinedInput-root": {
    //   "& .MuiInputBase-root .Mui-focused": {
    //     "& .MuiOutlinedInput-notchedOutline": {
    //       borderColor: "common.GrayColor",
    //       borderWidth: 1,
    //     },
    //   },
    // },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '4px',
    },
    '& .Mui-error': {
      marginLeft: 0,
    },
    // to be
    '& .MuiAccordionDetails-root': {
      '& .plus-minus-btn-main': {
        '& .Mui-disabled': {
          color: 'common.textLightGray',
        },
      },
    },
  },
  disabledField: {
    width: 1,
    color: 'common.textLightGray',
    fontSize: '0.875rem',
    lineHeight: '1.5',
    opacity: '0.38',
    '.MuiOutlinedInput-input': {
      py: 0.4,
      fontSize: '14px',
      backgroundColor: 'common.pageBackground',
      '& .MuiTypography-root': {
        fontSize: '14px',
        // fontWeight: "700",
      },
    },
    // ".MuiOutlinedInput-notchedOutline": {
    //   borderRadius: "0",
    //   borderColor: "common.GrayColor",
    // },
    // "&.MuiOutlinedInput-root": {
    //   "&.MuiInputBase-root.Mui-focused": {
    //     ".MuiOutlinedInput-notchedOutline": {
    //       borderColor: "common.GrayColor",
    //       borderWidth: 1,
    //     },
    //   },
    // },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '4px',
    },
    '& .Mui-error': {
      marginLeft: 0,
    },
  },
  inputStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    color: 'common.blue',
    // py: 1 / 5
  },
};

export default Style;
