import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Checkbox, FormControlLabel, Grid, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CustomizeButton } from 'components/CustomizeButton';
import { GridTextfield } from 'components/GridTextfield';
import { Loader } from 'components/Loader';
import useAuth from 'hooks/useAuth';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { removeAccessToken } from 'store/authSlice';
import { setLoader } from 'store/commonSlice';
import { getFileUrl } from 'store/configSlice';
import { resetUserState, updateTermsAndConditionInfo } from 'store/userHomeSlice';
import {
  answerField,
  defaultORGID,
  docFilesPageName,
  enLanguage,
  getCurrentPageName,
  getFormLabels,
  isEmpty,
  JurisdictionUser,
  ServiceProviderUser,
  t,
  FSAHome,
  MarketMonitor,
  AuctionAdministrator,
} from 'utils/constants';
import Style from './styles';

function TermsConditionsModal(props) {
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { user, authSignOut } = useAuth();
  const { defaultFileUrl } = useSelector(({ config }) => config);
  const { loader } = useSelector(({ common }) => common);
  const { userDetails } = useSelector(({ userHome }) => userHome);
  const { selectedJurisdiction } = useSelector(({ jurisdiction }) => jurisdiction);
  // React states
  const [acceptTAndC, setAcceptTAndC] = useState(false);
  const [buttonEnabled, setButttonEnabled] = useState(false);
  const [filteredForms, setFilteredForms] = useState([]);
  const [formsArray, setFormsArray] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {},
  });
  const { resetField, handleSubmit } = methods;

  useEffect(() => {
    let formsArr = [];
    /* Conflict Resolution Candidate
        if ((userDetails?.AcceptTAndC === false || userDetails?.AcceptTAndC === "false") && (userDetails?.AcceptTAndU === false || userDetails?.AcceptTAndU === "false"))
          formsArr = ['TERMS_AND_CONDITIONS_TEXT', 'WCI_TERMS_OF_USE']
        else if ((userDetails?.AcceptTAndC === false || userDetails?.AcceptTAndC === "false"))
     */
    if (userDetails?.AcceptTAndC === false && userDetails?.AcceptTAndU === false)
      formsArr = ['TERMS_AND_CONDITIONS_TEXT', 'WCI_TERMS_OF_USE'];
    else if (userDetails?.AcceptTAndC === false)
      formsArr = ['TERMS_AND_CONDITIONS_TEXT'];
    else if (userDetails?.AcceptTAndU === false)
      formsArr = ['WCI_TERMS_OF_USE'];
    setFormsArray(formsArr);
  }, [userDetails]);
  useEffect(() => {
    let filterForms = defaultFileUrl?.data?.filter((eachFile) => formsArray?.includes(eachFile?.SK?.split('#')?.pop()));
    filterForms = filterForms?.filter((item) => !isEmpty(item?.LabelPublished))?.map((item) => {
      return { fileName: t(t1, item?.SK?.split('#')?.pop()), ...item };
    });
    setFilteredForms(filterForms);
  }, [defaultFileUrl]);

  const initialFunctionCall = () => {
    const getDocFilePayload = {
      OrgID: userDetails?.OrgID || defaultORGID,
      QueryType: getFormLabels,
      PageLanguage: enLanguage(),
      Page: docFilesPageName,
    };
    dispatch(getFileUrl(getDocFilePayload));
  };

  useEffectOnce(() => {
    initialFunctionCall();
  }, []);

  const handleTAndC = (e) => {
    const { checked } = e.target;
    setAcceptTAndC(checked);
  };
  const onSubmit = async (data) => {
    dispatch(setLoader(true));
    let navUrl = '';
    if (userDetails?.RoleDetail?.RoleType[0]?.name === JurisdictionUser) {
      navUrl = '/jurisdiction-user';
    } else if (userDetails?.RoleDetail?.Role[0]?.name === FSAHome) {
      navigate('/fsa-home');
    } else if (userDetails?.RoleDetail?.Role[0]?.name === MarketMonitor) {
      navigate('/market-monitor');
    } else if (userDetails?.RoleDetail?.Role[0]?.name === AuctionAdministrator) {
      navigate('/auction-administrator');
    } else {
      navUrl = '/home';
    }


    console.log('filteredForms=', filteredForms);
    const payloadForAPI = [];
    filteredForms?.filter((item) => !isEmpty(item?.LabelPublished))?.map((item, index) => {
      const payload = {
        'UID': `U#${user?.userId}`,
        'OrgID': userDetails?.OrgID || '',
        'object_url': item?.LabelPublished,
        'TermType': item?.SK?.split('#')?.pop(),
        'AcceptTermsAndConditions': true,
        'LanguageCode': localStorage.getItem('languageCode'),
      };
      payloadForAPI.push(payload);
      return payload;
    });
    console.log('payloadForAPI after =', payloadForAPI);
    dispatch(updateTermsAndConditionInfo(user?.userId, payloadForAPI, navigate, navUrl, props?.setUpdateTCModal));
  };

  const handleClose = () => {
    authSignOut();
    removeAccessToken();
    dispatch(resetUserState());
    navigate('/');
  };

  return (
    <Modal
      open
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          props.isClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box sx={Style.SecurityQuestionModal}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1}>
                <Grid item md={12}>
                  <Typography id="modal-modal-title" variant="h2">
                    {t(t1, 'TERMS_AND_CONDITION_UPDATE_TEXT')}
                  </Typography>
                  <Typography
                    variant="h6">{`${t(t1, 'UPDATE_TERMS_CONDITION_LABEL_TEXT')} ${formsArray?.map((i) => t(t1, i))?.join(', ')} ${t(t1, 'TO_CONTINUE_TEXT')}`}
                    {/* <Typography>
                      <a
                        href={filteredForms?.[0]?.LabelPublished?.replace('#', '%23')}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#4d8da4", fontSize: "16px", fontWeight: 300, textDecoration:"none" }}
                      >
                        {t(t1, "TERMS_AND_CONDITIONS_TEXT")}
                      </a>
                    </Typography> */}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <FormControlLabel
                    sx={{
                      mr: '-5px',
                      '& .MuiFormControlLabel-label': {
                        color: 'common.text.primary',
                        fontWeight: 600,
                        fontSize: '14px',
                      },
                    }}
                    control={<Checkbox onChange={handleTAndC} checked={acceptTAndC} />}
                    label={t(t1, 'JURISDICTION_TERMS_AND_CONDITIONS_MODEL')}
                  />
                  {filteredForms?.filter((item) => !isEmpty(item?.LabelPublished))?.map((item, index) => {
                    return <>
                      {index !== 0 && <Typography as="span" sx={{
                        color: 'common.text.primary',
                        fontWeight: 600,
                        fontSize: '14px',
                      }}> {t(t1, 'AND')} </Typography>}
                      <a
                        href={item?.LabelPublished?.replace('#', '%23')}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          marginLeft: index === 0 ? '9px' : '0px',
                          color: '#4d8da4',
                          fontSize: '14px',
                          fontWeight: 600,
                        }}
                      >
                        {item?.fileName}
                      </a>
                    </>;
                  })}
                </Grid>
              </Grid>
              <Box sx={{ mb: 0, mt: 10, textAlign: 'end' }} className="bottom-btn">
                <CustomizeButton
                  variant="outlined"
                  csName="rc"
                  label={t(t1, 'CANCEL')}
                  name="priv_btn_Reset"
                  handleClick={handleClose}
                />
                <CustomizeButton
                  disableElevation
                  privId="priv_btn_continueTC"
                  disabled={!acceptTAndC || loader}
                  variant="contained"
                  color="primary"
                  type="submit"
                  label={t(t1, 'TC_MODEL_CONTINUE')}
                  name="priv_btn_ContinueBtn"
                />
              </Box>
            </form>
          </FormProvider>
        </Box>
        {loader && <Loader loader={loader} />}
      </>
    </Modal>
  );
}

export default TermsConditionsModal;
