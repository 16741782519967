import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import { FormCard } from 'components/FormCard';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomeDialogMessage } from 'components/CustomeDialogMessage';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { CheckCircle } from 'assets/icons';
import CustomPopper from 'components/_CustomPopper/customPopper';
import { FormActions } from 'components/EntityFormActions';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { TextTruncate } from 'components/TextTruncate';
import { useParams } from 'react-router-dom';
import {
  getJurisdictions,
  setJurisdiction,
  setJurisdictionId,
  setNextStepSelect,
  setSkipStepByKey,
  setSelectedClassification,
  setEntityType,
  setFormValueSubmittedOfStep,
  resetSteps,
} from 'store/entityApplicationSlice';
import {
  JURISDICTIONS_NAMES,
  COMPONENT_MODES,
  COMPONENT_NAMES,
  getCurrentPageName,
  isEmpty,
  scrollToTop,
  t,
  checkIsReadOrWrite,
  PERMISSION_NAMES,
  JURISDICTION_STATUS,
  ENTITY_TYPE_NAMES,
} from 'utils/constants';
import { Loader } from 'components/Loader';
import UnsavedPrompt from 'components/UnsavedDialog';
import Style from './SelectJurisdiction.style';
import '../../ComponentTextTruncate.css';
import { ENTITY_INFORMATION_MANDATORY_FIELDS_BY_JURISDICTION } from '../../util/entityRegistrationUtil';

export default function SelectJurisdiction({ displayHead = true, mode = COMPONENT_MODES.ADD }) {
  const { steps, jurisdictions, fieldPermissions, loadingJurisdictions } = useSelector(
    ({ entityApplication }) => entityApplication,
  );
  const { t: t1 } = useTranslation(getCurrentPageName());
  const { user } = useSelector(({ auth }) => auth);
  const { PrivAttributes } = user;
  const { id } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const viewOnly = mode === COMPONENT_MODES.EDIT;
  const themeColor = theme?.palette?.primary?.main;
  const defaultValuesStep = steps.find((item) => item.key === COMPONENT_NAMES.SELECT_JURISDICTION).filledData;
  const activeStepObj = steps.filter((item) => item.selected)[0];
  const [showActivateDeactivateModal, setShowActivateDeactivateModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isExitInProgress, setIsExitInProgress] = useState(false);
  const [selectedJuri, setSelectedJuri] = useState();

  const [jurisdictionName, setJurisdictionName] = useState(activeStepObj?.filledData?.jurisdiction?.Name);
  const isReadOnlyisReadOnly = useMemo(() => {
    return checkIsReadOrWrite(fieldPermissions, 'entity', 'jurisdiction') === PERMISSION_NAMES.READ;
  }, [fieldPermissions]);
  const readOnly = PrivAttributes?.priv_select_Jurisdiction?.PrivRole === 'RO' || isReadOnlyisReadOnly;
  const jurisdictionOptions = useMemo(
    () =>
      jurisdictions?.filter(
        (juridiction) =>
          juridiction?.Status?.Name !== JURISDICTION_STATUS.ONBOARDING &&
          juridiction?.Status?.Name !== JURISDICTION_STATUS.OFFBOARDED,
      ),
    [jurisdictions],
  );

  const defaultValues = {
    jurisdiction: '',
  };

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    setLoader(loadingJurisdictions && jurisdictions.length === 0 && !id);
  }, [loadingJurisdictions, jurisdictions]);

  useEffectOnce(() => {
    scrollToTop();
  });

  useEffect(() => {
    methods?.reset(defaultValuesStep);
    if (isEmpty(defaultValuesStep)) {
      methods?.reset({});
    }
  }, [defaultValuesStep]);

  if (PrivAttributes?.priv_select_Jurisdiction?.PrivRole === 'NA') {
    return null;
  }

  const resetData = () => {
    if (!id) {
      dispatch(resetSteps());
      dispatch(setEntityType(''));
    }
  };
  return (
    <FormProvider {...methods}>
      <div className={`STEP_CLASSNAME_${COMPONENT_NAMES.SELECT_JURISDICTION} MODE_${mode}`}>
        {displayHead && (
          <Typography style={Style.contentTextCard}>
            <div
              className="contentTextCardTwo"
              style={Style.contentTextCard}
            >
              <TextTruncate
                content={t(t1, 'FOOTER_DISCLAIMER_CONTENT')}
                animate
              />
            </div>
          </Typography>
        )}
        <FormCard
          customClass={'customFormCard select-jurisdiction'}
          title={t(t1, viewOnly || id ? 'SELECTED_JURISDICTION' : 'SELECT_JURISDICTION')}
          indicateRequired={false}
          setDefaultValues={() => methods?.reset(defaultValues)}
          stepNumber={1}
        >
          <FormControl
            sx={{
              mt: 2,
              width: 400,
              '& .Mui-error': { marginLeft: 0 },
            }}
          >
            {readOnly || mode === COMPONENT_MODES.EDIT || id ? (
              <Typography
                component="div"
                sx={Style.inputStyle}
                className="icon-lg"
              >
                <CheckCircle color={themeColor} />
                {t(t1, methods.getValues('jurisdiction')?.Name)}
              </Typography>
            ) : (
              <Controller
                name="jurisdiction"
                control={methods?.control}
                rules={{ required: t(t1, 'SELECT_JURISDICTION_REQUIRED') }}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <Autocomplete
                        PopperComponent={CustomPopper}
                        {...field}
                        value={field.value}
                        id="select-jurisdiction"
                        options={jurisdictionOptions}
                        disableClearable
                        getOptionLabel={(option) => (option.displayName ? t(t1, option.displayName) : '')}
                        renderOption={(props, option) => {
                          return (
                            <MenuItem
                              {...props}
                              disabled={option.disabled}
                              key={option.ID}
                              value={option}
                              sx={{
                                padding: '0 !important',
                              }}
                            >
                              <ListItemText
                                sx={{
                                  px: '16px',
                                  py: '6px',
                                }}
                                primary={t(t1, option.displayName)}
                              />
                            </MenuItem>
                          );
                        }}
                        ListboxProps={{
                          style: {
                            maxHeight: 190,
                          },
                        }}
                        sx={{
                          ...Style.formField,
                          color:
                            (methods?.getValues('jurisdiction') === '' ||
                              methods?.getValues('jurisdiction') === false) &&
                            'common.textColor',
                        }}
                        onChange={(e, newValue) => {
                          setSelectedJuri(newValue.Name);
                          const event = {
                            ...e,
                            target: { ...e.target, value: newValue },
                          };
                          if (!isEmpty(jurisdictionName)) {
                            if (jurisdictionName !== newValue.Name) {
                              setShowActivateDeactivateModal({
                                callback: () => {
                                  dispatch(setJurisdictionId(newValue.ID));
                                  setJurisdictionName(newValue.Name);
                                  field?.onChange(event);
                                },
                              });
                            }
                          } else {
                            dispatch(setEntityType(''));
                            setShowActivateDeactivateModal(false);
                            field?.onChange(event);
                          }
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              error={fieldState?.error?.message}
                              placeholder={
                                methods?.getValues('jurisdiction')?.length ? '' : t(t1, 'SELECT_JURISDICTION')
                              }
                              className={'SearchableDropdown'}
                            />
                          );
                        }}
                        disabled={!isEmpty(id)}
                      />
                      {fieldState?.error?.message ? (
                        <FormHelperText error>{fieldState?.error?.message}</FormHelperText>
                      ) : null}
                    </>
                  );
                }}
              />
            )}
          </FormControl>
        </FormCard>
        {mode === COMPONENT_MODES.ADD && (
          <FormActions
            handleSubmit={(data) => {
              const entityInformationData = steps.find((step) => step.key === COMPONENT_NAMES.ENTITY_INFORMATION);
              const entityTypeInformation = steps.find((step) => step.key === COMPONENT_NAMES.SELECT_ENTITY_TYPE)?.filledData?.entityType?.Name;
              const entityInformationFilledData = entityInformationData?.filledData || {};
              const mandatoryFields = (ENTITY_INFORMATION_MANDATORY_FIELDS_BY_JURISDICTION[data?.jurisdiction?.Name] || [
                'IdentificationNumber',
                'IDByAgency',
              ]);
              if (entityTypeInformation !== ENTITY_TYPE_NAMES.GENERAL_MARKET_PARTICIPANT_INDIVIDUAL) {
                dispatch(
                  setFormValueSubmittedOfStep({
                    page: COMPONENT_NAMES.ENTITY_INFORMATION,
                    isSubmitted:
                      !mandatoryFields.some((field) => !entityInformationFilledData[field]),
                  }),
                );
              }
              dispatch(setJurisdiction(data?.jurisdiction?.Name));
              if (data?.jurisdiction?.Name === JURISDICTIONS_NAMES.QUEBEC) {
                dispatch(setSkipStepByKey({ stepName: 'AUCTION', skip: true }));
              } else {
                dispatch(setSkipStepByKey({ stepName: 'AUCTION', skip: false }));
              }
              dispatch(setNextStepSelect({ steps, isEntityCreated: !!id }));
            }}
            stepNumber={1}
            continueDisabled={!methods?.formState.isValid}
            onExitClick={() => setIsExitInProgress(true)}
            onCancelExit={() => setIsExitInProgress(false)}
          />
        )}
        {showActivateDeactivateModal && (
          <CustomeDialogMessage
            title={t(t1, 'WARNING')}
            continueBtn={t(t1, 'CONTINUE')}
            open={showActivateDeactivateModal}
            handleClose={() => setShowActivateDeactivateModal(false)}
            message1={t(t1, 'CHANGE_ENTITY_TYPE_WARNING')}
            message2={t(t1, 'CANCEL_WARNING_CONTENT2')}
            message3={t(t1, 'CHANGE_ENTITY_TYPE_WARNING_MESSAGE3')}
            borderColor="red"
            cancelApplicationModal
            handleContinue={() => {
              showActivateDeactivateModal.callback();
              setShowActivateDeactivateModal(false);
              dispatch(setSelectedClassification(''));
            }}
          />
        )}
      </div>
      {loader && <Loader loader={loader} />}
      <UnsavedPrompt
        when={!isExitInProgress && !id && selectedJuri}
        title={t(t1, 'WARNING')}
        message={t(t1, 'CONFIRM_LEAVE_PAGE')}
        onSuccess={resetData}
      />
    </FormProvider>
  );
}
