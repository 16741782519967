import { useAuthentication } from 'hooks/useAuthentication';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { CreateEntityUserRegistration } from 'pages/EntityRegistration/Registration';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  checkIsEditAccess,
  getAlternateContact,
  getEnityFacilityById,
  getEntityContactById,
  getFieldPermission,
  getRepresentative,
  resetRepresentative,
  resetSteps,
  setAlternateContact,
  setEditAccess,
  setEntityType,
  setFacilitiesList,
  setLockIntervalId,
  updateLock,
} from 'store/entityApplicationSlice';
import { getActionStatus, getRepresentativeStatus, getRequestStatus } from 'store/entityProfileSlice';
import { useJurisdictionUserRole } from 'hooks/useUserRole';
import { setModelMessage } from 'store/commonSlice';
import {
  COMPONENT_NAME_FOR_ROLE,
  ENTITY_STATUS,
  POLLING_INTERVAL,
  getCurrentPageName,
  isEmpty,
  t,
} from 'utils/constants';
import usePrevious from '../../hooks/usePrevious';

export default function EntityRegistration() {
  const { id } = useParams();
  const navigate = useNavigate();
  const url = id ? `/entity-registration/${id}` : '/entity-registration';
  const { steps, isLocked, EditAccess, fieldPermissions, EntitiesData } = useSelector(
    ({ entityApplication }) => entityApplication,
  );
  const { entityErrorRes } = useSelector(({ entityProfile }) => entityProfile);
  useAuthentication({ redirectTo: url });
  const hasExecuted = useRef(false);
  const [isManuallyLocked, setIsManuallyLocked] = useState(false);
  const dispatch = useDispatch();
  const previousId = usePrevious(id);
  const [isDataReset, setIsDataReset] = useState(false);

  const { t: t1 } = useTranslation(getCurrentPageName());
  const { isJurisdictionRegistrarUser } = useJurisdictionUserRole();

  let alternateContactPersonPageDetail = {
    componentName: COMPONENT_NAME_FOR_ROLE.ALTERNATE_CONTACT_PERSON,
    pageName: 'Page_EntityProfile',
  };
  if (isJurisdictionRegistrarUser) {
    alternateContactPersonPageDetail = {
      componentName: COMPONENT_NAME_FOR_ROLE.ALTERNATE_CONTACT_PERSON,
      pageName: 'Page_JurisdictionEntityProfile',
    };
  }

  useEffect(() => {
    if (!id && previousId && !isDataReset) {
      dispatch(resetRepresentative());
      dispatch(resetSteps());
      dispatch(setEntityType(''));
      setIsDataReset(true);
    } else if (id && !previousId && isDataReset) {
      dispatch(getEntityContactById(id, true));
      dispatch(getEnityFacilityById(id, { componentName: 'addedFacilities', pageName: 'Page_EntityProfile' }));
      dispatch(getRepresentative(id));
      dispatch(getAlternateContact(id, alternateContactPersonPageDetail));
      dispatch(checkIsEditAccess(id));
      setIsDataReset(false);
    }
  }, [id, previousId, isDataReset]);

  let pageDetail = { componentName: 'addedFacilities', pageName: 'Page_EntityProfile' };

  useEffectOnce(() => {
    dispatch(
      getFieldPermission({
        pageName: 'Page_EntityRegistration',
        componentName:
          'entity,identifiers,physical,contact,facility,addedFacilities,addedFacilitie,mailing,entityContact,facilityContact,addRepresentatives,facilityMailingAddress,facilityPhysicalAddress,addRepresentative',
      }),
    );

    dispatch(getActionStatus());
    dispatch(getRepresentativeStatus());
    dispatch(getRequestStatus());

    if (id) {
      dispatch(getEntityContactById(id, true));
      if (isJurisdictionRegistrarUser) {
        pageDetail = { componentName: 'addedFacilities', pageName: 'Page_JurisdictionEntityProfile' };
      }
      dispatch(getEnityFacilityById(id, pageDetail));
      dispatch(getRepresentative(id));
      dispatch(getAlternateContact(id, alternateContactPersonPageDetail));
      dispatch(checkIsEditAccess(id));
    } else {
      dispatch(setEditAccess(true));
      dispatch(setAlternateContact());
      dispatch(setFacilitiesList());
    }
  });

  useEffect(() => {
    if (id && EntitiesData?.data?.isLocked) {
      const data = {
        title: t(t1, 'CANNOT_PROCEED'),
        message1: t(t1, 'APPLICATION_LOCKED'),
        error: true,
        isDefaultDisplay: true,
      };
      dispatch(setModelMessage(data));
    }
    let intervalId;
    if (
      id &&
      !EntitiesData?.data?.isLocked &&
      EntitiesData?.data?.entityStatus?.name === ENTITY_STATUS.APPLICATION_IN_PROGRESS
    ) {
      dispatch(updateLock(id, true));

      intervalId = setInterval(() => {
        dispatch(updateLock(id, true)); // Set is_locked to true as per your requirement
      }, POLLING_INTERVAL);
      dispatch(setLockIntervalId(intervalId));
    }
    return () => intervalId && clearInterval(intervalId);
  }, [id, EntitiesData?.data?.isLocked]);

  useEffect(() => {
    const entityStatus = EntitiesData?.data?.entityStatus?.name;

    if (entityStatus === ENTITY_STATUS.PENDING && id && !hasExecuted.current) {
      const toastMessage = {
        title: 'COMMON_ERROR',
        message1: 'ERR_ENTITY_STATUS_UPDATED_TO_PENDING',
        error: true,
      };
      dispatch(setModelMessage(toastMessage));
      hasExecuted.current = true;
      navigate(`/entity-profile/${id}`, { replace: true });
    }
  }, [EntitiesData?.data?.entityStatus?.name, id]);

  useEffect(() => {
    if (
      (entityErrorRes?.response?.data?.data === 'FORBIDDEN_ERROR' ||
        entityErrorRes?.response?.data?.messageKey === 'USER_IS_NOT_AUTHORIZED') && !hasExecuted.current
    ) {
      const toastMessage = {
        title: 'COMMON_ERROR',
        message1: 'ERR_ENTITY_STATUS_UPDATED_TO_CANCELLED',
        error: true,
      };
      dispatch(setModelMessage(toastMessage));
      hasExecuted.current = true;
      navigate(`/home`, { replace: true });
    }
  }, [entityErrorRes]);


  return <div>{!isEmpty(fieldPermissions) && <CreateEntityUserRegistration />}</div>;
}
