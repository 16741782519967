import { Typography } from '@mui/material';
import { getGridDateOperators } from '@mui/x-data-grid-premium';
import AccessibleLink from 'components/AccessibleLink';
import { betweenOperators } from 'components/MUITableGrid/components/MUICustomFilterFunctions';
import { Link as RouterLink } from 'react-router-dom';
import { formatDateUpdatedWithoutTZ, t } from 'utils/constants';
import QuickActions from './quickActionsMenu';

export const ManageJurisdictionSchema = (t1) => [
  {
    id: 1,
    field: 'jurisdictionId',
    headerName: t(t1, 'JURISDICTION_ID'),
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    hideable: false,
    renderCell: (params) => params?.row?.isPeripheralSetupDone ? (
      <AccessibleLink
        color="primary.light"
        component={RouterLink}
        to={`/manage-jurisdiction-details/${params.row.id}`}
      >
        <Typography
          variant="body2"
          className="link-cell"
        >
          {params?.row?.jurisdictionId}
        </Typography>
      </AccessibleLink>
    ) : (
      <Typography
        variant="body2"
      >
        {params?.row?.jurisdictionId}
      </Typography>
    ),
  },
  {
    id: 2,
    field: 'Name',
    headerName: t(t1, 'JURISDICTION_NAME'),
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    valueGetter: ({ row }) => t(t1, row?.displayName),
    groupingValueGetter: ({ row }) => t(t1, row?.displayName),
  },
  {
    id: 3,
    field: 'shortCode',
    headerName: t(t1, 'JURISDICTION_CODE'),
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    valueGetter: ({ row }) => row?.shortCode,
    groupingValueGetter: ({ row }) => row?.shortCode,
  },
  {
    id: 4,
    field: 'dateOfJoining',
    headerName: `${t(t1, 'DATE_OF_JOINING_ET')}`,
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    filterOperators: [...getGridDateOperators(), ...betweenOperators],
    valueGetter: ({ row }) => formatDateUpdatedWithoutTZ(row?.dateOfJoining),
  },
  {
    id: 5,
    field: 'statusUpdateDate',
    headerName: `${t(t1, 'STATUS_UPDATE_DATE_ET')}`,
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    filterOperators: [...getGridDateOperators(), ...betweenOperators],
    valueGetter: ({ row }) => formatDateUpdatedWithoutTZ(row?.statusUpdateDate),
  },
  {
    id: 6,
    field: 'actionBy',
    headerName: t(t1, 'UPDATE_BY'),
    minWidth: 100,
    maxWidth: 1000,
    flex: 1,
    valueGetter: ({ row }) => row?.actionBy,
    groupingValueGetter: ({ row }) => row?.actionBy,
  },
  {
    id: 7,
    field: 'Status',
    headerName: t(t1, 'STATUS'),
    flex: 1,
    minWidth: 100,
    maxWidth: 1000,
    valueGetter: ({ row, rowNode }) => {
      if (rowNode?.groupingField) {
        return '';
      }
      return t(t1, row?.Status?.Name);
    },
    groupingValueGetter: ({ row }) => t(t1, row?.Status?.Name),
  },
  {
    id: 8,
    field: 'quickActions',
    headerName: t(t1, 'QUICK_ACTIONS'),
    minWidth: 120,
    maxWidth: 120,
    align: 'center',
    renderCell: (params) => {
      if (params?.rowNode?.groupingField) return '';
      if (params?.row?.isPeripheralSetupDone !== null) return <QuickActions params={params} />;
      return '';
    },
    disableExport: true,
    hideable: false,
    resizable: false,
    sortable: false,
    filterable: false,
  },
];
