import _ from 'lodash';
import { formatDateUpdated, formatNumber, formatDateUpdatedWithoutTZ } from 'utils/constants';

export const getTransformedHistoryData = (data) =>
  data?.map((item) => ({
    id: item?.id,
    name: item?.name,
    displayedInDropdown: item?.displayedInDropdown === true ? 'YES' : 'NO',
    comment: item?.comment,
    updatedAt: formatDateUpdatedWithoutTZ(item?.updatedAt),
    userName: item?.userName,
  }));

export function validateIfExists(data, enteredValue, errorMsg) {
  const dataSet = new Set(data?.map((obj) => obj?.name?.toLowerCase()));
  if (dataSet?.has(enteredValue?.toLowerCase()?.trim())) {
    return errorMsg;
  }
  return true;
}

export const transformOffsetDataEdit = (data) => ({
  displayed_in_dropdown: data?.displayedInDropdown,
  id: data?.id,
  name: data?.name,
  updated_at: formatDateUpdated(data?.updatedAt),
  user_name: data?.userName,
  comment: data?.comments,
});

export const shouldSubmitBtnDisableMOF = (data, enteredValue, displayInDropDown, comment) => {
  if (
    data?.displayed_in_dropdown !== displayInDropDown ||
    data?.name?.toLowerCase() !== enteredValue?.toLowerCase()?.trim() ||
    data?.comment?.toLowerCase()?.trim() !== comment?.toLowerCase()?.trim()
  )
    return false;
  return true;
};

export const removeAlreadyExistName = (data, prefilledName) => {
  let result = [];
  if (!_.isEmpty(data)) {
    result = data.filter((i) => i?.name !== prefilledName);
  }
  return result;
};

// OTR - transformed
export const transformOTRDataEdit = (data) => ({
  id: data?.offsetTrackingRecordNumber,
  status: data?.status,
  name: data?.offsetTrackingRecordName,
  projectName: data?.offsetProjectName,
  comment: data?.comment,
  updatedAt: data?.updatedAt,
  updatedBy: data?.lastUpdatedBy,
  lastUpdatedBy: data?.lastUpdatedBy,
  jurisdictionName: data?.jurisdictionName,
});

export const transformOffsetTrackingRecords = (data) =>
  data.map((item) => ({
    id: item.offset_tracking_record_number,
    offsetTrackingRecordId: item.offset_tracking_record_number,
    offsetTrackingRecordName: item.offset_tracking_record_name,
    projectName: item.offset_project_name,
    status: item.status.name,
    updatedDateAndTime: formatDateUpdated(item.updated_at),
    updatedBy: item.last_updated_by,
  }));

export const transformOffsetCreditTable = (data) =>
  data.map((item) => ({
    id: item?.transactionId,
    vintageYear: item?.vintage_year,
    offsetTypeName: item?.offset_type_name,
    projectId: item?.project_id,
    comment: item?.comment,
    quantityTransferred: formatNumber(item.quantity_transferred),
    specialCategoryName: item?.special_category_name,
    offsetTrackingRecordDetailsFlag: item?.offset_tracking_record_details_flag,
    combinationStr: `${item?.project_id}${item?.vintage_year}${item?.offset_type_name}`,
  }));

export const transformAffectedOffsetCredit = (data) =>
  data?.length
    ? data.map((item, index) => ({
      id: item?.offsetTrackingTransferId || index,
      offsetTrackingTransferId: item?.offsetTrackingTransferId || index,
      vintageYear: item?.vintageYear,
      offsetTypeName: item?.offsetTypeName,
      projectId: item?.projectId,
      comment: item?.comment,
      quantityTransferred: formatNumber(item?.quantityTransferred),
      combinationStr: `${item?.projectId}${item?.vintageYear}${item?.offsetTypeName}`,
    }))
    : [];

export const transformStatusHistoryData = (data) =>
  data?.length
    ? data
      ?.map((item, index) => ({
        id: item?.id || index,
        comment: item?.comment,
        updatedAt: formatDateUpdated(item?.updatedAt),
        lastUpdatedBy: item?.lastUpdatedBy,
        status: item?.status,
      }))
      ?.sort((a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt))
    : [];

function getDisplayInDropdown(value) {
  if (typeof value === 'boolean') {
    return value === true ? 'YES' : 'NO';
  }
  return '';
}

export const transformOffsetResponse = (data) =>
  data?.map((item) => ({
    id: item.id,
    name: item.name || '',
    displayedInDropdown: getDisplayInDropdown(item.displayedInDropdown),
    updatedAt: formatDateUpdatedWithoutTZ(item.updatedAt) || '',
    updatedBy: item.updatedBy || '',
  }));
