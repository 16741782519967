import { Link, Typography } from '@mui/material';
import { LinkStyle } from 'config/common.styles';
import { Link as RouterLink } from 'react-router-dom';
import { getGridDateOperators } from '@mui/x-data-grid-premium';
import { betweenOperators } from 'components/MUITableGrid/components/MUICustomFilterFunctions';
import { ACTION_BY_JOB_EXECUTOR, formatDateUpdatedWithoutTZ, t } from 'utils/constants';

export const TransferHistorySchema = (t1, valueOptions) => [
  {
    id: 1,
    field: 'Status',
    headerName: t(t1, 'EVENT'),
    flex: 1,
    minWidth: 100,
    maxWidth: 1000,
    align: 'center',
    valueGetter: (params) => t(t1, params?.row?.Status?.name),
    type: 'singleSelect',
    valueOptions: valueOptions?.Status?.sort(),
  },
  {
    id: 2,
    field: 'createdAt',
    headerName: `${t(t1, 'EVENT_DATE_TIME_ET')}`,
    flex: 1,
    minWidth: 100,
    maxWidth: 1000,
    renderHeader: () => (
      <div style={{ marginTop: '10px' }}>
        <Typography style={{ fontWeight: 600, fontSize: '14px' }}>
          {t(t1, 'EVENT_DATE_TIME_ET')}
          {/* <span style={{ fontSize: "14px", fontWeight: 200 }}>(yyyy-mm-dd hh:mm:ss ET)</span> */}
        </Typography>
      </div>
    ),
    filterOperators: [...getGridDateOperators(), ...betweenOperators],
    valueGetter: ({ value }) => formatDateUpdatedWithoutTZ(value),
  },
  {
    id: 3,
    field: 'comment',
    headerName: t(t1, 'COMMENT'),
    flex: 1,
    minWidth: 100,
    maxWidth: 1000,
    align: 'center',
  },
  {
    id: 4,
    field: 'actionBy',
    headerName: t(t1, 'ACTION_BY'),
    flex: 1,
    minWidth: 100,
    maxWidth: 1000,
    align: 'center',
    valueGetter: ({ value }) => t(t1, value),
    renderCell: ({
                   row: {
                     createdBy,
                     actionBy,
                     Status: { name: status },
                   },
                 }) =>
      ['COMPLETE', 'EXPIRED'].includes(status) || (status === 'CANCELLED' && actionBy === ACTION_BY_JOB_EXECUTOR) ? (
        t(t1, actionBy)
      ) : (
        <Link
          color="primary.light"
          component={RouterLink}
          to={`/jurisdiction-user-actions?id=${window.btoa(createdBy)}`}
          sx={LinkStyle}
        >
          {actionBy}
        </Link>
      ),
  },
];
