import { Grid, List, Typography } from '@mui/material';
import { AccordionLink } from 'components';
import { useJurisdictionUserRole } from 'hooks/useUserRole';
import { useTranslation } from 'react-i18next';
import { getCurrentPageName, t } from 'utils/constants';

function Requests() {
  const { t: t1 } = useTranslation(getCurrentPageName());

  const { isAuthority } = useJurisdictionUserRole();

  if (!isAuthority) return null;

  return (
    <Grid>
      <Typography
        sx={{ fontWeight: 650, textTransform: 'uppercase' }}
        variant="h4"
        gutterBottom
      >
        {t(t1, 'REQUESTS')}
      </Typography>
      <List className="commonlistul">
        <AccordionLink to="/approvals" customStyle={{ width: '100%' }}>{`${t(t1, 'MY_APPROVALS')}`}</AccordionLink>
      </List>
    </Grid>
  );
}

export default Requests;
