import { Loadable } from 'components/Loadable';
import RoleBasedGuard from 'guards/RoleBasedGuard';
import { lazy } from 'react';

const ModifiedRecordsForTransfers = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.ModifiedRecordsForTransfers,
    })),
  ),
);

const AddModifiedRecordsForTransfers = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.AddModifiedRecordsForTransfers,
    })),
  ),
);

const EditModifiedRecordsForTransfers = Loadable(
  lazy(() =>
    import('./index').then((module) => ({
      default: module.EditModifiedRecordsForTransfers,
    })),
  ),
);

export const modifiedRecordsForTransferRoutes = {
  path: '/modified-records-for-transfers',
  children: [
    {
      index: true,
      element: (
        <RoleBasedGuard privId={''}>
          <ModifiedRecordsForTransfers />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'add',
      element: (
        <RoleBasedGuard privId={''}>
          <AddModifiedRecordsForTransfers />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'edit/:id',
      element: (
        <RoleBasedGuard privId={''}>
          <EditModifiedRecordsForTransfers />
        </RoleBasedGuard>
      ),
    },
  ],
};
