const labelContainer = {
  // padding:"5px",
  fontWeight: 300,
  '&:hover': {
    fontWeight: 600,
    border: '1px solid',
    textDecoration: 'underline',

  },
};

const muiCompleted = {
  fontWeight: 500,
};
const Style = {
  stepperWrapper: {
    width: '100%',
    pt: 8,
    pl: 2,
    position: 'sticky',
    top: '0',
    '.MuiStepConnector-line': {
      borderWidth: 0,
      minHeight: '12px',
    },
    '.substep': {
      '.MuiStepConnector-line': {
        borderWidth: '1px !important',
        marginLeft: '11.5px',
      },
    },
    '.MuiStep-root': {
      '&.MainStep': {
        '#substep-wrapper': {
          '.MuiStepConnector-root': {
            height: '24px',
            '&:first-of-type': {
              '.MuiStepConnector-line': {
                top: '0',
              },
            },
          },
          '.MuiStepLabel-root': {
            padding: '0',
          },
          '.MuiStepConnector-line': {
            borderWidth: '1px',
            position: 'relative',
            marginLeft: '11.5px',
            borderColor: '#797979',
            minHeight: '22px',
            top: '2px',
          },
          '.MuiStepLabel-iconContainer::after': {
            content: '\'\'',
            height: '1px',
            width: '10px',
            position: 'absolute',
            top: '50%',
            left: '28px',
            background: '#797979',
          },

        },
        '.MuiStepIcon-text': {
          display: 'block',
          fill: '#283459',
        },
        ml: -1.5,
        '.MuiStepLabel-iconContainer': {
          pr: 1,
        },
        '.MuiStepLabel-labelContainer': {
          ...labelContainer,
        },
        '.MuiStepLabel-labelContainer :hover': {
          ...labelContainer,
        },

        '.MuiStepIcon-root': {
          color: 'common.white',
          border: '1px solid #797979',
          borderRadius: '50%',
          boxSizing: 'border-box',
          width: '2em',
          height: '2em',
          '&.Mui-completed': {
            ...muiCompleted,
            color: 'common.green',
            border: '0',
            '&.Mui-active ': {
              color: 'primary.green',
              fontWeight: 700,

            },
          },
          '&.Mui-active ': {
            fontWeight: 400,
            color: 'primary.primary',
            '.MuiStepIcon-text': {
              fill: 'primary.primary',
            },
          },
        },
        '.MuiStepLabel-label': {
          fontSize: '14px',
          color: 'primary.primary',
          fontWeight: 300,
          '&.Mui-active ': {
            fontWeight: 500,
            color: 'primary.primary',
            '.MuiStepIcon-text': {
              fill: 'primary.primary',
            },
          },
        },
        '.MuiButtonBase-root': {
          padding: '0 0 0 8px',
        },
      },
      '&.SubStep': {
        lineHeight: '1.4',
        marginTop: '-3px',
        '.MuiStepConnector-line': {
          borderWidth: '1px',
        },
        '.MuiStepButton-root': {
          marginLeft: '6px',
        },
        '.MuiStepIcon-text': {
          display: 'none',
        },

        '.Mui-completed': {
          color: 'common.primary',

        },
        '.Mui-active': {
          color: 'primary.main',
          boderColor: 'primary.main',
          fontWeight: 700,
        },
        '.MuiStepIcon-root': {
          'circle': {
            stroke: 'color.main',
          },
          color: 'common.white',
          width: '20px',
          height: '20px',
          '&.Mui-completed': {
            color: 'common.green',
            '&.Mui-active ': {
              color: 'primary.green',
              fontWeight: 700,

            },
          },
          '&.Mui-active ': {
            fontWeight: 700,
            color: 'primary.primary',
            '.MuiStepIcon-text': {
              fill: 'primary.primary',
            },
          },
        },
        '.MuiStepLabel-iconContainer': {
          pr: 2,
        },
        '.MuiStepLabel-labelContainer': {
          ...labelContainer,
        },
        '.MuiStepLabel-label': {
          fontSize: '14px',
          color: 'primary.primary',
          fontWeight: 300,
          '&.Mui-active ': {
            fontWeight: 500,
            color: 'primary.primary',
            '.MuiStepIcon-text': {
              fill: 'primary.primary',
            },
          },
        },
      },

    },


  },
};

export default Style;
